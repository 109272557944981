import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import { formatCurrency } from "../../../lib/common/convertCurrency";
import styles from "./saved-pairing-card.module.scss";
import wineBottlePlaceholder from "../../../../assets/images/wine_bottle_placeholder.png";
import { post, get } from "../../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../../constants/constants";
import DefaultFoodImage from "../../../../assets/images/PAImages/default-food-SHW-PA.webp";

export default function SavedPairingCard({
  save,
  ghost = false,
  food,
  returnPage,
}) {
  const history = useHistory();

  const [wineTypeColor, setWineTypeColor] = useState("#EFEFEF");

  useEffect(() => {
    let wineType = save?.details?.wineType;
    if (!!wineType) {
      wineType = wineType.trimEnd().toLowerCase();
    }
    if (wineType == "red") {
      setWineTypeColor("#f6efef");
    } else if (wineType == "white") {
      setWineTypeColor("#F4ECE3");
    }
  }, [save]);

  //fetching flavor imgs and varietal details from AT
  const [profile, setProfile] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles && save?.details?.varietal) {
      setProfile(varietalProfiles[save?.details?.varietal.trimEnd()]);
    }
  }, [varietalProfiles, save]);

  // end AT FETCH

  const clickWineCard = (save) => {
    if (save?.item?.itemType == 2) {
      history.push({
        pathname: `/pairing-results-food/${save?.details?.recoId}`,
        state: { food: `${food}`, returnPage: returnPage },
      });
    } else if (save?.item?.itemType == 3) {
      history.push({
        pathname: `/pairing-results-wine/${save?.details?.recoId}`,
        state: { food: `${food}`, returnPage: returnPage },
      });
    } else if (save?.item?.itemType == 1) {
      history.push({
        pathname: `/pairing-results/${save?.details?.recoId}`,
        state: { food: `${food}`, returnPage: returnPage },
      });
    }
  };

  if (ghost) return <div className={`${styles.ghostCard}`}></div>;

  if (!save) return <></>;

  if (!profile) return <></>;

  return (
    <div className={`${styles.card}`} onClick={() => clickWineCard(save)}>
      <div
        className={`${styles.wineImgBg}`}
        style={{ backgroundColor: wineTypeColor }}
      >
        <img className={styles.foodImg} src={DefaultFoodImage} alt="food" />
        <img
          className={styles.varietalImg}
          src={
            save?.item?.itemType == 3
              ? save?.details?.wineImgUrl
              : profile?.wine_url
          }
          //src={wineBottlePlaceholder}
          alt="varietal bottle"
        />
      </div>
      <div className={`${styles.textBox}`}>
        <p className={`p1-reg`}>
          {save?.item?.itemType == 3
            ? save?.details?.wineName
            : save?.details?.varietal}
        </p>
        <p className={`p1-it`}>
          {save?.item?.itemType == 2 || save?.item?.itemType == 3
            ? save?.details?.foodName
            : save?.details?.searchValue}
        </p>
      </div>
    </div>
  );
}
