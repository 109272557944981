import React, { Component } from 'react';
import './MyEvents.scss';
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import EventCard from "../../components/EventCard/EventCard";

import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import emptyImage from "../../../assets/images/Events/events-empty-state.svg";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as GetMyEventsAction from "../../redux/actions/GetMyEventsAction";

class MyEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEventDesc: false,
      getMyEventDetailSuccess: "",
      getMyEventDetailFail: "",
      eventData: "",
      // userWineryStatus: "",
      fromMyEvents: true
    }
  }

  componentDidMount = () => {
    this.props.GetMyEventsAction.GetMyEvents();
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.getMyEventDetailSuccess) {
      return {
        eventData: nextProps.getMyEventDetailSuccess.results.rows,
        // userWineryStatus: nextProps.getMyEventDetailSuccess.results.rows,
      }
    }
    else {
      return null;
    }
  }

  gotoPreviousPage = () => {
    this.props.history.goBack();
  };

  openHomePage = () => {
    this.props.history.push("/discover");
  }

  renderEventCards = () => {
    let result = [];
    if (this.state.eventData.length > 0) {
      result.push(
        this.state.eventData.map((el, index) => (
          <EventCard
            wineryData={this.state.wineryData}
            eventData={this.state.eventData[index]}
            id={index}
            key={el.id}
            fromMyEvents={this.state.fromMyEvents}
          />
        ))
      );
    }
    return result;
  }

  renderEmptyState = () => {
    let result = [];
    result.push(
      <div className="empty-state-master-container-myevents" key={this.state.eventData.length}>
        <div className="empty-state-master-container" key={this.state.eventData.length}>
          <div className="empty-state-container-my-events">
            <img className="empty-state-image" src={emptyImage} alt="emptyStateImage" />
          </div>
          <div className="empty-state-text">
            {"No events added"}
          </div>
        </div>
      </div>
    );
    return result;
  }

  render() {
    let id = this.state.eventData.id;
    return (
      <div className={this.state.eventData.length > 0 ? "events-main-container-grey" : "events-main-container-white"} >
        <div className="accordian-container">
          <div className="common-page-header">
            <div className="common-page-sub-header new-common-header-change">
              <img
                alt="Open Recommendations page"
                className="common-sub-header-back-icon ptr-clss"
                onClick={() => { this.gotoPreviousPage() }}
                src={backBtn}
              />
              	<div className='new-header-text'>
                  <span className="common-sub-header-text new-search-wine-for-dish-text">
                  My Events
                  </span>
								</div>
              <img
                alt="PairAnything"
                className="common-sub-header-logo new-logo-header-design ml-0"
                src={pairAnythingLogo}
                onClick={() => { this.openHomePage() }}
              />
            </div>
          </div>
          {
            this.state.eventData ?
              this.state.eventData.length > 0 ?
                <div className="available-events-container column" key={id}>
                  {this.renderEventCards()}
                </div>
                :
                <div className="empty-state-main-container">
                  {this.renderEmptyState()}
                </div>
              :
              null
          }
          <div className="bottom-navigation shadow p-3 bg-white rounded">
            <BottomNavigation />
          </div>

        </div>
      </div>
    )
  }
}

// REDUX

function mapStateToProps(state, props) {
  return {
    getMyEventDetailSuccess: state.GetMyEventsReducer.getMyEventDetailSuccess,
    getMyEventDetailFail: state.GetMyEventsReducer.getMyEventDetailFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetMyEventsAction: bindActionCreators(GetMyEventsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyEvents);