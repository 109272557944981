import * as types from './action-types';
import * as constants from '../../constants/constants'


export const GetEvents = () => {

    let wineryId = (JSON.parse(localStorage.getItem('winery_id')));

    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;


    return dispatch => {
        fetch(constants.EVENT_API + '/api/v1/users/winery/' + wineryId + '/events', {

            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            },

        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.GET_EVENT_DETAIL_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.GET_EVENT_DETAIL_FAILED, payload: error })
        })
    }

}

export const GetEventsDummyApi = () => {
    return dispatch => {
        dispatch({ type: types.GET_EVENT_DETAIL_DUMMY, payload: undefined })
    }
}
