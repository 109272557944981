import React from "react";

export default function Edit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M5.91995 1.66016H2.09332C1.80336 1.66016 1.52526 1.77535 1.32023 1.98038C1.11519 2.18542 1 2.46351 1 2.75348V10.4067C1 10.6967 1.11519 10.9748 1.32023 11.1798C1.52526 11.3849 1.80336 11.5001 2.09332 11.5001H9.74658C10.0366 11.5001 10.3146 11.3849 10.5197 11.1798C10.7247 10.9748 10.8399 10.6967 10.8399 10.4067V6.58011"
        stroke="#951901"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0199 0.839652C10.2374 0.622176 10.5323 0.5 10.8399 0.5C11.1474 0.5 11.4424 0.622176 11.6599 0.839652C11.8774 1.05713 11.9995 1.35209 11.9995 1.65964C11.9995 1.9672 11.8774 2.26216 11.6599 2.47964L6.4666 7.67292L4.27995 8.21958L4.82661 6.03294L10.0199 0.839652Z"
        stroke="#951901"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
