import React, { Component } from "react";
import "./Chat.scss";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import backBtn from "../../../assets/images/back-btn.svg";
import backBtn from "../../../assets/images/left-arrow-white.svg";

import backArrowBtn from "../../../assets/images/swipe-left-arrow.png";

import { ChatService } from "./ChatService";

import Messages from "./Messages";
import Input from "./Input";

import * as constants from "../../constants/constants";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import emptyCardChatImage from "../../../assets/images/EmptyPageCard/chat-image.svg";
import { Observable } from "rxjs";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import PageNotFound from "../PageNotFound/PageNotFound";
import * as commonFunctions from "../../constants/commonFunctions";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import { connect } from "react-redux";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";

let chatServiceObject;
let consumerData;
// let consumerId = consumerData.id;

// let wineryId = ( JSON.parse(localStorage.getItem('winery_id') )  );
let wineryId;
// try {
//   wineryId = JSON.parse(localStorage.getItem("winery_id"));
// } catch (e) {
// }
// wineryId = 18;
class Chat extends Component {
  constructor(props) {
    super(props);
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    wineryId = JSON.parse(localStorage.getItem("winery_id"));
    this.state = {
      wineryId,
      sample: 0,
      // backBtnStatus: false,
      saveChangesBtnStatus: false,
      userInput: "",
      sendMessageStatus: false,
      getChatApiCallDone: false,
      getChatData: "",
      errorMessage: "",
      messages: [
        {
          text: "",
          userType: "",
          timestamp: "",
          member: {
            username: "",
          },

          roleId: "",
          id: "",
          userName: "",
          userId: "",
          roomId: "",
          wineryId: "",
        },
      ],
      member: {
        userType: "",
      },

      recievedMessage: [],
      buyNowMessageData: "",
      networkConnection: "",
      socketConnection: "",
    };
  }

  componentDidUpdate = () => {};

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData.error,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  updateStatusOfRoom = () => {
    let roomId = consumerData.id + "_" + wineryId;

    let updateRoomStatusApi =
      constants.CHAT_API +
      "/api/v1/chat/updateStatusOfRoom/" +
      roomId +
      "?isCustomer=" +
      1;

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    fetch(updateRoomStatusApi, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: updateRoomStatusApi,
            operation: "PUT",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state
      })
      .catch((error) => console.error(error));
  };

  componentDidMount = () => {
    let wineryId = localStorage.getItem("winery_id");

    let wineryIdFromUrl = this.props.match.params.wineryId;

    let wineryList = JSON.parse(localStorage.getItem("winery_list"));

    // let wineryIdValidity = 0;
    // for (let i = 0; i < wineryList.length; ++i) {
    //   if (
    //     wineryIdFromUrl == wineryList[i].wineryId
    //     // &&
    //     // wineryList[i].wineryUserStatus == 2
    //   ) {
    //     // wineruuserstatus is enabled
    //     wineryIdValidity++;
    //   }
    // }

    // if (wineryIdValidity != 0) {
    //   // valid winery ID URL param
    //   // user might have clicked on notif because for some other winery
    //   let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    //   if (wineryIdFromUrl == wineryId) {
    //   } else {
    //     localStorage.setItem("winery_id", wineryIdFromUrl);
    //     window.location.reload();
    //   }
    // }

    // //
    // let consumerWineryStatus = localStorage.getItem("user_winery_status");
    // let wineryName = localStorage.getItem("winery_name");

    // if (wineryIdValidity == 0) {
    //   // invalid Winery Id in the URL. User might have tried to change URL
    //   this.setState({
    //     errorMessage: "You do not have access to this page.",
    //   });
    // } else if (
    //   consumerWineryStatus != null &&
    //   consumerWineryStatus != undefined &&
    //   consumerWineryStatus == "disabled"
    // ) {
    //   this.setState({
    //     errorMessage:
    //       "Your email is no longer associated with " +
    //       wineryName +
    //       " winery, please contact the winery to gain access again.",
    //   });
    // } else {
    if (this.props.wineryDetails != null) {
      this.setState({
        wineryId: this.props.wineryDetails.id,
      });
      // }
      try {
        let roomObject;
        roomObject = {
          // userId : consumerData.id,
          // wineryId : wineryId,
          roomId: consumerData.id + "_" + wineryId,
        };
        this.getChat();
        // console.log('Initial Chat service obj', chatServiceObject);
        chatServiceObject = new ChatService();
        // console.log(chatServiceObject, roomObject);
        chatServiceObject.createConnection(roomObject);
        // chatServiceObject.disconnect(roomObject);

        chatServiceObject.getConnectionStatus().subscribe((status) => {
          // console.log(status)
          if (status == "disconnected") {
            // chatServiceObject = new ChatService();
            // chatServiceObject.connectSocket();
            this.setState({
              socketConnection: status,
            });
            // console.log('get connct stats', status, this.state.socketConnection);
            // chatServiceObject.createConnection(roomObject);

            let apiErrorObj = {
              statusText: "chat",
              type: "timeout",
              url: "/chat",
              operation: "timeout",
              status: "408",
              error: "chat has been disconnected",
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            // this.setState({
            // 	sample: 1
            // })
          } else if (status == "connected") {
            // If status is read then notificationCount should be 0
            setTimeout(() => {
              this.setState({
                socketConnection: status,
              });
              // console.log('get connct stats', status, this.state.socketConnection);
              this.getChatAfterReconnected();
              chatServiceObject.createConnection(roomObject);
              this.updateStatusOfRoom();
            }, 2000);
          }
        });

        chatServiceObject.getMessages().subscribe((message) => {
          // this.messages.push(message);

          // this.sendMessages()
          // let messageArray = [];
          // messageArray.push(message.message);
          if (message.roomId === roomObject.roomId) {
            let isUserActive = false;
            if (message.userWineryStatus) {
              if (message.userWineryStatus == "disabled") {
                isUserActive = false;
              } else {
                isUserActive = true;
              }
            } else {
              isUserActive = true;
            }
            if (isUserActive) {
              let messages = this.state.messages;

              if (
                message.userType == "superadminowner" ||
                message.userType == "wineryowneradmin"
              ) {
                messages.push({
                  text: message.text,
                  userType: message.userType,
                  timestamp: message.timestamp,
                  member: this.state.member,
                });
              } else if (message.userType == "customer") {
                messages.push({
                  text: message.text,
                  userType: message.userType,
                  timestamp: message.timestamp,
                  member: this.state.member,
                  roleId: message.roleId,
                  id: message.id,
                  userName: message.userName,
                  userId: message.userId,
                  roomId: message.roomId,
                  wineryId: message.wineryId,
                });
              }
              this.setState({
                messages: messages,
                sendMessageStatus: true,
              });
            } else {
              window.location.reload(false);
            }
          }
        });

        // let initialMessageObject = [];
        // if (this.state.errorMessage == "") {
        //   initialMessageObject.push({
        //     text:
        //       "Hi " +
        //       commonFunctions.capitalize(consumerData.firstName) +
        //       "! Please let us know how we can help you ",
        //     userType: "superadminowner",
        //     // timestamp: message.timeStamp,
        //     // member: this.state.member
        //   });
        // }
        // this.setState({
        //   messages: initialMessageObject,
        // });
        // this.mapGetChatData();

        // }

        // if(this.state.getChatApiCallDone && this.state.buyNowMessageData!="") {

        // let message = "I would like to buy:: " + this.state.buyNowMessageData.wineName;
        // this.onSendMessage(message)
      } catch (e) {
        if (e) {
          // this.postCaughtException(e);
        }
        // window.location.reload();
      }
      GoogleAnalyticsFunctions.logPagesVisitedByUser();
    }

    // Checking network connectivity
    const self = this; //  this should not be double quoted;
    window.addEventListener("offline", function (e) {
      self.setState({
        networkConnection: "offline",
      });
      // console.log(self.state.networkConnection);
    });
    window.addEventListener("online", function (e) {
      self.setState({
        networkConnection: "online",
      });
      // console.log(self.state.networkConnection);
    });
  };

  onCheckInternet = () => {};

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  getChat = () => {
    try {
      let wineryIdForGetChat = localStorage.getItem("winery_id");
      let wineryName = localStorage.getItem("winery_name");
      let roomId = consumerData.id + "_" + wineryIdForGetChat;
      let getDefaultApiUrl =
        constants.CHAT_API + "/api/v1/chat/getChat?roomId=" + roomId;
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      fetch(getDefaultApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getDefaultApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          if (json.userWineryStatus == "disabled") {
            this.setState({
              errorMessage:
                "Your email is no longer associated with " +
                wineryName +
                " winery, please contact the winery to gain access again.",
            });
          } else {
            this.setState({
              getChatData: json,
              getChatApiCallDone: true,
            });
            this.mapGetChatData();
          }
        })
        .catch((error) => {});
    } catch (e) {}
  };

  mapGetChatData = () => {
    let initialMessageObject = [];
    if (this.state.errorMessage == "") {
      initialMessageObject.push({
        text:
          "Hi " +
          commonFunctions.capitalize(consumerData.firstName) +
          "! Please let us know how we can help you ",
        userType: "superadminowner",
        // timestamp: message.timeStamp,
        // member: this.state.member
      });
    }
    this.setState({
      messages: initialMessageObject,
    });

    for (let i = 0; i < this.state.getChatData.Item.length; ++i) {
      let messages = this.state.messages;

      messages.push({
        text: this.state.getChatData.Item[i].message.text,
        userType: this.state.getChatData.Item[i].message.userType,
        timestamp: this.state.getChatData.Item[i].message.timestamp,

        // below data is needed for the POST response
        roleId: this.state.getChatData.Item[i].message.roleId,
        id: this.state.getChatData.Item[i].message.id,
        userName: this.state.getChatData.Item[i].message.userName,
        userId: this.state.getChatData.Item[i].message.userId,
        roomId: this.state.getChatData.Item[i].message.roomId,
        wineryId: this.state.getChatData.Item[i].message.wineryId,

        member: this.state.member,
      });

      this.setState({
        messages: messages,
      });

      // }
    }

    if (this.state.getChatApiCallDone && this.state.buyNowMessageData != "") {
      if (this.state.buyNowMessageData.wineName != "") {
        let message =
          "I am interested in " + this.state.buyNowMessageData.wineName;
        // +
        // 			  " ( " + this.state.buyNowMessageData.wineType +" wine )";

        this.onSendMessage(message);
        this.setState({
          buyNowMessageData: "",
        });
      }
    }
  };

  getChatAfterReconnected = () => {
    try {
      let wineryIdForGetChat = localStorage.getItem("winery_id");
      let wineryName = localStorage.getItem("winery_name");
      let roomId = consumerData.id + "_" + wineryIdForGetChat;
      let getDefaultApiUrl =
        constants.CHAT_API + "/api/v1/chat/getChat?roomId=" + roomId;
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      fetch(getDefaultApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getDefaultApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          if (json.userWineryStatus == "disabled") {
            this.setState({
              errorMessage:
                "Your email is no longer associated with " +
                wineryName +
                " winery, please contact the winery to gain access again.",
            });
          } else {
            this.setState({
              getChatData: json,
              getChatApiCallDone: true,
            });
          }
        })
        .catch((error) => {});
    } catch (e) {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    try {
      if (
        nextProps.location.state.wineIndividualData != undefined &&
        nextProps.location.state.wineIndividualData != null
      ) {
        // if(nextProps.location.state.wineIndividualData && nextProps.location.state.wineIndividualData!=undefined && nextProps.location.state.wineIndividualData!=null ) {

        return {
          buyNowMessageData: nextProps.location.state.wineIndividualData,
        };
      }
    } catch (e) {
      // this.postCaughtException(e);

      return null;
    }

    return null;
  }

  // input component
  onSendMessage = (message) => {
    let wineryIdForSendingMessage = localStorage.getItem("winery_id");

    var d = new Date();
    var timeStamp = d.toISOString();

    // const messages = this.state.messages;

    // messages.push({
    //   text: message,
    //   timestamp: timeStamp,
    //   userType: "customer",

    //   roleId: consumerData.role.id,
    //   id: consumerData.id + "_" + wineryIdForSendingMessage,
    //   userName: consumerData.firstName,
    //   userId: consumerData.id,
    //   roomId: consumerData.id + "_" + wineryIdForSendingMessage,
    //   wineryId: wineryIdForSendingMessage,

    //   member: this.state.member,
    // });
    this.setState({
      // messages: messages,
      sendMessageStatus: true,
    });
    let messageObject = {
      text: message,
      userType: "customer",
      timestamp: timeStamp,
      roleId: consumerData.role.id,
      id: consumerData.id + "_" + wineryIdForSendingMessage,
      userName: consumerData.firstName,
      userId: consumerData.id,
      roomId: consumerData.id + "_" + wineryIdForSendingMessage,
      wineryId: wineryIdForSendingMessage,
    };
    chatServiceObject.sendMessage(messageObject);
  };

  goBack = () => {
    // this.setState({
    //   backBtnStatus: true,
    // });
    // if (this.props.history.length > 1) {
    //   this.props.history.goBack();
    // } else {
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    // let url = "/search-wine" + "/" + this.state.wineryId;
    let url =
      "/" +
      commonFunctions.convertToCamelCase(localStorage.getItem("winery_name")) +
      "/pairings";
    this.props.history.push(url);
    // }
  };

  sendMessages = () => {
    // let result = [];
    if (this.state.messages.text != "") {
      return <Messages messages={this.state.messages} />;
    } else {
      return [];
    }
  };
  renderEmptyPageCardIfChatIsEmpty = () => {
    let result = [];
    let emptyCardData = {
      title: "No message, yet!",
      text: "Start Chatting with winery.",
      image: emptyCardChatImage,
    };
    // this.state.messages.length == 1 - since first message obj is ""

    if (this.state.errorMessage != "") {
      result.push(
        <div
          className={
            this.state.errorMessage == "You do not have access to this page."
              ? ""
              : "empty-page-main-container"
          }
        >
          {this.state.errorMessage == "You do not have access to this page." ? (
            <span
              className={
                this.state.errorMessage ==
                "You do not have access to this page."
                  ? ""
                  : "recommendation-input-error-message chat-error-message-container mt-0"
              }
            >
              <PageNotFound></PageNotFound>
            </span>
          ) : (
            !this.state.errorMessage.startsWith("Your email is no longer") && (
              <span
                className={
                  this.state.errorMessage ==
                  "You do not have access to this page."
                    ? ""
                    : "recommendation-input-error-message chat-error-message-container mt-0"
                }
              >
                {this.state.errorMessage}
              </span>
            )
          )}

          {this.state.errorMessage.startsWith("Your email is no longer") && (
            <div className="recommendation-history-main-container width-88">
              <div className="error-boundary-main-container mt-5">
                <span className="error-boundary-title">
                  {this.state.errorMessage}
                </span>
                <div className="error-boundary-image-container">
                  <img
                    alt="Something went wrong"
                    className="error-boundary-image"
                    src={errorBoundaryImage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (
      this.state.messages.length == 0 &&
      this.state.getChatApiCallDone
    ) {
      result.push(
        <div className="empty-page-main-container mt-0">
          <EmptyPageCard
            emptyCardFromChat={true}
            emptyPageCardData={emptyCardData}
          />
        </div>
      );
    } else {
    }
    return result;
  };

  /*
     let emptyCardData = {
            title: "No message, yet!",
            text: "Start Chatting with winery.",
            image: emptyCardChatImage
          }
        result.push(

          <div className = "empty-page-main-container">
        
                      <EmptyPageCard emptyPageCardData = { emptyCardData } />
           
                    </div>


        );
     */

  componentDidUpdate(prevProps, prevStates) {
    // if (this.state.backBtnStatus) {
    //   this.props.history.goBack();
    //   try {
    //     if (
    //       this.props.location.state.chatFromAvailableWinesPage != null &&
    //       this.props.location.state.chatFromAvailableWinesPage != undefined &&
    //       this.props.location.state.chatFromAvailableWinesPage == true
    //     ) {
    //       // return <Redirect push to="/available-wines" />;
    //       this.props.history.push("/available-wines");
    //     } else if (
    //       this.props.location.state.chatFromIndividualWinesPage != null
    //     ) {
    //       if (
    //         this.props.location.state.chatFromIndividualWinesPage ==
    //         "/available-wines"
    //       ) {
    //         // return <Redirect push to="/available-wines" />;
    //         this.props.history.push("/available-wines");
    //       }
    //       // else if(this.props.location.state.chatFromIndividualWinesPage =="/search-wine" ) {
    //       else if (
    //         this.props.location.state.chatFromIndividualWinesPage.substring(
    //           0,
    //           11
    //         ) == "/search-wine"
    //       ) {

    //         let url = "/search-wine" + "/" + this.state.wineryId;
    //         // return <Redirect push to={url} />;
    //         this.props.history.push("/search-wine" + "/" + this.state.wineryId);
    //       }
    //     } else if (this.props.location.state.chatFromProfilePage) {
    //       // return (
    //       //   <Redirect
    //       //     to={{
    //       //       pathname: "/profile",
    //       //       state: {},
    //       //     }}
    //       //   />
    //       // );
    //       this.props.history.push({
    //         pathname: "/profile",
    //         state: {},
    //       });
    //     }
    //     let url = "/search-wine" + "/" + this.state.wineryId;
    //     // return <Redirect push to={url} />;
    //     this.props.history.push(url);
    //   } catch (e) {
    //     if (e) {
    //       // this.postCaughtException(e);
    //     }

    //     let url = "/search-wine" + "/" + this.state.wineryId;
    //     // return <Redirect push to={url} />;
    //     this.props.history.push(url);
    //   }
    // }
    if (this.state.saveChangesBtnStatus) {
      // return <Redirect push to="/profile" />;
      this.props.history.push("/profile");
    }
  }
  pageRefresh = () => {
    window.location.reload();
  };

  renderConnectionError = () => {
    if (
      this.state.networkConnection == "offline" ||
      this.state.socketConnection == "disconnected"
    ) {
      return (
        <div className="socket-network-error-div">
          <div className="socket-network-error">
            Can't connect to server!{" "}
            <a
              className="refresh-now"
              href="javascript:void(0)"
              onClick={this.pageRefresh}
            >
              {" "}
              Refresh now
            </a>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div
            className="success-main-container"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <div className=" success-inner-container  new-discover-inner-container">
              <HeaderWithBurgerIcon
                title="Chat"
                arrowIcon={true}
                goBackFunc={() => this.props.history.goBack()}
              />
              <div
                className={isMobile ? null : "desktop-div-scroll "}
                style={{ backgroundColor: "#f2f2f2" }}
              >
                <div id="conversations" className="conversations">
                  {/* <div className="message-header">
								<div className="message-title">
									<a className="btn btn-light" href="messages.html">
										<i className="fa fa-flip-horizontal fa-share"></i>
									</a>
									<div className="user ml-2">
										<div className="avatar avatar-offline">
											<img src= {logo} alt="..." className="avatar-img rounded-circle border border-white" />
										</div>
										<div className="info-user ml-2">
											<span className="name">Winery Name</span>
											<span className="last-active">Active 2h ago</span>
										</div>
									</div>
									<div className="ml-auto">
										<button className="btn btn-light">
											<i className="fa fa-user-plus"></i>
										</button>
										<button className="btn btn-light page-sidebar-toggler d-xl-none">
											<i className="fa fa-angle-double-left"></i>
										</button>
									</div>
								</div>
							</div> */}

                  {this.renderEmptyPageCardIfChatIsEmpty()}
                  {this.renderConnectionError()}
                  <div className="conversations-body">
                    <div
                      id="conversations-content"
                      className="conversations-content"
                    >
                      {this.sendMessages()}
                    </div>
                  </div>
                </div>

                {this.state.getChatApiCallDone == true &&
                  this.state.errorMessage == "" && (
                    <div className="bottom-nav-induvidual-wines   shadow bg-white rounded">
                      <Input onSendMessage={this.onSendMessage} />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    if (chatServiceObject) {
      chatServiceObject.disconnect();
    }
  }
}
const mapStateToProps = (state) => {
  return { wineryDetails: state.WineryReducer.currentWineryDetails };
};
export default connect(mapStateToProps, null)(Chat);
