import React, { Component } from "react";
import "./EditTastePreference.scss";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as EditTastePreferenceAction from "../../redux/actions/EditTastePreferenceAction";

import * as constants from "../../constants/constants";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditTastePreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTastePreferenceMasterJson: this.props.selectedTastePreferenceJson,
      isChanged: false,
    };
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  handleChange = (i, j) => {
    let tastePreference =
      this.state.selectedTastePreferenceMasterJson.taste_preference;
    let selected = tastePreference[i].options[j].value;

    if (i === 2) {
      if (selected === 0) {
        for (let loop = 0; loop < 4; loop++) {
          this.state.selectedTastePreferenceMasterJson.taste_preference[
            i
          ].options[loop].value = 0;
        }
        this.state.selectedTastePreferenceMasterJson.taste_preference[
          i
        ].options[j].value = 1;
        this.setState({
          isChanged: true,
        });
      }
      if (selected === 1) {
        this.setState({
          isChanged: true,
        });
      }
    } else if (i === 1) {
      if (selected === 0) {
        for (let loop = 0; loop < 2; loop++) {
          this.state.selectedTastePreferenceMasterJson.taste_preference[
            i
          ].options[loop].value = 0;
        }
        this.state.selectedTastePreferenceMasterJson.taste_preference[
          i
        ].options[j].value = 1;
        this.setState({
          isChanged: true,
        });
      }
      if (selected === 1) {
        this.setState({
          isChanged: true,
        });
      }
    } else if (i === 0) {
      if (selected === 1) {
        this.state.selectedTastePreferenceMasterJson.taste_preference[
          i
        ].options[j].value = 0;
        this.setState({
          isChanged: !this.state.isChanged,
        });
      }
      if (selected === 0) {
        this.state.selectedTastePreferenceMasterJson.taste_preference[
          i
        ].options[j].value = 1;
        this.setState({
          isChanged: !this.state.isChanged,
        });
      }
    }
  };

  renderWineType = () => {
    let tastePreference =
      this.state.selectedTastePreferenceMasterJson.taste_preference;
    let result = [];
    let i = 0;
    if (tastePreference[i].category === "wine_type") {
      for (let j = 0; j < tastePreference[i].options.length; ++j) {
        let options = tastePreference[i].options;
        let selected = tastePreference[i].options[j].value;
        let resultName = options[j].name;
        resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
        result.push(
          <div className="result-container" key={resultName}>
            <button
              key={resultName}
              type="button"
              onClick={() => {
                this.handleChange(i, j);
              }}
              className={
                selected === 1
                  ? "btn btn-round results-selected"
                  : "btn btn-round results-not-selected"
              }
            >
              {resultName}
            </button>
          </div>
        );
      }
    }
    return result;
  };
  renderWineCharacteristics = () => {
    let tastePreference =
      this.state.selectedTastePreferenceMasterJson.taste_preference;
    let result = [];
    let i = 1;
    if (tastePreference[i].category === "wine_characteristics") {
      for (let j = 0; j < tastePreference[i].options.length; ++j) {
        let options = tastePreference[i].options;
        let selected = tastePreference[i].options[j].value;
        let resultName = options[j].name;
        resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
        result.push(
          <div className="result-container" key={resultName}>
            <button
              key={resultName}
              type="button"
              onClick={() => {
                this.handleChange(i, j);
              }}
              className={
                selected === 1
                  ? "btn btn-round results-selected"
                  : "btn btn-round results-not-selected"
              }
            >
              {resultName}
            </button>
          </div>
        );
      }
    }
    return result;
  };
  renderWineKnowledge = () => {
    let tastePreference =
      this.state.selectedTastePreferenceMasterJson.taste_preference;
    let result = [];
    let i = 2;
    if (tastePreference[i].category === "wine_knowledge") {
      for (let j = 0; j < tastePreference[i].options.length; ++j) {
        let options = tastePreference[i].options;
        let selected = tastePreference[i].options[j].value;
        let resultName = options[j].name;
        resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
        result.push(
          <div className="result-container" key={resultName}>
            <button
              key={resultName}
              type="button"
              onClick={() => {
                this.handleChange(i, j);
              }}
              className={
                selected === 1
                  ? "btn btn-round results-selected"
                  : "btn btn-round results-not-selected"
              }
            >
              {resultName}
            </button>
          </div>
        );
      }
    }
    return result;
  };

  handleSave = () => {
    this.props.EditTastePreferenceAction.TastePreferenceUpdateDetails({
      preference: this.state.selectedTastePreferenceMasterJson,
    });
    toast.info("Successfully updated your Taste Preference", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    this.props.handleEditedData();
  };

  render() {
    return (
      <div>
        <div className="edit-taste-pref-card-container">
          <div className="card card-post card-round edit-wine-type-card shadow p-3 mb-5 bg-white rounded">
            <div className="card-header">Wine Type</div>
            <div className="display-results-row">
              {this.state.selectedTastePreferenceMasterJson
                ? this.renderWineType()
                : null}
            </div>
          </div>
          <div className="card card-post card-round edit-wine-type-card shadow p-3 mb-5 bg-white rounded">
            <div className="card-header">Wine Characteristics</div>
            <div className="display-results-row">
              {this.state.selectedTastePreferenceMasterJson
                ? this.renderWineCharacteristics()
                : null}
            </div>
          </div>
          <div className="card card-post card-round edit-wine-type-card shadow p-3 mb-5 bg-white rounded">
            <div className="card-header">Wine Knowledge</div>
            <div className="display-results-row">
              {this.state.selectedTastePreferenceMasterJson
                ? this.renderWineKnowledge()
                : null}
            </div>
          </div>
        </div>
        <div className="btn-grp">
          <div className="save-btn-container">
            <button
              type="button"
              className="btn btn-round cancel-btn"
              onClick={() => {
                this.props.handleClick();
              }}
            >
              Cancel
            </button>
          </div>
          <div className="save-btn-container">
            <button
              type="button"
              onClick={() => {
                this.handleSave();
              }}
              className="btn btn-round save-btn"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

//Redux

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    EditTastePreferenceAction: bindActionCreators(
      EditTastePreferenceAction,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTastePreference);
