export const ELDORADO_COUNTY_WINERIES = [
    {
        id: 5,
        wineName: 'C.G. Di Arie Vineyards & Winery',
        winerUrl: 'https://www.cgdiarie.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/C_G_Di_Arie.jpg')
    },
    {
        id: 1,
        wineName: 'Boeger Winery',
        winerUrl: 'https://www.boegerwinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Boeger.PNG')
    },
    {
        id: 2,
        wineName: 'Bom Vinho! Vineyard',
        winerUrl: 'http://www.bomvinhovineyard.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Bom_Vinho.png')
    },
    {
        id: 3,
        wineName: 'Bumgarner Winery & Vineyard',
        winerUrl: 'https://www.bumgarnerwinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Bumgarner.jpeg')
    },
    {
        id: 4,
        wineName: 'Busby Cellars',
        winerUrl: 'http://busbycellars.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Busby_Cellars.png')
    },
    
    {
        id: 6,
        wineName: 'Cantiga Wineworks',
        winerUrl: 'https://www.cantigawine.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Cantiga_Wineworks.jpg')
    },
    {
        id: 7,
        wineName: 'Cedar Ville Vineyard',
        winerUrl: 'https://www.cedarvillevineyard.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Cedar_Ville.png')
    },
    {
        id: 8,
        wineName: 'Chateau Davell',
        winerUrl: 'http://chateaudavell.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Chateau_Davell.png')
    },
    {
        id: 9,
        wineName: 'Crystal Basin Cellars',
        winerUrl: 'https://crystalbasin.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Crystal-Basin-Cellars-1.jpg')
    },
    {
        id: 10,
        wineName: 'David Girard Vineyards',
        winerUrl: 'https://davidgirardvineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/David_Girard.png')
    },
    {
        id: 11,
        wineName: 'diVittorio Winery',
        winerUrl: 'https://www.divittoriowinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/diVittorio.jpg')
    },
    {
        id: 12,
        wineName: 'E16 Winery',
        winerUrl: 'https://www.e16winery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/E16.jpg')
    },
    {
        id: 13,
        wineName: 'Element 79 Vineyards',
        winerUrl: 'https://www.element79vineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Element_79.jpg')
    },
    {
        id: 14,
        wineName: 'Ever Hart Cellars & Hart 2 Hart Vineyards',
        winerUrl: 'https://www.everhartcellars.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Everhart.jpg')
    },
    {
        id: 15,
        wineName: 'Fenton Herriott Vineyards',
        winerUrl: 'https://www.fentonherriott.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Fenton_Herriott.jpg')
    },
    {
        id: 16,
        wineName: 'Findleton Estate Winery',
        winerUrl: 'https://finzinwines.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Findleton_Estate_Winery.jpg')
    },
    {
        id: 17,
        wineName: 'Gold Hill Vineyard & Brewery',
        winerUrl: 'http://www.goldhillvineyard.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Gold_Hill_Vineyard_Brewery.png')
    },
    {
        id: 18,
        wineName: 'Gwinllan Estate Vineyard and Winery',
        winerUrl: 'https://www.gwinllanestate.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Gwinllan_Estate_Vineyard_and_Winery.jpg')
    },
    {
        id: 19,
        wineName: 'Holly’s Hill Vineyards',
        winerUrl: 'http://www.hollyshill.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Holly’s_Hill_Vineyards.png')
    },
    {
        id: 20,
        wineName: 'Jodar Vineyards & Winery',
        winerUrl: 'https://www.jodarwinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Jodar.jpg')
    },
    {
        id: 21,
        wineName: 'Kehret Vineyards',
        winerUrl: 'https://www.kehretvineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Kehret_Vineyards.jpg')
    },
    {
        id: 22,
        wineName: 'Lava Cap Winery',
        winerUrl: 'http://www.lavacap.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Lava_Cap.jpg')
    },
    {
        id: 23,
        wineName: 'Lewis Grace Winery',
        winerUrl: 'https://gracepatriotwines.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Lewis_Grace_Winery.png')
    },
    {
        id: 24,
        wineName: 'Madroña Vineyards',
        winerUrl: 'https://madronavineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Madroña_Vineyards.png')
    },
    {
        id: 25,
        wineName: 'Mediterranean Vineyards',
        winerUrl: 'https://www.medivineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Mediterranean_Vineyards.png')
    },
    {
        id: 26,
        wineName: 'Miraflores Winery',
        winerUrl: 'https://www.mirafloreswinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Miraflores_Winery.png')
    },
    {
        id: 27,
        wineName: 'Narrow Gate Vineyards',
        winerUrl: 'https://narrowgatevineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Narrow_Gate_Vineyards.png')
    },
    {
        id: 28,
        wineName: 'Nello Olivo Winery',
        winerUrl: 'https://nelloolivo.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Nello_Olivo_Winery.png')
    },
    {
        id: 29,
        wineName: 'Oakstone Winery',
        winerUrl: 'https://oakstone-winery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Oakstone_Winery.jpg')
    },
    {
        id: 31,
        wineName: 'Saluti Cellars',
        winerUrl: 'https://saluticellars.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Saluti_Cellars.jpg')
    },
    {
        id: 32,
        wineName: 'Sentivo Vineyards & Winery',
        winerUrl: 'https://www.sentivovineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Sentivo_Vineyards_Winery.png')
    },
    {
        id: 33,
        wineName: 'Shadow Ranch Vineyard',
        winerUrl: 'https://www.shadowranch.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Shadow_Ranch_Vineyard.png')
    },
    {
        id: 34,
        wineName: 'Sierra Vista Vineyards & Winery',
        winerUrl: 'https://www.sierravistawinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Sierra_Vista_Vineyards_Winery.jpg')
    },
    {
        id: 35,
        wineName: 'Skinner Vineyards',
        winerUrl: 'https://www.skinnervineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Skinner.PNG')
    },
    {
        id: 36,
        wineName: 'Smokey Ridge Ranch',
        winerUrl: 'https://smokeyridgeranch.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Smokey_Ridge_Ranch.png')
    },
    {
        id: 37,
        wineName: 'Starfield Vineyards',
        winerUrl: 'https://www.starfieldvineyards.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Starfield_Vineyards.png')
    },
    {
        id: 38,
        wineName: 'Toogood Estate Winery',
        winerUrl: 'https://www.toogoodwinery.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Toogood_Estate.png')
    },
    {
        id: 39,
        wineName: 'Via Romano Vineyards',
        winerUrl: 'https://www.vrv.vin/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Via_Romano.svg')
    },
    {
        id: 40,
        wineName: 'Windwalker Vineyard',
        winerUrl: 'http://windwalkervineyard.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Windwalker_Vineyard.png')
    },
    {
        id: 30,
        wineName: 'Rucksack Cellars',
        winerUrl: 'https://rucksackcellars.com/',
        wineImage: require('../../../assets/images/Visit/El_Dorado_County/Rucksack_Cellars.jpg')
    }
]