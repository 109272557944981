import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
      case types.USER_SIGNIN_DUMMY:
        return {
          ...state,
          signinSuccess: action.payload,
        };
      case types.USER_SIGNIN_SUCCEEDED:
        return {
          ...state,
          signinSuccess: action.payload,
        };

      case types.USER_SIGNIN_FAILED:
        return {
          ...state,
          signinFail: action.payload,
        };
      case types.NEW_USER_SIGN_IN:
        return {
          ...state,
          showGetStarted: true,
          signinSuccess: action.payload,
        };
      case types.TOGGLE_SHOW_GET_STARTED:
        return {
          ...state,
          showGetStarted: !state.showGetStarted || false,
        };
      case types.CLOSE_SHOW_GET_STARTED:
        return {
          ...state,
          showGetStarted: false,
        };
      default:
        return state;
    }
};
