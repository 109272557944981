import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.GET_MY_EVENT_DETAIL_SUCCESS:
            return {
                ...state,
                getMyEventDetailSuccess: action.payload
            };

        case types.GET_MY_EVENT_DETAIL_FAILED:
            return {
                ...state,
                getMyEventDetailFail: action.payload
            };

        default:
            return state;
    }
};