import React from "react";
import "./WineHistory.scss";
import "./PARecommendation.scss";

import bottle from "../../../assets/images/individual-wine-bottle.png";

class WineHistory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  findWineImage(varietalName, varietalProfiles) {
    return varietalProfiles[varietalName.trimEnd()]?.wine_url;
  }
  render() {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    const {
      props: { recommendationQueryData },
    } = this;
    const pictureUrls = this.props.pictureUrl;
    let imgUrl = this.findWineImage(
      recommendationQueryData.varietalName,
      pictureUrls
    );
    if (imgUrl == bottle) {
      imgUrl = this.findWineImage(
        recommendationQueryData.wineType,
        pictureUrls
      );
    }
    return this.props.apiCallDone ? (
      <div className="form-group hist-container">
        <div className="wine-history-picture-frame">
          <img className="wine-history-picture" src={imgUrl}></img>
        </div>
        {recommendationQueryData &&
        recommendationQueryData.recommendation !== undefined ? (
          <div className="name">
            {recommendationQueryData &&
              recommendationQueryData.question &&
              recommendationQueryData.question}
          </div>
        ) : (
          <div
            onClick={this.props.wineClick}
            data-reco={recommendationQueryData.foodName}
            data-url={recommendationQueryData.recipeUrl}
            data-image-url={imgUrl}
            data-name={
              recommendationQueryData.wineType +
              ", " +
              recommendationQueryData.varietalName
            }
            className="name"
          >
            {recommendationQueryData.wineType},{" "}
            {recommendationQueryData.varietalName}
          </div>
        )}
      </div>
    ) : (
      ""
    );
  }
}

export default WineHistory;
