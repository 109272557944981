import React, { Component } from "react";
import "./ShippingAddressFormComponent.scss";
import backBtn from "../../../assets/images/back-btn.svg";
import Button from "@material-ui/core/Button";
import ShoppingCartHeader from "../../components/ShoppingCartHeader/ShoppingCartHeader";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import * as constants from "../../constants/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as commonFunctions from "../../constants/commonFunctions";
import axios from "axios";

toast.configure();

let consumerData;
let wineryId;
class ShippingAddressFormComponent extends Component {
  constructor(props) {
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    wineryId = JSON.parse(localStorage.getItem("winery_id"));
    super(props);
    this.state = {
      zipCode: "",
      addressFormError: "",
      zipcodeformError: "",
      addressLine1formError: "",
      cityformError: "",
      stateformError: "",
      saveAsformError: "",
      stateDetailsArray: [],
      shippingAddressForm: {
        id: this.props.addressDetails.id ? this.props.addressDetails.id : "",
        address1: this.props.addressDetails.address1
          ? this.props.addressDetails.address1
          : "",
        address2: this.props.addressDetails.address2
          ? this.props.addressDetails.address2
          : "",
        city: this.props.addressDetails.city
          ? this.props.addressDetails.city
          : "",
        stateId: this.props.addressDetails.stateId
          ? this.props.addressDetails.stateId
          : "",
        zipcode: this.props.addressDetails.zipcode
          ? this.props.addressDetails.zipcode
          : "",
        name: this.props.addressDetails.name // name of the address states as name
          ? this.props.addressDetails.name
          : "",
        isPrimary: this.props.addressDetails.isPrimary
          ? this.props.addressDetails.isPrimary
          : false,
      },
    };
  }

  getCardHeaderClassNames = () => {
    let classes = "card shadow shipping-address-card margin-change";
    if (this.props.fromShoppingCartShippingAddressPage) {
      classes = "card shadow shipping-address-card margin-align-for-shpng-cart";
    }
    return classes;
  };

  handleAllFieldBlur = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (value) {
      value = value.trim();
      this.setState((prevState) => ({
        shippingAddressForm: {
          // object that we are going to update
          ...prevState.shippingAddressForm, // retains all other state key-value data
          [name]: value, // update value of specific key
        },
      }));
    }
  };

  handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;

    if (name == "zipcode" && this.state.zipcodeFormError != "") {
      this.setState({
        zipcodeFormError: "",
      });
    }

    if (name == "name" && this.state.saveAsformError != "") {
      this.setState({
        saveAsformError: "",
      });
    }
    if (name == "address1" && this.state.addressLine1formError != "") {
      this.setState({
        addressLine1formError: "",
      });
    }
    if (name == "city" && this.state.cityformError != "") {
      this.setState({
        cityformError: "",
      });
    }
    if (name == "stateId" && this.state.stateformError != "") {
      this.setState({
        stateformError: "",
      });
    }

    this.setState((prevState) => ({
      addressFormError: "",
      shippingAddressForm: {
        // object that we are going to update
        ...prevState.shippingAddressForm, // retains all other state key-value data
        [name]: val, // update value of specific key
      },
    }));
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllStates();
  }

  getAllStates = () => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    try {
      let getAllStatesAPIUrl = constants.IDENTITY_API + "/api/v1/states";
      fetch(getAllStatesAPIUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getAllStatesAPIUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          // console.log(json);
          this.setState({
            stateDetailsArray: json.rows,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      // console.log(e);
    }
  };

  render() {
    let optionTemplate = this.state.stateDetailsArray.map((v, i) => (
      <option key={i} value={v.id}>
        {v.name + " - " + v.abbreviation}
      </option>
    ));
    // console.log(optionTemplate);
    /* NOTE: This form component will be shown from personal info and shopping cart shipping address page.
             It should be like popup view. But we are using hide and show method for this component. 
             To bring a popup view we are adding shopping cart address page and personal info headers manually
             using same css. and dynamically enabling/disabling the back btn functionality also some css change */
    return (
      <React.Fragment>
        <div className="shipping-address-main-container">
          {this.props.fromShippingAddressPage && (
            <React.Fragment>
              {/* <div className="shipping-address-header fadeIn-css">
                <div className="shipping-address-header-subcontainer new-pinfo-header-container">
                  <img
                    className=""
                    alt="Go Bact to Personal Info Page"
                    src={backBtn}
                    //   onClick={this.props.goBackFromAddressForm}
                  />
                  <div className="new-header-text">
                    <span className="shipping-address-title">
                      Personal Info
                    </span>
                  </div>
                  <div className="new-header-text">
                    <span className="common-sub-header-text new-search-wine-for-dish-text">
                      {" "}
                    </span>
                  </div>
                </div>
              </div> */}

              {/* <div className="shipping-address-header override-shipping-address-form-header">
                <div className="shipping-address-header-subcontainer">
                  <span className="shipping-address-title">Address</span>
                </div>
              </div> */}
            </React.Fragment>
          )}
          {/* {this.props.fromShoppingCartShippingAddressPage && (
            <ShoppingCartHeader
              onShippingAddressFormOpenFromShoppingCart={true}
            />
          )} */}
          {/* <form> */}
          <div className={this.getCardHeaderClassNames()}>
            {/* <div className="shipping-address-card-header">
              <img alt="Address" src={addressImage} />
              <span className="form-row-label-header">Address Details</span>
            </div> */}
            {/* {!this.props.fromShippingAddressPage && ( */}
            <div className="shipping-address-header override-shipping-address-form-header mt-3 mb-3">
              <div className="shipping-address-header-subcontainer">
                <span className="shipping-address-title address-title-change font-family-address-label">
                  Address
                </span>
              </div>
            </div>
            {/* )} */}
            <div className="form-group">
              <label className="form-row-label">Name</label>
              <input
                type="text"
                className="form-control form-row-input txt-transform-none"
                name="name"
                value={this.state.shippingAddressForm.name}
                onChange={this.handleChange}
                onBlur={this.handleAllFieldBlur}
              />
              {this.state.saveAsformError != "" ? (
                <span className="form-error-shipping-address">
                  {this.state.saveAsformError}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label className="form-row-label">Address Line 1</label>
              <input
                type="text"
                className="form-control form-row-input txt-transform-none"
                name="address1"
                value={this.state.shippingAddressForm.address1}
                onChange={this.handleChange}
              />
              {this.state.addressLine1formError != "" ? (
                <span className="form-error-shipping-address">
                  {this.state.addressLine1formError}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label className="form-row-label">Address Line 2</label>
              <input
                type="text"
                className="form-control form-row-input txt-transform-none"
                name="address2"
                value={this.state.shippingAddressForm.address2}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label className="form-row-label">City</label>
              <input
                type="text"
                className="form-control form-row-input txt-transform-none"
                name="city"
                value={this.state.shippingAddressForm.city}
                onChange={this.handleChange}
              />
              {this.state.cityformError != "" ? (
                <span className="form-error-shipping-address">
                  {this.state.cityformError}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label className="form-row-label">State</label>
              <select
                className="form-control form-row-input"
                name="stateId"
                value={this.state.shippingAddressForm.stateId}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  -- Select State --
                </option>
                {optionTemplate}
              </select>
              {/* <input
                type="text"
                className="form-control form-row-input "
                name="state"
                value={this.state.shippingAddressForm.state}
                onChange={this.handleChange}
              /> */}
              {this.state.stateformError != "" ? (
                <span className="form-error-shipping-address">
                  {this.state.stateformError}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label className="form-row-label">ZIP Code</label>
              <input
                type="text"
                className="form-control form-row-input "
                name="zipcode"
                value={this.state.shippingAddressForm.zipcode}
                onChange={this.handleChange}
              />
              {this.state.zipcodeFormError != "" ? (
                <span className="form-error-shipping-address">
                  {this.state.zipcodeFormError}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="form-error-shipping-address-container">
              <span className="form-error-shipping-address">
                {this.state.addressFormError}
              </span>
            </div>

            <div className="button p-2">
              <button
                className="mr-4 shpng-form-cancel-btn"
                onClick={this.props.goBackFromAddressForm}
                //   disabled={this.state.isCropping}
              >
                Cancel
              </button>
              <button
                className="shpng-form-save-btn"
                onClick={this.saveShippingForm}
                //   disabled={this.state.isCropping}
              >
                Save
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </React.Fragment>
    );
  }

  saveShippingForm = () => {
    let validForm = true;
    //  zipcode validation

    if (this.state.shippingAddressForm.zipcode) {
      let isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
        this.state.shippingAddressForm.zipcode
      );
      if (!isValidZip) {
        //Not false is true
        validForm = false;
        this.setState({
          zipcodeFormError: "Zipcode format is incorrect .",
        });
      }
    }

    if (!this.state.shippingAddressForm.zipcode) {
      validForm = false;
      this.setState({
        zipcodeFormError: "Zip code cannot be empty.",
      });
    }

    if (!this.state.shippingAddressForm.name) {
      validForm = false;
      this.setState({
        saveAsformError: "Address Name type cannot be empty.",
      });
    }
    if (!this.state.shippingAddressForm.address1) {
      validForm = false;
      this.setState({
        addressLine1formError: "Address Line 1 cannot be empty.",
      });
    }
    if (!this.state.shippingAddressForm.city) {
      validForm = false;
      this.setState({
        cityformError: "City cannot be empty.",
      });
    }
    if (!this.state.shippingAddressForm.stateId) {
      validForm = false;
      this.setState({
        stateformError: "State cannot be empty.",
      });
    }

    // Zipcode validation using Fetch

    if (validForm) {
      // console.log(this.state.shippingAddressForm.id);
      if (this.state.shippingAddressForm.id) {
        GoogleAnalyticsFunctions.updateAddress();
        this.updateUserAddress(this.state.shippingAddressForm);
      } else {
        GoogleAnalyticsFunctions.addNewAddress();
        this.addNewUserAddress(this.state.shippingAddressForm);
      }
    }

    //validate using Axios

    // axios.get(`https://api.zippopotam.us/us/${this.state.shippingAddressForm.zipcode}`,{mode : 'no-cors'}).then(resp => {
    //   console.log(resp.data) ;
    //   if(resp.data){
    // if (validForm) {
    //   // console.log(this.state.shippingAddressForm);
    //   if (this.state.shippingAddressForm.id) {
    //     GoogleAnalyticsFunctions.updateAddress();
    //     this.updateUserAddress(this.state.shippingAddressForm);
    //   } else {
    //     GoogleAnalyticsFunctions.addNewAddress();
    //     this.addNewUserAddress(this.state.shippingAddressForm);
    //   }
    // }
    //   }
    // }).catch(err =>{
    // this.setState({
    //   zipcodeFormError:
    //   "Zipcode Entered Is Not Valid .",
    //   // addressFormError: "* The above form has errors. ",
    // });
    // });
  };

  addNewUserAddress(obj) {
    // Finding stateName based on selected state id
    let stateName = this.state.stateDetailsArray.find(
      (x) => x.id === parseInt(obj.stateId)
    ).name;
    let postObj = {
      name: obj.name,
      stateId: parseInt(obj.stateId),
      stateName: stateName,
      city: obj.city,
      addressLine1: obj.address1,
      addressLine2: obj.address2,
      zipcode: obj.zipcode,
    };
    // console.log(postObj);
    this.addNewAddressAPICall(postObj);
  }

  addNewAddressAPICall = (postObj) => {
    //  console.log(postObj)
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let addNewAddressApiUrl = constants.IDENTITY_API + "/api/v1/user/address";
      fetch(addNewAddressApiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(postObj),
      })
        .then((response) => {
          //   console.log(response)
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            // let apiErrorObj = {
            //   statusText: response.statusText,
            //   type: response.type,
            //   status: response.status,
            //   url: addNewAddressApiUrl,
            //   operation: "POST",
            //   status: response.status,
            // };
            // commonFunctions.postCaughtException(JSON.stringify(apiErrorObj), consumerData, wineryId);
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            // throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          //  console.log(respJSON)
          if (respJSON) {
            // toast.info("Address added successfully.", {
            //   position: toast.POSITION.BOTTOM_CENTER,
            // });
            // console.log("Address added successfully.")
            this.props.goBackFromAddressForm();
          } else {
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            //    console.log(respJSON.error);
            console.log("Address not added successfully.");
            // this.setState({
            //   zipcodeFormError:
            //   "Enter a valid zipCode within US .",
            //   // addressFormError: "* The above form has errors. ",
            // });
            //   this.props.goBackFromAddressForm();
          }
        })
        .catch((error) => {
          // console.log(error);
          this.props.goBackFromAddressForm();
        });
    } catch (e) {
      toast.info("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.props.goBackFromAddressForm();
      // console.log(e);
    }
  };

  updateUserAddress(obj) {
    // Finding stateName based on selected state id
    let stateName = this.state.stateDetailsArray.find(
      (x) => x.id === parseInt(obj.stateId)
    ).name;
    let updateObj = {
      name: obj.name,
      stateId: parseInt(obj.stateId),
      stateName: stateName,
      city: obj.city,
      addressLine1: obj.address1,
      addressLine2: obj.address2,
      zipcode: obj.zipcode,
      isPrimary: obj.isPrimary,
    };
    // console.log(updateObj);
    this.updateAddressAPICall(updateObj, obj.id);
  }

  updateAddressAPICall = (updateObj, addressId) => {
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let updateAddressApiUrl =
        constants.IDENTITY_API + "/api/v1/user/address/" + addressId;
      fetch(updateAddressApiUrl, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(updateObj),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: updateAddressApiUrl,
              operation: "PUT",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            // toast.info("Address updated successfully.", {
            //   position: toast.POSITION.BOTTOM_CENTER,
            // });
            this.props.goBackFromAddressForm();
          } else {
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            // console.log(respJSON.errors);
            this.props.goBackFromAddressForm();
          }
        })
        .catch((error) => {
          // console.log(error);
          this.props.goBackFromAddressForm();
        });
    } catch (e) {
      toast.info("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.props.goBackFromAddressForm();
      // console.log(e);
    }
  };
}

export default ShippingAddressFormComponent;
