import React, { Component } from "react";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import * as constants from "../../constants/constants";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import ShoppingCartHeader from "../../components/ShoppingCartHeader/ShoppingCartHeader";
import "./PaymentDetails.scss";
import redAddIcon from "../../../assets/images/red-add-icon.svg";
import PaymentCardSelectionComponent from "../../components/PaymentCardSelectionComponent/PaymentCardSelectionComponent";
import * as commonFunctions from "../../constants/commonFunctions";
import AddedToCartPopUp from "../../components/AddedToCartPopUp/AddedToCartPopUp";
import PaymentSuccessPopUp from "../../components/PaymentSuccessPopUp/PaymentSuccessPopUp";
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/StripePaymentCheckOutForm/StripePaymentCheckOutForm'
// const cardDetailsArray = [
//   {
//     id: 1,
//     cardNumber: "7738773635",
//   },
//   {
//     id: 2,
//     cardNumber: "8838743635",
//   },
// ];
const stripePromise = loadStripe(constants.PUBLISHABLE_KEY);

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // cardDetails: cardDetailsArray,
      // selecteCardId: "",
      showPaymentSuccessPopUp: false,
    };
  }

  render() {
    return (
      <div
        className="recommendations-main-container font-family"
        id="parentShoppingCartDiv"
      >
        <ShoppingCartHeader />

        <div className="grand-total-card">
          <div>
            <span className="grand-total-value">Payment Details</span>
          </div>
          <div className="grand-total-div">
            <span className="grand-total-value">
              Total: {commonFunctions.formatter.format(2500)}
            </span>
          </div>
        </div>
        <div className="payment-options-header-label">
          <span>Credit or Debit card</span>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          {/* <label> */}
            <Elements stripe={stripePromise}>
              <InjectedCheckoutForm />
            </Elements>
          {/* </label> */}
        </div>
        {/* <div className="saved-cards">
          <div>
            <span>Saved Cards</span>
          </div>
          <div className="add-new-card">
            <img
              alt="Add New Address"
              className="add-new-icon"
              src={redAddIcon}
            />
            <span> Add New</span>
          </div>
        </div> */}

        {/* {this.state.cardDetails.length > 0 ? (
          <ErrorBoundary>
            {this.state.cardDetails.map((el, index) => (
              <PaymentCardSelectionComponent
                cardData={el}
                selecteCardId={this.state.selecteCardId}
                key={index}
                onCardSelect={this.onSelectingCard}
              />
            ))}
          </ErrorBoundary>
        ) : (
          <div className="empty-data mt-4">
            <span>No card details has been added yet.</span>
          </div>
        )}
        <div className="continue-button-container mb-2">
          <button
            type="button"
            onClick={this.onClickPayment}
            className="btn btn-round continue-button"
          >
            Pay
          </button>
        </div> */}
        {/* <AddedToCartPopUp open={this.state.showPaymentSuccessPopUp} /> */}
        {/* <PaymentSuccessPopUp open={this.state.showPaymentSuccessPopUp} /> */}
      </div>
    );
  }

  onClickPayment = () => {
    this.setState({
      showPaymentSuccessPopUp: true,
    });
    setTimeout(() => {
      this.setState({
        showPaymentSuccessPopUp: false,
      });
    }, 2000);
  };
  // onSelectingCard = (id) => {
  //   this.setState({
  //     selecteCardId: id,
  //   });
  // };
}

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);
export default PaymentDetails;
