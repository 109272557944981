import React from "react";

export default function BlueCheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M7 14.6943C10.866 14.6943 14 11.5603 14 7.69434C14 3.82834 10.866 0.694336 7 0.694336C3.13401 0.694336 0 3.82834 0 7.69434C0 11.5603 3.13401 14.6943 7 14.6943Z"
        fill="white"
      />
      <path
        d="M3.5 8.16133L5.83333 10.028L10.5 5.36133"
        stroke="#1E7ED7"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
