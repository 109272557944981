import React, { useEffect, useState } from "react";
import styles from "./wine-type-pill.module.scss";

export default function WineTypePill({ type }) {
  const [colorStyle, setColorStyle] = useState({});

  useEffect(() => {
    if (!!type) {
      type = type.trimEnd().toLowerCase();
      if (type == "white") {
        setColorStyle({
          color: "rgba(105, 114, 0, 1)",
          backgroundColor: "rgba(152, 155, 0, 0.15)",
        });
      } else if (type == "red") {
        setColorStyle({
          color: "rgba(149, 25, 1, 1)",
          backgroundColor: "rgba(149, 25, 1, 0.10)",
        });
      }
    }
  }, [type]);

  if (!type) return <></>;

  return (
    <div className={`${styles.pill}`} style={colorStyle}>
      {type}
    </div>
  );
}
