import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";

import SideNavBar from "../SideNavBar/SideNavBar";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import { isEmbedded } from '../../constants/commonFunctions';
const HeaderWithBurgerIcon = ({
  title,
  gotoPreviousPage,
  goBackFunc,
  arrowIcon,
  hideBackInEmbedded = false
}) => {
  const history = useHistory();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const toggleMenu = () => {
    setShowSideMenu(!showSideMenu);
  };
  const closeMenu = () => {
    setShowSideMenu(false);
  };
  const openHomePage = () => {
    history.push("/discover");
  };
  let cur_winery_id = JSON.parse(localStorage.getItem("winery_id"));
  return (
    <React.Fragment>
      <SideNavBar
        closeMenu={() => closeMenu()}
        showSideMenu={showSideMenu}
      ></SideNavBar>
      <div className="common-page-header">
        <div className="common-page-sub-header new-common-header-change">
          {/* 16-Oct-2020 Commenting back arrow - main pages doesn't need it */}
          {/* <img
      alt="Open Recommendations page"
      className="common-sub-header-back-icon"
      onClick={() => { this.gotoPreviousPage() }}
      src={backBtn}
    /> */}
          <div className="hamburger-menu">
            {(!hideBackInEmbedded || !isEmbedded()) &&
                <img
                    alt="Go to Previous page"
                    className="= ptr-clss"
                    onClick={() => {
                      if (!hideBackInEmbedded || !isEmbedded()) (goBackFunc ? goBackFunc() : history.goBack())
                    }
                    }
                    src={backBtn}
                />
            }
            {/* {arrowIcon ? ( */}
            {/* ) : (
              <MenuIcon
                style={{ fill: "white", fontSize: "25" }}
                onClick={() => {
                  toggleMenu();
                }}
              ></MenuIcon>
            )} */}
          </div>
          <div>
            <div className="new-header-text">
              <span className="common-sub-header-text new-search-wine-for-dish-text">
                {title}
              </span>
            </div>
            {title == "Chat" ? (
              <div>
                <span className="new-business-timings-css">
                  {" "}
                  Daily: 5am to 8pm (PST){" "}
                </span>
              </div>
            ) : null}
          </div>
          <img
            alt="PairAnything"
            className="common-sub-header-logo  new-logo-header-design ml-0"
            src={pairAnythingLogo}
            onClick={() => {
              if ((!hideBackInEmbedded || !isEmbedded()) && (cur_winery_id && cur_winery_id != 381)) {
                openHomePage();
              }
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderWithBurgerIcon;
