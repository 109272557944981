import * as types from './action-types';
import * as constants from '../../constants/constants'

export const AddToRsvp = (eventId, slotId, passCount) => {

    let wineryId = (JSON.parse(localStorage.getItem('winery_id')));
    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let apiPostData = {
        count: passCount
    }
    return dispatch => {
        fetch(constants.EVENT_API + '/api/v1/users/winery/' + wineryId + '/events/' + eventId + '/slots/' + slotId + '/book', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + signInToken            },
            body: JSON.stringify(
                apiPostData
            )
        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.ADD_TO_RSVP_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.ADD_TO_RSVP_FAILED, payload: error })
        })
    }
}
