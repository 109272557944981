import React, { useEffect, useState, useRef } from "react";
import * as constants from "../../../constants/constants";
import useSWR from "swr";
import { get, post, postPilotEvent } from "../../../lib/fetch";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./dd-pairing.module.scss";
import { useParams } from "react-router-dom";
import foodPairingData from "../foodPairingData";
import DDFoodCard from "./dd-food-card";
import { useHistory } from "react-router-dom";
import DDPhoneForm from "./dd-phone-form";
import IdleTimer from "../../../components/IdleTimer/IdleTimer";
import DDShareModal from "./dd-share-modal";
import wineDescriptionData from "../wineDescriptionData";
import { useLocation } from "react-router-dom";

export default function DDPairing() {
  const history = useHistory();
  let { search } = useLocation();
  let searchParams = new URLSearchParams(search);
  let fromQR = searchParams.get("qr");

  useEffect(() => {
    if (fromQR == "true") {
      let pilot_session = JSON.parse(localStorage.getItem("pilot_session"));
      if (!pilot_session || pilot_session == null) {
        createNewPilotSessionFromQR(window.location.href);
        history.replace(window.location.pathname);
      } else {
        postPilotEvent({
          eventName: "page_fromQR",
          pageUrl: window.location.href,
        });
        history.replace(window.location.pathname);
      }
    }
  }, [fromQR]);

  const { wineId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);

  const [sortedWines, setSortedWines] = useState(undefined);
  const [selectedWine, setSelectedWine] = useState(undefined);
  const [profile, setProfile] = useState(undefined);
  const [flavors, setFlavors] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (selectedWine && varietalProfiles) {
      setProfile(
        varietalProfiles[selectedWine.specifications?.varietal.trimEnd()]
      );
      setFlavors(
        Object.keys(
          varietalProfiles[selectedWine.specifications?.varietal.trimEnd()]
            .flavors
        ).reverse()
      );
    }
  }, [selectedWine, varietalProfiles]);

  // wines --------------------
  const wineryId = 415;

  const { data: wines } = useSWR(
    `${constants.WINERY_API}/api/v1/public/wine/winery/consumer?wineryId=${wineryId}`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (wines) {
      setSortedWines(
        wines.rows.filter((wine) => {
          return wine.id == wineId;
        })
      );
    }
  }, [wines]);

  useEffect(() => {
    if (sortedWines && sortedWines.length > 0) {
      setSelectedWine(sortedWines[0]);
    }
  }, [sortedWines]);

  //---------------------------

  // oooooooooo onClick Functions oooooooooooooooooooooooooooooo
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleBackBtnClick = async () => {
    postPilotEvent({
      eventName: "click_backButton",
      pageUrl: window.location.href,
    });

    // take us to the pairing result for wine page
    let eventBody = {
      type: "track",
      event: "click_pilotBack",
      visitorId: "dd_pilot_kiosk_1",
      timestamp: Date.now(),
      properties: {
        wineId: `${selectedWine?.id}`,
      },
      context: {},
    };

    let url = `https://app.pendo.io/data/track`;
    try {
      await post(
        url,
        {
          "X-Pendo-Integration-Key": constants.PENDO_TRACK_KEY,
          "Content-Type": "application/json",
        },
        eventBody
      );
    } catch (error) {
      console.error("Error:", error);
    }

    history.push({
      pathname: `/dd-welcome`,
    });
  };

  const createNewPilotSessionFromQR = async (page) => {
    let url = constants.IDENTITY_API + "/api/v1/public/user/post/pilotSession";
    let pilotSessionBody = {
      userAgent: window.navigator.userAgent,
    };

    try {
      const response = await post(
        url,
        {
          "Content-Type": "application/json",
        },
        pilotSessionBody
      );
      const result = await response.json();

      console.log(`result:`);
      console.log(result);

      localStorage.setItem("pilot_session", JSON.stringify(result?.id));
      console.log(JSON.parse(localStorage.getItem("pilot_session")));
      postPilotEvent({
        eventName: "session_startQR",
        pageUrl: page,
      });
      postPilotEvent({
        eventName: "page_fromQR",
        pageUrl: page,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className={`${styles.main}`}>
      <IdleTimer
        key={1}
        time={30}
        cb={() => {
          postPilotEvent({
            eventName: "session_timeOut",
            pageUrl: window.location.href,
          });
          localStorage.setItem("pilot_session", JSON.stringify(null));
          history.push({
            pathname: `/dd-welcome`,
          });
        }}
      />
      <div
        className={`${styles.winePanel}`}
        style={{
          backgroundColor:
            selectedWine?.wineType == "Red"
              ? "#EEE1E1"
              : selectedWine?.wineType == "White"
              ? "#EBE5D8"
              : "#EFEFEF",
        }}
      >
        <button
          className={`${styles.backBtn}`}
          onClick={() => handleBackBtnClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 12H5"
              stroke="#7D7D7D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 19L5 12L12 5"
              stroke="#7D7D7D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        {selectedWine && (
          <img
            src={selectedWine.wineImageSmall}
            className={`${styles.wineImg}`}
          />
        )}
      </div>
      <div className={`${styles.detailsPanel}`}>
        <div className={`${styles.titleBox}`}>
          <h1 className={`${styles.title}`}>{selectedWine?.wineName}</h1>
          {profile && (
            <>
              {selectedWine && flavors && selectedWine.id != 1947 ? (
                <p className={`${styles.wineDescription}`}>
                  {wineDescriptionData[selectedWine?.id]} Tastes like{" "}
                  <b>{flavors[0].toLowerCase()}</b>,{" "}
                  <b>{flavors[1].toLowerCase()}</b>, and{" "}
                  <b>{flavors[2].toLowerCase()}</b>.{" "}
                  <span>{profile?.body > 3 ? "Sweet" : "Dry"}</span>,{" "}
                  <span>{profile?.body > 3 ? "heavy body" : "light body"}</span>
                  , and{" "}
                  <span>
                    {profile?.tannins > 3 ? "high tannin" : "low tannin"}
                  </span>{" "}
                  <span>{selectedWine.wineType.toLowerCase()}</span> wine.
                </p>
              ) : (
                <p className={`${styles.wineDescription}`}>
                  A light red wine with notes of <b>blood orange</b>,{" "}
                  <b>black plums</b>, and a herbaceous tinge of <b>mushrooms</b>
                  . Delicate yet lively, supple yet reminiscent of its Swiss
                  heritage.,
                </p>
              )}
            </>
          )}
        </div>
        {foodPairingData[selectedWine?.id] ? (
          <>
            <div className={`${styles.pairsWithBox}`}>
              <h2 className={`${styles.pairsWith}`}>Pairs with</h2>
              <div className={`${styles.foodCardRow}`}>
                {foodPairingData[selectedWine?.id]?.map((food, i) => (
                  <DDFoodCard key={i} food={food} />
                ))}
              </div>
            </div>
            <button
              className={`${styles.shareBtn}`}
              onClick={() => {
                postPilotEvent({
                  eventName: "click_getACopy",
                  pageUrl: window.location.href,
                });
                setModalOpen(true);
              }}
            >
              <p className={`${styles.shareBtnTxt}`}>Send me more</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.sendIcon}
                viewBox="0 0 13 13"
                fill="none"
              >
                <g clip-path="url(#clip0_805_713)">
                  <path
                    d="M1.15744 4.43412C0.654939 4.60174 0.603752 5.29231 1.07588 5.53231L4.13569 7.08687C4.22216 7.13076 4.319 7.15011 4.4157 7.1428C4.51239 7.1355 4.60524 7.10182 4.68413 7.04543L8.15344 4.56724C8.33625 4.43693 8.56125 4.66193 8.43094 4.84474L5.95294 8.31387C5.89655 8.39276 5.86287 8.4856 5.85557 8.5823C5.84826 8.67899 5.86761 8.77584 5.9115 8.86231L7.46719 11.9236C7.70719 12.3961 8.39794 12.3447 8.56556 11.8422L11.8941 1.86049C12.0495 1.39381 11.6057 0.949994 11.1392 1.10562L1.15744 4.43412Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_805_713">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(0.5 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            {modalOpen && <DDShareModal closeModal={closeModal} />}
          </>
        ) : (
          <>
            <DDPhoneForm />
            <div className={`${styles.filler}`} />
          </>
        )}
      </div>
    </div>
  );
}
