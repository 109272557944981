
import * as types from './action-types';
import * as constants from '../../constants/constants'

let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
let bearer = 'Bearer ' + signInToken;

export const UserSignUp = (dataValue) => {
    const data = {
        
        email :  dataValue.email,
        password : dataValue.password,
        firstName : dataValue.firstName,
        lastName : dataValue.lastName,
        phoneNumber : dataValue.phoneNumber,
        address1 : dataValue.address1,
        address2 : dataValue.address2,
        city : dataValue.city ,
        state : dataValue.state,
        country : dataValue.country ,
        zipcode : dataValue.zipcode,
        createdBy : dataValue.email,
        updatedBY: dataValue.email,
        // wineryId: 50,

    }

    return dispatch => {
        fetch(constants.IDENTITY_API + '/api/v1/consumer/user/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : bearer
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.USER_SIGNUP_SUCCEEDED, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.USER_SIGNUP_FAILED, payload: error })
        })
    }
}
