import React, { useEffect, useState, useRef } from "react";

import * as constants from "../../../constants/constants";
import useSWR from "swr";
import { get, post, postPilotEvent } from "../../../lib/fetch";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./pairing.module.scss";
import pairingData from "../pairingData";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

export default function TDRating({ foodPairing }) {
  const [like, setLike] = useState(false);
  const [dislike, setDisLike] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // ========== Set up formik ==============================

  const [formSubmittable, setFormSubmittable] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      requestText: "",
      email: "",
    },
    validate,
    onSubmit: (values) => submitForm(values),
  });

  useEffect(() => {
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik]);

  // ==== onClick functions =========
  const submitForm = async (values) => {
    if (formSubmittable) {
      let event_name;
      if (like) {
        event_name = "click_submitLike";
      } else if (dislike) {
        event_name = "click_submitDislike";
      }
      try {
        postPilotEvent({
          eventName: event_name,
          eventDetails: String(values.email),
          pageUrl: window.location.href,
        });

        setFormSubmitted(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className={`${styles.ratingBox}`}>
      <h3 className={styles.ratingTitle}>How was this pairing?</h3>
      {!formSubmitted ? (
        <>
          <div className={styles.buttonBox}>
            <button
              className={styles.likeBtn}
              style={like ? { backgroundColor: "#343434" } : null}
              onClick={() => {
                if (!like) {
                  setLike(true);
                  setDisLike(false);
                  postPilotEvent({
                    eventName: "click_like",
                    pageUrl: window.location.href,
                  });
                }

                setTimeout(() => {
                  document.getElementById("ratingInput").focus();
                  document.getElementById("ratingInput").scrollIntoView({
                    behavior: "smooth",
                  });
                }, 100);
              }}
            >
              <p style={like ? { color: "white" } : null}>Good</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill={like ? "white" : "#343434"}
              >
                <path
                  opacity={like ? "1" : "0.5"}
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.21237 1.05098C7.33126 0.783479 7.59654 0.611084 7.88927 0.611084C8.6633 0.611084 9.40564 0.918569 9.95296 1.46589C10.5003 2.01322 10.8078 2.75555 10.8078 3.52959V5.69253H14.172C14.4882 5.68962 14.8014 5.75516 15.09 5.88467C15.3799 6.01482 15.6382 6.20645 15.8467 6.4463C16.0553 6.68614 16.2092 6.96847 16.2978 7.27371C16.3864 7.57895 16.4076 7.89981 16.3599 8.21405L15.3582 14.7472C15.2788 15.2698 15.0132 15.7468 14.6104 16.0891C14.2087 16.4306 13.6974 16.6158 13.1703 16.611H2.80782C2.22631 16.611 1.66862 16.38 1.25743 15.9688C0.846238 15.5576 0.615234 14.9999 0.615234 14.4184V9.33696C0.615234 8.75545 0.846238 8.19776 1.25743 7.78657C1.66862 7.37538 2.22631 7.14438 2.80782 7.14438H4.50419L7.21237 1.05098ZM4.24484 8.62586H2.80782C2.61922 8.62586 2.43835 8.70078 2.30499 8.83413C2.17164 8.96749 2.09672 9.14836 2.09672 9.33696V14.4184C2.09672 14.607 2.17164 14.7879 2.30499 14.9212C2.43835 15.0546 2.61922 15.1295 2.80782 15.1295H4.24484V8.62586ZM5.72632 15.1295H13.1824C13.3538 15.1314 13.5203 15.0714 13.6509 14.9603C13.7816 14.8492 13.8677 14.6947 13.8935 14.5251L14.8952 7.99173C14.9106 7.88992 14.9038 7.78565 14.8751 7.68676C14.8464 7.58776 14.7964 7.4962 14.7288 7.41841C14.6611 7.34063 14.5774 7.27848 14.4834 7.23627C14.3893 7.19406 14.2872 7.1728 14.1841 7.17397L14.1757 7.17406L10.067 7.17401C9.65793 7.17401 9.32629 6.84237 9.32629 6.43327V3.52959C9.32629 3.14847 9.17489 2.78295 8.9054 2.51346C8.74488 2.35294 8.55029 2.23432 8.33863 2.16463L5.72632 8.04231V15.1295Z"
                  fill={like ? "white" : "#343434"}
                />
              </svg>
            </button>
            <button
              className={styles.likeBtn}
              style={dislike ? { backgroundColor: "#343434" } : null}
              onClick={() => {
                if (!dislike) {
                  setDisLike(true);
                  setLike(false);
                  postPilotEvent({
                    eventName: "click_dislike",
                    pageUrl: window.location.href,
                  });
                }
                setTimeout(() => {
                  document.getElementById("ratingInput").focus();
                  document.getElementById("ratingInput").scrollIntoView({
                    behavior: "smooth",
                  });
                }, 100);
              }}
            >
              <p style={dislike ? { color: "white" } : null}>Bad</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill={dislike ? "white" : "#343434"}
              >
                <path
                  opacity={dislike ? "1" : "0.5"}
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.78763 16.1712C9.66874 16.4387 9.40346 16.6111 9.11073 16.6111C8.3367 16.6111 7.59436 16.3036 7.04704 15.7563C6.49971 15.2089 6.19223 14.4666 6.19223 13.6926V11.5296L2.82801 11.5296C2.51176 11.5325 2.1986 11.467 1.91003 11.3375C1.62006 11.2074 1.36184 11.0157 1.15328 10.7759C0.944713 10.536 0.79079 10.2537 0.702172 9.94846C0.613554 9.64322 0.592359 9.32236 0.640056 9.00812L1.64181 2.47492C1.72123 1.95236 1.98685 1.47538 2.38959 1.13305C2.79131 0.791584 3.30259 0.606344 3.8297 0.611177L14.1922 0.611177C14.7737 0.611177 15.3314 0.84218 15.7426 1.25337C16.1538 1.66456 16.3848 2.22225 16.3848 2.80376V7.88521C16.3848 8.46672 16.1538 9.02441 15.7426 9.4356C15.3314 9.84679 14.7737 10.0778 14.1922 10.0778H12.4958L9.78763 16.1712ZM12.7552 8.59631H14.1922C14.3808 8.59631 14.5616 8.52139 14.695 8.38803C14.8284 8.25468 14.9033 8.0738 14.9033 7.88521V2.80376C14.9033 2.61516 14.8284 2.43429 14.695 2.30094C14.5616 2.16758 14.3808 2.09266 14.1922 2.09266H12.7552L12.7552 8.59631ZM11.2737 2.09266L3.81765 2.09266C3.64616 2.09072 3.47974 2.15078 3.34907 2.26185C3.21839 2.37292 3.13226 2.52748 3.10655 2.69705L2.10476 9.23043C2.08936 9.33224 2.0962 9.43652 2.12491 9.53541C2.15365 9.6344 2.20357 9.72597 2.27121 9.80375C2.33885 9.88154 2.4226 9.94369 2.51664 9.9859C2.61069 10.0281 2.71279 10.0494 2.81586 10.0482L2.82425 10.0481L6.93297 10.0482C7.34207 10.0482 7.67371 10.3798 7.67371 10.7889V13.6926C7.67371 14.0737 7.82511 14.4392 8.0946 14.7087C8.25512 14.8692 8.44971 14.9879 8.66137 15.0575L11.2737 9.17986L11.2737 2.09266Z"
                  fill={dislike ? "white" : "#343434"}
                />
              </svg>
            </button>
          </div>
          {(like || dislike) && (
            <form
              className={styles.formBox}
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <div className={`${styles.formField}`}>
                <label className={styles.asteriskTxt} htmlFor="email">
                  Please enter your email to submit*
                </label>
                <input
                  placeholder="Enter your email"
                  id="ratingInput"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`${styles.formInput} p1-reg`}
                />
              </div>

              <button
                className={`${styles.submitBtn} ${
                  !formSubmittable ? styles.grayedOut : null
                }`}
                type="submit"
              >
                <p className={styles.submitBtnTxt}>Submit rating</p>
              </button>
            </form>
          )}
        </>
      ) : (
        <p className={styles.tyTxt}>Thank you, your feedback helps us a ton!</p>
      )}
    </div>
  );
}
