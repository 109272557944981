import React, { Component } from 'react';

import './Splash.scss';
import logo from '../../../assets/images/splash.png'; // with import

class Splash extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            
        }
        
    }
    componentDidMount() {   }

    
    render() {

        return (
          
            <div className = "splash-container">
                <img alt = "PairAnything" className="splash-icon" src={logo} alt = {logo} />
           </div>
                
        );
    }
}

export default Splash; 
