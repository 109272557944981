import React, { useState } from "react";
import "./ReviewWineModal.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import { CreateWineRating } from "../../../redux/actions/WineRatingAction";
import wineBottlePlaceholder from "../../../../assets/images/wine_bottle_placeholder.png";
import ThankYouModal from "./ThankYouModal";

const ReviewWineModal = (props) => {
  const { open, handleClose, wineId, wineImage, wineName } = props;

  const [rating, setRating] = useState(-1);
  const [sample, setSample] = useState(0);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDesc, setReviewDesc] = useState("");
  const [error, setError] = useState("");
  const [thankYouOpen, setThankyouOpen] = useState(false);

  const wineryId = JSON.parse(localStorage.getItem("winery_id"));

  const createWineRating = () => {
    const wineReviewData = {
      wineId: wineId,
      wineryId: wineryId,
      reviewTitle: reviewTitle,
      reviewDesc: reviewDesc,
      ratings: rating,
    };
    if (rating !== -1) {
      props.CreateWineRating(wineReviewData);
      setThankyouOpen(true);

      setTimeout(() => {
        handleClose();
      }, 3000);
      setError("");
    } else {
      setError("Please choose a rating.");
    }
  };

  const displayRatingBar = (count) => {
    let stars = [];

    for (let i = 1; i <= count; i++) {
      let ratingStarClass =
        "star-rating-ratingpage__star rate-reco-star wine-rating-star";

      if (sample !== 0 && rating >= i && rating != null) {
        // ratingStatus = 1;
        ratingStarClass += " is-selected";
      }
      stars.push(
        <label key={i} className={ratingStarClass} onClick={() => rate(i)}>
          ★
        </label>
      );
    }
    return stars;
  };

  const rate = (rating) => {
    setSample(1);
    setRating(rating);
  };

  return (
    <div className="modal-style">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogContent>
          <div className="wine-rating-title">
            <img src={wineImage || wineBottlePlaceholder} alt="wine" />
            <p>{wineName}</p>
          </div>

          <h2 className="wine-rating-title-text">Overall Rating</h2>
          {displayRatingBar(5)}
          <span className="wine-rating-error-text">{error}</span>
          <h2 className="wine-rating-title-text">Review Title</h2>
          <input
            className="wine-rating-review-title"
            placeholder="What's most important to know?"
            onChange={(e) => setReviewTitle(e.target.value)}
          />

          <h2 className="wine-rating-title-text">Review</h2>
          <textarea
            className="wine-rating-review-text"
            placeholder="What did you like or dislike? What did you use this product for?"
            onChange={(e) => setReviewDesc(e.target.value)}
          />

          <br></br>

          <button
            className="wine-rating-submit-button"
            onClick={createWineRating}
          >
            Submit
          </button>
          <ThankYouModal
            open={thankYouOpen}
            handleClose={() => setThankyouOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

// REDUX
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    CreateWineRating: (wineData) => dispatch(CreateWineRating(wineData)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ReviewWineModal);
