import React, { Component } from "react";
import { isMobile } from "react-device-detect";

import "./SetPassword.scss";
import logo from "../../../assets/images/splash.png";

import { Redirect } from "react-router-dom";
import { FormErrors } from "../../components/FormErrors/FormErrors";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as SetPasswordAction from "../../redux/actions/SetPasswordAction";

import * as constants from "../../constants/constants";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

let winery_id; // want updation to happen before setState

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getUserByEmailApiHit: false,
      password: "",
      confirmPassword: "",
      consumerId: "",
      setPasswordPageStatus: false,
      formErrors: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      emailValid: false,
      passwordValid: false,
      confirmPasswordValid: false,
      formValid: false,
      consumerEmailIdFromUrl: "",
      consumerIdFromEmail: "",
      wineryId: "",
      wineryName: "", // displayed by hiting an api using wineryId
      wineryDisplayName: "",
      isActive: false,
      userPairAnythingStatus: "",
      isVerified: false,
      signInPageLoaded: false,
      inValidMessageFromResponse: "",
      buttonClick: false,
      urlToken: "",
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
      this
    );
  }

  getWineryNameFromWineryId = (wineryId) => {
    if (wineryId != null && wineryId != undefined) {
      let getWineryNameFromWineryIdApiUrl =
        constants.WINERY_API + "/api/v1/public/winery/name/" + wineryId;

      fetch(getWineryNameFromWineryIdApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state

          this.setState({
            wineryName: json.name,
          });
        })
        .catch((error) => console.error(error));
    }
  };

  componentDidMount() {
    let urlTemp = []; // splitting the URL
    let items = window.location.href.split("&");

    // let items = "https://pairanything-consumerportal-dev.redblacktree.net/raina@redblacktree.com/set-password/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVHlwZSI6ImNvbnN1bWVyIiwiY3JlYXRlZEF0IjoxNTc2NTc0NzI3MTAwLCJleHAiOjE1NzY2NjExMjcxMDAsImlhdCI6MTU3NjU3NDcyN30.ide88T3RbJ-AuTEyHhPz7fvgJHdtuE-bVdtumVIFn8M".split("&");

    for (let index = 0; index < items.length; index++) {
      urlTemp = items[index].split("/");
    }

    let emailFromUrl = urlTemp[3];
    let tokenFromUrl = urlTemp[5];
    this.setState({
      consumerEmailIdFromUrl: emailFromUrl, // email
      urlToken: tokenFromUrl, // token
    });

    /* 
      NOTE: We get the email id from the URL, hit an api to get the ID based on the email id
            Then, we send the password and the id to the setpassword API 

    */
    if (this.validateEmail(emailFromUrl)) {
      // valid email

      fetch(
        constants.IDENTITY_API +
          "/api/v1/public/user/getUserByEmail/" +
          emailFromUrl,
        {
          // updated new url

          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state
          let pariAnythingStatus = json.user.pairanything_status.name;
          // if(json.isActive == true && json.user.isVerified == true ) {
          if (json.user.pairanything_status.name == "invited") {
            this.setState({
              userPairAnythingStatus: "invited",
            });
          } else if (json.user.pairanything_status.name == "active") {
            this.setState({
              // isActive: true,
              // isVerified: true
              userPairAnythingStatus: "active",
            });
          } else if (json.user.pairanything_status.name == "inactive") {
            this.setState({
              userPairAnythingStatus: "inactive",
            });
          }
          this.setState({
            getUserByEmailApiHit: true,
            consumerIdFromEmail: json.user.id,
            // wineryId: json.wineryId,
          });
          // winery_id = json.wineryId;

          // Checking Winery status
          let wineryStatus, wineryId, wineryName;

          if (json.user.wineryData.length > 0) {
            json.user.wineryData.forEach((element) => {
              if (element.wineryUserStatus == 1) {
                wineryStatus = element.wineryUserStatus;
                wineryId = element.wineryId;
                wineryName = element.wineryName;
                this.setState({
                  wineryId: wineryId,
                  userWineryStatus: wineryStatus,
                  wineryName: wineryName,
                });
              }
            });
            if (!wineryStatus) {
              this.setState({
                userWineryStatus: 0, // if user had no invited wineries
              });
            }
            // // If pair Anything status is invited and wineryStatus is 1 (invited) then we are validating token.
            // if (pariAnythingStatus === "invited" && wineryStatus == 1) {
            //   this.validateToken(json, tokenFromUrl);
            // }
          }
        })
        .catch((error) => console.error(error));
    } else {
      //  toast.error("Email address is invalid! ", {
      //   position: toast.POSITION.BOTTOM_CENTER
      // });
    }

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handlePasswordChange(event) {
    let val = event.target.value;
    this.setState({ password: event.target.value }, () => {
      this.validateField("password", val);
    });
  }
  handleConfirmPasswordChange(event) {
    let val = event.target.value;
    this.setState({ confirmPassword: event.target.value }, () => {
      this.validateField("confirmPassword", val);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //nextProps - new val from reducer
    // prevState - state value

    if (nextProps.setPasswordSuccess != undefined) {
      // coming from mapStateToProps

      if (nextProps.setPasswordSuccess.message) {
        if (nextProps.setPasswordSuccess.message == "User doesnt exist") {
          return {
            inValidMessageFromResponse: "User does not exist! ",
          };
        } else {
          return {
            inValidMessageFromResponse: nextProps.setPasswordSuccess.message,
          };
        }
      }

      if (nextProps.setPasswordSuccess[0] == 1) {
        // successful  if response = 1
        return {
          signInPageLoaded: true,
        };
      } else {
        return null;
      }
    }

    return null;
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : "Invalid email address";
        break;

      case "password":
        passwordValid = value.length >= 4;
        // passwordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
        fieldValidationErrors.password = passwordValid
          ? ""
          : "Password invalid. Enter atleast 4 characters.";
        break;
      case "confirmPassword":
        confirmPasswordValid =
          this.state.password == this.state.confirmPassword;
        // confirmPasswordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
        // fieldValidationErrors.confirmPassword = confirmPasswordValid ? '': ' and password do not match';
        fieldValidationErrors.password = passwordValid
          ? ""
          : " and confirm password do not match.";

        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        confirmPasswordValid: confirmPasswordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.passwordValid && this.state.confirmPasswordValid,
    });
  }

  setPasswordSubmit = () => {
    if (this.state.password == "" || this.state.confirmPassword == "") {
      this.setState({
        inValidMessageFromResponse:
          "Please enter your password and confirm password! ",
      });
    } else if (this.state.password != this.state.confirmPassword) {
      this.setState({
        inValidMessageFromResponse: "Passwords do not match! ",
      });
    } else if (
      this.state.password.length < 4 ||
      this.state.confirmPassword.length < 4
    ) {
      this.setState({
        inValidMessageFromResponse: "Please enter atleast 4 characters! ",
      });
    } else if (
      this.state.password != "" &&
      this.state.confirmPassword != "" &&
      this.state.password == this.state.confirmPassword
    ) {
      const data = {
        userType: "customer",

        password: this.state.password,
        // confirmPassword: this.state.confirmPassword,
        // id: this.state.consumerId,

        id: this.state.consumerIdFromEmail, // pass the ID from the state for the setPassword POST request
        wineryId: this.state.wineryId,
        tokenFromUrl: this.state.urlToken,
        //id-3,18,22,23,24
      };

      this.props.SetPasswordAction.SetPassword(data);
      GoogleAnalyticsFunctions.clickedSetPasswordSubmit();
    } else {
      this.setState({
        inValidMessageFromResponse:
          "Please enter password and confirm password! ",
      });
    }
    this.setState({
      buttonClick: true,
    });
  };

  componentDidUpdate(prevProps, prevStates) {
    if (
      this.state.userPairAnythingStatus == "active" &&
      this.state.getUserByEmailApiHit
    ) {
      toast.info(
        "You have already set your password. Please signin to continue!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );

      // return <Redirect push to="/sign-in" />;
      this.props.history.push("/sign-in");
    }
    // else if(this.state.userPairAnythingStatus == "inactive") {

    //   this.setState({
    //     inValidMessageFromResponse: 'Your account has been disabled. Please contact the winery admin! '
    //   })

    // }

    if (this.state.signInPageLoaded) {
      // return <Redirect push to="/set-password-success" />;
      this.props.history.push("/account-activated");
    }
  }

  render() {
    /* 
         #NOTE: If a user has already set his password, the response from the API Call getUserById
         will have isActive and isVerified set to true. If so, redirect to the signin page
    */
    // if(this.state.isActive == true && this.state.isVerified == true ) {

    return (
      <div className="set-password-main-background">
        <div className="set-password-main-container">
          <div className="header">
            <div className="logo-container">
              <img alt="PairAnything" className="logo" src={logo} />
            </div>
            {/* {isMobile && */}
            <div className="user-greeting-title">
              <p>
                {" "}
                Welcome to your new wine experience from <br />
                <strong className="winery-name-set-password">
                  Pair Anything
                  {/* {" "}
                {this.state.wineryName}{" "} */}
                </strong>
              </p>
            </div>
            {/* } */}
            {/* {!isMobile &&
              <div className="user-greeting-title-desktop">
                <p>
                  Password
              </p>
              </div>} */}
            {/* <div className = "set-password-title">
                        Set Password
                    </div> */}
          </div>
          <div className="form-errors-display">
            {
              // (this.state.buttonClick)?  <FormErrors formErrors={this.state.formErrors} /> : ''
            }
            {this.state.inValidMessageFromResponse != "" &&
            this.state.buttonClick
              ? this.state.inValidMessageFromResponse
              : ""}
          </div>
          {this.state.userPairAnythingStatus === "inactive" ||
          (this.state.userWineryStatus == 0 &&
            this.state.getUserByEmailApiHit) ? (
            <div className="form-errors-display">
              Your account has been disabled. Please contact the winery admin.
            </div>
          ) : (
            ""
          )}
          {this.state.userPairAnythingStatus === "invited" &&
            this.state.userWineryStatus === 1 && (
              <div className="set-password-form-parent">
                <div className="set-password-form-container">
                  <form className="set-password-form">
                    {/* <input
                                //  className="form-row"
                                className={`form-row ${this.state.passwordValid? '' : ' form-row-error'}`}
                                type="password" name="password" placeholder="Password"
                                value={this.state.password} onChange={this.handlePasswordChange}/>
                                <input
                                //  className="form-row" 
                                className={`form-row ${this.state.confirmPasswordValid? '' : ' form-row-error'}`}
                                type="password" name="confirmpassword" placeholder="Confirm Password"
                                value={this.state.confirmPassword} onChange={this.handleConfirmPasswordChange}/> */}

                    {/*
                                #NOTE: Input fields are from signin-form CSS
                              */}
                    <div className="signin-form-group form-group">
                      {!isMobile && (
                        <div className="form-email-text">Set Password</div>
                      )}
                      <input
                        className="form-control signin-form-row-input"
                        type="password"
                        name="password"
                        placeholder={isMobile ? "Set Password" : ""}
                        id="setPasswordPagePassword"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                      />
                      <label for="setPasswordPagePassword"></label>
                    </div>

                    <div className="signin-form-group form-group">
                      {!isMobile && (
                        <div className="form-email-text">Confirm Password</div>
                      )}
                      <input
                        className="form-control signin-form-row-input"
                        type="password"
                        name="confirmpassword"
                        placeholder={isMobile ? "Confirm Password" : ""}
                        id="setPasswordPageConfirmpassword"
                        value={this.state.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                      />
                      <label for="setPasswordPageConfirmpassword"></label>
                    </div>

                    <button
                      type="button"
                      onClick={this.setPasswordSubmit}
                      disabled={
                        this.state.userPairAnythingStatus != "invited" ||
                        this.state.userWineryStatus == 0
                      }
                      className="btn btn-round confirm-btn"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    setPasswordSuccess: state.SetPasswordReducer.setPasswordSuccess,
    setPasswordFail: state.SetPasswordReducer.setPasswordFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    SetPasswordAction: bindActionCreators(SetPasswordAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);

// export default SetPassword;
