import React, { useEffect, useState, useRef } from "react";
import styles from "./flavor-profile-card.module.scss";
import * as constants from "../../../constants/constants";
import useSWR from "swr";
import { get } from "../../../lib/fetch";
import { isNumber } from "../../../lib/common";

export default function FlavorProfileCard({ varietal = undefined }) {
  const [profile, setProfile] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `https://winery-service.pairanything.com/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles && varietal) {
      setProfile(varietalProfiles[varietal.trimEnd()]);
    }
  }, [varietalProfiles, varietal]);

  console.log(`profile:`);
  console.log(profile);

  if (!profile) return <></>;

  return (
    <div className={`${styles.varietalCard}`}>
      <div className={`${styles.flavorList}`}>
        {Object.keys(profile?.flavors)
          .map((flavor, i) => {
            if (i >= 3) return <></>;
            return (
              <div className="flavor-profile-list-item">
                <img
                  src={
                    [flavor] in profile?.flavors
                      ? profile?.flavors[flavor][
                          Math.floor(
                            Math.random() * profile?.flavors[flavor].length
                          )
                        ]
                      : undefined
                  }
                  className="flavor-profile-icon"
                />
                {flavor}
              </div>
            );
          })}
      </div>
      <div className="flex-col">
        {isNumber(profile?.sweetness) && (
          <MeasureBar
            value={profile?.sweetness}
            maxValue={5}
            minName="Dry"
            maxName="Sweet"
          />
        )}
        {isNumber(profile?.body) && (
          <MeasureBar
            value={profile?.body}
            maxValue={5}
            minName="Light"
            maxName="Bold"
          />
        )}
        {isNumber(profile?.tannins) && (
          <MeasureBar
            value={profile?.tannins}
            maxValue={5}
            minName="Low Tannin"
            maxName="High Tannin"
          />
        )}
      </div>
    </div>
  );
}

const MeasureBar = ({ value, maxValue, minName, maxName }) => {
  value = value < 0 ? 0 : value > 5 ? 5 : value;
  
  return (
    <div
      className="flex-row"
      style={{ maxHeight: "3px", marginBottom: "10%", width: "100%" }}
    >
      <p
        className={`${styles.valueLeft} ${value < 4 ? styles.valueBold : null}`}
      >
        {minName}
      </p>
      <div className={`${styles.valueContainer}`}>
        {[...new Array(value)].map((_, idx) => {
          return <div key={idx} className={`${styles.valuedBar}`} />;
        })}
        {[...new Array(maxValue - value)].map((_, idx) => {
          return <div key={idx} className={`${styles.notValuedBar}`} />;
        })}
      </div>
      <p
        className={`${styles.valueRight} ${
          value >= 4 ? styles.valueBold : null
        }`}
      >
        {maxName}
      </p>
    </div>
  );
};
