import React from "react";
import { useHistory } from "react-router-dom";
import backBtnBlack from "../../../assets/images/back-btn.svg";
const TermsOfUse = () => {
  const history = useHistory();
  return (
    <div className="sign-in-main-page overall-font">
      <div className="sign-in-main-container-background">
        <div className="sign-in-main-container padng-style height-for-terms-page mt-0">
          <div className="terms-header">
            <img
              className="ptr-clss back-btn-width"
              alt="Go Back"
              src={backBtnBlack}
              onClick={() => history.goBack()}
            />
            <div className="term-header-label">
              <span>Terms of Use</span>
            </div>
            <div>{""}</div>
          </div>
          <div className="terms-conditions-justify mt-4">
            <div>
              {/* <h4 className='all-terms-header-font terms-o'>Terms of Use</h4> */}
              <p>
                Please read these terms of use carefully before using the
                services offered by Pair Anything, Inc. (hereinafter “Pair
                Anything”, “we” or us”), These terms of use set forth the
                legally binding terms and conditions (hereafter referred to as
                “Terms”) between Pair Anything and the User for all use of our
                websites,{" "}
                <a
                  href="#"
                  className="pl-1 pr-1"
                  onClick={() =>
                    window.open("https://www.pairanything.com/", "_blank")
                  }
                >
                  www.PairAnything.com,
                </a>{" "}
                app.pairanything.com and winerypairanything.com (hereinafter
                “Internet Sites”), and all use of our application for mobile
                devices (hereinafter “App”). By using the functionalities of
                Pair Anything’s Internet Sites and/or App, (hereinafter
                collectively called the “Platform”), the User agrees with the
                terms and conditions described in these Terms for using our
                Platform as described herein, and as may be amended from time to
                time by us. The date of the most recent changes or revisions is
                September 9, 2020.
              </p>
              <p>
                Pair Anything provides an online platform that enables
                registered users (hereinafter singular “User” and plural
                “Users”) to get recommendations, shop and learn about wine via
                website or mobile application. It also enables wineries and
                service provider partners (“hereinafter singular “Partner and
                plural “Partners”) to connect directly with Users through the
                platform.
              </p>
            </div>
            <div>
              <h4 className="all-terms-header-font">Scope of terms</h4>
              <p>
                In order to use our Platform, the User must either sign in using
                a third-party application log-in, or fill out a form and create
                a profile without a third-party application log-in.
              </p>
              <p>
                When registering via our online Platform, the User provide us
                with certain information, including email, password, username,
                name, country of origin, preferred language. When registering
                and signing in via third-party application login (such as
                Facebook), and the User authorize us to access certain account
                information from that third-party application, such as basic
                profile information; name, e-mail address, current city
                (optional). Some of this information will never be made public,
                such as password.
              </p>
              <p>
                The Platform is intended for use by adults of legal drinking age
                in the jurisdiction in which you reside. If we receive notice or
                reasonably believe that someone under the legal drinking age has
                provided us with personal information, we will promptly delete
                the account and all personal information that has been provided
                to us.
              </p>
            </div>
            <div>
              <h4 className="all-terms-header-font">Disclaimer</h4>
              <p>
                The materials contained on the App are provided as a service to
                you for your use on an "as is, as available" basis. You
                acknowledge that you are using the App at your own risk. Pair
                Anything, Inc. assumes no responsibility for error or omissions
                in these materials. Pair Anything, Inc. makes no commitment to
                update the information contained herein. Pair Anything, Inc.
                makes no, and expressly disclaims any and all, representations
                or warranties, express or implied, regarding the App, including
                without limitation the accuracy, completeness, timeliness, or
                reliability of text, graphics, links, products and services, and
                other items accessed from or via the App, or that the App will
                be uninterrupted, error-free, or free of viruses or other
                harmful components. No advice or information given by Pair
                Anything, Inc. or any other party on the App shall create any
                warranty or liability. the App and the materials provided herein
                are for informational purposes only and are not legal advice. No
                reader should rely on or act or refrain from acting on the basis
                of any matter or information contained in the App without
                seeking appropriate legal or other professional advice.
              </p>
              <p>
                Under no circumstances shall Pair Anything, Inc. or any of its
                parents, subsidiaries, affiliates, members, advisors or their
                respective partners, officers, directors, employees, or agents
                be held liable for any damages, whether direct, incidental,
                indirect, special, or consequential, and including, without
                limitation, lost data, revenues, or profits, loss of or damage
                to property and claims of third parties, arising from or in
                connection with your use or performance of the App or your
                reliance on the information on the App.
              </p>
              <p>
                Pair Anything, Inc. periodically schedules system downtime for
                maintenance and other purposes. Unplanned outages also may
                occur. Pair Anything, Inc. shall have no liability for the
                resulting unavailability of the App or for any loss of data or
                transactions caused by planned or unplanned system outages, or
                any outages of web host providers or the Internet infrastructure
                and network external to the App.
              </p>
            </div>
            <div>
              <h4 className="all-terms-header-font">Copyright</h4>
              <p>
                All materials published on the App, including the compilation
                (meaning the collection, arrangement and assembly) of all
                content on the App, are protected by U.S. and international
                copyright laws. You may not reproduce, republish, distribute,
                transmit, resell, display, broadcast, or otherwise exploit such
                material in any manner without the express written permission of
                either Pair Anything, Inc. and, in the case of content licensed
                by Pair Anything, Inc. from third parties, the entity that is
                credited as the copyright holder of such licensed content.
              </p>
            </div>
            <div>
              <h4 className="all-terms-header-font">Trademarks</h4>
              <p>
                Pair Anything, and all page headers, custom graphics and button
                icons are service marks, trademarks, and/or trade dress of Pair
                Anything, Inc. and may not be used in connection with any
                product or service that is not offered by Pair Anything, Inc.
                All other trademarks and company names or logos cited herein are
                the property of their respective owners.
              </p>
            </div>
            <div>
              <h4 className="all-terms-header-font">Partners</h4>
              <p>
                The App provides information from or about wineries and other
                service providers . The companies that Pair Anything, Inc.
                selects as featured partners offer products and services that
                Site visitors may find useful. Pair Anything, Inc. in certain
                cases may have a business relationship with certain third
                parties that it selects as featured partners. Pair Anything,
                Inc. is not responsible for the accuracy or reliability of any
                advice or information given, or of any statement made, by or
                regarding such featured service providers, or for the content,
                services, products, or advertising on or available from their
                websites, or for the availability and operations of their
                websites.
              </p>
            </div>
            <div>
              <h4 className="all-terms-header-font">Jurisdiction</h4>
              <p>
                You agree that any claim, action, or proceeding arising out of
                these Terms and Conditions, or your use of the App, shall be
                governed by and construed in accordance with the laws of the
                State of Delaware applicable to contracts to be wholly performed
                therein, and any action based on or alleging a breach of this
                Agreement must be brought in a state or federal court in
                Delaware. In addition, you agree to submit to the personal
                jurisdiction of such courts.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsOfUse;
