import React, { Component } from 'react';
import Cookies from 'js-cookie'
import { unstable_createPortal } from 'react-dom/cjs/react-dom.development';
import likeButtonRed from '../../../assets/images/like-btn-red.svg'; 
import likeButtonWhite from '../../../assets/images/like-btn-white.svg'; 

class LikeButton extends Component {
    constructor() {
        super()
        let userData= JSON.parse(localStorage.getItem("user_data"))
        let userId= userData!=null? userData.id:''
        this.state = {
            liked: false,
            id: "user" + userId,
            init: false,
            checked: false,
        }
        this.toggleLike = this.toggleLike.bind(this)
    }

    toggleLike() {
        let beforeParse = localStorage.getItem(this.state.id)
        let curFav;
          if (beforeParse) curFav = JSON.parse(localStorage.getItem(this.state.id))

        console.log(curFav)

        let good
        if (curFav == undefined) {
            curFav = {[this.props.wine.wineId]: 1}
            good = true
        } else if (curFav[this.props.wine.wineId] === 1) {
            delete curFav[this.props.wine.wineId]
            //curFav[this.props.wine.wineId] = 1
            good = false
        } else {
            curFav[this.props.wine.wineId] = 1
            good = true

        }
        localStorage.setItem([this.state.id], JSON.stringify(curFav))
        this.setState(prevState => {
            return {
                liked: !prevState.liked,
                init: true,
                checked: good,
            }
        })
    }

    render() {        
        const id = this.props.wine.wineId
        let status = JSON.parse(localStorage.getItem(this.state.id));
        let image = likeButtonWhite;
        if (!(status && status.hasOwnProperty(id))) image = likeButtonWhite;
        else image = likeButtonRed;
        return (
            <div {...{
                className: "like-btn",
                onClick: (e) => {
                    e.stopPropagation();
                    this.toggleLike()
                }}}>         
                <img src={image}></img>
            </div>
        )
    }
}

export default LikeButton