import React, { useEffect, useState } from "react";
import styles from "./actions-row.module.scss";
import SaveIcon from "../../../../assets/images/PAIcons/save.svg";
import ShareIcon from "../../../../assets/images/PAIcons/share.svg";
import LikeIcon from "../../../../assets/images/PAIcons/like.svg";
import DislikeIcon from "../../../../assets/images/PAIcons/dislike.svg";
import ShareMethodsRow from "../ShareMethodsRow/ShareMethodsRow";
import { useHistory } from "react-router-dom";
import { useLoggedIn } from "../../../lib/hooks/useLoggedIn";
import * as constants from "../../../constants/constants";
import { put, post } from "../../../lib/fetch";
import { sessionUrl } from "../../../lib/common/sessionUrl";
import AnimationWrapper from "../AnimationWrapper/AnimationWrapper";
import ChevronRight from "../../../../assets/images/PAIcons/chevron-right.svg";
import { IDENTITY_API } from "../../../constants/constants";
import { useSessionUrl } from "../../../lib/hooks/useSession";

export default function ActionsRow({
  varietalId,
  pairingResult,
  foodName,
  itemType = "",
  wineId,
}) {
  const { loggedIn, roleId, consumerData } = useLoggedIn();

  const { data: userSaves } = useSessionUrl(
    `${IDENTITY_API}/api/v1/public/user/save/getUserSaves`
  );

  const { data: userLikes } = useSessionUrl(
    `${IDENTITY_API}/api/v1/public/user/like/getUserLikes`
  );

  console.log(`pairingResult:`);
  console.log(pairingResult);

  // console.log(`userSaves:`);
  // console.log(userSaves);

  // console.log(`userLikes:`);
  // console.log(userLikes);

  // console.log(pairingResult);

  const [saved, setSaved] = useState(false);
  const [share, setShare] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [tellUsWhyOpen, setTellUsWhyOpen] = useState(false);
  const [currentMsg, setCurrentMsg] = useState(null);
  const [blueBox, setBlueBox] = useState(false);

  useEffect(() => {
    if (!!userSaves && userSaves.length > 0) {
      let userSavesList = [];
      if (itemType == "varietal_reco") {
        userSavesList = userSaves.filter((item) => {
          return item.itemType == 2 && item.itemId == pairingResult?.info?.id;
        });
      } else if (itemType == "food_reco") {
        userSavesList = userSaves.filter((item) => {
          return (
            item.itemType == 1 && item.itemId == pairingResult?.varietal?.id
          );
        });
      } else if (itemType == "wine_reco") {
        userSavesList = userSaves.filter((item) => {
          return item.itemType == 3 && item.itemId == pairingResult?.info?.id;
        });
      } else if (itemType == "wine") {
        userSavesList = userSaves.filter((item) => {
          return item.itemType == 4 && item.itemId == wineId;
        });
      }
      if (userSavesList.length > 0) {
        if (userSavesList[0].saved == true) {
          setSaved(true);
        }
      }
    }
  }, [userSaves]);

  useEffect(() => {
    if (!!userLikes && userLikes.length > 0) {
      let userLikesList = [];
      if (itemType == "varietal_reco") {
        userLikesList = userLikes.filter((item) => {
          return item.itemType == 2 && item.itemId == pairingResult?.info?.id;
        });
      } else if (itemType == "wine_reco") {
        userLikesList = userLikes.filter((item) => {
          return item.itemType == 3 && item.itemId == pairingResult?.info?.id;
        });
      } else if (itemType == "food_reco") {
        userLikesList = userLikes.filter((item) => {
          return (
            item.itemType == 1 && item.itemId == pairingResult?.varietal?.id
          );
        });
      } else if (itemType == "wine") {
        userLikesList = userLikes.filter((item) => {
          return item.itemType == 4 && item.itemId == wineId;
        });
      }

      if (userLikesList.length > 0) {
        if (userLikesList[0].liked == true) {
          setLiked(true);
        } else {
          setDisliked(true);
        }
      }
    }
  }, [userLikes]);

  const onClickSave = async () => {
    let saveBody = {};
    if (itemType == "wine") {
      saveBody = {
        itemType: 4,
        itemId: wineId,
        saved: !saved,
      };
    } else if (itemType == "food_reco") {
      saveBody = {
        itemType: 1,
        itemId: pairingResult?.varietal?.id,
        saved: !saved,
      };
    } else if (itemType == "varietal_reco") {
      saveBody = {
        itemType: 2,
        itemId: pairingResult?.info?.id,
        saved: !saved,
      };
    } else if (itemType == "wine_reco") {
      saveBody = {
        itemType: 3,
        itemId: pairingResult?.info?.id,
        saved: !saved,
      };
    }

    let url = `${constants.IDENTITY_API}/api/v1/public/user/save/update`;

    try {
      const response = await put(
        sessionUrl(url),
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        saveBody
      );
      const result = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }

    if (!saved) {
      if (!!loggedIn) {
        setCurrentMsg("saved_user");
      } else {
        setCurrentMsg("saved");
      }
      setBlueBox(true);
    } else {
      setBlueBox(false);
    }
    setSaved(!saved);
  };

  const onClickShare = () => {
    setShare(!share);
  };

  const onClickLike = async () => {
    setDisliked(false);
    if (!liked) {
      let likeBody = {};
      if (itemType == "wine") {
        likeBody = {
          itemType: 4,
          itemId: wineId,
          liked: true,
        };
      } else if (itemType == "food_reco") {
        likeBody = {
          itemType: 1,
          itemId: pairingResult?.varietal?.id,
          liked: true,
        };
      } else if (itemType == "varietal_reco") {
        likeBody = {
          itemType: 2,
          itemId: pairingResult?.info?.id,
          liked: true,
        };
      } else if (itemType == "wine_reco") {
        likeBody = {
          itemType: 3,
          itemId: pairingResult?.info?.id,
          liked: true,
        };
      }

      let url = `${constants.IDENTITY_API}/api/v1/public/user/like/update`;

      try {
        const response = await put(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          likeBody
        );
        const result = await response.json();
      } catch (error) {
        console.error("Error:", error);
      }

      if (!!loggedIn) {
        setCurrentMsg("like_user");
      } else {
        setCurrentMsg("like");
      }

      setBlueBox(true);
    } else if (!liked && itemType != "varietal_reco") {
      if (!!loggedIn) {
        setCurrentMsg("like_user");
      } else {
        setCurrentMsg("like");
      }
      setBlueBox(true);
    } else {
      setBlueBox(false);
    }
    setLiked(!liked);
  };

  const onClickDislike = async () => {
    setLiked(false);
    if (!disliked) {
      let dislikeBody = {};
      if (itemType == "wine") {
        dislikeBody = {
          itemType: 4,
          itemId: wineId,
          liked: false,
        };
      } else if (itemType == "food_reco") {
        dislikeBody = {
          itemType: 1,
          itemId: pairingResult?.varietal?.id,
          liked: false,
        };
      } else if (itemType == "varietal_reco") {
        dislikeBody = {
          itemType: 2,
          itemId: pairingResult?.info?.id,
          liked: false,
        };
      } else if (itemType == "wine_reco") {
        dislikeBody = {
          itemType: 3,
          itemId: pairingResult?.info?.id,
          liked: false,
        };
      }

      let url = `${constants.IDENTITY_API}/api/v1/public/user/like/update`;

      try {
        const response = await put(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          dislikeBody
        );
        const result = await response.json();
      } catch (error) {
        console.error("Error:", error);
      }

      if (!!loggedIn) {
        setCurrentMsg("dislike_user");
      } else {
        setCurrentMsg("dislike");
      }
      setBlueBox(true);
    } else if (!disliked && itemType != "varietal_reco") {
      if (!!loggedIn) {
        setCurrentMsg("dislike_user");
      } else {
        setCurrentMsg("dislike");
      }
      setBlueBox(true);
    } else {
      setBlueBox(false);
    }
    setDisliked(!disliked);
  };

  const onClickTellUsWhy = () => {
    setTellUsWhyOpen(!tellUsWhyOpen);
  };

  const onClickShareFromShare = () => {
    setShare(!share);
  };

  const stopParentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`${styles.main}`}>
      {share && <ShareMethodsRow setShare={onClickShareFromShare} />}
      {tellUsWhyOpen && (
        <TellUsWhy
          setTellUsWhyOpen={onClickTellUsWhy}
          pairingResult={pairingResult}
          foodName={foodName}
        />
      )}
      {tellUsWhyOpen && (
        <TellUsWhy
          setTellUsWhyOpen={onClickTellUsWhy}
          pairingResult={pairingResult}
          foodName={foodName}
        />
      )}

      <div className={`mc flex-row`} style={{ gap: "40px" }}>
        <div
          className={styles.action}
          onClick={() => {
            onClickSave();
          }}
        >
          <div
            className={`${styles.actionCircle} ${
              saved ? styles.actionCircleActive : null
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.50435 1.56522C2.27063 1.56522 2.03647 1.66326 1.85631 1.85434C1.67469 2.04697 1.56522 2.31757 1.56522 2.6087V15.6427L6.33611 12.0284C6.61559 11.8166 7.00179 11.8166 7.28128 12.0284L12.0522 15.6427V2.6087C12.0522 2.31758 11.9427 2.04697 11.7611 1.85434C11.5809 1.66326 11.3468 1.56522 11.113 1.56522H2.50435ZM0.717476 0.780577C1.1831 0.286734 1.8248 0 2.50435 0H11.113C11.7926 0 12.4343 0.286735 12.8999 0.780577C13.3641 1.27286 13.6174 1.9312 13.6174 2.6087V17.2174C13.6174 17.5145 13.4491 17.786 13.1831 17.9182C12.917 18.0504 12.599 18.0206 12.3622 17.8412L6.80869 13.634L1.25519 17.8412C1.01837 18.0206 0.700372 18.0504 0.434307 17.9182C0.168243 17.786 0 17.5145 0 17.2174V2.6087C0 1.9312 0.253325 1.27286 0.717476 0.780577Z"
              />
            </svg>
          </div>
          <p>Save</p>
        </div>
        <div
          className={styles.action}
          onClick={() => {
            onClickShare();
          }}
        >
          <div
            className={`${styles.actionCircle} ${
              share ? styles.actionCircleActive : null
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="currentColor"
            >
              <path d="M1.3125 7.49967V16.5C1.3125 16.6182 1.4105 16.7142 1.53125 16.7142H12.4687C12.5268 16.7142 12.5824 16.6917 12.6234 16.6515C12.6645 16.6113 12.6875 16.5568 12.6875 16.5V7.49967C12.6875 7.44284 12.6645 7.38833 12.6234 7.34814C12.5824 7.30795 12.5268 7.28538 12.4687 7.28538H10.2812C10.1072 7.28538 9.94028 7.21765 9.81721 7.09708C9.69414 6.97652 9.625 6.813 9.625 6.6425C9.625 6.472 9.69414 6.30848 9.81721 6.18792C9.94028 6.06735 10.1072 5.99962 10.2812 5.99962H12.4687C13.314 5.99962 14 6.67164 14 7.49967V16.5C14 16.8978 13.8387 17.2793 13.5515 17.5606C13.2643 17.842 12.8749 18 12.4687 18H1.53125C1.12514 18 0.735658 17.842 0.448493 17.5606C0.161328 17.2793 0 16.8978 0 16.5V7.49967C0 6.67164 0.686 5.99962 1.53125 5.99962H3.71875C3.8928 5.99962 4.05972 6.06735 4.18279 6.18792C4.30586 6.30848 4.375 6.472 4.375 6.6425C4.375 6.813 4.30586 6.97652 4.18279 7.09708C4.05972 7.21765 3.8928 7.28538 3.71875 7.28538H1.53125C1.47323 7.28538 1.41759 7.30795 1.37657 7.34814C1.33555 7.38833 1.3125 7.44284 1.3125 7.49967ZM7.46375 0.188012L10.3075 2.97381C10.3939 3.05239 10.457 3.15234 10.4903 3.26313C10.5236 3.37392 10.5257 3.49144 10.4965 3.60332C10.4673 3.7152 10.4078 3.8173 10.3244 3.89885C10.2409 3.9804 10.1365 4.03839 10.0223 4.06671C9.90817 4.09535 9.78832 4.09335 9.6753 4.06092C9.56228 4.02849 9.46027 3.96683 9.38 3.88241L7.65625 2.19379V11.3569C7.65625 11.5274 7.58711 11.691 7.46404 11.8115C7.34097 11.9321 7.17405 11.9998 7 11.9998C6.82595 11.9998 6.65903 11.9321 6.53596 11.8115C6.41289 11.691 6.34375 11.5274 6.34375 11.3569V2.19379L4.62 3.88241C4.49552 3.99591 4.33098 4.05771 4.16095 4.05484C3.99092 4.05196 3.82865 3.98463 3.70825 3.86698C3.58816 3.74904 3.51943 3.59007 3.51649 3.42351C3.51355 3.25695 3.57664 3.09576 3.6925 2.97381L6.53625 0.188012C6.6593 0.0676218 6.82609 0 7 0C7.17391 0 7.3407 0.0676218 7.46375 0.188012Z" />
            </svg>
          </div>
          <p>Share</p>
        </div>
        <div
          className={styles.action}
          onClick={() => {
            onClickLike();
          }}
        >
          <div
            className={`${styles.actionCircle} ${
              liked ? styles.actionCircleActive : null
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.42178 0.494885C7.55553 0.193945 7.85396 0 8.18329 0C9.05408 0 9.8892 0.34592 10.5049 0.961662C11.1207 1.5774 11.4666 2.41253 11.4666 3.28332V5.71663H15.2514C15.6071 5.71335 15.9594 5.78708 16.2841 5.93279C16.6103 6.0792 16.9008 6.29479 17.1354 6.56461C17.3701 6.83444 17.5432 7.15206 17.6429 7.49545C17.7426 7.83885 17.7665 8.19981 17.7128 8.55334L16.5858 15.9032C16.4965 16.4911 16.1977 17.0277 15.7446 17.4128C15.2926 17.7969 14.7174 18.0053 14.1244 17.9999H2.46666C1.81246 17.9999 1.18505 17.74 0.722467 17.2774C0.259879 16.8148 0 16.1874 0 15.5332V9.81661C0 9.16241 0.259879 8.53501 0.722467 8.07242C1.18505 7.60983 1.81246 7.34995 2.46666 7.34995H4.37508L7.42178 0.494885ZM4.08331 9.01662H2.46666C2.25449 9.01662 2.05101 9.1009 1.90098 9.25093C1.75095 9.40096 1.66667 9.60444 1.66667 9.81661V15.5332C1.66667 15.7454 1.75095 15.9489 1.90098 16.0989C2.05101 16.2489 2.25449 16.3332 2.46666 16.3332H4.08331V9.01662ZM5.74998 16.3332H14.138C14.3309 16.3354 14.5182 16.2678 14.6652 16.1429C14.8122 16.0179 14.9091 15.8441 14.938 15.6533L16.065 8.30323C16.0823 8.18869 16.0746 8.07139 16.0423 7.96014C16.01 7.84876 15.9538 7.74576 15.8777 7.65825C15.8017 7.57074 15.7074 7.50081 15.6016 7.45333C15.4958 7.40584 15.381 7.38193 15.265 7.38324L15.2556 7.38335L10.6333 7.3833C10.173 7.3833 9.79994 7.0102 9.79994 6.54996V3.28332C9.79994 2.85456 9.62961 2.44335 9.32643 2.14017C9.14585 1.95959 8.92694 1.82614 8.68882 1.74774L5.74998 8.36013V16.3332Z"
              />
            </svg>
          </div>
          <p>Like</p>
        </div>
        <div
          className={styles.action}
          onClick={() => {
            onClickDislike();
          }}
        >
          <div
            className={`${styles.actionCircle} ${
              disliked ? styles.actionCircleActive : null
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7237 1.92069C15.5279 1.7526 15.2772 1.66227 15.0191 1.66683L15.0044 1.66709L13.6573 1.66696V8.98347H15.0191C15.2772 8.98803 15.5279 8.89782 15.7237 8.72974C15.9073 8.57211 16.0307 8.35639 16.0739 8.11923V2.5312C16.0307 2.29404 15.9073 2.07832 15.7237 1.92069ZM11.9906 9.63995V1.66696H3.60269C3.40977 1.66478 3.22255 1.73234 3.07555 1.8573C2.92854 1.98225 2.83165 2.15613 2.80272 2.34688L2.80252 2.34824L1.67573 9.69686C1.6757 9.69703 1.67575 9.69668 1.67573 9.69686C1.65843 9.81134 1.66611 9.92875 1.69839 10.0399C1.73072 10.1513 1.78688 10.2543 1.86298 10.3418C1.93907 10.4293 2.03328 10.4993 2.13908 10.5467C2.24488 10.5942 2.35974 10.6181 2.4757 10.6168L2.48513 10.6167L7.10738 10.6168C7.56762 10.6168 7.94072 10.9899 7.94072 11.4501V14.7167C7.94072 15.1455 8.11104 15.5567 8.41421 15.8598C8.59479 16.0404 8.8137 16.1739 9.05181 16.2523L11.9906 9.63995ZM13.3655 10.6501L10.3188 17.5051C10.1851 17.8061 9.88666 18 9.55734 18C8.68656 18 7.85144 17.6541 7.2357 17.0383C6.61997 16.4226 6.27405 15.5875 6.27405 14.7167V12.2834H2.48941C2.13362 12.2867 1.7813 12.213 1.45664 12.0673C1.13042 11.9209 0.83993 11.7053 0.605299 11.4355C0.370668 11.1656 0.197507 10.848 0.0978119 10.5046C-0.00188273 10.1612 -0.0257271 9.80027 0.0279316 9.44675L1.1549 2.09699C1.15486 2.09724 1.15493 2.09674 1.1549 2.09699C1.24423 1.50911 1.54305 0.972523 1.99613 0.5874C2.4481 0.203234 3.02331 -0.00516139 3.61634 0.000295354H14.9977C15.6612 -0.00947981 16.3056 0.223681 16.8092 0.656025C17.315 1.09011 17.6437 1.69478 17.733 2.35523C17.738 2.39225 17.7405 2.42957 17.7405 2.46693V8.18349C17.7405 8.22086 17.738 8.25817 17.733 8.2952C17.6437 8.95565 17.315 9.56031 16.8092 9.9944C16.3056 10.4267 15.6612 10.6599 14.9977 10.6501H13.3655Z"
              />
            </svg>
          </div>
          <p>Dislike</p>
        </div>
      </div>
      <div className={`flex-row`} style={{ width: "100%" }}>
        <AnimationWrapper
          animation="expand"
          active={blueBox}
          gapDelete={"-24px"}
          gapOnActive="bottom"
        >
          <div className={`${styles.blueBox}`}>
            <BlueMessage
              currentMsg={currentMsg}
              setTellUsWhyOpen={onClickTellUsWhy}
            />
          </div>
        </AnimationWrapper>
      </div>
    </div>
  );
}

const BlueMessage = ({ currentMsg, setTellUsWhyOpen }) => {
  const history = useHistory();
  useEffect(() => {
    //idk yet
  }, [currentMsg]);

  if (currentMsg == null) {
    return <></>;
  } else if (currentMsg == "saved") {
    return (
      <p className={`${styles.blueBoxText}`}>
        <span
          className={`${styles.underlined}`}
          onClick={() =>
            history.push({
              pathname: `/signup/setup`,
            })
          }
        >
          Create an account
        </span>{" "}
        to save this pairing
      </p>
    );
  } else if (currentMsg == "saved_user") {
    return (
      <p className={`${styles.blueBoxText}`}>
        Added to{" "}
        <span
          className={`${styles.underlined}`}
          // onClick={() =>
          //   history.push({
          //     pathname: `/signup/setup`,
          //   })
          // }
        >
          Saved Wines
        </span>
      </p>
    );
  } else if (currentMsg == "share") {
    return (
      <p className={`${styles.blueBoxText}`}>
        <span>Create an account</span> to save this pairing
      </p>
    );
  } else if (currentMsg == "like_user") {
    return (
      <p className={`${styles.blueBoxText}`}>
        Great! We'll recommend more wines like this.
      </p>
    );
  } else if (currentMsg == "like") {
    return (
      <p className={`${styles.blueBoxText}`}>
        <span
          className={`${styles.underlined}`}
          onClick={() =>
            history.push({
              pathname: `/signup/setup`,
            })
          }
        >
          Create an account
        </span>{" "}
        and we'll recommend more wines like this.
      </p>
    );
  } else if (currentMsg == "dislike") {
    return (
      <p className={`${styles.blueBoxText}`}>
        We know that taste is unique!{" "}
        <span
          onClick={() => setTellUsWhyOpen(true)}
          className={`${styles.underlined}`}
        >
          Tell us why you didn't like this wine
        </span>{" "}
        so we can recommend more of what you enjoy.
      </p>
    );
  } else if (currentMsg == "dislike_user") {
    return (
      <p className={`${styles.blueBoxText}`}>
        Got it. We won't show this wine again.{" "}
        <span
          onClick={() => setTellUsWhyOpen(true)}
          className={`${styles.underlined}`}
        >
          Tell us why you didn't like this wine
        </span>
      </p>
    );
  }
  return <p className={`${styles.blueBoxText}`}>Default Message</p>;
};

const TellUsWhy = ({ setTellUsWhyOpen, pairingResult, foodName }) => {
  const history = useHistory();
  const { loggedIn, roleId, consumerData } = useLoggedIn();

  const [varietal, setVarietal] = useState(false);
  const [type, setType] = useState(false);
  const [pairing, setPairing] = useState(false);
  const [other, setOther] = useState(false);
  const [otherText, setOtherText] = useState("");
  const [feedBackSubmittable, setFeedBackSubmittable] = useState(false);
  const [thankYou, setThankYou] = useState(false);

  useEffect(() => {
    if (
      ((other && otherText != "") || !other) &&
      (type || varietal || pairing || other)
    ) {
      setFeedBackSubmittable(true);
    } else {
      setFeedBackSubmittable(false);
    }
  }, [varietal, type, pairing, other, otherText]);

  const stopParentClick = (e) => {
    e.stopPropagation();
  };

  const onHandleChange = (e) => {
    setOtherText(e.target.value);
  };

  const onSubmit = () => {
    setThankYou(true);
  };

  const clickSignIn = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/login`,
      state: { from: "left" },
    });
  };

  const clickManage = (e) => {
    e.preventDefault();
    // history.push({
    //   pathname: `/signup/setup`,
    //   state: { from: "left" },
    // });
  };

  return (
    <div
      className={`${styles.modalBackground} ${styles.fadeIn}`}
      onClick={() => setTellUsWhyOpen()}
    >
      {!thankYou ? (
        <div className={styles.modalBox} onClick={(e) => stopParentClick(e)}>
          <div className={`${styles.modalContent}`}>
            <h2 className="h2-22">Tell us why you disliked this wine</h2>
            <div className={styles.optionsBox}>
              <div
                className={`${styles.option}`}
                onClick={() => setVarietal(!varietal)}
              >
                <div
                  className={`${styles.checkbox} ${
                    varietal ? styles.checked : null
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                  >
                    <path
                      d="M2 7.76923L6.33333 12L15 2"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <p className={`p1-reg`}>
                  I don't like {pairingResult?.varietal?.varietal}
                </p>
              </div>
              <div
                className={`${styles.option}`}
                onClick={() => setType(!type)}
              >
                <div
                  className={`${styles.checkbox} ${
                    type ? styles.checked : null
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                  >
                    <path
                      d="M2 7.76923L6.33333 12L15 2"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <p className={`p1-reg`}>
                  I don't like{" "}
                  {pairingResult?.varietal?.wineTypeName?.toLowerCase()} wine
                </p>
              </div>
              <div
                className={`${styles.option}`}
                onClick={() => setPairing(!pairing)}
              >
                <div
                  className={`${styles.checkbox} ${
                    pairing ? styles.checked : null
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                  >
                    <path
                      d="M2 7.76923L6.33333 12L15 2"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <p className={`p1-reg`}>
                  I don't like pairing {pairingResult?.varietal?.varietal} with{" "}
                  {foodName}
                </p>
              </div>
              <div
                className={`${styles.option}`}
                onClick={() => setOther(!other)}
              >
                <div
                  className={`${styles.checkbox} ${
                    other ? styles.checked : null
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                  >
                    <path
                      d="M2 7.76923L6.33333 12L15 2"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <p className={`p1-reg`}>Other</p>
              </div>
              <AnimationWrapper
                animation="expand"
                active={other}
                gapDelete={"-16px"}
                gapOnInactive="top"
              >
                <input
                  placeholder="Please specify"
                  id="userInput"
                  name="userInput"
                  type="text"
                  onChange={(e) => onHandleChange(e)}
                  value={otherText}
                  className={`${styles.formInput} p1-reg`}
                />
              </AnimationWrapper>
            </div>
            <button
              className={`${styles.feedBackBtn} ${
                !feedBackSubmittable ? styles.grayedOut : null
              }`}
              onClick={() => onSubmit()}
            >
              Send Feedback
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.modalBox} onClick={(e) => stopParentClick(e)}>
          <div className={`${styles.modalContent}`}>
            <div style={{ alignSelf: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="125"
                height="135"
                viewBox="0 0 125 135"
                fill="none"
              >
                <path
                  d="M64.7382 35.8587C66.8712 27.693 92.0228 22.5999 95.9524 27.5206C95.9524 27.5206 115.275 63.9138 101.816 85.7474C100.634 91.6272 105.429 108.535 109.374 108.079C113.318 107.623 119.499 106.229 123.606 108.629C126.41 110.267 120.994 115.47 107.742 118.65C89.9912 122.91 83.8019 120.471 86.7301 116.873C88.3193 114.92 96.7667 112.167 97.9478 110.758C101.305 106.752 97.2089 90.5331 92.5008 87.8259C73.6432 81.3879 64.7382 35.8587 64.7382 35.8587ZM96.8546 85.24C111.542 74.6347 97.7915 38.1778 97.7915 38.1778C86.9779 32.0434 92.2345 59.7197 71.0109 49.0587C71.0109 49.0587 78.9169 81.341 96.8546 85.24Z"
                  fill="#951901"
                />
                <path
                  d="M60.7145 48.7663C58.6259 40.5891 33.5023 35.3595 29.546 40.2588C29.546 40.2588 10.0261 76.5465 23.3661 98.4529C24.5165 104.339 19.6294 121.221 15.6875 120.743C11.7454 120.266 5.57208 118.839 1.45193 121.216C-1.36078 122.839 4.02725 128.071 17.2619 131.323C34.9891 135.679 41.1915 133.274 38.2829 129.66C36.7044 127.698 28.272 124.899 27.0986 123.484C23.7636 119.46 27.9474 103.264 32.6701 100.582C51.5623 94.2464 60.7145 48.7663 60.7145 48.7663ZM28.3304 97.9725C13.7009 87.2875 27.649 50.9058 27.649 50.9058C38.4958 44.8303 33.089 72.4776 54.3702 61.932C54.3702 61.932 46.2889 94.1709 28.3304 97.9725Z"
                  fill="#951901"
                />
                <path
                  d="M44 2.2207L52.5117 0.00035299L54.5205 17.6582L50.8726 18.6098L44 2.2207Z"
                  fill="#951901"
                />
                <path
                  d="M64.9219 4L71.0075 7.46106L62.3466 18.6434L59.7385 17.1601L64.9219 4Z"
                  fill="#951901"
                />
                <path
                  d="M31 22.7031L33.0197 15.9998L45.8493 21.9545L44.9837 24.8274L31 22.7031Z"
                  fill="#951901"
                />
              </svg>
            </div>
            <div className={styles.thankYouTextBox}>
              <h2 className="h2-22">Thank you for your feedback!</h2>
              {loggedIn ? (
                <p className="p1-reg">
                  Your likes and dislikes help PairAnything personalize your
                  experience.
                </p>
              ) : (
                <p className="p1-reg">
                  Your likes and dislikes help PairAnything personalize your
                  experience. Create an account to save your taste preferences!
                </p>
              )}
            </div>
            {!loggedIn ? (
              <>
                <button
                  className={styles.createAccountBtn}
                  onClick={() =>
                    history.push({
                      pathname: `/signup/setup`,
                    })
                  }
                >
                  Create Account
                </button>
                <button
                  className={styles.signInBtn}
                  style={{ alignSelf: "center" }}
                  onClick={(e) => clickSignIn(e)}
                >
                  <p className={`p1-reg ${styles.signInBtnTxt}`}>Sign In</p>
                  <img src={ChevronRight} alt="chevron right" />
                </button>
              </>
            ) : (
              <>
                <button
                  className={styles.signInBtn}
                  style={{ alignSelf: "center" }}
                  onClick={(e) => clickManage(e)}
                >
                  <p className={`p1-reg ${styles.signInBtnTxt}`}>
                    Manage Dislikes
                  </p>
                  <img src={ChevronRight} alt="chevron right" />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
