import axiosCall from "../../constants/index";
import * as constants from "../../constants/constants";

export const foodPairingAdd = async (dishName) => {
  let consumerData = JSON.parse(localStorage.getItem("user_data"));
  let sessionData = JSON.parse(localStorage.getItem("anonymous_session"))
  let url = constants.IDENTITY_API + "/api/v1/public/foodPairing/add";
  if(consumerData && consumerData !=null)
    url = url + "?userId=" + consumerData.id;
  else
    url = url + "?userSessionId=" + sessionData.id ;
  const path = `${url}&name=${dishName}`;
  let response = await axiosCall("get", path);
  return response.data;
};

export const getHistory = async () => {
  let consumerData = JSON.parse(localStorage.getItem("user_data"));
  let sessionData = JSON.parse(localStorage.getItem("anonymous_session"))
  let url = constants.IDENTITY_API + "/api/v1/public/foodPairing/allSearchResults";
  if(consumerData && consumerData !=null)
    url = url + "?userId=" + consumerData.id;
  else
    url = url + "?userSessionId=" + sessionData.id
  const path = `${url}`;
  let response = await axiosCall("get", path);
  return response.data;
};

export const getFoodResult = async (dishName) => {
  let url =
    constants.GOOGLE_SEARCH_API + "/api/v1/public/foodNamesDetails/getPhonetic";
  const path = `${url}?value=${dishName}`;
  let response = await axiosCall("get", path);
  return response.data;
};
