import React, { Component } from "react";
import "./EventCard.scss";
import moment from "moment";

import RsvpFormPopup from "../../components/RsvpFormPopup/RsvpFormPopup";

// import shareIcon from "../../../assets/images/Events/events-page-share-icon.svg";
// import likeIcon from "../../../assets/images/Events/events-page-like-icon.svg";
import eventDefaultImage from "../../../assets/images/Events/default-image-individual-events.jpg";
import calendarIcon from "../../../assets/images/Events/black-calendar.svg";
import checkIcon from "../../../assets/images/Events/check-green.svg";
import fullyBookedIcon from "../../../assets/images/Events/fully-booked.svg";
import cancelledIcon from "../../../assets/images/Events/cancelled-rsvp.svg";

import { withRouter } from "react-router-dom";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import * as AddToRsvpAction from "../../redux/actions/AddToRsvpAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addToRsvpSuccess: "",
            addToRsvpFail: "",
            showEventDesc: false,
            individualEventPageStatus: false,
            eventClicked: false,
            addToRsvpResponse: "",
            showFormPopup: false,
            isRegistered: false,
            addedToRsvpSuccessfully: false,
        }
    }


    showEventDesc = () => {
        this.setState({
            showEventDesc: true
        });
    }

    parseDate = () => {
        let formattedDate = new Date();
        let times = this.props.eventData.event_slots[0].startAt.split(':');
        formattedDate.setHours(parseInt(times[0]));
        formattedDate.setMinutes(parseInt(times[1]));
        formattedDate.setSeconds(parseInt(times[2]));
        let eventDate = moment.utc(this.props.eventData.startTime).local().format("DD MMM");
        let newDate = [];
        newDate.push(
            <div className="event-date" key={eventDate}>
                <div >
                    <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
                </div>
                <div className="date-content">{" "}{eventDate}</div>
            </div>
        );
        return newDate;
    }

    rsvpClickHandler = () => {
        this.setState({
            showFormPopup: true
        })
        GoogleAnalyticsFunctions.clickedRsvp();
    }

    saveClickHandler = (cancelRsvp, slotId, passCount, cancelReason) => {
        GoogleAnalyticsFunctions.clickedSubmitInRsvpFormPopup();
        this.props.AddToRsvpAction.AddToRsvp(this.props.eventData.id, slotId, passCount);
        setTimeout(() => {
            if (this.state.addToRsvpResponse.errors) {
                this.setState({
                    showFormPopup: false,
                })
                this.props.getEventsAgain();
                toast.info(this.state.addToRsvpResponse.errors[0].message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                this.setState({
                    addToRsvpResponse: ""
                })
            }
            else if (this.state.addToRsvpResponse.id !== undefined) {
                this.setState({
                    addedToRsvpSuccessfully: true,
                });
                setTimeout(() => {
                    this.setState({
                        addedToRsvpSuccessfully: false,
                    });
                }, 4000);
                setTimeout(() => {
                    this.setState({
                        addToRsvpResponse: "",
                        showFormPopup: false,
                        isRegistered: true
                    })
                }, 3750);
            }
        }, 3000);
    }

    handleCancel = () => {
        this.setState({
            showFormPopup: !this.state.showFormPopup
        })
    }

    renderEventCards = () => {
        let result = [];
        let picture, totalReservations = 0, totalCapacity = 0;
        { this.props.eventData.coverPicture === "" ? picture = eventDefaultImage : picture = this.props.eventData.coverPicture }
        let btnText;
        if (this.props.eventData.isAttending) {
            btnText = "Attending";
        } else if (parseInt(this.props.eventData.reservedCount) !== this.props.eventData.capacity) {
            btnText = "RSVP";
        }
        if (this.props.eventData.event_slots) {
            this.props.eventData.event_slots.map((event) => {
                totalCapacity = totalCapacity + event.capacity
                totalReservations = totalReservations + parseInt(event.reservedCount)
            })
            if (totalReservations >= totalCapacity) {
                btnText = "Fully Booked";
            }
        }
        if (this.state.isRegistered || this.props.eventData.isAttending) {
            btnText = "Attending"
        }
        if (this.props.fromMyEvents) {
            btnText = "Attending"
        }
        if (this.props.fromMyEvents) {
            if (this.props.eventData.isAttended === "true") {
                btnText = "Attended"
            }
        }
        if (this.props.fromMyEvents) {
            if (this.props.eventData.isActive === 0) {
                btnText = "Canceled"
            }
        }
        if (this.props.eventData) {
            result.push(
                <div className="individual-events-main-container" key={this.props.eventData.id}>
                    <div
                        className="available-event-details"
                    >
                        <div className="event-coverpic ptr-clss" onClick={() => { this.showEventDesc() }}>
                            <img className="cover-picture card-img-top" src={picture} alt={this.props.eventData.title} />
                        </div>
                        <div className="event-title ptr-clss" onClick={() => { this.showEventDesc() }}>
                            <span className="event-name">
                                {this.props.eventData.title}
                            </span>
                            {/* {Commented  Temporarily - Waiting for API} */}
                            {/* <span className="title-icons">
                                <span className="like-icon">
                                    <img src={likeIcon} />
                                </span>
                                <span className="share-icon">
                                    <img src={shareIcon} />
                                </span>
                            </span> */}
                        </div>
                        <div className="event-date-container">
                            {this.parseDate()}
                        </div>
                        <div className="event-card-bottom">
                            {this.props.eventData.price !== 0 &&

                                <div className="event-price">
                                    {"$"}{this.props.eventData.price}
                                </div>
                            }
                            {this.props.eventData.price === 0 &&
                                <div className="event-price">
                                    {"Free"}
                                </div>
                            }
                            {
                                (btnText === "Attending") ?
                                    <button className="btn btn-round attending-btn">
                                        <img className="check-icon" src={checkIcon} alt="checkIcon" />
                                        {btnText}
                                    </button>
                                    :
                                    (btnText === "Attended") ?
                                        <button className="btn btn-round attended-btn"
                                        >
                                            <img className="check-icon" src={checkIcon} alt="checkIcon" />
                                            {btnText}
                                        </button>
                                        :
                                        (btnText === "Canceled") ?
                                            <button className="btn btn-round cancelled-btn"
                                            >
                                                <img className="cancelled-icon" src={cancelledIcon} alt="cancelledIcon" />
                                                {btnText}
                                            </button>
                                            :
                                            (btnText === "Fully Booked") ?
                                                <button className="btn btn-round fully-booked-btn"
                                                >
                                                    <img className="fully-booked-icon" src={fullyBookedIcon} alt="fullyBookedIcon" />
                                                    {btnText}
                                                </button>
                                                :
                                                <button className="btn btn-round rsvp-button"
                                                    onClick={() => { if (btnText === "RSVP") { this.rsvpClickHandler() } }}
                                                    disabled={this.props.eventData.isAttending || this.state.isRegistered}
                                                >
                                                    {btnText}
                                                </button>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        return result;
    }

    openEventDetails = () => {
        this.setState({
            eventClicked: true
        }, () => {
            if (this.props.fromMyEvents) {
                this.props.history.push({
                    pathname: "/events/" + this.props.eventData.id,
                    state: {
                        eventData: this.props.eventData,
                        wineryId: this.props.eventData.wineryId,
                        fromMyEvents: this.props.fromMyEvents,
                        isRegistered: (this.state.isRegistered || this.props.eventData.isAttending),
                    },
                });
            }
            else {
                this.props.history.push({
                    pathname: "/events/" + this.props.eventData.id,
                    state: {
                        eventData: this.props.eventData,
                        isRegistered: (this.state.isRegistered || this.props.eventData.isAttending),
                    },
                });
            }

        })
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.addToRsvpSuccess !== undefined) {
            return {
                addToRsvpResponse: nextProps.addToRsvpSuccess
            }
        }
        else {
            return null;
        }
    }

    render() {
        return (
            <div className="event-desc-main-container">
                {(this.state.showEventDesc) ?
                    (
                        this.openEventDetails()
                    )
                    :
                    (
                        <div className="available-event-card card shadow" >
                            {this.renderEventCards()}
                        </div>
                    )
                }
                {
                    this.props.fromMyEvents ?
                        null :
                        <RsvpFormPopup
                            open={this.state.showFormPopup}
                            eventData={this.props.eventData}
                            handleSave={this.saveClickHandler}
                            handleCancel={() => { this.handleCancel() }}
                            addedToRsvpSuccessfully={this.state.addedToRsvpSuccessfully}
                        />
                }
            </div>
        );
    }
}

EventCard = withRouter(EventCard);

//REDUX

function mapStateToProps(state, props) {
    return {
        addToRsvpSuccess: state.AddToRsvpReducer.addToRsvpSuccess,
        addToRsvpFail: state.AddToRsvpReducer.addToRsvpFail,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        AddToRsvpAction: bindActionCreators(AddToRsvpAction, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(EventCard);