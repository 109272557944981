import React, { Component, useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import "./App.css";
import AppRouter from "./AppRouter";
import "./app/styles/global.scss";
import "./app/styles/util.scss";
import "./app/styles/animation.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./app/redux/store/configure-store";
import { initializeTagManager } from "./app/lib/gtm";

export default function App() {
  useEffect(() => {
    //pendo
    window.pendo.initialize({
      visitor: {
        id: "VISITOR-UNIQUE-ID",
      },
    });

    //gtm
    if (!window.GTM_INITIALIZED) {
      initializeTagManager();
      window.GTM_INITIALIZED = true;
    }
  }, []);

  return (
    <Provider store={configureStore().store}>
      <PersistGate persistor={configureStore().persistor}>
        <BrowserRouter>
          <Switch>
            <React.Fragment>
              <AppRouter />
            </React.Fragment>
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};
