import React from 'react'
import FacebookLogin from 'react-facebook-login'
import PropTypes from 'prop-types'

import FaceBookIcon from './FaceBookIcon'
import './FaceBookButton.scss'

const FaceBookButton = ({appId, scope, fields, cssClass, autoLoad=false, size, isMobile=false, callback, icon=<FaceBookIcon />, isDisabled=false, ...others}) => {
	return (
    <FacebookLogin
      appId={appId}
      scope={scope}
      fields={fields}
      cssClass={cssClass}
      autoLoad={autoLoad}
      size={size}
      isMobile={isMobile}
      isDisabled={isDisabled}
      callback={callback}
      icon={icon}
      {...others}
    />
  );
}


FaceBookButton.propTypes = {
  appId: PropTypes.string.isRequired,
  scope: PropTypes.string,
  fields: PropTypes.string,
  autoLoad: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'metro']),
  autoload: PropTypes.bool,
  isMobile: PropTypes.bool,
  isDisabled: PropTypes.bool,
  callback: PropTypes.func,
};

export default FaceBookButton