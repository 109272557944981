import React, { useState, useEffect } from "react";
import styles from "./pairing-feedback.module.scss";
import AnimationWrapper from "../AnimationWrapper/AnimationWrapper";
import * as constants from "../../../constants/constants";
import { put, post, postPilotEvent } from "../../../lib/fetch";
import { sessionUrl } from "../../../lib/common/sessionUrl";

export default function PairingFeedback({
  recoItemId = undefined,
  generic = false,
  text = "Was this pairing helpful?"
}) {
  const [currentMsg, setcurrentMsg] = useState(text);
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);

  // console.log(`recoItemId:`);
  // console.log(recoItemId);

  const submitFeedbackGeneric = async (helpful) => {
    // try {
    //   await postPilotEvent({
    //     eventName: "clicked_feedback",
    //     eventDetails: helpful,
    //     pageUrl: window.location.href,
    //   });

    // } catch (e) {
    //   console.error(e);
    // }

    setClicked1(true);
    setTimeout(() => {
      setcurrentMsg("Thanks for letting us know!");
      setClicked2(true);
    }, 1000);
  };

  const submitFeedback = async (helpful) => {
    let feedbackBody = {
      varietal_reco_item_id: recoItemId,
      helpful: helpful,
    };

    let url = `${constants.IDENTITY_API}/api/v1/public/support/feedback`;

    try {
      const response = await put(
        sessionUrl(url),
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        feedbackBody
      );
      const result = await response.json();
      console.log(`result:`);
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }

    setClicked1(true);
    setTimeout(() => {
      setcurrentMsg("Thanks for letting us know!");
      setClicked2(true);
    }, 1000);
  };

  return (
    <div
      className={`${styles.helpful} ${clicked2 ? styles.helpfulClicked : null}`}
    >
      <div className={`p1-semi ${styles.msgBox}`}>{currentMsg}</div>

      {!clicked2 ? (
        <div className={`wa`}>
          <AnimationWrapper animation={"fadeOut"} active={clicked1}>
            {generic ? (
              <div className={styles.yesNo}>
                <button
                  onClick={() => submitFeedbackGeneric(1)}
                  className={`p1-semi ${styles.yesNoBtn}`}
                >
                  Yes
                </button>
                <button
                  onClick={() => submitFeedbackGeneric(0)}
                  className={`p1-semi ${styles.yesNoBtn}`}
                >
                  No
                </button>
              </div>
            ) : (
              <div className={styles.yesNo}>
                <button
                  onClick={() => submitFeedback(1)}
                  className={`p1-semi ${styles.yesNoBtn}`}
                >
                  Yes
                </button>
                <button
                  onClick={() => submitFeedback(0)}
                  className={`p1-semi ${styles.yesNoBtn}`}
                >
                  No
                </button>
              </div>
            )}
          </AnimationWrapper>
        </div>
      ) : (
        <>
          <svg
            className={`no-shrink`}
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M9.5 18.5C14.4706 18.5 18.5 14.4706 18.5 9.5C18.5 4.52944 14.4706 0.5 9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5C0.5 14.4706 4.52944 18.5 9.5 18.5Z"
              fill="#343434"
            />
            <path
              d="M6.5 10.1047L8.83333 11.9714L13.5 7.30469"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      )}
    </div>
  );
}
