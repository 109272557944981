import * as types from './action-types';
import * as constants from '../../constants/constants'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const EditRsvp = (eventId, slotId, eventBookingId, passCount) => {
    let wineryId = (JSON.parse(localStorage.getItem('winery_id')));
    let postPreferencesApiUrl = constants.EVENT_API + '/api/v1/users/winery/' + wineryId + '/events/' + eventId + '/booking/' + eventBookingId
    let apiPostData = {
        count: passCount,
        slotId: slotId
    }
    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;

    return dispatch => {
        fetch(postPreferencesApiUrl, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + signInToken 
            },
            body: JSON.stringify(
                apiPostData
            )
        }).then(res => {
            return res.json();
        }).then(respJSON => {
            dispatch({ type: types.EDIT_RSVP_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.EDIT_RSVP_FAILED, payload: error })
        })
    }
}
