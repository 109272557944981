import React, { Component } from "react";

import "./RsvpFormPopup.scss";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import moment from "moment";

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextareaAutosize from 'react-textarea-autosize';
import * as CancelRsvpAction from '../../redux/actions/CancelRsvpAction';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import savedIcon from "../../../assets/images/Events/correct-red.svg";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const theme = createMuiTheme({
    palette: {
        primary: { main: "#cd4545" },
    },
});


const GlobalCss = withStyles({
    "@global": {
        ".MuiPaper-root": {
            background: "none",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
        },
        ".MuiBackdrop-root": {
            background: "#F2F2F299 0% 0% no-repeat padding-box",
        },
        ".MuiTypography-body1": {
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif !important"
        },
        ".MuiInputBase-input": {
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif !important",
            width: "100%",
            border: 0,
            display: "block",
            padding: "6px 0 7px",
            minWidth: 0,
            background: "none",
            boxSizing: "content-box",
            animationName: "mui-auto-fill-cancel",
            lineHeight: "1.2",
        }
    },
})(() => null);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const contentStyle = {
    justify: "center",
    paddingRight: "19px",
    display: "flex",
    flexDirection: "column",
};

class RsvpFormPopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstDropTitle: "Select Pass Type",
            slot: "0",
            selectedSlotId: -1,
            passCount: 1,
            radioSlotId: '',
            availablePasses: 1,
            disableBtn: true,
            drawerOpened: false,
            drawerClosed: false,
            availableIndex: 0,
            notAttendingClicked: false,
            reasonValue: "",
            editClicked: false,
            bookedSlotId: 0,
            cancelRsvpSuccess: "",
            cancelRsvpFail: "",
            cancelRsvpResponse: "",
            showCancelSuccess: false,
            showAddedToRsvpPopUp: false,
            editedRsvpSuccessfully: false,
            addedToRsvpSuccessfully: false,
            checkedCancel: false,
            disableFormSubmit: false
        }
    }

    componentDidMount = () => {
        if (parseInt(this.props.eventData.event_slots[0].reservedCount) !== this.props.eventData.event_slots[0].capacity) {
            this.setState({
                radioSlotId: this.props.eventData.event_slots[0].id,
                selectedSlotId: this.props.eventData.event_slots[0].id,
                availableIndex: 0
            })
        } else {
            for (let i = 0; i < this.props.eventData.event_slots.length; i++) {
                if (parseInt(this.props.eventData.event_slots[i].reservedCount) !== this.props.eventData.event_slots[i].capacity) {
                    this.setState({
                        radioSlotId: this.props.eventData.event_slots[i].id,
                        selectedSlotId: this.props.eventData.event_slots[i].id,
                        availableIndex: i
                    })
                    break;
                } else {
                    continue;
                }
            }

        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.editClicked !== prevState.editClicked) {
            return {
                radioSlotId: nextProps.bookedSlotId,
                selectedSlotId: nextProps.bookedSlotId,
                bookedSlotId: nextProps.bookedSlotId,
                editClicked: nextProps.editClicked,
                disableFormSubmit: nextProps.disableFormSubmit
            }
        }

        if (nextProps.bookedSlotId !== prevState.bookedSlotId) {
            if (nextProps.bookedPassCount) {
                return {
                    radioSlotId: nextProps.bookedSlotId,
                    selectedSlotId: nextProps.bookedSlotId,
                    bookedSlotId: nextProps.bookedSlotId,
                    passCount: nextProps.bookedPassCount,
                    editClicked: nextProps.editClicked,
                }
            } else {
                return {
                    radioSlotId: nextProps.bookedSlotId,
                    selectedSlotId: nextProps.bookedSlotId,
                    bookedSlotId: nextProps.bookedSlotId,
                    editClicked: nextProps.editClicked,
                }
            }

        }
        if (nextProps.editedRsvpSuccessfully !== prevState.editedRsvpSuccessfully) {
            return {
                editedRsvpSuccessfully: nextProps.editedRsvpSuccessfully
            }
        }
        if (nextProps.addedToRsvpSuccessfully !== prevState.addedToRsvpSuccessfully) {
            return {
                addedToRsvpSuccessfully: nextProps.addedToRsvpSuccessfully
            }
        }
        if (nextProps.cancelRsvpSuccess) {
            return {
                cancelRsvpResponse: nextProps.cancelRsvpSuccess,
            }
        }
        else {
            return null;
        }
    }

    handlePlusClick = () => {
        this.setState({
            passCount: this.state.passCount + 1,
            disableBtn: false
        })
    }

    handleMinusClick = () => {
        this.setState({
            passCount: this.state.passCount - 1,
            disableBtn: false
        })
    }

    handleChangeSecondOption = (event) => {
        let selectedVal = parseInt(event.target.value)
        this.setState({
            selectedSlotId: selectedVal,
            radioSlotId: selectedVal,
            disableBtn: false,
        });
    };

    handleCancelClick = () => {
        GoogleAnalyticsFunctions.clickedCancelInRsvpFormPopup();
        this.setState({
            notAttendingClicked: false
        })
    }

    renderOptions = (item) => {
        let optionsResult = [];
        let momentStart = moment
            .utc(item.startAt, [moment.ISO_8601, "h:mm A"])
            .local()
            .format("h:mm A");
        let momentEnd = moment
            .utc(item.endAt, [moment.ISO_8601, "h:mm A"])
            .local()
            .format("h:mm A");
        let disableTimeSlot = false;
        if (item.capacity === parseInt(item.reservedCount)) {
            if (this.state.bookedSlotId !== item.id) {
                disableTimeSlot = true
            }
        }
        optionsResult.push(
            <FormControlLabel
                value={item.id}
                disabled={disableTimeSlot}
                key={item.id}
                style={{ fontSize: "16px", fontFamily: "poppins" }}
                control={<Radio
                    color="primary" />}
                label={disableTimeSlot ? (momentStart + " - " + momentEnd) + " (fully booked)" : (momentStart + " - " + momentEnd)}
            />
        )
        return optionsResult;
    }

    handleCancelClicked = () => {
        GoogleAnalyticsFunctions.clickedCantMakeItRsvpFormPopup();
        this.setState({
            notAttendingClicked: true,
            disableBtn: true,
            checkedCancel: false
        })
    }

    handleCancelRsvp = () => {
        GoogleAnalyticsFunctions.clickedSubmitInRsvpFormPopup();
        if (this.state.notAttendingClicked === true) {
            this.props.CancelRsvpAction.CancelRsvp(this.props.eventData.id, this.props.eventData.event_booking[0].eventSlotId, this.props.eventData.event_booking[0].id, this.state.reasonValue);
            setTimeout(() => {
                if (this.state.cancelRsvpResponse.errors) {
                    toast.info(this.state.cancelRsvpResponse.errors[0].message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                }
                else if (this.state.cancelRsvpResponse.message) {
                    this.setState({
                        showCancelSuccess: true,
                    })
                }
                setTimeout(() => {
                    this.props.cancelledRsvp()
                }, 3750);
                setTimeout(() => {
                    this.setState({
                        reasonValue: "",
                        editClicked: false,
                        showCancelSuccess: false,
                        radioSlotId: this.props.eventData.event_slots[this.state.availableIndex].id,
                        selectedSlotId: this.props.eventData.event_slots[this.state.availableIndex].id,
                        notAttendingClicked: false,
                        disableBtn: false
                    })
                }, 4000);

            }, 3000);
        }
    }

    onDrawerOpen = () => {
        this.setState({
            drawerOpened: true,
        })
    }

    handleChangeCheckBox = () => {
        this.setState({
            checkedCancel: !this.state.checkedCancel,
        })
    }

    onDrawerClose = () => {
        setTimeout(() => {
            this.setState({
                drawerClosed: true,
                notAttendingClicked: false,
                disableBtn: false
            })
            if (this.state.editClicked) {
                this.setState({
                    editClicked: false
                })
            }
            this.handleCancelClick()
        }, 100);


    }

    handleReasonChange = (event) => {
        this.setState({
            reasonValue: event.target.value
        })
    }


    changeStateAfterSubmit = () => {
        setTimeout(() => {
            this.setState({
                notAttendingClicked: false,
                reasonValue: "",
                disableBtn: false
            })
        }, 4000);
    }

    changeStateAfterCancel = () => {
        this.setState({
            disableBtn: true
        })
        setTimeout(() => {
            this.setState({
                reasonValue: "",
            })
        }, 3100);
    }

    render() {
        let { open } = this.props;
        let slotId = this.state.selectedSlotId;
        let plusBtnDisabler = false;
        let passCount = this.state.passCount;
        let cancelRsvp = this.state.notAttendingClicked;
        let cancelReason = this.state.reasonValue;
        // const handleChangeFirstOption = (event) => {
        //     const name = event.target.name;
        //     this.setState({
        //         [name]: event.target.value,
        //     });
        // };
        if (this.state.selectedSlotId === -1) {
            slotId = this.props.eventData.event_slots[this.state.availableIndex].id
        } else {
            slotId = this.state.selectedSlotId
        }
        let selectedSlotIndex = this.props.eventData.event_slots.findIndex(x => x.id === parseInt(this.state.selectedSlotId));
        let availablePasses;
        if (this.props.eventData.event_slots) {
            let tempReservedCount;
            if (this.props.eventData.event_slots[this.state.availableIndex].reservedCount) {
                tempReservedCount = this.props.eventData.event_slots[this.state.availableIndex].reservedCount
            } else {
                tempReservedCount = 0;
            }
            availablePasses = (this.props.eventData.event_slots[this.state.availableIndex].capacity) - (tempReservedCount);
        }

        let slotBtnDisabler = false;
        if (this.props.eventData.event_slots) {
            if (this.props.eventData.event_slots.length === 0) {
                slotBtnDisabler = true;
            }
        }
        if (this.props.eventData.event_slots[selectedSlotIndex]) {
            availablePasses = (this.props.eventData.event_slots[selectedSlotIndex].capacity) - (this.props.eventData.event_slots[selectedSlotIndex].reservedCount)
        }
        let headerText;
        if (this.state.notAttendingClicked) {
            headerText = "Cancel RSVP"
        }

        else if (this.props.editClicked) {
            headerText = "Edit RSVP"
        }
        else {
            headerText = "RSVP"
        }
        if (this.props.editClicked && (this.props.bookedSlotId === slotId)) {
            availablePasses = availablePasses + this.props.bookedPassCount
        }
        if (availablePasses === 0) {
            plusBtnDisabler = true;
        }
        return (
            <React.Fragment>
                <MuiThemeProvider theme={theme}>
                    <SwipeableDrawer
                        disableSwipeToOpen
                        onBackdropClick={() => { this.props.handleCancel() }}
                        anchor="bottom"
                        open={open}
                        style={contentStyle}
                        onOpen={this.onDrawerOpen}
                        onClose={this.onDrawerClose}
                    >
                        <GlobalCss />

                        <div className="bg"

                        >
                            <div onTouchStart={() => { this.props.handleCancel() }}
                                className="thin-line-div"
                            >
                                <div className="thin-line"></div>
                            </div>
                            {
                                (!this.state.editedRsvpSuccessfully && !this.state.addedToRsvpSuccessfully && !this.state.showCancelSuccess) &&
                                <div className="header-text">{headerText}</div>
                            }


                            {
                                (!this.state.notAttendingClicked && !this.state.showCancelSuccess && !this.state.editedRsvpSuccessfully && !this.state.addedToRsvpSuccessfully) &&
                                <div className="second-dd">
                                    <FormControl variant="outlined"
                                        className="form-control-dd"
                                        style={{ width: "90%", marginLeft: "5%", marginTop: "10px" }}
                                    >
                                        <div className="time-slot-container">
                                            <FormLabel component="time-slot-header" className="time-slot-header">Time Slots</FormLabel>
                                            <RadioGroup
                                                aria-label="Time Slots"
                                                name="timeslots"
                                                onChange={this.handleChangeSecondOption}
                                                value={this.state.radioSlotId}
                                            >
                                                {this.props.eventData.event_slots.map((item) => (
                                                    this.renderOptions(item)
                                                ))}
                                            </RadioGroup>
                                        </div>
                                    </FormControl>
                                </div>
                            }

                            {
                                (!this.state.notAttendingClicked && !this.state.showCancelSuccess && !this.state.editedRsvpSuccessfully && !this.state.addedToRsvpSuccessfully) &&
                                <div className="btn-selector-grp-dd">
                                    <span className="btn-grp-text">Indicate # of Guests</span>
                                    <ButtonGroup color="default" aria-label="outlined button group" className="three-btn-grp">
                                        <Button disabled={(this.state.passCount <= 1) ? true : false} onClick={() => { this.handleMinusClick() }}>-</Button>
                                        <Button className="pass-count-center-btn">{this.state.passCount}</Button>
                                        <Button
                                            disabled={(this.state.passCount === availablePasses) || plusBtnDisabler}
                                            onClick={() => { this.handlePlusClick() }}>+</Button>
                                    </ButtonGroup>
                                </div>
                            }
                            {
                                this.state.showCancelSuccess &&
                                <div className="show-cancel-sucess">
                                    <img className="saved-icon" src={savedIcon} alt="Saved Icon" />
                                    We're going to miss you! <br /> Hope to see you at other upcoming events.
                                </div>
                            }
                            {
                                this.state.editedRsvpSuccessfully &&
                                <div className="show-edit-sucess">
                                    <img className="saved-icon" src={savedIcon} alt="Saved Icon" />
                                    <div className="edited-success-message">Your altered RSVP has been saved. <br /> We look forward to seeing you at the event.</div>
                                </div>
                            }
                            {
                                this.state.addedToRsvpSuccessfully &&
                                <div className="show-edit-sucess">
                                    <img className="saved-icon" src={savedIcon} alt="Saved Icon" />
                                    Thank you! <br /> Looking forward to seeing you at the event.
                                </div>
                            }
                            {
                                (this.state.notAttendingClicked && !this.state.showCancelSuccess && !this.state.editedRsvpSuccessfully && !this.state.addedToRsvpSuccessfully) &&
                                <div className="checkbox-container">
                                    <Checkbox
                                        checked={this.state.checkedCancel}
                                        onChange={() => {
                                            this.handleChangeCheckBox()
                                            this.setState({
                                                disableBtn: !this.state.disableBtn
                                            })
                                        }}
                                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 18 }} />}
                                        checkedIcon={<CheckBoxIcon style={{ fontSize: 18 }} />}
                                        size="medium"
                                        style={{ color: "#cd4545", width: "30px", height: "30px" }}
                                        inputProps={{ 'aria-label': 'eventscheckbox' }}
                                    />
                                    <div className="checkbox-contents">
                                        I will not be able to attend the event
                                </div>
                                </div>
                            }

                            {
                                (this.state.notAttendingClicked && !this.state.showCancelSuccess && !this.state.editedRsvpSuccessfully && !this.state.addedToRsvpSuccessfully) &&
                                <div className="textarea-container">
                                    <TextareaAutosize
                                        minRows="6"
                                        className="reason-text-box"
                                        onChange={this.handleReasonChange}
                                        type="text"
                                        placeholder="Cancellation reason"
                                        disabled={!this.state.checkedCancel}
                                    />
                                </div>
                            }
                            {
                                (!this.state.showCancelSuccess && !this.state.editedRsvpSuccessfully && !this.state.addedToRsvpSuccessfully) &&
                                <div className="footer-buttons">
                                    {
                                        (this.props.editClicked && !this.state.notAttendingClicked) &&
                                        <div className="cancel-event-rsvp"
                                            onClick={
                                                () => {
                                                    this.handleCancelClicked()
                                                }
                                            }>
                                            Not Attending?
                                </div>
                                    }
                                    {
                                        this.state.notAttendingClicked &&
                                        <div className="cancelBtn-container">
                                            <button className={(this.props.editClicked && !this.state.notAttendingClicked) ? "btn btn-round cancel-button-edit-form" : "btn btn-round cancel-button"}
                                                onClick={() => {
                                                    this.handleCancelClick()
                                                    this.setState({
                                                        notAttendingClicked: false,
                                                        checkedCancel: false
                                                    })
                                                }}>
                                                Cancel
                                    </button>
                                        </div>
                                    }
                                    {
                                        !this.state.notAttendingClicked &&
                                        <div className="cancelBtn-container">
                                            <button className={(this.props.editClicked && !this.state.notAttendingClicked) ? "btn btn-round cancel-button-edit-form" : "btn btn-round cancel-button"}
                                                onClick={() => {
                                                    this.props.handleCancel()
                                                    this.handleCancelClick()
                                                    this.setState({
                                                        notAttendingClicked: false,
                                                    })
                                                }}>
                                                Cancel
                                    </button>
                                        </div>
                                    }
                                    <div className="saveBtn-container">
                                        {
                                            ((!this.state.notAttendingClicked) && (headerText === "RSVP")) &&
                                            <button className={(this.props.editClicked && !this.state.notAttendingClicked) ? "btn btn-round save-button-edit-form" : "btn btn-round save-button"}
                                                onClick={() => {
                                                    this.setState({
                                                        disableBtn: true,
                                                        disableFormSubmit: true
                                                    })
                                                    this.changeStateAfterSubmit()
                                                    this.props.handleSave(cancelRsvp, slotId, passCount, cancelReason)
                                                }}
                                                disabled={this.state.disableBtn && this.state.disableFormSubmit}
                                            >
                                                Submit
                                            </button>
                                        }
                                        {
                                            (headerText === "Edit RSVP") &&
                                            <button className={(this.props.editClicked && !this.state.notAttendingClicked) ? "btn btn-round save-button-edit-form" : "btn btn-round save-button"}
                                                onClick={() => {
                                                    this.setState({
                                                        disableBtn: true,
                                                        disableFormSubmit: true
                                                    })
                                                    this.changeStateAfterSubmit()
                                                    this.props.handleSave(cancelRsvp, slotId, passCount, cancelReason)
                                                }}
                                                disabled={this.state.disableBtn && this.state.disableFormSubmit}
                                            >
                                                Submit
                                            </button>
                                        }
                                        {
                                            this.state.notAttendingClicked &&
                                            <button className={(this.props.editClicked && !this.state.notAttendingClicked) ? "btn btn-round save-button-edit-form" : "btn btn-round save-button"}
                                                onClick={() => {
                                                    this.setState({
                                                        disableBtn: true
                                                    })
                                                    this.changeStateAfterCancel()
                                                    this.handleCancelRsvp()
                                                }}
                                                disabled={this.state.disableBtn}
                                            >
                                                Submit
                                    </button>
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </SwipeableDrawer>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        cancelRsvpSuccess: state.CancelRsvpReducer.cancelRsvpSuccess,
        cancelRsvpFail: state.CancelRsvpReducer.cancelRsvpFail,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        CancelRsvpAction: bindActionCreators(CancelRsvpAction, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(RsvpFormPopUp);