import React, { useEffect, useState, useRef } from "react";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./dd-pairing.module.scss";
import { useFormik } from "formik";
import { post, postPilotEvent } from "../../../lib/fetch";
import { sessionUrl } from "../../../lib/common/sessionUrl";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";

export default function DDPhoneForm({ type = undefined }) {
  const history = useHistory();

  // ========== Set up formik ==============================
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittable, setFormSubmittable] = useState(false);
  const [v, setV] = useState("");

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const handleChange = (e) => {
    setV(normalizeInput(e.target.value, v));
  };

  useEffect(() => {
    if (v && v.length == 12) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [v]);

  // onClick

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formSubmittable) {
      setFormSubmitted(true);

      try {
        postPilotEvent({
          eventName: "click_submitPhoneNumber",
          eventDetails: String(v),
          pageUrl: window.location.href,
        });
      } catch (err) {}
    }
  };

  return (
    <div
      className={`${styles.phoneFormBox}`}
      style={
        type == "modal"
          ? { marginTop: 0, border: "none", padding: "2.4rem 0 0 0" }
          : null
      }
    >
      <div className={`${styles.phoneFormInnerBox}`}>
        {!formSubmitted ? (
          <>
            <h2 className={`${styles.pairsWith}`}>
              {" "}
              {type == "modal" ? "Want more?" : "We're working on it"}
            </h2>
            <p className={`${styles.phoneTxt}`}>
              {type == "modal"
                ? "Park these pairing delights on your phone for the future. We’ll also text you 2 more pairings for you to taste."
                : "Kindly share your phone number and we’ll send this pairing to yourphone in the next 24 hrs!"}
            </p>
            <form
              onSubmit={(e) => handleSubmit(e)}
              autoComplete="off"
              className={`${styles.formContainer}`}
            >
              <div className={`${styles.formField}`}>
                <input
                  placeholder="### - ### - ####"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  onChange={(e) => handleChange(e)}
                  onFocus={() => {
                    postPilotEvent({
                      eventName: "click_focusPhoneInput",
                      pageUrl: window.location.href,
                    });
                  }}
                  value={v}
                  className={`${styles.formInput}`}
                />
              </div>

              <button
                className={`${styles.submitBtn} ${
                  !formSubmittable ? styles.grayedOut : null
                }`}
                type="submit"
              >
                <p className={`${styles.submitBtnTxt}`}>
                  {type == "modal" ? "Send me more" : "Get pairing"}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.sendIcon}
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <g clip-path="url(#clip0_805_713)">
                    <path
                      d="M1.15744 4.43412C0.654939 4.60174 0.603752 5.29231 1.07588 5.53231L4.13569 7.08687C4.22216 7.13076 4.319 7.15011 4.4157 7.1428C4.51239 7.1355 4.60524 7.10182 4.68413 7.04543L8.15344 4.56724C8.33625 4.43693 8.56125 4.66193 8.43094 4.84474L5.95294 8.31387C5.89655 8.39276 5.86287 8.4856 5.85557 8.5823C5.84826 8.67899 5.86761 8.77584 5.9115 8.86231L7.46719 11.9236C7.70719 12.3961 8.39794 12.3447 8.56556 11.8422L11.8941 1.86049C12.0495 1.39381 11.6057 0.949994 11.1392 1.10562L1.15744 4.43412Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_805_713">
                      <rect
                        width="12"
                        height="12"
                        fill="white"
                        transform="translate(0.5 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </form>
          </>
        ) : (
          <div className={`${styles.submittedBox}`}>
            <h2 className={`${styles.pairsWith}`}>
              Gotcha, we’ll send your pairings within 24 hours!
            </h2>
            <button
              className={`${styles.submitBtn}`}
              onClick={() => {
                postPilotEvent({
                  eventName: "click_findAnotherPairingBtn",
                  pageUrl: window.location.href,
                });
                history.push("/dd-welcome");
              }}
            >
              <p className={`${styles.submitBtnTxt}`}>Find another pairing</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
