import React from 'react';

const FacebookIcon = () => {
	return (
    <div style={{
			fill: '#ffff'
		}}>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path className="a" d="M24,12A12,12,0,1,0,12,24c.07,0,.141,0,.211,0V14.658H9.633v-3h2.578V9.441A3.61,3.61,0,0,1,16.064,5.48a20.943,20.943,0,0,1,2.311.117V8.278H16.8c-1.242,0-1.486.591-1.486,1.458v1.913h2.977l-.389,3H15.314v8.883A12,12,0,0,0,24,12Z"/></svg>
		</div>
	);
}
export default FacebookIcon;
