import React , {Component} from 'react';
import './WineEducationCard.scss';
import { Redirect } from 'react-router';
import wineBottle from '../../../assets/images/wine-bottle.png'
import wineBookImage from '../../../assets/images/WineEducation/wine-book.png'
import * as constants from '../../constants/constants';


class WineEducationCard extends Component {

   constructor(props) {
       super(props);
       this.state =  {

    }
   }

   
  componentDidMount = () => {

    
  }

renderWineEducationCards = () => {
    let result = [];
    if(this.props.wineData!= undefined || this.props.wineData!=null) {
 
        result.push(

            <div className="wine-education-card-details">
                <div className="wine-education-card-bottle-logo-container">
                    <img alt = "Wine logo"
                    className = { (this.props.wineData!=null && this.props.wineData!=undefined && 
                        this.props.wineData.profilePicture!="" )? "wine-education-card-bottle-logo-from-api" :
                                                                  "wine-education-card-bottle-logo"}
                    src = { (this.props.wineData.profilePicture!=null && this.props.wineData.profilePicture!="" 
                        && this.props.wineData!=undefined )? this.props.wineData.profilePicture : wineBottle}
                    />
            </div>

                <div className="wine-education-card-name-container">

                                                
                <div className="wine-education-card-subcontainer">

                
                    <span className = "wine-education-card-name">
                    { (this.props.wineData.name!=null || this.props.wineData.name!=undefined )? this.props.wineData.name : 'Wine Name'}
                    </span>

                    <span className = "wine-education-card-subtype-title">
                    { (this.props.wineData.wineType!=null || this.props.wineData.wineType!=undefined )? this.props.wineData.wineType : 'Wine Type'}
                    </span>
                    <span className = "wine-education-card-price-text">
                    { (this.props.wineData.price!=null || this.props.wineData.price!=undefined )? '$' + this.props.wineData.price : '$45'}

                    </span>
                </div>
             
            </div>
        </div>
        )

    } else {
            result.push(
                <div className="wine-education-card-details">
                    <div className="wine-education-card-bottle-logo-container">
                        <img className = "wine-education-card-bottle-logo" alt = "Wine Education "
                        // src = {wineBottle} 
                        src = { (this.props.wineData!=null || this.props.wineData!=undefined )? this.props.wineData.profilePicture : wineBottle}
                        />
                </div>

                    <div className="wine-education-card-name-container">
                                                
                <div className="wine-education-card-subcontainer">

                
                    <span className = "wine-education-card-name">
                            Wine Name
                    </span>
                     <span className = "wine-education-card-subtype-title">
                            Wine Type
                    </span>
                    <span className = "wine-education-card-price-text">
                        Wine Price
                    </span>
                </div>
             
            </div>
        </div>

            );
    }

    return result;
}

openWineEducationContent = () => {

}
 

    render() {


        // if (this.props.wineData!=null && this.props.wineData!=undefined) {
           
        //     return <Redirect to={{
        //         pathname: '/individual-wine-details',
        //         state: { 
        //                     individualWineDetailsFromAvailableWines: true,
        //                     wineIndividualData: this.props.wineData
        //                }
        //     }}/>;

        //   }

     
        return(
                        
            <div onClick = {this.openWineEducationContent.bind(this)} className="card wine-education-card-container shadow">               

                                    {/* { this.renderWineEducationCards() } */}

             <div className="wine-education-card-details">
                <div className="wine-education-card-bottle-logo-container">
                    <img alt = "wine book"
                    className = "wine-education-card-bottle-logo"
                    src = { wineBookImage }
                    />
                </div>

                <div className="wine-education-card-name-container">

                                                
                    <div className="wine-education-card-subcontainer">

                    
                        <span className = "wine-education-card-title">
                                California Wine 2019 Harvest Report
                        </span>
                     
                        <span className = "wine-education-card-content">
                        Winegrapes across California ripened at lower sugars, thanks to the extended, cool growing season,
                        and vintners are praising the full flavors, fresh acidity and superb balance of the 2019 fruit.
                   
                         {/*      The wine is just fine. For now. Despite a late October blaze that raged through one of the world's
                        best-known wine-growing regions. forcing evacuations in two mid-sized towns, wine production in
                        Sonoma County escaped largely unscathed. */}
                        </span>
       
                    </div>
               
            
            </div>
        </div>

                                  
            </div>

        );
    }
}

export default WineEducationCard;