import React, { useMemo } from "react";
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import useResponsiveFontSize from "./useResponsiveFontSize";


// const useOptions = () => {
//     const fontSize = useResponsiveFontSize();
//     const options = useMemo(
//       () => ({
//         style: {
//           base: {
//             fontSize,
//             color: "#424770",
//             letterSpacing: "0.025em",
//             fontFamily: "Source Code Pro, monospace",
//             "::placeholder": {
//               color: "#aab7c4"
//             }
//           },
//           invalid: {
//             color: "#9e2146"
//           }
//         }
//       }),
//       [fontSize]
//     );

//     return options;
//   };

const iframeStyles = {
    base: {
        color: "#fff",
        fontSize: "16px",
        iconColor: "#fff",
        "::placeholder": {
            color: "#87bbfd"
        }
    },
    invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE"
    },
    complete: {
        iconColor: "#cbf4c9"
    }
};

const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
};

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            // fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#9e2146"
        }
    }
    // style: {
    //     base: {
    //         padding: '10px 12px',
    //         color: '#32325d',
    //         fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    //         fontSmoothing: 'antialiased',
    //         fontSize: '16px',
    //         '::placeholder': {
    //             color: '#aab7c4',
    //             // fontSize: '9px'
    //         },
    //     },
    //     invalid: {
    //         color: '#fa755a',
    //     }
    // }
    // iconStyle: "solid",
    // hidePostalCode: true,
    // style: {
    //     base: {
    //         iconColor: "rgb(240, 57, 122)",
    //         color: "rgb(240, 57, 122)",
    //         fontSize: "16px",
    //         fontFamily: '"Open Sans", sans-serif',
    //         fontSmoothing: "antialiased",
    //         "::placeholder": {
    //             color: "#CFD7DF"
    //         }
    //     },
    //     invalid: {
    //         color: "#e5424d",
    //         ":focus": {
    //             color: "#303238"
    //         }
    //     }
    // }
};

// const publishableKey = 'pk_test_51HQAiwBjm4t8TS5vxEhsxl5WU3YB1ZcBnTfBf8kviDJAuorgzWq1uv0xgY9uv5twyIkAMBoyX0cy5iv59Uz3ciok00i2AzrjqP'
// const stripePromise = loadStripe(publishableKey);

class CheckoutForm extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props);
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        const { stripe, elements } = this.props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
    };
    render() {
        // const options = useOptions();
        // const fontSize = useResponsiveFontSize();
        // console.log(fontSize);
        const { stripe } = this.props;
        return (
            <React.Fragment>
                {/* <form onSubmit={this.handleSubmit}> */}
                <CardElement  />

                {/* <button type="submit" disabled={!stripe}>
                    Pay
        </button> */}
                {/* </form> */}
            </React.Fragment>
        );
    }
}

export default CheckoutForm;