import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import differenceInYears from 'date-fns/differenceInYears';
import FaceBookButton from '../../components/FacebookButton';
import GoogleButton from '../../components/GoogleButton';
import * as Helper from '../../constants/commonFunctions';
import * as Constants from '../../constants/constants';
import logo from '../../../assets/images/splash.png';
import leftArrowBtn from '../../../assets/images/back-btn.svg';
import * as GoogleAnalyticsFunctions from '../../components/PAGoogleAnalytics';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
toast.configure();

const EmailSignupForm = ({ setShowEmailForm, setShowSuccess, setOutput, state, setState, error, setError, facebookResponse, googleResponse }) => {
	const history = useHistory()
	const [submitDisabled, showSubmitDisabled] = useState(true);


  const { firstName, lastName, email, password, confirmPassword } = state;
  const {
    firstNameError,
    lastNameError,
    firstNameErrorMsg,
    lastNameErrorMsg,
    emailError,
    emailErrorMsg,
    passwordError,
    passwordErrorMsg,
    confirmPasswordError,
    confirmPasswordErrorMsg,
    commonError,
    commonErrorMsg,
  } = error;

  const handleChange = (event) => {
    const { value, name } = event.target;
    const errorName = name + 'Error';
    const errorMessage = name + 'ErrorMsg';
    setState({ ...state, [name]: value });
    setError({ ...error, [errorName]: false, [errorMessage]: '' });
  };

  const validatePassword = () => {
    if (password.length <= 0) {
      setError({
        ...error,
        passwordError: true,
        passwordErrorMsg: 'Password cannot be blank',
      });
      return false;
    } else if (password.length !== confirmPassword.length) {
      setError({
        ...error,
        confirmPasswordError: true,
        confirmPasswordErrorMsg: `password & ConfirmPassword doesn't match`,
      });
      return false;
    } else if (password !== confirmPassword) {
      setError({
        ...error,
        confirmPasswordErrorError: true,
        confirmPasswordErrorMsg: `password & ConfirmPassword doesn't match`,
      });
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    if (!email) {
      setError({
        ...error,
        emailError: true,
        emailErrorMsg: 'Email is Required',
      });
      return false;
    }
    if (!Helper.validateEmail(email)) {
      setError({
        ...error,
        emailError: true,
        emailErrorMsg: 'Enter a valid Email',
      });
      return false;
    }
    setError({ ...error, emailError: false, emailErrorMsg: '' });
    return true;
  };

  const validateFirstName = () => {
    if (!firstName) {
      setError({
        ...error,
        firstNameError: true,
        firstNameErrorMsg: 'firstName is required',
      });
      return false;
    }
    setError({
      ...error,
      firstNameError: false,
    });
    return true;
  };

  const validateLastName = () => {
    if (!lastName) {
      setError({
        ...error,
        lastNameError: true,
        lastNameErrorMsg: 'lastName is required',
      });
      return false;
    }
    setError({
      ...error,
      lastNameError: false,
    });
    return true;
  };

  const validateOnBlur = (event) => {
    const { value, name } = event.target;
    if (name === 'password' || name === 'confirmPassword') validatePassword();
    if (name === 'email') validateEmail();
    if (name === 'firstName') validateFirstName();
    if (name === 'lastName') validateLastName();
  };

  const validateDateOfBirthday = () => {
    const currDate = new Date();
    const diff = Math.abs(
      differenceInYears(currDate, new Date(state.dateOfBirth))
    );
    if (diff < 21) {
      setError({
        ...error,
        commonError: true,
        commonErrorMsg: 'You must be 21 years or older to login',
      });
      return false;
    }
    setError({
      ...error,
      commonError: false,
      commonErrorMsg: '',
    });
    return true;
	};

	useEffect(() => {
		if(firstName && lastName && email && password && confirmPassword) {
			showSubmitDisabled(false);
			return
		}
		showSubmitDisabled(true)
	}, [firstName, lastName, email, password, confirmPassword])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (
			!submitDisabled &&
			validateEmail() &&
			validateFirstName() &&
			validateLastName() &&
			validatePassword() &&
			validateDateOfBirthday()
		) {
			submitData();
		} else {
			showSubmitDisabled(true)
		}
	};

  const submitData = async () => {
    try {
      const {data} = await axios.post(
        `${Constants.IDENTITY_API}/auth/v1/customer/signUp`,
        state
			);
			setShowSuccess(true)
			setOutput(data)
      GoogleAnalyticsFunctions.clickedSignUp();
    } catch ({ response, ...error }) {
			if(response) {
				if(response.status === 404 ||response.status === 500) {
					toast.info("Please try again after a while.", {position: toast.POSITION.BOTTOM_CENTER,})
				} else {
					let errObj = { ...error };
					response.data.errors.map(({ message, param }, index) => {
						if (param) {
							if (message === "User already Exists") {
									toast.info("User already exists for this email. Please sign in to continue.", {
											position: toast.POSITION.BOTTOM_CENTER,
									});
									history.push("/sign-in");
							}
							const error = param + 'Error';
							const errorMsg = param + 'ErrorMsg';
							errObj = { ...errObj, [error]: true, [errorMsg]: message };
						}
						setError({ ...errObj });
					});
				}
			}
    }
  };

  return (
    <div className="col-xs-12 col-sm-12 p-0">
      <div className="sign-in-main-page overall-font">
        <div className="sign-in-main-container-background">
          <div className="mt-0">
            <div
              className="header"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '63px',
                  height: '50px',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <img
                  alt="Go back"
                  className="individual-wine-details-back-btn"
                  src={leftArrowBtn}
                  onClick={() => {
                    setShowEmailForm(false);
                  }}
                />
              </div>
              <p className="new-sign-up-title mb-1">Sign Up</p>
              <img
                alt="PairAnything"
                src={logo}
                style={{
                  width: '63px',
                  height: '50px',
                  float: 'right',
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-12 p-0 social-media-container">
              <GoogleButton
                clientId={Constants.GOOGLE_CLIENT_ID}
                isMobile={false}
                onSuccess={googleResponse}
              />
              <FaceBookButton
                appId={Constants.FACEBOOK_APP_ID}
                scope={'public_profile, email, user_birthday, user_age_range'}
                fields={'name,email,picture,birthday,user_age_range'}
                cssClass={'facebook-btn'}
                autoLoad={false}
                // size={'md'}
                textButton={'Facebook'}
                isMobile={false}
                callback={facebookResponse}
              />
            </div>
            <div className="new-sing-up-address-container mt-2">
              <form>
                <div className="form-group">
                  <input
                    type="name"
                    className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                    onBlur={validateOnBlur}
                    placeholder="First Name"
                  />
                  {firstNameError ? (
                    <span className="form-error-shipping-address new-error-msg-font">
                      {firstNameErrorMsg}
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="name"
                    className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                    onBlur={validateOnBlur}
                    placeholder="Last Name"
                  />
                  {lastNameError ? (
                    <span className="form-error-shipping-address new-error-msg-font">
                      {lastNameErrorMsg}
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    onBlur={validateOnBlur}
                    placeholder="Email"
                  />
                  {emailError ? (
                    <span className="form-error-shipping-address new-error-msg-font">
                      {emailErrorMsg}
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    onBlur={validateOnBlur}
                    placeholder="password"
                  />
                  {passwordError ? (
                    <span className="form-error-shipping-address new-error-msg-font">
                      {passwordErrorMsg}
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    onBlur={validateOnBlur}
                    placeholder="confirm Password"
                  />
                  {confirmPasswordError ? (
                    <span className="form-error-shipping-address new-error-msg-font">
                      {confirmPasswordErrorMsg}
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                    className="btn btn-round sign-in-btn mb-2 mt-4"
                  >
                    Sign Up
                  </button>
                  {commonError ? (
                    <span className="form-error-shipping-address new-error-msg-font">
                      {commonErrorMsg}
                    </span>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// For Typechecking React Components
EmailSignupForm.propTypes = {
	setShowEmailForm: PropTypes.func.isRequired,
};

export default React.memo(EmailSignupForm)