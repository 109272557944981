import React, { Component } from "react";
import redAddIcon from "../../../assets/images/red-add-icon.svg";
import locationIcon from "../../../assets/images/ShippingAddress/address.svg";
import "./AddressSelectionComponent.scss";
import * as commonFunctions from '../../constants/commonFunctions';

export default class AddressSelectionComponent extends Component {
  render() {
    const {
      addressData,
      onAddressSelect,
      onAddressDelete,
      onAddressEdit,
      selectedCard
    } = this.props;
    return (
      <React.Fragment>
        <div className="address-selection-container col-sm-12 col-xs-12 mt-4">
          <div className="select-address-radio col-sm-1 col-xs-1 p-0">
            <input
              type="radio"
              className='ptr-clss'
              value={addressData.id}
              checked={selectedCard}
              onChange={() => onAddressSelect(addressData)}
            />
          </div>
          <div
            className="col-sm-11 col-xs-11 p-0 radio-btn-address-container ptr-clss"
            onClick={() => onAddressSelect(addressData)}
          >
            <div className="mt-4 ml-3 mr-3">
              <img
                alt="Shipping Location"
                className="shpng-lctn"
                src={locationIcon}
              />
              <span className="address-type-label">
                {commonFunctions.capitalize(addressData.name)}
              </span>
            </div>
            <div className="shpng-address-details mt-3 mb-3 mr-3">
              <div>
                <span className="">
                  {addressData.addressLine1 ? commonFunctions.capitalize(addressData.addressLine1) : " "}{" "}
                  {/* {addressData.addressLine2 ? commonFunctions.capitalize(addressData.addressLine2) + "," : " "} */}
                  {/* {addressData.city ? commonFunctions.capitalize(addressData.city) + "," : " "}{" "}
                  {addressData.stateName} */}
                </span>
              </div>
              <div className=''>
                <span className="">
                  {addressData.addressLine2 ? commonFunctions.capitalize(addressData.addressLine2) + "" : " "}{" "}
                </span>
              </div>
              <div className=''>
                <span className="">
                  {addressData.city ? commonFunctions.capitalize(addressData.city) : " "}{", "}
                  {addressData.state.abbreviation + " "}{" "}
                  {addressData.zipcode ? addressData.zipcode : ""}
                </span>
              </div>
              {/* <div>
                <span className="">Zip: {addressData.zipcode ? addressData.zipcode + "." : ""}</span>
              </div> */}
            </div>
            <div className="shpng-address-edit-delete mt-4 mb-3 mr-3">
              <div>
                <span onClick={(e) => onAddressEdit(e, addressData.id)}>
                  Edit
                </span>
              </div>
              <div className="ml-4">
                <span onClick={(e) => onAddressDelete(e, addressData.id)}>Delete</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
