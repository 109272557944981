import React, { Component } from "react";

import "./NewConfirmCommonPopup.scss";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import greenCircletick from "../../../assets/images/greenCircletick.svg";
import { Modal } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#cd4545" },
  },
});

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      bottom: "0 !important",
      background: "none",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiTypography-body1": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
    },
    ".MuiInputBase-input": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
      width: "100%",
      border: 0,
      display: "block",
      padding: "6px 0 7px",
      minWidth: 0,
      background: "none",
      boxSizing: "content-box",
      animationName: "mui-auto-fill-cancel",
      lineHeight: "1.2",
    },
  },
})(() => null);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const contentStyle = {
  justify: "center",
  paddingRight: "19px",
  display: "flex",
  flexDirection: "column",
};

class NewConfirmCommonPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onDrawerOpen = () => {};

  onDrawerClose = () => {};

  render() {
    const {
      open,
      headerText,
      handleYes,
      handleCancel,
      confirmText,
      cancelText,
      addToCartClick,
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-style"
      >
        <div className="modal-bg font-family">
          {/* <div
            onTouchStart={() => {
              handleCancel();
            }}
            className="thin-line-div new-thin-line-div"
          >
            <div className="thin-line"></div>
          </div> */}
          <div className="headertext-style">
            {addToCartClick && (
              // <img
              //     alt="Tick"
              //     className="mr-3"
              //     src={greenCircletick}
              // />

              // Custom css tick mark
              <span className="checkmark mr-3">
                <div className="checkmark_circle"></div>
                <div className="checkmark_stem"></div>
                <div className="checkmark_kick"></div>
              </span>
            )}
            <span>{headerText}</span>
          </div>
          <div className="new-footer-pop-btn-css">
            <div className="new-cancel-css">
              <button
                className="btn btn-round cancel-button new-cnacel-btn-css"
                onClick={() => {
                  handleCancel();
                }}
              >
                {cancelText}
              </button>
            </div>
            <div className="new-yes-css">
              <button
                className={
                  confirmText.startsWith("Proceed")
                    ? "btn btn-round save-button new-proceed-btn-css"
                    : "btn btn-round save-button"
                }
                onClick={() => {
                  handleYes();
                }}
              >
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      // <React.Fragment>
      //     <div className="cart-popup">
      //     <MuiThemeProvider theme={theme}>
      //         <SwipeableDrawer
      //             onBackdropClick={() => { handleCancel() }}
      //             anchor="bottom"
      //             open={open}
      //             style={contentStyle}
      //             onOpen={this.onDrawerOpen}
      //             onClose={this.onDrawerClose}
      //         >
      //             <GlobalCss />
      //             <div className="bg font-family">
      //                 <div onTouchStart={() => { handleCancel() }} className="thin-line-div new-thin-line-div">
      //                     <div className="thin-line"></div>
      //                 </div>
      //                 <div className='headertext-style'>
      //                     {addToCartClick && (
      //                         // <img
      //                         //     alt="Tick"
      //                         //     className="mr-3"
      //                         //     src={greenCircletick}
      //                         // />

      //                         // Custom css tick mark
      //                         <span className="checkmark mr-3">
      //                             <div className="checkmark_circle"></div>
      //                             <div className="checkmark_stem"></div>
      //                             <div className="checkmark_kick"></div>
      //                         </span>
      //                     )}
      //                     <span>{headerText}</span>
      //                 </div>
      //                 <div className="new-footer-pop-btn-css">
      //                     <div className="new-cancel-css">
      //                         <button className="btn btn-round cancel-button new-cnacel-btn-css"
      //                             onClick={() => { handleCancel() }}>
      //                             {cancelText}
      //                         </button>
      //                     </div>
      //                     <div className="new-yes-css">
      //                         <button className={confirmText.startsWith('Proceed') ?
      //                             'btn btn-round save-button new-proceed-btn-css' : 'btn btn-round save-button'}
      //                             onClick={() => { handleYes() }}>
      //                             {confirmText}
      //                         </button>
      //                     </div>
      //                 </div>
      //             </div>
      //         </SwipeableDrawer>
      //     </MuiThemeProvider>
      //     </div>
      // </React.Fragment>
    );
  }
}

export default NewConfirmCommonPopUp;
