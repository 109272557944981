import React, { Component } from 'react';
import food from "../../../assets/images/food.png"
import './WineSearchCard.scss';
import emptyCardSearchImage from "../../../assets/images/EmptyPageCard/recommendation-screen-image.svg";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";



class WineSearchCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: []
        }
    }


    componentDidMount = () => { }



    render() {
        let value = this.props && this.props.result
        let emptyCardData = {
            title: "No recommendations available",
            text: "Please enter another search",
            image: emptyCardSearchImage,
        };
        return (
            <>
                { value.length == 0 ?
                    <div>
                        <EmptyPageCard emptyPageCardData={emptyCardData} />
                    </div>
                    :
                    value && value.length !== 0 && < div className="card" style={{ width: '90%' }}>
                        <div class="card-horizontal">
                            <div class="img-square-wrapper" style={{ width: '35%' }}>
                                <img class="" src={value[0].image  ? value[0].image : food} alt="Card image cap" width="100" height="100" />
                            </div>
                            <div class="card-body" style={{ width: '70%', padding:'0.25rem' }}>
                                <strong class="card-title font-family">{value[0].foodName}</strong>
                                <p class="card-text ">{value[0].wineType}, {value[0].varietalName}</p>
                                <p class="card-text" >{value && value[0].RecipeUrl ? <a href={value[0].RecipeUrl} style={{ color: "#CD4545" }} target="_blank">View Recipe<i class="fa fa-chevron-right" style={{ color: "#CD4545" }} aria-hidden="true"></i></a> : ''}</p>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default WineSearchCard;