import React, { useEffect, useState } from 'react';
import PoweredByHeader from '../PoweredByHeader/PoweredByHeader';
import './PARecommendationEntrance.scss';
import ChickenWing from '../../../assets/images/Entrance/chicken-wing.png';
import WineBottomLeft from '../../../assets/images/Entrance/wine-1.png';
import WineBottomMiddle from '../../../assets/images/Entrance/wine-2.png';
import Pizza from '../../../assets/images/Entrance/pizza.png';
import NewSearchIcon from '../../../assets/images/newSearchIcon.svg';

const cycles = [
    "Chicken Wings",
    "Pinot Noir"
]

export default function PARecommendationEntrance({ setShowResult, query }) {
    const [placeholderIndex, setPlaceholderIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (placeholderIndex === cycles.length - 1) setPlaceholderIndex(0);
            else setPlaceholderIndex(placeholderIndex + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <PoweredByHeader/>
            <img src={ChickenWing} width="101px" height="137px" className="top-right"/>
            <img src={WineBottomLeft} width="128px" height="260px" className="bottom-left"/>
            <img src={WineBottomMiddle} width="121px" height="160px" className="bottom-middle"/>
            <img src={Pizza} width="129px" height="147px" className="bottom-right"/>

            <div className="entrance-content-container">
                <div className="entrance-title">
                    What pairs well with
                </div>
                <div className="entrance-query-container">
                    <input className="entrance-query" placeholder={cycles[placeholderIndex]}/>
                    <img src={NewSearchIcon} alt="search-icon" className="entrance-search-icon"></img>
                </div>
                <div className="entrance-subtitle-container">
                    TAP TO TYPE A <strong className="bold">FOOD</strong> OR <strong className="bold">WINE</strong> FOR A PAIRING
                </div>
            </div>
        </div>
    )
}