
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import './index.css';
import App from './App';
import firebase from "firebase";

import * as constants from './app/constants/constants';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
//var getAllPreviousFCMTokenResponse;

let consumerData = (JSON.parse(localStorage.getItem('user_data')));

ReactGA.initialize(constants.GA_TRACKING_ID)

// try {

//   console.log('inside index.js')

// let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );

// }catch(e){

// }


// var firebaseConfig = {
//     apiKey: "AIzaSyADFoqDoIUGSlCpr6jzcftFyjxt7YChC1E",
//     authDomain: "pairanythingdev.firebaseapp.com",
//     databaseURL: "https://pairanythingdev.firebaseio.com",
//     projectId: "pairanythingdev",
//     storageBucket: "pairanythingdev.appspot.com",
//     messagingSenderId: "707091803686",
//     appId: "1:707091803686:web:0d36d4c3f56a27913480e7",
//     measurementId: "G-45JQRYHMG8"
//   };

var firebaseConfig = {
  apiKey: "AIzaSyDqUFWM9CN-b48EHnHHDFspgGAA2DnmMpw",
  authDomain: "pairanything-dev.firebaseapp.com",
  databaseURL: "https://pairanything-dev.firebaseio.com",
  projectId: "pairanything-dev",
  storageBucket: "pairanything-dev.appspot.com",
  messagingSenderId: "1095603135777",
  appId: "1:1095603135777:web:54657be4f01a169daeca60",
  measurementId: "G-S8FQDLGE2H"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//var subscription_token = ""


function checkForAlreadyExistingNotificationSubscription() {

  //console.log('checkForAlreadyExistingNotificationSubscription');

  let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
  let bearer = 'Bearer ' + signInToken;
  try {


    let wineryId = (JSON.parse(localStorage.getItem('winery_id')));

    let checkForNotificationSubscriptionApiUrl = constants.WINERY_API + '/api/v1/notificationSubscription/user/' + consumerData.id + '/winery/' + wineryId;
    fetch(checkForNotificationSubscriptionApiUrl, {

      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": bearer
      }
    })
      .then(res => {
        if (res.ok) {

          return res.json();

        } else {
          throw Error(res.statusText);
        }
      })
      .then(json => {

        //getAllPreviousFCMTokenResponse = json;

        initializePush(json);
      })
      .catch(error => {
        // console.error(error)
      }
      );

  } catch (e) {

  }

}
function initializePush(previousFCMTokenResponse) {


  const messaging = firebase.messaging();
  messaging
    .requestPermission()
    .then(() => {
      // console.log("Have Permission");
      return messaging.getToken();
    })
    .then(token => {
      // console.log("FCM Tokens::", token);
      // console.log('Previous FCM Tokens::', previousFCMTokenResponse);
      localStorage.setItem('fcm_token', JSON.stringify(token));

      let previousFCMTokenAlreadyPresent = 0;

      if (previousFCMTokenResponse !== null && previousFCMTokenResponse !== undefined) {



        for (let i = 0; i < previousFCMTokenResponse.rows.length; ++i) {
          if (token === previousFCMTokenResponse.rows[i].fcmToken) {
            previousFCMTokenAlreadyPresent++;
            // console.log('FCM Token already present!')
            break;
          }
        }


        try {

          let wineryId = (JSON.parse(localStorage.getItem('winery_id')));

          if (previousFCMTokenAlreadyPresent === 0 && wineryId !== null && wineryId !== undefined
            && consumerData.id !== null && consumerData.id !== undefined) {


            //subscription_token = token
            let subscription = {
              userId: consumerData.id,
              wineryId: wineryId,
              userVisibleOnly: true,
              fcmToken: token

            }

            let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
            if (signInToken !== null && signInToken !== undefined &&
              subscription.fcmToken != null && subscription.fcmToken !== undefined) {

              //console.log('before sendSubscription-obj: ', subscription);
              sendSubscription(subscription); // call sendSubscription() only after successful sign-in 
            } else {
              // console.log('no!')
            }
          }
        } catch (e) {

        }

      }

      //you probably want to send your new found FCM token to the
      //application server so that they can send any push
      //notification to you.
    })
    .catch(error => {
      if (error.code === "messaging/permission-blocked") {
        //  console.log("Please Unblock Notification Request Manually");
      } else {
        //  console.log("Error Occurred", error);
      }
    });

  // }
}

function sendSubscription(subscription) {


  let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
  let bearer = 'Bearer ' + signInToken;
  //console.log('inside send subscription - obj', subscription);

  let sendSubscriptionApiUrl = constants.IDENTITY_API + '/api/v1/notificationSubscription/create';
  // https://pairanything-identity-api-dev.redblacktree.net/api/v1/notificationSubscription/create
  return fetch(sendSubscriptionApiUrl, {
    method: 'POST',
    body: JSON.stringify(subscription),
    headers: {
      'Content-Type': 'application/json',
      "Authorization": bearer
    }
  })
}



if ('serviceWorker' in navigator) {
  // window.addEventListener('load', async () => {
  //   const registration = await navigator.serviceWorker.register('../firebase-messaging-sw.js', {
  //     updateViaCache: 'none'
  //   });
  //   const messaging = firebase.messaging();
  //   console.log(registration);
  //   checkForAlreadyExistingNotificationSubscription();
  //   messaging.useServiceWorker(registration);
  //   messaging.onMessage((payload) => {
  //     console.log(payload);
  //     const title = payload.data.title;
  //     const options = {
  //       body: payload.data.body,
  //       icon: '/public/PA192.png',
  //       data: { url: payload.data.click_action }, //the url which we gonna use later
  //       actions: [{ action: "open_url", title: "Open" }]
  //     }
  //     registration.showNotification(title, options);
  //   });
  // });

  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function (registration) {

    if (registration.pushManager) {
      // Web Push supported.
      //console.log("web push supported! ")

      checkForAlreadyExistingNotificationSubscription(); //  response returns previous FCM Tokens

      const messaging = firebase.messaging();
      messaging.onMessage(function (payload) {
        //console.log("onMessage: ", payload)
      })

    } else {
      //console.log("web push not supported! ")
    }
    navigator.serviceWorker.addEventListener("message", (message) => {
      navigator.serviceWorker.ready.then(function (registration) {
        const payload = message.data['firebase-messaging-msg-data']
        const baseUrl = new URL(payload.data.click_action)
        const isUserActiveOnPage = baseUrl.pathname === window.location.pathname
        const title = payload.data.title;
        const options = {
          body: payload.data.body,
          icon: '../PA192.png',
          data: { url: payload.data.click_action }, //the url which we gonna use later
          actions: [{ action: "open_url", title: "Open" }]
        }
        if (!isUserActiveOnPage) return registration.showNotification(title, options);
      })
    });
    //console.log('Registration successful, scope is:', registration.scope);
  }).catch(function (err) {
    //console.log('Service worker registration failed, error:', err);
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

