import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import "./PaymentSuccessPopUp.scss";
import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
// const theme = useTheme();

const MsgContainerCss = withStyles({
  "@global": {
    // ".MuiBackdrop-root": {
    //   background: "none !important",
    // },
  },
})(() => null);

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiPaper-root": {
      background: "none",
      //   borderRadius: "60%",
    },

    ".MuiPaper-elevation24": {
      boxShadow: "none",
    },

    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiDialogContent-root:first-child": {
      paddingTop: "3px",
      //   paddingLeft: "28px",
      //   paddingRight: "28px",
      //   paddingTop: "8px",
      //   paddingBottom: "18px",
    },
    ".MuiDialogContent-root": {
      overflowY: "visible",
      padding: "5px 12px",
    },
  },
})(() => null);

const checkContainer = {
  //   height: "15px",
  //   width: "15px",
  //   border: "8px solid#a84551",
  borderRadius: "50%",
  background: "#CD4545",
  //   background: "#3F3D56",
  //   paddingTop: "10px",
  //   paddingBottom: "1px",
  //   paddingRight: "19px",
  //   display: "flex",
  //   flexDirection: "column",
  //   borderRadius: "50%",
};

const tickbg = {
  paddingLeft: "28px",
  paddingRight: "28px",
  paddingTop: "8px",
  paddingBottom: "18px",
};

const title = {
  fontFamily: "poppins, SemiBold 14px/21px sans-serif",
  fontSize: "1.5rem",
  color: "white",
  fontWeight: "500",
  letterSpacing: "0.5px",
  marginLeft: "1.9rem",
  marginTop: "0.25rem",
};

const checkIcon = {
  display: "inline-block",
  transform: "rotate(45deg)",
  height: "100px",
  width: "50px",
  borderBottom: "5px  solid white",
  borderRight: "5px  solid white",
  background: "#CD4545",
};

const successMessage = {
  textAlign: "center",
  fontSize: "initial",
  marginTop: "1em",
  color: "#CD4545",
  fontWeight: "500",
};

const msgContainer = {
  marginTop: "10em",
  background: "none",
  backgroundColor: "none",
};

export default class PaymentSuccessPopUp extends Component {
  render() {
    let { open } = this.props;
    return (
      <React.Fragment>
        <React.Fragment>
          <GlobalCss />
          <Dialog open={open} aria-labelledby="payment-added">
            <DialogContent style={checkContainer}>
              <DialogContent style={tickbg}>
                <DialogContent>
                  <Typography style={checkIcon}></Typography>
                </DialogContent>
              </DialogContent>
            </DialogContent>
            <DialogContentText style={successMessage}>
              Payment Successful
            </DialogContentText>
          </Dialog>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
