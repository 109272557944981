import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./IndividualWineDetails.scss";
import moment from "moment";

import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from "react-player";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import wineBottleImage from "../../../assets/images/individual-wine-bottle.png";
import openWineUrlInBrowser from "../../../assets/images/open-website-url.svg";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";

import leftArrowBtn from "../../../assets/images/left-arrow-white.svg";

import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";

import * as constants from "../../constants/constants";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import winePlaceholder from "../../../assets/images/wine-placeholder.jpg";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import AddedToCartPopUp from "../../components/AddedToCartPopUp/AddedToCartPopUp";
// wine_bottle_placeholder1 , wine_bottle_placeholder
import * as commonFunctions from "../../constants/commonFunctions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AddToCartAction from "../../redux/actions/AddToCartAction";
import { isMobile } from "react-device-detect";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import backBtn from "../../../assets/images/back-btn.svg";
import NewConfirmCommonPopUp from "../../components/NewConfirmCommonPopup/NewConfirmCommonPopup";
import ShareButton from "../../components/ShareButton/ShareButton";
import * as GetCartTotalQtyAction from "../../redux/actions/GetCartTotalQtyAction";
import LikeButton from "../../components/LikeButton/LikeButton";
import { Button, Collapse } from "react-bootstrap";
import SignInToContinue from "../../components/CommonComponents/SignInToContinue";
import ReactLinkify from "react-linkify";
import discountTag from "../../../assets/images/discountTag.svg";
import { setCurrentWineryDetails } from "../../redux/actions/WineryAction";
import { isEmbedded } from '../../constants/commonFunctions';

import PAResultsTastePreferenceCard from '../../components/PAResultsTastePreferenceCard/PAResultsTastePreferenceCard';

let consumerData;
let wineryId;

toast.configure();
class IndividualWineDetails extends Component {
  constructor(props) {
    // consumerData = JSON.parse(localStorage.getItem("user_data"));
    // wineryId = JSON.parse(localStorage.getItem("winery_id"));
    super(props);
    this.state = {
      dishName: "",
      searchPageLoaded: false,
      availableWinesPageLoaded: false,
      loaded: false,
      buyNowStatus: false,
      wineryApiCallDone: false,
      wineryDetails: "",
      wineryLogo: "",
      showAddedToCartPopUp: false,
      wineryId: "",
      wineId: "",
      addToCartApiCall: false,
      isNavigateToChatPage: false,
      isNavigateToNewBrowser: false,
      navigationWineUrl: "",
      navigationWebsite: "",
      addToCartSuccess: "",
      addToCartFail: "",
      cartProceedToBuyText: "Proceed to Buy",
      cartContinueShoppingText: "Continue Shopping",
      headerText: "Added to Cart.",
      disableAddtoCartOnApiCall: false,
      descriptionOpen: true,
      specificationOpen: true,
      wineProfileOpen: true,
    };
  }

  showImage = () => {
    this.setState({
      loaded: true,
    });
  };

  descriptionCollapse = () => {
    this.setState({
      descriptionOpen: !this.state.descriptionOpen,
    });
  };

  specificationCollapse = () => {
    this.setState({
      specificationOpen: !this.state.specificationOpen,
    });
  };

  wineProfileCollapse = () => {
    this.setState({
      wineProfileOpen: !this.state.wineProfileOpen,
    });
  };

  gotoRecommendationsPage = () => {
    if (this.state.dishName != "") {
      this.sendPairingQuestion();
    }
  };

  componentDidMount = () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    localStorage.setItem(
      "winery_id",
      this.props.location.state.wineIndividualData.wineryId
    );
    // localStorage.setItem(
    //   "winery_name",
    //   this.props.location.state.wineIndividualData.winery.displayName
    // );
    // this.props.setCurrentWineryDetails(
    //   this.props.location.state.wineIndividualData.winery
    // );
    if (this.props.location.state.addToCartAutomatically) {
      this.addToCart();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    //nextProps - new val from reducer
    // prevState - state value

    let returnValue = {};
    try {
      if (nextProps.location.state.wineIndividualData != undefined) {
        // return {
        //   wineryId: nextProps.location.state.wineIndividualData.wineryId,
        // };
        returnValue.wineryId =
          nextProps.location.state.wineIndividualData.wineryId;
        returnValue.wineId = nextProps.location.state.wineIndividualData.id;
      }
      if (prevState.addToCartApiCall === true) {
        if (nextProps.addToCartSuccess != undefined) {
          if (nextProps.addToCartSuccess.usesPAShoppingCart === false) {
            if (nextProps.addToCartSuccess.wineUrl) {
              returnValue.isNavigateToChatPage = false;
              returnValue.isNavigateToNewBrowser = true;
              returnValue.website = "";
              returnValue.wineUrl = nextProps.addToCartSuccess.wineUrl;
              returnValue.addToCartApiCall = false;
              return returnValue;
            } else if (nextProps.addToCartSuccess.website) {
              returnValue.isNavigateToChatPage = false;
              returnValue.isNavigateToNewBrowser = true;
              returnValue.website = nextProps.addToCartSuccess.website;
              returnValue.wineUrl = "";
              returnValue.addToCartApiCall = false;
              return returnValue;
            } else {
              returnValue.isNavigateToChatPage = true;
              returnValue.isNavigateToNewBrowser = false;
              returnValue.addToCartApiCall = false;
              return returnValue;
            }
          } else {
            returnValue.isNavigateToChatPage = true;
            returnValue.isNavigateToNewBrowser = false;
            returnValue.addToCartApiCall = false;
            return returnValue;
          }
        } else if (nextProps.addToCartFail != undefined) {
          returnValue.isNavigateToChatPage = true;
          returnValue.isNavigateToNewBrowser = false;
          returnValue.addToCartApiCall = false;
          return returnValue;
        }
      }
      return returnValue;
    } catch (e) {
      return {
        availableWinesPageLoaded: true,
      };
    }

    return null;
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  getWineryDetails = (wineryId) => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    // let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    // let wineryId = this.props.location.state.wineIndividualData.wineryId;
    let getWineryDetailsApiUrl =
      constants.WINERY_API + "/api/v1/winery/consumer?wineryId=" + wineryId;
    fetch(getWineryDetailsApiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getWineryDetailsApiUrl,
            operation: "GET",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        this.setState({
          wineryApiCallDone: true,
          wineryLogo: json.logo,
        });
      })
      .catch((error) => console.error(error));
  };

  buyNowClicked = () => {
    if (
      this.props.location.state.wineIndividualData.wineUrl != null &&
      this.props.location.state.wineIndividualData.wineUrl != "" &&
      this.props.location.state.wineIndividualData.wineUrl != undefined
    ) {
      this.openWineUrlInWebBrowser();
    } else {
      this.setState({
        buyNowStatus: true,
      });
    }

    GoogleAnalyticsFunctions.clickedBuyNowForWine();
  };

  gotoPreviousPage = () => {
    // if (this.props.location.state.individualWineDetailsFromAvailableWines) {
    //   this.setState({
    //     availableWinesPageLoaded: true,
    //   });
    // } else
    if (this.props.location.state.individualWineDetailsFromSearch) {
      this.setState({
        searchPageLoaded: true,
      });
    } else this.props.history.goBack();
    // this.props.history.push(
    //   "/" +
    //     commonFunctions.convertToCamelCase(
    //       localStorage.getItem("winery_name")
    //     ) +
    //     "/wines"
    // );
    // this.props.history.push("/available-wines");
  };

  openWineUrlInWebBrowser = () => {
    let url = this.props.location.state.wineIndividualData.wineUrl;

    if (url != null && url != undefined && url != "") {
      if (!url.includes("http://") && !url.includes("https://")) {
        url = "https://" + url;
      }
    }

    var newWindow = window.open(url, "_blank");
    // newWindow.focus();
  };

  /*
     #NOTE:
            1) Individual wine details page can be loaded on clicking the list of wines
               in the available wine page . 
               (individualWineDetailsFromAvailableWines is set to true ) in props

            2) Individual wine details page can also be loaded on clicking the list of wines in the Search for wines
               based on dish page
               (individualWineDetailsFromSearch is set to true - set in Wine Details component ) in props
            
            3) In both cases, we send data through react redirect state that is received using this.props 
            
    */
  componentDidUpdate(prevProps, prevStates) {
    if (this.state.availableWinesPageLoaded) {
      // return <Redirect to={{
      //     pathname: '/available-wines',
      //     state: {

      //            }

      // }}/>;
      this.props.history.push({
        pathname:
          "/" +
          commonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          ) +
          "/wines",
        // pathname: "/available-wines",
        state: {},
      });
    } else if (this.state.searchPageLoaded) {
      //   return <Redirect to={{
      //     pathname: '/search-wine' + '/' + this.state.wineryId,
      //     // pathname: '/winery/' + this.state.wineryId + '/recommended-wines/' + this.props.questionIdForCurrentWineFromRecommendationHistory,
      //     state: {
      //         // searchPageLoaded: true
      //            }

      // }}/>;
      this.props.history.push({
        pathname:
          "/" +
          commonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          ) +
          "/pairings",
        state: {
          // searchPageLoaded: true
        },
      });
    } else if (this.state.isNavigateToChatPage) {
      this.onNavigatingToChatPage();
    } else if (this.state.isNavigateToNewBrowser) {
      if (this.state.wineUrl) {
        this.onOpenWineUrlInNewTab(this.state.wineUrl);
      } else if (this.state.website) {
        this.onOpenWineUrlInNewTab(this.state.website);
      }
    }
    // else if (this.state.buyNowStatus) {
    //   let pageRenderedFrom;
    //   if (this.props.location.state.individualWineDetailsFromAvailableWines) {
    //     pageRenderedFrom = "/available-wines";
    //   } else if (this.props.location.state.individualWineDetailsFromSearch) {
    //     pageRenderedFrom = "/search-wine" + "/" + this.state.wineryId;
    //   }
    //   this.props.history.push({
    //     pathname: "/chat" + "/" + this.state.wineryId,
    //     state: {
    //       openChatForWinePurchase: true,
    //       wineIndividualData: this.props.location.state.wineIndividualData,
    //       chatFromIndividualWinesPage: pageRenderedFrom,
    //     },
    //   });
    // }
  }
  render() {
    console.log(this.props.location.state);
    var buttonText;
    if (
      this.props.location.state.wineIndividualData &&
      !this.props.location.state.wineIndividualData.availableForSale
    ) {
      buttonText = "Coming Soon";
    } else {
      if (!this.props.location.state.wineIndividualData.isDeleted) {
        let cur_winery_id = JSON.parse(localStorage.getItem("winery_id"));
        if (cur_winery_id && cur_winery_id == 381) { //For target winestation
          buttonText = "Shop Now at Target"
        } else {
          buttonText = "Buy Now";
        }
      } else {
        buttonText = "No Longer Available";
      }
    }
    // if (this.state.wineryId != "" && this.state.wineryApiCallDone == false) {
    //   this.getWineryDetails(this.state.wineryId);
    // }

    let bottledDay = moment(
      this.props.location.state.wineIndividualData.specifications.bottledDate
    ).format("DD");
    let bottledMonth = moment(
      this.props.location.state.wineIndividualData.specifications.bottledDate
    ).format("MMM");
    let bottledYear = moment(
      this.props.location.state.wineIndividualData.specifications.bottledDate
    ).format("YYYY");
    let dealData = this.props.location.state.dealData;
    let discountPercent =
      dealData && dealData.discount
        ? dealData.discount
        : dealData
        ? (dealData.dollarsOff /
            this.props.location.state.wineIndividualData.price[0].price) *
          100
        : "";
    let discountPrice =
      dealData && dealData.dollarsOff
        ? dealData.dollarsOff
        : dealData
        ? (this.props.location.state.wineIndividualData.price[0].price *
            dealData.discount) /
          100
        : "";
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <ErrorBoundary>
            <div className="success-inner-container new-discover-inner-container-induvidual-wines">
              <div
                className="individual-wine-details-main-container"
                // style={
                //   isMobile
                //     ? null
                //     : {
                //         // height: "80vh",
                //         overflowY: "scroll",
                //         overflowX: "hidden",
                //       }
                // }
              >
                {/* <div className="individual-wine-details-header1"> */}

                <div className="individual-wine-details-header1">
                  <div className="common-page-sub-header new-common-header-change">
                    <img
                      style={{ cursor: "pointer" }}
                      alt="Open Recommendations page"
                      className="common-sub-header-back-icon"
                      onClick={this.gotoPreviousPage.bind(this)}
                      src={leftArrowBtn}
                    />
                    <div className="new-header-text">
                      <span className="common-sub-header-text new-search-wine-for-dish-text">
                        Wine Details
                      </span>
                    </div>
                    <div className="new-header-text">
                      <span className="common-sub-header-text new-search-wine-for-dish-text">
                        {" "}
                      </span>
                    </div>
                    {/* <img
                      alt="PairAnything"
                      className="common-sub-header-logo  new-logo-header-design ml-0"
                      src={pairAnythingLogo}
                      onClick={() => { this.openSwitchWinery() }}
                    /> */}
                  </div>
                  {/* <div className="individual-wine-details-subheader">
                    <img
                      alt="Go back"
                      className="individual-wine-details-back-btn"
                      src={leftArrowBtn}
                      onClick={this.gotoPreviousPage.bind(this)}
                    />
                    <span className="individual-wine-details-title">
                      Wine Details
                    </span>
                  </div> */}
                </div>

                <div className="individual-wine-details-scroll-container">
                  <div className="individual-wine-details-main-container1">
                    <div className="individual-wine-details-sub-container">
                      <div
                        // className="spot-card shadow"
                        className="card shadow individual-wine-details-image-container"
                      >
                        <div className="available-wine-details-list ptr-clss">
                          <div className="wine-text-div">
                            <div className="discount-like-box">
                              <div className="like">
                              {/* <ShareButton wine={this.getWineDetails()} /> */}
                                <LikeButton
                                  wine={{
                                  wineId: this.state.wineId,
                                  wineryId: this.state.wineryId,
                                  // "id": this.state.id,
                                  }}
                                />
                              </div>
                              {dealData ? (
                                <div>
                                  {/* <img className="badge-place" src={discountTag} /> */}
                                  <span className={"badge-text-avail"}>
                                    {dealData.discount
                                      ? parseInt(dealData.discount) + "% Off"
                                      : "$" +
                                        parseInt(dealData.dollarsOff) +
                                        " Off"}
                                  </span>
                                </div>
                              ) : null}
                          </div>



                            <div
                              className="available-wine-name-container"
                              // onClick={this.gotoIndividualWineDetailsPage.bind(
                              //   this
                              // )}
                            >
                              <div className="available-wine-subcontainer">
                                <span className={"deal-name deal-margin"}>
                                  {this.props.location.state.wineIndividualData
                                    .wineName != null &&
                                  this.props.location.state.wineIndividualData
                                    .wineName != undefined
                                    ? this.props.location.state
                                        .wineIndividualData.wineName
                                    : "Wine Name"}
                                </span>
                                <span
                                  className={
                                    isMobile
                                      ? "spot-wine-type"
                                      : "available-wine-subtype-title"
                                  }
                                >
                                  {this.props.location.state.wineIndividualData
                                    .wineType != null &&
                                  this.props.location.state.wineIndividualData
                                    .wineType != undefined
                                    ? this.props.location.state
                                        .wineIndividualData.wineType
                                    : "Wine Type"}
                                </span>
                                <span
                                  className={
                                    isMobile
                                      ? "spot-wine-type"
                                      : "available-wine-subtype-title"
                                  }
                                >
                                  {this.props.location.state.wineIndividualData
                                    .specifications.appellation
                                    ? this.props.location.state
                                        .wineIndividualData.specifications
                                        .appellation
                                    : ""}
                                </span>
                                {/* <span className="available-wine-subtype-title">
                {this.props.location.state.wineIndividualData.specifications.varietal != null &&
                this.props.location.state.wineIndividualData.specifications.varietal != undefined
                  ? this.props.location.state.wineIndividualData.specifications.varietal
                  : ""}
              </span>

              <span className="available-wine-subtype-title">
                {this.props.location.state.wineIndividualData.specifications.appellation != null &&
                this.props.location.state.wineIndividualData.specifications.appellation != undefined
                  ? this.props.location.state.wineIndividualData.specifications.appellation
                  : ""}
              </span> */}

                                {/* <span className = "available-wine-price-text">
                    { (this.props.location.state.wineIndividualData.price!=null && this.props.location.state.wineIndividualData.price!=undefined )? '$' + this.props.location.state.wineIndividualData.price[0].price : '$45'}

                    </span> */}
                              </div>
                              {/* <img className="cart-icon" src = {cartIcon} /> */}
                              {/* <span className="available-wine-price-text cart-icon">
                {this.props.location.state.wineIndividualData.price != null &&
                this.props.location.state.wineIndividualData.price != undefined
                  ? commonFunctions.formatter.format(this.props.location.state.wineIndividualData.price[0].price)
                  : " "}
              </span> */}
                            </div>
                            <div className="available-wine-subcontainer">
                              {/* {this.state.wineAverageRating !== 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {this.displayRatingBar(
                                  5,
                                  Math.round(this.state.wineAverageRating)
                                )}
                              </div>
                            ) : (
                              <p
                                className={
                                  isMobile
                                    ? "first-review-text"
                                    : "first-review-text-desktop"
                                }
                                onClick={this.handleReviewClick}
                              >
                                Be the first to review
                              </p>
                            )} */}
                              <div className="">
                                {this.props.location.state.wineIndividualData
                                  .price[0].price ? (
                                  dealData ? (
                                    <div className="render-flex">
                                      <span className="type-text-price">
                                        {this.props.location.state
                                          .wineIndividualData.price[0].price !=
                                          null &&
                                        this.props.location.state
                                          .wineIndividualData.price[0].price !=
                                          undefined
                                          ? commonFunctions.formatter.format(
                                              this.props.location.state
                                                .wineIndividualData.price[0]
                                                .price -
                                                parseFloat(discountPrice)
                                            )
                                          : " "}
                                      </span>
                                      <span className="spot-wine-price-text strike-text cart-icon">
                                        <del>
                                          {this.props.location.state
                                            .wineIndividualData.price[0]
                                            .price != null &&
                                          this.props.location.state
                                            .wineIndividualData.price[0]
                                            .price != undefined
                                            ? commonFunctions.formatter.format(
                                                this.props.location.state
                                                  .wineIndividualData.price[0]
                                                  .price
                                              )
                                            : " "}
                                        </del>
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="type-text-price">
                                      {this.props.location.state
                                        .wineIndividualData.price[0].price
                                        ? commonFunctions.formatter.format(
                                            this.props.location.state
                                              .wineIndividualData.price[0].price
                                          )
                                        : ""}
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            
                          </div>
                          
                          <div className="box">
                            
                            <div className="wine-logo">
                              <div className="available-wine-bottle-logo-container">
                                <img
                                  onLoad={this.showImage}
                                  alt="Available wines logo"
                                  className={
                                    this.props.location.state
                                      .wineIndividualData != null &&
                                    this.props.location.state
                                      .wineIndividualData != undefined &&
                                    this.props.location.state.wineIndividualData
                                      .wineImageSmall != "" &&
                                    this.props.location.state.wineIndividualData
                                      .wineImageSmall != null
                                      ? "available-wine-bottle-logo-from-api"
                                      : "available-wine-bottle-logo"
                                  }
                                  src={
                                    this.props.location.state.wineIndividualData
                                      .wineImageSmall != null &&
                                    this.props.location.state.wineIndividualData
                                      .wineImageSmall != "" &&
                                    this.props.location.state
                                      .wineIndividualData != undefined
                                      ? this.state.loaded
                                        ? this.props.location.state
                                            .wineIndividualData.wineImageSmall
                                        : wineBottlePlaceholder
                                      : wineBottlePlaceholder
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div
                {...{
                  className: "share",
                  onClick: (e) => {
                    // console.log(this.props)
                    e.stopPropagation();
                  },
                }}
              >
                <ShareButton wine={this.getWineDetails()} />
              </div> */}

                          {/* <img  
                    className = { (this.props.location.state.wineIndividualData!=null && this.props.location.state.wineIndividualData!=undefined && 
                        this.props.location.state.wineIndividualData.wineImageSmall!="" && this.props.location.state.wineIndividualData.wineImageSmall!=null )? "available-wine-bottle-logo-from-api" :
                                                                  "available-wine-bottle-logo"}

             
                        src = { (this.props.location.state.wineIndividualData.wineImageSmall!=null && this.props.location.state.wineIndividualData.wineImageSmall!="" 
                            && this.props.location.state.wineIndividualData!=undefined )? this.props.location.state.wineIndividualData.wineImageSmall : wineBottlePlaceholder}
                    /> */}
                        </div>
                      </div>
                    </div>
                    <div className="add-to-cart-btn-container">
                      <button
                        type="button"
                        // onClick={this.buyNowClicked.bind(this)}
                        onClick={this.addToCart}
                        className="btn btn-round wine-detail-add-to-cart-btn"
                        disabled={
                          this.props.location.state.wineIndividualData
                            .isDeleted ||
                          this.state.disableAddtoCartOnApiCall ||
                          !this.props.location.state.wineIndividualData
                            .availableForSale
                        }
                      >
                        {buttonText}
                      </button>
                    </div>
                    { ([1587, 1586].includes(this.state.wineId)) &&
                      <div className="vimeoWrapper">
                        <Vimeo video="https://vimeo.com/775683017" autoplay/>
                      </div>
                    }
                    { ([1584, 1585].includes(this.state.wineId)) &&
                      <div className="vimeoWrapper">
                        <Vimeo video="https://vimeo.com/754572784" autoplay/>
                      </div>
                    }
                    
                    <PAResultsTastePreferenceCard name={"hi"} wineType={this.props.location.state.wineIndividualData.wineType} varietal={this.props.location.state.wineIndividualData.specifications.varietal} propStyle={{width: "92%", marginTop: "5%"}}/>
                    <div className="individual-wine-details-sub-container2">
                      <div className="individual-wine-details-content-container1">
                        {this.props.location.state.wineIndividualData
                          .wineDescription ? (
                          <div className="card shadow individual-wine-details-data-subcontainer">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span className="individual-wine-details-wine-name">
                                {this.props.location.state.wineIndividualData
                                  .wineDescription
                                  ? "Description"
                                  : ""}
                              </span>
                              {/* <i
                                className={
                                  this.state.descriptionOpen
                                    ? "accordion-rotate-item__icon accordion-item__icon"
                                    : "accordion-item__icon"
                                }
                                onClick={() => {
                                  this.descriptionCollapse();
                                }}
                              ></i> */}
                            </div>
                            <Collapse in={this.state.descriptionOpen}>
                              <span className="type-text">
                                <ReactLinkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target="_blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {this.props.location.state.wineIndividualData
                                    .wineDescription
                                    ? this.props.location.state
                                        .wineIndividualData.wineDescription
                                    : ""}
                                </ReactLinkify>
                              </span>
                            </Collapse>
                            {/* <span className="individual-wine-paired-name">Can be paired with</span>
                                    <span className="individual-wine-paired-content">Chicken Masala. Fish Toast. Bread and Butter</span> */}
                            <span></span>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* <hr />  */}

                        {/* <hr />   */}

                        {
                          // display div for specifications only if specification obj values!=""
                          this.props.location.state.wineIndividualData
                            .specifications &&
                          (this.props.location.state.wineIndividualData
                            .specifications.alcoholPercentage != "" ||
                            this.props.location.state.wineIndividualData
                              .specifications.bottledDate != "" ||
                            this.props.location.state.wineIndividualData
                              .specifications.cooperage != "" ||
                            this.props.location.state.wineIndividualData
                              .specifications.vintage != "" ||
                            this.props.location.state.wineIndividualData
                              .specifications.vineyard != "" ||
                            this.props.location.state.wineIndividualData
                              .specifications.pH != "") ? (
                            <div className="card shadow individual-wine-details-data-subcontainer">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="individual-wine-details-wine-name">
                                  {
                                    // this.props.location.state.wineIndividualData.specifications ?

                                    this.props.location.state.wineIndividualData
                                      .specifications &&
                                    (this.props.location.state
                                      .wineIndividualData.specifications
                                      .alcoholPercentage != "" ||
                                      this.props.location.state
                                        .wineIndividualData.specifications
                                        .bottledDate != "" ||
                                      this.props.location.state
                                        .wineIndividualData.specifications
                                        .cooperage != "" ||
                                      this.props.location.state
                                        .wineIndividualData.specifications
                                        .vintage != "" ||
                                      this.props.location.state
                                        .wineIndividualData.specifications
                                        .vineyard != "" ||
                                      this.props.location.state
                                        .wineIndividualData.specifications.pH !=
                                        "")
                                      ? "Specifications"
                                      : ""
                                  }
                                </span>
                                {/* <i
                                  class={
                                    this.state.specificationOpen
                                      ? "accordion-rotate-item__icon accordion-item__icon"
                                      : "accordion-item__icon"
                                  }
                                  onClick={() => {
                                    this.specificationCollapse();
                                  }}
                                  aria-hidden="true"
                                ></i> */}
                              </div>

                              <Collapse in={this.state.specificationOpen}>
                                <span>
                                  {this.props.location.state.wineIndividualData
                                    .price[0].quantity ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.price[0].quantity
                                          ? "Bottle Size: "
                                          : ""}
                                      </span>
                                      <span className="type-text">
                                        {this.props.location.state
                                          .wineIndividualData.price[0].quantity
                                          ? this.props.location.state
                                              .wineIndividualData.price[0]
                                              .quantity + "ml"
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .specifications.vineyard ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .vineyard
                                            ? "Vineyard: "
                                            : ""
                                          : ""}
                                      </span>
                                      <span className="type-text-vineyard">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .vineyard
                                            ? this.props.location.state
                                                .wineIndividualData
                                                .specifications.vineyard
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .specifications.vintage ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .vintage
                                            ? "Vintage: "
                                            : ""
                                          : ""}
                                      </span>
                                      <span className="type-text">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .vintage
                                            ? this.props.location.state
                                                .wineIndividualData
                                                .specifications.vintage
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .specifications.alcoholPercentage ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .alcoholPercentage
                                            ? "Alcohol: "
                                            : ""
                                          : ""}
                                      </span>
                                      <span className="type-text">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .alcoholPercentage
                                            ? this.props.location.state
                                                .wineIndividualData
                                                .specifications
                                                .alcoholPercentage + "%"
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .specifications.pH ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .pH
                                            ? "pH: "
                                            : ""
                                          : ""}
                                      </span>
                                      <span className="type-text">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .pH
                                            ? this.props.location.state
                                                .wineIndividualData
                                                .specifications.pH
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.props.location.state.wineIndividualData
                                    .specifications.cooperage ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .cooperage
                                            ? "Cooperage: "
                                            : ""
                                          : ""}
                                      </span>
                                      <span className="type-text-cooperage">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .cooperage
                                            ? this.props.location.state
                                                .wineIndividualData
                                                .specifications.cooperage
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .specifications.bottledDate ? (
                                    <div className="specifications-type-container">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .bottledDate
                                            ? "Bottled Date:"
                                            : ""
                                          : ""}
                                      </span>
                                      <span className="type-text">
                                        {this.props.location.state
                                          .wineIndividualData.specifications
                                          ? this.props.location.state
                                              .wineIndividualData.specifications
                                              .bottledDate
                                            ? bottledDay +
                                              " " +
                                              bottledMonth +
                                              " " +
                                              bottledYear
                                            : ""
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Collapse>
                            </div>
                          ) : (
                            ""
                          )
                        }

                        {
                          // this.props.location.state.wineIndividualData.wineProfile ?

                          this.props.location.state.wineIndividualData
                            .wineProfile &&
                          (this.props.location.state.wineIndividualData
                            .wineProfile.foodPairingNotes != "" ||
                            this.props.location.state.wineIndividualData
                              .wineProfile.productionNotes != "" ||
                            this.props.location.state.wineIndividualData
                              .wineProfile.tastingNotes != "" ||
                            this.props.location.state.wineIndividualData
                              .wineProfile.vintageNotes != "") ? (
                            <div className="card shadow individual-wine-details-data-subcontainer">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="individual-wine-details-wine-name">
                                  {
                                    this.props.location.state.wineIndividualData
                                      .wineProfile &&
                                    (this.props.location.state
                                      .wineIndividualData.wineProfile
                                      .foodPairingNotes != "" ||
                                      this.props.location.state
                                        .wineIndividualData.wineProfile
                                        .productionNotes != "" ||
                                      this.props.location.state
                                        .wineIndividualData.wineProfile
                                        .tastingNotes != "" ||
                                      this.props.location.state
                                        .wineIndividualData.wineProfile
                                        .vintageNotes != "")
                                      ? "Wine Profile"
                                      : ""

                                    // ?
                                    // 'Wine Profile' : ''
                                  }
                                </span>
                                {/* <i
                                  class={
                                    this.state.wineProfileOpen
                                      ? "accordion-rotate-item__icon accordion-item__icon"
                                      : "accordion-item__icon"
                                  }
                                  onClick={() => {
                                    this.wineProfileCollapse();
                                  }}
                                  aria-hidden="true"
                                ></i> */}
                              </div>
                              {/* { this.props.location.state.wineIndividualData.foodPairings ? 
                                        <div className="specifications-type-container1">

                                        <span className="specifications-type-title">
                                        {
                                            this.props.location.state.wineIndividualData.foodPairings ? 'Food Pairings: '
                                                : ''
                                        }
                                        </span>
                                        <span className="type-text-wine-profile">
                                        
                                            {
                                                    this.props.location.state.wineIndividualData.foodPairings ? 
                                                    this.props.location.state.wineIndividualData.foodPairings : ''
                                            }    
                                                                                        
                                        </span>
                                        </div> : '' } */}

                              <Collapse in={this.state.wineProfileOpen}>
                                <span>
                                  {this.props.location.state.wineIndividualData
                                    .wineProfile.foodPairingNotes ? (
                                    <div className="specifications-type-container1">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .foodPairingNotes
                                          ? "Food Pairing Notes: "
                                          : ""}
                                      </span>
                                      <span className="type-text-wine-profile">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .foodPairingNotes
                                          ? this.props.location.state
                                              .wineIndividualData.wineProfile
                                              .foodPairingNotes
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .wineProfile.productionNotes ? (
                                    <div className="specifications-type-container1">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .productionNotes
                                          ? "Production Notes: "
                                          : ""}
                                      </span>
                                      <span className="type-text-wine-profile">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .productionNotes
                                          ? this.props.location.state
                                              .wineIndividualData.wineProfile
                                              .productionNotes
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .wineProfile.tastingNotes ? (
                                    <div className="specifications-type-container1">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .tastingNotes
                                          ? "Tasting Notes: "
                                          : ""}
                                      </span>
                                      <span className="type-text-wine-profile">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .tastingNotes
                                          ? this.props.location.state
                                              .wineIndividualData.wineProfile
                                              .tastingNotes
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {this.props.location.state.wineIndividualData
                                    .wineProfile.vintageNotes ? (
                                    <div className="specifications-type-container1">
                                      <span className="specifications-type-title">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .vintageNotes
                                          ? "Vintage Notes: "
                                          : ""}
                                      </span>
                                      <span className="type-text-wine-profile">
                                        {this.props.location.state
                                          .wineIndividualData.wineProfile
                                          .vintageNotes
                                          ? this.props.location.state
                                              .wineIndividualData.wineProfile
                                              .vintageNotes
                                          : ""}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Collapse>
                            </div>
                          ) : (
                            ""
                          )
                        }
                      </div>
                      {/* <hr />   */}
                    </div>
                  </div>
                  {/* <div className="individual-wine-detail-footer">
                  <div className="add-to-cart-btn-container">
                    <button
                      type="button"
                      // onClick={this.buyNowClicked.bind(this)}
                      onClick={this.addToCart}
                      className="btn btn-round wine-detail-add-to-cart-btn"
                      disabled={
                        this.props.location.state.wineIndividualData.isDeleted || this.state.disableAddtoCartOnApiCall
                      }
                    >
                      {buttonText}
                    </button>
                  </div>
                </div> */}
                  {this.props.location.state.wineIndividualData
                    .wineDescription ||
                  (this.props.location.state.wineIndividualData
                    .specifications &&
                    (this.props.location.state.wineIndividualData.specifications
                      .alcoholPercentage != "" ||
                      this.props.location.state.wineIndividualData
                        .specifications.bottledDate != "" ||
                      this.props.location.state.wineIndividualData
                        .specifications.cooperage != "" ||
                      this.props.location.state.wineIndividualData
                        .specifications.vintage != "" ||
                      this.props.location.state.wineIndividualData
                        .specifications.vineyard != "" ||
                      this.props.location.state.wineIndividualData
                        .specifications.pH != "")) ||
                  (this.props.location.state.wineIndividualData.wineProfile &&
                    (this.props.location.state.wineIndividualData.wineProfile
                      .foodPairingNotes != "" ||
                      this.props.location.state.wineIndividualData.wineProfile
                        .productionNotes != "" ||
                      this.props.location.state.wineIndividualData.wineProfile
                        .tastingNotes != "" ||
                      this.props.location.state.wineIndividualData.wineProfile
                        .vintageNotes != "")) ? (
                    <div className="add-to-cart-btn-container">
                      <button
                        type="button"
                        // onClick={this.buyNowClicked.bind(this)}
                        onClick={this.addToCart}
                        className="btn btn-round wine-detail-add-to-cart-btn"
                        disabled={
                          this.props.location.state.wineIndividualData
                            .isDeleted ||
                          this.state.disableAddtoCartOnApiCall ||
                          !this.props.location.state.wineIndividualData
                            .availableForSale
                        }
                      >
                        {buttonText}
                      </button>
                    </div>
                  ) : null}
                </div>

                {!isEmbedded() && (wineryId && wineryId != 381 && wineryId != 6) &&
                    <div className="bottom-nav-induvidual-wines p-3 bg-white rounded">
                      <BottomNavigation />
                    </div>
                }

                {/* {this.state.showAddedToCartPopUp && ( */}
                {/* <AddedToCartPopUp open={this.state.showAddedToCartPopUp} /> */}
                {/* )} */}
              </div>
            </div>
          </ErrorBoundary>
        </div>
        {this.state.showAddedToCartPopUp && (
          <NewConfirmCommonPopUp
            open={this.state.showAddedToCartPopUp}
            handleYes={this.handleYes}
            handleCancel={this.handleCancel}
            headerText={this.state.headerText}
            confirmText={this.state.cartProceedToBuyText}
            cancelText={this.state.cartContinueShoppingText}
            addToCartClick={this.state.showAddedToCartPopUp}
          />
        )}
        <SignInToContinue
          signInPopUp={this.state.signInPopUp}
          addToCartAutomatically={true}
          url={this.props.location.pathname}
          where={"to Add to cart"}
          handleClose={() => this.setState({ signInPopUp: false })}
          state={this.props.location.state}
        />
      </React.Fragment>
    );
  }

  addToCart = () => {
    // GoogleAnalyticsFunctions.addToCartForWine();
    // this.setState({
    //   addToCartApiCall: true,
    //   addToCartSuccess: undefined,
    //   addToCartFail: undefined,
    //   isNavigateToChatPage: false,
    //   isNavigateToNewBrowser: false,
    //   wineUrl: "",
    //   website: "",
    // });
    // this.props.AddToCartAction.AddToCartDummyApi();
    // this.props.AddToCartAction.AddToCart(
    //   this.state.wineryId,
    //   this.state.wineId
    // );

    // this.setState({
    //   showAddedToCartPopUp: true,
    // });
    // setTimeout(() => {
    //   this.setState({
    //     showAddedToCartPopUp: false,
    //   });
    // }, 2000);
    // Writing new logic and api integration for add to cart scenario
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let wineryId = localStorage.getItem("winery_id");

    let selectedWineryData = commonFunctions.getWineryDetailsFromLocalStorage(
      wineryId,
      this.props.wineryListFromRedux
    );
    if (!signInToken && (selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.usePAShoppingCart)
      ) {
      this.setState({ signInPopUp: true });
    } else {
      if (
        selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.usePAShoppingCart
      ) {
        this.setState({
          disableAddtoCartOnApiCall: true,
        });
        this.addWineToCartAPI();
      } else if (
        selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.useWineryWebsiteForOrders
      ) {
        if (this.props.location.state.wineIndividualData.wineUrl) {
          this.onOpenWineUrlInNewTab(
            this.props.location.state.wineIndividualData.wineUrl
          );
        } else if (selectedWineryData.website) {
          this.onOpenWineUrlInNewTab(selectedWineryData.website);
        }
        // else {
        //   this.onNavigatingToChatPage();
        // }
      } else {
        if (this.props.location.state.wineIndividualData.wineUrl) {
          this.onOpenWineUrlInNewTab(
            this.props.location.state.wineIndividualData.wineUrl
          );
        } else if (selectedWineryData.website) {
          this.onOpenWineUrlInNewTab(selectedWineryData.website);
        }
      }
    }
  };

  addWineToCartAPI = () => {
    let dealData = this.props.location.state.dealData;
    let discountPrice = dealData
      ? dealData.dollarsOff
        ? dealData.dollarsOff
        : (this.props.location.state.wineIndividualData.price[0].price *
            dealData.discount) /
          100
      : "";
    let apiPostData = {
      wineId: this.props.location.state.wineIndividualData.id,
      unitPrice: dealData
        ? this.props.location.state.wineIndividualData.price[0].price -
          discountPrice
        : this.props.location.state.wineIndividualData.price[0].price,
    };
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let addToCartApiUrl =
      constants.SHOPPING_CART_API +
      "/api/v1/users/winery/" +
      wineryId +
      "/cart/addItems";
    fetch(addToCartApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(response);
          let apiErrorObj = {
            statusText: response.statusText,
            type: response.type,
            status: response.status,
            url: addToCartApiUrl,
            operation: "GET",
            status: response.status,
          };
          commonFunctions.postCaughtException(
            JSON.stringify(apiErrorObj),
            consumerData,
            wineryId
          );
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          throw Error(response.statusText);
        }
      })
      .then((respJSON) => {
        if (!respJSON.errors) {
          // console.log(respJSON, this.props.cartTotalQuantity);
          this.setState({
            showAddedToCartPopUp: true,
            disableAddtoCartOnApiCall: false,
          });
          let totalCartQuantity = this.props.cartTotalQuantity + 1;
          this.props.GetCartTotalQtyAction.UpdateCartQuantity(
            totalCartQuantity
          );
        } else {
          this.setState({
            disableAddtoCartOnApiCall: false,
          });
          // console.log(respJSON.errors);
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((error) => {
        this.setState({
          disableAddtoCartOnApiCall: false,
        });
        console.log(error);
      });
  };

  handleYes = () => {
    // console.log('Yes Click');
    GoogleAnalyticsFunctions.proceedToBuy();
    this.setState({
      showAddedToCartPopUp: false,
    });

    if (this.props.location.state.addToCartAutomatically) {
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.location.state,
        addToCartAutomatically: undefined,
      });
    }
    this.props.history.push("/shopping-cart");
  };
  handleCancel = () => {
    GoogleAnalyticsFunctions.continueShopping();
    // console.log('Cancel Click');

    this.setState({
      showAddedToCartPopUp: false,
    });
    if (this.props.location.state.addToCartAutomatically) {
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.location.state,
        addToCartAutomatically: undefined,
      });
    }
  };

  onNavigatingToChatPage = () => {
    this.props.history.push({
      pathname: "/chat" + "/" + this.state.wineryId,
      state: {
        openChatForWinePurchase: true,
        wineIndividualData: this.props.location.state.wineIndividualData,
      },
    });
  };

  onOpenWineUrlInNewTab = (url) => {
    this.setState({
      isNavigateToNewBrowser: false,
    });
    if (!url.includes("http://") && !url.includes("https://")) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  };
}

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    addToCartSuccess: state.AddToCartReducer.addToCartSuccess,
    addToCartFail: state.AddToCartReducer.addToCartFail,
    cartTotalQuantity: state.GetCartTotalQtyReducer.cartTotalQuantity,
    wineryListFromRedux: state.WineryReducer.wineryList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AddToCartAction: bindActionCreators(AddToCartAction, dispatch),
    GetCartTotalQtyAction: bindActionCreators(GetCartTotalQtyAction, dispatch),
    setCurrentWineryDetails: bindActionCreators(
      setCurrentWineryDetails,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualWineDetails);
