import * as types from "./action-types";
import * as constants from "../../constants/constants";
import Axios from "axios";

export const UserSignIn = (email, password) => {
  const data = {
    username: email,
    password: password,
  };

  return (dispatch) => {
    fetch(constants.IDENTITY_API + "/auth/v1/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((respJSON) => {
        console.log(respJSON);
        dispatch({ type: types.USER_SIGNIN_SUCCEEDED, payload: respJSON });
      })
      .catch((error) => {
        dispatch({ type: types.RESET_APP });
        dispatch({ type: types.USER_SIGNIN_FAILED, payload: error });
      });
  };
};

export const FaceBookSignIn = (accessToken, userID) => {
  const data = {
    accessToken,
    userID,
  };

  return async (dispatch) => {
    try {
      const output = await Axios.post(
        constants.IDENTITY_API + "/auth/v1/facebook",
        data
      );
      dispatch({
        type: types.NEW_USER_SIGN_IN,
        payload: output.data,
      });
    } catch ({ response, ...error }) {
      if (response.status === 401) {
        let errObj =
          response.data.message.length > 0
            ? { message: response.data.message }
            : { message: "Oops something went wrong" };
        dispatch({ type: types.RESET_APP });
        dispatch({ type: types.USER_SIGNIN_FAILED, payload: errObj });
      } else if (response.status === 409) {
        dispatch({
          type: types.USER_SIGNIN_SUCCEEDED,
          payload: response.data,
        });
      } else if (response.status === 422) {
        let errObj =
          response.data.errors.length > 0
            ? { message: response.data.errors[0].message }
            : { message: "Oops something went wrong" };
        dispatch({ type: types.RESET_APP });
        dispatch({ type: types.USER_SIGNIN_FAILED, payload: errObj });
      } else {
        dispatch({ type: types.RESET_APP });
        dispatch({
          type: types.USER_SIGNIN_FAILED,
          payload: { message: "Oops something went wrong" },
        });
      }
    }
  };
};

export const GoogleSignIn = (idToken, accessToken, googleId) => {
  const data = {
    idToken,
    accessToken,
    googleId,
  };

  return async (dispatch) => {
    try {
      const output = await Axios.post(
        constants.IDENTITY_API + "/auth/v1/google",
        data
      );
      dispatch({
        type: types.NEW_USER_SIGN_IN,
        payload: output.data,
      });
    } catch ({ response, ...error }) {
      if (response.status === 401) {
        let errObj =
          response.data.message.length > 0
            ? { message: response.data.message }
            : { message: "Oops something went wrong" };
        dispatch({ type: types.RESET_APP });
        dispatch({ type: types.USER_SIGNIN_FAILED, payload: errObj });
      } else if (response.status === 409) {
        dispatch({
          type: types.USER_SIGNIN_SUCCEEDED,
          payload: response.data,
        });
      } else if (response.status === 422) {
        let errObj =
          response.data.errors.length > 0
            ? { message: response.data.errors[0].message }
            : { message: "Oops something went wrong" };
        dispatch({ type: types.RESET_APP });
        dispatch({ type: types.USER_SIGNIN_FAILED, payload: errObj });
      } else {
        dispatch({ type: types.RESET_APP });
        dispatch({
          type: types.USER_SIGNIN_FAILED,
          payload: { message: "Oops something went wrong" },
        });
      }
    }
  };
};

// Calling Dummy method before calling sign in api
export const SingInDummyCall = () => {
  return (dispatch) => {
    // dispatch({ type: types.RESET_APP });
    dispatch({ type: types.USER_SIGNIN_DUMMY, payload: undefined });
  };
};

export const toggleShowGetStarted = () => {
  return (dispatch) => {
    dispatch({ type: types.TOGGLE_SHOW_GET_STARTED });
  };
};

export const toggleViewedShowGetStarted = () => {
  return (dispatch) => {
    dispatch({ type: types.VIEWED_SHOW_GET_STARTED });
  };
};

export const closeShowGetStarted = () => {
  return (dispatch) => {
    dispatch({ type: types.CLOSE_SHOW_GET_STARTED });
  };
};
