import { useHistory } from "react-router-dom";
import styles from "./header-burger.module.scss";
import React, { useState } from "react";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import leftArrowIcon from "../../../assets/images/left-arrow-white.svg";
import logo from "../../../assets/images/search-wine-pa-logo.svg";
import { isEmbedded } from '../../constants/commonFunctions';

export default function HeaderBurger({ title, hideBackInEmbedded = false }) {
  const history = useHistory();
  const openHomePage = () => {
    history.push("/discover");
  };
  // const [visible, setVisible] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.burger}>
          <img
            draggable="false"
            alt="Go Back"
            className="cursor-pointer"
            onClick={() => history.goBack()}
            src={leftArrowIcon}
          />
        </div>
        <div className="text-center">
          <span className={`${styles.headerText} unset-margin-left`}>
            {title}
          </span>
        </div>
        <img
          draggable="false"
          alt="PairAnything"
          className={`${styles.logo} ml-0`}
          src={logo}
          onClick={() => {
            if ((!hideBackInEmbedded || !isEmbedded())) {
              openHomePage();
            }
          }}
        />
      </div>
    </div>
  );
}
