import React, { Component } from "react";
import "./SelectWineryCard.scss";
import { Redirect } from "react-router-dom";
import wineBottle from "../../../assets/images/wine-bottle.png";
import DefaultLogo from "../../../assets/images/DefaultLogo.png";
import { withRouter } from "react-router-dom";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { convertToCamelCase } from "../../constants/commonFunctions";
import { setCurrentWineryDetails } from "../../redux/actions/WineryAction";
import { connect } from "react-redux";

// wine_bottle_placeholder1 , wine_bottle_placeholder

class SelectWineryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winerySelectedStatus: false,
      loaded: false,
      winerySelectionError: "",
    };
  }

  showImage = () => {
    this.setState({
      loaded: true,
    });
  };

  componentDidMount = () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  gotoPrevScreen = () => {};

  renderSelectWineryCards = () => {
    // let result = [];
    if (this.props.wineryData != undefined || this.props.wineryData != null) {
      return (
        <div
          className="select-winery-card-details"
          onClick={this.winerySelected.bind(this)}
        >
          <div className="select-winery-card-bottle-logo-container">
            <img
              onLoad={this.showImage}
              alt="Available wines logo"
              className={
                this.props.wineryData != null &&
                this.props.wineryData != undefined &&
                this.props.wineryData.logo != "" &&
                this.props.wineryData.logo != null
                  ? "select-winery-card-bottle-logo-from-api"
                  : "select-winery-card-bottle-logo"
              }
              src={
                this.props.wineryData.logo != null &&
                this.props.wineryData.logo != "" &&
                this.props.wineryData != undefined
                  ? this.state.loaded
                    ? this.props.wineryData.logo
                    : DefaultLogo
                  : DefaultLogo
              }
            />
          </div>

          <div className="select-winery-card-name-container">
            <div className="select-winery-card-subcontainer">
              <span className="select-winery-card-name">
                {this.props.wineryData.displayName != null &&
                this.props.wineryData.displayName != undefined
                  ? this.props.wineryData.displayName
                  : "Wine Name"}
                {/* Winery name */}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return [];
    }
  };

  winerySelected = () => {
    if (this.props.wineryData.wineryType == "Foodom") {
      GoogleAnalyticsFunctions.clickedFoodom();
      window.open(this.props.wineryData.wineryUrl, "_blank");
    } else if (this.props.wineryData.wineryType == "Vinified") {
      GoogleAnalyticsFunctions.clickedVinified();
      window.open(this.props.wineryData.wineryUrl, "_blank");
    } else {
      GoogleAnalyticsFunctions.clickedEnabledWinery(
        this.props.wineryData.displayName
      );
      localStorage.setItem(
        "winery_id",
        JSON.stringify(this.props.wineryData.wineryId)
      );
      localStorage.setItem("winery_name", this.props.wineryData.displayName);
      this.setState({
        winerySelectedStatus: true,
      });
    }
  };

  componentDidUpdate(prevProps, prevStates) {
    if (
      this.state.winerySelectedStatus &&
      this.props.wineryData != null &&
      this.props.wineryData != undefined
    ) {
      this.props.dispatch(setCurrentWineryDetails(this.props.wineryData));
      if (this.props.navigateTo) {
        if (this.props.navigateTo === "enjoy") {
          this.props.history.push({
            pathname: "/search-wine" + "/" + this.props.wineryData.wineryId,
          });
        } else if (this.props.navigateTo === "shop") {
          this.props.history.push(
            "/" +
              convertToCamelCase(this.props.wineryData.displayName) +
              "/wines"
          );
        }
      } else {
        this.props.history.push(
          "/" + convertToCamelCase(this.props.wineryData.displayName) + "/wines"
        );
        localStorage.setItem(
          "winery_id",
          JSON.stringify(this.props.wineryData.wineryId)
        );
        localStorage.setItem("winery_name", this.props.wineryData.displayName);
        // this.props.history.push({
        //     pathname: '/search-wine' + '/' + this.props.wineryData.wineryId
        // });
      }
    }
  }

  render() {
    return this.renderSelectWineryCards();
  }
}
SelectWineryCard = withRouter(SelectWineryCard);
export default connect(null, null)(SelectWineryCard);
