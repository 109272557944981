import React, { Component } from 'react';
import { Redirect } from 'react-router';
import './WineNewsFeed.scss';

import chatBtn from '../../../assets/images/chat-selected-icon.svg';  
import chatWithBackgroundBtn from '../../../assets/images/chat-with-circle.svg'

import BottomNavigation from '../../components/BottomNavigation/BottomNavigation';

import * as constants from '../../constants/constants'

import WineEducationCard from '../../components/WineEducationCard/WineEducationCard';
import WineNewsFeedCard from '../../components/WineNewsFeedCard/WineNewsFeedCard';

import documentImage from '../../../assets/images/WineNewsFeed/document.svg'
import wineEventImage from '../../../assets/images/WineNewsFeed/wine-event.png'

import newsFeedImage1 from '../../../assets/images/WineNewsFeed/newsfeed-image1.jpg'
import newsFeedImage2 from '../../../assets/images/WineNewsFeed/newsfeed-image2.jpg'



let wineNewsFeedArray = [1,2,3];
class WineNewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {

            chatBtnClickedStatus: false,
            wineFeedData: [{
                title: '',
                image: '',
                content: ''
            }]
        }
        

    }

   
  
      

    componentDidMount = () => {
          
        let initialObject = [];
        initialObject.push( {
            title: 'HISTORY OF ZINFANDEL AND AMADOR COUNTY!',
            image: newsFeedImage1 ,
            content: 'You may or may not know how significant the Zinfandel varietal is in Amador County, but with this month being Zinfandel month for Scott Harvey Wines, Scott took some time to elaborate on the various plantings and noteworthy events of this grape in our region.'
        } , {
            title: 'FIVE WINE MYTHS: DEBUNKED!',
            image: newsFeedImage1 ,
            content: 'There are many common misconceptions about wine that often become unquestioned beliefs. We’re getting to the bottom of five of these fallacies once and for all and addressing some of the most common myths we’ve heard in our 40+ years in the wine industry.'
        } , {
            title: "THREE OF OUR FAVORITE HOLIDAY PAIRINGS",
            image: newsFeedImage2 ,
            content: "I love the saying, “a magnum bottle of wine is the perfect size for two people if one of them isn’t drinking.” While that may be a bit of an exaggeration, it’s true that magnum bottles are excellent choices for enjoying wine as a group."
        } );
        

        this.setState({
            wineFeedData: initialObject
        })

        // this.displayNotificationCount();
      }

      displayNotificationCount = () => {

     
        let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );
        let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );
        let roomId = consumerData.id + "_" + wineryId;
        let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
        let bearer = 'Bearer ' + signInToken;

        fetch(constants.CHAT_API + '/api/v1/chat/getChatRoomById/' + roomId , {  // updated new URL
    
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization" : bearer
            }
            })
            .then(res => {
                if (res.ok) {
                
                return res.json();
                
                } else {          
                throw Error(res.statusText);
                }
            })
            .then(json => {       
            })
            .catch(error => console.error(error));
    
    
      }
    

      
      chatBtnClicked = () => {
        this.setState({
            chatBtnClickedStatus: true
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.chatBtnClickedStatus) {
            let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );
            //    return <Redirect to={{
            //        pathname: '/chat' + '/' + wineryId,
                  
            //    }}/>;
            this.props.history.push({
                pathname: '/chat' + '/' + wineryId
               });
           }
    }
   
   render() {
        
        return (
             <div className="wine-news-feed-main-container">
                
                <div className = "wine-news-feed-header">
                  <div className="wine-news-feed-header-container">
                     <span className = "wine-news-feed-text" >Insider wine news and events</span>
                  </div> 
                  <div className="wine-news-feed-winery-name">
                        <button className="winery-name-btn btn btn-border btn-round">
                            
                        </button>
                  </div>
                </div>

           
                <div className = "wine-news-feed-container">                
                 
                    { 
                        this.state.wineFeedData.map( (el,index) => 

                        <WineNewsFeedCard wineFeedData = { this.state.wineFeedData[index] } 
                         
                        />
                        )
                    }
                </div>

                {/* Chat  */}
                {/* <div className = "chat-btn-container">
                    <img className = "shadow chat-icon" src = { chatWithBackgroundBtn } onClick = {this.chatBtnClicked}/>
                </div> */}
         
              <div className = "bottom-navigation shadow p-3 bg-white rounded">
                   
                   <BottomNavigation wineNewsFeedActive = "true" />                 
                     
                </div>
          
               
            </div>
         );
    }
}


export default WineNewsFeed;
