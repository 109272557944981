import React, { Component } from "react";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import * as constants from "../../constants/constants";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import ShoppingCartHeader from "../../components/ShoppingCartHeader/ShoppingCartHeader";
import "./ShoppingCartShippingAddress.scss";
import redAddIcon from "../../../assets/images/red-add-icon.svg";
import locationIcon from "../../../assets/images/ShippingAddress/address.svg";
import AddressSelectionComponent from "../../components/AddressSelectionComponent/AddressSelectionComponent";
import ShippingAddressFormComponent from "../../components/ShippingAddressFormComponent/ShippingAddressFormComponent";
import { ToastContainer, toast } from "react-toastify";
import NewConfirmCommonPopUp from "../../components/NewConfirmCommonPopup/NewConfirmCommonPopup";
import * as commonFunctions from "../../constants/commonFunctions";
import InfoIcon from "@material-ui/icons/Info";
import { isMobile } from "react-device-detect";
toast.configure();

let consumerData;
let wineryId;

class ShoppingCartShippingAddress extends Component {
  constructor(props) {
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    wineryId = JSON.parse(localStorage.getItem("winery_id"));
    super(props);
    this.state = {
      addressDetails: [],
      showAddressFillingForm: false,
      showDeleteConfirmPopUp: false,
      shippingAddressFormData: {
        formErrors: {
          addressFormError: "",
          zipcodeformError: "",
          addressLine1formError: "",
          cityformError: "",
          stateformError: "",
          saveAsformError: "",
        },
        shippingAddressForm: {
          id: "",
          address1: "",
          address2: "",
          city: "",
          stateId: "",
          zipcode: "",
          name: "",
          isPrimary: false,
        },
      },
      removeAddressConfirmText: "Delete",
      removeAddressCancelText: "Cancel",
      deleteHeaderText: "Are you sure you want to delete this address?",
      removeAddressId: "",
      defaultAddressSelectError: "",
      getAddressAPICallDone: false,
      selectedAddressValidForCurrentWinery: false,
    };
  }

  componentDidMount() {
    this.onGetShippingAddressDetails();
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  }

  onCheckAddressHavingSelectedAddress = () => {
    if (this.state.addressDetails.length > 0) {
      let selectedAddress = this.state.addressDetails.find(
        (x) => x.isPrimary === true
      );
      // console.log(selectedAddress);
      if (selectedAddress) {
        this.cartDetailsApiCall(selectedAddress.id); // To check whether selected has availability to shipping for the current winery.
      }
    } else {
      this.setState({
        addressdefaultAddressSelectErrorError: "",
        selectedAddressValidForCurrentWinery: false,
      });
    }
  };

  getStateName = (addressId) => {
    let selectedAddress = this.state.addressDetails.find(
      (x) => x.isPrimary === true
    );
    return selectedAddress.stateName;
  };
  getZipcode = (addressId) => {
    let selectedAddress = this.state.addressDetails.find(
      (x) => x.isPrimary === true
    );
    return selectedAddress.zipcode;
  };
  cartDetailsApiCall = (addressId) => {
    // console.log('Selected Address Id', addressId);
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    try {
      let getCartCheckoutApiUrl =
        constants.SHOPPING_CART_API +
        "/api/v1/users/winery/" +
        wineryId +
        "/cart/checkout?addressId=" +
        addressId;
      fetch(getCartCheckoutApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getCartCheckoutApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.setState({
              defaultAddressSelectError:
                "Something went wrong. Please try again later.",
              selectedAddressValidForCurrentWinery: false,
            });
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          // console.log(json);
          if (
            !json.paymentDetails.isAvailableForShipping &&
            !json.paymentDetails.isExcludedZipCode
          ) {
            this.setState({
              defaultAddressSelectError:
                "Sorry, we don't ship to " +
                " " +
                this.getStateName(addressId) +
                ".",
              selectedAddressValidForCurrentWinery: false,
            });
          } else if (
            !json.paymentDetails.isAvailableForShipping &&
            json.paymentDetails.isExcludedZipCode
          ) {
            this.setState({
              defaultAddressSelectError:
                "Sorry, we don't ship to " +
                " " +
                this.getZipcode(addressId) +
                ".",
              selectedAddressValidForCurrentWinery: false,
            });
          } else {
            this.setState({
              defaultAddressSelectError: "",
              selectedAddressValidForCurrentWinery: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            defaultAddressSelectError:
              "Something went wrong. Please try again later.",
            selectedAddressValidForCurrentWinery: false,
          });
          console.error(error);
        });
    } catch (e) {
      this.setState({
        defaultAddressSelectError:
          "Something went wrong. Please try again later.",
        selectedAddressValidForCurrentWinery: false,
      });
      // console.log(e);
    }
  };

  onGetShippingAddressDetails = () => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    try {
      let getUserShippingAddressDetailsApiUrl =
        constants.IDENTITY_API + "/api/v1/user/address";
      fetch(getUserShippingAddressDetailsApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getUserShippingAddressDetailsApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.setState({
              getAddressAPICallDone: true,
            });
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          // console.log(json);
          this.setState({
            getAddressAPICallDone: true,
            addressDetails: json.rows,
          });
          this.onCheckAddressHavingSelectedAddress();
        })
        .catch((error) => {
          this.setState({
            getAddressAPICallDone: true,
          });
          console.error(error);
        });
    } catch (e) {
      this.setState({
        getAddressAPICallDone: true,
      });
      // console.log(e);
    }
  };

  render() {
    return (
      <div
        className="recommendations-main-container font-family new-shpng-cart-pages-dsktp-main-container-changes"
        id="parentShoppingCartDiv"
      >
        <div className="success-main-container ">
          <div className=" success-inner-container  new-discover-inner-container">
            <ShoppingCartHeader
              goBackFunc={() => {
                if (this.state.showAddressFillingForm)
                  this.setState({ showAddressFillingForm: false });
                else this.props.history.goBack();
              }}
            />
            <div
              className={isMobile ? null : "desktop-div-scroll "}
              style={{ backgroundColor: "#f2f2f2" }}
            >
              {this.state.showAddressFillingForm ? (
                // <div className="shipping-address-container">
                <ShippingAddressFormComponent
                  fromShippingAddressPage={false}
                  fromShoppingCartShippingAddressPage={true}
                  goBackFromAddressForm={this.onCloseShippingAddressForm}
                  addressDetails={
                    this.state.shippingAddressFormData.shippingAddressForm
                  }
                  // onChangeAddressForm={this.onChangeAddressForm}
                  // onShippingAddressFormSave={this.onShippingAddressSavingForm}
                />
              ) : (
                <React.Fragment>
                  {this.state.getAddressAPICallDone && (
                    <div className="">
                      <div className="cart-stepper-header stepper-new-margin ">
                        <div className="">
                          {/* <span className="dot-cart-stepper-select completed-cart-stepper"></span> */}
                          <span className="cart-name-new-css mr-3">Cart</span>
                        </div>{" "}
                        <div className="separator-stepper-select">
                          <span className="dot-cart-stepper-select"></span>
                          <span className="ml-3 mr-3">Address</span>
                        </div>{" "}
                        <div className="separator-stepper final-stepper-css">
                          <span className="dot-cart-non-select-stepper"></span>
                          <span className="ml-3 stepper-label-non-select">
                            Payment
                          </span>
                        </div>{" "}
                      </div>
                      {/* <div className="shipping-address-header-label">
                    <span>Select Shipping Address</span>
                  </div> */}
                      <div className="shipping-addresses">
                        <div>
                          <span>Select Shipping Address</span>
                        </div>
                        <div
                          className="add-new-address ptr-clss"
                          onClick={this.onClickAddNewAddress}
                        >
                          <img
                            alt="Add New Address"
                            className="add-new-icon"
                            src={redAddIcon}
                          />
                          <span>Add New</span>
                        </div>
                      </div>
                      {/* <div className='mt-4 ml-3 mr-3 mb-4'>
                    <button className="btn btn-round modify-add-address-btn ptr-clss" onClick={this.onClickAddNewAddress} >Add Address</button>
                  </div> */}
                      {/* <div className='shpng-adrs-div ml-3 mr-3'>
                    <label className='shpng-adrs-label mt-3'>Select Address</label>
                  </div> */}
                      <div className="available-wines-container row">
                        {this.state.addressDetails.length > 0 ? (
                          <ErrorBoundary>
                            {this.state.addressDetails.map((el, index) => (
                              <AddressSelectionComponent
                                addressData={el}
                                key={index}
                                onAddressSelect={this.onSelectingAddress}
                                onAddressEdit={this.onAddressEdit}
                                onAddressDelete={this.onAddressDelete}
                                selectedCard={el.isPrimary}
                              />
                            ))}
                          </ErrorBoundary>
                        ) : (
                          <div className="empty-data mt-4">
                            <span>No address has been added yet.</span>
                          </div>
                        )}
                        {/* {this.state.defaultAddressSelectError && (<div className="form-error-shipping-address-container mt-3">
                      <span className="form-error-shipping-address">
                        {this.state.defaultAddressSelectError}
                      </span>
                    </div>)} */}
                        {this.state.defaultAddressSelectError && (
                          <div className="address-select-error-div">
                            {/* <div className="">
                        <div className=""> */}
                            {/* <InfoIcon style={{ fontSize: 20 }} /> */}
                            <span className="ml-2">
                              {this.state.defaultAddressSelectError}
                            </span>
                            {/* </div>
                      </div> */}
                          </div>
                        )}
                        <div className="continue-button-container mb-2">
                          <button
                            type="button"
                            onClick={this.onClickConfirm}
                            disabled={
                              !this.state.selectedAddressValidForCurrentWinery
                            }
                            className={
                              this.state.selectedAddressValidForCurrentWinery
                                ? "btn btn-round continue-button"
                                : "btn btn-round continue-button disabled-btn-css"
                            }
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                      {this.state.showDeleteConfirmPopUp && (
                        <NewConfirmCommonPopUp
                          open={this.state.showDeleteConfirmPopUp}
                          handleYes={this.handleYes}
                          handleCancel={this.handleCancel}
                          headerText={this.state.deleteHeaderText}
                          confirmText={this.state.removeAddressConfirmText}
                          cancelText={this.state.removeAddressCancelText}
                        />
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleYes = () => {
    // console.log('Yes Click');
    this.onDeleteAddressAPICall();
  };
  handleCancel = () => {
    // console.log('Cancel Click');
    this.setState({
      removeAddressId: null,
      showDeleteConfirmPopUp: false,
    });
  };

  onSelectingAddress = (addressData) => {
    GoogleAnalyticsFunctions.onSelectDefaultShippingAddress();
    this.setState({
      defaultAddressSelectError: "",
    });
    const addressDetails = [...this.state.addressDetails];
    addressDetails.forEach((element, i) => {
      addressDetails[i] = { ...element };
      if (element.id === addressData.id) {
        addressDetails[i].isPrimary = true; // make selected address isPrimary true
        this.cartDetailsApiCall(element.id); // To check selected address has shipping for current selected winery
      } else {
        addressDetails[i].isPrimary = false; // make selected address isPrimary false for other addresses
      }
    });
    this.setState({ addressDetails });
  };

  onAddressEdit = (e, id) => {
    GoogleAnalyticsFunctions.onEditShippingAddress();
    e.stopPropagation();
    const addressData = this.state.addressDetails.find((x) => x.id === id);
    this.setState({
      shippingAddressFormData: {
        shippingAddressForm: {
          id: addressData.id ? addressData.id : "",
          address1: addressData.addressLine1 ? addressData.addressLine1 : "",
          address2: addressData.addressLine2 ? addressData.addressLine2 : "",
          city: addressData.city ? addressData.city : "",
          stateId: addressData.stateId ? addressData.stateId : "",
          zipcode: addressData.zipcode ? addressData.zipcode : "",
          name: addressData.name ? addressData.name : "",
          isPrimary: addressData.isPrimary ? addressData.isPrimary : false,
        },
      },
      showAddressFillingForm: true,
    });
  };

  onAddressDelete = (e, id) => {
    GoogleAnalyticsFunctions.onDeleteShippingAddress();
    e.stopPropagation();
    // console.log('Address Delete Id', id);
    this.setState({
      removeAddressId: id,
      showDeleteConfirmPopUp: true,
    });
  };

  onDeleteAddressAPICall = () => {
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let deleteAddressApiUrl =
        constants.IDENTITY_API +
        "/api/v1/user/address/" +
        this.state.removeAddressId;
      fetch(deleteAddressApiUrl, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: deleteAddressApiUrl,
              operation: "DELETE",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            // toast.info("Something went wrong. Please try again later.", {
            //   position: toast.POSITION.BOTTOM_CENTER,
            // });
            // Remove address from state
            this.setState({
              removeAddressId: null,
              showDeleteConfirmPopUp: false,
            });
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            let consumerData = JSON.parse(localStorage.getItem("user_data"));
            let consumerId = consumerData.id;
            let signInToken = JSON.parse(localStorage.getItem("signin_token"));
            let bearer = "Bearer " + signInToken;
            this.onGetShippingAddressDetails(
              consumerData,
              consumerId,
              signInToken,
              bearer
            );
            this.setState({
              removeAddressId: null,
              showDeleteConfirmPopUp: false,
            });
          } else {
            this.setState({
              removeAddressId: null,
              showDeleteConfirmPopUp: false,
            });
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            // console.log(respJSON.errors);
          }
        })
        .catch((error) => {
          this.setState({
            removeAddressId: null,
            showDeleteConfirmPopUp: false,
          });
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          // console.log(error);
        });
    } catch (e) {
      this.setState({
        removeAddressId: null,
        showDeleteConfirmPopUp: false,
      });
      toast.info("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      // console.log(e);
    }
  };

  onClickAddNewAddress = () => {
    this.setState({
      shippingAddressFormData: {
        shippingAddressForm: {
          id: "",
          address1: "",
          address2: "",
          city: "",
          stateId: "",
          zipcode: "",
          name: "",
          isPrimary: false,
        },
      },
      showAddressFillingForm: true,
    });
  };

  onCloseShippingAddressForm = () => {
    this.setState({
      showAddressFillingForm: false,
      defaultAddressSelectError: "",
      selectedAddressValidForCurrentWinery: false,
    });
    this.onGetShippingAddressDetails();
  };

  onClickConfirm = () => {
    GoogleAnalyticsFunctions.onSavePrimaryAddress();
    // Checking if any one of the address is selected or not
    let isPrimaryFalseCount = 0;
    if (this.state.addressDetails.length > 0) {
      this.state.addressDetails.forEach((element) => {
        if (element.isPrimary === false) {
          isPrimaryFalseCount++;
        }
      });
      if (isPrimaryFalseCount === this.state.addressDetails.length) {
        this.setState({
          defaultAddressSelectError:
            "* Please select primary address for shipping.",
        });
      } else {
        let selectedPrimaryAddress = this.state.addressDetails.find(
          (x) => x.isPrimary === true
        );
        let updateObj = {
          isPrimary: true,
        };
        GoogleAnalyticsFunctions.clickedUpdatePrimaryAddress();
        this.updateAddressDefaultSelection(
          updateObj,
          selectedPrimaryAddress.id
        );
      }
    }
  };

  updateAddressDefaultSelection = (updateObj, addressId) => {
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let updateAddressApiUrl =
        constants.IDENTITY_API + "/api/v1/user/address/" + addressId;
      fetch(updateAddressApiUrl, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(updateObj),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: updateAddressApiUrl,
              operation: "PUT",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            this.props.history.goBack();
          } else {
            // console.log(respJSON.errors);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (e) {
      // console.log(e);
    }
  };
}
export default ShoppingCartShippingAddress;
