import React, { useState, useEffect } from "react";
import axios from "axios";
import differenceInYears from "date-fns/differenceInYears";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as signoutAction from "../../redux/actions/Signout";
import SingUpPasswordSuccess from "../SingUpPasswordSuccess/SingUpPasswordSuccess";

import signUpSuccesWineImage from "../../../assets/images/SignUp/wines-signup-image.svg";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./SignUp.scss";
import logo from "../../../assets/images/splash.png";
import EmailSignupForm from "./EmailSignupForm";
import * as CommonFunctions from "../../constants/commonFunctions";

import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import * as Constants from "../../constants/constants";
import FaceBookButton from "../../components/FacebookButton";
import GoogleButton from "../../components/GoogleButton";

toast.configure();

const SignUp = ({ ...props }) => {
  const history = useHistory();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    month: "",
    day: "",
    year: "",
  });
  const [output, setOutput] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showTermsAndConds, setShowTermsAndConds] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [ticked, setTicked] = useState(false);

  const [errors, setErrors] = useState({
    commonError: false,
    commonErrorMsg: "",
    firstNameError: false,
    firstNameErrorMsg: "First Name is Required",
    lastNameError: false,
    lastNameErrorMsg: "Last Name is Required",
    emailError: false,
    emailErrorMsg: "email is Required",
    passwordError: false,
    passwordErrorMsg: "password is Required",
    confirmPasswordError: false,
    confirmPasswordErrorMsg: "confirmPassword is Required",
    dobError: false,
    dobErrorMsg: "",
  });

  const { month, day, year, dateOfBirth } = state;
  const { dobError, dobErrorMsg } = errors;

  // for Tick
  useEffect(() => {
    if (ticked && month && day && year) {
      if (validateAge()) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  }, [ticked]);

  // For GA
  useEffect(() => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  }, []);

  // for dob
  useEffect(() => {
    if (month && day && year) {
      if (validateAge() && ticked) {
        if (ticked) {
          setDisableSubmit(false);
        } else {
          setDisableSubmit(true);
        }
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  }, [day, year, month]);

  const handleChange = (event) => {
    let { value, name } = event.target;
    if (name == "day") {
      if (value.length > 2) value = value.slice(0, 2);
    }
    if (name == "year") {
      if (value.length > 4) value = value.slice(0, 4);
    }
    setState({ ...state, [name]: value });
    setErrors({ ...errors, dobError: false });
  };

  const getStarted = () => {
    props.signoutAction.ResetApp();
    if (output.user && output.user.pairanything_status.name === "active") {
      window.localStorage.clear();
      window.localStorage.setItem("signin_token", JSON.stringify(output.token));
      window.localStorage.setItem("user_data", JSON.stringify(output.user));
      window.localStorage.setItem("user_id", JSON.stringify(output.user.id));
      window.localStorage.setItem("winery_id", JSON.stringify(output.wineryId));
      window.localStorage.setItem("ageConfirmed", true);
      window.localStorage.setItem(
        "reward_points",
        JSON.stringify(output.rewardPoints)
      );
      window.localStorage.setItem(
        "winery_list",
        JSON.stringify(output.user.wineryData)
      );

      let signinWineryUserStatus = "";
      let disabledWineryCount = 0;
      for (let i = 0; i < output.user.wineryData.length; ++i) {
        if (output.user.wineryData[i].wineryUserStatus == 3) {
          disabledWineryCount++;
        }
      }
      if (output.user.wineryData.length == 1) {
        window.localStorage.setItem(
          "winery_name",
          output.user.wineryData[0].displayName
        );
        window.localStorage.setItem(
          "winery_id",
          JSON.stringify(output.user.wineryData[0].wineryId)
        );
      }
      if (
        output.user.wineryData.length == 1 &&
        output.user.wineryData[0].wineryUserStatus == 2
      ) {
        signinWineryUserStatus = "single winery-enabled";
      } else if (
        output.user.wineryData.length == 1 &&
        output.user.wineryData[0].wineryUserStatus == 3
      ) {
        // user is part of 1 winery and is disabled in that
        window.localStorage.setItem("user_winery_status", "disabled");
        signinWineryUserStatus = "single winery-disabled";
        // return {
        //   inValidMessageFromResponse: 'Your winery has disabled you. Please contact the winery admin.'
        // }
      } else if (disabledWineryCount == output.user.wineryData.length) {
        // user is disabled in all wineries which he/she is a part of
        window.localStorage.setItem("user_winery_status", "disabled");
        signinWineryUserStatus = "multiple winery-disabled";
      }

      if (signinWineryUserStatus == "single winery-enabled") {
        //user is a member of one winery and that wineryuser status is enabled
        let wineryId = JSON.parse(window.localStorage.getItem("winery_id"));
        // let url = "/search-wine" + "/" + wineryId;
        let url =
          "/" +
          CommonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          ) +
          "/pairings";
        history.push(url);
      } else if (signinWineryUserStatus == "single winery-disabled") {
        //user is a member of one winery and that winery is disabled
        history.push("/profile");
      } else if (signinWineryUserStatus == "multiple winery-disabled") {
        history.push("/profile");
      } else {
        history.push("/wineries");
      }
    }
  };

  const validateAge = () => {
    if (!month || !day || !year) {
      setErrors({
        ...errors,
        dobError: true,
        dobErrorMsg: "Date of Birth Cannot be Empty",
      });
      return false;
    }
    const DOB = new Date(`${year}-${month}-${day}`);

    const currDate = new Date();
    const diff = differenceInYears(currDate, new Date(DOB));
    const validDate = CommonFunctions.isValidDate(DOB);
    if (!validDate) {
      setErrors({
        dobError: true,
        dobErrorMsg: "Invalid Date",
      });
      return false;
    }
    if (diff < 21) {
      setErrors({
        dobError: true,
        dobErrorMsg:
          "You need to be above 21+ or older to log in to our application.",
      });
      return false;
    }
    setErrors({ dobError: false, dobErrorMsg: "" });
    const birthDay = format(new Date(DOB), "yyyy-MM-dd");
    setState({
      ...state,
      dateOfBirth: birthDay,
    });
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateAge()) {
      if (ticked) {
        const birthDay = `${year}-${month}-${day}`;
        setState({
          ...state,
          dateOfBirth: birthDay,
        });
        setDisableSubmit(false);
      }
    }
    return;
  };

  const googleResponse = async (response) => {
    const { tokenId: idToken, accessToken, googleId } = response;
    try {
      const { data: googleData } = await axios({
        url: `${Constants.IDENTITY_API}/auth/v1/google`,
        method: "post",
        data: {
          idToken,
          accessToken,
          googleId,
        },
      });
      GoogleAnalyticsFunctions.clickedGoogleSignIn();
      setOutput(googleData);
      setShowSuccess(true);
    } catch ({ response }) {
      if (response) {
        if (response.status === 404 || response.status === 500) {
          toast.info("Please try again after a while.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          let errObj = { ...errors };
          response.data.errors.map(({ message, param }, index) => {
            if (param) {
              if (message === "User already Exists") {
                toast.info(
                  "User already exists for this email. Please sign in to continue.",
                  {
                    position: toast.POSITION.BOTTOM_CENTER,
                  }
                );
                history.push("/sign-in");
              } else if (message === "Email is Required") {
                toast.info(message, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
              const error = param + "Error";
              const errorMsg = param + "ErrorMsg";
              errObj = { ...errObj, [error]: true, [errorMsg]: message };
            }
            setErrors({ ...errObj });
          });
        }
      }
    }
  };

  const facebookResponse = async (response) => {
    const { accessToken, userID } = response;
    try {
      const { data: fbData } = await axios({
        url: `${Constants.IDENTITY_API}/auth/v1/facebook`,
        method: "post",
        data: {
          accessToken,
          userID,
        },
      });
      GoogleAnalyticsFunctions.clickedFacebookSignUp();
      setOutput(fbData);
      setShowSuccess(true);
    } catch ({ response, ...error }) {
      if (response) {
        if (response.status === 404 || response.status === 500) {
          toast.info("Please try again after a while.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          let errObj = { ...error };
          response.data.errors.map(({ message, param }, index) => {
            if (param) {
              if (message === "User already Exists") {
                toast.info(
                  "User already exists for this email. Please sign in to continue.",
                  {
                    position: toast.POSITION.BOTTOM_CENTER,
                  }
                );
                history.push("/sign-in");
              } else if (message === "Email is Required") {
                toast.info(message, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
              const error = param + "Error";
              const errorMsg = param + "ErrorMsg";
              errObj = { ...errObj, [error]: true, [errorMsg]: message };
            }
            setErrors({ ...errObj });
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div>
        {showSuccess ? (
          <SingUpPasswordSuccess
            onClickToSignIn={() => getStarted()}
            signUpSuccesWineImage={signUpSuccesWineImage}
          />
        ) : (
          <div className="col-xs-12 col-sm-12 p-0">
            <div className="sign-in-main-page overall-font">
              <div className="sign-in-main-container-background">
                <div className="sign-in-main-container padng-style mt-0">
                  <div>
                    {showTermsAndConds ? (
                      <TermsAndConditions
                        showSignUpFromTerms={() => setShowTermsAndConds(false)}
                        acceptTermsAndConditions={() => {
                          setTicked(true);
                          setShowTermsAndConds(false);
                        }}
                        cancelClickFromTerms={() => {
                          setTicked(false);
                          setShowTermsAndConds(false);
                        }}
                      />
                    ) : showEmailForm ? (
                      <EmailSignupForm
                        state={state}
                        error={errors}
                        setError={setErrors}
                        setState={setState}
                        dateOfBirth={dateOfBirth}
                        setShowEmailForm={setShowEmailForm}
                        setShowSuccess={setShowSuccess}
                        setOutput={setOutput}
                        googleResponse={googleResponse}
                        facebookResponse={facebookResponse}
                      />
                    ) : (
                      <div>
                        <div className="header mb-4">
                          <div className="logo-container new-paddin-style-logo-header">
                            <img
                              alt="PairAnything"
                              className="logo new-pa-logo-width"
                              style={{
                                width: "136px",
                                height: "108px",
                              }}
                              src={logo}
                            />
                          </div>
                        </div>
                        <div className="new-sign-up-title mb-5">Sign Up</div>
                        <label className="chck-box-label pl-0">
                          <p
                            style={{
                              display: "flex",
                              color: "#69696D",
                            }}
                          >
                            {"DOB "}
                            <b
                              style={{
                                color: "#FF0000",
                              }}
                            >
                              {"*"}
                            </b>
                            <p
                              style={{
                                display: "flex",
                                marginLeft: "auto",
                                fontSize: "12px",
                              }}
                            >
                              {
                                "(You must be 21 years of age or older to enter)"
                              }
                            </p>
                          </p>
                          {/* * */}
                        </label>
                        <form onSubmit={handleSubmit}>
                          <div className="new-sing-up-address-container">
                            <div>
                              <div
                                className={"form-group col-xs-4 col-sm-4 pl-0"}
                              >
                                <select
                                  className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                                  name="month"
                                  value={month}
                                  onChange={handleChange}
                                >
                                  <option value="" disabled>
                                    Month
                                  </option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                              </div>
                              <div
                                className={"form-group col-xs-4 col-sm-4 pl-0"}
                              >
                                <input
                                  type="number"
                                  className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                                  name="day"
                                  placeholder="Day"
                                  maxLength="2"
                                  value={day}
                                  onChange={handleChange}
                                  // onBlur={handleDayBlur}
                                />
                              </div>
                              <div
                                className={"form-group col-xs-4 col-sm-4 pl-0"}
                              >
                                <input
                                  type="number"
                                  className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                                  name="year"
                                  maxLength="4"
                                  placeholder="Year"
                                  value={year}
                                  onChange={handleChange}
                                />
                              </div>
                              {dobError ? (
                                <span className="dob-error-message-text new-error-msg-font">
                                  {dobErrorMsg}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </form>
                        <div className="label-chckbx-style mt-4">
                          <div className="chckbox-input-style">
                            <input
                              type="checkbox"
                              id="agreeId"
                              name="agree"
                              onChange={() => {
                                setTicked(!ticked);
                              }}
                              checked={ticked}
                              className="new-sign-up-input-design ptr-clss"
                            />
                          </div>
                          <label className="chck-box-label term-cond">
                            By continuing, you are indicating that you have read
                            and agreed to the{" "}
                            <a
                              onClick={() => setShowTermsAndConds(true)}
                              style={{
                                color: "#CD4545",
                                textDecoration: "none",
                              }}
                            >
                              {"Terms of Use "}
                            </a>
                            and
                            <a
                              onClick={() => setShowTermsAndConds(true)}
                              style={{
                                color: "#CD4545",
                                textDecoration: "none",
                              }}
                            >
                              {" Privacy Policy."}
                            </a>
                          </label>
                        </div>
                        <button
                          disabled={disableSubmit}
                          className="btn btn-round sign-up-btn"
                          onClick={() => setShowEmailForm(true)}
                        >
                          Sign Up with Email
                        </button>
                        <div
                          className="col-xs-12 col-sm-12 p-0 social-media-container-dob"
                          style={{
                            marginTop: "0em !important",
                          }}
                        >
                          <GoogleButton
                            scope={
                              "https://www.googleapis.com/auth/user.birthday.read"
                            }
                            // cookiePolicy={'None'}
                            clientId={Constants.GOOGLE_CLIENT_ID}
                            disabled={false}
                            isMobile={false}
                            onSuccess={googleResponse}
                          />
                          <FaceBookButton
                            appId={Constants.FACEBOOK_APP_ID}
                            isDisabled={false}
                            scope={
                              "public_profile, email, user_birthday, user_age_range"
                            }
                            fields={"name,email,picture,birthday,age-range"}
                            cssClass={"facebook-btn"}
                            autoLoad={false}
                            textButton={"Facebook"}
                            isMobile={false}
                            callback={facebookResponse}
                          />
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {"Already have an account? "}
                          <Link
                            onClick={() =>
                              GoogleAnalyticsFunctions.clickedSignIn()
                            }
                            to="/sign-in"
                            style={{
                              font: "normal normal 600 14px/21px Poppins",
                              letterSpacing: "0px",
                              color: "#CD4545",
                            }}
                          >
                            {"Sign In"}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signoutAction: bindActionCreators(signoutAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
