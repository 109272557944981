import useSWR from "swr";
import * as constant from "../../constants/constants";
//import dayjs from 'dayjs';
import { useLocalStorage } from "./useLocalStorage";
import { useEffect, useState } from "react";
import { get } from "../fetch";
import { reportFrontendException } from "../report";
import { useHistory } from "react-router-dom";

export function useLoggedIn() {
  //useLocalStorage sets a state - checks if the key is in local, otherwise returns undefined

  //const [sessionData] = useLocalStorage("anonymous_session", undefined);

  const [consumerData] = useLocalStorage("user_data", undefined);
  const [token] = useLocalStorage("signin_token", undefined);
  const [loggedIn, setLoggedIn] = useState(false);
  const [roleId, setRoleId] = useState(undefined);

  const history = useHistory();

  const logout = (location) => {
    localStorage.clear();
    localStorage.setItem("ageConfirmed", true);

    if (location) {
      history.push({
        pathname: `${location}`,
      });
      //window.location.reload(false);
    } else {
      //This code is temporary --- but it will log a user out and "refresh the page" without clearing cache
      if (history.location.pathname == "/welcome") {
        history.push({
          pathname: `/discover`,
        });
        setTimeout(() => {
          history.replace({
            pathname: `/welcome`,
          });
        }, 10);
      } else {
        history.push({
          pathname: `/welcome`,
        });
      }
    }
  };

  useEffect(() => {
    if (!!token) {
      setLoggedIn(true);
    }
    if (!!consumerData) {
      setRoleId(consumerData.roleId);
    }
  }, [token, consumerData]);

  return { loggedIn, roleId, token, consumerData, logout };
}
