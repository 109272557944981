import React from "react";

export default function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
    >
      <g opacity="0.5">
        <path
          d="M2.4 0.900391H13.6C14.37 0.900391 15 1.53039 15 2.30039V10.7004C15 11.4704 14.37 12.1004 13.6 12.1004H2.4C1.63 12.1004 1 11.4704 1 10.7004V2.30039C1 1.53039 1.63 0.900391 2.4 0.900391Z"
          stroke="#7D7D7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 2.30078L8 7.20078L1 2.30078"
          stroke="#7D7D7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
