import * as constants from "../constants/constants";

export async function post(url, headers = {}, body) {
  const token = getLocalToken();

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...(!!token && {
        Authorization: `Bearer ${token}`,
      }),
    },
  });
}

export async function postPilotEvent(body) {
  const pilot_session = JSON.parse(localStorage.getItem("pilot_session"));
  const url = `${constants.IDENTITY_API}/api/v1/public/support/pilotEvent`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ pilotSessionId: pilot_session, ...body }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function postEvent(body) {

  const user = JSON.parse(localStorage.getItem("user_data"));
  const session = JSON.parse(localStorage.getItem("anonymous_session"));

  const url = `${constants.IDENTITY_API}/api/v1/public/support/pilotEvent`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ userSessionId: session?.id ?? null, userId: user?.id ?? null, ...body }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function postPendo(url, headers = {}, body) {
  return fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(body),
    headers: {
      ...headers,
    },
  });
}

export async function get(url, headers = {}) {
  const token = getLocalToken();

  return fetch(url, {
    headers: {
      ...headers,
      ...(!!token && {
        Authorization: `Bearer ${token}`,
      }),
    },
  });
}

export async function put(url, headers = {}, body) {
  const token = getLocalToken();

  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...(!!token && {
        Authorization: `Bearer ${token}`,
      }),
    },
  });
}

// export async function getAirtable(url, headers = {}) {
//   return fetch(url, {
//     headers: {
//       ...headers,
//       ...{
//         Authorization: `Bearer ${constants.AIRTABLE_API_TOKEN}`,
//       },
//     },
//   });
// }

const getLocalToken = () => {
  if (isClient) return localStorage.getItem("signin_token");

  return undefined;
};

const isClient = typeof window != "undefined";
