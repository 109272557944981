import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { logPagesVisitedByUser } from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./account-setup.module.scss";
import "./signup.css";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import Header from "../../components/CommonComponents/header";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import moment from "moment";
import * as constants from "../../constants/constants";
import { get, post } from "../../lib/fetch";
import useSWR, { preload } from "swr";
import { useFormik } from "formik";

import LayoutContents from "../../components/CommonComponents/layout-contents";

export default function AccountSetUp() {
  const history = useHistory();

  // ########## fetching target locations #############

  const [targetStoresLoaded, setTargetStoresLoaded] = useState(false);

  const { data: targetStores, error: targetStoresError } = useSWR(
    `${constants.WINERY_API}/api/v1/public/winery/get/targetStoreLocations`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (!!targetStores) {
      setTargetStoresLoaded(true);
    }
    if (!!targetStoresError) {
      console.error(`ERROR: ${targetStoresError}`);
    }
  }, [targetStores, targetStoresError]);

  // ########## end fetching target stores ######################

  const [storeNumberZip, setStoreNumberZip] = useState("");
  const [isAmbassador, setIsAmbassador] = useState(false);

  useEffect(() => {
    logPagesVisitedByUser();
  }, []);

  // ^^^^^^^^^^ searching for target store numbers ^^^^^^^^^^^^

  const [searchValue, setSearchValue] = useState("");
  const [storeOptions, setStoreOptions] = useState(undefined);
  const [storeOptionsVisible, setStoreOptionsVisible] = useState(false);

  useEffect(() => {
    if (!!storeOptions && storeOptions.length > 0) {
      setStoreOptionsVisible(true);
    } else {
      setStoreOptionsVisible(false);
    }
  }, [storeOptions]);

  useEffect(() => {
    if (searchValue.length >= 2 && targetStoresLoaded) {
      setStoreOptions(
        targetStores.filter((store) => {
          return (
            store?.store_number?.toString().includes(searchValue) ||
            store?.store_zip?.toString().includes(searchValue)
          );
        })
      );
    } else {
      setStoreOptions(undefined);
    }
  }, [searchValue, targetStoresLoaded]);

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // ========== Set up formik ==============================

  const [formSubmittable, setFormSubmittable] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik, isAmbassador]);

  // oooooooooo OnClick functions oooooooooooooooooooooooooooooooo

  const clickedStore = (store) => {
    setStoreNumberZip(store?.store_zip);
    setSearchValue(
      `#${
        store?.store_number
      } - ${store?.store_address.trim()}, ${store?.store_city.trim()}, ${
        store?.store_state
      }, ${store?.store_zip} `
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  // WIP
  const [serverError, setServerError] = useState(undefined);

  const submitForm = async () => {
    if (formSubmittable) {
      let signupData = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        // dateOfBirth: dateOfBirth,
        email: formik.values.email,
        password: formik.values.password,
        confirmPassword: formik.values.password,
        isAgeVerified: true,
        ageVerifiedOn: moment().utc(),
        isAmbassador: isAmbassador.toString(),
        storeZip: parseInt(storeNumberZip),
      };

      try {
        const response = await post(
          `${constants.IDENTITY_API}/auth/v1/customer/signUp`,
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          signupData
        );
        const result = await response.json();

        if (result?.errors) {
          setServerError(result.errors[0].message);
        } else if (result?.message) {
          setServerError(result.message);
        }

        if (!!result?.token) {
          localStorage.clear();
          localStorage.setItem("signin_token", JSON.stringify(result?.token));
          localStorage.setItem("user_data", JSON.stringify(result?.user));
          localStorage.setItem("user_id", JSON.stringify(result?.user?.id));
          localStorage.setItem(
            "reward_points",
            JSON.stringify(result?.rewardPoints)
          );
          localStorage.setItem("ageConfirmed", true);

          history.push({
            pathname: `/welcome`,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <Layout>
      <LayoutContents>
        <PAHeader center />
        <div className={`${styles.mainContainer}`}>
          <div className={`${styles.mainContainerSection}`}>
            <h1 className={`h1-30`}>Set up your account.</h1>

            <form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              className={`${styles.formContainer}`}
            >
              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="firstName">
                  First Name<span className={styles.asterisk}>*</span>
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>

              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="lastName">
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>

              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="email">
                  Email Address<span className={styles.asterisk}>*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="firstName">
                  Password<span className={styles.asterisk}>*</span>
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              {/* <button type="submit">Submit</button> */}
            </form>

            <div className={`${styles.ambassadorItem}`}>
              <div
                className={`${styles.checkbox} ${
                  isAmbassador ? styles.checked : null
                }`}
                onClick={() => setIsAmbassador(!isAmbassador)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                >
                  <path
                    d="M2 7.76923L6.33333 12L15 2"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <p className={`p1-semi`}>
                I am a brand ambassador for Product Connections.
              </p>
            </div>
            <div
              className={`${styles.animationWrapper} ${
                isAmbassador ? styles.expanded : null
              }`}
            >
              <div className={`${styles.animationExpandable}`}>
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  className={`${styles.formContainer}`}
                >
                  <div className={`${styles.formField}`}>
                    <p className={`p1-bold`}>
                      Which Target store do you work at?
                    </p>
                    <div className={styles.inputAndResultsBox}>
                      <div
                        className={`${styles.inputWithIcon} ${
                          storeOptionsVisible ? styles.borderBottom : null
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            d="M13 7.6665C13 12.3332 7 16.3332 7 16.3332C7 16.3332 1 12.3332 1 7.6665C1 6.07521 1.63214 4.54908 2.75736 3.42386C3.88258 2.29864 5.4087 1.6665 7 1.6665C8.5913 1.6665 10.1174 2.29864 11.2426 3.42386C12.3679 4.54908 13 6.07521 13 7.6665Z"
                            stroke="#7D7D7D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.99902 9.66699C8.10359 9.66699 8.99902 8.77156 8.99902 7.66699C8.99902 6.56242 8.10359 5.66699 6.99902 5.66699C5.89445 5.66699 4.99902 6.56242 4.99902 7.66699C4.99902 8.77156 5.89445 9.66699 6.99902 9.66699Z"
                            stroke="#7D7D7D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <input
                          value={searchValue}
                          onChange={(event) =>
                            setSearchValue(event.target.value)
                          }
                          placeholder="Enter store # or ZIP"
                          type="text"
                          name="zipfield"
                          required
                          className={`${styles.noShrink} p1-reg`}
                        />
                      </div>
                      {storeOptionsVisible && (
                        <div className={styles.storesListBox}>
                          {storeOptions?.slice(0, 3).map((store, idx) => (
                            <div
                              key={idx}
                              className={styles.storesListItem}
                              onClick={() => clickedStore(store)}
                            >
                              <p className={styles.storeText}>
                                <span style={{ fontWeight: 700 }}>
                                  #{store?.store_number}
                                </span>{" "}
                                - <span>{store?.store_address.trim()}</span>,{" "}
                                <span>{store?.store_city.trim()}</span>,{" "}
                                <span>{store?.store_state.trim()}</span>,{" "}
                                <span>{store?.store_zip}</span>
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={styles.mainContainerSection} style={{ gap: "16px" }}>
            <p className={`p2-reg`}>
              By clicking{" "}
              <span className={styles.createAccText}>Create Account</span> you
              verify that you are 21 years of age or older and agree to our{" "}
              <span
                className={styles.termsOfUseText}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/terms-of-use`,
                    "_blank"
                  );
                }}
              >
                Terms of Use
              </span>{" "}
              and{" "}
              <span
                className={styles.termsOfUseText}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/privacy-policy`,
                    "_blank"
                  );
                }}
              >
                Privacy Policy.
              </span>
            </p>
            {!!serverError ? (
              <div className={styles.serverErrorBox}>{serverError}</div>
            ) : (
              <></>
            )}
            <button
              className={`${styles.submitBtn} ${
                !formSubmittable ? styles.grayedOut : null
              }`}
              onClick={() => submitForm()}
            >
              Create Account
            </button>
            <div className={styles.loginBox}>
              <p className={`p1-reg`}>
                Have an account?{" "}
                <span
                  className={styles.loginTxt}
                  onClick={() =>
                    history.push({
                      pathname: `/login`,
                    })
                  }
                >
                  Log in
                </span>
              </p>
            </div>
          </div>
        </div>
      </LayoutContents>
    </Layout>
  );
}

// save

{
  /* <form
autoComplete="off"
onSubmit={handleSubmit}
className={`${styles.formContainer}`}
>
<div className={`${styles.formField}`}>
  <p className={`p1-bold`}>Name</p>
  <input
    value={name}
    onChange={handleChangeName}
    placeholder="Enter your name"
    type="text"
    name="namefield"
    required
    className={`${styles.formInput} p1-reg`}
  />
</div>
<div className={`${styles.formField}`}>
  <p className={`p1-bold`}>Email</p>
  <input
    value={email}
    onChange={handleChangeEmail}
    placeholder="Enter your email"
    type="email"
    name="emailfield"
    required
    className={`${styles.formInput} p1-reg`}
  />
</div>
<div className={`${styles.formField}`}>
  <p className={`p1-bold`}>Password</p>
  <input
    value={password}
    onChange={handleChangePassword}
    placeholder="Create a password"
    type="password"
    name="passwordfield"
    required
    className={`${styles.formInput} p1-reg`}
  />
</div>
</form> */
}
