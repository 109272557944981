import * as types from './action-types';
import * as constants from '../../constants/constants'

export const CancelRsvp = (eventId, slotId, eventBookingId, cancelReason) => {

    let wineryId = (JSON.parse(localStorage.getItem('winery_id')));
    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = signInToken;
    let consumerData = (JSON.parse(localStorage.getItem('user_data')));
    let apiPostData = {
        cancelledFeedback: cancelReason
    }
    return dispatch => {
        fetch(constants.EVENT_API + '/api/v1/users/winery/' + wineryId + '/events/' + eventId + '/slots/' + slotId + '/booking/' + eventBookingId + '/cancel', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + signInToken   
            },
            body: JSON.stringify(
                apiPostData
            )
        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.CANCEL_RSVP_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.CANCEL_RSVP_FAILED, payload: error })
        })
    }
}
