import React, { Component } from "react";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import "./MyOrders.scss";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import backBtn from "../../../assets/images/left-arrow-white.svg";
// import MyOrdersDetailCard from "../../components/MyOrderDetailsCard/MyOrderDetailsCard";
import OrderHistoryDetailsCard from "../../components/OrderHistoryDetailsCard/OrderHistoryDetailsCard";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { getOrderDetailsByUser } from "./action";
import * as commonFunctions from "../../constants/commonFunctions";
import * as constants from "../../constants/constants";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import { isMobile } from "react-device-detect";
// import emptyShoppingCartImg from "../../../assets/images/empty-cart-updated-img.svg";
class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myOrdersList: [],
      errorMessage: "",
      getOrderListApiCallDone: false,
      emptyOrderMessage: "No orders.",
    };
  }

  componentDidMount = async () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    let getOrdersListUrl = constants.SHOPPING_CART_API + "/api/v1/users/orders";
    try {
      const data = await getOrderDetailsByUser(getOrdersListUrl);
      this.setState({ myOrdersList: data.rows, getOrderListApiCallDone: true });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      // let wineryId = JSON.parse(localStorage.getItem('winery_id'));
      this.setState({
        errorMessage: "Something went wrong.Please try again later.",
        getOrderListApiCallDone: true,
      });
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getOrdersListUrl,
        operation: "GET",
      };
      commonFunctions.postCaughtException(
        JSON.stringify(apiErrorObj),
        consumerData,
        null
      );
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div
        className="success-main-container"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div
          className="success-inner-container new-discover-inner-container"
          id="parentShoppingCartDiv"
        >
          {" "}
          <HeaderWithBurgerIcon
            title="My Orders"
            arrowIcon={true}
            goBackFunc={this.goBack}
          />
          <div className={isMobile ? null : "desktop-div-scroll "}>
            {/* <div className="common-page-header">
          <div className="common-page-sub-header new-common-header-change">
            <img
              alt="Go Back"
              className="common-sub-header-back-icon ptr-clss"
              onClick={this.goBack}
              src={backBtn}
            />
            <div className='new-header-text'>
              <span className="common-sub-header-text new-search-wine-for-dish-text">
                My Orders
                </span>
            </div>
            <img
              alt="PairAnything"
              className="common-sub-header-logo new-logo-header-design ml-0"
              src={pairAnythingLogo}
              onClick={() => { this.openHomePage() }}
            />
          </div>
        </div> */}

            {/*
        // below code based on old UI design
        <div className="active-order-heading font-color ft-s-m ft-wt-600">
          <span>Active Orders</span>
        </div>
        <MyOrdersDetailCard />
        <MyOrdersDetailCard />
        <div className="order-history-heading font-color ft-s-m ft-wt-600">
          <span>Order History</span>
        </div> */}
            {this.state.getOrderListApiCallDone &&
              this.state.errorMessage === "" && (
                <div
                  className="order-history-container"
                  style={{ backgroundColor: "#f2f2f2" }}
                >
                  <ErrorBoundary>
                    {this.state.myOrdersList.length > 0 &&
                      this.state.myOrdersList.map((el, i) => {
                        return (
                          <OrderHistoryDetailsCard
                            key={i}
                            orderDetails={el}
                            onNavigateOrderDetails={this.onOrderClick}
                          />
                        );
                      })}
                  </ErrorBoundary>
                  {this.state.getOrderListApiCallDone &&
                    this.state.myOrdersList.length === 0 &&
                    this.renderEmptyOrderImg()}
                </div>
              )}
            {this.state.getOrderListApiCallDone &&
              this.state.errorMessage != "" && (
                <div className="recommendation-history-main-container new-error-css">
                  <div className="error-boundary-main-container">
                    <span className="error-boundary-title">
                      {this.state.errorMessage}
                    </span>
                    <div className="error-boundary-image-container">
                      <img
                        alt="Something went wrong"
                        className="error-boundary-image"
                        src={errorBoundaryImage}
                      />
                    </div>
                  </div>
                </div>
              )}

            <div className="bottom-scroll-padding"></div>
            <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
              <BottomNavigation />
            </div>
          </div>
        </div>
      </div>
    );
  }

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  onOrderClick = (orderObj) => {
    GoogleAnalyticsFunctions.clickedOrderDetails();
    this.props.history.push("/receipt-details/" + orderObj.id);
  };
  renderEmptyOrderImg = () => {
    return (
      <React.Fragment>
        <div className="error-boundary-image-container empty-cart">
          {/* <img
            alt="Empty Cart"
            id="emptyCart"
            className="error-boundary-image new-empty-cart-width"
            src={emptyShoppingCartImg}
          /> */}
        </div>
        <div className="empty-cart-error-msg-div mt-5">
          <span className="error-boundary-title">
            {this.state.emptyOrderMessage}
          </span>
        </div>
      </React.Fragment>
    );
  };
}

export default MyOrders;
