export const SACRAMENTO_COUNTY_WINERIES = [
    {
        id: 15,
        wineName: 'Scott Harvey Wines',
        winerUrl: 'https://www.scottharveywines.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Scott_Harvey.jpeg')
    },
    {
        id: 9,
        wineName: 'Heringer Estates',
        winerUrl: 'https://heringerestates.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Heringer_Estates.png')
    },
    {
        id: 1,
        wineName: 'Batia Vineyards',
        winerUrl: 'https://www.batiavineyards.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Batia.png')
    },
    {
        id: 2,
        wineName: 'Bogle Vineyards & Winery',
        winerUrl: 'https://boglewinery.com/#',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Bogle.png')
    },
    {
        id: 3,
        wineName: 'Bump City Wine Co.',
        winerUrl: 'http://www.bumpcitywineco.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Bump_City.jpg')
    },
    {
        id: 4,
        wineName: 'Carvalho Family Winery',
        winerUrl: 'http://www.carvalhofamilywinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Carvalho_Family.jpg')
    },
    {
        id: 5,
        wineName: 'Clarksburg Wine Company',
        winerUrl: 'http://clarksburgwineco.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Clarksburg_Wine_CO.jpg')
    },
    {
        id: 6,
        wineName: 'Draconis',
        winerUrl: 'https://www.draconisvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Draconis.png')
    },
    {
        id: 7,
        wineName: 'Due Vigne Winery',
        winerUrl: 'https://duevignewinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Due_Vigne.png')
    },
    {
        id: 8,
        wineName: 'Elevation Ten',
        winerUrl: 'https://www.elevationten.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Elevation_Ten.png')
    },
   
    {
        id: 10,
        wineName: 'Julietta Winery',
        winerUrl: 'https://juliettawinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Julietta.png')
    },
    {
        id: 11,
        wineName: 'Kirchhoff Family Wines',
        winerUrl: 'https://www.kirchhoffwines.com',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Kirchhoff.jpg')
    },
    {
        id: 12,
        wineName: 'Miner\'s Leap Winery',
        winerUrl: 'https://www.minersleap.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Miners_Leap.png')
    },
    {
        id: 13,
        wineName: 'Putah Creek Winery',
        winerUrl: 'http://www.putahcreekwinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Putah_Creek.jpg')
    },
    {
        id: 14,
        wineName: 'Rendez-Vous Winery',
        winerUrl: 'https://www.rendez-vouswinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/RendezVous.jpeg')
    },
    
    {
        id: 16,
        wineName: 'Seka Hills Tasting Room',
        winerUrl: 'https://www.sekahills.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Seka_Hills.jpg')
    },
    {
        id: 17,
        wineName: 'Silt Wine Company',
        winerUrl: 'https://www.siltwineco.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Silt.png')
    },
    {
        id: 18,
        wineName: 'Three Wine Company',
        winerUrl: 'https://threewinecompany.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Three_Wine.jpeg')
    },
    {
        id: 19,
        wineName: 'Todd Taylor',
        winerUrl: 'https://www.toddtaylor.wine/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Todd_Taylor.jpg')
    },
    {
        id: 20,
        wineName: 'Wilson Vineyards',
        winerUrl: 'http://www.wilsonvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Wilson.png')
    },
    {
        id: 21,
        wineName: 'Adkins Family Vineyards',
        winerUrl: 'http://shop.adkinsfamilyvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Adkins.jpg')
    },
    {
        id: 22,
        wineName: 'Bradley Ranch Winery',
        winerUrl: 'https://www.bradleyranch.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Bradley_Ranch.jpg')
    },
    {
        id: 23,
        wineName: 'Christopher Cellars',
        winerUrl: 'https://www.christophercellars.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Christopher_Cellars.png')
    },
    {
        id: 24,
        wineName: 'McConnell Estates Winery',
        winerUrl: 'https://www.mcconnellestates.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/McConnell.jpg')
    },
    {
        id: 25,
        wineName: 'Vannatta Wine',
        winerUrl: 'vannattawinery.com',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Vannatta.jpeg')
    },
    {
        id: 26,
        wineName: 'D’Artagnan Winery Tasting Room',
        winerUrl: 'https://dartagnanvineyardsandwinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/DArtagnan.jpeg')
    },
    {
        id: 27,
        wineName: 'Barsetti Vineyards',
        winerUrl: 'https://www.barsettivineyards.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Barsetti_Vineyards.png')
    },
    {
        id: 28,
        wineName: 'Grace Vineyards',
        winerUrl: 'https://www.gracevineyards.net/',
        wineImage: ''
    },
    
    {
        id: 29,
        wineName: 'Valensin Vineyard & Winery',
        winerUrl: 'https://www.valensinvineyard.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Valesin.jpeg')
    },
    
    {
        id: 30,
        wineName: 'Acheson Wine Company',
        winerUrl: 'https://achesonwinecompany.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Acheson.jpg')
    },
    
    {
        id: 31,
        wineName: 'Bailarin Cellars',
        winerUrl: 'https://www.bailarincellars.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/bailarin_cellars.jpg')
    },
    
    {
        id: 32,
        wineName: 'Frasinetti Winery',
        winerUrl: 'http://frasinetti.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Frasinettis.png')
    },
    
    {
        id: 33,
        wineName: 'Lucid Winery & Tasting Room',
        winerUrl: 'https://www.lucidwinery.com/wine',
        wineImage: ''
    },
    
    {
        id: 34,
        wineName: 'Scribner Bend Vineyards, Inc',
        winerUrl: 'https://www.scribnerbend.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Scribner.jpg')
    },
    
    {
        id: 35,
        wineName: 'Six Hands Winery',
        winerUrl: 'https://www.sixhandswinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Six_hands.png')
    },
    
    {
        id: 36,
        wineName: 'Brave Horse Winery',
        winerUrl: 'http://www.Bravehorsewinery.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/Brave_Horse.jpg')
    },
    {
        id: 37,
        wineName: 'South Slope Wines',
        winerUrl: 'https://southslopewines.com/',
        wineImage: require('../../../assets/images/Visit/Sacramento_County/South_Slope.png')
    },
]