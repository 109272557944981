import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./Profile.scss";

import * as signoutAction from "../../redux/actions/Signout";
import profileCartBtn from "../../../assets/images/profile-cart.svg";
import profilePic from "../../../assets/images/default-avatar.png";
import rightArrowBtn from "../../../assets/images/right-arrow-profile.svg";
import signOutBtn from "../../../assets/images/sign-out-profile.svg";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import medal from "../../../assets/images/medal.svg";

import * as constants from "../../constants/constants";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import { ToastContainer, toast } from "react-toastify";
// import "../FavoriteWines/node_modules/react-toastify/dist/ReactToastify.css";

import plusIcon from "../../../assets/images/plus-icon.jpeg";
import AlertDialogSlide from "../../components/AlertDialog/AlertDialog";
import Slider from "@material-ui/core/Slider";
import { getAllWineryAddress } from "./action";
import Cropper from "react-easy-crop";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ImgDialog from "../../components/ImageCropAndPreview/ImgDialog";
import getCroppedImg from "../../components/ImageCropAndPreview/cropImage";
import imageCompression from "browser-image-compression";
import { isMobile } from "react-device-detect";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import CheckInPopUp from "../../components/CheckInPopUp/CheckInPopUp";
import * as commonFunctions from "../../constants/commonFunctions";
import { Link } from "react-router-dom";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";

toast.configure();

const isReceivedProfileDataApiResponse = true;
const LIMIT = 5;

let defaultConsumerData = JSON.parse(localStorage.getItem("user_data"));
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCheckInPopUp: false,
      checkinMessage: "",
      checkinStatus: false,
      switchWineryStatus: false,
      consumerWineryStatus: "",
      // denotes if user is disabled - all wineries which user is a member of can disable him
      // the only winery which he is a customer in can also disable him
      profileOptionsConsumerWineryStatus: [],
      rewardPoints: "",
      customerChatNotificationCount: 0,
      signOutStatus: false,
      alertDialogStatusSuccess: false,
      apiHit: false,
      profileData: {
        profileName:
          defaultConsumerData != undefined && defaultConsumerData != null
            ? defaultConsumerData.firstName != null &&
              defaultConsumerData.firstName != undefined
              ? defaultConsumerData.firstName +
                " " +
                (defaultConsumerData.lastName
                  ? defaultConsumerData.lastName
                  : "")
              : ""
            : "",
        profileEmail:
          defaultConsumerData != undefined && defaultConsumerData != null
            ? defaultConsumerData.email != null &&
              defaultConsumerData.email != undefined
              ? defaultConsumerData.email
              : ""
            : "",
        profilePic: "",
      },
      tastePreferenceStatus: false,
      wineryDetailstatus: false,
      shippingAddressStatus: false,
      myEventStatus: false,
      myFavoriteStatus: false,
      changePasswordStatus: false,
      chatStatus: false,
      navigateHelpPage: false,
      file: "",
      imagePreviewUrl: "",
      imageCroppingWithPreview: false,
      imageFile: null,
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      isCropping: false,
      rotation: 0,
    };
    this.profileOptions = [
      "My Profile",
      "Taste Preference",
      "Check In",
      // "My Events",
      "My Orders",
      // "My Favorites",
      // "Winery Details",
      "App Feedback",
      "Virtual Tasting",
      "Help",
    ];
    this.wineryAddressLocation = [];
  }

  postCaughtException = (errorData) => {
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));

      let postCaughtExceptionApiUrl =
        constants.IDENTITY_API + "/api/v1/frontendException/create";
      let apiPostData = {
        portal: "consumerportal",
        page: window.location.href,
        error: errorData,
        userId: consumerData.id,
        wineryId: wineryId,
        operation: JSON.parse(errorData).operation,
        statusCode: JSON.parse(errorData).status,
      };
      fetch(postCaughtExceptionApiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(apiPostData),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(res.statusText);
          }
        })
        .then((json) => {})
        .catch((error) => console.error(error));
    } catch (e) {}
  };

  getDistance(lat1, lon1, lat2, lon2) {
    // lat1, lon1: winery
    // lat2, lon2: user
    const R = 3958.8; // earth radius in miles

    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      // if (unit=="K") { dist = dist * 1.609344 }
      // if (unit=="N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  notifyWinery = (checkinDetails) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let notifyWineryApiUrl =
      constants.IDENTITY_API + `/api/v1/winery/${wineryId}/checkin`;

    fetch(notifyWineryApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(checkinDetails),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: notifyWineryApiUrl,
            operation: "POST",
          };
          commonFunctions.postCaughtException(
            JSON.stringify(apiErrorObj),
            consumerData,
            wineryId
          );
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  locationSuccess = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let wineryLat = -1;
    let wineryLng = -1;
    let wineryAddressList = this.wineryAddressLocation;
    let wineryLocation;
    let msg, status;

    if (wineryAddressList.length > 0) {
      for (const e of wineryAddressList) {
        if (this.getDistance(e.latitude, e.longitude, lat, lon) < LIMIT) {
          msg = "Thank you for checking in";
          status = true;
          wineryLat = e.latitude;
          wineryLng = e.longitude;
          wineryLocation = e.addressLine1;
        }
      }
    }

    if (wineryLng === -1) {
      msg = `Sorry, check-in is only available within ${LIMIT} mile radius`;
      status = false;
    } else {
      const checkinDetails = {
        eventId: -1,
        userLat: lat,
        userLon: lon,
        wineryLat: wineryLat,
        wineryLon: wineryLng,
        wineryLocation: wineryLocation,
      };
      // API for back-end implementation
      this.notifyWinery(checkinDetails);
    }

    this.setState({
      showCheckInPopUp: true,
      checkinMessage: msg,
      checkinStatus: status,
    });
  };

  locationError = (position) => {
    let msg = "Sorry, your location is not available!";
    this.setState({
      showCheckInPopUp: true,
      checkinMessage: msg,
      checkinStatus: false,
    });
  };

  checkIn = () => {
    navigator.geolocation.getCurrentPosition(
      this.locationSuccess,
      this.locationError,
      { enableHighAccuracy: true }
    );
    setTimeout(() => {
      this.handleCancel();
    }, 4000);
  };

  handleCancel = () => {
    this.setState({
      showCheckInPopUp: false,
    });
  };

  componentDidMount = async () => {
    // let rewardPoints;

    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let consumerWineryStatus = localStorage.getItem("user_winery_status");

    let profileOptionsConsumerWineryStatus = [];

    if (
      consumerWineryStatus != null &&
      consumerWineryStatus != undefined &&
      consumerWineryStatus == "disabled"
    ) {
      if (isMobile) {
        profileOptionsConsumerWineryStatus = [1, 1, 0, 1, 1, 0, 0, 1, 1]; // based on mobile profile options order.
      } else {
        profileOptionsConsumerWineryStatus = [1, 1, 0, 1, 1, 0, 0, 1, 1]; // based on desktop profile options order.
      }

      /*
                1. Personal info
                2. Taste Preference
                3. Winery Details
              // deleted now  3. Contact Winery // deleted now
                4. App Feedback
                5. Help Page
            */
    }

    if (consumerWineryStatus != null && consumerWineryStatus != undefined) {
      this.setState({
        consumerWineryStatus: consumerWineryStatus,
        profileOptionsConsumerWineryStatus: profileOptionsConsumerWineryStatus,
      });
    }

    let consumerId = consumerData.id;
    if (consumerWineryStatus != "disabled") {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));

      this.wineryAddressLocation = await getAllWineryAddress(wineryId);
    }

    // if(( JSON.parse(localStorage.getItem('reward_points'))  ) !=undefined) {
    //      rewardPoints =  ( JSON.parse(localStorage.getItem('reward_points'))  );

    //      if(rewardPoints!=null && rewardPoints!=undefined) {
    //         this.setState({
    //             rewardPoints: rewardPoints
    //         })
    //     }
    // }

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    if (consumerData.profilePicture != null) {
      this.setState({
        // profilePic: consumerData.profilePicture
      });
    }

    let getUserDetailsApiUrl =
      constants.IDENTITY_API + "/api/v1/user/getCustomerById/" + consumerId;
    const { showLoading } =
      this.props.location.state != undefined
        ? this.props.location.state
        : false;
    if (showLoading != undefined && showLoading == true) {
      this.isReceivedProfileDataApiResponse = false;
    } else {
      this.isReceivedProfileDataApiResponse = true;
    }
    this.getUserDetails(getUserDetailsApiUrl, bearer);

    if (
      consumerWineryStatus != null &&
      consumerWineryStatus != undefined &&
      consumerWineryStatus != "disabled"
    ) {
      this.displayNotificationCount();
    }

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  getUserDetails = (getUserDetailsApiUrl, bearer) => {
    fetch(getUserDetailsApiUrl, {
      // new updated URL

      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        this.isReceivedProfileDataApiResponse = true;
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getUserDetailsApiUrl,
            operation: "GET",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state

        if (json.user.firstName == undefined) {
        }
        this.setState((prevState) => ({
          apiHit: true,
          rewardPoints: json.user.rewardPoints,
          profileData: {
            // object that we are going to update
            ...prevState.profileData, // retains all other state key-value data
            profileName:
              json.user.firstName +
              " " +
              (json.user.lastName ? json.user.lastName : ""), // update value of specific key
            profilePic: json.user.profilePicture,
            profileEmail: json.user.email,
          },
        }));
        // localStorage.setItem('user_data', JSON.stringify(json) );
        // update local storage user_data
      })
      .catch((error) => {
        this.isReceivedProfileDataApiResponse = true;
        console.error(error);
      });
  };

  displayNotificationCount = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let roomId = consumerData.id + "_" + wineryId;

    let getChatRoomByIdApiUrl =
      constants.CHAT_API + "/api/v1/chat/getChatRoomById/" + roomId;
    fetch(getChatRoomByIdApiUrl, {
      // updated new URL

      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getChatRoomByIdApiUrl,
            operation: "GET",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        this.setState({
          customerChatNotificationCount: json.customerNotificationCount,
        });
      })
      .catch((error) => console.error(error));
  };
  deleteNotificationSubscription = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    let fcmToken = JSON.parse(localStorage.getItem("fcm_token"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let deleteNotificationSubscriptionApiUrl =
      constants.IDENTITY_API + "/api/v1/notificationSubscription/delete";

    if (fcmToken) {
      let apiData = {
        userId: consumerData.id,
        wineryId: wineryId,
        fcmToken: fcmToken,
      };

      fetch(deleteNotificationSubscriptionApiUrl, {
        // dev
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(apiData),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: deleteNotificationSubscriptionApiUrl,
              operation: "DELETE",
              status: res.status,
            };

            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .then((json) => {})
        .catch((error) => console.error(error));
    }
  };

  getSignOutStatus = (status) => {
    // console.log(status);
    if (status) {
      if (
        this.state.consumerWineryStatus != "" &&
        this.state.consumerWineryStatus != null &&
        this.state.consumerWineryStatus != undefined &&
        this.state.consumerWineryStatus != "disabled"
      ) {
        this.deleteNotificationSubscription();
      }

      this.setState({
        signOutStatus: true,
      });

      try {
        localStorage.clear();
        localStorage.setItem("ageConfirmed", true);
      } catch (e) {}

      localStorage.setItem("signout", "yes");
      // console.log(this.props.signoutAction, 'this.props.signoutAction')
      this.props.signoutAction.ResetApp();
    } else {
      // console.log(this.state.alertDialogStatusSuccess);
      // window.location.reload();
      this.setState({
        alertDialogStatusSuccess: false,
        // signOutStatus: true
      });
    }
  };

  signOut = () => {
    // console.log(this.state.alertDialogStatusSuccess);
    this.setState({
      alertDialogStatusSuccess: true,
      // signOutStatus: true
    });
  };

  switchWinery = () => {
    this.setState({
      switchWineryStatus: true,
    });
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  setRotation = (rotation) => {
    this.setState({ rotation });
  };

  cancelUpload = () => {
    this.setState({
      isCropping: false,
      imageCroppingWithPreview: false,
    });
  };
  showResult = async () => {
    try {
      this.setState({
        isCropping: true,
      });
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        this.state.rotation
      );
      this.postProfilePicture(croppedImage);
      this.setState({
        croppedImage,
        isCropping: false,
        imageCroppingWithPreview: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isCropping: false,
        imageCroppingWithPreview: false,
      });
    }
  };

  onClose = async () => {
    this.setState({
      croppedImage: null,
    });
  };

  handleImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      e.preventDefault();

      const options = {
        maxSizeMB: 2, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        //useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
        //maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
        //exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
        //onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
        //fileType: string            // optional, fileType override
      };
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      let imageDataUrl = await readFile(compressedFile);

      // apply rotation if needed --> Now we are not using and not installed this get orientation package
      // const orientation = await getOrientation(file);
      // const rotation = ORIENTATION_TO_ANGLE[orientation];
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      // }

      this.setState({
        imageCroppingWithPreview: true,
        imageSrc: imageDataUrl,
        imageFile: compressedFile,
        crop: { x: 0, y: 0 },
        zoom: 1,
        rotation: 0,
      });
    }

    // below comments are completely old style
    // e.preventDefault();
    // let reader = new FileReader();
    // let file = e.target.files[0];
    // reader.onloadend = () => {

    //   // // crop the source image at various aspect ratios
    //   // let croppedValue;
    //   // crop(reader.result, 1 / 1).then((canvas) => {
    //   //   croppedValue = canvas;
    //   // });
    //   this.setState({
    //     file: file,
    //     imagePreviewUrl: reader.result,
    //   });
    // };
    // if (e.target.files[0]) {
    //   reader.readAsDataURL(file);
    //   this.postProfilePicture(file);
    // }
  };

  postProfilePicture = (file) => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let consumerId = consumerData.id;
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    this.isReceivedProfileDataApiResponse = false;

    let postProfilePictureApiUrl =
      constants.IDENTITY_API +
      "/api/v1/user/profilePicture?userId=" +
      consumerId;
    const formData = new FormData();

    formData.append("profilePicture", file);
    let apiPostData = formData;

    fetch(postProfilePictureApiUrl, {
      // dev
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: bearer,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: postProfilePictureApiUrl,
            operation: "POST",
            status: res.status,
          };
          toast.info("Oops, Something went wrong, please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          this.isReceivedProfileDataApiResponse = true;
          this.postCaughtException(JSON.stringify(apiErrorObj));
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state
        // this.props.history.go();

        // Again calling get user details API.
        let consumerData = JSON.parse(localStorage.getItem("user_data"));
        let consumerId = consumerData.id;

        let signInToken = JSON.parse(localStorage.getItem("signin_token"));
        let bearer = "Bearer " + signInToken;

        let getUserDetailsApiUrl =
          constants.IDENTITY_API + "/api/v1/user/getCustomerById/" + consumerId;
        this.isReceivedProfileDataApiResponse = false;
        this.getUserDetails(getUserDetailsApiUrl, bearer);
        toast.info("Profile picture updated!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((error) => {
        this.isReceivedProfileDataApiResponse = true;
        toast.info("Oops, Something went wrong, please try again later.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  changeRoute = (routeName) => {
    if (routeName === "Taste Preference") {
      // changed from taste preference to taste profile

      // if (this.state.consumerWineryStatus == "disabled") {
      // } else {
      this.setState({
        tastePreferenceStatus: true,
      });
      // }
    } else if (routeName === "Winery Details") {
      // if (this.state.consumerWineryStatus == "disabled") {
      // } else {
      this.setState({
        wineryDetailstatus: true,
      });
      // }
    } else if (routeName === "Check In") {
      this.checkIn();
    } else if (routeName === "My Events") {
      this.setState({
        myEventStatus: true,
      });
    } else if (routeName === "My Favorites") {
      this.setState({
        myFavoriteStatus: true,
      });
    } else if (routeName === "My Profile") {
      GoogleAnalyticsFunctions.clickedPersonalInfo();
      this.setState({
        shippingAddressStatus: true,
      });
    } else if (routeName === "Change Password") {
      this.setState({
        // changePasswordStatus: true
      });
      // return <Redirect push to="/sign-up" />;
    } else if (routeName === "App Feedback") {
      GoogleAnalyticsFunctions.clickedAppFeedback();
      this.openFeedback();
    }
    // below contact winery removed for now
    // else if (routeName == "Contact Winery") {
    //   if (this.state.consumerWineryStatus == "disabled") {
    //   } else {
    //     GoogleAnalyticsFunctions.clickedChat();
    //     this.setState({
    //       chatStatus: true,
    //     });
    //   }
    // }
    else if (routeName === "Help") {
      GoogleAnalyticsFunctions.clickedHelpPage();
      this.setState({
        navigateHelpPage: true,
      });
    } else if (routeName === "My Orders") {
      GoogleAnalyticsFunctions.clickedMyOrders();
      this.props.history.push("/my-orders");
    }
  };
  openFeedback = () => {
    this.props.history.push("/feedback");
  };

  onImageClick = (e) => {
    // ("input[id='user-profile-pic-upload']").click();
  };

  render() {
    // Currently imagePreviewUrl not using.
    // let { imagePreviewUrl } = this.state;
    let $imagePreview = null;

    // Currently imagePreviewUrl not using.
    // if (imagePreviewUrl) {
    //   $imagePreview = (
    //     <img
    //       alt=""
    //       onClick={(e) => this.onImageClick(e)}
    //       className="user-profile-pic"
    //       src={imagePreviewUrl}
    //     />
    //   );
    // } else
    if (this.state.profileData.profilePic == undefined) {
      $imagePreview = (
        <img
          alt=""
          onClick={(e) => this.onImageClick(e)}
          className="user-profile-pic"
          src={profilePic}
        />
      ); // we set it to a default pic
    } else if (this.state.profileData.profilePic != "") {
      // alert(1);
      $imagePreview = (
        <img
          alt=""
          onClick={(e) => this.onImageClick(e)}
          className="user-profile-pic"
          src={this.state.profileData.profilePic}
        />
      ); // if an image is uploaded already
    } else {
      $imagePreview = (
        <img
          alt=""
          onClick={(e) => this.onImageClick(e)}
          className="user-profile-pic"
          src={profilePic}
        />
      ); // we set it to a default pic
    }

    if (this.state.switchWineryStatus) {
      // return <Redirect push to="/switch-winery" />;
      this.props.history.push("/switch-winery");
    } else if (this.state.tastePreferenceStatus) {
      // return <Redirect push to="/taste-preferences" />; // old api
      // return <Redirect push to="/selected-taste-preference" />;
      this.props.history.push("/selected-taste-preference");
    } else if (this.state.wineryDetailstatus) {
      GoogleAnalyticsFunctions.clickedWineryDetails();

      // return <Redirect push to="/winery-details" />;
      this.props.history.push(
        "/" +
          commonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          )
      );
      // this.props.history.push("/winery-details");
    } else if (this.state.shippingAddressStatus) {
      // return <Redirect push to="/shipping-address" />;
      this.props.history.push("/shipping-address");
    }
    //  else if (this.state.myEventStatus) {
    //   this.props.history.push("/my-events");
    // }
    else if (this.state.myFavoriteStatus) {
      this.props.history.push("/my-favorite");
    }
    // contact winery removed now so below code was commented
    // else if (this.state.chatStatus) {
    //   let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    //   this.props.history.push({
    //     pathname: "/chat" + "/" + wineryId,
    //     state: {
    //       chatFromProfilePage: true,
    //     },
    //   });
    // }
    else if (this.state.changePasswordStatus) {
      // return <Redirect push to="/change-password" />;
      this.props.history.push("/change-password");
    } else if (this.state.signOutStatus) {
      // return <Redirect push to="/sign-in" />;

      // return <Redirect to={{
      //     pathname: '/sign-in',
      //     state: {
      //         signOutDone: this.state.signOutStatus
      //     }
      // }}/>;

      this.props.history.replace({
        pathname: "/discover",
        state: {
          signOutDone: this.state.signOutStatus,
        },
      });
    } else if (this.state.navigateHelpPage) {
      this.props.history.push("/help-page");
    }

    return (
      <div
        className="success-main-container"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className="success-inner-container new-discover-inner-container">
          <HeaderWithBurgerIcon title="Profile" />
          <div
            className={isMobile ? null : "desktop-div-scroll "}
            style={{ backgroundColor: "#f2f2f2" }}
          >
            {this.state.imageCroppingWithPreview && (
              <Fragment>
                <div className="profile-container">
                  <div className="card card-post card-round profile-card shadow p-3 mb-5 bg-white rounded">
                    <div className="crop-container">
                      <Cropper
                        cropShape="round"
                        showGrid={false}
                        image={this.state.imageSrc}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                        onRotationChange={this.setRotation}
                        rotation={this.state.rotation}
                      />
                    </div>
                    <div className="controls">
                      <label className="sliderLableZoom">Zoom</label>
                      <Slider
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                        classes={{ container: "slider" }}
                      />
                    </div>
                    <div className="controls">
                      <label className="sliderLableRotation">Rotation</label>
                      <Slider
                        value={this.state.rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        classes={{ container: "slider" }}
                        onChange={(e, rotation) => this.setRotation(rotation)}
                      />
                    </div>
                    <div className="button p-2">
                      <Button
                        className="mr-3 cancel-button"
                        color="secondary"
                        variant="contained"
                        onClick={this.cancelUpload}
                        disabled={this.state.isCropping}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="upload-button"
                        color="primary"
                        variant="contained"
                        onClick={this.showResult}
                        disabled={this.state.isCropping}
                      >
                        Upload
                      </Button>
                    </div>
                    {/* <ImgDialog
                    img={this.state.croppedImage}
                    onClose={this.onClose}
                  /> */}
                  </div>
                </div>
              </Fragment>
            )}
            {!this.state.imageCroppingWithPreview && (
              <div className="profile-container">
                <div className="card card-post card-round profile-card shadow p-3 mb-5 bg-white rounded">
                  <div className="profile-card-personal-details">
                    {!this.isReceivedProfileDataApiResponse && (
                      <div className="profile-card-personal-details-subheader">
                        <span className="points-value">Loading...</span>
                      </div>
                    )}
                    {this.isReceivedProfileDataApiResponse && (
                      <div className="profile-card-personal-details-subheader">
                        {/* <img className="card-img-top profile-pic" 
                            //   src={this.state.profileData.profilePic}
                            //    alt={this.profilePic} />   
                                src = {profilePic}
                                 /> */}

                        {/* 
                                This image div is moved inside the label to enable onclick image upload 
                                Uncomment if that's not needed in future
                            */}
                        {/* <div className = "user-profile-pic-container">
                                    {$imagePreview}
                            </div> */}

                        {/* <img src={this.state.preview} alt="Preview" /> */}

                        <input
                          className="consumer-image-input ptr-clss"
                          type="file"
                          id="user-profile-pic-upload"
                          accept="image/x-png,image/jpeg"
                          onChange={(e) => this.handleImageChange(e)}
                        />

                        {/* The file upload input has an id="user-profile-pic-upload" We are overriding the 
            file input's default text: (no file chosen/ abc.png ..) and we are adding a label
            by using the ID*/}
                        <label
                          className="click-here-text-label ptr-clss"
                          htmlFor="user-profile-pic-upload"
                        >
                          {/* Click here to change your profile picture */}

                          <div className="user-profile-pic-container">
                            {$imagePreview}
                            <img
                              alt=""
                              className="add-pic-image"
                              src={plusIcon}
                            />
                          </div>
                        </label>
                        <span className="profile-name">
                          {this.state.profileData.profileName !== null
                            ? ""
                            : this.state.profileData.profileName}{" "}
                        </span>
                        <span className="profile-email">
                          {this.state.profileData.profileEmail}{" "}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* 
                                NOTE: Code for displaying Orders and cart has been commented for now
                          */}

                  {/* <div className="row profile-row">
                                <div className="profile-order-cart-details"> 
                                    <span className = "profile-orders">Orders - 2</span>
                                </div>
                              <div className="profile-order-cart-details">
                                <span className = "profile-cart">Cart - 2</span>
                              </div>
                          </div> */}

                  <div className="points-earned-main-container">
                    <div className="points-earned-container">
                      <img alt="Points" className="points-icon-" src={medal} />
                      <span className="points-value">
                        {this.state.rewardPoints} Points
                      </span>
                    </div>
                  </div>
                </div>

                <div className="card card-post card-round profile-options-list shadow p-3 mb-5 bg-white rounded">
                  {/* The 4 profile option buttons have been rendered dynamically */}
                  {this.profileOptions.map((el, index) => {
                    if (el == "App Feedback") {
                      return (
                        <Link
                          to="/feedback"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            key={el}
                            className={
                              this.state.consumerWineryStatus == "disabled" &&
                              this.state.profileOptionsConsumerWineryStatus[
                                index
                              ] == 0
                                ? "profile-option-container-disabled ptr-clss"
                                : "profile-option-container ptr-clss"
                            }
                          >
                            <span
                              className="profile-option-text"
                              value={el}
                              key={el}
                            >
                              {" "}
                              {el}{" "}
                            </span>
                            <img
                              alt=""
                              className="profile-right-arrow-btn"
                              src={rightArrowBtn}
                            />
                          </div>
                        </Link>
                      );
                    }
                    if (el == "Virtual Tasting") {
                      return (
                        <Link
                          to="/virtual-tasting"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            key={el}
                            className={"profile-option-container ptr-clss"}
                          >
                            <span
                              className="profile-option-text"
                              value={el}
                              key={el}
                            >
                              {" "}
                              {el}{" "}
                            </span>
                            <img
                              alt=""
                              className="profile-right-arrow-btn"
                              src={rightArrowBtn}
                            />
                          </div>
                        </Link>
                      );
                    } else if (el !== "Check In" || isMobile) {
                      return (
                        <div
                          key={el}
                          onClick={() => this.changeRoute(el)}
                          className={"profile-option-container ptr-clss"}
                        >
                          <span
                            className="profile-option-text"
                            value={el}
                            key={el}
                          >
                            {" "}
                            {el}{" "}
                          </span>

                          {el == "Contact Winery" &&
                          this.state.customerChatNotificationCount > 0 ? (
                            <span className=" profile-right-arrow-btn chat-count">
                              {" "}
                              {this.state.customerChatNotificationCount}{" "}
                            </span>
                          ) : (
                            ""
                          )}

                          <img
                            alt=""
                            className="profile-right-arrow-btn"
                            src={rightArrowBtn}
                          />
                        </div>
                      );
                    }
                  })}
                  {
                    // <CheckInPopUp
                    //   // open={this.state.showCheckInPopUp}
                    //   open={this.state.showCheckInPopUp}
                    //   // handleYes={this.handleYes}
                    //   handleCancel={this.handleCancel}
                    //   checkinMsg={this.state.checkinMessage}
                    //   checkinStatus={this.state.checkinStatus}
                    //   // confirmText={"confirmText"}
                    //   // cancelText={"cancelText"}
                    //   // addToCartClick={"addToCartClick"}
                    // />
                  }
                </div>

                {/* <div onClick = {this.switchWinery} className = "card card-post card-round profile-footer shadow p-3 mb-5 bg-white rounded">
                   
                   <span className = "profile-signout-text" >Switch Winery</span>
                  
               </div> */}

                <div
                  // onClick={this.signOut}
                  onClick={(e) => {
                    // this.getResponseForQuestionFromWineryAdmin(recommendationQueryData.id)
                    this.signOut();
                    e.stopPropagation(); // to stop parent div onclick to get fired so as to enable arrow up/down
                  }}
                  className="card card-post card-round profile-footer shadow p-3 mb-5 bg-white rounded ptr-clss"
                >
                  <span className="profile-signout-text">Sign Out</span>
                  <img
                    alt="Sign Out"
                    className="profile-signout-btn"
                    src={signOutBtn}
                  />
                </div>
                {this.state.alertDialogStatusSuccess ? (
                  <AlertDialogSlide
                    signOutData="true"
                    signOutStatus={this.getSignOutStatus.bind(this)}
                  />
                ) : (
                  ""
                )}
              </div>
            )}

            {/* {isMobile && ( */}
            <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
              <BottomNavigation
                //     searchWineActive = "false" chatActive = "false"
                //    availableWinesActive = "false"
                profileActive="true"
              />
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    );
  }
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    // FindTastePreferenceByCustomerAction: bindActionCreators(FindTastePreferenceByCustomerAction, dispatch),
    signoutAction: bindActionCreators(signoutAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
