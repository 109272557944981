// import PairingsIcon from "../../../assets/images/Discover/pairing.svg";
// import WineriesIcon from "../../../assets/images/Discover/wineries v2.svg";
// import TasteQuizIcon from "../../../assets/images/Discover/tastequiz.svg";
// import ShopIcon from "../../../assets/images/Discover/shop.svg";
// import {
//   clickedEnjoy,
//   clickedShop,
//   clickedVisit,
// } from "../../components/PAGoogleAnalytics";

const foodPairingData = {
  1943: [
    {
      name: "Garlic Butter Shrimp Scampi",
      link: "https://cafedelites.com/wp-content/uploads/2018/03/Garlic-Butter-Shrimp-IMAGE-15.jpg",
      type: "Simple Celebrations",
    },
    {
      name: "Fettuccine Alfredo",
      link: "https://www.twopeasandtheirpod.com/wp-content/uploads/2018/05/fettuccine-alfredo-6-650x959.jpg",
      type: "Cozy Conversations",
    },
    {
      name: "Sushi Roll",
      link: "https://cdn.britannica.com/52/128652-050-14AD19CA/Maki-zushi.jpg?w=400&h=300&c=crop",
      type: `Tackle it "Today"`,
    },
  ],
  1944: [
    {
      name: "Fondue",
      link: "https://ainttooproudtomeg.com/wp-content/uploads/2023/08/0001_Fondue_Board.jpg",
      type: "Cozy Conversations",
    },
    {
      name: "Proscuitto",
      link: "https://www.volpifoods.com/wp-content/uploads/2020/11/volpi-foods-prosciutto-header.jpg",
      type: "Afternoon Delight",
    },
    {
      name: "Spicy Chicken Enchiladas",
      link: "https://www.jessicagavin.com/wp-content/uploads/2018/04/chicken-enchiladas-5-1200.jpg",
      type: "Sacramento Summer",
    },
  ],
  1946: [
    {
      name: "Roast Chicken",
      link: "https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2022/10/Roast-Chicken-5.jpg",
      type: "Traditional",
    },
    {
      name: "Pastrami Sandwich",
      link: "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2021/09/02/DV3411_House-Pastrami-on-Rye_s4x3.jpg.rend.hgtvcom.1280.1280.suffix/1630595833205.jpeg",
      type: `Tackle it "Today"`,
    },
    {
      name: "Baked Brie",
      link: "https://www.gimmesomeoven.com/wp-content/uploads/2015/11/Cranberry-Baked-Brie-Bites-9.jpg",
      type: "Afternoon Delight",
    },
  ],
};

export default foodPairingData;
