const PRIORITY_MAP = [
    "Foodom",
    "Tablas Creek",
    "Ridge Vineyards",
    "Trefethen",
    "Rodney Strong",
    "Castello",
    "Pine Ridge Vineyards",
    "Sanford",
    "Paso Wine"
]

export const getPriority = name => {
    const namedPriority = PRIORITY_MAP.find(n => n.toLowerCase() === name?.toLowerCase());
    if (!namedPriority) return -1;

    return PRIORITY_MAP.length - PRIORITY_MAP.indexOf(namedPriority);
}