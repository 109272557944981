import React, { useEffect, useState } from "react";
import "./AvailableWines.scss";
import { useSessionUrl } from "../../lib/hooks/useSession";
import { get } from "../../lib/fetch";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import * as constants from "../../constants/constants";
import AvailableWineCard from "../../components/AvailableWineCard/AvailableWineCard";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import CartIconComponent from "../../components/CartIcon/CartIcon";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import {
  logPagesVisitedByUser,
  clickedAvailableWine,
} from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./available-wines.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import { WINERY_API } from "../../constants/constants";
import HeaderBurger from "../../components/CommonComponents/header-burger";
import Layout from "../../components/CommonComponents/layout";

export default function AvailableWines() {
  const history = useHistory([]);
  const { wineryName } = useParams();
  const [availableWineDiscounts, setAvailableWineDiscounts] = useState({});
  const [dealsLoaded, setDealsLoaded] = useState(false);

  const { data } = useSessionUrl(
    `${constants.WINERY_API}/api/v1/public/winery/get/wineryByUniqueUrl/${wineryName}`
  );
  const [wineryDetails, setWineryDetails] = useState(undefined);
  useEffect(() => {
    if (data) {
      setWineryDetails(data);
      let winery_list = [];
      winery_list.push(data);
      localStorage.setItem("winery_list", JSON.stringify(winery_list));
    }
  }, [data]);

  useEffect(() => {
    logPagesVisitedByUser();
    clickedAvailableWine();
  }, []);

  const getWinesAndDiscounts = () => {
    if (!!wineryDetails) {
      Promise.all([
        get(
          `${WINERY_API}/api/v1/public/wine/winery/consumer?wineryId=${wineryDetails.id}`
        ),
        get(
          `${WINERY_API}/api/v1/public/spotlightDeal/getDealsBasedOnWineryForCurrentDate/${
            wineryDetails.id
          }?dateSearch=${moment().utc().format("YYYY-MM-DD")}`
        ),
        get(
          `${WINERY_API}/api/v1/public/discount/getDiscountsBasedOnWineryForCurrentDate/${
            wineryDetails.id
          }?dateSearch=${moment().utc().format("YYYY-MM-DD")}`
        ),
      ])
        .then((r) => Promise.all(r.map((a) => a.json())))
        .then(([avWines, spotlights, discounts]) => {
          let rows = [];
          if (avWines?.rows?.length > 0) {
            rows = avWines.rows.map((wine) => {
              return {
                ...wine,
                deal: spotlights.filter((deal) => deal.wineId == wine.id),
              };
            });
            rows = rows.map((wine) => {
              if (wine.deal && wine.deal.length > 0) return { ...wine };
              else
                return {
                  ...wine,
                  deal: discounts.filter((deal) =>
                    deal.discountWines.some((x) => x.wineId === wine.id)
                  ),
                };
            });
            setDealsLoaded(true);
            setAvailableWineDiscounts(rows);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (wineryDetails != undefined) {
      getWinesAndDiscounts();
      console.log("second");
      console.log(wineryDetails);
    }
  }, [wineryDetails]);

  const renderAvailableWineCards = () => {
    let result = [];
    if (availableWineDiscounts?.length > 0) {
      // display AvailableWineCard if wines have been added
      result.push(
        availableWineDiscounts.map((el, index) => (
          <AvailableWineCard
            index={index}
            wineData={el}
            id={el.id}
            key={el.id}
            addToCartAutomatically={undefined}
          />
        ))
      );
    }
    return result;
  };

  const isExternalReferrer = () => {
    const isExternalReferrer =
      document.referrer &&
      document.referrer.indexOf(window.location.hostname) < 0;
    //console.log("check external referrer", isExternalReferrer);
    return isExternalReferrer;
  };

  return (
    <Layout>
      <HeaderBurger title="Wines" />
      <div style={isMobile ? null : { overflowY: "auto", height: "80vh" }}>
        <div>
          <CartIconComponent
            fromRecommendedPage={false}
            fromAvailableWinesPage={true}
          />
        </div>

        <div className={`${styles.avWinesContainer} slide-from-left row`}>
          <ErrorBoundary>
            {dealsLoaded && renderAvailableWineCards()}
          </ErrorBoundary>
        </div>
        {!isExternalReferrer() &&
          window.location.pathname != "/TargetWineStation/wines" && (
            <div className="bottom-nav shadow bg-white">
              <BottomNavigation availableWinesActive="true" />
            </div>
          )}
      </div>
    </Layout>
  );
}
