import React from "react";
import axios from "axios";
import * as constants from "./constants";
import { useSelector } from "react-redux";
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const isEmbedded = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// Front end exception handling
export const postCaughtException = (errorData, consumerData, wineryId) => {
  console.log("Exception Caught", errorData, constants);
  let signInToken = JSON.parse(localStorage.getItem("signin_token"));
  let bearer = "Bearer " + signInToken;
  let postCaughtExceptionApiUrl =
    constants.IDENTITY_API + "/api/v1/frontendException/create";
  let apiPostData = {
    portal: "consumerportal",
    page: window.location.href,
    error: errorData.error,
    userId: consumerData.id,
    wineryId: wineryId,
    operation: JSON.parse(errorData).operation,
    statusCode: JSON.parse(errorData).status,
  };
  fetch(postCaughtExceptionApiUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    body: JSON.stringify(apiPostData),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw Error(res.statusText);
      }
    })
    .then((json) => {})
    .catch((error) => console.error(error));
};

// Get winery details based on selected wineryId from localstorage
export const getWineryDetailsFromLocalStorage = (
  wineryId,
  wineryListFromRedux
) => {
  let wineryList = JSON.parse(localStorage.getItem("winery_list"));
  let wineryData = [];
  if (
    wineryListFromRedux != undefined &&
    wineryListFromRedux != null &&
    wineryListFromRedux.length > 0
  ) {
    wineryData = wineryListFromRedux.find(
      (x) => x.wineryId == wineryId || x.id == wineryId
    );
  } else {
    wineryData = wineryList.find(
      (x) => x.wineryId == wineryId || x.id == wineryId
    );
  }
  return wineryData;
};

export const shoppingCartOptions = {
  usePAShoppingCart: "usePAShoppingCart",
  useWineryWebsiteForOrders: "useWineryWebsiteForOrders",
  useChatForOrders: "useChatForOrders",
};

export const validateEmail = (email) => {
  const tester =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;

  if (email.length > 256) return false;

  if (!tester.test(email)) return false;

  var emailParts = email.split("@");
  var account = emailParts[0];
  var address = emailParts[1];
  if (account.length > 64) return false;

  var domainParts = address.split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return true;
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getLocalToken = async () => {
  const data = await JSON.parse(localStorage.getItem("signin_token"));
  return data;
};

export const setAuthToken = async () => {
  const token = await getLocalToken();
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else delete axios.defaults.headers.common.Authorization;
};

// This method is to check and update the localStorage 'user_winery_status' value based on the winery list
export const checkAndUpdateCustomerDisabledStatus = () => {
  let localStorageWineryList = JSON.parse(localStorage.getItem("winery_list"));
  let disabledWineryCount = 0;
  localStorageWineryList.forEach((element) => {
    if (element.wineryUserStatus == 3) {
      disabledWineryCount++;
    }
  });
  if (disabledWineryCount === localStorageWineryList.length) {
    localStorage.setItem("user_winery_status", "disabled");
  } else {
    localStorage.removeItem("user_winery_status");
  }
};

export const soundex = function (s) {
  var a = s.toLowerCase().split(""),
    f = a.shift(),
    r = "",
    codes = {
      a: "",
      e: "",
      i: "",
      o: "",
      u: "",
      b: 1,
      f: 1,
      p: 1,
      v: 1,
      c: 2,
      g: 2,
      j: 2,
      k: 2,
      q: 2,
      s: 2,
      x: 2,
      z: 2,
      d: 3,
      t: 3,
      l: 4,
      m: 5,
      n: 5,
      r: 6,
    };

  r =
    f +
    a
      .map(function (v, i, a) {
        return codes[v];
      })
      .filter(function (v, i, a) {
        return i === 0 ? v !== codes[f] : v !== a[i - 1];
      })
      .join("");

  return (r + "000").slice(0, 4).toUpperCase();
};

/*
 * Function to fetch the campaign details given encrypted campaignId and wineryId
 */
export const fetchCampaignDetails = (queryString, history) => {
  console.log("in fetch campaign details");
  let url = constants.IDENTITY_API + "/api/v1/campaign/";
  let path = `${url}getInfo?campaignId=${queryString.campaignId}`;
  path = queryString.wineryId
    ? path + `&wineryId=${queryString.wineryId}`
    : path;
  let signInToken = JSON.parse(localStorage.getItem("signin_token"));
  let bearer = "Bearer " + signInToken;
  axios
    .get(path, {
      headers: {
        Authorization: bearer,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.setItem("winery_id", JSON.stringify(res.data.wineryId));
      history.replace(res.data.campaignUrl);
    })
    .catch((err) => {
      console.log(err);
      history.replace("/discover");
    });
};

export const convertToCamelCase = (str) => {
  let wineryId = localStorage.getItem("winery_id");
  let localStorageWineryList = JSON.parse(localStorage.getItem("winery_list"));
  let strArr;
  console.log(str, parseInt(wineryId), localStorageWineryList);
  if (str && str !== null)
    return str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function (b) {
        return b.toUpperCase();
      });
  else if (
    wineryId &&
    wineryId != null &&
    localStorageWineryList &&
    localStorageWineryList.length > 0
  ) {
    strArr = localStorageWineryList.find(
      (x) => x.wineryId == parseInt(wineryId) || x.id == parseInt(wineryId)
    );
    console.log(strArr);
    if (strArr !== -1)
      return strArr.displayName
        .replace(/\s(.)/g, function (a) {
          return a.toUpperCase();
        })
        .replace(/\s/g, "")
        .replace(/^(.)/, function (b) {
          return b.toUpperCase();
        });
    else return "wineryName";
  } else return "wineryName";
};

export const camelToName = (stringValue) => {
  return stringValue
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/\s\s+/g, " ")
    .trim();
};
