import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { logPagesVisitedByUser } from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./pairing-results.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import SearchIcon from "../../../assets/images/PAIcons/search.svg";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import { DebounceInput } from "react-debounce-input";
import { post, get } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import { useSessionUrl } from "../../lib/hooks/useSession";
import { useQuery } from "../../lib/hooks/useQueryParams";
import VarietalCard from "./VarietalCard";
import ResultsScrollBar from "../../components/TargetComponents/ResultsScrollBar/ResultsScrollBar";
import FoodCard from "./FoodCard";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";
import { sessionUrl } from "../../lib/common/sessionUrl";

export default function PairingResultsFood() {
  const history = useHistory();
  const from = history.location.state?.from;
  const [currentFood, setCurrentFood] = useState(0);

  const [pairingResultUrl, setPairingResultUrl] = useState(null);

  const { search } = useParams();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    logPagesVisitedByUser();
    if (search != null) {
      setPairingResultUrl(
        `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/varietal/${search}`
      );
    }
  }, [search]);

  //fetching the pairing results as [{varietal, wines}]
  const [pairingResultLoaded, setPairingResultLoaded] = useState(false);

  const { data: pairingResult } = useSessionUrl(pairingResultUrl);

  useEffect(() => {
    if (!!pairingResult?.reco?.items) {
      setPairingResultLoaded(true);
    }
  }, [pairingResult]);

  useEffect(() => {
    if (!!pairingResult?.reco?.varietalName) {
      setSearchValue(pairingResult.reco.varietalName);
    }
  }, [pairingResult]);

  return (
    <Layout white>
      <AnimationWrapper animation={from == "left" ? "fromLeft" : "fromRight"}>
        <LayoutContents>
          <PAHeader left />

          <div className={`mc`}>
            <div className={styles.inputWithIcon}>
              <img src={SearchIcon} alt="search icon" />
              <DebounceInput
                className={`p1-reg ${styles.searchBar}`}
                debounceTimeout={500}
                placeholder={`Try "tacos" or "moscato"`}
                type="text"
                name="dish-name"
                value={searchValue}
                onFocus={() => {
                  const url = window.localStorage.getItem("winery_url");
                  url
                    ? history.push({
                        pathname: `/${JSON.parse(url)}`,
                        state: { from: "results" },
                      })
                    : history.push({
                        pathname: `/welcome`,
                        state: { from: "results" },
                      });
                }}
                onChange={(event) => setSearchValue(event.target.value)}
                //onKeyDown={(event) => {
                //  if (event.key === "Enter") {
                // TODO - Render result (food/wine)
                //  }
                //}}
              />
            </div>
          </div>
          {pairingResultLoaded ? (
            <ResultsScrollBar
              food
              itemList={pairingResult?.reco?.items}
              currentSelection={currentFood}
              setCurrentSelection={setCurrentFood}
            />
          ) : (
            <></>
          )}

          {pairingResultLoaded ? (
            <FoodCard
              pairingResult={pairingResult?.reco?.items[currentFood]}
              varietalDescription={pairingResult?.reco?.varietalDescription}
            />
          ) : (
            <></>
          )}
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}
