import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import  CheckoutForm  from '../../components/StripePaymentCheckOutForm/StripePaymentCheckOutForm'
import * as constants from "../../constants/constants";

const stripePromise = loadStripe(constants.PUBLISHABLE_KEY);

class StripePayment extends React.Component {

    render() {
        console.log(stripePromise);
        return (
            <Elements stripe={stripePromise}>
                <InjectedCheckoutForm />
            </Elements>
        );
    }
}

const InjectedCheckoutForm = () => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} />
        )}
    </ElementsConsumer>
);

export default StripePayment;


// const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

// const App = () => (
//     <Elements stripe={stripePromise}>
//         <InjectedCheckoutForm />
//     </Elements>
// );