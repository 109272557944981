import React, { Component } from "react";
import { isMobile } from "react-device-detect";

import "./SignIn.scss";
import logo from "../../../assets/images/splash.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "../../redux/actions/UserAction";
import * as constants from "../../constants/constants";
// import FaceBookButton from "../../components/FacebookButton";
import AppleIDButton from "../../components/AppleIDButton/AppleIDButton";
import GoogleButton from "../../components/GoogleButton";
import SingUpPasswordSuccess from "../SingUpPasswordSuccess/SingUpPasswordSuccess";
import signUpSuccesWineImage from "../../../assets/images/SignUp/wines-signup-image.svg";
import { withRouter } from "react-router-dom";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import {
  convertToCamelCase,
  fetchCampaignDetails,
} from "../../constants/commonFunctions";
import axiosCall from "../../constants";
// import SignUpPopUp from "../SignUpPopup/SignUpPopUp";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      forgotPasswordPageLoaded: false,
      signUpPageLoaded: false,
      signInPageStatus: false,
      userExists: false, // userExists and inValidMessageFromResponse are from the response json values
      inValidMessageFromResponse: "",
      formErrors: { email: "", password: "" },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      signInApiResponse: {
        token: "",
        user: "",
      },
      buttonClick: false,
      signedUp: false,
      showGetStarted: false,
      userPreferencesAlreadyPresent: false,
      signinSuccess: {},
      userPreferencesNotPresent: false,
      rewardPoints: "",
      signinWineryUserStatus: "",
      anonymousUser: false,
      signInBeforeResults: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount = () => {
    const location = this.props.location;
    if(location.state === "anonymous"){
      this.setState({anonymousUser: true});
    }
    else {
      this.setState({anonymousUser: false});
    }

    if (localStorage.getItem("signout") != null) {
      localStorage.removeItem("signout");
    } // we have signout localstorage as we haven't yet called the redux dummy API. we are deleting it after signout

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };



  handleEmailChange(event) {
    let val = event.target.value;
    this.setState(
      {
        email: event.target.value,
        inValidMessageFromResponse: "",
        // buttonClick: false
      },
      () => {
        this.validateField("email", val.trim());
      }
    );
  }
  handlePasswordChange(event) {
    let val = event.target.value;
    this.setState(
      {
        password: event.target.value,
        inValidMessageFromResponse: "",
        // buttonClick: false
      },
      () => {
        this.validateField("password", val);
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showGetStarted) {
      return {
        showGetStarted: true,
      };
    }
    if (nextProps.signinFail && Object.keys(nextProps.signinFail).length != 0) {
      return {
        buttonClick: true,
        inValidMessageFromResponse:
          nextProps.signinFail.message || "Oops Something went wrong",
      };
    }
    //nextProps - new val from reducer
    // prevState - state value
    if (nextProps.signinSuccess != undefined && prevState.buttonClick == true) {
      // coming from mapStateToProps
      /*
        #NOTE: If a message is displayed, it means that status code isn't 200. That is, one of the following might have happened: 
               1) User does not exist
               2) Wrong Password
               3) Please verify the email to login - email not verified
               4) Consumer has been blocked 
               These messages are displayed if they exist 
               nextProps.signinSuccess.message is empty otherwise      
      */

      if (nextProps.signinSuccess.message) {
        //  && prevState.buttonClick==true
        //"Missing credentials"
        if (
          nextProps.signinSuccess.message.message != null &&
          nextProps.signinSuccess.message.message === "Missing credentials" && nextProps.location.state !== 'anonymous'
        ) {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse:
              "please enter your username and password.",
          };
        } else if (nextProps.signinSuccess.message === "User doesnt exist") {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse: "User does not exist.",
          };
        } else if (nextProps.location.state === 'anonymous') {
          localStorage.setItem("email",JSON.stringify(prevState.email));
          // prevState.createTable(prevState.email);
          nextProps.history.push('/TestResults');
        }
        else if (
          nextProps.signinSuccess.message ==
          "Please set your password to continue"
        ) {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse: "Please set your password to continue",
          };
        } else if (nextProps.signinSuccess.message == "Invalid Credentials") {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse: "Invalid credentials.",
          };
        } else if (
          nextProps.signinSuccess.message ==
          "User has been blocked by Administrator"
        ) {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse:
              "Your account has been disabled. Please contact the winery.",
          };
        } else if (nextProps.signinSuccess.message == "Winery isnt active") {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse:
              "Your winery is not active. Please contact your winery administrator.",
          };
        } else {
          return {
            signInPageStatus: false,
            inValidMessageFromResponse: nextProps.signinSuccess.message.message,
          };
        }

        //  } else if(nextProps.signinSuccess.user &&
        //                 nextProps.signinSuccess.user.isVerified == true  && nextProps.signinSuccess.isActive == true ) { // valid user
      } else if (
        nextProps.signinSuccess.user &&
        // nextProps.signinSuccess.user.isVerified == true &&
        (nextProps.signinSuccess.user.password == null ||
          nextProps.signinSuccess.user.password == "")
      ) {
        return {
          signInPageStatus: false,
          inValidMessageFromResponse: "Please set your password to continue",
        };
      } else if (
        nextProps.signinSuccess.user &&
        // nextProps.signinSuccess.user.isVerified == true &&
        nextProps.signinSuccess.user.pairanything_status.name == "active"
      ) {
        // valid user
        // pairanything_status
        // signinSuccess.status.name

        if (
          localStorage.getItem("signout") != null &&
          localStorage.getItem("signout") == "yes"
        ) {
          localStorage.clear();

          return null;
        }
        localStorage.setItem(
          "signin_token",
          JSON.stringify(nextProps.signinSuccess.token)
        );
        localStorage.setItem(
          "user_data",
          JSON.stringify(nextProps.signinSuccess.user)
        );
        localStorage.setItem(
          "user_id",
          JSON.stringify(nextProps.signinSuccess.user.id)
        );
        // localStorage.setItem(
        //   "winery_id",
        //   JSON.stringify(nextProps.signinSuccess.wineryId)
        // );
        localStorage.setItem(
          "reward_points",
          JSON.stringify(nextProps.signinSuccess.rewardPoints)
        );
        localStorage.setItem("ageConfirmed", true);

        let wineryList = [];
        // for (
        //   let i = 0;
        //   i < nextProps.signinSuccess.user.wineryData.length;
        //   ++i
        // ) {
        //   // if(nextProps.signinSuccess.user.wineryData[i].wineryUserStatus == 2) { // if wineryUserStatus is enabled
        //   wineryList.push(nextProps.signinSuccess.user.wineryData[i].wineryId);
        //   // }
        // }
        // localStorage.setItem(
        //   "winery_list",
        //   JSON.stringify(nextProps.signinSuccess.user.wineryData)
        // );

        let signinWineryUserStatus = "";
        // list of winery IDs
        // localStorage.setItem('winery_id_list', JSON.stringify(nextProps.signinSuccess.user.wineryData));
        let disabledWineryCount = 0;
        for (
          let i = 0;
          i < nextProps.signinSuccess.user.wineryData.length;
          ++i
        ) {
          if (
            nextProps.signinSuccess.user.wineryData[i].wineryUserStatus == 3
          ) {
            disabledWineryCount++;
          }
        }
        if (nextProps.signinSuccess.user.wineryData.length == 1) {
          localStorage.setItem(
            "winery_name",
            nextProps.signinSuccess.user.wineryData[0].displayName
          );
          localStorage.setItem(
            "winery_id",
            JSON.stringify(nextProps.signinSuccess.user.wineryData[0].wineryId)
          );
        }
        if (
          nextProps.signinSuccess.user.wineryData.length == 1 &&
          nextProps.signinSuccess.user.wineryData[0].wineryUserStatus == 2
        ) {
          // localStorage.setItem(
          //   "winery_id",
          //   JSON.stringify(nextProps.signinSuccess.user.wineryData[0].wineryId)
          // );
          signinWineryUserStatus = "single winery-enabled";
        } else if (
          nextProps.signinSuccess.user.wineryData.length == 1 &&
          nextProps.signinSuccess.user.wineryData[0].wineryUserStatus == 3
        ) {
          // user is part of 1 winery and is disabled in that
          localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "single winery-disabled";
          // return {
          //   inValidMessageFromResponse: 'Your winery has disabled you. Please contact the winery admin.'
          // }
        } else if (
          disabledWineryCount == nextProps.signinSuccess.user.wineryData.length
        ) {
          // user is disabled in all wineries which he/she is a part of
          localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "multiple winery-disabled";
        }
        // localStorage.setItem('signout', "no");

        // signout is set in local storage in the profile screen when signout button is clicked.
        if (localStorage.getItem("signout") != null) {
          localStorage.clear();
          return null;
        }

        return {
          signinWineryUserStatus: signinWineryUserStatus,
          signInPageStatus: true,
          rewardPoints: nextProps.signinSuccess.rewardPoints,
          userExists: true,
          signInApiResponse: {
            // object that we are going to update
            ...prevState.signInApiResponse, // retains all other state key-value data
            token: [nextProps.signinSuccess.token], // update value of specific key
            user: [nextProps.signinSuccess.user],
          },
        };
        // } else if (
        //   nextProps.signinSuccess.user.pairanything_status.name == "invited"
        // ) {
        //   return {
        //     inValidMessageFromResponse:
        //       "Please verify your email and set your password to continue",
        //   };
        // } else if (
        //   nextProps.signinSuccess.user.pairanything_status.name == "inactive"
        // ) {
        //   return {
        //     inValidMessageFromResponse:
        //       "Your account has been blocked. Please contact the winery admin",
        //   };
      } else {
        return {
          // formErrors: 'User does not exist',
          signInPageStatus: true,
          userExists: false,
        };
      }
    }

    return null;
  }

  getStarted = () => {
    if (
      this.state.signinSuccess.user &&
      this.state.signinSuccess.user.pairanything_status.name === "active"
    ) {
      //
      window.localStorage.clear();
      window.localStorage.setItem(
        "signin_token",
        JSON.stringify(this.state.signinSuccess.token)
      );
      window.localStorage.setItem(
        "user_data",
        JSON.stringify(this.state.signinSuccess.user)
      );
      window.localStorage.setItem(
        "user_id",
        JSON.stringify(this.state.signinSuccess.user.id)
      );
      window.localStorage.setItem(
        "winery_id",
        JSON.stringify(this.state.signinSuccess.wineryId)
      );
      window.localStorage.setItem(
        "reward_points",
        JSON.stringify(this.state.signinSuccess.rewardPoints)
      );
      window.localStorage.setItem(
        "winery_list",
        JSON.stringify(this.state.signinSuccess.user.wineryData)
      );

      let signinWineryUserStatus = "";
      let disabledWineryCount = 0;
      for (
        let i = 0;
        i < this.state.signinSuccess.user.wineryData.length;
        ++i
      ) {
        if (this.state.signinSuccess.user.wineryData[i].wineryUserStatus == 3) {
          disabledWineryCount++;
        }
      }
      if (this.state.signinSuccess.user.wineryData.length == 1) {
        window.localStorage.setItem(
          "winery_name",
          this.state.signinSuccess.user.wineryData[0].displayName
        );
        window.localStorage.setItem(
          "winery_id",
          JSON.stringify(this.state.signinSuccess.user.wineryData[0].wineryId)
        );
      }
      if (this.props.location.state && this.props.location.state.queryString) {
        fetchCampaignDetails(
          this.props.location.state.queryString,
          this.props.history
        );
      } else {
        if (
          this.state.signinSuccess.user.wineryData.length == 1 &&
          this.state.signinSuccess.user.wineryData[0].wineryUserStatus == 2
        ) {
          signinWineryUserStatus = "single winery-enabled";
        } else if (
          this.state.signinSuccess.user.wineryData.length == 1 &&
          this.state.signinSuccess.user.wineryData[0].wineryUserStatus == 3
        ) {
          // user is part of 1 winery and is disabled in that
          window.localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "single winery-disabled";
          // return {
          //   inValidMessageFromResponse: 'Your winery has disabled you. Please contact the winery admin.'
          // }
        } else if (
          disabledWineryCount == this.state.signinSuccess.user.wineryData.length
        ) {
          // user is disabled in all wineries which he/she is a part of
          window.localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "multiple winery-disabled";
        }

        if (signinWineryUserStatus == "single winery-enabled") {
          //user is a member of one winery and that wineryuser status is enabled
          let wineryId = JSON.parse(window.localStorage.getItem("winery_id"));
          // let url = "/search-wine" + "/" + wineryId;
          let url =
            "/" +
            convertToCamelCase(localStorage.getItem("winery_name")) +
            "/pairings";
          this.props.history.push(url);
        } else if (signinWineryUserStatus == "single winery-disabled") {
          //user is a member of one winery and that winery is disabled
          this.props.history.push("/profile");
        } else if (signinWineryUserStatus == "multiple winery-disabled") {
          this.props.history.push("/profile");
        } else {
          this.props.history.push("/wineries");
        }
      }
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid
          ? ""
          : "Please enter a valid email address! ";
        break;
      case "password":
        passwordValid = value.length >= 4;
        // passwordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
        fieldValidationErrors.password = passwordValid
          ? ""
          : "Password invalid.";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid,
    });
  }

  forgotPassword = () => {
    this.setState({
      forgotPasswordPageLoaded: true,
    });
  };

  signInSubmit = () => {
    this.props.UserAction.SingInDummyCall();
    var username = this.state.email;
    username = username.replace(/^[ ]+|[ ]+$/g, "");
    var password = this.state.password;

    if (this.state.formErrors.email != "") {
      this.setState({
        inValidMessageFromResponse: this.state.formErrors.email,
      });
    } else if (this.state.formErrors.password != "") {
      this.setState({
        inValidMessageFromResponse: this.state.formErrors.password,
      });
    } else if ((username !== "" && password !== "") || (username !== "" && password === "" && this.state.anonymousUser)) {
      this.setState({
        inValidMessageFromResponse: "",
        signInPageStatus: false,
        userPreferencesAlreadyPresent: false,
        userPreferencesNotPresent: false,
        signinWineryUserStatus: "",
      });
      this.props.UserAction.UserSignIn(username.toLowerCase(), password);
      GoogleAnalyticsFunctions.clickedSignIn();
    } else {
      this.setState({
        inValidMessageFromResponse: "Please enter your username and password.",
      });
    }
    if (!this.state.userExists) {
    }
    this.setState({
      buttonClick: true,
    });
  };

  gotoSignUpPage = () => {
    this.setState({
      signUpPageLoaded: true,
    });
  };
  findTastePreferenceByCustomer = () => {
    // GET Default for new taste pref API - GET DEFAULT API URL

    let consumerId = localStorage.getItem("user_id");
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let findTastePreferenceByCustomerApiUrl =
      constants.CONSUMER_API +
      "/api/v1/taste_preference/findDefaultTastePreferenceByCustomer?userId=" +
      consumerId;
    // axiosCall("GET")
    fetch(findTastePreferenceByCustomerApiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        if (json.preference != null) {
          this.setState({
            userPreferencesAlreadyPresent: true,
          });
        } else {
          // json.message is not returned in the new API Change - dynamo to mysql change
          this.setState({
            userPreferencesNotPresent: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          userPreferencesNotPresent: true,
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.signUpPageLoaded) {
      // return <Redirect push to="/sign-up" />;
      //navigate to sign up from email campaign
      if (
        this.props.location.state &&
        (this.props.location.state.queryString ||
          this.props.location.state.redirectTo)
      ) {
        // this.props.history.push("/signup", this.props.location.state);
        this.props.history.push("/sign-up", this.props.location.state);
      } else {
        this.props.history.push("/sign-up");
      }
    } else if (this.state.forgotPasswordPageLoaded) {
      // return <Redirect push to="/forgot-password" />;
      this.props.history.push("/forgot-password");
    } else if (this.state.signInPageStatus) {
      // on successful signin
      if (this.props.location.state && this.props.location.state.queryString) {
        fetchCampaignDetails(
          this.props.location.state.queryString,
          this.props.history
        );
      } else {
        //navigate to sign up from event rsvp email
        if (this.props.location.state && this.props.location.state.redirectTo) {
          // if (this.props.location.state.addToCartAutomatically) {
          //   this.props.location.state.addToCartAutomatically();
          // }
          this.props.history.push(this.props.location.state.redirectTo, {
            ...this.props.location.state,
          });
        } else {
          if(this.state.signInBeforeResults){
            this.props.history.push('/TestResults');
          }
          else {
            this.props.history.push("/discover");
          }
        };
      }
      // Skipping taste preference api call -- currently we are allowing all users without taste preference page
      // this.findTastePreferenceByCustomer(); // new api url for taste preference- get default api
      // this.onNavigateWithoutTastePreference(); // commenting this method - as per PAIR-1895
    }

    // Skipping taste preference page for baker winery for other winery run the default navigation s/m.
    // if (this.state.signInApiResponse.user) {
    //   const user = this.state.signInApiResponse.user[0];
    //   if (user.wineryData.length > 0) {
    //     const wineryDetails = user.wineryData;
    //     if (wineryDetails.find(x => x.name === 'Baker Family Wines')) {
    //       this.onNavigateWithoutTastePreference();
    //     } else {
    //       this.onDefaultNavigate();
    //     }
    //   } else {
    //     this.onDefaultNavigate();
    //   }
    // }
  }
  // Skipping taste preference for all users
  // Below onNavigateWithoutTastePreference() method is Previous navigation logic - now as per PAIR-1895 Customer passport Discover page is the first page
  // onNavigateWithoutTastePreference = () => {
  //   if (this.state.signinWineryUserStatus == "single winery-enabled") {
  //     //user is a member of one winery and that wineryuser status is enabled
  //     let wineryId = JSON.parse(localStorage.getItem("winery_id"));
  //     let url = "/search-wine" + "/" + wineryId;
  //     this.props.history.push(url);
  //   } else if (
  //     this.state.signinWineryUserStatus == "single winery-disabled"
  //   ) {
  //     //user is a member of one winery and that winery is disabled
  //     this.props.history.push("/profile");
  //   } else if (
  //     this.state.signinWineryUserStatus == "multiple winery-disabled"
  //   ) {
  //     this.props.history.push("/profile");
  //   } else {
  //     this.props.history.push("/select-winery");
  //   }
  // }

  // Below onDefaultNavigate() method is navigation with taste preference logic
  // onDefaultNavigate = () => {
  //   if (this.state.userPreferencesAlreadyPresent) {
  //     this.onNavigateWithoutTastePreference();
  //   } else if (this.state.userPreferencesNotPresent) {
  //     this.props.history.push("/user-taste-preference");
  //   }
  // }
  onNavigateToSignUp = () => {
    //navigate to sign up from email campaign
    if (
      this.props.location.state &&
      (this.props.location.state.queryString ||
        this.props.location.state.redirectTo)
    ) {
      this.props.history.push("/sign-up", this.props.location.state);
    } else {
      if(this.state.signInBeforeResults){
        this.setState({anonymousUser: true});
      }
      else {
        this.props.history.push("/sign-up");
      }
    }
  };
  onNavigateToSignIn = () => {
    this.setState({anonymousUser: false});
    this.setState({signInBeforeResults: true});
  };

  googleResponse = (response) => {
    const { tokenId, accessToken, googleId, profileObj, ...others } = response;
    const { email } = profileObj;
    try {
      this.setState({
        email,
      });
      this.props.UserAction.SingInDummyCall();
      this.props.UserAction.GoogleSignIn(tokenId, accessToken, googleId);
      if (!this.state.showGetStarted) {
        GoogleAnalyticsFunctions.clickedGoogleSignIn();
        this.setState({
          inValidMessageFromResponse: "",
          signInPageStatus: false,
          userPreferencesAlreadyPresent: false,
          userPreferencesNotPresent: false,
          signinWineryUserStatus: "",
          buttonClick: true,
        });
      } else {
        this.setState({
          showGetStarted: true,
        });
        GoogleAnalyticsFunctions.clickedGoogleSignUp();
      }
    } catch ({ response, ...error }) {
      this.setState({
        inValidMessageFromResponse: "Oops Something went wrong!",
      });
    }
  };

  appleResponse = async (response) => {
    console.log(response);
  }

  // facebookResponse = async (response) => {
  //   const { accessToken, userID, ...others } = response;
  //   console.log(others, "fb others");
  //   try {
  //     // this.setState({
  //     //   email,
  //     // });
  //     this.props.UserAction.SingInDummyCall();
  //     this.props.UserAction.FaceBookSignIn(accessToken, userID);
  //     if (!this.state.showGetStarted) {
  //       GoogleAnalyticsFunctions.clickedFacebookSignIn();
  //       this.setState({
  //         inValidMessageFromResponse: "",
  //         signInPageStatus: false,
  //         userPreferencesAlreadyPresent: false,
  //         userPreferencesNotPresent: false,
  //         signinWineryUserStatus: "",
  //         buttonClick: true,
  //       });
  //     } else {
  //       GoogleAnalyticsFunctions.clickedFacebookSignUp();
  //       this.setState({
  //         showGetStarted: true,
  //       });
  //     }
  //   } catch ({ response, ...error }) {
  //     this.setState({
  //       buttonClick: true,
  //       inValidMessageFromResponse: "Oops Something went wrong!",
  //     });
  //   }
  // };

  initialCallback = () => {
    this.setState(
      {
        signinSuccess: this.props.signinSuccess,
      },
      () => {
        this.props.UserAction.SingInDummyCall();
      }
    );
  };

  render() {
    return (
      <div className="success-main-container ">
        <div
          className=" success-inner-container  new-discover-inner-container sign-in-main-container-background"
          style={{ backgroundColor: "#fff" }}
        >
          <div className={isMobile ? null : "desktop-div-scroll-signin "}>
            {this.state.showGetStarted ? (
              <SingUpPasswordSuccess
                showGetStarted={this.state.showGetStarted}
                initialCallback={this.initialCallback}
                onClickToSignIn={this.getStarted}
                signUpSuccesWineImage={signUpSuccesWineImage}
              />
            ) : (
              <div>
                <div>
                  <div className="header">
                    <div className="logo-container new-paddin-style-logo-header">
                      <img alt="PairAnything" className="logo" src={logo} />
                    </div>
                    <div className="new-sign-up-title">{this.state.anonymousUser ? `${constants.ANONYMOUS_HEADER}` : 'Sign In'}</div>
                  </div>
                  <div className="form-errors-display">
                    <span className="invalid-messages">
                      {/* <FormErrors formErrors={this.state.userExists} /> */}
                      {/* {
                     (!this.state.userExists && this.state.buttonClick)? 'User does not exist' : ''
                   } */}
                      {this.state.inValidMessageFromResponse !== "" &&
                      this.state.buttonClick
                        ? this.state.inValidMessageFromResponse
                        : ""}
                    </span>
                  </div>
                  <div className="sign-in-form-parent">
                    <div
                  className="col-xs-12 col-sm-12 p-0 social-media-container-dob"
                  style={{
                    marginTop: "0em !important",
                  }}
                ></div>
                    <div
                  className="col-xs-12 col-sm-12 p-0 social-media-container-signin"
                  style={{
                    marginTop: "0em !important",
                  }}
                >
                      {/*
                       <GoogleButton
                    scope={"https://www.googleapis.com/auth/user.birthday.read"}
                    // cookiePolicy={'None'}
                    clientId={constants.GOOGLE_CLIENT_ID}
                    disabled={false}
                    // isMobile={isMobile}
                    onSuccess={this.googleResponse}
                  />
                   <AppleIDButton
                    clientID = {constants.APPLE_ID}
                    redirectURI = {constants.APPLE_REDIRECT_URI}
                    scope = {"email, name"}
                    callback={this.appleResponse}
                  />
                       */
                      }
                  {/* <FaceBookButton
                    appId={constants.FACEBOOK_APP_ID}
                    isDisabled={false}
                    scope={
                      "public_profile, email, user_birthday, user_age_range"
                    }
                    fields={"name,email,picture,birthday,age-range"}
                    cssClass={"facebook-btn"}
                    autoLoad={false}
                    textButton={"Facebook"}
                    // isMobile={isMobile}
                    callback={this.facebookResponse}
                  /> */}

                </div>
                    <div className="sign-in-form-container">
                      <form
                        className="sign-in-form"
                        onSubmit={this.handleSubmit}
                      >
                        {}

                        <div className="signin-form-group form-group">
                          {!isMobile && (
                            <div className="signin-form-email-text">
                              Email address
                            </div>
                          )}
                          <input
                            className="form-control signin-form-row-input"
                            type="text"
                            name="email"
                            placeholder={isMobile ? "Email" : ""}
                            id="emailformInput"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                          />
                          <label htmlFor="emailformInput"> </label>
                        </div>

                        {!this.state.anonymousUser && 
                        <div className="signin-form-group form-group">
                          {!isMobile && (
                            <div className="signin-form-password-text">
                              Password
                            </div>
                          )}
                          <input
                            className="form-control signin-form-row-input"
                            type="password"
                            name="password"
                            placeholder={isMobile ? "Password" : ""}
                            id="signInPassword"
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                          />
                          <div
                            onClick={this.forgotPassword}
                            className="new-frgt-pwd-text mt-2 ptr-clss"
                          >
                            Forgot Password?
                          </div>
                          <label htmlFor="signInPassword"> </label>
                        </div>
                        }

                        <button
                          type="button"
                          onClick={this.signInSubmit}
                          //  disabled={!this.state.formValid}
                          className="btn btn-round sign-in-btn"
                        >
                          {this.state.anonymousUser ? 'See Results' : 'Sign In'}
                        </button>

                        {/* <div
                    onClick={this.forgotPassword}
                    className="forgot-password-text"
                  >
                    Forgot Password
              </div> */}
                        {/* <SignUpPopUp open={true} /> */}

                        {!this.state.anonymousUser && 
                        <div
                          onClick={this.onNavigateToSignUp}
                          className="new-sign-up-div"
                        >
                          <span className="new-grey-font">
                            Don't have an account?
                          </span>
                          <span className="ml-2 new-sign-up-color ptr-clss">
                            Sign Up
                          </span>
                        </div>
                        }
                        {this.state.anonymousUser && 
                        <div
                          className="new-sign-up-div"
                        >
                          <span className="new-grey-font">
                            Already have an account?
                          </span>
                          <span onClick={this.onNavigateToSignIn} className="ml-2 new-sign-up-color ptr-clss">
                            Sign In
                          </span>
                        </div>
                        }
                      </form>
                    </div>
                  </div>
                  {}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// export default SignIn;

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value

  return {
    viewedGetStarted: state.AuthReducer.viewedGetStarted,
    signinSuccess: state.AuthReducer.signinSuccess,
    showGetStarted: state.AuthReducer.showGetStarted,
    signinFail: state.AuthReducer.signinFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    UserAction: bindActionCreators(UserAction, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
