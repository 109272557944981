import React, { Component } from "react";

import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./AddedToRsvpPopUp.scss";

import PopUpImage from "../../../assets/images/Events/new-success-rsvp.svg";
import { withStyles } from "@material-ui/core/styles";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";


const theme = createMuiTheme({
  shadows: Array(25).fill("none"),
});

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      background: "none",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box"
    },
  },
})(() => null);

const contentStyle = {
  background: "#FFFFFF",
  paddingTop: "10px",
  paddingBottom: "1px",
  paddingRight: "19px",
  display: "flex",
  flexDirection: "row",
};

export default class AddedToRsvp extends Component {

  componentDidMount = () => {
    GoogleAnalyticsFunctions.addedToRsvp();
  }

  render() {
    let { open } = this.props;

    return (
      <React.Fragment>
        <GlobalCss />
        <MuiThemeProvider theme={theme}>
          <Dialog
            open={open}
            aria-labelledby="added-to-cart-title"
          >
            <DialogContent style={contentStyle}>
              <div className="popup-container">
                <div className="popup-text">
                  Thank You!!
              </div>
                <img src={PopUpImage} alt="PopupImage" />
                <div className="popup-text">
                  Successfully added to my events
              </div>
              </div>

              {
                this.props.showCancel &&
                <div className="popup-container">
                  <div className="popup-text">
                    We are gonna miss you! Hope we see you soon.
              </div>
                </div>
              }
              {
                this.props.editedRsvpSuccessfully &&
                <div className="popup-container">
                  <div className="popup-text">
                    Edited RSVP Successfully. Looking forward to see you.
              </div>
                </div>
              }
            </DialogContent>
          </Dialog>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}
