import React, { useState, useEffect } from "react";
import "./QuizUsersPopup.scss";
import * as quizQuestions from "./QuizQuestions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import { isMobile } from "react-device-detect";
import * as constants from "../../constants/constants";
import savedIcon from "../../../assets/images/Events/correct-red.svg";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const GlobalCss2 = withStyles({
  "@global": {
    ".MuiPaper-root": {
      background: "none",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiTypography-body1": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
    },
    ".MuiInputBase-input": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
      width: "100%",
      border: 0,
      display: "block",
      padding: "6px 0 7px",
      minWidth: 0,
      background: "none",
      boxSizing: "content-box",
      animationName: "mui-auto-fill-cancel",
      lineHeight: "1.2",
    },
  },
})(() => null);

const contentStyleDrawer = {
  justify: "center",
  paddingRight: "19px",
  display: "flex",
  flexDirection: "column",
};

const modalHeight = () => {
  if (isMobile) {
    return "85vh";
  } else if (!isMobile) {
    return "85vh";
  }
};

const popupStyle = {
  fontFamily: "Poppins, Regular sans-serif",
  position: "fixed",
  bottom: "30%",
};

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      background: "FFFFFF",
      borderRadius: "10px",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiDialog-paperWidthFalse": {
      width: isMobile ? "80vw" : "430px !important",
      height: modalHeight(),
      position: "fixed",
      bottom: isMobile ? "5%" : "5%",
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
})(() => null);

const theme = createMuiTheme({
  palette: {
    primary: { main: "#cd4545" },
  },
});

const QuizUsersPopup = ({ openDialog, initial }) => {
  const [nextQuestion, setNextQuestion] = useState(false);
  const [startGame, setStart] = useState(true);

  const generateRandomQuestionIndex = () => {
    const max = 7;
    const min = 0;

    // if user has not started playing yet, initialize array to keep track of questions played in local storage
    if (localStorage.getItem("quiz_users_question_array") == null) {
      let tempArr = [];
      for (let i = 0; i <= max; i++) {
        tempArr[i] = 0;
      }
      tempArr[max] = 1;
      let randomIndex = Math.floor(Math.random() * (max - min) + min);

      localStorage.setItem(
        "quiz_users_question_array",
        JSON.stringify(tempArr)
      );
    }

    // if questions are all done
    let arr = JSON.parse(localStorage.getItem("quiz_users_question_array"));
    if (!arr.includes(0)) {
      return -1;
    }

    // generate random number for question
    let randomIndex = Math.floor(Math.random() * (max - min) + min);
    while (arr[randomIndex] == 1 && arr.includes(0)) {
      randomIndex = Math.floor(Math.random() * (max - min) + min);
    }

    return randomIndex;
  };

  const questions = quizQuestions.QUIZ_QUESTIONS;
  const initialQuestion = generateRandomQuestionIndex();
  const [selectedBool, setSelected] = useState([]);
  const [open, setOpen] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(initialQuestion);
  const [scoreCard, setScoreCard] = useState([0, 0, 0]);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [questionArray, setQuestionArray] = useState([]);
  const [checkAnswer, setCheckAnswer] = useState(false);
  const [noSelectionError, setSelectionError] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState(false);
  const [allQuestionsFinished, setQuestionsFinished] = useState(false);
  const [continuedPlay, setContinuedPlay] = useState(false);

  useEffect(() => {
    if (nextQuestion) {
      const nextQuestionIndex = generateRandomQuestionIndex();
      if (nextQuestionIndex == -1) {
        setQuestionsFinished(true);
      } else {
        setQuestionIndex(nextQuestionIndex);
        let prevArray = questionArray;
        prevArray.push(questions[questionIndex].question);
        setQuestionArray(prevArray);
        setNextQuestion(false);
        setSelected([]);
      }
    }

    if (startGame == true && questionNumber == 3) {
      setContinuedPlay(true);
      setNextQuestion(false);
      setSelected([]);
      setQuestionArray([]);
      setQuestionNumber(0);
    }
  }, [nextQuestion, startGame]);

  useEffect(() => {
    initial ? setOpen(true) : setOpen(false);
  }, [openDialog]);

  useEffect(() => {
    showQuiz();
  }, []);

  const manageQuestions = (questionInd) => {
    if (localStorage.getItem("quiz_users_question_array") == null) {
      // if the user has not started playing yet
      localStorage.setItem("quiz_users_question_array", JSON.stringify([]));
    } else {
      // mark the question as already played
      let newArray = JSON.parse(
        localStorage.getItem("quiz_users_question_array")
      );
      newArray[questionInd] = 1;
      localStorage.setItem(
        "quiz_users_question_array",
        JSON.stringify(newArray)
      );
    }
  };

  const showQuiz = () => {
    let lastQuizPlayed = localStorage.getItem("last_quiz_played");
    let d = new Date();
    let currentDate = parseInt(Date.parse(d.toISOString()));
    let lastSignIn = 0;
    const max = 7;
    const min = 0;
    //localStorage.removeItem("quiz_users_question_array")

    // keep track of when user last signed in
    if (lastQuizPlayed == null) {
      localStorage.setItem("last_quiz_played", currentDate);
    } else {
      lastSignIn = parseInt(localStorage.getItem("last_quiz_played"));
      localStorage.setItem(
        "last_quiz_played",
        Date.parse(new Date().toISOString())
      );
    }

    // if user has not yet played before
    if (localStorage.getItem("quiz_users_question_array") == null) {
      let tempArr = [];
      for (let i = 0; i <= max; i++) {
        tempArr[i] = 0;
      }
      tempArr[max] = 1;
      localStorage.setItem(
        "quiz_users_question_array",
        JSON.stringify(tempArr)
      );
    }

    // if user has logged on within the past 7 days OR they have finished all questions do not show the quiz
    if (
      (lastSignIn != null &&
        parseInt((currentDate - lastSignIn) / (1000 * 60 * 60 * 24)) < 7) ||
      !JSON.parse(localStorage.getItem("quiz_users_question_array")).includes(0)
    ) {
      setOpen(false);
    }

    // if the user has NOT logged on within the past 7 days and still have questions left, show the quiz
    else if (
      lastSignIn != null &&
      parseInt((currentDate - lastSignIn) / (1000 * 60 * 60 * 24)) >= 7 &&
      JSON.parse(localStorage.getItem("quiz_users_question_array")).includes(0)
    ) {
      d = new Date();
      currentDate = Date.parse(d.toISOString());
      // update the last login
      localStorage.setItem("last_quiz_played", currentDate);
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (!startGame) {
      setDrawerOpen(true);
      setThankYouMessage(true);
      setTimeout(() => {
        setDrawerOpen(false);
      }, 3000);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else {
      setOpen(false);
    }
  };
  const postCaughtException = (errorData) => {};

  const calculatePoints = () => {
    // calculate points here
  };
  const handleChange = (i, j) => {
    // handles when a choice for a question is selected
    let prevArray = [];
    prevArray[j] = !selectedBool[j];
    setSelected(prevArray);
  };

  const renderChoices = (el) => {
    let result = [];
    let i = 0;
    for (let j = 0; j < el.choices.length; ++j) {
      let options = el.choices; //an array
      //let selected = tastePreference[i].options[j].value;
      let selected = selectedBool[j];
      let resultName = options[j];
      resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
      let today = new Date();
      let totalTime =
        today.getHours() * 60 * 60 +
        today.getMinutes() * 60 +
        today.getSeconds();

      result.push(
        <Fade in={!nextQuestion} timeout={2000 + j * 1200}>
          <div className="result-container" key={resultName}>
            <button
              key={resultName}
              type="button"
              onClick={() => {
                handleChange(i, j);
              }}
              className={
                selectedBool[j]
                  ? resultName.length > 55
                    ? "btn btn-round results-selected small-selected"
                    : "btn btn-round results-selected"
                  : resultName.length > 55
                  ? "btn btn-round results-not-selected small-selected"
                  : "btn btn-round results-not-selected"
              }
            >
              {resultName}
            </button>
          </div>
        </Fade>
      );
    }
    return result;
  };

  const renderCorrectAnswer = () => {
    const el = questions[questionIndex];
    let result = [];
    let i = 0;
    let answer = el.answer;

    return (
      <div className="result-container" key={answer}>
        <button
          key={answer}
          type="button"
          // onClick={() => { handleChange(i, j) }}
          className={
            answer.length > 55
              ? "btn btn-round results-selected small-selected"
              : "btn btn-round results-selected"
          }
        >
          {answer}
        </button>
      </div>
    );
  };

  const renderQuestion = (el) => {
    return el.question;
  };

  const checkUserAnswer = () => {
    for (let j = 0; j < questions[questionIndex].choices.length; j++) {
      if (
        selectedBool[j] &&
        questions[questionIndex].choices[j] == questions[questionIndex].answer
      ) {
        return true;
      }
    }
    return false;
  };

  const handleStart = () => {
    setStart(!startGame);
  };

  const handleQuestionSubmit = (index) => {
    let selection = null;
    for (let j = 0; j < questions[index].choices.length; j++) {
      if (selectedBool[j]) {
        selection = questions[index].choices[j];
      }
    }
    if (selection == null) {
      setSelectionError(true);
      return;
    } else {
      setSelectionError(false);
    }
    if (selection == questions[index].answer) {
      //alert("correct!")
      let prevScore = scoreCard;
      prevScore[questionNumber] = 1;
      setScoreCard(prevScore);
    } else {
      //alert("incorrect!")
      let prevScore = scoreCard;
      prevScore[questionNumber] = 0;
      setScoreCard(prevScore);
    }

    // setQuestionNumber(questionNumber + 1);

    // setNextQuestion(true);
    setCheckAnswer(true);
  };

  const handleCheckQuestionSubmit = (index) => {
    setCheckAnswer(false);
    setQuestionNumber(questionNumber + 1);
    setNextQuestion(true);
    manageQuestions(questionIndex);
  };
  const numberQuestionsCorrect = () => {
    let numCorrect = 0;
    for (let j = 0; j < scoreCard.length; j++) {
      if (scoreCard[j] == 1) {
        numCorrect += 1;
      }
    }
    return numCorrect;
  };

  const renderQuestionCards = () => {
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="content-style">
          <div className="inner-div">
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>

            <div className="edit-taste-pref-card-container">
              <div className="card card-post card-round edit-wine-type-card shadow p-3 mb-5 bg-white rounded">
                <div className="card-header">
                  {/* {questions.map((el, index) => {
                                        return (
                                            renderQuestion(el)
                                        )})} */}
                  {renderQuestion(questions[questionIndex])}
                </div>

                <div className="display-results-row">
                  {/* {questions.map((el, index) => {
                                        return (
                                            renderWineType(el)
                                    )})} */}
                  {renderChoices(questions[questionIndex])}

                  {/* {this.state.selectedTastePreferenceMasterJson ? this.renderWineType() : null} */}
                </div>
              </div>
            </div>
            <div className="btn-grp">
              {/* <div className="cancel-btn-container">
                                <button type="button"
                                    className="btn btn-round cancel-btn"
                                    onClick={() => {  handleQuestionSubmit() }}>
                                    Cancel
                                </button>
                            </div> */}
              <div className="save-btn-container">
                <button
                  type="button"
                  onClick={() => {
                    handleQuestionSubmit(questionIndex);
                  }}
                  className="btn btn-round save-btn"
                >
                  Submit
                </button>
              </div>
            </div>
            {noSelectionError ? (
              <div>Please choose an answer before continuing!</div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const handleCancel = () => {
    setDrawerOpen(false);
  };

  const renderThankYouPopUp = () => {
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <SwipeableDrawer
            disableSwipeToOpen
            onBackdropClick={() => {
              handleCancel();
            }}
            anchor="bottom"
            open={drawerOpen}
            style={contentStyleDrawer}
            // onOpen={onDrawerOpen}
            // onClose={onDrawerClose}
          >
            <GlobalCss2 />

            <div className="bg">
              <div
                onTouchStart={() => {
                  this.props.handleCancel();
                }}
                className="thin-line-div"
              >
                <div className="thin-line"></div>
              </div>
              <div className="show-edit-sucess">
                <img className="saved-icon" src={savedIcon} alt="Saved Icon" />
                Thank you for playing!
                <br />
                We look forward to playing with you again.
              </div>
            </div>
          </SwipeableDrawer>
        </MuiThemeProvider>
      </React.Fragment>
    );
  };

  const renderSolutionCards = () => {
    let numCorrect = numberQuestionsCorrect();
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="content-style">
          <div style={{ background: "#FFFFFF", overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
            {numCorrect == 3 ? (
              <div className="card-header-2">
                Good job! You got {numCorrect}/3 questions correct!
              </div>
            ) : numCorrect == 2 ? (
              <div className="card-header-2">
                Nice job! You got {numCorrect}/3 questions correct!
              </div>
            ) : (
              <div className="card-header-2">
                Better luck next time! You got {numCorrect}/3 questions correct!
              </div>
            )}
            {/* <div className="card-header">
                        
                    </div> */}
            {/* <div>
                        {questionArray}
                    </div> */}

            <div className="card-header-2">
              Would you like to continue playing?
            </div>
            <div className="btn-grp">
              <div className="cancel-btn-container">
                <button
                  type="button"
                  className="btn btn-round cancel-btn"
                  onClick={handleClose}
                >
                  No
                </button>
              </div>
              <div className="save-btn-container">
                <button
                  type="button"
                  onClick={handleStart}
                  className="btn btn-round save-btn"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderAllQuestionsFinished = () => {
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="content-style">
          <div style={{ background: "#FFFFFF", overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
            <div className="card-header-2">
              Awesome job! You've finished all of our trivia questions!
            </div>

            <div className="card-header-2">
              Check back soon to see when we have more questions ready for you!
            </div>
            <div className="next-question-btn-container">
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-round return-btn"
              >
                Return to Pair Anything
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  const renderOpening = () => {
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="content-style">
          <div style={{ background: "#FFFFFF", overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>

            <div className="card-header-2">
              Welcome to Pair Anything Trivia! Answer trivia questions to test
              your wine knowledge. Would you like to play?
            </div>
            <div className="btn-grp">
              <div className="cancel-btn-container">
                <button
                  type="button"
                  className="btn btn-round cancel-btn"
                  onClick={handleClose}
                >
                  No
                </button>
              </div>
              <div className="save-btn-container">
                <button
                  type="button"
                  onClick={handleStart}
                  className="btn btn-round save-btn"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderCheckQuestionCards = () => {
    let userCorrect = checkUserAnswer();
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="content-style">
          <div style={{ background: "#FFFFFF", overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>

            <div className="edit-taste-pref-card-container">
              <div className="card card-post card-round edit-wine-type-card shadow p-3 mb-5 bg-white rounded">
                <div className="card-header">
                  {/* {questions.map((el, index) => {
                                            return (
                                                renderQuestion(el)
                                            )})} */}
                  {renderQuestion(questions[questionIndex])}
                </div>

                {/* {questions.map((el, index) => {
                                            return (
                                                renderWineType(el)
                                        )})} */}
                {userCorrect ? (
                  <div className="display-results-row-2">
                    <div className="solution-header">
                      Your answer was correct!
                    </div>

                    {renderCorrectAnswer(questions[questionIndex])}
                  </div>
                ) : (
                  <div className="display-results-row-2">
                    <div className="solution-header">
                      Your answer was incorrect. The correct answer was:
                    </div>

                    {renderCorrectAnswer(questions[questionIndex])}
                  </div>
                )}

                {/* {this.state.selectedTastePreferenceMasterJson ? this.renderWineType() : null} */}
              </div>
            </div>
            <div className="btn-grp">
              {/* <div className="cancel-btn-container">
                                    <button type="button"
                                        className="btn btn-round cancel-btn"
                                        onClick={() => {  handleQuestionSubmit() }}>
                                        Cancel
                                    </button>
                                </div> */}
              <div className="next-question-btn-container">
                <button
                  type="button"
                  onClick={() => {
                    handleCheckQuestionSubmit(questionIndex);
                  }}
                  className="btn btn-round next-question-btn"
                >
                  Next Question
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  const determineScreen = () => {
    if (
      allQuestionsFinished &&
      !JSON.parse(localStorage.getItem("quiz_users_question_array")).includes(0)
    ) {
      return renderAllQuestionsFinished();
    } else {
      if (startGame && !continuedPlay) {
        return renderOpening();
      } else if (questionNumber != 3 && !checkAnswer) {
        return renderQuestionCards();
      } else if (questionNumber != 3 && checkAnswer) {
        return renderCheckQuestionCards();
      } else if (questionNumber == 3) {
        return renderSolutionCards();
      }
    }
  };
  return (
    <React.Fragment>
      <GlobalCss />
      {determineScreen()}
      {thankYouMessage ? renderThankYouPopUp() : null}
    </React.Fragment>
  );
};

export default QuizUsersPopup;
