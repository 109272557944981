import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.FORGOT_PASSWORD_DUMMY:
            return {
                ...state,
                forgotPasswordSuccess: action.payload,
            };
        case types.FORGOT_PASSWORD_SUCCEEDED:
            return {
                ...state,
                forgotPasswordSuccess: action.payload
            };

        case types.FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                forgotPasswordFail: action.payload
            };

        default:
            return state;
    }
};
