export const NEVADA_COUNTY_WINERIES = [
    {
        id: 1,
        wineName: 'Avanguardia Wines',
        winerUrl: 'http://www.avanguardiawines.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Avanguardia.jpg')
    },
    {
        id: 3,
        wineName: 'Clavey Wine',
        winerUrl: 'https://claveywine.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Clavey.png')
    },
    {
        id: 4,
        wineName: 'Double Oak Vineyards & Winery',
        winerUrl: 'https://www.yelp.com/biz/double-oak-vineyards-and-winery-nevada-city',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Double_Oak.jpg')
    },
    {
        id: 5,
        wineName: 'Gray Pine Winery',
        winerUrl: 'http://graypinewinery.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Gray_Pine.jpeg')
    },
    {
        id: 6,
        wineName: 'Lucchesi Vineyards & Winery',
        winerUrl: 'https://www.lucchesivineyards.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Lucchesi.jpeg')
    },
    {
        id: 7,
        wineName: 'Montoliva Vineyard & Winery',
        winerUrl: 'https://www.montoliva.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Montoliva.jpg')
    },
    {
        id: 8,
        wineName: 'Naggiar Vineyards',
        winerUrl: 'https://naggiarvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Naggiar.jpg')
    },
    {
        id: 9,
        wineName: 'Nevada City Winery',
        winerUrl: 'https://www.ncwinery.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Nevada_City_Winery.jpg')
    },
    {
        id: 10,
        wineName: 'Pilot Peak Vineyard & Winery',
        winerUrl: 'https://pilotpeak.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Pilot_Peak.png')
    },
    {
        id: 11,
        wineName: 'Sierra Starr Vineyard',
        winerUrl: 'https://sierrastarr.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Sierra_Starr.jpeg')
    },
    {
        id: 13,
        wineName: 'Szabo Vineyards',
        winerUrl: 'https://www.szabovineyards.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Szabo.jpg')
    },
    {
        id: 2,
        wineName: 'Bent Metal Winery',
        winerUrl: 'http://www.bentmetalwinery.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Bent_Metal_Winery.jpg')
    },
    {
        id: 12,
        wineName: 'Smith Vineyard',
        winerUrl: 'http://smithvineyard.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Smith_Vineyard.png')
    },
    {
        id: 14,
        wineName: 'Truckee River Winery',
        winerUrl: 'https://www.truckeeriverwinery.com/',
        wineImage: require('../../../assets/images/Visit/Nevada_County/Truckee_River_Winery.png')
    }
]