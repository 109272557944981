import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import styles from "./help.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import * as constants from "../../constants/constants";
import { get, post } from "../../lib/fetch";
import { useFormik } from "formik";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import { sessionUrl } from "../../lib/common/sessionUrl";

export default function Help() {
  const history = useHistory();

  useEffect(() => {
    logPagesVisitedByUser();
  }, []);

  // ========== Set up formik ==============================

  const [formSubmittable, setFormSubmittable] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.requestText) {
      errors.requestText = "Required";
    } else if (values.requestText.length < 10) {
      errors.requestText = "Must be more than 10 characters";
    } else if (values.requestText.length >= 1000) {
      errors.requestText = "Max length is 1000 characters";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      requestText: "",
      email: "",
    },
    validate,
    onSubmit: (values) => submitForm(values),
  });

  useEffect(() => {
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik]);

  // oooooooooo OnClick functions oooooooooooooooooooooooooooooooo

  const [serverError, setServerError] = useState(undefined);

  const submitForm = async (values) => {
    if (formSubmittable) {
      let helpRequestBody = {
        email: values.email,
        request: values.requestText,
      };

      let url = `${constants.IDENTITY_API}/api/v1/public/support/helpRequest`;

      try {
        const response = await post(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          helpRequestBody
        );

        const result = await response.json();
        history.push("/help/success");
      } catch (err) {
        setServerError(
          "Sorry, there was a problem submitting your help request."
        );
      }
    }
  };

  return (
    <Layout>
      <LayoutContents>
        <PAHeader left />
        <div className={`mc ${styles.main}`}>
          <section className={`${styles.head}`}>
            <div className={`${styles.title}`}>
              <h1 className={`h1-30`}>How can we help?</h1>
              <div>
                <p className={`p1-reg`}>
                  Please describe what you need in the box below.
                </p>
                <p className={`p1-reg`}>We'll get back to you shortly.</p>
              </div>
            </div>
          </section>
          <div className={`${styles.line}`} />
          <section className={`${styles.formSection}`}>
            <form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              className={`${styles.formContainer}`}
            >
              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="requestText">
                  What do you need help with?
                  <span className={styles.asterisk}>*</span>
                </label>
                <textarea
                  placeholder="Describe the problem..."
                  id="requestText"
                  name="requestText"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.requestText}
                  className={`${styles.formInputTextArea} p1-reg`}
                />
                {formik.touched.requestText && formik.errors.requestText ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.requestText}
                  </div>
                ) : null}
              </div>
              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="email">
                  What's your email address?
                  <span className={styles.asterisk}>*</span>
                </label>
                <input
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              <button
                className={`${styles.submitBtn} ${
                  !formSubmittable ? styles.grayedOut : null
                }`}
                type="submit"
              >
                Submit
              </button>
            </form>
          </section>
          {!!serverError ? (
            <div className={styles.serverErrorBox}>{serverError}</div>
          ) : (
            <></>
          )}
        </div>
      </LayoutContents>
    </Layout>
  );
}
