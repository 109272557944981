import * as types from "./action-types";
import * as constants from "../../constants/constants";

export const GetWineryDetails = () => {
  try {
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    return (dispatch) => {
      fetch(
        constants.WINERY_API + "/api/v1/winery/consumer?wineryId=" + wineryId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: bearer,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((respJSON) => {
          dispatch({
            type: types.GET_WINERY_DETAILS_SUCCEEDED,
            payload: respJSON,
          });
        })
        .catch((error) => {
          dispatch({ type: types.GET_WINERY_DETAILS_FAILED, payload: error });
        });
    };
  } catch (e) {
    let consumerWineryStatus = localStorage.getItem("user_winery_status");
    if (
      consumerWineryStatus != null &&
      consumerWineryStatus != undefined &&
      consumerWineryStatus == "disabled"
    ) {
      // this.setState({
      // errorOccurred: 'getRecommendationHistory',
      // errorMessage: 'Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin'
      // })
    }
  }
};

export const GetWineryDetailsWithPublicAPI = () => {
  try {
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    return (dispatch) => {
      fetch(
        constants.WINERY_API +
          "/api/v1/public/winery/get/wineryById/" +
          wineryId,
        {
          method: "GET",
          // headers: {
          //   "Content-Type": "application/json",
          //   Authorization: bearer,
          // },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((respJSON) => {
          dispatch({
            type: types.GET_WINERY_DETAILS_SUCCEEDED,
            payload: respJSON,
          });
        })
        .catch((error) => {
          dispatch({ type: types.GET_WINERY_DETAILS_FAILED, payload: error });
        });
    };
  } catch (e) {}
};

export const setWineryList = (list) => {
  return {
    type: types.SET_WINERY_LIST,
    list,
  };
};
export const setSpotLigthDealList = (list) => {
  return {
    type: types.SET_SPOTLIGHT_DEAL_LIST,
    list,
  };
};
export const setCurrentWineryDetails = (wineryDetails) => {
  return {
    type: types.SET_WINERY_DETAILS,
    wineryDetails,
  };
};
