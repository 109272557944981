import axios from 'axios';
import { setAuthToken } from './commonFunctions';

export default async function axiosCall(method, url, data) {
  let apiData = data ? { method, url, data } : { method, url };
  await setAuthToken();
  return new Promise((resolve,reject) => {
    axios(apiData)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}


