import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./OrderHistoryDetailsCard.scss";
import CheckCircleGreen from "../../../assets/images/MyOrderPageIcons/check-circle-green.svg";
import CancelRedIcon from "../../../assets/images/MyOrderPageIcons/x-circle-red.svg";
import rightArrowBtn from "../../../assets/images/right-arrow-profile.svg";
import * as commonFunctions from "../../constants/commonFunctions";
import moment from "moment";

class OrderHistoryDetailsCard extends Component {
  onNavigateOrderDetails = () => {
    this.props.history.push('/receipt-details')
  }
  render() {
    const orderDetailObj = this.props.orderDetails;

    return (
        <div className="mt-4 font-family order-details-history-container">
          <div>
            <div className='winery-name-container ptr-clss' onClick={() => { this.props.onNavigateOrderDetails(orderDetailObj) }}>
              <div className='order-hist-winery-name'>
                <span>{orderDetailObj.winery.displayName}</span>
              </div>
              <img
                  alt="go to order details"
                  className="ml-auto profile-right-arrow-btn"
                  src={rightArrowBtn}
              />
            </div>
            <div className='brder-btm-brkup mt-3 mb-3'>
              {""}
            </div>
            <div className="order-details-history-header">
              <div>
                <span className='order-hist-lbl-colour'>Order/Transaction No.</span>
              </div>
              {/* <div className="ml-auto history-status">
              <img alt="Delivered" src={CheckCircleGreen} />
            </div> */}
              <div className="ml-auto history-status">
                <span>{orderDetailObj.id}</span>
              </div>
            </div>
            <div className="order-details-history-header">
              <div>
                <span className='order-hist-lbl-colour'>Order Date</span>
              </div>
              <div className="ml-auto history-status">
                <span>{moment.utc(orderDetailObj.paymentDate).local().format("DD MMM YYYY")}</span>
              </div>
            </div>
            <div className="order-details-history-header">
              <div>
                <span className='order-hist-lbl-colour'>Order Status</span>
              </div>
              <div className="ml-auto history-status">
              <span className={orderDetailObj.orderStatus == 'Confirmed' ? 'shpmt-open-lbl'
                  : orderDetailObj.orderStatus === 'Packed' ? 'shpmt-transit-lbl'
                      : orderDetailObj.orderStatus === 'Canceled' ? 'shpmt-cancld-lbl'
                          : orderDetailObj.orderStatus === 'Shipped' ? 'shpmt-shipped-lbl'
                              : 'shpmt-delvrd-lbl'}>{orderDetailObj.orderStatus === 'Confirmed' ? 'Open' : orderDetailObj.orderStatus}</span>
              </div>
            </div>
            <div className="order-details-history-header">
              <div>
                <span className='order-hist-lbl-colour'>Total Amount</span>
              </div>
              <div className="ml-auto history-status">
                <span>{commonFunctions.formatter.format(orderDetailObj.totalAmount)}</span>
              </div>
            </div>
            <div className="order-details-history-header">
              <div>
                <span className='order-hist-lbl-colour'>Tracking Link</span>
              </div>
              {/* <div className="ml-auto history-status">
              <img alt="Delivered" src={CheckCircleGreen} />
            </div> */}
              <div className="ml-auto history-status">
              <span>{orderDetailObj.shipmentTrackingUrl ?
                  <a className='ptr-class' onClick={(e) => { this.navigateTracking(e, orderDetailObj.shipmentTrackingUrl) }} style={{ color: "#CD4545" }} > Click here </a>
                  : '--'}</span>
              </div>
            </div>
            {/* <div className="history-wine-name-div">
            <div>
              <span>Scott Harvey Wines</span>
            </div>
          </div>
          <div className="history-wine-date-div">
            <div>
              <span>26/03/2020</span>
            </div>
          </div> */}
          </div>
        </div>
    );
  }

  navigateTracking = (e, link) => {
    e.preventDefault();
    if (link) {
      if (!link.includes("http://") && !link.includes("https://")) {
        link = "https://" + link;
      }
    }
    window.open(link,"_blank");
  }
}
OrderHistoryDetailsCard = withRouter(OrderHistoryDetailsCard);
export default OrderHistoryDetailsCard;

