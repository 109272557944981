import React, { Component } from 'react';
import { Redirect } from 'react-router';
import './WineEducation.scss';

import rightArrowBtn from '../../../assets/images/right-arrow-profile.svg';  
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation';

import * as constants from '../../constants/constants'
import WineEducationCard from '../../components/WineEducationCard/WineEducationCard';


let wineEducationArray = [1,2,3];
class WineEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {

         
        }
        

    }

   
  
      

    componentDidMount = () => {    }

    render() {
              
        return (
             <div className="wine-education-main-container">
                
                <div className = "wine-education-header">
                    <span className = "wine-education-text" >Education</span>
                </div>

           
                <div className = "wine-education-container">                
                 
                    { 
                        wineEducationArray.map( () => 
                            <WineEducationCard />
                        )
                    }
                </div>
         
              <div className = "bottom-navigation shadow p-3 bg-white rounded">
                   
                   <BottomNavigation wineEducationActive = "true" />                 
                     
                </div>
          
               
            </div>
         );
    }
}


export default WineEducation;
