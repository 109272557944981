import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import "./AddedToCartPopUp.scss";
import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
// const theme = useTheme();

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiPaper-root": {
      background: "none",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
  },
})(() => null);

const contentStyle = {
  background: "#3F3D56",
  paddingTop: "10px",
  paddingBottom: "1px",
  paddingRight: "19px",
  display: "flex",
  flexDirection: "row",
};

const title = {
  fontFamily: "poppins, SemiBold 14px/21px sans-serif",
  fontSize: "1.5rem",
  color: "white",
  fontWeight: "500",
  letterSpacing: "0.5px",
  marginLeft: "1.9rem",
  marginTop: "0.25rem",
};

const checkIcon = {
  display: "inline-block",
  transform: "rotate(45deg)",
  height: "21px",
  width: "10px",
  borderBottom: "3px solid white",
  borderRight: "3px solid white",
};

export default class AddedToCartPopUp extends Component {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };export default class AlertDialogSlide extends Component
  render() {
    let { open } = this.props;

    return (
      <React.Fragment>
        <GlobalCss />
        <Dialog
          //   fullScreen={fullScreen}
          open={open}
          //   TransitionComponent={Transition}
          aria-labelledby="added-to-cart-title"
        >
          <DialogContent style={contentStyle}>
            <Typography style={checkIcon} />
            <DialogContentText style={title}>Added to Cart</DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
