import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer'
import WineryReducer from './WineryReducer'
import ShippingAddressReducer from './ShippingAddressReducer'
import SignUpReducer from './SignUpReducer'
import SetPasswordReducer from './SetPasswordReducer'
import ForgotPasswordReducer from './ForgotPasswordReducer'
import ResetPasswordReducer from './ResetPasswordReducer'
import FindTastePreferenceByCustomerReducer from './FindTastePreferenceByCustomerReducer'
import AddToCartReducer from './AddToCartReducer'
import AddToRsvpReducer from './AddToRsvpReducer'
import GetEventsReducer from './GetEventsReducer'
import CancelRsvpReducer from './CancelRsvpReducer'
import GetMyEventsReducer from './GetMyEventsReducer'
import EditTastePreferenceReducer from './EditTastePreferenceReducer'
import EditRsvpReducer from './EditRsvpReducer'
import GetCartTotalQtyReducer from './GetCartTotalQtyReducer'
import WineRatingReducer from './WineRatingReducer'

const appReducer = combineReducers({
  //include imported reducers here
  AuthReducer,
  WineryReducer,
  ShippingAddressReducer,
  SignUpReducer,
  SetPasswordReducer,
  ResetPasswordReducer,
  ForgotPasswordReducer,
  FindTastePreferenceByCustomerReducer,
  EditTastePreferenceReducer,
  EditRsvpReducer,
  AddToCartReducer,
  GetEventsReducer,
  GetMyEventsReducer,
  AddToRsvpReducer,
  CancelRsvpReducer,
  GetCartTotalQtyReducer,
  WineRatingReducer
});

const rootReducer = (state, action) => {
	// console.log(action.type, 'type')
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;
