// import PairingsIcon from "../../../assets/images/Discover/pairing.svg";
// import WineriesIcon from "../../../assets/images/Discover/wineries v2.svg";
// import TasteQuizIcon from "../../../assets/images/Discover/tastequiz.svg";
// import ShopIcon from "../../../assets/images/Discover/shop.svg";
// import {
//   clickedEnjoy,
//   clickedShop,
//   clickedVisit,
// } from "../../components/PAGoogleAnalytics";

const wineDescriptionData = {
  1943: "Petite Arvine is an exquisite high-class white grape from the Valais Region of Switzerland. Renowned for its exquisite taste and complexity.",
  1944: "Cave de la Côte's flagship Chasselas from the Vaud region of Switzerland is now available with DDI wine.",
  1945: "A rare wine for the adventurous looking for something unique and unavailable elsewhere in California.",
  1946: "Swiss Gamay is known for its uniquely light welcoming body and elegant fruity nature, such a treat to pair with your dishes.",
  1947: "A light red wine with notes of blood orange, black plums, and a herbaceous tinge of mushrooms. Delicate yet lively, supple yet reminiscent of its Swiss heritage.",
};

export default wineDescriptionData;
