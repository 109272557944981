const pairingData = {
  1: {
    foodName: "Cheesecake",
    foodImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/cheesecake.jpg",
    wineName: "Sparkling Wine",
    wineImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/sparkling.png",
    wineDescription:
      "Sparkling wine is highly food-friendly, with bubbles and acidity transforming dining experiences.",
  },
  2: {
    foodName: "Fudge Brownies",
    foodImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/brownies.jpg",
    wineName: "Cabernet Sauvignon",
    wineImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/cabernetsauvignon.png",
    wineDescription:
      "Fruitier with black cherry, licorice, pepper, touch of vanilla, less tannin/acidity, more alcohol.",
  },
  3: {
    foodName: "Spinach Dip with French Bread",
    foodImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/spinachdip.png",
    wineName: "Sparkling Wine",
    wineImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/sparkling.png",
    wineDescription:
      "Sparkling wine is highly food-friendly, with bubbles and acidity transforming dining experiences.",
  },
  4: {
    foodName: "Bourbon Glazed Meatballs",
    foodImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/meatballs.png",
    wineName: "Chardonnay",
    wineImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/chardonnay.png",
    wineDescription:
      "Versatile, popular grape; unoaked versions lean, crisp, high acidity, like Sauvignon Blanc but fuller-bodied.",
  },
  5: {
    foodName: "Smoked Gouda Mac n Cheese Cups",
    foodImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/macncheese.png",
    wineName: "Cabernet Sauvignon ",
    wineImg:
      "https://pairanything.s3.us-west-1.amazonaws.com/assets/image/techstars-demo-images/cabernetsauvignon.png",
    wineDescription:
      "Fruity with black cherry, licorice, and pepper, less tannin, more alcohol.",
  },
};

export default pairingData;
