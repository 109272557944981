import React, { Component } from "react";
import "./RegionBasedWineries.scss";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import * as amadorWineries from "./AmadorWineries";
import * as elDoradoWineries from "./ElDoradoWineries";
import * as nevadaWineries from "./NevadaWineries";
import * as placerWineries from "./PlacerWineries";
import * as yoloWineries from "./YoloWineries";
import * as sacramentoWineries from "./Sacramento";
import * as aggiesUncorked from "./AggiesUncorked";
import sampleWine from "../../../assets/images/sample_wine.png";
import DefaultLogo from "../../../assets/images/DefaultLogo.png";
import * as commonFunctions from "../../constants/commonFunctions";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import CouponModal from "../../components/CouponModal/CouponModal";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import axiosCall from "../../constants";
import * as constants from "../../constants/constants";
import {
  setCurrentWineryDetails,
  setWineryList,
} from "../../redux/actions/WineryAction";
import { LocationSearching } from "@material-ui/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class LocationBasedWineries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wineries: [],
      regionId: "",
      regionName: "",
      searchText: "",
      searchedWineries: [],
      noSearchResult: false,
      countyBasedWineries: [],
      associationBasedWineries: [],
      allWineries: [],
      selectedType: 3,
      associations: [],
      locations: [],
    };
  }
  getCountyBasedWineries = () => {
    console.log(this.props);
    let countyWineryUrl =
      constants.WINERY_API +
      "/api/v1/public/winery/get/wineryBasedOnLocations/" +
      this.props.match.params.countyId;
    axiosCall("GET", countyWineryUrl)
      .then((res) => {
        let wineriesListForLocalStorage = [];
        if (res.data.length > 0) {
          wineriesListForLocalStorage = res.data.map((element) => {
            return {
              id: element.winery.id,
              wineryId: element.winery.id,
              wineryUserStatus: element.winery.statusId,
              displayName: element.winery.displayName,
              shoppingCartOptions: element.winery.shoppingCartOptions,
              website: element.winery.website,
              name: element.winery.name,
              ...element.winery,
            };
          });

          this.setState({
            wineries: wineriesListForLocalStorage,
            countyBasedWineries: [
              ...this.state.countyBasedWineries,
              ...wineriesListForLocalStorage,
            ],
          });
        }
      })
      .catch((error) => console.error(error));
  };
  componentDidMount = () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    this.getCountyBasedWineries();
  };
  gotoPreviousPage = () => {
    this.props.history.replace("/visit", this.props.location.state);
  };
  render() {
    const allWineries = [...this.state.wineries];
    console.log(this.state.wineries);
    const n = 6; //tweak this to add more items per swipe
    const wineriesPerSwipe = new Array(Math.ceil(allWineries.length / n))
      .fill()
      .map((_) => allWineries.splice(0, n));
    const settings = {
      className: "slick-slider-overwrite",
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className=" success-inner-container  new-discover-inner-container">
            <HeaderWithBurgerIcon
              title={this.props.match.params.countyName + " Wineries"}
              arrowIcon={true}
              goBackFunc={() => this.gotoPreviousPage()}
            />
            <div
              className={isMobile ? null : "desktop-div-scroll "}
              style={{ backgroundColor: "#f2f2f2" }}
            >
              <div className="available-wines-main font-family">
                <div className="available-wines-main-container">
                  <div
                    className="select-winery-container row new-discover-container fl-wrap mt-4"
                    style={{ backgroundColor: "#f2f2f2" }}
                  >
                    <div className="region-based-search-bar-container">
                      <div
                        className="recommendation-input form-control search-wine-form-row-input shadow region-based-search-bar"
                        style={{ paddingLeft: "18px" }}
                      >
                        {/* <i className="fa fa-search pointer" onClick={() => this.onSearchClick()} aria-hidden="true"></i> */}
                        <input
                          placeholder="Search by winery name"
                          type="text"
                          name="searchText"
                          className="input-search-box-wine"
                          value={this.state.searchText}
                          onChange={this.onSearchInputChange}
                          onKeyDown={(e) =>
                            e.key === "Enter" ? this.onSearchClick() : ""
                          }
                          style={{ paddingLeft: "14px", fontSize: "18px" }}
                        />
                      </div>
                    </div>
                    <div className="select-winery ">
                      {this.state.wineries.map((el, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => this.onClickWinery(el)}
                            className="card select-winery-card-display-mob shadow ptr-clss"
                          >
                            <ErrorBoundary>
                              {this.renderWineriesCard(el)}
                            </ErrorBoundary>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSwipingDiv = (wineryDetails, index) => {
    return (
      <React.Fragment>
        {wineryDetails &&
          wineryDetails.map((el, j) => {
            return (
              <div
                key={j}
                onClick={() => this.onClickWinery(el)}
                // className="card select-winery-card-card shadow ptr-clss new-ht"
                className="card select-winery-card-display-mob shadow ptr-clss"
              >
                <ErrorBoundary>{this.renderWineriesCard(el)}</ErrorBoundary>
              </div>
            );
          })}
      </React.Fragment>
    );
  };
  renderWineriesCard = (el) => {
    // const logo = require(el.wineImage);
    return (
      <div className="select-winery-card-details">
        <div className="select-winery-card-bottle-logo-container">
          <img
            alt={"winery logo"}
            className={
              el.wineImage
                ? "select-winery-card-bottle-logo-from-api"
                : "select-winery-card-bottle-logo"
            }
            src={el.logo ? el.logo : DefaultLogo}
          />
        </div>

        <div className="select-winery-card-name-container">
          <div className="select-winery-card-subcontainer">
            <span className="select-winery-card-name">{el.displayName}</span>
          </div>
        </div>
      </div>
    );
  };

  onClickWinery = (el) => {
    localStorage.setItem("winery_id", JSON.stringify(el.id));
    localStorage.setItem("winery_name", el.displayName);
    this.props.setCurrentWineryDetails(el);
    this.props.history.push(
      "/" + commonFunctions.convertToCamelCase(el.displayName)
    );
    // this.props.history.push("/winery-details");
  };
  openHomePage = () => {
    this.props.history.push("/discover");
  };

  onSearchInputChange = async (e) => {
    console.log(e.target);
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
    if (name === "searchText" && value !== "") {
      let searchedWineryDetails = await this.onFilterWinerName(value);
      if (searchedWineryDetails.length > 0) {
        this.setState({
          wineries: searchedWineryDetails,
          noSearchResult: false,
        });
      } else {
        this.setState({
          wineries: searchedWineryDetails,
          noSearchResult: true,
        });
      }
    } else {
      this.setState({
        wineries: this.state.countyBasedWineries,
        noSearchResult: false,
      });
    }
  };
  onSearchClick = async () => {
    let searchedWineryDetails = this.onFilterWinerName(
      this.state.searchText.trim()
    );
    this.setState({ wineries: searchedWineryDetails });
  };
  onFilterWinerName = (searchValue) => {
    console.log(searchValue);
    let newFilter = [];
    newFilter = this.state.countyBasedWineries.filter(
      (winery) =>
        (winery.name != null &&
          winery.name.toUpperCase().startsWith(searchValue.toUpperCase())) ||
        (winery.displayName != null &&
          winery.displayName
            .toUpperCase()
            .startsWith(searchValue.toUpperCase()))
    );
    console.log(newFilter);
    return newFilter;
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setCurrentWineryDetails: bindActionCreators(
      setCurrentWineryDetails,
      dispatch
    ),
  };
}
export default connect(null, mapDispatchToProps)(LocationBasedWineries);
