import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
            
        case types.CREATE_WINE_RATING_SUCCESS:
            return {
                ...state,
                createWineRatingSuccess: action.payload
            };

        case types.CREATE_WINE_RATING_FAILED:
            return {
                ...state,
                createWineRatingFailed: action.payload
            };

        case types.GET_AVERAGE_WINE_RATING_SUCCESS:
          return {
              ...state,
              getAverageWineRatingSuccess: action.payload
          };

      case types.GET_AVERAGE_WINE_RATING_FAILED:
          return {
              ...state,
              getAverageWineRatingFailed: action.payload
          };

        default:
            return state;
    }
};
