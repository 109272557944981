import React, { useEffect, useState, useRef } from "react";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./dd-pairing.module.scss";

export default function DDFoodCard({ food = undefined }) {
  if (!food) return <></>;

  return (
    <div className={`${styles.foodCard}`}>
      <p className={`${styles.pairingTypeTxt}`}>{food.type}</p>
      <img src={food.link} alt={"Food Image"} className={`${styles.foodImg}`} />
      <p className={`${styles.foodNameTxt}`}>{food.name}</p>
    </div>
  );
}
