import React, { Component } from "react";
import "./TopNavigation.scss";
import { withRouter } from "react-router-dom";
import * as constants from "../../constants/constants";

import { Navbar, NavItem, NavDropdown, MenuItem, Nav } from "react-bootstrap";

import chatBtnNotSelected from "../../../assets/images/BottomNavigationIcons/chat-not-selected-icon.svg";

import chatBtnSelected from "../../../assets/images/TopNavigationIcons/chat-selected-icon.svg";
import pairAnythingLogo from "../../../assets/images/TopNavigationIcons/top-nav-logo.png";

import popupChatIcon from "../../../assets/images/TopNavigationIcons/popup-chat-icon.svg";
import popupSwitchWineryIcon from "../../../assets/images/TopNavigationIcons/popup-switch-winery-icon.svg";

import eventsNotSelected from "../../../assets/images/TopNavigationIcons/events-icon-not-selected.svg";
import eventsSelected from "../../../assets/images/BottomNavigationIcons/events-icon-selected.svg";

import wineGlassNotSelectedBtn from "../../../assets/images/BottomNavigationIcons/wine-glass-not-selected.svg";
import wineGlassSelectedBtn from "../../../assets/images/BottomNavigationIcons/wine-glass-selected.svg";

import userProfileSelectedBtn from "../../../assets/images/TopNavigationIcons/more-selected-icon.svg";
import userProfileNotSelectedBtn from "../../../assets/images/BottomNavigationIcons/more.svg";

import pairAnythingNotSelectedLogoBtn from "../../../assets/images/BottomNavigationIcons/pair-anything-not-selected-icon.png";
import pairAnythingSelectedLogoBtn from "../../../assets/images/TopNavigationIcons/top-nav-pairings-icon.svg";
import moreIcon from "../../../assets/images/TopNavigationIcons/expand_more_24px.svg";
import MenuIcon from "@material-ui/icons/Menu";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import axiosCall from "../../constants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";
import SignInToContinue from "../CommonComponents/SignInToContinue";
import { convertToCamelCase } from "../../constants/commonFunctions";
import { connect } from "react-redux";

class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumerWineryStatus: "",
      wineryId: "",
      wineryName: "",
      wineryLogo: "",
      wineryDescription: "",
      wineryMessage: "",
      wineryDetailsClicked: false,
      showSideMenu: false,
      signInPopUp: false,
    };
  }

  changeRoute = () => {};

  componentDidMount() {
    // this.displayNotificationCount();

    let consumerWineryStatus = localStorage.getItem("user_winery_status");
    // let wineryId = localStorage.getItem("winery_id");

    if (this.props.wineryDetails !== null) {
      this.setState({
        wineryId: this.props.wineryDetails.id,
      });
    }

    if (consumerWineryStatus != null && consumerWineryStatus != undefined) {
      this.setState({
        consumerWineryStatus: consumerWineryStatus,
      });
    }
    const wineryBased = this.props.isWineryBased === "false" ? false : true;
    if (wineryBased && consumerWineryStatus != "disabled") {
      this.getWineryDetails();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.wineryDetails != null &&
      prevProps.wineryDetails.id != this.props.wineryDetails.id
    ) {
      this.getWineryDetails();
    }
  }
  getWineryDetails = () => {
    if (this.props.wineryDetails !== null) {
      this.setState({
        wineryId: this.props.wineryDetails.id,
        wineryName: this.props.wineryDetails.displayName,
        wineryLogo: this.props.wineryDetails.logo,
        wineryDescription: this.props.wineryDetails.description,
        wineryMessage: this.props.wineryDetails.message,
      });
    }

    // // let consumerData = JSON.parse(localStorage.getItem("user_data"));
    // let wineryId = localStorage.getItem("winery_id");
    // // let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    // // let bearer = "Bearer " + signInToken;
    // let getWineryDetailsApiUrl =
    //   constants.WINERY_API +
    //   "/api/v1/public/winery/consumer?wineryId=" +
    //   wineryId;
    // axiosCall("GET", getWineryDetailsApiUrl)
    //   .then((res) => {
    //     if (res.statusText == "OK")
    //       this.setState({
    //         wineryName: res.data.displayName,
    //         wineryLogo: res.data.logo,
    //         wineryDescription: res.data.description,
    //         wineryMessage: res.data.message,
    //       });
    //     else {
    //       let apiErrorObj = {
    //         statusText: res.statusText,
    //         type: res.type,
    //         url: getWineryDetailsApiUrl,
    //         operation: "GET",
    //         status: res.status,
    //       };
    //       this.postCaughtException(JSON.stringify(apiErrorObj));
    //       throw Error(res.statusText);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // fetch(getWineryDetailsApiUrl, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: bearer,
    //   },
    // })
    //   .then((res) => {
    //     if (res.ok) {
    //       return res.json();
    //     } else {
    //       let apiErrorObj = {
    //         statusText: res.statusText,
    //         type: res.type,
    //         url: getWineryDetailsApiUrl,
    //         operation: "GET",
    //         status: res.status,
    //       };
    //       this.postCaughtException(JSON.stringify(apiErrorObj));
    //       throw Error(res.statusText);
    //     }
    //   })
    //   .then((json) => {
    //     //   if successful, update state
    //     // console.log(json);
    //     this.setState({
    //       wineryName: json.displayName,
    //       wineryLogo: json.logo,
    //       wineryDescription: json.description,
    //       wineryMessage: json.message,
    //     });
    //     // sessionStorage.setItem("wineryName", this.state.wineryName);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  profileIconClicked = () => {
    // document.getElementById("profile-icon").src = {userProfileBtn}
  };

  handleWineryDetailsClick = () => {
    this.setState({
      wineryDetailsClicked: !this.state.wineryDetailsClicked,
    });
  };

  openSwitchWinery = () => {
    this.props.history.push({
      pathname: "/wineries",
      // pathname: "/select-winery",
      state: {},
    });
  };

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  openChat = () => {
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    if (!signInToken) {
      this.setState({
        signInPopUp: true,
        url: "/chat/" + this.props.wineryDetails.is,
        where: "to Chat with our representatives.",
      });
    } else
      this.props.history.push({
        pathname: "/chat/" + this.props.wineryDetails.id,
        state: {},
      });
  };

  render() {
    if (this.props.searchWineActive) {
    } else if (this.props.chatActive) {
    } else if (this.props.availableWinesActive) {
    } else if (this.props.profileActive) {
    } else if (this.props.eventsActive) {
    } else {
    }
    const wineryBased = this.props.isWineryBased === "false" ? false : true;
    return (
      <React.Fragment>
        <SideNavBar
          closeMenu={this.closeMenu}
          showSideMenu={this.state.showSideMenu}
        ></SideNavBar>
        <div>
          <Nav variant="tabs" defaultActiveKey="/home" className="top-nav-main">
            <div className="hamburger-menu-top-nav">
              <MenuIcon
                style={{ fill: "white", fontSize: "25", cursor: "pointer" }}
                onClick={() => {
                  this.toggleMenu();
                }}
              ></MenuIcon>
            </div>
            <div className="nav-logo-container">
              <img
                src={pairAnythingLogo}
                alt="PA LOGO"
                className="pair-logo-top-nav"
                onClick={() => {
                  this.openHomePage();
                }}
              />
            </div>
            <Nav.Item>
              <Nav.Link
                style={{ marginRight: "2% !important" }}
                className="nav-link-top-nav"
                onClick={() =>
                  this.state.consumerWineryStatus === "disabled"
                    ? ""
                    : wineryBased === true
                    ? this.handleNavClick(
                        "/" +
                          convertToCamelCase(
                            localStorage.getItem("winery_name")
                          ) +
                          "/pairings",
                        "to view Pairings."
                      )
                    : // ? this.handleNavClick(
                      //     "/search-wine" + "/" + this.state.wineryId,
                      //     "to view Pairings."
                      //   )
                      this.handleNavClick(
                        "/pa-recommendations",
                        "to view Pairings."
                      )
                }
                // href={
                //   this.state.consumerWineryStatus == "disabled"
                //     ? ""
                //     : "/search-wine" + "/" + this.state.wineryId
                // }
              >
                <img
                  className={
                    this.state.consumerWineryStatus === "disabled"
                      ? "icon-style top-nav-icon-disabled"
                      : "icon-style"
                  }
                  alt="Recommendations"
                  src={
                    this.props.searchWineActive
                      ? pairAnythingSelectedLogoBtn
                      : pairAnythingNotSelectedLogoBtn
                  }
                />
                <div
                  className={
                    this.props.searchWineActive
                      ? "top-nav-title-selected"
                      : this.state.consumerWineryStatus == "disabled"
                      ? "top-nav-title-not-selected top-nav-icon-disabled"
                      : "top-nav-title-not-selected"
                  }
                >
                  Pairings
                </div>
              </Nav.Link>
            </Nav.Item>

            {!this.props.isWineryBased && (
              <React.Fragment>
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "2% !important" }}
                    className="nav-link-top-nav"
                    onClick={
                      () =>
                        this.state.consumerWineryStatus == "disabled"
                          ? ""
                          : this.handleNavClick(
                              "/" +
                                convertToCamelCase(
                                  localStorage.getItem("winery_name")
                                ) +
                                "/wines",
                              "Wines"
                            )
                      // : this.handleNavClick("/available-wines", "Wines")
                    }
                    // href={
                    //   this.state.consumerWineryStatus == "disabled"
                    //     ? ""
                    //     : "/available-wines"
                    // }
                    // eventKey="link-2"
                  >
                    <img
                      className={
                        this.state.consumerWineryStatus == "disabled"
                          ? "icon-style top-nav-icon-disabled"
                          : "icon-style"
                      }
                      alt="Wines"
                      //  src={wineGlassNotSelectedBtn}
                      src={
                        this.props.availableWinesActive
                          ? wineGlassSelectedBtn
                          : wineGlassNotSelectedBtn
                      }
                    />
                    <div
                      className={
                        this.props.availableWinesActive
                          ? "top-nav-title-selected"
                          : this.state.consumerWineryStatus == "disabled"
                          ? "top-nav-title-not-selected top-nav-icon-disabled"
                          : "top-nav-title-not-selected"
                      }
                    >
                      Wines
                    </div>
                  </Nav.Link>
                </Nav.Item>

                {/* 
                                          Commenting out profile and chat icons from the bottom nav
                                          Also the displayNotificationCount for now
                                   */}

                {/* <Nav.Item>
                                          <Nav.Link className="nav-link-bottom-nav" href = "/wine-news-feed" eventKey="link-4">
                                              <img className="dashboard-btn" 
                                        
                                              // src = { this.props.wineEducationActive? archiveSelectedBtn : archiveNotSelectedBtn } />
                                                 src = { this.props.wineNewsFeedActive ? dashboardSelectedBtn : dashboardNotSelectedBtn } /> 
                                              />
                                          </Nav.Link>
                                      </Nav.Item> */}

                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "2% !important" }}
                    className="nav-link-top-nav"
                    // eventKey="link-2"
                    onClick={() =>
                      this.state.consumerWineryStatus == "disabled"
                        ? ""
                        : this.handleNavClick(
                            "/chat" + "/" + this.props.wineryDetails.id,
                            "to Chat with one of our representatives."
                          )
                    }
                  >
                    <img
                      className={
                        this.state.consumerWineryStatus == "disabled"
                          ? "icon-style top-nav-icon-disabled"
                          : "icon-style"
                      }
                      alt="Chat"
                      src={
                        this.props.chatActive
                          ? chatBtnSelected
                          : chatBtnNotSelected
                      }
                    />
                    <div
                      className={
                        this.props.chatActive
                          ? "top-nav-title-selected"
                          : this.state.consumerWineryStatus == "disabled"
                          ? "top-nav-title-not-selected top-nav-icon-disabled"
                          : "top-nav-title-not-selected"
                      }
                    >
                      Chat
                    </div>
                  </Nav.Link>
                </Nav.Item>

                {/* <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "2% !important" }}
                    className="nav-link-top-nav"
                    // eventKey="link-3"
                    onClick={() =>
                      this.state.consumerWineryStatus == "disabled"
                        ? ""
                        : this.handleNavClick("/events", "to view Events.")
                    }
                  >
                    <img
                      className={
                        this.state.consumerWineryStatus === "disabled"
                          ? "icon-style top-nav-icon-disabled"
                          : "icon-style"
                      }
                      alt="Events"
                      src={
                        this.props.eventsActive
                          ? eventsSelected
                          : eventsNotSelected
                      }
                    />
                    <div
                      className={
                        this.props.eventsActive
                          ? "top-nav-title-selected"
                          : this.state.consumerWineryStatus == "disabled"
                          ? "top-nav-title-not-selected top-nav-icon-disabled"
                          : "top-nav-title-not-selected"
                      }
                    >
                      Events
                    </div>
                  </Nav.Link>
                </Nav.Item> */}

                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "2% !important" }}
                    className="nav-link-top-nav"
                    onClick={() =>
                      this.handleNavClick("/profile", "to view your Profile.")
                    }
                    // eventKey="link-5"
                  >
                    <img
                      onClick={this.profileIconClicked.bind(this)}
                      id="profile-icon"
                      className="icon-style"
                      alt="Profile"
                      src={
                        this.props.profileActive
                          ? userProfileSelectedBtn
                          : userProfileNotSelectedBtn
                      }
                    />
                    <div
                      className={
                        this.props.profileActive
                          ? "top-nav-title-selected"
                          : "top-nav-title-not-selected"
                      }
                    >
                      More
                    </div>
                  </Nav.Link>

                  {/* <Nav.Link eventKey="link-4" disabled>
                                      Disabled
                                      </Nav.Link> */}
                </Nav.Item>
              </React.Fragment>
            )}
            {!this.props.isWineryBased && this.props.wineryDetails != null ? (
              <div
                className="winery-details-top-nav"
                onClick={() => {
                  this.handleWineryDetailsClick();
                }}
              >
                <div className="winery-name-container-top-nav">
                  {this.props.wineryDetails.displayName}
                </div>
                {this.props.wineryDetails.logo && (
                  <div className="winery-logo-container-top-nav">
                    <img
                      src={this.props.wineryDetails.logo}
                      className="winery-logo-top-nav"
                      alt="wineryLogo"
                    />
                  </div>
                )}
                <img src={moreIcon} className="ptr-clss" alt="More Icon" />
              </div>
            ) : null}
          </Nav>
          {this.state.wineryDetailsClicked && !this.props.isWineryBased && (
            <div className="more-winery-details-top-nav-container">
              <div className="more-winery-details-top-nav">
                {this.props.wineryDetails.logo && (
                  <div className="winery-logo-container-top-nav-popup">
                    <img
                      src={this.props.wineryDetails.logo}
                      className="winery-logo-top-nav"
                      alt="wineryLogo"
                    />
                  </div>
                )}

                <div className="winery-text-content-container">
                  <div className="winery-name-popup">
                    {this.props.wineryDetails.displayName}
                  </div>
                  <div className="winery-message-popup">
                    {this.props.wineryDetails.description}
                  </div>
                  <div className="popup-icons-container">
                    <div
                      className="chat-logo-popup-container"
                      onClick={() => {
                        this.openChat();
                      }}
                    >
                      <img
                        src={popupChatIcon}
                        className="chat-logo-popup ptr-clss"
                        alt="Chat Logo"
                      />
                    </div>
                    <div
                      className="switch-winery-logo-popup-container"
                      onClick={() => {
                        this.openSwitchWinery();
                      }}
                    >
                      <img
                        src={popupSwitchWineryIcon}
                        className="switch-winery-logo-popup ptr-clss"
                        alt="Switch Winery Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <SignInToContinue
          handleClose={this.handlePopupClose}
          signInPopUp={this.state.signInPopUp}
          where={this.state.where}
          url={this.state.url}
        />
      </React.Fragment>
    );
  }
  handlePopupClose = () => {
    this.setState({ signInPopUp: false });
  };
  handleNavClick = (url, where) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    if (!signInToken && where != "Wines") {
      this.setState({ signInPopUp: true, url, where });
    } else this.props.history.push(url);
  };

  toggleMenu = () => {
    this.setState({
      showSideMenu: !this.state.showSideMenu,
    });
  };
  closeMenu = () => {
    this.setState({
      showSideMenu: false,
    });
  };
}
const mapStateToProps = (state) => {
  return { wineryDetails: state.WineryReducer.currentWineryDetails };
};
TopNavigation = withRouter(TopNavigation);
export default connect(mapStateToProps, null)(TopNavigation);
