import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";
import { logPagesVisitedByUser } from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./login.module.scss";
import "./signup.css";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import Header from "../../components/CommonComponents/header";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import moment from "moment";
import * as constants from "../../constants/constants";
import { get, post } from "../../lib/fetch";
import useSWR, { preload } from "swr";
import { useFormik } from "formik";

import LayoutContents from "../../components/CommonComponents/layout-contents";

export default function Login() {
  const history = useHistory();

  useEffect(() => {
    logPagesVisitedByUser();
  }, []);

  // ========== Set up formik ==============================

  const [formSubmittable, setFormSubmittable] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    console.log(formik.errors, formik.values);
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik]);

  // oooooooooo OnClick functions oooooooooooooooooooooooooooooooo

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  // WIP
  const [serverError, setServerError] = useState(undefined);

  const submitForm = async () => {
    if (formSubmittable) {
      let loginData = {
        username: formik.values.email,
        password: formik.values.password,
      };

      //console.log(signupData)

      try {
        const response = await post(
          `${constants.IDENTITY_API}/auth/v1/login`,
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          loginData
        );
        const result = await response.json();

        if (result?.errors) {
          setServerError(result.errors[0].message);
        } else if (result?.message?.message) {
          setServerError(result.message?.message);
        } else if (result?.message) {
          setServerError(result.message);
        }
        console.log(result);

        if (!!result?.token) {
          const url = window.localStorage.getItem("winery_url");
          localStorage.clear();
          localStorage.setItem("signin_token", JSON.stringify(result?.token));
          localStorage.setItem("user_data", JSON.stringify(result?.user));
          localStorage.setItem("user_id", JSON.stringify(result?.user?.id));
          localStorage.setItem(
            "reward_points",
            JSON.stringify(result?.rewardPoints)
          );
          localStorage.setItem("ageConfirmed", true);

          if (result?.user?.userStoreZip) {
            if (
              result?.user?.userStoreZip !== null &&
              result?.user?.userStoreZip !== ""
            ) {
              ReactGA.event({
                action: "Login",
                category: "BrandAmbassador",
                label: "Brand Ambassador Logged In",
              });
            }
          }

          if (url) {
            history.push(`/${JSON.parse(url)}`);
          } else {
            history.push({
              pathname: `/welcome`,
            });
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <Layout>
      <LayoutContents>
        <PAHeader center />
        <div className={`${styles.mainContainer}`}>
          <div className={`${styles.mainContainerSection}`}>
            <h1 className={`h1-30`}>Log In</h1>

            <form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              className={`${styles.formContainer}`}
            >
              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="email">
                  Email Address<span className={styles.asterisk}>*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className={`${styles.formField}`}>
                <label className={`p1-bold`} htmlFor="firstName">
                  Password<span className={styles.asterisk}>*</span>
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`${styles.formInput} p1-reg`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className={styles.formFieldError}>
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              {/* <button type="submit">Submit</button> */}
            </form>
            <div
              className={styles.mainContainerSection}
              style={{ gap: "16px" }}
            >
              {!!serverError ? (
                <div className={styles.serverErrorBox}>{serverError}</div>
              ) : (
                <></>
              )}
              <button
                className={`${styles.submitBtn} ${
                  !formSubmittable ? styles.grayedOut : null
                }`}
                onClick={() => submitForm()}
              >
                Log In
              </button>
              <div className={styles.loginBox}>
                <p className={`p1-reg`}>
                  Don't have an account?{" "}
                  <span
                    className={styles.loginTxt}
                    onClick={() =>
                      history.push({
                        pathname: `/signup/setup`,
                      })
                    }
                  >
                    Sign Up
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </LayoutContents>
    </Layout>
  );
}
