import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import styles from "./virtual-wine-tasting.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import * as constants from "../../constants/constants";
import { sessionUrl } from "../../lib/common/sessionUrl";
import { post } from "../../lib/fetch";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function VirtualWineTasting() {
  // ========== Set up formik ==============================

  const [formSubmittable, setFormSubmittable] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [startDate1, setStartDate1] = useState(undefined);
  const [startDate2, setStartDate2] = useState(undefined);
  const [eventTime1, setEventTime1] = useState(undefined);
  const [eventTime2, setEventTime2] = useState(undefined);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.contact_name) {
      errors.contact_name = "Required";
    }

    if (!values.phone) {
      errors.phone = "Required";
    }

    if (!values.budget) {
      errors.budget = "Required";
    }

    if (!values.participant_locations) {
      errors.participant_locations = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      contact_name: "",
      email: "",
      phone: "",
      company: null,
      budget: "",
      participant_locations: "",
      experience: null,
      info: null,
    },
    validate,
    onSubmit: (values) => submitForm(values),
  });

  useEffect(() => {
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik]);

  const submitForm = async (values) => {
    if (formSubmittable) {
      let utc1,
        utc2 = null;

      if (startDate1 && eventTime1) {
        let date1 = startDate1;
        date1.setHours(eventTime1.getHours());
        date1.setMinutes(eventTime1.getMinutes());
        date1.setSeconds(eventTime1.getSeconds());
        utc1 = Date.UTC(
          date1.getUTCFullYear(),
          date1.getUTCMonth(),
          date1.getUTCDate(),
          date1.getUTCHours(),
          date1.getUTCMinutes(),
          date1.getUTCSeconds()
        );
      }

      if (startDate2 && eventTime2) {
        let date2 = startDate2;
        date2.setHours(eventTime2.getHours());
        date2.setMinutes(eventTime2.getMinutes());
        date2.setSeconds(eventTime2.getSeconds());
        utc2 = Date.UTC(
          date2.getUTCFullYear(),
          date2.getUTCMonth(),
          date2.getUTCDate(),
          date2.getUTCHours(),
          date2.getUTCMinutes(),
          date2.getUTCSeconds()
        );
      }

      let vtRequestBody = {
        contact_name: values.contact_name,
        email: values.email,
        phone: values.phone,
        company: values.company,
        event_date_1: utc1,
        event_date_2: utc2,
        budget: values.budget,
        participant_locations: values.participant_locations,
        experience: values.experience,
        info: values.info,
      };

      let url = `${constants.IDENTITY_API}/api/v1/public/support/virtualTasting`;

      try {
        const response = await post(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          vtRequestBody
        );

        setFormSubmitted(true)

        const result = await response.json();
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className={styles.main}>
      {!formSubmitted ? (
        <>
          <div className={styles.heading}>
            <h1 className={`h1-30`}>Virtual Tasting Experience</h1>
            <p className={`p1-reg`} style={{ paddingTop: "2rem" }}>
              Let us know more about the virtual tasting experience you would
              like.
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className={`${styles.formContainer}`}
          >
            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="contact_name">
                Primary Contact Name<span className={styles.asterisk}>*</span>
              </label>
              <input
                id="contact_name"
                name="contact_name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contact_name}
                className={`${styles.formInput} p1-reg`}
              />
              {formik.touched.contact_name && formik.errors.contact_name ? (
                <div className={styles.formFieldError}>
                  {formik.errors.contact_name}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="email">
                Email<span className={styles.asterisk}>*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={`${styles.formInput} p1-reg`}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={styles.formFieldError}>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="phone">
                Phone Number<span className={styles.asterisk}>*</span>
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                className={`${styles.formInput} p1-reg`}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className={styles.formFieldError}>
                  {formik.errors.phone}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="company">
                Company Name
              </label>
              <input
                id="company"
                name="company"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company}
                className={`${styles.formInput} p1-reg`}
              />
            </div>

            <div className={`${styles.formFieldDatePicker}`}>
              <label className={`p1-bold`} htmlFor="event_date_1">
                Desired Event Date/Time - 1st choice
              </label>

              <div style={{ display: "flex" }}>
                <ReactDatePicker
                  id="event_date_1"
                  selected={startDate1}
                  onChange={(date) => setStartDate1(date)}
                  placeholderText="-- Select Date --"
                />
                <ReactDatePicker
                  id="event_time_1"
                  selected={eventTime1}
                  onChange={(time) => setEventTime1(time)}
                  placeholderText="-- Select Time --"
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                />
              </div>
            </div>

            <div className={`${styles.formFieldDatePicker}`}>
              <label className={`p1-bold`} htmlFor="event_date_1">
                Desired Event Date/Time - 2nd choice
              </label>
              <div style={{ display: "flex" }}>
                <ReactDatePicker
                  id="event_date_2"
                  selected={startDate2}
                  onChange={(date) => setStartDate2(date)}
                  placeholderText="-- Select Date --"
                />
                <ReactDatePicker
                  id="event_time_2"
                  selected={eventTime2}
                  onChange={(time) => setEventTime2(time)}
                  placeholderText="-- Select Time --"
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                />
              </div>
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="budget">
                Budget for each wine shipment
                <span className={styles.asterisk}>*</span>
              </label>
              <label className={`p1-reg`} htmlFor="budget">
                Tasting kits start at $125. Enter “Ukraine Event” if you are the
                winning bidder of the auction prize.
              </label>

              <input
                id="budget"
                name="budget"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.budget}
                className={`${styles.formInput} p1-reg`}
              />
              {formik.touched.budget && formik.errors.budget ? (
                <div className={styles.formFieldError}>
                  {formik.errors.budget}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="participant_locations">
                Number of tasting event participants and their locations
                <span className={styles.asterisk}>*</span>
              </label>
              <label className={`p1-reg`} htmlFor="participant_locations">
                Enter the number of participating households and the City/State
                destination where the wines will be shipped.
              </label>

              <textarea
                placeholder=""
                id="participant_locations"
                name="participant_locations"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.improvements}
                className={`${styles.formInputTextArea} p1-reg`}
              />
              {formik.touched.improvements && formik.errors.improvements ? (
                <div className={styles.formFieldError}>
                  {formik.errors.improvements}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="experience">
                Level of Wine Experience
              </label>
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="experience"
                name="experience"
                style={{ padding: "0 1rem" }}
              >
                <option selected value={0}></option>
                <option value={1}>Newbie</option>
                <option value={2}>
                  Casual drinker - I would love to learn more
                </option>
                <option value={3}>
                  I can order confidently at a restaurant
                </option>
                <option value={4}>I know how to pronouce sommelier</option>
              </select>
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="info">
                Share any additional information to help us make your event the
                best ever.
              </label>

              <textarea
                placeholder=""
                id="info"
                name="info"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.info}
                className={`${styles.formInputTextArea} p1-reg`}
              />
            </div>

            <button
              className={`${styles.submitBtn} ${
                !formSubmittable ? styles.grayedOut : null
              }`}
              type="submit"
            >
              Submit
            </button>

            {/* <button type="submit">Submit</button> */}
          </form>
        </>
      ) : (
        <div className={styles.heading}>
          <h1 className={`h1-30`}>Thanks for your Submission!</h1>
          <p className={`p1-reg`} style={{ paddingTop: "2rem" }}>
            We will get back to you shortly
          </p>
        </div>
      )}
    </div>
  );
}
