import * as types from "../actions/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case types.ADD_TO_CART_DUMMY:
      return {
        ...state,
        addToCartSuccess: action.payload,
      };
    case types.ADD_TO_CART_SUCCEED:
      return {
        ...state,
        addToCartSuccess: action.payload,
      };

    case types.ADD_TO_CART_FAILED:
      return {
        ...state,
        addToCartFail: action.payload,
      };

    default:
      return state;
  }
};
