import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./see-all-wines.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import ArrowRight from "../../../assets/images/PAIcons/circle-arrow-right.svg";
import ChevronLeft from "../../../assets/images/PAIcons/chevron-left.svg";
import TargetLogo from "../../../assets/images/PALogos/target-logo.png";
import "react-phone-number-input/style.css";
import { post, get } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import { formatCurrency } from "../../lib/common/convertCurrency";
import WineCard from "../../components/TargetComponents/WineCard/WineCard";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";

export default function SeeAllWines() {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  //state from previous page
  const { varietalName } = useParams();
  const history = useHistory();
  const food = history.location.state?.food;
  const goBack = history.location.state?.goBack;
  const from = history.location.state?.from;
  const returnPage = history.location.state?.returnPage;
  const [pairingResultUrl, setPairingResultUrl] = useState(null);


  console.log(`goBack:`);
  console.log(goBack);
  console.log(food);
  console.log(varietalName);

  useEffect(() => {
    logPagesVisitedByUser();

    //if we were passed a state from previous page use that to make api call
    if (food != null) {
      setPairingResultUrl(
        `${constants.GOOGLE_SEARCH_API}/api/v1/public/varietalList/getVarietalResultByFood/${food}`
      );
    }
  }, []);

  //fetching the pairing results as [{varietal, wines}]
  const [pairingResultLoaded, setPairingResultLoaded] = useState(false);

  const { data: pairingResult } = useSWR(pairingResultUrl, (u) =>
    get(u).then((res) => res.json())
  );

  const [wines, setWines] = useState(undefined);

  useEffect(() => {
    if (pairingResult) {
      setPairingResultLoaded(true);

      setWines(
        pairingResult
          .filter((result) => {
            return result?.varietal?.varietal == varietalName;
          })[0]
          ?.wines.filter((wine) => {
            return (
              wine.wineryId == 405
            );
          })
      );
    }
  }, [pairingResult]);

  const clickBack = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/pairing-results/${goBack}`,
      state: { from: "left" },
    });
  };

  return (
    <Layout white>
      <AnimationWrapper animation={from == "left" ? "fromLeft" : "fromRight"}>
        <LayoutContents>
          <PAHeader left />
          <div className={`${styles.mainContainer}`}>
            <button
              className={styles.backToResultBtn}
              onClick={(e) => clickBack(e)}
            >
              <img src={ChevronLeft} alt="chevron left" />
              <p className={`p1-reg ${styles.backToResultBtnTxt}`}>
                Back to pairing result
              </p>
            </button>
            <div>
              {wines && wines != undefined ? (
                <WinesGrid
                  wines={wines}
                  varietalName={varietalName}
                  food={food}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}

const WinesGrid = ({ wines, varietalName, food }) => {
  const history = useHistory();
  const clickSeeAll = () => {
    window.open(`https://target.com/s?searchTerm=${varietalName}`, "_blank");
  };

  return (
    <>
      <div className={styles.getBox}>
        <div className={`${styles.getAtTarget}`}>Get it at Target</div>
        <img className={styles.targetLogo} src={TargetLogo} alt="target logo" />
      </div>

      <h1 className={`h1-30 ${styles.header}`}>{varietalName}</h1>
      <div className={styles.wineGrid}>
        {wines.map((wine, idx) => (
          <WineCard
            key={idx}
            wine={wine}
            idx={idx}
            food={food}
            returnPage={history.location.pathname}
            justify={idx % 2 == 1}
          />
        ))}
        <div
          className={styles.seeAllCard}
          onClick={() => clickSeeAll()}
        >
          <img
            className={styles.rightArrow}
            src={ArrowRight}
            alt="Arrow facing right"
          />
          <div className={styles.seeAllCardText}>See All at Target</div>
        </div>
      </div>
    </>
  );
};
