import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/images/splash.png";
import "./AgeConfirmation.scss";
const AgeConfirmation = ({ redirectTo }) => {
  let location = useLocation();
  let history = useHistory();

  const confirmedAge = () => {
    localStorage.setItem("ageConfirmed", true);
    if (redirectTo) history.push(redirectTo);
    else history.push("/discover");
  };
  return (
    <React.Fragment>
      <div className="success-main-container font-family">
        <div className="success-inner-container new-discover-inner-container">
          <div className="header-container">
            <div className="success-logo-container new-discover-logo-container">
              <img alt="PairAnything" className="logo-wwidth" src={logo} />
            </div>
          </div>
          <div className="age-container outer-container-div bg-none new-discover-outer-container-div">
            <div className="age-text thanks-fonts-style">
              <div className="age-confirm-head">
                <span>Welcome to PairAnything!</span>
              </div>
              <div className="age-confirmation-container">
                <p className="order-success-txt ft-500">
                  {" "}
                  By clicking on the Submit button you verify that you are 21
                  years of age or older and agree to our{" "}
                  <Link to="/terms-of-use">Terms of Use </Link> and
                  <Link to="/privacy-policy"> Privacy Policy.</Link>
                </p>
                <button
                  className="btn btn-round age-confirm-button "
                  onClick={() => {
                    confirmedAge();
                  }}
                >
                  Submit
                </button>
                {/* <p style={{ fontSize: 10 }}>Please confirm to continue</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AgeConfirmation;
