export const AGGIES_UNCORKED = [
    {
        id: 1,
        wineName: 'Sans Wine Co.',
        winerUrl: 'http://www.sanswineco.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Sans_Wine_Co.png')
    },
    {
        id: 2,
        wineName: 'Dashe Cellars',
        winerUrl: 'https://www.dashecellars.com',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Dashe_Cellars.png')
    },
    {
        id: 3,
        wineName: 'Schramsberg Vineyards',
        winerUrl: 'http://www.schramsberg.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Schramsberg_Vineyards.jpeg')
    },
    {
        id: 4,
        wineName: 'Heringer Estates',
        winerUrl: 'http://www.heringerestates.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Heringer_Estate.jpg')
    },
    {
        id: 5,
        wineName: 'brick & mortar wines',
        winerUrl: 'https://www.brickandmortarwines.com',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/brick_mortar_wines.jpg')
    },
    {
        id: 6,
        wineName: 'Mikami Vineyards',
        winerUrl: 'https://mikamivineyards.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Mikami_Vineyards.png')
    },
    {
        id: 7,
        wineName: 'Flying Goat Cellars',
        winerUrl: 'http://www.flyinggoatcellars.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Flying_Goat_Cellars.jpg')
    },
    {
        id: 8,
        wineName: 'Sill Family Vineyards',
        winerUrl: 'http://sillfamilyvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Sill_Family_Vineyards.png')
    },

    {
        id: 10,
        wineName: 'Andis Wines',
        winerUrl: 'https://www.andiswines.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Andis_Wines.png')
    },
    {
        id: 11,
        wineName: 'Mirror Wines',
        winerUrl: 'https://www.mirrorwine.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Mirror_Wines.jpg')
    },
    {
        id: 12,
        wineName: 'J. Lohr Vineyards and Wines',
        winerUrl: 'http://www.jlohr.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/JLohr_Vineyards.png')
    },
    {
        id: 13,
        wineName: 'Pazienza by Mark Edward',
        winerUrl: 'https://www.markedwardwines.com',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Pazienza.jpg')
    },
    {
        id: 15,
        wineName: 'Ceja Vineyards',
        winerUrl: 'http://www.cejavineyards.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Ceja_Vineyards.jpg')
    },
    {
        id: 16,
        wineName: 'Gloria Ferrer',
        winerUrl: 'http://www.gloriaferrer.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Gloria_Ferrer.jpg')
    },
    {
        id: 17,
        wineName: 'Schug Carneros Estate Winery',
        winerUrl: 'https://schugwinery.com',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Schug_Carneros Estate_Winery.png')
    },
    {
        id: 18,
        wineName: 'Bucher Wines',
        winerUrl: 'https://bucher.wine',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Bucher_Wines.png')
    },
    {
        id: 19,
        wineName: 'Matchbook ',
        winerUrl: 'https://www.matchbookwines.com/',
        wineImage: require('../../../assets/images/Visit/Aggies_Uncorked/Matchbook_Wines.png')
    },

]