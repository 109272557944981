import React from "react";

export default function MapPin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
    >
      <g opacity="0.5">
        <path
          d="M13 7.16602C13 11.8327 7 15.8327 7 15.8327C7 15.8327 1 11.8327 1 7.16602C1 5.57472 1.63214 4.04859 2.75736 2.92337C3.88258 1.79816 5.4087 1.16602 7 1.16602C8.5913 1.16602 10.1174 1.79816 11.2426 2.92337C12.3679 4.04859 13 5.57472 13 7.16602Z"
          stroke="#7D7D7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.99902 9.16602C8.10359 9.16602 8.99902 8.27059 8.99902 7.16602C8.99902 6.06145 8.10359 5.16602 6.99902 5.16602C5.89445 5.16602 4.99902 6.06145 4.99902 7.16602C4.99902 8.27059 5.89445 9.16602 6.99902 9.16602Z"
          stroke="#7D7D7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
