import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.GET_EVENT_DETAIL_DUMMY:
            return {
                ...state,
                getEventDetailSuccess: action.payload,
            };
        case types.GET_EVENT_DETAIL_SUCCESS:
            return {
                ...state,
                getEventDetailSuccess: action.payload
            };

        case types.GET_EVENT_DETAIL_FAILED:
            return {
                ...state,
                getEventDetailFail: action.payload
            };

        default:
            return state;
    }
};