import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./RecommendedWinesDesktop.scss";

import profileCartBtn from "../../../assets/images/profile-cart.svg";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";

import recommendationResponseImage from "../../../assets/images/EmptyPageCard/recommendation-response-image.svg";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import * as constants from "../../constants/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import WineDetails from "../../components/WineDetails/WineDetails";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import PageNotFound from "../PageNotFound/PageNotFound";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import { withRouter } from "react-router-dom";
import CartIconComponent from "../../components/CartIcon/CartIcon";
import * as commonFunctions from "../../constants/commonFunctions";
import { connect } from "react-redux";

toast.configure();

let wineList = [1, 2, 3, 4, 5];
class RecommendedWinesDesktop extends Component {
  intervalId = 0;

  constructor(props) {
    super(props);
    this.state = {
      backBtnStatus: false,
      responseForQuestion: "",
      wineryDetails: "",
      errorOccurred: "",
      errorMessage: "",
      questionId: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.questionId !== nextProps.questionId) {
      return {
        questionId: nextProps.questionId,
      };
      // this.getResponseForQuestionFromWineryAdmin();
    } else {
      return null;
    }
  }

  componentDidMount = () => {
    ///
    let questionId;

    // try {
    questionId = this.props.questionId;
    // } catch (e) {
    //   questionId = this.props.questionId;
    // }
    if (this.props.isWineryBased === true) {
      let wineryIdFromUrl = JSON.parse(localStorage.getItem("winery_id"));

      let wineryList = JSON.parse(localStorage.getItem("winery_list"));

      // let wineryIdValidity = 0;
      // for (let i = 0; i < wineryList.length; ++i) {
      //   if (
      //     wineryIdFromUrl == wineryList[i].wineryId
      //     // &&
      //     // wineryList[i].wineryUserStatus == 2
      //   ) {
      //     // wineruuserstatus is enabled
      //     wineryIdValidity++;
      //   }
      // }

      // if (wineryIdValidity != 0) {
      //   // valid winery ID URL param
      //   // user might have clicked on notif because for some other winery
      //   let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      //   if (wineryIdFromUrl == wineryId) {
      //   } else {
      //     localStorage.setItem("winery_id", wineryIdFromUrl);
      //     window.location.reload();
      //   }
      // }

      ///

      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      this.setState({
        wineryId: wineryId,
      });

      this.getWineryDetailsFromWineryId(); // to display winery name

      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      let wineryName = localStorage.getItem("winery_name");
      // if (wineryIdValidity == 0) {
      //   // invalid Winery Id in the URL. User might have tried to change URL
      //   this.setState({
      //     errorOccurred: "User might have changed URL",
      //     errorMessage: "You do not have access to this page.",
      //   });
      // } else
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "getRecommendationHistory",
          errorMessage:
            "Your email is no longer associated with " +
            wineryName +
            " winery, please contact the winery to gain access again.",
        });
      } else {
        this.setState({
          questionId: questionId,
        });
        this.getResponseForQuestionFromWineryAdmin(questionId);
      }
    }

    this.setState({
      questionId: questionId,
    });
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  getWineryDetailsFromWineryId = () => {
    this.setState({ wineryDetails: this.props.wineryDetails });
    // let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    // let getWineryDetailsFromWineryIdApiUrl =
    //   constants.WINERY_API + "/api/v1/public/winery/" + wineryId;

    // let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    // let bearer = "Bearer " + signInToken;

    // fetch(getWineryDetailsFromWineryIdApiUrl, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: bearer,
    //   },
    // })
    //   .then((res) => {
    //     if (res.ok) {
    //       return res.json();
    //     } else {
    //       let apiErrorObj = {
    //         statusText: res.statusText,
    //         type: res.type,
    //         status: res.status,
    //         url: getWineryDetailsFromWineryIdApiUrl,
    //         operation: "GET",
    //         status: res.status,
    //       };

    //       this.postCaughtException(JSON.stringify(apiErrorObj));

    //       throw Error(res.statusText);
    //     }
    //   })
    //   .then((json) => {
    //     this.setState({
    //       wineryDetails: json,
    //     });
    //   })
    //   .catch((error) => console.error(error));
  };

  displayDishNameFromSearch = () => {
    let result = [];
    if (
      this.props.location.state.dishName != null &&
      this.props.location.state.dishName != undefined
    ) {
      result.push(
        //  CSS from SearchWineForDish.scss
        <div className="recommendation-dish-name-container">
          <div className="recommendation-dish-name form-control search-wine-form-row-input1 shadow">
            <span className="search-wine-button">
              {/* { this.props.location.state.dishName } */}
              {this.state.responseForQuestion.question}
            </span>
          </div>
        </div>
      );
    }

    return result;
  };

  gotoSearchPage = () => {
    this.setState({
      backBtnStatus: true,
    });
    // this.props.history.goBack();
  };

  getResponseForQuestionFromWineryAdmin = (questionId) => {
    if (this.props.questionId) {
      let wineryIdForGetResponse = this.props.wineryDetails.id;

      // let getResponseForQuestionApiUrl =  constants.IDENTITY_API + "/api/v1/recommendation/question/" + questionId ;
      {
        let getResponseForQuestionApiUrl =
          constants.IDENTITY_API +
          "/api/v1/recommendation?questionId=" +
          this.state.questionId +
          "&wineryId=" +
          wineryIdForGetResponse;

        let signInToken = JSON.parse(localStorage.getItem("signin_token"));
        let bearer = "Bearer " + signInToken;

        fetch(getResponseForQuestionApiUrl, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: bearer,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              let apiErrorObj = {
                statusText: res.statusText,
                type: res.type,
                status: res.status,
                url: getResponseForQuestionApiUrl,
                operation: "GET",
                status: res.status,
              };

              this.postCaughtException(JSON.stringify(apiErrorObj));

              this.setState({
                errorOccurred: "getResponseForQuestion",
                errorMessage:
                  "Could not fetch your recommendations for this dish. Something went wrong. Please try again later",
              });

              throw Error(res.statusText);
            }
          })
          .then((json) => {
            //   if successful, update state

            this.setState({
              responseForQuestion: json,
            });
            if (json.recommendation == null) {
              clearInterval(this.intervalId);
              this.intervalId = setInterval(
                (e) => this.getResponseForQuestionFromWineryAdmin(questionId),
                5000
              );
            } else {
              this.props.afterRecommendationResponse(json);
              if (this.intervalId != 0) {
                clearInterval(this.intervalId);
              }
            }
          })
          .catch((error) => console.error(error));
      }
    }
  };

  renderRecommendationDataBasedOnHistory = () => {
    let result = [];

    if (this.state.errorOccurred == "getResponseForQuestion") {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    }
    // if(this.props.location.state.recommendationHistoryData) {
    else if (this.state.responseForQuestion != "") {
      // let questionAttended = this.props.location.state.recommendationHistoryData.isAttended;

      // if(questionAttended) { // if response is there for question ID, get response data

      if (this.state.responseForQuestion.recommendation) {
        // if(this.state.responseForQuestion.recommendation) {

        // this.getResponseForQuestionFromWineryAdmin(questionId);

        let wineList = [];
        let data = this.state.responseForQuestion.recommendation;
        if (data != null) {
          wineList = this.state.responseForQuestion.recommendation;

          result.push(
            <div
              className="recommendations-response-container-desktop"
              key="response container"
            >
              {/* <div> */}
              {/* Cart Icon with total quantity badge */}
              <CartIconComponent
                fromRecommendedPage={true}
                fromAvailableWinesPage={false}
              />

              <span className="recommendations-response-text-desktop">
                {" "}
                Here is your personalized wine recommendation for your
                {/* from
                <span className="recommendations-winery-name">
                                    {this.state.wineryDetails.displayName}
                                </span>
                for your */}
                <span className="recommendations-dish-name">
                  {/* {this.props.location.state.recommendationHistoryData.question ? 
                                                this.props.location.state.recommendationHistoryData.question : '' } */}
                  {this.state.responseForQuestion.question}.
                </span>
              </span>

              {this.state.responseForQuestion.recommendationNote != undefined &&
              this.state.responseForQuestion.recommendationNote != null &&
              this.state.responseForQuestion.recommendationNote != "" ? (
                <span className="recommendations-pairing-note">
                  {"Pairing Note: " +
                    this.state.responseForQuestion.recommendationNote}
                </span>
              ) : null}
              <ErrorBoundary>
                <div className="recommended-wines-result-container">
                  {wineList.map((el, index) => (
                    <div className="wine-list-desktop" key={index}>
                      <WineDetails
                        questionIdFromRecommendationHistory={
                          this.state.questionId
                        }
                        wineDataFromRecommendedHistory={
                          this.state.responseForQuestion.recommendation[index]
                        }
                        id={index}
                        key={index}
                        isWineryBased={this.props.isWineryBased}
                      />
                    </div>
                    // wineList[index].message=="This wine is not available anymore" ?
                  ))}
                </div>
              </ErrorBoundary>
            </div>
          );
        }
      } else {
        let emptyCardData;
        if (this.state.responseForQuestion.isExcluded) {
          emptyCardData = {
            title: "",
            text: `We don't recognize '${commonFunctions.capitalize(
              this.state.responseForQuestion.question
            )}' as a food item. Please enter a different search.`,
            image: "",
          };
        } else {
          emptyCardData = {
            title: "",
            text: "Pairing is in progress… kindly give us a moment to get your recommendation.",
            image: recommendationResponseImage,
          };
        }
        return (
          <div className="empty-page-main-container mt-0">
            <EmptyPageCard emptyPageCardData={emptyCardData} />
          </div>
        );
      }
    } else if (this.state.responseForQuestion == "") {
      // let questionId = this.props.location.state.questionId!=null && this.props.location.state.questionId!=undefined ?
      // this.props.location.state.questionId : questionIdFromUrl;
      let questionId;
      try {
        questionId = this.props.questionId;
      } catch (e) {
        questionId = this.props.match.params.questionId;
      }
      this.getResponseForQuestionFromWineryAdmin(questionId);
    }

    return result;
  };

  renderRecommendationBasedOnStatus = () => {
    let result = [];
    let i = 0;
    result.push(
      <div key={i++} className="recommendations-container-desktop">
        {this.renderRecommendationDataBasedOnHistory()}
      </div>
    );
    return result;
  };

  renderRecommendationwineStatus = () => {
    let result = [];

    if (this.state.errorOccurred == "getResponseForQuestion") {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.props.wineResult !== null) {
      if (this.props.tab == true) {
        let emptyCardData = {
          title: "",
          text: "Pairing is in progress… kindly give us a moment to get your recommendation.",
          image: recommendationResponseImage,
        };
        result.push(
          <div className="empty-page-main-container mt-0">
            <EmptyPageCard emptyPageCardData={emptyCardData} />
          </div>
        );
      } else {
        let wineList = [];
        let data = this.props.wineResult;
        if (data != null) {
          wineList = this.props.wineResult;
          result.push(
            <div
              className="recommendations-response-container-desktop"
              key="response container"
            >
              <ErrorBoundary>
                <div className="recommended-wines-result-container">
                  {/* {wineList.map((el, index) => ( */}
                  <div className="wine-list-desktop">
                    <WineDetails
                      winetabDataFromRecommendedHistory={this.props.wineResult}
                      isWineryBased={this.props.isWineryBased}
                      // id={index}
                      // key={index}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </ErrorBoundary>
            </div>
          );
        }
      }
    } else {
      let emptyCardData = {
        title: "",
        text: "Pairing is in progress… kindly give us a moment to get your recommendation.",
        image: recommendationResponseImage,
      };
      return (
        <div className="empty-page-main-container mt-0">
          <EmptyPageCard emptyPageCardData={emptyCardData} />
        </div>
      );
    }

    return result;
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.state.backBtnStatus) {
      // return <Redirect to={{
      //     pathname: '/search-wine' + '/' + this.state.wineryId,

      // }}/>;
      this.props.history.push({
        pathname:
          "/" +
          commonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          ) +
          "/pairings",
      });
    }
    if (prevProps.questionId !== this.props.questionId) {
      this.getResponseForQuestionFromWineryAdmin(this.props.questionId);
    }
  }
  render() {
    let active = this.props.active;
    return (
      <div className="recommendations-main font-family">
        <div className="recommended-wines-main-container-desktop">
          {this.state.errorMessage == "You do not have access to this page." ? (
            <div>
              <PageNotFound></PageNotFound>
            </div>
          ) : (
            <span>
              {active == true
                ? this.renderRecommendationwineStatus()
                : this.renderRecommendationBasedOnStatus()}
            </span>
          )}
        </div>
      </div>
    );
  }
  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
const mapStateToProps = (state) => {
  return { wineryDetails: state.WineryReducer.currentWineryDetails };
};
RecommendedWinesDesktop = withRouter(RecommendedWinesDesktop);
export default connect(mapStateToProps, null)(RecommendedWinesDesktop);
