// Local
// export const CONSUMER_APP_URL = "http://localhost:3000";
// export const IDENTITY_API = "http://localhost:4200";
// export const WINERY_API = "http://localhost:4000";
// export const GOOGLE_SEARCH_API = "http://localhost:4900";
// export const TASTE_PREF_API = "http://localhost:4001"
// export const CONSUMER_API = "https://dev-consumer-services.pairanything.com";
// export const CHAT_API = "https://dev-chat-services.pairanything.com";
// export const EVENT_API = "https://dev-events-services.pairanything.com";
// export const SHOPPING_CART_API =
//   "https://dev-shoppingcart-services.pairanything.com";

// // Dev Deployment
// export const CONSUMER_APP_URL = "https://dev-app.pairanything.com";
// export const IDENTITY_API = "https://dev-identity-services.pairanything.com";
// export const WINERY_API = "https://dev-winery-services.pairanything.com";
// export const GOOGLE_SEARCH_API =
//   "https://dev-api-services.pairanything.com/googlesearch";
// export const TASTE_PREF_API =
//   "https://dev-tastepreference-services.pairanything.com";
// export const CONSUMER_API = "https://dev-consumer-services.pairanything.com";
// export const CHAT_API = "https://dev-chat-services.pairanything.com";
// export const EVENT_API = "https://dev-events-services.pairanything.com";
// export const SHOPPING_CART_API =
//   "https://dev-shoppingcart-services.pairanything.com";

// Demo Deployment
// export const CONSUMER_APP_URL = "https://demo-app.pairanything.com";
// export const IDENTITY_API = "https://demo-identity-services.pairanything.com";
// export const WINERY_API = "https://demo-winery-services.pairanything.com";
// export const GOOGLE_SEARCH_API =
//   "https://demo-api-services.pairanything.com/googlesearch";
// export const TASTE_PREF_API =
//   "https://demo-tastepreference-services.pairanything.com";
// export const CONSUMER_API = "https://demo-consumer-services.pairanything.com";
// export const CHAT_API = "https://demo-chat-services.pairanything.com";
// export const EVENT_API = "https://demo-events-services.pairanything.com";
// export const SHOPPING_CART_API =
//   "https://demo-shoppingcart-services.pairanything.com";

// // Production Deployment
export const CONSUMER_APP_URL = "https://app.pairanything.com";
export const IDENTITY_API = "https://identity-service.pairanything.com";
export const WINERY_API = "https://winery-service.pairanything.com";
export const GOOGLE_SEARCH_API = "https://api-service.pairanything.com/googlesearch";
export const TASTE_PREF_API = "https://tastepreference-service.pairanything.com";
export const CONSUMER_API = "https://consumer-service.pairanything.com";
export const CHAT_API = "https://chat-service.pairanything.com";
export const EVENT_API = "https://events-service.pairanything.com";
export const SHOPPING_CART_API =
  "https://shoppingcart-service.pairanything.com";

export const GA_TRACKING_ID = "G-8M0RZPK4G8"; //for prod
export const GA_UA_TRACKING_ID = "UA-161811179-3";

export const PUBLISHABLE_KEY =
  "pk_test_51IJvkqDZAR2ZK3ULdwyFe24pxFZrGeQeiTDvPYf9Edb3hzblLVvLIQH92ZlDQX4KdmYWb5aIsWWSmK41kHXkNmiv00TPPyvunN";

export const FACEBOOK_APP_ID = "331346908086440"; //for dev
export const GOOGLE_CLIENT_ID =
  "157109074418-ek3l39f6i4phjri4f4lsa6vuqsdiit7f.apps.googleusercontent.com"; //for dev
export const APPLE_ID = "com.pairanything.app";
// export const APPLE_REDIRECT_URI = "https://app.pairanything.com/discover";
export const APPLE_REDIRECT_URI = "https://app.pairanything.com/discover";

export const REACT_APP_API_KEY = "key2NRWl08o5DUlCy"; //for all env
export const REACT_APP_AMADOR_AIR_TABLE_BASE = "app054hSHieshpDDE"; //for all env
export const REACT_APP_ELDORADO_AIR_TABLE_BASE = "appQMM2dnyt1dPOQg";
export const REACT_APP_NEVADA_AIR_TABLE_BASE = "appbynY68DKdlFgMF";
export const REACT_APP_PLACER_AIR_TABLE_BASE = "appH7XG3riYVECb0p";
export const REACT_APP_SACRAMENTO_AIR_TABLE_BASE = "apppaZBFwJ6jaOxws";
export const REACT_APP_YOLO_AIR_TABLE_BASE = "app8vxQmJSJL0fbca"; // for all env
export const REACT_APP_AGGIES_AIR_TABLE_BASE = "apppRlj0ogyCA4GW7"; // for all env
export const REACT_APP_LODI_AIR_TABLE_BASE = "appmMhAEXnP5renaq";
export const SUBMIT_NEW_WINERY_AIR_TABLE_BASE = "appkXgUGoXXytjIBD";
export const SUBMIT_NEW_WINERY_API_KEY = "keyfUYV1YFsVCRo9h"; //updated airtable api key
export const SUBMIT_ANONYMOUS_USER_EMAIL = "appcCGsBB8FhMqR8U";

// UA-161811179-1 - dev
// UA-161811179-3 - prod
export const EMAILJS_API_KEY = "aEnZDE9eW9Ltn2eul";
export const EMAILJS_SERVICE_API_KEY = "service_uh1os7c";
export const EMAILJS_TEMPLATE_API_KEY = "template_et9xd2j";
export const AIRTABLE_API_TOKEN =
  "patxU5AnAwYWHSDz0.c11e108ee2f8b67769c5d5247078f60002fed380ba6cccd93be4682abc21aff0";
export const PENDO_TRACK_KEY = "84e2449c-5f3d-45a2-417a-805220f7d013";
export const AIRTABLE_API_KEY = "keyrU3qfhKKjbmf9t"; // DEPRECATED

export const RECIPE_PAIRING_RECO_BASE = "appmFBK8Mx6UB1zoy";
export const RECIPE_PAIRING_RECO_API = [
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20Cakebread",
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20Napa%20Vintners",
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20UCD%2C%20etc.%20(ALREADY%20DONE) ",
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20Wine%20Country%20Table",
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20Schramsberg",
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20Napa%20Valley",
  "https://api.airtable.com/v0/appmFBK8Mx6UB1zoy/Recipe%20Pairings%20-%20Format%20Template",
];
export const PRIORITY_DISH_API =
  "https://api.airtable.com/v0/app2gVUTLF0c8XAFx/All";

export const TASTE_PREF_QUIZ_BASE = "appwPrhfFc5V14LQU";

export const WINE_IMAGES_BASE = "app2M0OcDA8OnXWFh";
// export const TASTE_PREF_API ='http://localhost:4000';
export const EMAIL_INFO =
  "We will use your email address to save your quiz results. We will email you a link to your results too so you can reference them at any time.";
export const ANONYMOUS_HEADER =
  "Almost there! Log in or enter your email to see your results.";
