import React, { Component } from 'react';

import './ResetPassword.scss';
import logo from '../../../assets/images/splash.png';  

import { Redirect } from 'react-router';
import { FormErrors } from '../../components/FormErrors/FormErrors';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ResetPasswordAction from '../../redux/actions/ResetPasswordAction'

import * as constants from '../../constants/constants'
import Alert from 'react-bootstrap/Alert'


import backBtn from '../../../assets/images/back-btn.svg'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

class ResetPassword extends Component {
    
    constructor(props) {
        super(props);
        this.state = {

            password: '',
            confirmPassword: '' ,
            consumerId: '' , 
            setPasswordPageStatus: false ,
            formErrors: {password: '' , confirmPassword: '' },            
            passwordValid: false,
            confirmPasswordValid: false,
            formValid: false,
            consumerEmailIdFromUrl : '' ,
            consumerIdFromEmail : '',
            signInPageLoaded: false,
            inValidMessageFromResponse: '',
            buttonClick: false

        }

         this.handlePasswordChange = this.handlePasswordChange.bind(this)
         this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this)

        
    }
    componentDidMount() { 

    let urlTemp = []; // splitting the URL
    let items = (window.location.href).split("&");

    // let items = "https://pairanything-consumerportal-dev.redblacktree.net/raina@redblacktree.com/set-password/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVHlwZSI6ImNvbnN1bWVyIiwiY3JlYXRlZEF0IjoxNTc2NTc0NzI3MTAwLCJleHAiOjE1NzY2NjExMjcxMDAsImlhdCI6MTU3NjU3NDcyN30.ide88T3RbJ-AuTEyHhPz7fvgJHdtuE-bVdtumVIFn8M".split("&");

    for (let index = 0; index < items.length; index++) {
        urlTemp = items[index].split("/");
    }

    let emailFromUrl = urlTemp[3] 
    let token = urlTemp[5]
    this.setState({
        consumerEmailIdFromUrl: emailFromUrl // email
    })



    /* 
      NOTE: We get the email id from the URL, hit an api to get the ID based on the email id
            Then, we send the password and the id to the changepassword API 

    */

// get user by email ID. We're getting the email id from the URL. 

    fetch(constants.IDENTITY_API + '/api/v1/public/user/getUserByEmail/' + emailFromUrl , {  // updated new url

             method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `${token}`
            }
            })
            .then(res => {
                if (res.ok) {
                
                return res.json();
             
                
                } else {          
                throw Error(res.statusText);
                }
            })
            .then(json => {
                //   if successful, update state       
                this.setState({
                    consumerIdFromEmail : json.user.id  
                });

              
            })
            .catch(error => console.error(error));

      }


    handleSubmit = (event) => {
        event.preventDefault();
        
      }


     handlePasswordChange(event) {
        let val = event.target.value;
        this.setState({password: event.target.value} ,
            () => { this.validateField("password", val) });
     }
     handleConfirmPasswordChange(event) {
        let val = event.target.value;
        this.setState({confirmPassword: event.target.value} ,
            () => { this.validateField("confirmPassword", val) });
     }


     static getDerivedStateFromProps(nextProps, prevState) {
      //nextProps - new val from reducer
      // prevState - state value
   
      if(nextProps.resetPasswordSuccess!= undefined) {  // coming from mapStateToProps
        
      
     if(nextProps.resetPasswordSuccess[0] == 1) { // successful  if response = 1
 
    toast.info("Your password has been reset. Please sign in to continue!", {
      position: toast.POSITION.BOTTOM_CENTER
    });
      

       return { 
      
           signInPageLoaded: true
         
     }
 
     } else {
          return null;
     }

       }
   }


     validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let passwordValid = this.state.passwordValid;
        let confirmPasswordValid = this.state.confirmPasswordValid;

        switch(fieldName) {
      
          case 'password':
            passwordValid = value.length >= 4;
            // passwordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
            fieldValidationErrors.password = passwordValid ? '': ' invalid. Enter atleast 4 characters.';
            break;
          case 'confirmPassword':
            confirmPasswordValid = (this.state.password == this.state.confirmPassword);
            // confirmPasswordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
            // fieldValidationErrors.confirmPassword = confirmPasswordValid ? '': ' and password do not match';
            fieldValidationErrors.password = passwordValid ? '': ' and confirm password do not match.';

            break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        passwordValid: passwordValid,
                        confirmPasswordValid: confirmPasswordValid
                      }, this.validateForm);
      }

      validateForm() {
        this.setState({formValid: this.state.passwordValid && 
                                 this.state.confirmPasswordValid });
      }


    resetPasswordSubmit = () => {

 
      if(this.state.password=='' || this.state.confirmPassword == '') {
        this.setState({
          inValidMessageFromResponse: 'Please enter your password and confirm password! '
        })
      }
       else if(this.state.password!= this.state.confirmPassword) {
          this.setState({
            inValidMessageFromResponse: 'Passwords do not match! '
          })
        } else if(this.state.password.length < 4 || this.state.confirmPassword.length < 4 ) {
          this.setState({
            inValidMessageFromResponse: 'Please enter atleast 4 characters! '
          })
        }

        else if(this.state.password!='' && this.state.confirmPassword!='' && 
                      this.state.password == this.state.confirmPassword ) {
  

      const data = {
      
        password : this.state.password,
        // confirmPassword: this.state.confirmPassword,
        // id: this.state.consumerId,

        id: this.state.consumerIdFromEmail  // pass the ID from the state for the resetPassword POST request

        //id-3,18,22,23,24

      }
      
 
        this.props.ResetPasswordAction.ResetPassword(data)
        
    }

    this.setState({
      buttonClick: true
    })

    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.signInPageLoaded) {
        // return <Redirect push to="/sign-in" />;
        this.props.history.push("/sign-in");
      }  
    }
    
    render() {
        return (
   
            <div className = "reset-password-main-container">
                <div className = "reset-header">
                <div className="back-btn-container"> 
                     <img alt="Go back" className="" src={backBtn} onClick = { this.gotoSignInPage }/>
                </div>

                    <div className = "logo-container">
                            <img alt = "PairAnything" className="logo" src={logo} />
                    </div>
                   
                    
                </div>

                <div className = "reset-password-header">
                    <span className = "reset-password-title">Reset Password</span>      
                </div> 

                <div className="form-errors-display">
                {
                  // (this.state.buttonClick)?  <FormErrors formErrors={this.state.formErrors} /> : ''
                }
                    {
                     (this.state.inValidMessageFromResponse !='' && this.state.buttonClick)? this.state.inValidMessageFromResponse: ''
                   }
                </div>



                {/* <div className="form-errors-display">
                    <FormErrors formErrors={this.state.formErrors} />
                </div> */}


                <div className = "reset-password-form-parent">
                    <div className = "reset-password-form-container">
                        <form className = "reset-password-form" >
                        
                        
                                {/* <input
                                className="reset-password-form-row"
                                // className={`form-row ${this.state.passwordValid? '' : ' form-row-error'}`}
                                type="password" name="password" placeholder="Password"
                                value={this.state.password} onChange={this.handlePasswordChange}/>
                                <input
                                className="reset-password-form-row" 
                                // className={`form-row ${this.state.confirmPasswordValid? '' : ' form-row-error'}`}
                                type="password" name="confirmpassword" placeholder="Confirm Password"
                                value={this.state.confirmPassword} onChange={this.handleConfirmPasswordChange}/> */}

                              {/*
                                #NOTE: CSS used that was added in signin.js
                              */}
                              <div className="signin-form-group form-group">
                              
                                <input className = "form-control signin-form-row-input"
                                type="password" name="password" placeholder="Password" id="resetPasswordScreenPassword"
                                value={this.state.password} onChange={this.handlePasswordChange}/>  
                                <label for="resetPasswordScreenPassword"></label>

                              </div>

                              <div className="signin-form-group form-group">
                              
                                <input className = "form-control signin-form-row-input"
                                type="password" name="confirmpassword" placeholder="Confirm Password" id="resetPasswordScreenConfirmPassword"
                                value={this.state.confirmPassword} onChange={this.handleConfirmPasswordChange}/>
                                <label for="resetPasswordScreenConfirmPassword"></label>

                              </div>



                                <button type="button" onClick={this.resetPasswordSubmit}
                                //  disabled={!this.state.formValid}
                                className="btn btn-round reset-password-btn">Confirm</button>  

                              
                        </form>
                    </div>
                </div> 

           </div>
                
        );
    }
}


// REDUX

function mapStateToProps(state, props) {  // getDerivedStateFromProps gets this returned value

  return {
    resetPasswordSuccess: state.ResetPasswordReducer.resetPasswordSuccess,
    resetPasswordFail: state.ResetPasswordReducer.resetPasswordFail
  }
}

function mapDispatchToProps(dispatch) {
  return {
      ResetPasswordAction: bindActionCreators(ResetPasswordAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)





// export default reSetPassword; 
