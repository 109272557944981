import React, { useEffect, useState } from "react";

export default function IdleTimer({ time, cb }) {
  const [currSeconds, setCurrSeconds] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrSeconds(currSeconds + 1);
      if (currSeconds == time) {
        cb();
      }
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [currSeconds]);

  function resetTimer() {
    setCurrSeconds(0);
  }

  // Define the events that
  // would reset the timer
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.ontouchstart = resetTimer;
  window.onclick = resetTimer;
  window.onkeydown = resetTimer;

  return <></>;
}
