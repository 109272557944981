// Auth/Signin
export const USER_SIGNIN_SUCCEEDED = "USER_SIGNIN_SUCCEEDED";
export const USER_SIGNIN_FAILED = "USER_SIGNIN_FAILED";
export const USER_SIGNIN_DUMMY = "USER_SIGNIN_DUMMY";
export const NEW_USER_SIGN_IN = "NEW_USER_SIGN_IN";
export const TOGGLE_SHOW_GET_STARTED = "TOGGLE_SHOW_GET_STARTED";
export const CLOSE_SHOW_GET_STARTED = "CLOSE_SHOW_GET_STARTED";
export const VIEWED_SHOW_GET_STARTED = "VIEWED_SHOW_GET_STARTED";

// GET winery details
export const GET_WINERY_DETAILS_SUCCEEDED = "GET_WINERY_DETAILS_SUCCEEDED";
export const GET_WINERY_DETAILS_FAILED = "GET_WINERY_DETAILS_FAILED";

// PUT Shipping Address details
export const PUT_SHIPPING_ADDRESS_SUCCEEDED = "PUT_SHIPPING_ADDRESS_SUCCEEDED";
export const PUT_SHIPPING_ADDRESS_FAILED = "PUT_SHIPPING_ADDRESS_FAILED";

// POST Sign up
export const USER_SIGNUP_SUCCEEDED = "USER_SIGNUP_SUCCEEDED";
export const USER_SIGNUP_FAILED = "USER_SIGNUP_FAILED";

// POST Set Password
export const SET_PASSWORD_SUCCEEDED = "SET_PASSWORD_SUCCEEDED";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";

// POST Forgot Password
export const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_DUMMY = "FORGOT_PASSWORD_DUMMY";

// POST Reset Password
export const RESET_PASSWORD_SUCCEEDED = "RESET_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// GET FIND_TASTE_PREF_BY_CUST_SUCCEEDED
export const FIND_TASTE_PREF_BY_CUST_SUCCEEDED =
  "FIND_TASTE_PREF_BY_CUST_SUCCEEDED";
export const FIND_TASTE_PREF_BY_CUST_FAILED = "FIND_TASTE_PREF_BY_CUST_FAILED";

// GET FIND_DEFAULT_TASTE_PREF_BY_CUST_SUCCEEDED
export const FIND_DEFAULT_TASTE_PREF_BY_CUST_SUCCEEDED =
  "FIND_DEFAULT_TASTE_PREF_BY_CUST_SUCCEEDED";
export const FIND_DEFAULT_TASTE_PREF_BY_CUST_FAILED =
  "FIND_DEFAULT_TASTE_PREF_BY_CUST_FAILED";

//  ADD TO CART
export const ADD_TO_CART_SUCCEED = "ADD_TO_CART_SUCCEED";
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED";
export const ADD_TO_CART_DUMMY = "ADD_TO_CART_DUMMY";

//EDIT TASTE PREFERENCE
export const EDIT_TASTE_PREFERENCE_SUCCESS = "EDIT_TASTE_PREFERENCE_SUCCESS";
export const EDIT_TASTE_PREFERENCE_FAILED = "EDIT_TASTE_PREFERENCE_FAILED";

//GET EVENT DETAIL
export const GET_EVENT_DETAIL_SUCCESS = "GET_EVENT_DETAIL_SUCCESS";
export const GET_EVENT_DETAIL_FAILED = "GET_EVENT_DETAIL_FAILED";
export const GET_EVENT_DETAIL_DUMMY = "GET_EVENT_DETAIL_DUMMY";

//ADD TO RSVP
export const ADD_TO_RSVP_SUCCESS = "ADD_TO_RSVP_SUCCESS";
export const ADD_TO_RSVP_FAILED = "ADD_TO_RSVP_FAILED";

//EDIT RSVP
export const EDIT_RSVP_SUCCESS = "EDIT_RSVP_SUCCESS";
export const EDIT_RSVP_FAILED = "EDIT_RSVP_FAILED";

//GET EVENT DETAIL FOR MY EVENTS
export const GET_MY_EVENT_DETAIL_SUCCESS = "GET_MY_EVENT_DETAIL_SUCCESS";
export const GET_MY_EVENT_DETAIL_FAILED = "GET_MY_EVENT_DETAIL_FAILED";

//CANCEL RSVP
export const CANCEL_RSVP_SUCCESS = "CANCEL_RSVP_SUCCESS";
export const CANCEL_RSVP_FAILED = "CANCEL_RSVP_FAILED";

//GET CART TOTAL QUANTITY
export const GET_CART_TOTAL_QTY_SUCCESS = "GET_CART_TOTAL_QTY_SUCCESS";
export const GET_CART_TOTAL_QTY_FAILED = "GET_CART_TOTAL_QTY_FAILED";

export const RESET_APP = "RESET_APP";

//WINE RATING
export const CREATE_WINE_RATING_SUCCESS = "CREATE_WINE_RATING_SUCCESS";
export const CREATE_WINE_RATING_FAILED = "CREATE_WINE_RATING_FAILED";
export const GET_AVERAGE_WINE_RATING_SUCCESS =
  "GET_AVERAGE_WINE_RATING_SUCCESS";
export const GET_AVERAGE_WINE_RATING_FAILED = "GET_AVERAGE_WINE_RATING_FAILED";

export const SET_WINERY_LIST = "SET_WINERY_LIST";
export const SET_WINERY_DETAILS = "SET_WINERY_DETAILS";
export const SET_SPOTLIGHT_DEAL_LIST = "SET_SPOTLIGHT_DEAL_LIST";
