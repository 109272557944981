import * as types from "./action-types";
import * as constants from "../../constants/constants";

// let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
export const SetPassword = (dataValue) => {
  const data = {
    id: dataValue.id,
    password: dataValue.password,
    userType: dataValue.userType,
    wineryId: dataValue.wineryId,
  };
  let bearer = "Bearer " + dataValue.tokenFromUrl;
  return (dispatch) => {
    // constants.IDENTITY_API + "/api/v1/public/user/setPassword" --> removed public bcz this api needs beared token 
    fetch(constants.IDENTITY_API + "/api/v1/user/setPassword", {
      // new updated API url
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
        // Authorization: bearer,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((respJSON) => {
        dispatch({ type: types.SET_PASSWORD_SUCCEEDED, payload: respJSON });
      })
      .catch((error) => {
        dispatch({ type: types.SET_PASSWORD_FAILED, payload: error });
      });
  };
};
