import * as types from "../actions/action-types";
const defaultState = {
  wineryList: [],
  spotlightDealList: [],
  currentWineryDetails: null,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_WINERY_DETAILS_SUCCEEDED:
      return {
        ...state,
        getWineryDetailsSuccess: action.payload,
      };

    case types.GET_WINERY_DETAILS_FAILED:
      return {
        ...state,
        getWineryDetailsFail: action.payload,
      };
    case types.SET_WINERY_LIST:
      let arr = [...state.wineryList, ...action.list];
      const unique = Object.values(
        arr.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      return {
        ...state,
        wineryList: unique,
      };
    case types.SET_SPOTLIGHT_DEAL_LIST:
      let arr1 = [...state.spotlightDealList, ...action.list];
      const unique1 = Object.values(
        arr1.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      return {
        ...state,
        spotlightDealList: unique1,
      };
    case types.SET_WINERY_DETAILS:
      return {
        ...state,
        currentWineryDetails: action.wineryDetails,
      };

    default:
      return state;
  }
};
