import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiPaper-root": {
      background: "#FFFFFF !important",
    },
  },
})(() => null);
export default class AlertDialogSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  // const [open ,setOpen] = React.useState(false);

  //   const [open] = React.useState(true);
  handleClickOpen = () => {
    // setOpen(true);
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    // setOpen(false);

    this.setState({
      open: false,
    });
    this.props.signOutStatus(false);
  };

  successBtnClicked = () => {
    // setOpen(false);

    this.setState({
      open: false,
    });

    this.props.signOutData
      ? this.props.signOutStatus(true)
      : this.props.shippingAddressStatus(true);
  };

  failureBtnClicked = () => {
    this.setState({
      open: false,
    });

    this.props.signOutData
      ? this.props.signOutStatus(false)
      : this.props.shippingAddressStatus(false);
  };

  componentDidMount = () => {
    // this.handleClickOpen();
  };
  render() {
    const mystyle = {
      color: "black",
      fontSize: "1.3em",
      background: "#FFFFFF !important",
    };

    return (
      <div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
        <React.Fragment>
          {/* <GlobalCss /> */}
          <div className="modal-style">
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              maxWidth="xs"
              onClose={this.handleClose.bind(this)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {/* <DialogTitle className="alert-dialog-title" id="alert-dialog-slide-title">
        {"Are you sure that you want to sign out?"}</DialogTitle> */}

              <DialogContent className=" white-bg">
                <DialogContentText
                  style={mystyle}
                  id="alert-dialog-slide-description"
                >
                  {this.props.signOutData
                    ? "Are you sure you want to sign out?"
                    : "Your changes have not been saved."}
                </DialogContentText>
              </DialogContent>
              <DialogActions className=" white-bg">
                <Button
                  style={mystyle}
                  className="alert-dialog-text"
                  onClick={this.failureBtnClicked.bind(this)}
                >
                  {this.props.signOutData ? "No" : "Cancel"}
                </Button>
                <Button
                  style={mystyle}
                  className="alert-dialog-text"
                  onClick={this.successBtnClicked.bind(this)}
                >
                  {this.props.signOutData ? "Yes" : "Ok"}
                </Button>
              </DialogActions>
            </Dialog>{" "}
          </div>
        </React.Fragment>
      </div>
    );
  }
}
