import React, { useState } from "react";
import styles from "./header-inline.module.scss";
import PALogo from "../../../assets/images/PALogos/PairAnything_logo_full.png";
import { useHistory } from "react-router-dom";
import { useLoggedIn } from "../../lib/hooks/useLoggedIn";

export default function PAHeaderInline({
  center = false,
  noMenu = false,
  disableClick = false,
}) {
  const { loggedIn, roleId, consumerData, logout } = useLoggedIn();
  const history = useHistory();
  const currentPage = history.location.pathname;

  const [menuActive, setMenuActive] = useState(false);

  const stopParentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.PAHeader}>
      {menuActive ? (
        <div
          className={styles.modalBackground}
          onClick={() => setMenuActive(false)}
        />
      ) : (
        <></>
      )}
      <div
        className={`${styles.container} ${
          menuActive ? styles.containerActive : null
        }`}
        onClick={(e) => stopParentClick(e)}
      >
        <div className={styles.modalContent}>
          <div
            className={`${styles.logoIconContainer}`}
            style={center ? { justifyContent: "center" } : {}}
          >
            {disableClick ? (
              <img
                className={`${styles.logoDisabled}`}
                src={PALogo}
                alt="PA logo"
              />
            ) : (
              <img
                onClick={() => {
                  const url = window.localStorage.getItem("winery_url");
                  url
                    ? history.push(`/${JSON.parse(url)}`)
                    : history.push("/welcome");
                }}
                className={`${styles.logo}`}
                src={PALogo}
                alt="PA logo"
              />
            )}

            {!center ? (
              <div
                className={`${styles.menuIcon}`}
                style={noMenu ? { display: "none" } : null}
                onClick={() => setMenuActive(!menuActive)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8.49991C0 7.85976 0.345961 7.34082 0.772726 7.34082H19.3182C19.7449 7.34082 20.0909 7.85976 20.0909 8.49991C20.0909 9.14006 19.7449 9.659 19.3182 9.659H0.772726C0.345961 9.659 0 9.14006 0 8.49991Z"
                    fill="#343434"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 1.15909C0 0.518942 0.345961 0 0.772726 0H19.3182C19.7449 0 20.0909 0.518942 20.0909 1.15909C20.0909 1.79924 19.7449 2.31818 19.3182 2.31818H0.772726C0.345961 2.31818 0 1.79924 0 1.15909Z"
                    fill="#343434"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 15.8407C0 15.2006 0.345961 14.6816 0.772726 14.6816H19.3182C19.7449 14.6816 20.0909 15.2006 20.0909 15.8407C20.0909 16.4809 19.7449 16.9998 19.3182 16.9998H0.772726C0.345961 16.9998 0 16.4809 0 15.8407Z"
                    fill="#343434"
                  />
                </svg>
              </div>
            ) : (
              <></>
            )}
          </div>
          {menuActive ? (
            <div className={styles.menuContent}>
              <div className={styles.optionsBox}>
                <div
                  className={styles.option}
                  onClick={() => {
                    if (history.location.pathname == "/welcome") {
                      history.push({
                        pathname: `/discover`,
                      });
                      setTimeout(() => {
                        history.replace({
                          pathname: `/welcome`,
                        });
                      }, 10);
                    } else {
                      history.push({
                        pathname: `/welcome`,
                      });
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <rect
                      y="0.694336"
                      width="24"
                      height="24"
                      rx="2"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M17.1429 5.69434C17.3528 5.69436 17.5554 5.7693 17.7123 5.90495C17.8692 6.04059 17.9694 6.22749 17.994 6.43021L18 6.52772V19.8619C17.9998 20.0743 17.9161 20.2786 17.7661 20.433C17.6162 20.5875 17.4112 20.6805 17.1931 20.6929C16.975 20.7053 16.7602 20.6364 16.5927 20.5C16.4252 20.3637 16.3175 20.1703 16.2917 19.9594L16.2857 19.8619V15.695H15.4286C15.2186 15.6949 15.016 15.62 14.8591 15.4843C14.7022 15.3487 14.602 15.1618 14.5774 14.9591L14.5714 14.8616V9.86126C14.5714 8.01948 15.8571 5.69434 17.1429 5.69434ZM12 5.69434C12.2099 5.69436 12.4126 5.7693 12.5695 5.90495C12.7263 6.04059 12.8266 6.22749 12.8511 6.43021L12.8571 6.52772V10.6946C12.857 11.4337 12.6044 12.1519 12.139 12.7363C11.6736 13.3206 11.0217 13.7381 10.2857 13.9232V19.8619C10.2855 20.0743 10.2018 20.2786 10.0518 20.433C9.90187 20.5875 9.6969 20.6805 9.4788 20.6929C9.26071 20.7053 9.04596 20.6364 8.87843 20.5C8.7109 20.3637 8.60323 20.1703 8.57743 19.9594L8.57143 19.8619V13.9232C7.86467 13.7455 7.23464 13.3533 6.77294 12.8035C6.31125 12.2538 6.04197 11.5751 6.00429 10.8663L6 10.6946V6.52772C6.00024 6.31531 6.0839 6.111 6.23387 5.95655C6.38384 5.80209 6.58882 5.70914 6.80691 5.69669C7.025 5.68424 7.23975 5.75324 7.40728 5.88957C7.57482 6.0259 7.68248 6.21929 7.70829 6.43021L7.71429 6.52772V10.6946C7.71429 10.9872 7.79351 11.2746 7.94397 11.528C8.09443 11.7814 8.31083 11.9918 8.57143 12.1381V6.52772C8.57167 6.31531 8.65532 6.111 8.8053 5.95655C8.95527 5.80209 9.16025 5.70914 9.37834 5.69669C9.59643 5.68424 9.81118 5.75324 9.97871 5.88957C10.1462 6.0259 10.2539 6.21929 10.2797 6.43021L10.2857 6.52772L10.2866 12.1381C10.5258 12.0036 10.728 11.815 10.8761 11.5882C11.0241 11.3614 11.1136 11.1032 11.1369 10.8355L11.1429 10.6946V6.52772C11.1429 6.30669 11.2332 6.09472 11.3939 5.93843C11.5547 5.78214 11.7727 5.69434 12 5.69434Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                  <p className={`h3-16`}>Pairings</p>
                </div>
                <div
                  className={styles.option}
                  onClick={() =>
                    history.push({
                      pathname: `/TastePreferenceQuiz`,
                      state: { from: currentPage },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <rect
                      y="0.694336"
                      width="24"
                      height="24"
                      rx="2"
                      fill="#EFEFEF"
                    />
                    <path
                      d="M17.9051 11.9898L16.0224 6.07838C15.9871 5.96749 15.914 5.87017 15.8141 5.80095C15.7142 5.73173 15.5928 5.69433 15.468 5.69434H8.53194C8.40719 5.69433 8.28579 5.73173 8.18587 5.80095C8.08595 5.87017 8.01288 5.96749 7.97756 6.07838L6.09491 11.9899C5.93773 12.4824 5.97673 13.0109 6.2048 13.4789C6.66982 14.4278 7.39737 15.2459 8.31364 15.85C9.22991 16.4541 10.3023 16.8229 11.422 16.9189V19.6229H9.10995C8.95665 19.6229 8.80963 19.6793 8.70124 19.7798C8.59284 19.8803 8.53194 20.0165 8.53194 20.1586C8.53194 20.3007 8.59284 20.437 8.70124 20.5374C8.80963 20.6379 8.95665 20.6943 9.10995 20.6943H14.89C15.0433 20.6943 15.1904 20.6379 15.2988 20.5374C15.4072 20.437 15.468 20.3007 15.468 20.1586C15.468 20.0165 15.4072 19.8803 15.2988 19.7798C15.1904 19.6793 15.0433 19.6229 14.89 19.6229H12.578V16.9189C13.6977 16.8229 14.7701 16.4541 15.6864 15.85C16.6026 15.2458 17.3302 14.4278 17.7952 13.4789C18.0233 13.0108 18.0623 12.4823 17.9051 11.9898ZM12.2585 11.1081C10.367 10.2315 8.84869 10.232 7.78551 10.4659L8.964 6.76576H15.0361L16.5398 11.4873C15.7612 11.7882 14.2945 12.0515 12.2586 11.1081H12.2585Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                  <p className={`h3-16`}>Taste Quiz</p>
                </div>
                <div
                  className={styles.option}
                  onClick={() => {
                    history.push({
                      pathname: `/viewProfile`,
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <rect
                      y="0.694336"
                      width="24"
                      height="24"
                      rx="2"
                      fill="#EFEFEF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.16667 9.02767C9.16667 8.14361 9.51786 7.29577 10.143 6.67065C10.7681 6.04553 11.6159 5.69434 12.5 5.69434C13.3841 5.69434 14.2319 6.04553 14.857 6.67065C15.4821 7.29577 15.8333 8.14361 15.8333 9.02767C15.8333 9.91172 15.4821 10.7596 14.857 11.3847C14.2319 12.0098 13.3841 12.361 12.5 12.361C11.6159 12.361 10.7681 12.0098 10.143 11.3847C9.51786 10.7596 9.16667 9.91172 9.16667 9.02767ZM9.16667 14.0277C8.0616 14.0277 7.00179 14.4667 6.22039 15.2481C5.43899 16.0295 5 17.0893 5 18.1943C5 18.8574 5.26339 19.4933 5.73223 19.9621C6.20107 20.4309 6.83696 20.6943 7.5 20.6943H17.5C18.163 20.6943 18.7989 20.4309 19.2678 19.9621C19.7366 19.4933 20 18.8574 20 18.1943C20 17.0893 19.561 16.0295 18.7796 15.2481C17.9982 14.4667 16.9384 14.0277 15.8333 14.0277H9.16667Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                  <p className={`h3-16`}>Profile</p>
                </div>
              </div>
              <div className={styles.secondaryList}>
                <p
                  className={`p1-reg ${styles.clickHover}`}
                  onClick={() => {
                    if (history.location.pathname == "/help") {
                      history.push({
                        pathname: `/discover`,
                      });
                      setTimeout(() => {
                        history.replace({
                          pathname: `/help`,
                        });
                      }, 10);
                    } else {
                      history.push({
                        pathname: `/help`,
                      });
                    }
                  }}
                >
                  Help
                </p>
                <p
                  className={`p1-reg ${styles.clickHover}`}
                  onClick={() =>
                    history.push({
                      pathname: `/feedback`,
                    })
                  }
                >
                  Leave Feedback
                </p>
                <p
                  className={`p1-reg ${styles.clickHover}`}
                  onClick={() => {
                    if (history.location.pathname == "/welcome") {
                      history.push({
                        pathname: `/discover`,
                      });
                      setTimeout(() => {
                        history.replace({
                          pathname: `/welcome`,
                        });
                      }, 10);
                    } else {
                      history.push({
                        pathname: `/welcome`,
                      });
                    }
                  }}
                >
                  PairAnything Home
                </p>
              </div>
              <div className={styles.signupBox}>
                {!loggedIn ? (
                  <>
                    <button
                      className={styles.signupBtn}
                      onClick={() =>
                        history.push({
                          pathname: `/signup/setup`,
                        })
                      }
                    >
                      Sign Up
                    </button>
                    <div className={styles.loginBox}>
                      <p className={`p1-reg`}>
                        Have an account?{" "}
                        <span
                          className={styles.loginTxt}
                          onClick={() =>
                            history.push({
                              pathname: `/login`,
                            })
                          }
                        >
                          Log in.
                        </span>
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => logout()}
                      className={styles.logoutBtn}
                    >
                      <p className={`p1-reg ${styles.logoutBtnTxt}`}>
                        Sign Out
                      </p>
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
