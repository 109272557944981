import React from "react";

export default function ExternalLink() {
  return (
    <svg
      className={`no-shrink`}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="M10.6663 7.11161V10.7781C10.6663 11.1023 10.5376 11.4131 10.3084 11.6423C10.0792 11.8715 9.76829 12.0003 9.44415 12.0003H2.72218C2.39804 12.0003 2.08717 11.8715 1.85797 11.6423C1.62876 11.4131 1.5 11.1023 1.5 10.7781V4.05616C1.5 3.73202 1.62876 3.42115 1.85797 3.19195C2.08717 2.96275 2.39804 2.83398 2.72218 2.83398H6.38871"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83325 1H12.4998V4.66653"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.7771 7.72198L12.4991 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
