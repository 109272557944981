import React, { Component } from "react";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./RateRecommendationPopup.scss";
import RateRecommendation from "../../screens/RateRecommendation/RateRecommendation";
import PopUpImage from "../../../assets/images/Events/new-success-rsvp.svg";
import { withStyles } from "@material-ui/core/styles";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

const theme = createMuiTheme({
  shadows: Array(25).fill("none"),
});

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      background: "none",
      width: "430px !important",
    },
    ".MuiBackdrop-root": {
      background: "#2b2b2b 0% 0% no-repeat padding-box",
      opacity: "0.7 !important",
    },
  },
})(() => null);

const contentStyle = {
  background: "#FFFFFF",
  padding: "5px",
  // paddingTop: "10px",
  // paddingBottom: "1px",
  // paddingRight: "19px",
  borderRadius: "25px",
  display: "flex",
  flexDirection: "row",
};

export default class RateRecommendationPopup extends Component {
  componentDidMount = () => {
    GoogleAnalyticsFunctions.addedToRsvp();
  };

  render() {
    let { open } = this.props;

    return (
      <React.Fragment>
        <GlobalCss />
        <MuiThemeProvider theme={theme}>
          <Dialog open={open} aria-labelledby="added-to-cart-title">
            <DialogContent style={contentStyle}>
              <RateRecommendation
                recommendationData={this.props.recommendationData}
                exitRateRecommendation={this.props.exitRateRecommendation}
                history={this.props.history}
              />
            </DialogContent>
          </Dialog>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}
