import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import "./ShoppingCartHeader.scss";
import * as commonFunctions from "../../constants/commonFunctions";

class ShoppingCartHeader extends Component {
  goBack = () => {
    if (this.props.onShippingAddressFormOpenFromShoppingCart) {
    } else {
      let urlTemp = []; // splitting the URL
      let items = window.location.href.split("&");

      for (let index = 0; index < items.length; index++) {
        urlTemp = items[index].split("/");
      }
      let checkoutPage = urlTemp[3]; // checking if url from stripe back click
      let fromSrtipePage = urlTemp[4]; // checking if url from stripe back click
      let fromStripePageCheckout = urlTemp[5]; // checking if url from stripe back click
      // If these url matches then by default navigate to available wines page
      if (
        fromSrtipePage &&
        checkoutPage &&
        checkoutPage === "shopping-cart" &&
        fromSrtipePage === "stripe"
      ) {
        this.props.history.push(
          "/" +
            commonFunctions.convertToCamelCase(
              localStorage.getItem("winery_name")
            ) +
            "/wines"
        );
      } else if (
        fromSrtipePage &&
        checkoutPage &&
        fromStripePageCheckout &&
        checkoutPage === "shopping-cart" &&
        fromStripePageCheckout === "stripe"
      ) {
        this.props.history.push("/shopping-cart/stripe");
      } else {
        this.props.history.goBack();
      }
    }
  };
  render() {
    return (
      <div className={this.getClassNamesForHeader()}>
        <div className="common-page-sub-header new-common-header-change">
          <img
            alt="Go to Previous page"
            className="common-sub-header-back-icon ptr-clss"
            onClick={() => {
              this.props.goBackFunc ? this.props.goBackFunc() : this.goBack();
            }}
            src={backBtn}
          />
          <div className="new-header-text">
            <span className="common-sub-header-text new-search-wine-for-dish-text">
              Shopping Cart
            </span>
          </div>
          {/* <span className="common-sub-header-text">
            {" "}
            Shopping Cart
          </span> */}
          <img
            alt="PairAnything"
            className="common-sub-header-logo new-logo-header-design ml-0"
            src={pairAnythingLogo}
            onClick={() => {
              this.openHomePage();
            }}
          />
        </div>
      </div>
    );
  }
  getClassNamesForHeader = () => {
    let classes = "common-page-header font-family";
    if (this.props.onShippingAddressFormOpenFromShoppingCart) {
      classes =
        "common-page-header font-family shopping-cart-fade-in-position-change";
    }
    return classes;
  };
  openHomePage = () => {
    this.props.history.push("/discover");
  };
}

ShoppingCartHeader = withRouter(ShoppingCartHeader);
export default ShoppingCartHeader;
