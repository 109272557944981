import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import logo from './logo.svg';
import logo from "../../../logo.svg";
import { Redirect } from "react-router-dom";

import "./Home.scss";
import Splash from "../Splash/Splash";
import { isMobile } from "react-device-detect";
import AgeConfirmation from "../AgeConfirmation/AgeConfirmation";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashScreenLoaded: undefined,
      isUserLoggedIn: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ splashScreenLoaded: true });
    }, 2000);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  render() {
    // Check if user already logged in - yes navigate to home page discover
    let signInToken = localStorage.getItem("signin_token");
    let ageConfirmed = localStorage.getItem("ageConfirmed");
    const isFirstVisit = localStorage.getItem("firstVisit");
    if (isFirstVisit === 'true') {
      localStorage.setItem("checkIn", new Date().getTime());
      localStorage.setItem("checkOut", "false");
    }
    
    return (
      <React.Fragment>
        <div>
          {!this.state.splashScreenLoaded && isMobile ? (
            <Splash />
          ) : signInToken || (ageConfirmed && ageConfirmed == "true") ? (
            <Redirect push to="/discover" />
          ) : (
            <AgeConfirmation
              redirectTo={
                this.props.location.state
                  ? this.props.location.state.redirectTo
                  : undefined
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
