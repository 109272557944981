import { Component } from "react";
import React from "react";
import TextareaAutosize from 'react-textarea-autosize';

class InputDesktop extends Component {
  state = {
    text: "",
  };

  onUserInputTextChange(e) {
    this.setState({ text: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ text: "" });
    if (this.state.text !== "") {
      this.props.onSendMessage(this.state.text);
    }
  }

  render() {
    return (
      <div className="input-main-container">
        <form className="messages-form">
          <div className="messages-form-control">
            <TextareaAutosize
              maxRows="3"
              className="input-solid"
              onChange={(e) => this.onUserInputTextChange(e)}
              value={this.state.text}
              type="text"
              placeholder="Ask us anything"
              style={{ width: "95%", resize: "none" }}
            />
          </div>
          <div className="messages-form-send">
            <span
              onClick={(e) => this.onSubmit(e)}
              className="messages-form-send-text ptr-clss"
            >
              SEND
            </span>
          </div>
        </form>
      </div>
    );
  }
}

export default InputDesktop;
