import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./profile.module.scss";
import ChevronLeft from "../../../assets/images/PAIcons/chevron-left.svg";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import { post, get } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import { formatCurrency } from "../../lib/common/convertCurrency";
import RolePill from "../../components/TargetComponents/RolePill/RolePill";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";
import TargetLogo from "../../../assets/images/PALogos/targetlogo.png";
import { useLoggedIn } from "../../lib/hooks/useLoggedIn";
import Phone from "../../components/Icons/phone";
import Email from "../../components/Icons/email";
import Lock from "../../components/Icons/lock";
import MapPin from "../../components/Icons/map-pin";
import PasswordDots from "../../components/Icons/password-dots";
import Edit from "../../components/Icons/edit";
import ChevronRight from "../../components/Icons/Arrows/chev-right";
import { IDENTITY_API } from "../../constants/constants";
import { useSessionUrl } from "../../lib/hooks/useSession";
import SavedWineCard from "../../components/TargetComponents/SavedWineCard/SavedWineCard";
import SavedPairingCard from "../../components/TargetComponents/SavedPairingCard/SavedPairingCard";

export default function ViewProfile() {
  // const { hi } = useParams();
  // console.log(`hi:`);
  // console.log(hi);

  // oooooooooooo USER SAVES oooooooooooooo

  const { data: userSaves } = useSessionUrl(
    `${IDENTITY_API}/api/v1/public/user/save/getUserSavesSaturated`
  );

  useEffect(() => {
    if (!!userSaves && userSaves.length > 0) {
      console.log("hello");
    }
  }, [userSaves]);

  console.log(`userSaves:`);
  console.log(userSaves);

  const history = useHistory();

  const { loggedIn, roleId, consumerData } = useLoggedIn();

  console.log(consumerData);

  return (
    <Layout white>
      <AnimationWrapper animation="fromRight">
        <LayoutContents>
          <PAHeader left />
          <main className={`mc ${styles.main}`}>
            <section className={`${styles.head}`}>
              <p className={`p1-reg`}>Your Profile</p>
              <div className={`${styles.title}`}>
                <h1 className={`h1-30`}>
                  {consumerData?.firstName +
                    " " +
                    (consumerData?.lastName || "")}
                </h1>
                <RolePill roleId={roleId} />
              </div>
            </section>
            <div className={`${styles.line}`} />
            <section className={`${styles.personalInfoSection}`}>
              <div className={`${styles.sectionTitle}`}>
                <h2 className={`h2-22`}>Personal Info</h2>
                <button className={`${styles.editBox}`}>
                  <p className={`p1-bold-red`}>Edit</p>
                  <Edit />
                </button>
              </div>
              <div className={`${styles.personalInfoItem}`}>
                <Phone />
                <p className={`p1-reg`}>
                  {consumerData?.phoneNumber || "No data"}
                </p>
              </div>
              <div className={`${styles.personalInfoItem}`}>
                <Email />
                <p className={`p1-reg`}>{consumerData?.email || "No data"}</p>
              </div>
              <div className={`${styles.personalInfoItem}`}>
                <Lock />
                <div style={{ paddingLeft: "2px" }}>
                  <PasswordDots />
                </div>
              </div>
              <div className={`${styles.personalInfoItem}`}>
                <MapPin />
                <p className={`p1-reg`}>
                  {consumerData?.zipCode || "No Location"}
                </p>
              </div>
            </section>

            <div className={`${styles.line}`} />
            <section className={`${styles.savedSection}`}>
              <div className={`${styles.sectionTitle}`}>
                <h2 className={`h2-22`}>Saved</h2>
                <button
                  className={`${styles.editBox}`}
                  onClick={() =>
                    history.push({
                      pathname: `/viewProfile/saved`,
                    })
                  }
                >
                  <p className={`p1-bold-red`}>See All</p>
                  <ChevronRight />
                </button>
              </div>
              {!!userSaves ? (
                <div className={`${styles.savedCardBox}`}>
                  {userSaves.slice(0, 2).map((item, idx) => (
                    <>
                      {!!item?.details ? (
                        <SavedPairingCard key={item.id} save={item} />
                      ) : (
                        <SavedWineCard key={item.id} save={item} />
                      )}
                    </>
                  ))}
                  {userSaves.length == 1 ? <SavedWineCard ghost /> : <></>}
                </div>
              ) : (
                <></>
              )}
            </section>
            <div className={`${styles.line}`} />
            <section className={`${styles.savedSection}`}>
              <div className={`${styles.sectionTitle}`}>
                <h2 className={`h2-22`}>Pairing History</h2>
                <button
                  className={`${styles.editBox}`}
                  onClick={() =>
                    history.push({
                      pathname: `/viewProfile/history`,
                    })
                  }
                >
                  <p className={`p1-bold-red`}>See All</p>
                  <ChevronRight />
                </button>
              </div>
            </section>
            <div className={`${styles.line}`} />
            <div className={`${styles.sectionTitle}`}>
              <h2 className={`h2-22`}>Liked</h2>
              <button className={`${styles.editBox}`}>
                <ChevronRight stroke={2} />
              </button>
            </div>
            <div className={`${styles.line}`} />
            <div className={`${styles.sectionTitle}`}>
              <h2 className={`h2-22`}>Disliked</h2>
              <button className={`${styles.editBox}`}>
                <ChevronRight stroke={2} />
              </button>
            </div>
            <div className={`${styles.line}`} />
          </main>
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}
