import React from "react";

export default function ChevronRight({ stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
    >
      <path
        d="M1 9L5 5L1 1"
        strokeWidth={stroke || 1}
        stroke="#951901"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
