import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./RecommendedWines.scss";

import profileCartBtn from "../../../assets/images/profile-cart.svg";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import { isMobile } from "react-device-detect";
import recommendationResponseImage from "../../../assets/images/EmptyPageCard/recommendation-response-image.svg";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import * as constants from "../../constants/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import WineDetails from "../../components/WineDetails/WineDetails";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import PageNotFound from "../PageNotFound/PageNotFound";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import CartIconComponent from "../../components/CartIcon/CartIcon";
import axiosCall from "../../constants/index";
import TastePreferencePopUp from "../../components/TastePreferencePopUp/TastePreferencePopUp";
import * as commonFunctions from "../../constants/commonFunctions";
import { connect } from "react-redux";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import moment from "moment";
import { isEmbedded } from '../../constants/commonFunctions';

toast.configure();

let wineList = [1, 2, 3, 4, 5];
class RecommendedWines extends Component {
  intervalId = 0;

  constructor(props) {
    super(props);
    this.state = {
      backBtnStatus: false,
      responseForQuestion: "",
      wineryDetails: "",
      errorOccurred: "",
      errorMessage: "",
      questionId: "",
      wineryId: JSON.parse(localStorage.getItem("winery_id")),
      showTastePreferencePopUp: false,
    };
  }

  componentDidMount = () => {
    ///
    
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    this.setState({
      wineryId: wineryId,
    });

    this.getWineryDetailsFromWineryId(); // to display winery name

    let questionId;

    try {
      questionId = this.props.match.params.questionId;
    } catch (e) {
      questionId = this.props.match.params.questionId;
    }
    // console.log(questionId)
    this.setState({
      questionId: questionId,
    });
    this.getResponseForQuestionFromWineryAdmin(questionId);

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    
    // Check taste preference is there or not and open taste preference pop-up
    setTimeout(() => {
      this.onCheckTastePreference();
    }, 3000);
    // }
  };

  isExternalReferrer = () => {
    const isExternalReferrer = document.referrer && document.referrer.indexOf(window.location.hostname) < 0;
    console.log('check external referrer', isExternalReferrer);
    return isExternalReferrer;
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  getWineryDetailsFromWineryId = () => {
    this.setState({ wineryDetails: this.props.wineryDetails });
    // let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    // let getWineryDetailsFromWineryIdApiUrl =
    //   constants.WINERY_API + "/api/v1/public/winery/" + wineryId;

    // let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    // let bearer = "Bearer " + signInToken;

    // fetch(getWineryDetailsFromWineryIdApiUrl, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: bearer,
    //   },
    // })
    //   .then((res) => {
    //     if (res.ok) {
    //       return res.json();
    //     } else {
    //       let apiErrorObj = {
    //         statusText: res.statusText,
    //         type: res.type,
    //         status: res.status,
    //         url: getWineryDetailsFromWineryIdApiUrl,
    //         operation: "GET",
    //         status: res.status,
    //       };

    //       this.postCaughtException(JSON.stringify(apiErrorObj));

    //       throw Error(res.statusText);
    //     }
    //   })
    //   .then((json) => {
    //     this.setState({
    //       wineryDetails: json,
    //     });
    //   })
    //   .catch((error) => console.error(error));
  };

  displayDishNameFromSearch = () => {
    let result = [];
    if (
      this.props.location.state.dishName != null &&
      this.props.location.state.dishName != undefined
    ) {
      result.push(
        //  CSS from SearchWineForDish.scss
        <div className="recommendation-dish-name-container">
          <div className="recommendation-dish-name form-control search-wine-form-row-input1 shadow">
            <span className="search-wine-button">
              {/* { this.props.location.state.dishName } */}
              {this.state.responseForQuestion.question}
            </span>
          </div>
        </div>
      );
    }

    return result;
  };

  gotoSearchPage = () => {
    this.setState({
      backBtnStatus: true,
    });
    // this.props.history.goBack();
  };

  getResponseForQuestionFromWineryAdmin = (questionId) => {
    let wineryIdForGetResponse = JSON.parse(localStorage.getItem("winery_id"));

    // let getResponseForQuestionApiUrl =  constants.IDENTITY_API + "/api/v1/recommendation/question/" + questionId ;
    let getResponseForQuestionApiUrl =
      constants.IDENTITY_API +
      "/api/v1/public/recommendation?questionId=" +
      questionId +
      "&wineryId=" +
      wineryIdForGetResponse;

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    fetch(getResponseForQuestionApiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getResponseForQuestionApiUrl,
            operation: "GET",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          this.setState({
            errorOccurred: "getResponseForQuestion",
            errorMessage:
              "Could not fetch your recommendations for this dish. Something went wrong. Please try again later",
          });

          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state
        // console.log(json);
        this.setState({
          responseForQuestion: json,
        });
        if (json.recommendation == null) {
          if (this.intervalId == 0) {
            this.intervalId = setInterval(
              (e) => this.getResponseForQuestionFromWineryAdmin(questionId),
              5000
            );
          }
        } else {
          this.getDealForWine();
          if (this.intervalId != 0) {
            clearInterval(this.intervalId);
          }
        }
      })
      .catch((error) => console.error(error));
  };
  getDealForWine = () => {
    try {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let renderSoptlightDealApiUrl =
        constants.WINERY_API +
        "/api/v1/public/spotlightDeal/getDealsBasedOnWineryForCurrentDate/" +
        wineryId +
        "?dateSearch=" +
        moment().utc().format("YYYY-MM-DD");
      axiosCall("GET", renderSoptlightDealApiUrl)
        .then((res) => {
          if (res.statusText == "OK") {
            let { responseForQuestion } = this.state;
            let rows = responseForQuestion.recommendation;
            if (
              responseForQuestion &&
              responseForQuestion !== null &&
              responseForQuestion.recommendation.length > 0
            ) {
              // console.log(res.data);
              
              rows = responseForQuestion.recommendation.map((wine) => {
                return {
                  ...wine,
                  deal: res.data.filter((deal) => deal.wineId == wine.id),
                };
              });
              this.setState({
                responseForQuestion: {
                  ...responseForQuestion,
                  recommendation: rows,
                },
                spotLightDeals: res.data,
              },()=>this.getWineryDiscount());
            }
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: renderSoptlightDealApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .catch((error) => console.log(error));
        console.log(this.props.location.state)
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "renderWines",
          errorMessage:
            "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
        });
      }
    }
  };
  getWineryDiscount = () => {
    try {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let renderSoptlightDealApiUrl =
        constants.WINERY_API +
        "/api/v1/public/discount/getDiscountsBasedOnWineryForCurrentDate/" +
        wineryId +
        "?dateSearch=" +
        moment().utc().format("YYYY-MM-DD");
      axiosCall("GET", renderSoptlightDealApiUrl)
        .then((res) => {
          if (res.statusText == "OK") {
            let { responseForQuestion } = this.state;
            let rows = responseForQuestion.recommendation;
            if (
              responseForQuestion &&
              responseForQuestion !== null &&
              responseForQuestion.recommendation.length > 0
            ) {
              console.log(res.data);
              rows = responseForQuestion.recommendation.map((wine) => {
                if(wine.deal && wine.deal.length > 0)
                return { ...wine}
                else return {
                  ...wine,
                  deal: res.data.filter((deal) => deal.discountWines.some(x => x.wineId === wine.id)),
                };
              });
              this.setState({
                responseForQuestion: {
                  ...responseForQuestion,
                  recommendation: rows,
                },
                discount: res.data,
              });
              if (
                this.props.location.state &&
                this.props.location.state.addToCartAutomatically &&
                !this.state.addToCartAutomatically
              ) {
                this.setState({
                  addToCartAutomatically:
                    this.props.location.state.addToCartAutomatically,
                })
                this.props.history.replace(this.props.location.pathname, {
                  ...this.props.location.state,
                  addToCartAutomatically: undefined,
                });
              }
            }
            
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: renderSoptlightDealApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .catch((error) => console.log(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "renderWines",
          errorMessage:
            "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
        });
      }
    }
  };
  isExternalReferrer = () => {
    const isExternalReferrer = document.referrer && document.referrer.indexOf(window.location.hostname) < 0;
    console.log('check external referrer', isExternalReferrer);
    return isExternalReferrer;
  }

  onCheckTastePreference = async () => {
    let popUp = localStorage.getItem("do_it_later");
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    if (popUp === null && consumerData && consumerData != null) {
      let url =
        constants.CONSUMER_API +
        "/api/v1/taste_preference/findDefaultTastePreferenceByCustomer?userId=" +
        consumerData.id;
      try {
        await axiosCall("get", url);
        this.setState({ showTastePreferencePopUp: false });
      } catch (e) {
        // If it throws error means no taste preference is present for the use
        // console.log('err response', e);
        this.setState({ showTastePreferencePopUp: true });
      }
    }
  };

  renderRecommendationDataBasedOnHistory = () => {
    // let result = [];

    if (this.state.errorOccurred == "getResponseForQuestion") {
      return (
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    }
    // if(this.props.location.state.recommendationHistoryData) {
    else if (this.state.responseForQuestion != "") {
      // let questionAttended = this.props.location.state.recommendationHistoryData.isAttended;

      // if(questionAttended) { // if response is there for question ID, get response data

      if (this.state.responseForQuestion.recommendation) {
        // if(this.state.responseForQuestion.recommendation) {

        // this.getResponseForQuestionFromWineryAdmin(questionId);

        let wineList = [];
        let data = this.state.responseForQuestion.recommendation;
        if (data != null) {
          wineList = this.state.responseForQuestion.recommendation;

          return (
            <div className="recommendations-response-container cont-pad">
              {/* <div> */}

              {/* Cart Icon with total quantity badge */}
              <CartIconComponent
                fromRecommendedPage={true}
                fromAvailableWinesPage={false}
              />

              <span className="recommendations-response-text">
                {" "}
                Here is your personalized wine recommendation for your
                {/* from
                <span className="recommendations-winery-name">
                  {this.state.wineryDetails.displayName}
                </span>
                for your */}
                <span className="recommendations-dish-name">
                  {/* {this.props.location.state.recommendationHistoryData.question ? 
                                                this.props.location.state.recommendationHistoryData.question : '' } */}
                  {this.state.responseForQuestion.question}.
                </span>
              </span>
              {this.state.responseForQuestion.recommendationNote != undefined &&
              this.state.responseForQuestion.recommendationNote != null &&
              this.state.responseForQuestion.recommendationNote != "" ? (
                <span className="recommendations-pairing-note">
                  {"Pairing Note: " +
                    this.state.responseForQuestion.recommendationNote}
                </span>
              ) : null}
              <ErrorBoundary>
                {wineList.map((el, index) => (
                  // wineList[index].message=="This wine is not available anymore" ?
                  <WineDetails
                  addToCartAutomatically={this.state.addToCartAutomatically == el.id}
                    questionIdFromRecommendationHistory={this.props.match.params.questionId}
                    wineDataFromRecommendedHistory={
                      this.state.responseForQuestion.recommendation[index]
                    }
                    id={index}
                    key={index}
                    isWineryBased={true}
                  />
                ))}
              </ErrorBoundary>
            </div>
          );
        }
      } else {
        let emptyCardData;
        if (this.state.responseForQuestion.isExcluded) {
          emptyCardData = {
            title: "",
            text: `We don't recognize '${commonFunctions.capitalize(
              this.state.responseForQuestion.question
            )}' as a food item. Please enter a different search.`,
            image: "",
          };
        } else {
          emptyCardData = {
            title: "",
            text: "Pairing is in progress… kindly give us a moment to get your recommendation.",
            image: recommendationResponseImage,
          };
        }
        return (
          <div className="empty-page-main-container">
            <EmptyPageCard emptyPageCardData={emptyCardData} />
          </div>
        );
      }
    } else if (this.state.responseForQuestion == "") {
      let questionIdFromUrl = this.props.match.params.questionId;

      // let questionId = this.props.location.state.questionId!=null && this.props.location.state.questionId!=undefined ?
      // this.props.location.state.questionId : questionIdFromUrl;
      let questionId;
      try {
        questionId = this.props.match.params.questionId;
      } catch (e) {
        questionId = this.props.match.params.questionId;
      }
      this.getResponseForQuestionFromWineryAdmin(questionId);
    }

    // return result;
  };

  renderRecommendationBasedOnStatus = () => {
    let result = [];
    let i = 0;
    result.push(
      <div key={i++} className="recommendations-container">
        {this.renderRecommendationDataBasedOnHistory()}
      </div>
    );
    return result;
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.state.backBtnStatus) {
      // return <Redirect to={{
      //     pathname: '/search-wine' + '/' + this.state.wineryId,

      // }}/>;
      this.props.history.push({
        pathname:
          "/" +
          commonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          ) +
          "/pairings",
      });
    }
  }
  render() {
    let cur_winery_id = JSON.parse(localStorage.getItem("winery_id"));
    return (
      <div
        className="success-main-container"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className=" success-inner-container  new-discover-inner-container">
          <HeaderWithBurgerIcon
            arrowIcon={true}
            title="Pairing Recommendations"
            goBackFunc={() => this.props.history.goBack()}
          />
          <div
            className={isMobile ? null : "desktop-div-scroll "}
            style={{ backgroundColor: "#f2f2f2" }}
          >
            {this.state.errorMessage ==
            "You do not have access to this page." ? (
              <div>
                <PageNotFound></PageNotFound>
              </div>
            ) : (
              this.renderRecommendationBasedOnStatus()
            )}

            {this.state.showTastePreferencePopUp && (
              <TastePreferencePopUp
                open={this.state.showTastePreferencePopUp}
                handleYes={this.handleYes} // go to select taste preference
                handleCancel={this.handleCancel} // do it later
              />
            )}
            {!this.isExternalReferrer()   &&!isEmbedded() && (cur_winery_id && cur_winery_id != 381 && cur_winery_id != 390 && cur_winery_id != 6) &&
                <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
                  <BottomNavigation />
                </div>
            }
          </div>
        </div>
      </div>
    );
  }

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  // Go to select taste preference
  handleYes = () => {
    GoogleAnalyticsFunctions.clickedSelectTastePreference();
    this.setState({
      showTastePreferencePopUp: false,
    });
    this.props.history.push({
      pathname: "/user-taste-preference",
      state: {
        path: this.props.location.pathname,
      },
    });
  };

  //Do it later
  handleCancel = () => {
    GoogleAnalyticsFunctions.clickedDoItLater();
    this.setState({
      showTastePreferencePopUp: false,
    });
    localStorage.setItem("do_it_later", true);
  };

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
const mapStateToProps = (state) => {
  return { wineryDetails: state.WineryReducer.currentWineryDetails };
};
export default connect(mapStateToProps, null)(RecommendedWines);
