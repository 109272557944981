import React, { Component } from "react";
import "../AvailableWineCard/AvailableWineCard.scss";
import "./SpotlightDealsCard.scss";
import { Redirect } from "react-router-dom";
import cartIcon from "../../../assets/images/cart-wine-details.svg";
import wineBottle from "../../../assets/images/wine-bottle.png";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";
import * as constants from "../../constants/constants";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { withRouter } from "react-router-dom";
import CartQtyIncrementDecrement from "../CartQuantityIncrementDecrement/CartQuantityIncrementDecrement";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddedToCartPopUp from "../../components/AddedToCartPopUp/AddedToCartPopUp";
import * as commonFunctions from "../../constants/commonFunctions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AddToCartAction from "../../redux/actions/AddToCartAction";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import NewConfirmCommonPopUp from "../NewConfirmCommonPopup/NewConfirmCommonPopup";
import * as GetCartTotalQtyAction from "../../redux/actions/GetCartTotalQtyAction";
// import ShareButton from "../ShareButton/ShareButton"
import ShareButton from "../../components/ShareButton/ShareButton";
import LikeButton from "../../components/LikeButton/LikeButton";
import axiosCall from "../../constants";
import SignInToContinue from "../CommonComponents/SignInToContinue";
import { Badge } from "@material-ui/core";
import { setCurrentWineryDetails } from "../../redux/actions/WineryAction";
import discountTag from "../../../assets/images/discountTag.svg";
import { isMobile } from "react-device-detect";
let consumerData;
let wineryId;
// wine_bottle_placeholder1 , wine_bottle_placeholder
toast.configure();

class SpotlightDealsCard extends React.PureComponent {
  constructor(props) {
    // consumerData = JSON.parse(localStorage.getItem("user_data"));
    // wineryId = JSON.parse(localStorage.getItem("winery_id"));
    super(props);
    this.state = {
      individualWineDataPageStatus: false,
      loaded: false,
      showAddedToCartPopUp: false,
      wineryId: "",
      wineId: "",
      addToCartApiCall: false,
      isNavigateToChatPage: false,
      isNavigateToNewBrowser: false,
      navigationWineUrl: "",
      navigationWebsite: "",
      addToCartSuccess: "",
      addToCartFail: "",
      cartProceedToBuyText: "Proceed to Buy",
      cartContinueShoppingText: "Continue Shopping",
      headerText: "Added to Cart.",
      disableAddtoCartOnApiCall: false,
      signInPopUp: false,
    };
  }

  showImage = () => {
    this.setState({
      loaded: true,
    });
  };
  renderCards = () => {
    let dealData = this.props.dealData;
    if (dealData != undefined || dealData != null) {
      let wineData = this.props.dealData.wine;
      let wineryData = this.props.dealData.winery;
      let discountPercent =
        dealData && dealData.discount
          ? dealData.discount
          : dealData
          ? (dealData.dollarsOff / wineData.price[0].price) * 100
          : "";
      let discountPrice =
        dealData && dealData.dollarsOff
          ? dealData.dollarsOff
          : dealData
          ? (wineData.price[0].price * dealData.discount) / 100
          : "";
      return (
        <React.Fragment>
          <div className="available-wine-details-list ptr-clss">
            <div className="wine-text-div">
              <div className="discount-like-box">
                {dealData ? (
                  // <div className="render-flex">
                  //   <img className="badge-place" src={discountTag} />
                  <div className={"badge-text-avail"}>
                    {dealData.discount
                      ? parseInt(dealData.discount) + "% Off"
                      : "$" + parseInt(dealData.dollarsOff) + " Off"}
                  </div>
                ) : // </div>
                null}
              </div>

              <div
                className="available-wine-name-container"
                onClick={() => this.gotoIndividualWineDetailsPage(dealData)}
              >
                <div className="available-wine-subcontainer">
                  <span className="spot-winery-name">
                    {wineryData.displayName != null &&
                    wineryData.displayName != undefined
                      ? wineryData.displayName
                      : "Wine Name"}
                  </span>
                  <span className={"deal-name"}>
                    {wineData.wineName != null && wineData.wineName != undefined
                      ? wineData.wineName
                      : "Wine Name"}
                  </span>
                  <span className={"spot-wine-type"}>
                    {wineData.wineType != null && wineData.wineType != undefined
                      ? wineData.wineType
                      : "Wine Type"}
                  </span>

                  {/* <span className="available-wine-subtype-title">
                {wineData.specifications.varietal != null &&
                wineData.specifications.varietal != undefined
                  ? wineData.specifications.varietal
                  : ""}
              </span>

              <span className="available-wine-subtype-title">
                {wineData.specifications.appellation != null &&
                wineData.specifications.appellation != undefined
                  ? wineData.specifications.appellation
                  : ""}
              </span> */}

                  {/* <span className = "available-wine-price-text">
                    { (wineData.price!=null && wineData.price!=undefined )? '$' + wineData.price[0].price : '$45'}

                    </span> */}
                </div>
                {/* <img className="cart-icon" src = {cartIcon} /> */}
                {/* <span className="available-wine-price-text cart-icon">
                {wineData.price != null &&
                wineData.price != undefined
                  ? commonFunctions.formatter.format(wineData.price[0].price)
                  : " "}
              </span> */}
              </div>
              <div className="available-wine-subcontainer">
                <div className="render-flex">
                  {wineData.price[0].price ? (
                    dealData ? (
                      <div className="render-flex">
                        <span className="type-text-price">
                          {wineData.price[0].price != null &&
                          wineData.price[0].price != undefined
                            ? commonFunctions.formatter.format(
                                wineData.price[0].price -
                                  parseFloat(discountPrice)
                              )
                            : " "}
                        </span>
                        <span className="spot-wine-price-text strike-text cart-icon">
                          <del>
                            {wineData.price[0].price != null &&
                            wineData.price[0].price != undefined
                              ? commonFunctions.formatter.format(
                                  wineData.price[0].price
                                )
                              : " "}
                          </del>
                        </span>
                      </div>
                    ) : (
                      <span className="type-text-price">
                        {wineData.price[0].price
                          ? commonFunctions.formatter.format(
                              wineData.price[0].price
                            )
                          : ""}
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            
            <div
              className="box"
              onClick={() => this.gotoIndividualWineDetailsPage(dealData)}
            >
              {/* <div className="like">
                <ShareButton wine={this.getWineDetails()} />
                <LikeButton
                  wine={{
                    wineId: this.state.wineId,
                    wineryId: this.state.wineryId,
                    // "id": this.state.id,
                  }}
                />
              </div> */}
              <div className="wine-logo">
                <div className="available-wine-bottle-logo-container">
                  <img
                    onLoad={this.showImage}
                    alt="Available wines logo"
                    className={
                      wineData != null &&
                      wineData != undefined &&
                      wineData.wineImageSmall != "" &&
                      wineData.wineImageSmall != null
                        ? "available-wine-bottle-logo-from-api"
                        : "available-wine-bottle-logo"
                    }
                    src={
                      wineData.wineImageSmall != null &&
                      wineData.wineImageSmall != "" &&
                      wineData != undefined
                        ? this.state.loaded
                          ? wineData.wineImageSmall
                          : wineBottlePlaceholder
                        : wineBottlePlaceholder
                    }
                  />
                </div>
              </div>
              {/* <div
                {...{
                  className: "share",
                  onClick: (e) => {
                    // console.log(this.props)
                    e.stopPropagation();
                  },
                }}
              >
                <ShareButton wine={this.getWineDetails()} />
              </div> */}

              {/* <img  
                    className = { (wineData!=null && wineData!=undefined && 
                        wineData.wineImageSmall!="" && wineData.wineImageSmall!=null )? "available-wine-bottle-logo-from-api" :
                                                                  "available-wine-bottle-logo"}

             
                        src = { (wineData.wineImageSmall!=null && wineData.wineImageSmall!="" 
                            && wineData!=undefined )? wineData.wineImageSmall : wineBottlePlaceholder}
                    /> */}
            </div>
          </div>
          <div className="add-to-cart-button">
            {/* <div className="wine-details-order-cart add-to-cart available-wines-add-to-cart-width mt-1"> */}
            <button
              className="btn btn-round spot-wines-view-btn"
              onClick={() => this.gotoIndividualWineDetailsPage(dealData)}
              // disabled={
              //   this.state.disableAddtoCartOnApiCall ||
              //   !wineData.availableForSale
              // }
            >
              View
            </button>
            {/* </div> */}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="available-wine-details">
          <div className="available-wine-bottle-logo-container">
            <img
              className="available-wine-bottle-logo"
              alt="Available wine bottle logo"
              src={wineBottle}
            />
          </div>

          <div className="available-wine-name-container">
            <div className="available-wine-subcontainer">
              <span className="available-wine-name">Wine Name</span>
              <img alt="cart" className="cart-icon" src={cartIcon} />
              <span className="available-wine-subtype-title">Wine Type</span>
              <span className="available-wine-price-text">Wine Price</span>
            </div>
            {/* <img className="cart-icon" src = {cartIcon} /> */}
          </div>
        </div>
      );
    }

    // return result;
  };
  addToCart = () => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    if (!signInToken) {
      this.setState({ signInPopUp: true });
    } else {
      GoogleAnalyticsFunctions.addToCartForWine();
      let selectedWineryData = commonFunctions.getWineryDetailsFromLocalStorage(
        wineryId,
        this.props.wineryListFromRedux
      );
      if (
        selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.usePAShoppingCart
      ) {
        this.setState({
          disableAddtoCartOnApiCall: true,
        });
        this.addWineToCartAPI();
      } else if (
        selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.useWineryWebsiteForOrders
      ) {
        if (this.props.dealData.wine.wineUrl) {
          this.onOpenWineUrlInNewTab(this.props.dealData.wine.wineUrl);
        } else if (selectedWineryData.website) {
          this.onOpenWineUrlInNewTab(selectedWineryData.website);
        } else {
          this.onNavigatingToChatPage();
        }
      } else {
        this.onNavigatingToChatPage();
      }
    }
  };

  addWineToCartAPI = () => {
    let apiPostData = {
      wineId: this.props.dealData.wine.id,
      unitPrice: this.props.dealData.wine.price[0].price,
    };
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let addToCartApiUrl =
      constants.SHOPPING_CART_API +
      "/api/v1/users/winery/" +
      wineryId +
      "/cart/addItems";
    axiosCall("POST", addToCartApiUrl, apiPostData)
      .then((response) => {
        if (response.statusText == "OK") {
          let respJSON = response.data;
          if (!respJSON.errors) {
            this.setState({
              showAddedToCartPopUp: true,
              disableAddtoCartOnApiCall: false,
            });
            let totalCartQuantity = this.props.cartTotalQuantity + 1;
            this.props.GetCartTotalQtyAction.UpdateCartQuantity(
              totalCartQuantity
            );
          } else {
            this.setState({
              disableAddtoCartOnApiCall: false,
            });
            // console.log(respJSON.errors);
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        } else {
          let apiErrorObj = {
            statusText: response.statusText,
            type: response.type,
            status: response.status,
            url: addToCartApiUrl,
            operation: "GET",
            status: response.status,
          };
          commonFunctions.postCaughtException(
            JSON.stringify(apiErrorObj),
            consumerData,
            wineryId
          );
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          throw Error(response.statusText);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          disableAddtoCartOnApiCall: false,
        });
      });
  };

  handleYes = () => {
    GoogleAnalyticsFunctions.proceedToBuy();
    // console.log('Yes Click');
    this.setState({
      showAddedToCartPopUp: false,
    });
    this.props.history.push("/shopping-cart");
  };
  handleCancel = () => {
    GoogleAnalyticsFunctions.continueShopping();
    // console.log('Cancel Click');
    this.setState({
      showAddedToCartPopUp: false,
    });
  };

  gotoIndividualWineDetailsPage = (dealData) => {
    localStorage.setItem("winery_id", dealData.winery.id);
    localStorage.setItem("winery_name", dealData.winery.displayName);
    this.props.setCurrentWineryDetails(dealData.winery);
    // toast.info("Now Viewing " + dealData.winery.displayName, {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });
    this.setState({
      individualWineDataPageStatus: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.individualWineDataPageStatus &&
      this.props.dealData.wine != null &&
      this.props.dealData.wine != undefined
    ) {
      this.props.history.push({
        pathname: "/individual-wine-details",
        state: {
          individualWineDetailsFromAvailableWines: true,
          wineIndividualData: this.props.dealData.wine,
          dealData: this.props.dealData,
        },
      });
    } else if (this.state.isNavigateToChatPage) {
      this.onNavigatingToChatPage();
    } else if (this.state.isNavigateToNewBrowser) {
      if (this.state.wineUrl) {
        this.onOpenWineUrlInNewTab(this.state.wineUrl);
      } else if (this.state.website) {
        this.onOpenWineUrlInNewTab(this.state.website);
      }
    }
  }

  render() {
    return (
      <div
        // onClick={this.gotoIndividualWineDetailsPage.bind(this)}
        className="spot-card shadow"
      >
        <SignInToContinue
          signInPopUp={this.state.signInPopUp}
          url={this.props.location.pathname}
          addToCartAutomatically={true}
          where={"to Add to cart."}
          handleClose={() => this.setState({ signInPopUp: false })}
        />
        {this.renderCards()}
        {this.state.showAddedToCartPopUp && (
          <NewConfirmCommonPopUp
            open={this.state.showAddedToCartPopUp}
            handleYes={this.handleYes}
            handleCancel={this.handleCancel}
            headerText={this.state.headerText}
            confirmText={this.state.cartProceedToBuyText}
            cancelText={this.state.cartContinueShoppingText}
            addToCartClick={this.state.showAddedToCartPopUp}
          />
        )}
        {/* <AddedToCartPopUp open={this.state.showAddedToCartPopUp} /> */}
      </div>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // coming from mapStateToProps
    let returnValue = {};
    if (nextProps.wineData != undefined) {
      returnValue.wineryId = nextProps.wineData.wineryId;
      returnValue.wineId = nextProps.wineData.id;
    }
    if (prevState.addToCartApiCall === true) {
      if (nextProps.addToCartSuccess != undefined) {
        if (nextProps.addToCartSuccess.usesPAShoppingCart === false) {
          if (nextProps.addToCartSuccess.wineUrl) {
            returnValue.isNavigateToChatPage = false;
            returnValue.isNavigateToNewBrowser = true;
            returnValue.website = "";
            returnValue.wineUrl = nextProps.addToCartSuccess.wineUrl;
            returnValue.addToCartApiCall = false;
            return returnValue;
          } else if (nextProps.addToCartSuccess.website) {
            returnValue.isNavigateToChatPage = false;
            returnValue.isNavigateToNewBrowser = true;
            returnValue.website = nextProps.addToCartSuccess.website;
            returnValue.wineUrl = "";
            returnValue.addToCartApiCall = false;
            return returnValue;
          } else {
            returnValue.isNavigateToChatPage = true;
            returnValue.isNavigateToNewBrowser = false;
            returnValue.addToCartApiCall = false;
            return returnValue;
          }
        } else {
          returnValue.isNavigateToChatPage = true;
          returnValue.isNavigateToNewBrowser = false;
          returnValue.addToCartApiCall = false;
          return returnValue;
        }
      } else if (nextProps.addToCartFail != undefined) {
        returnValue.isNavigateToChatPage = true;
        returnValue.isNavigateToNewBrowser = false;
        returnValue.addToCartApiCall = false;
        return returnValue;
      }
    }
    return returnValue;
  }

  onNavigatingToChatPage = () => {
    this.props.history.push({
      pathname: "/chat" + "/" + this.state.wineryId,
      state: {
        openChatForWinePurchase: true,
        wineIndividualData: this.props.dealData.wine,
      },
    });
  };

  onOpenWineUrlInNewTab = (url) => {
    this.setState({
      isNavigateToNewBrowser: false,
    });
    if (!url.includes("http://") && !url.includes("https://")) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  };
}

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    addToCartSuccess: state.AddToCartReducer.addToCartSuccess,
    addToCartFail: state.AddToCartReducer.addToCartFail,
    cartTotalQuantity: state.GetCartTotalQtyReducer.cartTotalQuantity,
    wineryListFromRedux: state.WineryReducer.wineryList,
    wineryDetails: state.WineryReducer.currentWineryDetails,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    AddToCartAction: bindActionCreators(AddToCartAction, dispatch),
    GetCartTotalQtyAction: bindActionCreators(GetCartTotalQtyAction, dispatch),
    setCurrentWineryDetails: bindActionCreators(
      setCurrentWineryDetails,
      dispatch
    ),
  };
}

SpotlightDealsCard = withRouter(SpotlightDealsCard);

export default connect(mapStateToProps, mapDispatchToProps)(SpotlightDealsCard);

// export default AvailableWineCard;
