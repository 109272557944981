import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./discover.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import routes from "./routes";
import Header from "../../components/CommonComponents/header";
import Layout from "../../components/CommonComponents/layout";

export default function Discover() {
  const history = useHistory();

  useEffect(() => {
    logPagesVisitedByUser();
  }, []);

  return (
    <Layout>
      <div style={isMobile ? null : { overflowY: "auto" }}>
        <Header />
        <div className="outer-container bg-none unset-children">
          <div className="thanks-fonts-style">
            <span className="font-success font-slim">
              What would you like to discover today?
            </span>
          </div>

          <div className={`${styles.container} slide-from-left row`}>
            {routes.map((route) => {
              return (
                <div
                  className={`card ${styles.card} ${styles.selectCard} shadow cursor-pointer new-discover-select-card`}
                  key={route.link || route.title.toLowerCase()}
                  onClick={() => {
                    if (route.onClick) route.onClick();
                    if (route.link) history.push(route.link);
                  }}
                >
                  <div className={styles.cardDetail}>
                    <div className={`${styles.logoContainer} slide-from-left`}>
                      <img
                        draggable="false"
                        alt={route.link}
                        src={route.icon}
                      />
                    </div>

                    <div className={styles.nameContainer}>
                      <div className={styles.subContainer}>
                        <span className={styles.cardText}>{route.title}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

// class CustomerPassportDiscover extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount = () => {};

//   render() {
//     let discoverOptions = [
//       "Pairings",
//       "Taste Quiz",
//       "Wineries",
//       "Shop",
//       // "Virtual Tasting",
//       //"Blog",
//     ];
//     return (
//       <React.Fragment>
//         <div className="success-main-container font-family">
//           <div
//             className="success-inner-container new-discover-inner-container"
//             style={isMobile ? null : { overflowY: "auto " }}
//           >
//             <div className="header-container">
//               <div className="success-logo-container new-discover-logo-container">
//                 <img alt="PairAnything" className="logo-wwidth" src={logo} />
//               </div>
//             </div>
//             <div className="outer-container-div bg-none new-discover-outer-container-div">
//               <div className="thanks-fonts-style">
//                 {/* <TastePreference /> */}

//                 <div className="">
//                   <span className="order-success-txt ft-500">
//                     What would you like to discover today?
//                   </span>
//                 </div>
//               </div>
//               <div className="discover-container row new-discover-container">
//                 {discoverOptions.map((el, index) => {
//                   return (
//                     <div
//                       key={el}
//                       onClick={() => this.onClickDiscoverCard(el)}
//                       className="card select-winery-card-card shadow ptr-clss new-discover-select-card"
//                     >
//                       {this.renderDiscoverSelectionCards(el)}
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         </div>
//       </React.Fragment>
//     );
//   }
//   onClickDiscoverCard = (el) => {
//     if (el === "Wineries") {
//       GoogleAnalyticsFunctions.clickedVisit();
//       this.props.history.push("/visit");
//     } else if (el === "Virtual Tasting") {
//       GoogleAnalyticsFunctions.clickedVirtualTasting();
//       this.props.history.push("/virtual-tasting");
//     } else if (el === "Blog") {
//       GoogleAnalyticsFunctions.clickedKnow();
//       let knowUrl = "https://www.pairanything.com/our-blog";
//       window.open(knowUrl, "_blank");
//     } else if (el === "Pairings" || el === "Taste Quiz") {
//       GoogleAnalyticsFunctions.clickedEnjoy();
//       this.navigationLogic(el);
//     } else {
//       GoogleAnalyticsFunctions.clickedShop();
//       this.navigationLogic(el);
//     }
//   };
//   renderDiscoverSelectionCards = (el) => {
//     return (
//       <div className="select-winery-card-details">
//         <div className="select-winery-card-bottle-logo-container">
//           <img
//             alt={el}
//             className="new-discover-card-logo"
//             src={
//               el === "Pairings"
//                 ? Pairings
//                 : el === "Taste Quiz"
//                 ? TasteQuiz
//                 : el === "Shop"
//                 ? Shop
//                 : el === "Wineries"
//                 ? Wineries
//                 : el === null
//             }
//           />
//         </div>

//         <div className="select-winery-card-name-container">
//           <div className="select-winery-card-subcontainer">
//             <span className="select-winery-card-name">{el}</span>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   navigationLogic = (discoverPage) => {
//     let userWineryList = JSON.parse(localStorage.getItem("winery_list"));
//     let userToken = localStorage.getItem("signin_token");
//     if (discoverPage === "Pairings") {
//       this.props.history.push("/pa-recommendations");
//     } else if (discoverPage === "Taste Quiz") {
//       this.props.history.push("/TastePreferenceQuiz");
//     } else {
//       this.props.history.push("/wineries");
//       // this.props.history.push("/select-winery")
//     }
//     // else if (userToken) {
//     //   if (userWineryList.length === 1) {
//     //     if (userWineryList[0].wineryUserStatus === 3) {
//     //       this.props.history.push("/profile");
//     //     } else {
//     //       // if (discoverPage === 'Enjoy') {
//     //       //     let url = "/search-wine" + "/" + userWineryList[0].wineryId;
//     //       //     this.props.history.push(url);
//     //       // } else {
//     //       // If Wines navigate to wines page
//     //       this.props.history.push("/available-wines");
//     //       // }
//     //     }
//     //   } else if (userWineryList.length > 1) {
//     //     // Check user is active in any one of the winery
//     //     let userActiveWineries = userWineryList.filter(
//     //       (x) => x.wineryUserStatus === 2
//     //     );
//   };
// }
