import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
            
        case types.ADD_TO_RSVP_SUCCESS:
            return {
                ...state,
                addToRsvpSuccess: action.payload
            };

        case types.ADD_TO_RSVP_FAILED:
            return {
                ...state,
                addToRsvpFail: action.payload
            };

        default:
            return state;
    }
};
