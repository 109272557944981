import React, { Component } from "react";
import "./WineDetails.scss";
import "../AvailableWineCard/AvailableWineCard.scss";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import cartIcon from "../../../assets/images/cart-wine-details.svg";
import wineBottle from "../../../assets/images/wine-bottle.png";
import winePlaceholder from "../../../assets/images/wine-placeholder.jpg";
import * as constants from "../../constants/constants";
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import { withRouter } from "react-router-dom";
import CartQtyIncrementDecrement from "../CartQuantityIncrementDecrement/CartQuantityIncrementDecrement";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import AddedToCartPopUp from "../../components/AddedToCartPopUp/AddedToCartPopUp";
import * as commonFunctions from "../../constants/commonFunctions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AddToCartAction from "../../redux/actions/AddToCartAction";
import { isMobile } from "react-device-detect";
import NewConfirmCommonPopUp from "../NewConfirmCommonPopup/NewConfirmCommonPopup";
import ShareButton from "../ShareButton/ShareButton";
import * as GetCartTotalQtyAction from "../../redux/actions/GetCartTotalQtyAction";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";
import SignInToContinue from "../CommonComponents/SignInToContinue";
toast.configure();
let consumerData;
let wineryId;
class WineDetails extends Component {
  constructor(props) {
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    super(props);
    if (this.props.isWineryBased) {
      wineryId = JSON.parse(localStorage.getItem("winery_id"));
    }
    this.state = {
      individualWineDetailsPageLoaded: false,
      buyNowClicked: false,
      showAddedToCartPopUp: false,
      wineryId: "",
      wineId: "",
      addToCartApiCall: false,
      isNavigateToChatPage: false,
      isNavigateToNewBrowser: false,
      navigationWineUrl: "",
      navigationWebsite: "",
      addToCartSuccess: "",
      addToCartFail: "",
      cartProceedToBuyText: "Proceed to Buy",
      cartContinueShoppingText: "Continue Shopping",
      headerText: "Added to Cart.",
      disableAddtoCartOnApiCall: false,
      signInPopUp: false,
    };
  }

  skipPreferencesOnClick = () => {};

  gotoPrevScreen = () => {};

  viewIndividualWineDetails = () => {
    GoogleAnalyticsFunctions.clickedRecommendationForDish();

    this.setState({
      individualWineDetailsPageLoaded: true,
    });
  };

  buyNowClicked = () => {
    GoogleAnalyticsFunctions.clickedBuyNowForWine();

    this.setState({
      buyNowClicked: true,
    });
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.props.addToCartAutomatically != prevProps.addToCartAutomatically) {
      this.addToCart();
    }
    if (this.state.buyNowClicked) {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));

      // return <Redirect to={{
      //     pathname: '/chat' + '/' + wineryId,
      //     state: {
      //             openChatForWinePurchase: true,
      //             wineIndividualData: this.props.wineDataFromRecommendedHistory ,
      //            }

      // }}/>;
      this.props.history.push({
        pathname: "/chat" + "/" + wineryId,
        state: {
          openChatForWinePurchase: true,
          wineIndividualData: this.props.wineDataFromRecommendedHistory,
        },
      });
    } else if (this.state.individualWineDetailsPageLoaded) {
      // if(this.props.location.state.dishName!=null || this.props.location.state.dishName!=undefined) {

      // return <Redirect to={{
      //     pathname: '/individual-wine-details',
      //     state: {
      //                individualWineDetailsFromSearch: true,
      //                questionIdForCurrentWineFromRecommendationHistory: this.props.questionIdFromRecommendationHistory,
      //                wineIndividualData: this.props.wineDataFromRecommendedHistory
      //            }

      // }}/>;
      this.props.history.push({
        pathname: "/individual-wine-details",
        state: {
          // individualWineDetailsFromSearch: true,
          questionIdForCurrentWineFromRecommendationHistory:
            this.props.questionIdFromRecommendationHistory,
          wineIndividualData: prevProps.wineDataFromRecommendedHistory,
        },
      });
      // }
    } else if (this.state.isNavigateToChatPage) {
      this.onNavigatingToChatPage();
    } else if (this.state.isNavigateToNewBrowser) {
      if (this.state.wineUrl) {
        this.onOpenWineUrlInNewTab(this.state.wineUrl);
      } else if (this.state.website) {
        this.onOpenWineUrlInNewTab(this.state.website);
      }
    }
  }

  onRenderClassNamesForCardHeader() {
    let className = "card shadow p-3 mb-5 rounded";
    if (
      this.props &&
      this.props.wineDataFromRecommendedHistory &&
      this.props.wineDataFromRecommendedHistory.isDeleted
    ) {
      className += " wine-details-card-not-available";
    }
    className += " wine-details-card-available";
    return className;
  }

  getWineDetails = () => {
    this.viewIndividualWineDetails.bind(this);
    //console.log(this.state)
    const wine = [
      {
        id: this.state.wineId,
        image: this.props.wineDataFromRecommendedHistory.wineImageSmall,
        name: this.props.wineDataFromRecommendedHistory.wineName,
        type: this.props.wineDataFromRecommendedHistory.wineType,
        varietal:
          this.props.wineDataFromRecommendedHistory.specifications.varietal,
        appellation:
          this.props.wineDataFromRecommendedHistory.specifications.appellation,
        dish_pairing: false,
        wineryId: this.state.wineryId,
        url: `${constants.CONSUMER_APP_URL}`,
      },
    ];

    return wine;
  };
  render() {
    var buttonText;
    let cur_winery_id = JSON.parse(localStorage.getItem("winery_id"));
    if (this.props && this.props.wineDataFromRecommendedHistory !== undefined) {
      if (!this.props.wineDataFromRecommendedHistory.availableForSale) {
        buttonText = "Coming Soon";
      } else {
        if (!this.props.wineDataFromRecommendedHistory.isDeleted) {
          buttonText = "Add to Cart";
        } else {
          buttonText = "No Longer Available";
        }
      }
    }
    let dealData = this.props.wineDataFromRecommendedHistory.deal;
    let discountPercent =
      dealData && dealData.length > 0
        ? dealData[0].discount
          ? dealData[0].discount
          : (dealData[0].dollarsOff /
              this.props.wineDataFromRecommendedHistory.price[0].price) *
            100
        : "";
    let discountPrice =
      dealData && dealData.length > 0
        ? dealData[0].dollarsOff
          ? dealData[0].dollarsOff
          : (this.props.wineDataFromRecommendedHistory.price[0].price *
              dealData[0].discount) /
            100
        : "";

    let selectedWineryData = commonFunctions.getWineryDetailsFromLocalStorage(
        wineryId,
        this.props.wineryListFromRedux
    );

    return this.props &&
      this.props.wineDataFromRecommendedHistory &&
      this.props.wineDataFromRecommendedHistory.message ==
        "This wine is not available anymore" ? (
      " "
    ) : (
      // <span>
      //     This wine is not available anymore
      //
      // </span>
      <div className="card shadow wd-card">
        <React.Fragment>
          <SignInToContinue
            signInPopUp={this.state.signInPopUp}
            url={this.props.location.pathname}
            addToCartAutomatically={
              this.props.wineDataFromRecommendedHistory.id
            }
            where={"to Add to cart."}
            handleClose={() => this.setState({ signInPopUp: false })}
          />
          <div
            className="available-wine-details-list ptr-clss"
            onClick={() => this.viewIndividualWineDetails()}
          >
            <div className="wine-text-div">
              <div className="discount-like-box">
                {dealData && dealData.length > 0 ? (
                  <div className="render-flex">
                    {/* <img className="badge-place" src={discountTag} /> */}
                    <span className={"badge-text-avail"}>
                      {dealData[0].discount
                        ? parseInt(dealData[0].discount) + "% Off"
                        : "$" + parseInt(dealData[0].dollarsOff) + " Off"}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="available-wine-name-container">
                <div className="available-wine-subcontainer">
                  <span className={"deal-name deal-margin"}>
                    {this.props.wineDataFromRecommendedHistory.wineName !=
                      null &&
                    this.props.wineDataFromRecommendedHistory.wineName !=
                      undefined
                      ? this.props.wineDataFromRecommendedHistory.wineName
                      : "Wine Name"}
                  </span>
                  <span
                    className={
                      isMobile
                        ? "spot-wine-type"
                        : "available-wine-subtype-title"
                    }
                  >
                    {this.props.wineDataFromRecommendedHistory.wineType !=
                      null &&
                    this.props.wineDataFromRecommendedHistory.wineType !=
                      undefined
                      ? this.props.wineDataFromRecommendedHistory.wineType
                      : "Wine Type"}
                  </span>

                  <span
                    className={
                      isMobile
                        ? "spot-wine-type"
                        : "available-wine-subtype-title"
                    }
                  >
                    {this.props.wineDataFromRecommendedHistory.specifications
                      .varietal != null &&
                    this.props.wineDataFromRecommendedHistory.specifications
                      .varietal != undefined
                      ? this.props.wineDataFromRecommendedHistory.specifications
                          .varietal
                      : ""}
                  </span>

                  <span
                    className={
                      isMobile
                        ? "spot-wine-type"
                        : "available-wine-subtype-title"
                    }
                  >
                    {this.props.wineDataFromRecommendedHistory.specifications
                      .appellation != null &&
                    this.props.wineDataFromRecommendedHistory.specifications
                      .appellation != undefined
                      ? this.props.wineDataFromRecommendedHistory.specifications
                          .appellation
                      : ""}
                  </span>
                </div>
              </div>
              <div className="available-wine-subcontainer">
                <div className="">
                  {this.props.wineDataFromRecommendedHistory.price[0].price ? (
                    dealData && dealData.length > 0 ? (
                      <div className="render-flex">
                        <span className="type-text-price">
                          {this.props.wineDataFromRecommendedHistory.price[0]
                            .price != null &&
                          this.props.wineDataFromRecommendedHistory.price[0]
                            .price != undefined
                            ? commonFunctions.formatter.format(
                                this.props.wineDataFromRecommendedHistory
                                  .price[0].price - parseFloat(discountPrice)
                              )
                            : " "}
                        </span>
                        <span className="spot-wine-price-text strike-text cart-icon">
                          <del>
                            {this.props.wineDataFromRecommendedHistory.price[0]
                              .price != null &&
                            this.props.wineDataFromRecommendedHistory.price[0]
                              .price != undefined
                              ? commonFunctions.formatter.format(
                                  this.props.wineDataFromRecommendedHistory
                                    .price[0].price
                                )
                              : " "}
                          </del>
                        </span>
                      </div>
                    ) : (
                      <span className="type-text-price">
                        {this.props.wineDataFromRecommendedHistory.price[0]
                          .price
                          ? commonFunctions.formatter.format(
                              this.props.wineDataFromRecommendedHistory.price[0]
                                .price
                            )
                          : ""}
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            
            <div className="box">
              <div className="wine-logo">
                <div className="available-wine-bottle-logo-container">
                  <img
                    onLoad={this.showImage}
                    alt="Available wines logo"
                    className={
                      this.props.wineDataFromRecommendedHistory != null &&
                      this.props.wineDataFromRecommendedHistory != undefined &&
                      this.props.wineDataFromRecommendedHistory
                        .wineImageSmall != "" &&
                      this.props.wineDataFromRecommendedHistory
                        .wineImageSmall != null
                        ? "available-wine-bottle-logo-from-api"
                        : "available-wine-bottle-logo"
                    }
                    src={
                      this.props.wineDataFromRecommendedHistory
                        .wineImageSmall != null &&
                      this.props.wineDataFromRecommendedHistory
                        .wineImageSmall != "" &&
                      this.props.wineDataFromRecommendedHistory != undefined
                        ? this.props.wineDataFromRecommendedHistory
                            .wineImageSmall
                        : wineBottlePlaceholder
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="add-to-cart-button">
            <button
              className="btn btn-round available-wines-add-to-cart-btn"
              onClick={() => this.viewIndividualWineDetails()}
              disabled={
                this.state.disableAddtoCartOnApiCall ||
                !this.props.wineDataFromRecommendedHistory.availableForSale
              }
            >
              Learn More
            </button>
          </div>

          <div>
            <button
              className="btn btn-round shop-at-btn"
              onClick={this.addToCart}
              disabled={
                this.state.disableAddtoCartOnApiCall ||
                !this.props.wineDataFromRecommendedHistory.availableForSale
              }
            >
              {
              !this.props.wineDataFromRecommendedHistory.availableForSale
                  ? "Coming Soon"
                  : selectedWineryData.shoppingCartOptions ===
                  commonFunctions.shoppingCartOptions.usePAShoppingCart ? "Add to Cart" : 
                    (wineryId && wineryId == 381) ? "Shop at Target" : "Buy Now"}
            </button>
          </div>

          {this.state.showAddedToCartPopUp && (
            <NewConfirmCommonPopUp
              open={this.state.showAddedToCartPopUp}
              handleYes={this.handleYes}
              handleCancel={this.handleCancel}
              headerText={this.state.headerText}
              confirmText={this.state.cartProceedToBuyText}
              cancelText={this.state.cartContinueShoppingText}
              addToCartClick={this.state.showAddedToCartPopUp}
            />
          )}
        </React.Fragment>
      </div>
    );
  }

  addToCart = () => {
    GoogleAnalyticsFunctions.addToCartForWine();
    // this.setState({
    //   addToCartApiCall: true,
    //   addToCartSuccess: undefined,
    //   addToCartFail: undefined,
    //   isNavigateToChatPage: false,
    //   isNavigateToNewBrowser: false,
    //   wineUrl: "",
    //   website: "",
    // });
    // this.props.AddToCartAction.AddToCartDummyApi();
    // this.props.AddToCartAction.AddToCart(
    //   this.state.wineryId,
    //   this.state.wineId
    // );

    // this.setState({
    //   showAddedToCartPopUp: true,
    // });
    // setTimeout(() => {
    //   this.setState({
    //     showAddedToCartPopUp: false,
    //   });
    // }, 2000);
    // Writing new logic and api integration for add to cart scenario
    let selectedWineryData = commonFunctions.getWineryDetailsFromLocalStorage(
        wineryId,
        this.props.wineryListFromRedux
    );

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    if (!signInToken && (selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.usePAShoppingCart)
    ) {
      this.setState({ signInPopUp: true });
    } else {
      if (
        selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.usePAShoppingCart
      ) {
        this.setState({
          disableAddtoCartOnApiCall: true,
        });
        this.addWineToCartAPI();
      } else if (
        selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.useWineryWebsiteForOrders
      ) {
        /*
        if (this.props.wineDataFromRecommendedHistory.wineUrl) {
          this.onOpenWineUrlInNewTab(
            this.props.wineDataFromRecommendedHistory.wineUrl
          );
        } else if (selectedWineryData.website) {
          this.onOpenWineUrlInNewTab(selectedWineryData.website);
        }
         */
        //console.log(this.props.wineDataFromRecommendedHistory.wineUrl)

        if (this.props.wineDataFromRecommendedHistory.wineUrl) {
          this.onOpenWineUrlInNewTab(
            this.props.wineDataFromRecommendedHistory.wineUrl
          );
        } else if (selectedWineryData.website) {
          this.onOpenWineUrlInNewTab(selectedWineryData.website);
        }
        // else {
        //   this.onNavigatingToChatPage();
        // }
      } else {
        if (this.props.wineDataFromRecommendedHistory.wineUrl) {
          this.onOpenWineUrlInNewTab(
            this.props.wineDataFromRecommendedHistory.wineUrl
          );
        } else if (selectedWineryData.website) {
          this.onOpenWineUrlInNewTab(selectedWineryData.website);
        }
      }
    }
  };

  addWineToCartAPI = () => {
    let dealData = this.props.wineDataFromRecommendedHistory.deal;
    let discountPrice =
      dealData && dealData.length > 0
        ? dealData[0].dollarsOff
          ? dealData[0].dollarsOff
          : (this.props.wineDataFromRecommendedHistory.price[0].price *
              dealData[0].discount) /
            100
        : "";
    console.log(dealData);
    let apiPostData = {
      wineId: this.props.wineDataFromRecommendedHistory.id,
      unitPrice:
        this.props.wineDataFromRecommendedHistory.deal &&
        this.props.wineDataFromRecommendedHistory.deal.length > 0
          ? this.props.wineDataFromRecommendedHistory.price[0].price -
            discountPrice
          : this.props.wineDataFromRecommendedHistory.price[0].price,
    };
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let addToCartApiUrl =
      constants.SHOPPING_CART_API +
      "/api/v1/users/winery/" +
      wineryId +
      "/cart/addItems";
    fetch(addToCartApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(response);
          let apiErrorObj = {
            statusText: response.statusText,
            type: response.type,
            status: response.status,
            url: addToCartApiUrl,
            operation: "GET",
            status: response.status,
          };
          commonFunctions.postCaughtException(
            JSON.stringify(apiErrorObj),
            consumerData,
            wineryId
          );
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          throw Error(response.statusText);
        }
      })
      .then((respJSON) => {
        if (!respJSON.errors) {
          this.setState({
            showAddedToCartPopUp: true,
            disableAddtoCartOnApiCall: false,
          });
          let totalCartQuantity = this.props.cartTotalQuantity + 1;
          this.props.GetCartTotalQtyAction.UpdateCartQuantity(
            totalCartQuantity
          );
        } else {
          this.setState({
            disableAddtoCartOnApiCall: false,
          });
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          // console.log(respJSON.errors);
        }
      })
      .catch((error) => {
        this.setState({
          disableAddtoCartOnApiCall: false,
        });
        console.log(error);
      });
  };

  handleYes = () => {
    GoogleAnalyticsFunctions.proceedToBuy();
    // console.log('Yes Click');
    this.setState({
      showAddedToCartPopUp: false,
    });
    this.props.history.push("/shopping-cart");
  };
  handleCancel = () => {
    GoogleAnalyticsFunctions.continueShopping();
    // console.log('Cancel Click');
    this.setState({
      showAddedToCartPopUp: false,
    });
  };
  onNavigatingToChatPage = () => {
    this.props.history.push({
      pathname: "/chat" + "/" + this.state.wineryId,
      state: {
        openChatForWinePurchase: true,
        wineIndividualData: this.props.wineDataFromRecommendedHistory,
      },
    });
  };

  onOpenWineUrlInNewTab = (url) => {
    this.setState({
      isNavigateToNewBrowser: false,
    });
    if (!url.includes("http://") && !url.includes("https://")) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // coming from mapStateToProps
    let returnValue = {};

    if (nextProps.wineDataFromRecommendedHistory != undefined) {
      // return {
      //   wineryId: nextProps.location.state.wineIndividualData.wineryId,
      // };
      returnValue.wineryId = nextProps.wineDataFromRecommendedHistory.wineryId;
      returnValue.wineId = nextProps.wineDataFromRecommendedHistory.id;
    }
    if (prevState.addToCartApiCall === true) {
      if (nextProps.addToCartSuccess != undefined) {
        if (nextProps.addToCartSuccess.usesPAShoppingCart === false) {
          if (nextProps.addToCartSuccess.wineUrl) {
            returnValue.isNavigateToChatPage = false;
            returnValue.isNavigateToNewBrowser = true;
            returnValue.website = "";
            returnValue.wineUrl = nextProps.addToCartSuccess.wineUrl;
            returnValue.addToCartApiCall = false;
            return returnValue;
          } else if (nextProps.addToCartSuccess.website) {
            returnValue.isNavigateToChatPage = false;
            returnValue.isNavigateToNewBrowser = true;
            returnValue.website = nextProps.addToCartSuccess.website;
            returnValue.wineUrl = "";
            returnValue.addToCartApiCall = false;
            return returnValue;
          } else {
            returnValue.isNavigateToChatPage = true;
            returnValue.isNavigateToNewBrowser = false;
            returnValue.addToCartApiCall = false;
            return returnValue;
          }
        } else {
          returnValue.isNavigateToChatPage = true;
          returnValue.isNavigateToNewBrowser = false;
          returnValue.addToCartApiCall = false;
          return returnValue;
        }
      } else if (nextProps.addToCartFail != undefined) {
        returnValue.isNavigateToChatPage = true;
        returnValue.isNavigateToNewBrowser = false;
        returnValue.addToCartApiCall = false;
        return returnValue;
      }
    }
    return returnValue;
  }
}

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    addToCartSuccess: state.AddToCartReducer.addToCartSuccess,
    addToCartFail: state.AddToCartReducer.addToCartFail,
    cartTotalQuantity: state.GetCartTotalQtyReducer.cartTotalQuantity,
    wineryListFromRedux: state.WineryReducer.wineryList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AddToCartAction: bindActionCreators(AddToCartAction, dispatch),
    GetCartTotalQtyAction: bindActionCreators(GetCartTotalQtyAction, dispatch),
  };
}

WineDetails = withRouter(WineDetails);

export default connect(mapStateToProps, mapDispatchToProps)(WineDetails);
