import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./region-based-wineries.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import { WINERY_API } from "../../constants/constants";
import axiosCall from "../../constants";
import HeaderBurger from "../../components/CommonComponents/header-burger";
import Layout from "../../components/CommonComponents/layout";
import DefaultLogo from "../../../assets/images/DefaultLogo.png";
import * as commonFunctions from "../../constants/commonFunctions";
import useSWR from "swr";
import { get } from "../../lib/fetch";

export default function RegionBasedWineries() {
  const history = useHistory([]);
  const [wineries, setWineries] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  const [associations, setAssociations] = useState(undefined);
  const [regions, setRegions] = useState(undefined);
  const [filterState, setFilterState] = useState(0);
  const [mounted, setMounted] = useState(false);


  //useSWR for caching
  const { data: d1, error: e1 } = useSWR(
    mounted ? `${WINERY_API}/api/v1/public/winery/get/allActiveWinery` : null,
    (u) => get(u).then((res) => res.json())
  );

  const { data: d2, error: e2 } = useSWR(
    mounted ? `${WINERY_API}/api/v1/public/associations/get/all` : null,
    (u) => get(u).then((res) => res.json())
  );

  const { data: d3, error: e3 } = useSWR(
    mounted ? `${WINERY_API}/api/v1/public/locations/get/all` : null,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    setWineries(d1);
  }, [d1]);

  useEffect(() => {
    setAssociations(d2);
  }, [d2]);

  useEffect(() => {
    setRegions(d3);
  }, [d3]);

  useEffect(() => {
    setMounted(true);
    logPagesVisitedByUser();
  }, []);

  const onClickWinery = (el) => {
    localStorage.setItem("winery_id", JSON.stringify(el.id));
    localStorage.setItem("winery_name", el.displayName);
    //this.props.setCurrentWineryDetails(el);
    history.push("/winery/" + commonFunctions.convertToCamelCase(el.displayName));

    // history.push(
    //   "/county/" +
    //   el.county +
    //   "/" +
    //   el.id +
    //   "/wineries"
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const displayWineries = () => {
    if (wineries == undefined) return <></>;
    let newFilter = [];
    newFilter = wineries.filter(
      (winery) =>
        (winery.name != null &&
          winery.name.toUpperCase().startsWith(searchText.toUpperCase())) ||
        (winery.displayName != null &&
          winery.displayName.toUpperCase().startsWith(searchText.toUpperCase()))
    );

    return newFilter.map((winery, idx) => (
      <div
        className={`card ${styles.card} ${styles.selectCard} shadow cursor-pointer new-discover-select-card`}
        key={idx}
        onClick={() => onClickWinery(winery)}
      >
        <div className={styles.cardDetail}>
          <div className={`${styles.logoContainer} slide-from-left`}>
            <img
              className={styles.cardImage}
              draggable="false"
              alt={winery.name}
              src={winery?.logo || DefaultLogo}
            />
          </div>

          <div className={styles.nameContainer}>
            <div className={styles.subContainer}>
              <span className={styles.cardText}>{winery.name}</span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const displayRegions = () => {
    if (regions == undefined) return <></>;
    let newFilter = [];
    newFilter = regions.rows.filter(
      (region) =>
        region.county != null &&
        region.county.toUpperCase().startsWith(searchText.toUpperCase())
    );

    return newFilter.map((region, idx) => (
      <div
        className={`card ${styles.card} ${styles.selectCard} shadow cursor-pointer new-discover-select-card`}
        key={idx}
        onClick={() => {
          history.push(
            "/county/" + region?.county + "/" + region?.id + "/wineries"
          );
        }}
      >
        <div className={styles.cardDetail}>
          <div className={`${styles.logoContainer} slide-from-left`}>
            <img
              className={styles.cardImage}
              draggable="false"
              alt={region?.county}
              src={region?.locationImageUrl || DefaultLogo}
            />
          </div>

          <div className={styles.nameContainer}>
            <div className={styles.subContainer}>
              <span className={styles.cardText}>{region?.county}</span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const displayAssociations = () => {
    if (associations == undefined) return <></>;
    let newFilter = [];
    newFilter = associations.rows.filter(
      (association) =>
        association.associationsName != null &&
        association.associationsName
          .toUpperCase()
          .startsWith(searchText.toUpperCase())
    );

    return newFilter.map((association, idx) => (
      <div
        className={`card ${styles.card} ${styles.selectCard} shadow cursor-pointer new-discover-select-card`}
        key={idx}
        onClick={() => {
          history.push(
            "/association/" +
              association?.associationsName +
              "/" +
              association?.id +
              "/wineries"
          );
        }}
      >
        <div className={styles.cardDetail}>
          <div className={`${styles.logoContainer} slide-from-left`}>
            <img
              className={styles.cardImage}
              draggable="false"
              alt={association?.associationsName}
              src={association?.associationImageUrl || DefaultLogo}
            />
          </div>

          <div className={styles.nameContainer}>
            <div className={styles.subContainer}>
              <span className={styles.cardText}>
                {association?.associationsName}
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Layout>
      <HeaderBurger title="Wineries" />
      <div style={isMobile ? null : { overflowY: "auto", height: "80vh" }}>
        <div>
          <div className={`${styles.inputSelectionBox} ${styles.flexCol}`}>
            <div className={`${styles.inputContainer} shadow`}>
              <input
                placeholder="Search by winery name"
                className={`${styles.inputBox} recommendation-input`}
                value={searchText}
                onChange={handleChange}
                type="text"
                name="searchText"
                //onKeyDown={(e) =>
                //  e.key === "Enter" ? this.onSearchClick() : ""
                //}
                style={{ paddingLeft: "14px", fontSize: "18px" }}
              />
            </div>
            <div className={` ${styles.buttonBox} flex-row`}>
              <button
                className={` card ${styles.buttonText} flex-row`}
                style={{
                  color: filterState == 0 ? "#a84551" : null,
                  border: filterState == 0 ? "2px solid #a84551" : null,
                }}
                onClick={() => {
                  setFilterState(0);
                  setSearchText("");
                }}
              >
                All Wineries
              </button>
              <button
                className={` card ${styles.buttonText} flex-row`}
                style={{
                  color: filterState == 1 ? "#a84551" : null,
                  border: filterState == 1 ? "2px solid #a84551" : null,
                }}
                onClick={() => {
                  setFilterState(1);
                  setSearchText("");
                }}
              >
                Regions
              </button>
              <button
                className={` card ${styles.buttonText} flex-row`}
                style={{
                  color: filterState == 2 ? "#a84551" : null,
                  border: filterState == 2 ? "2px solid #a84551" : null,
                }}
                onClick={() => {
                  setFilterState(2);
                  setSearchText("");
                }}
              >
                Associations
              </button>
            </div>
          </div>
          <div className={styles.wineryCardBox}>
            {filterState == 0
              ? displayWineries()
              : filterState == 1
              ? displayRegions()
              : displayAssociations()}
          </div>
        </div>
      </div>
    </Layout>
  );
}
