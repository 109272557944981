
import * as types from './action-types';
import * as constants from '../../constants/constants'
import { CssBaseline } from '@material-ui/core';
import { ChatBubbleOutlined } from '@material-ui/icons';


export const CreateWineRating = (wineRatingData) => {

    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;



    return dispatch => {
        fetch(constants.WINERY_API + '/api/v1/public/wine/review', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            },
            body: JSON.stringify(wineRatingData)

        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.CREATE_WINE_RATING_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.CREATE_WINE_RATING_FAILED, payload: error })
        })
    }
}

export const GetAverageWineRating = (wineId, cb) => {

    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;

    return dispatch => {
        fetch(constants.WINERY_API + '/api/v1/public/wine/ratings/' + wineId, {

            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            },

        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.GET_AVERAGE_WINE_RATING_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.GET_AVERAGE_WINE_RATING_FAILED, payload: error })
        }).finally(cb)
    }
}
