import * as types from './action-types';
import * as constants from '../../constants/constants'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const TastePreferenceUpdateDetails = (res) => {
    let consumerData = (JSON.parse(localStorage.getItem('user_data')));
    let consumerId = consumerData.id;
    let wineryId = (JSON.parse(localStorage.getItem('winery_id')));
    let id = res.id;
    let postPreferencesApiUrl = constants.CONSUMER_API + '/api/v1/taste_preference/user/' + consumerId + '/winery/' + wineryId + '/update'
    let postPreference = res.preference;

    let apiPostTempData = {
        id: id,
        userId: consumerId,
        wineryId: null,
        tastePreferenceQuestionId: 1,
        preference: postPreference
    }

    let apiPostData = apiPostTempData;
    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;

    return dispatch => {
        fetch(postPreferencesApiUrl, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": bearer
            },
            body: JSON.stringify(
                apiPostData
            )
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                else {
                    let apiErrorObj = {
                        statusText: res.statusText,
                        type: res.type,
                        status: res.status,
                        url: postPreferencesApiUrl,
                        operation: 'PUT',
                        status: res.status
                    }
                    toast.info("Something went wrong. Please try again later.", {
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                    this.postCaughtException(JSON.stringify(apiErrorObj));
                    throw Error(res.statusText);
                }
            }).then(respJSON => {
                dispatch({ type: types.EDIT_TASTE_PREFERENCE_SUCCESS, payload: respJSON })
            }).catch(error => {
                dispatch({ type: types.EDIT_TASTE_PREFERENCE_FAILED, payload: error })
            })
    }
}