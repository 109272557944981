import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {

        case types.CANCEL_RSVP_SUCCESS:
            return {
                ...state,
                cancelRsvpSuccess: action.payload
            };

        case types.CANCEL_RSVP_FAILED:
            return {
                ...state,
                cancelRsvpFail: action.payload
            };

        default:
            return state;
    }
};
