import * as types from '../actions/action-types';

const initialState = {
    preference: {
        taste_preference: [
            {
                category: "wine_type",
                options: [
                    {
                        name: "red",
                        value: 0
                    },
                    {
                        name: "white",
                        value: 0
                    },
                    {
                        name: "rosé",
                        value: 0
                    },
                    {
                        name: "sparkling",
                        value: 0
                    },
                    {
                        name: "dessert",
                        value: 0
                    }
                ]
            },
            {
                category: "wine_characteristics",
                options: [
                    {
                        name: "fruitiness",
                        value: 0
                    },
                    {
                        name: "smoothness",
                        value: 0
                    }
                ]
            },
            {
                category: "wine_knowledge",
                options: [
                    {
                        name: "newbie",
                        value: 0
                    },
                    {
                        name: "little knowledge",
                        value: 0
                    },
                    {
                        name: "some knowledge",
                        value: 0
                    },
                    {
                        name: "expert",
                        value: 0
                    }
                ]
            },
            {
                category: "adventurous",
                options: [
                    {
                        name: "comfort seeker",
                        value: 0
                    },
                    {
                        name: "adventure seeker",
                        value: 0
                    }
                ]
            },
            {
                category: "chocolate_type",
                options: [
                    {
                        name: "dark",
                        value: 0
                    },
                    {
                        name: "white",
                        value: 0
                    },
                    {
                        name: "milk",
                        value: 0
                    },
                    {
                        name: "with nuts",
                        value: 0
                    },
                    {
                        name: "with caramel",
                        value: 0
                    }
                ]
            },
            {
                category: "tea_type",
                options: [
                    {
                        name: "hot tea brewed strong",
                        value: 0
                    },
                    {
                        name: "hot tea with sugar",
                        value: 0
                    },
                    {
                        name: "hot tea with lemon",
                        value: 0
                    },
                    {
                        name: "sweetened iced tea",
                        value: 0
                    },
                    {
                        name: "Plain iced tea",
                        value: 0
                    },
                    {
                        name: "Iced tea + Lemonade",
                        value: 0
                    },
                    {
                        name: "I prefer coffee",
                        value: 0
                    }
                ]
            },
            {
                category: "fruit_type",
                options: [
                    {
                        name: "Cherry",
                        value: 0
                    },
                    {
                        name: "Rasberry",
                        value: 0
                    },
                    {
                        name: "Blackberry",
                        value: 0
                    },
                    {
                        name: "Strawberry",
                        value: 0
                    },
                    {
                        name: "Cranberry",
                        value: 0
                    },
                    {
                        name: "Orange",
                        value: 0
                    },
                    {
                        name: "Lemon",
                        value: 0
                    },
                    {
                        name: "Peach",
                        value: 0
                    },
                    {
                        name: "Passion Fruit",
                        value: 0
                    }
                ]
            },
            {
                category: "favourite_wine",
                options: [
                    {
                        name: "Zinfandel",
                        value: 0
                    },
                    {
                        name: "Barbera",
                        value: 0
                    },
                    {
                        name: "Syrah",
                        value: 0
                    },
                    {
                        name: "Rose",
                        value: 0
                    },
                    {
                        name: "Chardonnay",
                        value: 0
                    },
                    {
                        name: "Orange",
                        value: 0
                    },
                    {
                        name: "Cabernet Sauvignon",
                        value: 0
                    },
                    {
                        name: "Sparkling",
                        value: 0
                    },
                    {
                        name: "Blends",
                        value: 0
                    },
                    {
                        name: "Dessert",
                        value: 0
                    },
                    {
                        name: "Others",
                        value: 0
                    }
                ]
            },
            {
                category: "red_wine",
                options: [
                    {
                        name: "Cabernet Sauvignon",
                        value: 0
                    },
                    {
                        name: "Merlot",
                        value: 0
                    },
                    {
                        name: "Syrah",
                        value: 0
                    },
                    {
                        name: "Pinot Noir",
                        value: 0
                    },
                    {
                        name: "Zinfandel",
                        value: 0
                    },
                    {
                        name: "Barbera",
                        value: 0
                    },
                    {
                        name: "Tempranillo",
                        value: 0
                    },
                    {
                        name: "Teroldego",
                        value: 0
                    },
                    {
                        name: "Sangiovese",
                        value: 0
                    },
                    {
                        name: "Petite Sirah",
                        value: 0
                    },
                    {
                        name: "Cabernet Franc",
                        value: 0
                    },
                    {
                        name: "Carignan",
                        value: 0
                    }
                ]
            },
            {
                category: "white_wine",
                options: [
                    {
                        name: "Chardonnay",
                        value: 0
                    },
                    {
                        name: "Savignon Blanc",
                        value: 0
                    },
                    {
                        name: "Marsanne",
                        value: 0
                    },
                    {
                        name: "Gruner Veltiner",
                        value: 0
                    },
                    {
                        name: "Chenin Blanc",
                        value: 0
                    },
                    {
                        name: "Riesling",
                        value: 0
                    }
                ]
            }
        ]
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FIND_TASTE_PREF_BY_CUST_SUCCEEDED:
            return {
								...state,
								preference: action.payload.preference
                // findTastePrefByCustSuccess: action.payload
            };

        case types.FIND_TASTE_PREF_BY_CUST_FAILED:
            return {
                ...state,
                // findTastePrefByCustFail: action.payload
            };

        case types.FIND_DEFAULT_TASTE_PREF_BY_CUST_SUCCEEDED:
            return {
                ...state,
								preference: action.payload
								// findDefaultTastePrefByCustSuccess: action.payload,
            };

        case types.FIND_DEFAULT_TASTE_PREF_BY_CUST_FAILED:
            return {
                ...state,
                // findDefaultTastePrefByCustFail: action.payload
            };

        default:
            return state;
    }
};
