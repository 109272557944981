import React, { Component } from "react";

import "./TastePreferencePopUp.scss";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import tpIcon from "../../../assets/images/TastePreference/personalization_icon.svg";
import { isMobile } from "react-device-detect";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#cd4545" },
  },
});

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      bottom: "0 !important",
      background: "none",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: isMobile ? null :"10px",
      borderBottomRightRadius: isMobile ? null :"10px",
      width: "410px !important",
      marginBottom: isMobile ? null : "6vh"
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiTypography-body1": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
    },
    ".MuiInputBase-input": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
      width: "100%",
      border: 0,
      display: "block",
      padding: "6px 0 7px",
      minWidth: 0,
      background: "none",
      boxSizing: "content-box",
      animationName: "mui-auto-fill-cancel",
      lineHeight: "1.2",
    },
  },
})(() => null);

const contentStyle = {
  justify: "center",
  paddingRight: "19px",
  display: "flex",
  flexDirection: "column",
};

class TastePreferencePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onDrawerOpen = () => {};

  onDrawerClose = () => {};

  render() {
    const { open, handleYes, handleCancel } = this.props;
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <SwipeableDrawer
            onBackdropClick={() => {
              handleCancel();
            }}
            anchor="bottom"
            open={open}
            style={contentStyle}
            onOpen={this.onDrawerOpen}
            onClose={this.onDrawerClose}
          >
            <GlobalCss />
            <div className="bg font-family">
              <div
                onTouchStart={() => {
                  handleCancel();
                }}
                className="thin-line-div new-thin-line-div"
              >
                <div className="thin-line"></div>
              </div>
              <div className="tp-detail-container mt-4">
                <div>
                  <span>The more we know what you like,</span>
                </div>
                <div>
                  <span>
                    The better your{" "}
                    <span className="theme-color pa-title">PairAnything</span>
                  </span>
                </div>
                <div>
                  <span>experience will be.</span>
                </div>
                <img
                  className="mt-4"
                  alt="taste preference icon"
                  src={tpIcon}
                />
              </div>
              <div className="tp-footer-pop-btn-css">
                <div className="">
                  <button
                    className="btn btn-round select-tp-btn"
                    onClick={() => {
                      handleYes();
                    }}
                  >
                    Select Taste Preferences
                  </button>
                </div>
                <div className="mt-2">
                  <button
                    className="btn btn-round tp-do-it-later-btn-css"
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    Do it Later
                  </button>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default TastePreferencePopUp;
