import React from "react";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import styles from "./layout.module.scss";
import { isMobile } from "react-device-detect";

export default function LayoutContents({ children, gray = false, white = false }) {
  return (
    <div
      className={`outer-container-no-pad unset-children flex-col page-bottom`}
      style={
        gray
          ? { backgroundColor: "#EFEFEF" }
          : white
          ? { backgroundColor: "white" }
          : null
      }
    >
      {children}
    </div>
  );
}
