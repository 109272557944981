export const AMADOR_COUNTY_WINERIES = [
  {
    id: 1,
    wineName: "Scott Harvey Wines",
    winerUrl: "https://www.scottharveywines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/scott_harvey_wines.png"),
  },
  {
    id: 62,
    wineName: "La Mesa Vineyards",
    winerUrl: "https://lamesavineyards.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/La_mesa.png"),
  },
  {
    id: 22,
    wineName: "Iron Hub Winery",
    winerUrl: "http://www.ironhubwines.com",
    wineImage: require("../../../assets/images/Visit/Amador_County/Iron_Hub.jpg"),
  },
  {
    id: 8,
    wineName: "C.G. DiArie Vineyard and Winery",
    winerUrl: "https://www.cgdiarie.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Di_Arie.jpg"),
  },
  {
    id: 19,
    wineName: "Helwig Vineyards and Winery",
    winerUrl: "https://www.helwigwinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Helwig.jpg"),
  },
  {
    id: 27,
    wineName: "Prospect Cellars",
    winerUrl: "https://www.prospectcellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Prospect_Cellars.png"),
  },

  {
    id: 2,
    wineName: "Amador Cellars",
    winerUrl: "https://www.amadorcellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Amador_Cellars.jpg"),
  },
  {
    id: 29,
    wineName: "Rombauer Vineyards",
    winerUrl: "https://www.rombauer.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Rombauer.jpg"),
  },
  {
    id: 41,
    wineName: "Vino Noceto",
    winerUrl: "https://www.noceto.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Vino_Noceto.png"),
  },
  {
    id: 80,
    wineName: "Matthew Gibson Winery",
    winerUrl: "https://matthewgibsonwinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Matthew_Gibson_Winery.jpg"),
  },
  {
    id: 81,
    wineName: "Young's Vineyard",
    winerUrl: "http://www.youngsvineyard.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Youngs_Vineyard.png"),
  },
  {
    id: 83,
    wineName: "Wine Tree Farm & Corinne Wines",
    winerUrl: "https://www.winetreefarm.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Wine_Tree_Farm.jpg"),
  },
  {
    id: 84,
    wineName: "Paul J Wines",
    winerUrl: "https://www.pauljwines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Paul_J_Wines.PNG"),
  },

  {
    id: 24,
    wineName: "Karmere Vineyards and Winery",
    winerUrl: "http://www.karmere.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Karmere.jpg"),
  },

  {
    id: 10,
    wineName: "Convergence Vineyards",
    winerUrl: "https://amadorwine.com/convergence-vineyards/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Convergence_Vineyards.PNG"),
  },

  {
    id: 4,
    wineName: "Avio Vineyards and Winery",
    winerUrl: "http://www.aviowine.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Avio.jpg"),
  },
  {
    id: 6,
    wineName: "Borjón Winery",
    winerUrl: "https://www.borjonwinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Borjon.jpg"),
  },
  {
    id: 7,
    wineName: "Bray Vineyards",
    winerUrl: "https://www.brayvineyards.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Bray_Vineyards.jpg"),
  },
  {
    id: 13,
    wineName: "Dillian Wines",
    winerUrl: "https://www.dillianwines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Dillian.png"),
  },
  {
    id: 14,
    wineName: "Distant Cellars",
    winerUrl: "https://www.distantcellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Distant_Cellars.jpg"),
  },
  {
    id: 16,
    wineName: "Dobra Zemlja",
    winerUrl: "https://www.dobraz.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Dobra_Zemlka.png"),
  },
  {
    id: 17,
    wineName: "Drytown Cellars",
    winerUrl: "https://www.drytowncellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Drytown_Cellars.jpg"),
  },
  {
    id: 18,
    wineName: "Feist Wines",
    winerUrl: "https://www.feistwines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Feist_Wines.png"),
  },

  {
    id: 20,
    wineName: "Holgate Hill",
    winerUrl: "https://holgatehill.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Holgate.jpg"),
  },

  {
    id: 23,
    wineName: "Jeff Runquist Wines",
    winerUrl: "https://www.jeffrunquistwines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Jeff_R.jpg"),
  },

  {
    id: 25,
    wineName: "Le Mulet Rouge",
    winerUrl: "https://www.lemuletrouge.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/le-mulet-rouge.jpg"),
  },
  // {
  //     id: 26,
  //     wineName: 'Paul J Wines',
  //     winerUrl: 'https://amadorwine.com/paul-j-wines/',
  //     wineImage: require('../../../assets/images/Visit/Amador_County/Paul_J_Wines.PNG')
  // },

  {
    id: 28,
    wineName: "Rancho Victoria Vineyard",
    winerUrl: "https://www.ranchovictoriavineyard.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Rancho_Victoria.png"),
  },

  {
    id: 30,
    wineName: "Sera Fina Cellars",
    winerUrl: "https://serafinacellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Sera_Fina.jpg"),
  },
  {
    id: 31,
    wineName: "Shenandoah Vineyards",
    winerUrl: "http://www.shenandoahvineyards.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Shenandoah.jpg"),
  },
  {
    id: 32,
    wineName: "Sobon Estate",
    winerUrl: "https://sobonwine.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Sobon_Wine.png"),
  },
  {
    id: 33,
    wineName: "Story Winery",
    winerUrl: "http://www.zin.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Story.png"),
  },
  {
    id: 34,
    wineName: "Tanis Vineyards",
    winerUrl: "http://www.tanisvineyards.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/tanis-vineyards.jpg"),
  },
  {
    id: 35,
    wineName: "Terra d’ Oro Winery",
    winerUrl: "https://www.terradorowinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Terra_dOro.PNG"),
  },
  {
    id: 36,
    wineName: "Terre Rouge and Easton Wines",
    winerUrl: "https://www.terrerougewines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/TerreRouge.PNG"),
  },
  {
    id: 37,
    wineName: "The End of Nowhere",
    winerUrl: "http://www.ljlwine.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/The_End_of_Nowhere.jpg"),
  },
  {
    id: 38,
    wineName: "TKC Vineyards",
    winerUrl: "http://www.tkcvineyards.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/tkc.jpg"),
  },
  {
    id: 39,
    wineName: "Turley Wine Cellars",
    winerUrl: "https://www.turleywinecellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Turley.jpg"),
  },
  {
    id: 40,
    wineName: "Uphill Vineyards",
    winerUrl: "https://www.uphillvineyards.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Uphill.PNG"),
  },

  {
    id: 42,
    wineName: "Wilderotter Vineyard",
    winerUrl: "http://www.wilderottervineyard.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Wilderotter.png"),
  },
  {
    id: 50,
    wineName: "Bella Piazza Winery",
    winerUrl: "https://www.bellapiazzawinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/BELA-PIAZZA-logo-gold.png"),
  },
  {
    id: 51,
    wineName: "Fate Wines",
    winerUrl: "https://www.fatewines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Fate_Wines.jpg"),
  },
  {
    id: 52,
    wineName: "Leoni Farms Wine",
    winerUrl: "https://leoni-farms.square.site/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Leoni.PNG"),
  },
  {
    id: 53,
    wineName: "Villa Toscano Winery",
    winerUrl: "https://www.villatoscano.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Villa_Toscano.png"),
  },
  {
    id: 54,
    wineName: "Clos Du Lac Cellars",
    winerUrl: "http://www.closdulac.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Clos_du_Lac.jpg"),
  },
  {
    id: 55,
    wineName: "24 BRIX Wines",
    winerUrl: "https://24brixwines.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/24_Birx.png"),
  },
  {
    id: 56,
    wineName: "Casino Mine Ranch",
    winerUrl: "https://www.casinomineranch.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Casino_Mine_Ranch.png"),
  },
  {
    id: 57,
    wineName: "Fiddletown Cellars",
    winerUrl: "https://www.fiddletowncellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Fiddletown.png"),
  },
  {
    id: 58,
    wineName: "Renegade Winery",
    winerUrl: "https://drinkrenegadewine.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Renegade.jpg"),
  },
  {
    id: 59,
    wineName: "Tabeaux Cellars",
    winerUrl: "https://www.tabeauxwinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Tabeaux.jpg"),
  },
  {
    id: 60,
    wineName: "1850 Wine Cellars",
    winerUrl: "https://1850winecellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/1850_Wine_Cellars.png"),
  },
  {
    id: 61,
    wineName: "Driven Cellars",
    winerUrl: "https://www.drivencellars.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Driven_Cellars.png"),
  },

  {
    id: 63,
    wineName: "MFV Winery",
    winerUrl: "http://mfvwinery.com/",
    wineImage: require("../../../assets/images/Visit/Amador_County/MFV.png"),
  },
  {
    id: 64,
    wineName: "Miller Wine Works",
    winerUrl: "https://millerwineworks.com/our-wines/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Miller_Wine_Works.jpeg"),
  },
  {
    id: 21,
    wineName: "Il Gioiello Winery and Morse Wines",
    winerUrl: "https://amadorwine.com/il-gioiello-winerymorse-wines/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Il_Gioiello_Morse_Wines.jpg"),
  },
  {
    id: 9,
    wineName: "Charles Spinetta Winery",
    winerUrl:
      "https://amadorwine.com/charles-spinetta-winery-and-wildlife-art-gallery/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Charles_Spinetta.png"),
  },

  {
    id: 11,
    wineName: "Cooper Vineyards",
    winerUrl: "https://amadorwine.com/cooper-vineyards/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Cooper_Vineyards.jpg"),
  },
  {
    id: 12,
    wineName: "Deaver Vineyards",
    winerUrl: "https://amadorwine.com/deaver-vineyards/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Deaver_Vineyards.png"),
  },
  {
    id: 15,
    wineName: "Di Stasio Vineyards and Wines",
    winerUrl: "https://amadorwine.com/di-stasio-vineyards-and-wines/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Di_Stasio_Vineyards.png"),
  },
  {
    id: 5,
    wineName: "Bella Grace Vineyards",
    winerUrl: "https://amadorwine.com/bellagrace-vineyards/",
    wineImage: require("../../../assets/images/Visit/Amador_County/BELLA_GRACE_VINEYARDS_LOGO_COLOR.jpg"),
  },
  {
    id: 3,
    wineName: "Andis Wines",
    winerUrl: "https://amadorwine.com/andis-wines/",
    wineImage: require("../../../assets/images/Visit/Amador_County/Andis_Wines.png"),
  },
];
