import React , {Component} from 'react'
import { Link } from 'react-router-dom';

import notSelectedLogo from '../../../assets/images/not-selected-heart.svg'; 
import tasteProfileIntroLogo from '../../../assets/images/TastePreference/taste-profile-intro.png'

// taste-profile-intro.svg
import personalizationIcon from '../../../assets/images/TastePreference/personalization_icon.png';
import pairAnythingLogo from '../../../assets/images/pairanything-logo.png';
 
import './PreferenceScreenCard.scss';

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";


import TastePreferenceOptionCard from '../TastePreferenceOptionCard/TastePreferenceOptionCard';


class PreferenceScreenCard extends Component {

    constructor (props) {
        super(props);
        this.state = {
            selected: false,
            selectedOption: '',
            tastePreferenceCardObject: ''
        }

          
    }

    
    toggleImage = () => {
      
        let currentStatus = this.state.selected? true : false;
        let selectedStatus = !currentStatus;
        this.setState(state => ({ selected: !state.selected }));

        try {
            this.props.sendDataAfterChange(this.props.preferenceCardData.categoryName , this.props.preferenceCardData.questionName , this.props.preferenceCardData.option_type , selectedStatus );
        }catch(e) {
        }
        

      }

          
    componentDidMount = () => {

        GoogleAnalyticsFunctions.logPagesVisitedByUser();
    
        
    }
 
    // optionSelected = () => { 
    //     document.getElementById("optionSelectedImageId").src = notSelectedLogo       
    // }

    nextButtonClicked = () => {
        GoogleAnalyticsFunctions.clickedTastePreferenceGetStarted();
        let status = true;
        this.props.sendNextBtnStatus(status);
    }
    exitButtonClicked = () => {
        let status = true;
        this.props.sendExitBtnStatus(status);
    }

    getTastePrefOptionCardStatus = (optionName, optionImageUrl, optionStatus) => {
        
       if(this.state.tastePreferenceCardObject.selection_type == "0 or 1") {

            let selectedDataObject = this.checkSelectionType(optionName,optionImageUrl,optionStatus);

            let categoryTempData = this.state.tastePreferenceCardObject;

            for(let i =0; i < categoryTempData.options.length;++i) {

                if(selectedDataObject.selectionStatus) { // if selected
                    if(categoryTempData.options[i].name == selectedDataObject.optionName &&  
                        categoryTempData.question == selectedDataObject.question)
                         {

                            categoryTempData.options[i].selectionStatus = true

                    } else {
                        // categoryTempData.options[i]
                        categoryTempData.options[i].selectionStatus = false;

                    }

                }
            }

    
            this.setState({
                selectedOption: selectedDataObject,
                tastePreferenceCardObject: categoryTempData
            })

             


       }




    }

    checkSelectionType = (optionName,optionImageUrl,optionStatus) => {
        let tastePrefResponse = this.state.tastePreferenceCardObject;

        
        if(tastePrefResponse.selection_type == "0 or 1") {

            for(let i=0;i<tastePrefResponse.options.length;++i) {
                if(optionName == tastePrefResponse.options[i].name &&
                         optionImageUrl == tastePrefResponse.options[i].img_url) {

                            let respObj = {
                                question: tastePrefResponse.question,
                                selectionType : tastePrefResponse.selection_type,
                                optionName : tastePrefResponse.options[i].name ,
                                optionImageUrl : tastePrefResponse.options[i].image_url,
                                selectionStatus : optionStatus
                            }

                            return respObj;
                            

                         }
            }
        }
        
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.tastePreferenceCardObject!=undefined) {
            return {
                tastePreferenceCardObject: nextProps.tastePreferenceCardObject,
               };
        }
        return null;

    }

    renderImageBasedOnCardStatus = () => {
        let result = [];

        let tastePreferenceCardObject = this.state.tastePreferenceCardObject;

        
        if(this.props.status == 'swipeablecards') {

            this.state.tastePreferenceCardObject.options.map( (el,index) => {


            result.push(
             
                <TastePreferenceOptionCard selectedOption = {this.state.selectedOption} sendTastePrefOptionCardStatus = {this.getTastePrefOptionCardStatus}
                 selectedState = {this.state.selectedState}
                 category = {this.state.tastePreferenceCardObject.category}
                 preferenceCardDataObject = {this.state.tastePreferenceCardObject.options[index]} /> 

            )    
        })
           
           
        } else if(this.props.status == 'intro') {

            result.push(
            <div className = "next-btn-container" >
                <button type="button" onClick={this.nextButtonClicked}
                     className="btn btn-round taste-preference fruit-preferences-next-btn">Select Taste Preferences</button>         
            </div>
    
            )
        } else if(this.props.status == 'exit') {
             
            result.push(
                <div className = "exit-btn-container" >
                    <button type="button" onClick={()=>this.exitButtonClicked()}
                         className="btn btn-round exit-screen-button">Finish</button>         
                </div>
            )
        }
       return result; 
    }

    

    render() {

        return(

            
            <div onDoubleClick = {this.toggleImage} className="preference-screen-card-main-container">
             <div 
             className = "preference-screen-card-category-container"
             className = { this.props.status == 'swipeablecards'? 'preference-screen-card-category-container' : 'intro-exit-preference-screen-card-container' }
             >

{/* “Let’s create your unique profile, by letting us know what you like.
On the next screens, you can:”
 */}                                  
            {
                this.props.status == "intro"?
                <div className="intro-screen-explainer-text-container">
                    {/* <img alt="PairAnything" className="intro-screen-explainer-pair-anything-logo" src = {pairAnythingLogo} /> */}
                    <span className="intro-screen-explainer-text">
                       The more we know what you like, The better your <span className="intro-screen-pairanything-text">PairAnything</span> experience will be.
                    </span>
                    {/* <span className="intro-screen-explainer-text">
                        On the next screens, you can:
                    </span> */}
                </div>
                : ''
            }
                    <span id = "categoryName"
                     value = {this.props.status == 'swipeablecards'? this.state.tastePreferenceCardObject.question : this.props.status == 'intro'? '': ''}
                    className = { this.props.status == 'swipeablecards' ?  "preference-screen-card-title" : this.props.status == 'intro'? "intro-exit-preference-screen-card-title" : "exit-preference-screen-card-title" }>
                        {this.props.status == 'swipeablecards'? this.state.tastePreferenceCardObject.question : this.props.status == 'intro'? '': this.props.status == 'exit'? 
                         <span className="intro-screen-pairanything-text">
                         Thank you<span className="exit-screen-text" > <br /> 
                         for letting us know your taste preference
                        </span> 
                        </span>  
                            : ''}
                        {/* sample name */}
                    </span>   
                    {
                        // this.props.status == 'intro'? <img className="heart-intro-icon" src= { selectedLogo } /> : ''
                    }
             </div>

            <div>
     
            </div>

            
           
                       
                <div className = "preference-screen-card-sub-container">

              
                    <div className = "preference-screen-card-question" id="preference-screen-card-question" >
                         
                      
                     
                       {/*  
                            style= { { 'fontFamily': 'trajan-sans-pro,sans-serif'} } 
                            add this to below span
                            Added to PreferenceScreenCard.scss right now

                            id = "question-name" in below span is used in SwipeableCard component to check if user swipes 
                            left in the first card
                       */}
                            <span id = "question-name"
                            value = {this.props.status == 'swipeablecards'? "" : this.props.status == 'intro'? '': ''}
                            // intro-exit-screen-content-text
                            className = {this.props.status == 'swipeablecards'? "question-name" : "exit-preference-screen-card-title" }>
                              {this.props.status == 'swipeablecards'? "": this.props.status == 'intro'? '': (this.props.status == 'exit' ? 
                              
                            //   'Preferences saved!' 

                            <span className="exit-screen-text-bottom" >
                                The more we know what you like, the better your 
                                <span className="intro-screen-pairanything-text"> PairAnything</span> experience will be 
                            </span>
                         
                              
                              
                              
                              : '')}
                            
                            </span>  

                            {
                             this.props.status == 'intro' ? 
                             
                                 <img alt = "Taste Profile" className="gesture-logo" src = {tasteProfileIntroLogo} />
                             : this.props.status == 'exit' ? 
                             
                             <img alt= "Taste Profile" className="exit-screen-logo" src = {personalizationIcon} />

                             : ''
                         
                           
                        }



                    
                      
                    </div>
                   
                        { this.renderImageBasedOnCardStatus() }  
                        {
                             this.props.status == 'intro' ? 
                             
                                <span className="later-text" >
                                    <Link to={this.props.path} style={{color: '#CD4545'}}>Do it Later</Link>
                                </span>
                             : ''
                         
                           
                        }

                        {/*
                         renderImageBasedOnCardStatus method dynamically displays the image - heart or the intro screen next btn 
                         based on the status that it recieves from swipeablecard.js
                        
                        */}


                    {/* swipe-left-arrow.png */}

                  
                        

                </div>
               
            </div>

        );
    }
}

export default PreferenceScreenCard;