import React , {Component} from 'react';

import errorBoundaryImage from '../../../assets/images/error-fixing.svg';
import * as constants from '../../constants/constants'

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { 
          hasError: false 
        };
    }


    postCaughtException = (errorData) => {

		let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
        let bearer = 'Bearer ' + signInToken;

        let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );
		let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );


		let postCaughtExceptionApiUrl = constants.IDENTITY_API + "/api/v1/frontendException/create";
		let apiPostData = {
			portal : "consumerportal",
			page:  window.location.href,
			error: errorData,
			userId : consumerData.id,
			wineryId : wineryId,
			// operation: "",
			// statuscode: ""
			
		}
		fetch(postCaughtExceptionApiUrl , {  
	  
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Authorization" : bearer
				} ,
				body: JSON.stringify(apiPostData)
				})
				.then(res => {
					if (res.ok) {
					
					return res.json();
					
					} else {          
					throw Error(res.statusText);
					}
				})
				.then(json => {				
				})
				.catch(error => console.error(error));
    }
    


  
    componentDidCatch(error, info) {

      // Display fallback UI
      this.setState({ 
          hasError: true
      });
      // You can also log the error to an error reporting service

      if(info.componentStack) {
        this.postCaughtException(info.componentStack);
      }
      // logging error for now

    }
  
    render() {
      if (this.state.hasError) {
        //  return <h1>Something went wrong.</h1>;

        return(

            <div className="error-boundary-main-container">
                {/* errorBoundaryImage */}
                <span className="error-boundary-title">Something went wrong.</span>;
                <div className="error-boundary-image-container">

                      <img  alt = "Something went wrong" className = "error-boundary-image" src = { errorBoundaryImage } />
    
                </div>
            </div>
          )

      }
      return this.props.children;

    //   return(

    //     <div>
    //         {/* errorBoundaryImage */}
    //         <h1>Something went wrong.</h1>;
    //         <img src = { errorBoundaryImage } />

    //     </div>
    //   )
    }
  }

  export default ErrorBoundary;