import { Modal, Slide } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import moment from "moment";
const PromotionModal = ({ promotionData, openDialog, initial }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const popupStyle = {
    fontFamily: "Poppins, Regular sans-serif",
  };

  const [open, setOpen] = useState(true);

  useEffect(() => {
    initial ? setOpen(true) : setOpen(false);
  }, [openDialog]);

  const handleClose = () => {
    let wineryId = localStorage.getItem("winery_id");
    let disablePromotion = localStorage.getItem("disable_promotion");
    disablePromotion = disablePromotion ? JSON.parse(disablePromotion) : [];
    let objIndex = disablePromotion.findIndex(
      (obj) => obj.wineryId == wineryId
    );
    if (objIndex != -1) {
      disablePromotion[objIndex].date = moment().utc().format("YYYY-MM-DD");
    }
    localStorage.setItem(
      "disable_promotion",
      JSON.stringify([
        ...disablePromotion,
        {
          date: moment().utc().format("YYYY-MM-DD"),
          wineryId,
        },
      ])
    );
    setOpen(false);
  };

  let classes = {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2%",
      margin: "2%",
    },
    parent: {
      padding: isMobile ? "5%" : "2%",
      borderRadius: "5px",
      height: "fit-content",
      // maxHeight: "85vh",
      // overflow: "scroll",
    },
  };
  const renderPromo = () => {
    return (
      <div
        style={classes.modal}
        // className="card card-post card-round winery-details-card shadow p-3 mb-5 bg-white rounded"
      >
        <div className="content-style" style={classes.parent}>
          {/* <div style={{ background: "#FFFFFF", overflowY: "visible" }}>
            <button className="close-button" onClick={handleClose}>
              X
            </button>
          </div> */}
          <div
          // className="text-one font-bold titleX"
          // style={{ fontSize: "25px !important" }}
          >
            <h3 className="promo-title">{promotionData.title}</h3>
          </div>
          {promotionData.coverPicture && promotionData.coverPicture != null ? (
            <img
              className={isMobile ? "mobile-amador-image" : "amador-image"}
              style={{
                marginBottom: "3%",
                maxHeight: "200px",
                maxWidth: "200px",
              }}
              src={promotionData.coverPicture}
            />
          ) : null}
          <div style={{width:"100%"}}> 
          <div
            dangerouslySetInnerHTML={{ __html: promotionData.description }}
          ></div></div>
          <div className="valid-text">
            Valid till {moment(promotionData.endDate).format("MMMM Do")}
          </div>
          {/* {promotionData.buttonText != "" ? (
            <a
            // href="https://www.cellarpass.com/events/behind-the-cellar-door-2021-7042?utm_source=website&utm_medium=cellarpass&utm_campaign=BehindtheCellarDoor_2021_03_03&referrer=pair_anything&utm_content=hyperlink"
            // target="_blank"
            >
              <button className="amador-button">
                {promotionData.buttonText}
              </button>
            </a>
          ) : null} */}
        </div>
      </div>
    );
  };
  return renderPromo();
};
export default PromotionModal;
