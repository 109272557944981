import React from 'react';

const GoogleIcon = () => {
	return (
    <div style={{
			fill: '#ffff'
		}}>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(0 0)"><path className="a" d="M66.191,7.81a6.106,6.106,0,0,1,9.186-.63l.476.476,3.812-3.812-.476-.476a11.5,11.5,0,0,0-16.264,0q-.3.3-.567.609Z" transform="translate(-59.557)"/><path className="a" d="M249.2,208.659q.313-.271.609-.567a11.425,11.425,0,0,0,3.368-8.132,11.6,11.6,0,0,0-.188-2.083l-.1-.553H241v5.391h6.13a6.082,6.082,0,0,1-1.761,2.116Z" transform="translate(-230.174 -188.46)"/><path className="a" d="M73.554,340.028a6.118,6.118,0,0,1-7.364-1.89l-3.833,3.833q.271.313.567.609a11.507,11.507,0,0,0,14.577,1.4Z" transform="translate(-59.557 -322.948)"/><path className="a" d="M5.924,121.461a6.105,6.105,0,0,1,0-4.989l-3.951-3.951a11.515,11.515,0,0,0,0,12.891Z" transform="translate(0 -107.466)"/></g></svg>		</div>
	);
}
export default GoogleIcon;
