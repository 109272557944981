import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import ReactGA from "react-ga4";
import "./App.css";
import Home from "./app/screens/Home/Home";
// import Login from './app/screens/Login/Login'
import SignIn from "./app/screens/SignIn/SignIn";
import SignUp from "./app/screens/SignUp/SignUp";
import WineryDetails from "./app/components/WineryDetails/WineryDetails";
import ShippingAddress from "./app/components/ShippingAddress/ShippingAddress";
import Profile from "./app/screens/Profile/Profile";
import FavoriteWines from "./app/screens/FavoriteWines/FavoriteWines";
import SetPassword from "./app/screens/SetPassword/SetPassword";
import ForgotPassword from "./app/screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./app/screens/ResetPassword/ResetPassword";
import PageNotFound from "./app/screens/PageNotFound/PageNotFound";
import SetPasswordSuccessPage from "./app/screens/SetPasswordSuccessPage/SetPasswordSuccessPage";
import PreferenceScreenCard from "./app/components/PreferenceScreenCard/PreferenceScreenCard";
import { Swipeable } from "./app/components/SwipeableCard/SwipeableCard";
import SelectedTastePreference from "./app/screens/SelectedTastePreference/SelectedTastePreference";
import EventList from "./app/screens/EventList/EventList";
import EventDescription from "./app/components/EventDescription/EventDescription";
import EditTastePreference from "./app/components/EditTastePreference/EditTastePreference";
import Chat from "./app/screens/Chat/Chat";
import ChatDesktop from "./app/screens/ChatDesktop/ChatDesktop";
import RecommendedWines from "./app/screens/RecommendedWines/RecommendedWines";
import AvailableWines from "./app/screens/AvailableWines/AvailableWines";
import SearchWineForDish from "./app/screens/SearchWineForDish/SearchWineForDish";
import IndividualWineDetails from "./app/screens/IndividualWineDetails/IndividualWineDetails";
import WineEducation from "./app/screens/WineEducation/WineEducation";
import WineNewsFeed from "./app/screens/WineNewsFeed/WineNewsFeed";
import TastePreferenceOptionCard from "./app/components/TastePreferenceOptionCard/TastePreferenceOptionCard";
import TastePreferenceQuizCard from "./app/components/TastePreferenceQuizCard/TastePreferenceQuizCard";
import DateOfBirthCheck from "./app/screens/DateOfBirthCheck/DateOfBirthCheck";
import RateRecommendation from "./app/screens/RateRecommendation/RateRecommendation";
import SelectWinery from "./app/screens/SelectWinery/SelectWinery";
import ShoppingCart from "./app/screens/ShoppingCart/ShoppingCart";
import ShoppingCartShippingAddress from "./app/screens/ShoppingCartShippingAddress/ShoppingCartShippingAddress";
import PaymentDetails from "./app/screens/PaymentDetails/PaymentDetails";
import HelpPage from "./app/screens/HelpPage/HelpPage";
import MyEvents from "./app/screens/MyEvents/MyEvents";
import RecommendationsDesktop from "./app/screens/RecommendationsDesktop/RecommendationsDesktop";
import MyOrders from "./app/screens/MyOrders/MyOrders";
import ReceiptDetails from "./app/screens/ReceiptDetails/ReceiptDetails";
import AvailableWinesDesktop from "./app/screens/AvailableWinesDesktop/AvailableWinesDesktop";
// import NonPAWinery from "./app/screens/NonPAWinery/NonPAWinery";
import { isMobile } from "react-device-detect";
import NewSignUp from "./app/screens/NewSignUp/NewSignUp";
import SignupV2 from "./app/screens/Signupv2";
import SingUpPasswordSuccess from "./app/screens/SingUpPasswordSuccess/SingUpPasswordSuccess";
import StripePayment from "./app/screens/StripePayment/StripePayment";
// import PrivateRoute from './app/components/PrivateRoute/PrivateRoute';
import StripePaymentSuccess from "./app/screens/StripePaymentResponsePages/StripePaymentSuccess";
import StripePaymentCancel from "./app/screens/StripePaymentResponsePages/StripePaymentCancel";
import ShoppingCartCheckout from "./app/screens/ShoppingCartCheckout/ShoppingCartCheckout";
import CustomerPassportDiscover from "./app/screens/CustomerPassportDiscover/CustomerPassportDiscover";
// import Visit from "./app/screens/Visit/Visit";
import RegionBasedWineries from "./app/screens/RegionBasedWineries/RegionBasedWineries";
import PARecommendation from "./app/screens/PARecommendation/PARecommendation";
import PARecommendedVarietals from "./app/screens/PARecommendedVarietals/PARecommendedVarietals";
import PARecommendationDesktop from "./app/screens/PARecommendation/PARecommendationDesktop";
import AppFeedback from "./app/screens/AppFeedback/AppFeedback";
import ShoppingCartBillingAddress from "./app/screens/ShoppingCartBillingAddress/ShoppingCartBillingAddress";
import { withRouter, matchPath } from "react-router-dom";
import { fetchCampaignDetails } from "./app/constants/commonFunctions";
import queryString from "query-string";
import PrivacyPolicy from "./app/screens/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./app/screens/TermsOfUse/TermsOfUse";
import AgeConfirmation from "./app/screens/AgeConfirmation/AgeConfirmation";
import VirtualWineTasting from "./app/screens/VirtualWineTasting/virtual-wine-tasting";
import LocationBasedWineries from "./app/screens/RegionBasedWineries/LocationBasedWineries";
import AssociationsBasedWineries from "./app/screens/RegionBasedWineries/AssociationsBasedWineries";
import QuizResults from "./app/screens/testResults/TestResults.js";
import moment from "moment";
import * as constants from "./app/constants/constants";
import axiosCall from "./app/constants/index";
import SignUpPhoneEmail from "./app/screens/SignUpPhoneEmail/SignUpPhoneEmail";
import LandingPage from "./app/screens/LandingPage/LandingPage";
import WineDetails from "./app/screens/WineDetails/WineDetails";
import PairingResults from "./app/screens/PairingResults/PairingResults";
import PairingResultsFood from "./app/screens/PairingResults/PairingResultsFood";
import SeeAllWines from "./app/screens/PairingResults/SeeAllWines";
import AccountSetUp from "./app/screens/SignUpPhoneEmail/AccountSetUp";
import TermsOfUseNew from "./app/screens/TermsOfUse/TermsOfUseNew";
import PrivacyPolicyNew from "./app/screens/PrivacyPolicy/PrivacyPolicyNew";
import Login from "./app/screens/SignUpPhoneEmail/Login";
import TestScreen from "./app/screens/TestScreen/TestScreen";
import ViewProfile from "./app/screens/ViewProfile/profile";
import Help from "./app/screens/Help/Help";
import WineryLanding from "./app/screens/WineryLanding/WineryLanding.jsx";
import HelpSuccess from "./app/screens/Help/HelpSuccess.jsx";
import PairingResultsWine from "./app/screens/PairingResults/PairingResultsWine.jsx";
import DDLanding from "./app/screens/domain-dardagny-pilot/dd-landing/dd-landing.jsx";
import DDPairing from "./app/screens/domain-dardagny-pilot/dd-pairing/dd-pairing";
import TDLanding from "./app/screens/techstars-demo/landing/landing.jsx";
import TDPairing from "./app/screens/techstars-demo/pairing.jsx/pairing.jsx";
import WineryWineDetails from "./app/screens/WineryWineDetails/winery-wine-details.jsx";

// import Footer from "./app/components/CommonComponents/Footer";
class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    localStorage.setItem("firstVisit", "true");
    checkforQuery(this.props);
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          {/*  */}
          <Route
            exact
            path="/tastepreferencequiz"
            component={TastePreferenceQuizCard}
          />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/signup" component={NewSignUp} />
          {/* <Route exact path="/signup2" component={SignUpPhoneEmail} /> */}
          <Route exact path="/signup/setup" component={AccountSetUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/sign-up" component={NewSignUp} />
          <Route exact path="/sign-up/:wineryName" component={NewSignUp} />
          <Route
            exact
            path="/sign-up/:wineryName/:page"
            component={NewSignUp}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicyNew} />
          <Route exact path="/terms-of-use" component={TermsOfUseNew} />
          {/* <Route exact path="/signup" component={SignupV2} /> */}
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/winery-details"
            component={WineryDetails}
          />
          <PrivateRoute
            exact
            path="/shipping-address"
            component={ShippingAddress}
          />
          {/* <AgeConfirmedRoute location={this.props.location} exact path='/change-password' component={SignUp} /> */}
          {/* <PrivateRoute exact path="/wine-education" component={WineEducation} /> */}
          {/* <PrivateRoute exact path='/wine-news-feed' omponent={WineNewsFeed} /> */}
          <PrivateRoute
            exact
            path="/profile"
            component={Profile}
            location={this.props.location}
          />
          <PrivateRoute
            exact
            path="/viewProfile/:hi"
            component={ViewProfile}
            location={this.props.location}
          />
          <PrivateRoute
            exact
            path="/viewProfile"
            component={ViewProfile}
            location={this.props.location}
          />
          <PrivateRoute exact path="/my-favorite" component={FavoriteWines} />
          <AgeConfirmedRoute
            exact
            path="/winery/:wineryId/recommended-wines/:questionId"
            component={RecommendedWines}
          />
          <AgeConfirmedRoute
            exact
            path="/winery/:wineryName/recommended-wines/:questionId"
            component={RecommendedWines}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/available-wines"
            component={AvailableWines}
          />
          {/* <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/testtest"
            component={TestScreen}
          /> */}
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/TargetCalifornia/pairings"
            component={LandingPage}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/welcome"
            component={LandingPage}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pairing-results"
            component={PairingResults}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pairing-results-food"
            component={PairingResultsFood}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pairing-results-wine"
            component={PairingResultsWine}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pairing-results/:search"
            component={PairingResults}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pairing-results-food/:search"
            component={PairingResultsFood}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pairing-results-wine/:search"
            component={PairingResultsWine}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/wine-details/:wineId"
            component={WineDetails}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/see-all-wines/:varietalName"
            component={SeeAllWines}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/help"
            component={Help}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/help/success"
            component={HelpSuccess}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/:wineryName/wines/:wineId"
            component={WineryWineDetails}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/:wineryName/wines"
            component={AvailableWines}
          />
          <PrivateRoute
            location={this.props.location}
            exact
            path="/search-wine/:wineryId"
            component={SearchWineForDish}
          />
          {/* PA-85*/}
          {/* <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/BottlesUpChicago/pairings"
            component={PARecommendation}
          /> */}
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/:wineryName/pairings"
            component={SearchWineForDish}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/individual-wine-details" // new api
            component={IndividualWineDetails}
          />
          <AgeConfirmedRoute
            exact
            path="/testresults"
            component={QuizResults}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/county/:countyName/:countyId/wineries"
            component={LocationBasedWineries}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/association/:associationName/:associationId/wineries"
            component={AssociationsBasedWineries}
          />
          {/* <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/region-based-wineries/:regionId/:regionName/:wineryName"
            component={NonPAWinery}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/regions/:regionId/:regionName/:wineryName"
            component={NonPAWinery}
          /> */}
          {/* <Route exact path="/set-password"  component={SetPassword} />                  */}
          <Route
            exact
            path="/:email/set-password/:token"
            component={SetPassword}
          />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/:email/reset-password/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/set-password-success"
            component={SetPasswordSuccessPage}
          />
          <Route
            exact
            path="/account-activated"
            component={SetPasswordSuccessPage}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/select-winery"
            component={SelectWinery}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/wineries"
            component={SelectWinery}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/preference-screen-card"
            component={PreferenceScreenCard}
          />
          <PrivateRoute
            location={this.location}
            exact
            path="/user-taste-preference3"
            component={TastePreferenceOptionCard}
          />
          <PrivateRoute
            exact
            path="/user-taste-preference"
            component={Swipeable}
          />
          <PrivateRoute
            exact
            path="/events"
            component={EventList}
            location={this.props.location}
          />
          {/* <PrivateRoute exact path="/event-description"  component={EventDescription} /> */}
          <PrivateRoute
            exact
            path="/selected-taste-preference"
            component={SelectedTastePreference}
          />
          <PrivateRoute exact path="/my-events" component={MyEvents} />
          <PrivateRoute
            exact
            path="/edit-taste-preference"
            component={EditTastePreference}
          />
          <PrivateRoute
            location={this.props.location}
            exact
            path="/events/:eventId"
            component={EventDescription}
          />
          <PrivateRoute
            location={this.props.location}
            exact
            path="/events/:eventId/:wineryId"
            component={EventDescription}
          />
          <PrivateRoute
            location={this.props.location}
            exact
            path="/chat/:wineryId"
            component={Chat}
          />
          {/* <AgeConfirmedRoute location={this.props.location} exact path="/sign-up-success" component={SingUpPasswordSuccess} /> */}
          {/* --- based on new on board process */}
          <Route
            exact
            path="/:email/sign-up-verify/:token"
            component={DateOfBirthCheck}
          />
          <Route
            exact
            path="/:email/sign-up-verify/:token/:wineryId"
            component={DateOfBirthCheck}
          />
          <Route
            exact
            path="/:email/sign-up-verify/:token/:wineryId/:wineryName"
            component={DateOfBirthCheck}
          />
          {/* <PrivateRoute exact path="/sign-up-verify" 
                                // component={DateOfBirthCheck} /> */}{" "}
          {/* --- based on previous on board process */}
          <PrivateRoute
            exact
            path="/rate-recommendedation/:id"
            component={RateRecommendation}
          />
          <PrivateRoute exact path="/shopping-cart" component={ShoppingCart} />
          {/*   If shopping cart page from stripe back button below url will get called, if page from stripe
                                        on back click by default it will navigate to available wines page, that was handled in shopping cart header 
                                        component*/}
          <PrivateRoute
            exact
            path="/shopping-cart/stripe"
            component={ShoppingCart}
          />
          <PrivateRoute
            exact
            path="/shopping-cart/shipping-address"
            component={ShoppingCartShippingAddress}
          />
          <PrivateRoute
            exact
            path="/shopping-cart/checkout"
            component={ShoppingCartCheckout}
          />
          <PrivateRoute
            exact
            path="/shopping-cart/checkout/stripe"
            component={ShoppingCartCheckout}
          />
          <PrivateRoute
            exact
            path="/shopping-cart/payment"
            component={PaymentDetails}
          />
          <PrivateRoute exact path="/my-orders" component={MyOrders} />
          <PrivateRoute
            exact
            path="/receipt-details/:orderId"
            component={ReceiptDetails}
          />
          <Route exact path="/help-page" component={HelpPage} />
          <PrivateRoute
            exact
            path="/stripe-payment"
            component={StripePayment}
          />
          <PrivateRoute
            exact
            path="/stripe-payment-success"
            component={StripePaymentSuccess}
          />
          <PrivateRoute
            exact
            path="/stripe-payment-cancel"
            component={StripePaymentCancel}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/discover"
            component={CustomerPassportDiscover}
          />
          {/* <AgeConfirmedRoute
          location={this.props.location}
          exact
          path="/visit"
          component={Visit}
        /> */}
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/visit"
            component={RegionBasedWineries}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/region-based-wineries/:regionId/:regionName"
            component={RegionBasedWineries}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/regions/:regionId/:regionName"
            component={RegionBasedWineries}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pa-recommendations"
            component={isMobile ? PARecommendation : PARecommendationDesktop}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pa-recommendations/mobile"
            component={PARecommendation}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/pa-recommendations/desktop"
            component={PARecommendationDesktop}
          />
          <AgeConfirmedRoute
            exact
            path="/pa-recommended-varietals/:requestId"
            component={PARecommendedVarietals}
          />
          <Route exact path="/age-confirm" component={AgeConfirmation} />
          <PrivateRoute exact path="/feedback" component={AppFeedback} />
          <PrivateRoute
            exact
            path="/shopping-cart/billing-address"
            component={ShoppingCartBillingAddress}
          />
          <AgeConfirmedRoute
            exact
            path="/virtual-tasting"
            component={VirtualWineTasting}
          />
          {/* <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/TargetCalifornia"
            component={LandingPage}
          /> */}
          <Route exact path="/techstars" component={TDLanding} />
          <Route
            exact
            path="/techstars/pairing/:foodId"
            component={TDPairing}
          />
          <Route exact path="/dd-welcome" component={DDLanding} />
          <Route exact path="/dd-wine/:wineId" component={DDPairing} />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/:wineryName"
            component={WineryLanding}
          />
          <AgeConfirmedRoute
            location={this.props.location}
            exact
            path="/winery/:wineryName"
            component={WineryDetails}
          />
          {/* <PrivateRoute authed={this.state.authed} path='/forgot-password' component={ForgotPassword} /> */}
          <Route exact path="*" component={PageNotFound} />
        </Switch>
        {/* {isMobile ? null : <Footer />} */}
      </div>
    );
  }
}

const RouteChangeTracker = ({ history }) => {
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
  return;
};

//  Add Private Route here
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        AuthUser.verifyAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { redirectTo: props.location.pathname },
            }}
          />
        )
      }
    />
  );
}
//  Add Private Route here
function AgeConfirmedRoute({ component: Component, ...rest }) {
  const history = useHistory();
  let ageConfirmed = localStorage.getItem("ageConfirmed");
  let signInToken = localStorage.getItem("signin_token");
  let anonymousSession = localStorage.getItem("anonymous_session");
  const pathName = history.location.pathname.split("/");
  if (pathName[2] === "pairings") {
    localStorage.setItem("ageConfirmed", true);
    ageConfirmed = localStorage.getItem("ageConfirmed");
  }
  if (!anonymousSession || anonymousSession == null) {
    getUserSession();
  } else if (
    !moment(new Date()).isBetween(
      moment(anonymousSession.sessionStartedAt),
      moment(anonymousSession.sessionExpiresAt)
    )
  )
    getUserSession();
  return (
    <Route
      {...rest}
      render={(props) =>
        signInToken || (ageConfirmed && ageConfirmed == "true") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { redirectTo: props.location.pathname },
            }}
          />
        )
      }
    />
  );
}
function DefaultAgeConfirmRoute({ component: Component, ...rest }) {
  let ageConfirmed = localStorage.getItem("ageConfirmed");
  let signInToken = localStorage.getItem("signin_token");

  return (
    <Route
      {...rest}
      render={(props) =>
        signInToken || (ageConfirmed && ageConfirmed == "true") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/age-confirm",
              state: {
                redirectTo: props.location.pathname,
                defaultAskAgeConfirmation: true,
              },
            }}
          />
        )
      }
    />
  );
}

const AuthUser = {
  verifyAuth() {
    if (!localStorage.getItem("signin_token")) {
      return false;
    }
    return true;
  },
};

// the user to the respective screens if coming from eamil campaign
const mailCampaignNavigate = (queryString, props) => {
  // check if user is already logged in, if yes navigate to desired page else navigate to sign-in screen and pass details in state.
  if (!!matchPath(props.location.pathname, "/:email/sign-up-verify/:token")) {
    // props.history.push(props.location.pathname, { queryString });
  } else {
    if (!AuthUser.verifyAuth()) {
      props.history.push("/sign-in", { queryString });
    } else {
      fetchCampaignDetails(queryString, props.history);
    }
  }
};
function checkforQuery(props) {
  /*fetch query strings from URL. Used to identify if user is redirected from email campaign.
   * if query present, fetch required data and navigate
   * else normal navigation flow goes through.
   */
  let query = queryString.parse(props.location.search);
  if (Object.keys(query).length !== 0 && query["campaignId"]) {
    console.log("User directed from mail campaign");
    mailCampaignNavigate(query, props);
  }
}
const getUserSession = () => {
  let userSessionUrl =
    constants.IDENTITY_API + "/api/v1/public/user/post/mySessionId";
  let data = {
    userAgent: window.navigator.userAgent,
    sessionStartedAt: moment(new Date()).utc(),
  };

  axiosCall("POST", userSessionUrl, data)
    .then((resp) => {
      localStorage.setItem("anonymous_session", JSON.stringify(resp.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export default withRouter(AppRouter);
