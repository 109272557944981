import React, { Component } from "react";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import "./ReceiptDetails.scss";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
// import GreyHomeIcon from "../../../assets/images/MyOrderPageIcons/grey-home-icon.svg";
// import GreyPackedIcon from "../../../assets/images/MyOrderPageIcons/grey-packed-icon.svg";
// import GreyShippedIcon from "../../../assets/images/MyOrderPageIcons/grey-shipped-icon.svg";
import CheckCircleGreen from "../../../assets/images/MyOrderPageIcons/check-circle-green.svg";
// import CancelRedIcon from "../../../assets/images/MyOrderPageIcons/x-circle-red.svg";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
// import winePlaceholder from "../../../assets/images/wine-placeholder.jpg";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";
import * as commonFunctions from "../../constants/commonFunctions";
import moment from "moment";
import { getOrderDetails } from "./action";
import * as constants from "../../constants/constants";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import { isMobile } from "react-device-detect";
// FUNCTIONAL COMPONENTS

function RenderWineDetails({ wineData }) {
  return (
    <div className="card-bottom">
      <div className="order-details-img-wine-details-div">
        <div className="receipt-win-img-div">
          <img
            alt="Wine Logo"
            className={
              wineData.wine.wineImageSmall
                ? "receipt-details-wine-img"
                : "receipt-details-wine-empty-img"
            }
            src={
              wineData.wine.wineImageSmall
                ? wineData.wine.wineImageSmall
                : wineBottlePlaceholder
            }
          />
        </div>
        <div className="receipt-wine-details-div ft-s-m">
          {/* <div className="receipt-winery-name">
            <span>Scott Harver Wines</span> 
          </div> */}
          <div className="theme-color ft-wt-600">
            <span>
              {wineData.wine.wineName != null &&
              wineData.wine.wineName != undefined
                ? wineData.wine.wineName
                : ""}
            </span>
          </div>
          <div>
            <span>
              {wineData.wine.wineType ? wineData.wine.wineType : ""}
              {wineData.wine
                ? wineData.wine.specifications
                  ? wineData.wine.specifications.varietal
                    ? ", " + wineData.wine.specifications.varietal
                    : ""
                  : ""
                : ""}
              {/* {wineData.wine
                ? wineData.wine.specifications
                  ? wineData.wine.specifications.appellation
                    ? ", " + wineData.wine.specifications.appellation
                    : ""
                  : ""
                : ""} */}
            </span>
          </div>
          <div className="ft-wt-600">
            <span>Quantity: {wineData.quantity}</span>
          </div>
          <div className="theme-color ft-wt-600">
            <span>{commonFunctions.formatter.format(wineData.unitPrice)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

class ReceiptDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetailsSummary: "",
      orderDetailsItemsSummary: "",
      getOrderDetailsApiCallDone: false,
      errorMessage: "",
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = async () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    let orderId = this.props.match.params.orderId;
    let getOrderDetailsListUrl =
      constants.SHOPPING_CART_API +
      "/api/v1/users/orders/" +
      orderId +
      "/items";
    let getOrderDetailsUrl =
      constants.SHOPPING_CART_API + "/api/v1/orders/" + orderId;

    // Getting Order Summary
    try {
      const data = await getOrderDetails(getOrderDetailsUrl);
      this.setState({ orderDetailsSummary: data });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      this.setState({
        errorMessage: "Something went wrong.Please try again later.",
      });
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getOrderDetailsUrl,
        operation: "GET",
      };
      commonFunctions.postCaughtException(
        JSON.stringify(apiErrorObj),
        consumerData,
        null
      );
    }

    // Getting Order Items Details
    try {
      const data = await getOrderDetails(getOrderDetailsListUrl);
      this.setState({ orderDetailsItemsSummary: data.rows });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      this.setState({
        errorMessage: "Something went wrong.Please try again later.",
      });
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getOrderDetailsListUrl,
        operation: "GET",
      };
      commonFunctions.postCaughtException(
        JSON.stringify(apiErrorObj),
        consumerData,
        null
      );
    }
    this.setState({ getOrderDetailsApiCallDone: true });
  };

  render() {
    const orderStatus = this.state.orderDetailsSummary.orderStatus;
    return (
      <div
        className="success-main-container"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div
          className="success-inner-container new-discover-inner-container"
          id="parentShoppingCartDiv"
        >
          {" "}
          <HeaderWithBurgerIcon
            title="Order Details"
            arrowIcon={true}
            goBackFunc={this.goBack}
          />
          <div
            className={isMobile ? null : "desktop-div-scroll "}
            style={{ backgroundColor: "#f2f2f2" }}
          >
            {/* <div className="common-page-header">
              <div className="common-page-sub-header new-common-header-change">
                <img
                  alt="Go Back"
                  className="common-sub-header-back-icon ptr-clss"
                  onClick={this.goBack}
                  src={backBtn}
                />
                <div className="new-header-text">
                  <span className="common-sub-header-text new-search-wine-for-dish-text">
                    Order Details
                  </span>
                </div>
                <img
                  alt="PairAnything"
                  className="common-sub-header-logo new-logo-header-design ml-0"
                  src={pairAnythingLogo}
                  onClick={() => {
                    this.openHomePage();
                  }}
                />
              </div>
            </div> */}
            {this.state.getOrderDetailsApiCallDone &&
              this.state.errorMessage === "" && (
                <React.Fragment>
                  <div className="mt-4 rcpt-details-card">
                    <div className="card-container-details">
                      <div>
                        <span className="order-hist-lbl-colour">
                          Order Number
                        </span>
                      </div>
                      <div className="ml-auto history-status">
                        <span>{this.state.orderDetailsSummary.id}</span>
                      </div>
                    </div>
                    <div className="card-container-details mt-3">
                      <div>
                        <span className="order-hist-lbl-colour">
                          Order Date
                        </span>
                      </div>
                      <div className="ml-auto history-status">
                        <span>
                          {moment
                            .utc(this.state.orderDetailsSummary.paymentDate)
                            .local()
                            .format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="card-container-details mt-3">
                      <div>
                        <span className="order-hist-lbl-colour">
                          Order Status
                        </span>
                      </div>
                      <div className="ml-auto history-status">
                        <span
                          className={
                            orderStatus == "Confirmed"
                              ? "shpmt-open-lbl"
                              : orderStatus === "Packed"
                              ? "shpmt-transit-lbl"
                              : orderStatus === "Canceled"
                              ? "shpmt-cancld-lbl"
                              : orderStatus === "Shipped"
                              ? "shpmt-shipped-lbl"
                              : "shpmt-delvrd-lbl"
                          }
                        >
                          {orderStatus === "Confirmed" ? "Open" : orderStatus}
                        </span>
                      </div>
                    </div>
                    <div className="card-container-details mt-3">
                      <div>
                        <span className="order-hist-lbl-colour">Winery</span>
                      </div>
                      <div className="ml-auto history-status">
                        <span>
                          {this.state.orderDetailsSummary.winery.displayName}
                        </span>
                      </div>
                    </div>
                    <div className="card-container-details mt-3">
                      <div>
                        <span className="order-hist-lbl-colour">
                          Tracking Link
                        </span>
                      </div>
                      <div className="ml-auto history-status">
                        <span>
                          {this.state.orderDetailsSummary
                            .shipmentTrackingUrl ? (
                            <a
                              className="ptr-class"
                              onClick={(e) => {
                                this.navigateTracking(
                                  e,
                                  this.state.orderDetailsSummary
                                    .shipmentTrackingUrl
                                );
                              }}
                              href={
                                this.state.orderDetailsSummary
                                  .shipmentTrackingUrl
                              }
                              style={{ color: "#CD4545" }}
                              target="_blank"
                            >
                              {" "}
                              Click here{" "}
                            </a>
                          ) : (
                            "--"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="order-receipt-details-header">
          <div className="order-details-id-value-div">
            <span className="order-details-id-value">
              Order ID #lorem9230456
            </span>
          </div>
          <div className="ml-auto order-details-header-end">
            <img
              alt="Packed"
              className="order-receipt-status-img pr-2"
              src={GreyShippedIcon}
            />
            <span className="pr-2">Shipped</span>
          </div>
        </div> */}
                  {this.renderOrderSummary()}
                  {/* <div className="receipt-placed-on-div ft-s-m">
          <div className="order-details-id-value-div">
            <span className="order-details-id-value ft-color-light-grey">
              Order Place On: 26/03/2020
            </span>
          </div>
        </div> */}
                  <div className="ft-size-1em">
                    <div className="receipt-wine-details-container mt-4">
                      <div className="winery-name-container order-s-padding">
                        <div className="order-hist-winery-name">
                          <span>Order Summary</span>
                        </div>
                      </div>
                      <div className="brder-btm-brkup mt-1 mb-3">{""}</div>
                      <div className="receipt-wine-details-card">
                        <ErrorBoundary>
                          {this.state.orderDetailsItemsSummary.map(
                            (el, index) => (
                              <RenderWineDetails wineData={el} key={index} />
                            )
                          )}
                        </ErrorBoundary>
                        {/* <div className="ft-size-receipts ln-hght">
              <div className="receipt-qty-breakup-div mt-4">
                <div className="ft-color-light-grey">
                  <span>Order Placed On:</span>
                </div>
                <div className="ml-auto">
                  <span className="ft-color-light-grey">26/03/2020</span>
                </div>
              </div>
              <div className="">
                <ErrorBoundary>
                  {this.state.orderDetailsOfWines.map((el1, index) => (
                    <RenderOrderedQuantityDetails
                      orderDetails={el1}
                      key={index}
                    />
                  ))}
                </ErrorBoundary>
              </div>
              <div className="receipt-qty-breakup-div theme-color ft-wt-600">
                <div className="">
                  <span>Grand Total:</span>
                </div>
                <div className="ml-auto">
                  <span className="">
                    {commonFunctions.formatter.format(2067)}
                  </span>
                </div>
              </div>
              <div className="receipt-qty-breakup-div mb-4 ft-wt-600">
                <div className="">
                  <span>Paid Via</span>
                </div>
                <div className="ml-auto">
                  <span className="">Credit/Debit Card</span>
                </div>
              </div>
            </div> */}
                      </div>
                    </div>
                  </div>
                  {this.renderShippingAddress()}
                </React.Fragment>
              )}
            {this.state.getOrderDetailsApiCallDone &&
              this.state.errorMessage != "" && (
                <div className="recommendation-history-main-container new-error-css">
                  <div className="error-boundary-main-container">
                    <span className="error-boundary-title">
                      {this.state.errorMessage}
                    </span>
                    <div className="error-boundary-image-container">
                      <img
                        alt="Something went wrong"
                        className="error-boundary-image"
                        src={errorBoundaryImage}
                      />
                    </div>
                  </div>
                </div>
              )}
            <div className="bottom-scroll-padding"></div>
            <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
              <BottomNavigation />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrderSummary = () => {
    const oderSummary = this.state.orderDetailsSummary;
    return (
      <div className="mt-4 font-family order-details-history-container ft-size-1em">
        <div className="ft-size-1em">
          <div className="winery-name-container">
            <div className="order-hist-winery-name">
              <span>Payment Details</span>
            </div>
            <div className="ml-auto">
              <span className="ft-w-normal">Paid</span>
            </div>
            <img
              alt="paid"
              className="pl-3 profile-right-arrow-btn tick-icon"
              src={CheckCircleGreen}
            />
          </div>
          <div className="brder-btm-brkup mt-3 mb-3">{""}</div>
          <div className="order-details-history-header">
            <div>
              <span className="order-hist-lbl-colour">Sub Total</span>
            </div>
            <div className="ml-auto history-status">
              <span>
                {commonFunctions.formatter.format(oderSummary.orderedAmount)}
              </span>
            </div>
          </div>
          {oderSummary.discountAmount == 0 ? null : (
            <div className="order-details-history-header">
              <div>
                <span className="order-hist-lbl-colour">Discount</span>
              </div>
              <div className="ml-auto history-status">
                <span className="promo-code-amnt-color">
                  {commonFunctions.formatter.format(
                    -oderSummary.discountAmount
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="order-details-history-header">
            <div>
              <span className="order-hist-lbl-colour">Salex Tax</span>
            </div>
            <div className="ml-auto history-status">
              <span>
                {commonFunctions.formatter.format(oderSummary.salesTaxAmount)}
              </span>
            </div>
          </div>
          {oderSummary.discountAmount == 0 ? null : (
            <div className="order-details-history-header">
              <div>
                <span className="order-hist-lbl-colour">Shipping Charges</span>
              </div>
              <div className="ml-auto history-status">
                <span>
                  {commonFunctions.formatter.format(oderSummary.shippingCost)}
                </span>
              </div>
            </div>
          )}
          <div className="brder-btm-brkup mt-3 mb-3">{""}</div>
          <div className="order-details-history-header mb-0">
            <div>
              <span className="order-hist-lbl-colour">Total Amount</span>
            </div>
            <div className="ml-auto history-status">
              <span>
                {commonFunctions.formatter.format(oderSummary.totalAmount)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderShippingAddress = () => {
    const shippingdAddress = this.state.orderDetailsSummary.user_address;
    return shippingdAddress !== null ? (
      <div className="order-summary-details-container">
        <div className="mt-4 font-family order-details-history-container ft-size-1em">
          <div className="ft-size-1em">
            <div className="winery-name-container">
              <div className="order-hist-winery-name">
                <span>{commonFunctions.capitalize(shippingdAddress.name)}</span>
              </div>
            </div>
            <div className="order-details-shpng-add-container">
              <div className="shpng-address-inner-container">
                <span className="order-hist-lbl-colour">
                  {commonFunctions.capitalize(shippingdAddress.addressLine1)}{" "}
                </span>
              </div>
              <div className="shpng-address-inner-container">
                <span className="order-hist-lbl-colour">
                  {shippingdAddress.addressLine2
                    ? commonFunctions.capitalize(shippingdAddress.addressLine2)
                    : " "}{" "}
                </span>
              </div>
              <div className="shpng-address-inner-container">
                <span className="order-hist-lbl-colour">
                  {commonFunctions.capitalize(shippingdAddress.city)}
                  {", "}
                  {this.state.orderDetailsSummary.shippingStateAbbreviation
                    .abbreviation + " "}
                  {shippingdAddress.zipcode}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  };
  openHomePage = () => {
    this.props.history.push("/discover");
  };

  navigateTracking = (e, link) => {
    e.preventDefault();
    if (link) {
      if (!link.includes("http://") && !link.includes("https://")) {
        link = "https://" + link;
      }
    }
    window.open(link, "_blank");
  };
}
export default ReceiptDetails;
