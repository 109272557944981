import React, { Component } from "react";
import "./PARecommendedVarietals.scss";

import backBtn from "../../../assets/images/left-arrow-white.svg";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";

import recommendationResponseImage from "../../../assets/images/EmptyPageCard/recommendation-response-image.svg";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import * as constants from "../../constants/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import PageNotFound from "../PageNotFound/PageNotFound";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import * as commonFunctions from "../../constants/commonFunctions";
import { getFoodRequestDetails } from "../../screens/PARecommendation/action";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import TastePreferencePopUp from "../../components/TastePreferencePopUp/TastePreferencePopUp";
import axiosCall from "../../constants/index";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
// import { setpaReco } from "../../redux/actions/PARecommendationAction";
import { connect } from "react-redux";

toast.configure();

class PARecommendedVarietals extends Component {
  intervalId = 0;

  constructor(props) {
    super(props);
    this.state = {
      responseForRequest: "",
      errorMessage: "",
      requestId: "",
      showTastePreferencePopUp: false,
    };
  }

  componentDidMount = async () => {
    let requestId;
    if (this.props.requestId) {
      requestId = this.props.requestId;
    } else {
      requestId = this.props.match.params.requestId;
    }
    // let requestId = this.props.match.params.requestId ? this.props.match.params.requestId : (this.props.requestId ? this.props.requestId : '');

    this.setState({
      requestId: requestId,
    });

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    await this.getFoodRequestDetailsFunction(requestId);
    // Check taste preference is there or not and open taste preference pop-up
    if (isMobile) {
      setTimeout(async () => {
        await this.onCheckTastePreference();
      }, 3000);
    }
  };

  getFoodRequestDetailsFunction = async (requestId) => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let getRequestDetailsUrl =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/public/recommendation/paRequest/" +
      requestId;
    try {
      const recommendedDetails = await getFoodRequestDetails(
        getRequestDetailsUrl
      );
      // if (consumerData && consumerData == null) {
      // } else this.props.dispatch(setpaReco([recommendedDetails]));
      console.log(recommendedDetails);
      if (recommendedDetails.pa_reco_items.length == 0) {
        if (this.intervalId == 0) {
          this.intervalId = setInterval(
            (e) => this.getFoodRequestDetailsFunction(requestId),
            5000
          );
        }
      } else {
        if (this.intervalId != 0) {
          clearInterval(this.intervalId);
        }
      }
      this.setState({ responseForRequest: recommendedDetails });
    } catch (e) {
      this.setState({
        errorMessage: "Something went wrong.Please try again later.",
      });

      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getRequestDetailsUrl,
        operation: "GET",
        status: e.status,
      };
      if (consumerData && consumerData != null)
        commonFunctions.postCaughtException(
          JSON.stringify(apiErrorObj),
          consumerData,
          null
        );
    }
  };

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.requestId !== this.props.requestId) {
      this.getFoodRequestDetailsFunction(this.props.requestId);
    }
  }
  goBack = () => {
    this.props.history.goBack();
  };

  onRenderClassNamesForCardHeader() {
    let className = "card shadow p-3 mb-5 rounded";
    if (
      this.props &&
      this.props.wineDataFromRecommendedHistory &&
      this.props.wineDataFromRecommendedHistory.isDeleted
    ) {
      className += " wine-details-card-not-available";
    }

    className += " wine-details-card-available";

    return className;
  }

  renderRecommendationDataBasedOnHistory = () => {
    // let result = [];

    if (this.state.errorMessage) {
      return (
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.responseForRequest) {
      if (this.state.responseForRequest.pa_reco_items.length > 0) {
        let varietalList = [];
        let data = this.state.responseForRequest.pa_reco_items;
        if (data != null) {
          varietalList = this.state.responseForRequest.pa_reco_items;

          return (
            <div className={"recommendations-response-container"}>
              <span className={"recommendations-response-text"}>
                {" "}
                Here is your personalized varietal recommendation for your
                <span className="recommendations-dish-name">
                  {this.state.responseForRequest.request}.
                </span>
              </span>
              <div>
                <ErrorBoundary>
                  {varietalList.map((el, index) => (
                    <div key={index} className={"card shadow p-3 mb-5 rounded"}>
                      <div className="varietal-details-div font-family ft-s-17">
                        <div className="varietal-details-container">
                          <img
                            alt="Wine Type Image"
                            className="wine-type-img"
                            src={el.wine_type.imageUrl}
                          />
                          <div className="varietal-details">
                            <div className="heading-name-container">
                              <span>Varietal </span>
                            </div>
                            <div className="name-container">
                              <span className="">{el.varietalName}</span>
                            </div>
                            <div className="heading-name-container mt-3">
                              <span>Wine Type </span>
                            </div>
                            <div className="name-container">
                              <span className="">{el.wine_type.type}</span>
                            </div>
                          </div>
                        </div>
                        <div className="varietal-card-bottom">
                          <div
                            className={
                              "wine-details-order-cart add-to-cart br-none bl-none h-0 vareital-details-shop-btn-mob"
                            }
                          >
                            <button
                              className="btn btn-round rate-recommendation-btn add-to-cart-new-design"
                              onClick={this.goToShopWines}
                            >
                              Shop Wines
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </ErrorBoundary>
              </div>
            </div>
          );
        }
      } else {
        let emptyCardData;
        if (this.state.responseForRequest.noRecoExcludedFoodName) {
          emptyCardData = {
            title: "",
            text: `We don't recognize '${commonFunctions.capitalize(
              this.state.responseForRequest.request
            )}' as a food item. Please enter a different search.`,
            image: "",
          };
        } else {
          emptyCardData = {
            title: "",
            text: "Pairing is in progress… kindly give us a moment to get your recommendation.",
            image: recommendationResponseImage,
          };
        }
        return (
          <div className={"empty-page-main-container"}>
            <EmptyPageCard emptyPageCardData={emptyCardData} />
          </div>
        );
      }
    }
  };

  goToShopWines = () => {
    this.props.history.push({
      pathname: "/wineries",
      state: {
        navigateTo: "shop",
      },
    });
  };

  renderRecommendationBasedOnStatus = () => {
    let result = [];
    let i = 0;
    result.push(
      <div key={i++} className={"recommendations-container"}>
        {this.renderRecommendationDataBasedOnHistory()}
      </div>
    );
    return result;
  };

  render() {
    return (
      <div
        className="success-main-container"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className=" success-inner-container  new-discover-inner-container">
          <HeaderWithBurgerIcon
            title="Pairing Recommendations"
            arrowIcon={true}
            goBackFunc={() => this.props.history.goBack()}
          />
          <div
            className={isMobile ? null : "desktop-div-scroll "}
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <div className="recommendations-main font-family">
              <div className={"page-main-container"}>
                {this.state.errorMessage ? (
                  <div>
                    <PageNotFound></PageNotFound>
                  </div>
                ) : (
                  <span>{this.renderRecommendationBasedOnStatus()}</span>
                )}
                {this.state.showTastePreferencePopUp && (
                  <TastePreferencePopUp
                    open={this.state.showTastePreferencePopUp}
                    handleYes={this.handleYes} // go to select taste preference
                    handleCancel={this.handleCancel} // do it later
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  onCheckTastePreference = async () => {
    let popUp = localStorage.getItem("do_it_later");
    let consumerData = JSON.parse(localStorage.getItem("user_data"));

    if (popUp === null && consumerData && consumerData != null) {
      let url =
        constants.CONSUMER_API +
        "/api/v1/taste_preference/findDefaultTastePreferenceByCustomer?userId=" +
        consumerData.id;
      try {
        await axiosCall("get", url);
        this.setState({ showTastePreferencePopUp: false });
      } catch (e) {
        // If it throws error means no taste preference is present for the use
        // console.log('err response', e);
        this.setState({ showTastePreferencePopUp: true });
      }
    }
  };

  // Go to select taste preference
  handleYes = () => {
    GoogleAnalyticsFunctions.clickedSelectTastePreference();
    this.setState({
      showTastePreferencePopUp: false,
    });
    this.props.history.push({
      pathname: "/user-taste-preference",
      state: {
        path: this.props.location.pathname,
      },
    });
  };

  //Do it later
  handleCancel = () => {
    GoogleAnalyticsFunctions.clickedDoItLater();
    this.setState({
      showTastePreferencePopUp: false,
    });
    localStorage.setItem("do_it_later", true);
  };
}
PARecommendedVarietals = withRouter(PARecommendedVarietals);
export default connect(null, null)(PARecommendedVarietals);
