import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { logPagesVisitedByUser } from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./pairing-results.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import SearchIcon from "../../../assets/images/PAIcons/search.svg";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import { DebounceInput } from "react-debounce-input";
import { post, get } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import { useSessionUrl } from "../../lib/hooks/useSession";
import { formatCurrency } from "../../lib/common/convertCurrency";
import { useQuery } from "../../lib/hooks/useQueryParams";
import VarietalCard from "./VarietalCard";
import ResultsScrollBar from "../../components/TargetComponents/ResultsScrollBar/ResultsScrollBar";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";
import NoResultImage from "../../../assets/images/PAImages/noresult.JPG";
import { useFormik } from "formik";
import BlueCheckCircle from "../../components/Icons/blue-check-circle";
import { sessionUrl } from "../../lib/common/sessionUrl";

export default function PairingResults() {
  const history = useHistory();
  const from = history.location.state?.from;
  const searchMissState = history.location.state?.searchMiss;
  const noResult = history.location.state?.noResult;
  const [searchFocus, setSearchFocus] = useState(false);
  const [currentVarietal, setCurrentVarietal] = useState(0);

  const [pairingResultUrl, setPairingResultUrl] = useState(null);
  const [showSearchMiss, setShowSearchMiss] = useState(!!noResult);
  const [searchMiss, setSearchMiss] = useState(searchMissState);

  const { search } = useParams();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    logPagesVisitedByUser();

    if (search != null) {
      setSearchValue(search);
      setPairingResultUrl(
        `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/food/${Number(
          search
        )}`
      );
    }
  }, []);

  //fetching the pairing results as [{varietal, wines}]
  const [pairingResultLoaded, setPairingResultLoaded] = useState(false);

  const { data: pairingResult } = useSessionUrl(pairingResultUrl);

  console.log(`pairingResult:`);
  console.log(pairingResult);

  useEffect(() => {
    if (!!pairingResult?.reco?.items) {
      console.log(`pairingResult:`);
      console.log(pairingResult);
      setPairingResultLoaded(true);
    }
  }, [pairingResult]);

  // setup formik -----------------------------

  const [formSubmittable, setFormSubmittable] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  useEffect(() => {
    //console.log(formik.errors, formik.values);
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik]);

  // ---------end fornik -----------------

  const onClickInstead = () => {
    setShowSearchMiss(true);
  };

  const sendPairingReq = async (values) => {
    console.log(`values:`);
    console.log(values);
    let pairingRequestBody = {
      email: values.email,
      request: searchMiss?.value,
      requestType: 1,
    };

    let url = `${constants.IDENTITY_API}/api/v1/public/support/pairingRequest`;
    try {
      const response = await post(
        sessionUrl(url),
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        pairingRequestBody
      );
      // const result = await response.json();
      // console.log(`result:`);
      // console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const submitForm = async (values) => {
    if (formSubmittable) {
      sendPairingReq(values);
      setShowSuccess(true);
    }
  };

  return (
    <Layout white>
      <AnimationWrapper animation={from == "left" ? "fromLeft" : "fromRight"}>
        <LayoutContents>
          <PAHeader left />

          {!showSearchMiss ? (
            <>
              <div className={`mc`}>
                <div className={styles.inputWithIcon}>
                  <img src={SearchIcon} alt="search icon" />
                  <DebounceInput
                    className={`p1-reg ${styles.searchBar}`}
                    debounceTimeout={500}
                    placeholder={`Try "tacos" or "moscato"`}
                    type="text"
                    name="dish-name"
                    value={pairingResult?.reco?.searchValue}
                    onFocus={() => {
                      const url = window.localStorage.getItem("winery_url");
                      url
                        ? history.push({
                            pathname: `/${JSON.parse(url)}`,
                            state: { from: "results" },
                          })
                        : history.push({
                            pathname: `/welcome`,
                            state: { from: "results" },
                          });
                    }}
                    onChange={(event) => setSearchValue(event.target.value)}
                    //onKeyDown={(event) => {
                    //  if (event.key === "Enter") {
                    // TODO - Render result (food/wine)
                    //  }
                    //}}
                  />
                </div>
              </div>
              {searchMiss ? (
                <div className={`${styles.blueBoxContainer}`}>
                  <div className={`${styles.blueBox}`}>
                    <div>
                      <p>
                        Showing results for&nbsp;
                        <i>{`${pairingResult?.reco?.searchValue}`}</i>.
                      </p>
                      <p
                        className={`${styles.bbLink}`}
                        onClick={() => onClickInstead(searchMiss.id)}
                      >
                        Search instead for {searchMiss.value}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {pairingResultLoaded ? (
                <ResultsScrollBar
                  itemList={pairingResult?.reco?.items}
                  currentSelection={currentVarietal}
                  setCurrentSelection={setCurrentVarietal}
                />
              ) : (
                <></>
              )}
              {pairingResultLoaded ? (
                <VarietalCard
                  idx={currentVarietal}
                  foodName={pairingResult?.reco?.searchValue}
                  searchValue={pairingResult?.reco?.searchValue}
                  varietalName={
                    pairingResult?.reco?.items[currentVarietal]?.varietal
                      ?.varietal
                  }
                  pairingResult={pairingResult?.reco?.items[currentVarietal]}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className={`mc`}>
                <div className={styles.inputWithIcon}>
                  <img src={SearchIcon} alt="search icon" />
                  <DebounceInput
                    className={`p1-reg ${styles.searchBar}`}
                    debounceTimeout={500}
                    placeholder={`Try "tacos" or "moscato"`}
                    type="text"
                    name="dish-name"
                    value={
                      searchMiss?.value || pairingResult?.reco?.searchValue
                    }
                    onFocus={() => {
                      const url = window.localStorage.getItem("winery_url");
                      url
                        ? history.push({
                            pathname: `/${JSON.parse(url)}`,
                            state: { from: "results" },
                          })
                        : history.push({
                            pathname: `/welcome`,
                            state: { from: "results" },
                          });
                    }}
                    onChange={(event) => setSearchValue(event.target.value)}
                    //onKeyDown={(event) => {
                    //  if (event.key === "Enter") {
                    // TODO - Render result (food/wine)
                    //  }
                    //}}
                  />
                </div>
              </div>{" "}
              <div className={`mc ${styles.noResultBox}`}>
                <div className={`${styles.noResultHeader}`}>
                  <h1 className={`h1-30`}>
                    No results for{" "}
                    <q>
                      {searchMiss?.value || pairingResult?.reco?.searchValue}
                    </q>
                    .
                  </h1>
                  {!noResult ? (
                    <p className={`p1-reg`}>
                      Did you mean{" "}
                      <span
                        className={`p1-reg ${styles.searchInstead}`}
                        onClick={() => {
                          setSearchMiss(false);
                          setShowSearchMiss(false);
                        }}
                      >
                        {pairingResult?.reco?.searchValue}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={`${styles.noResultImgBox}`}>
                  <img
                    className={`${styles.noResultImg}`}
                    src={NoResultImage}
                    alt="no result image"
                  />
                </div>
                <div className={`${styles.sendRequestBox}`}>
                  <p className={`p1-reg`}>
                    Looking for{" "}
                    <q>
                      {searchMiss?.value || pairingResult?.reco?.searchValue}
                    </q>{" "}
                    pairings? Enter your email, and we'll work our magic to make
                    it happen for you.
                  </p>

                  <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className={`${styles.formContainer}`}
                  >
                    <input
                      placeholder="Enter your email"
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className={`${styles.formInput} p1-reg`}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className={styles.formFieldError}>
                        {formik.errors.email}
                      </div>
                    ) : null}

                    <button
                      className={`${styles.submitBtn} ${
                        !formSubmittable ? styles.grayedOut : null
                      }`}
                      type="submit"
                      style={{ marginTop: "16px" }}
                    >
                      Request Pairing
                    </button>
                  </form>
                </div>
                <AnimationWrapper animation="fadeIn" active={showSuccess}>
                  {" "}
                  {showSuccess ? (
                    <div className={`${styles.blueBoxSuccessContainer}`}>
                      <div className={`${styles.blueBoxSuccess}`}>
                        <BlueCheckCircle />
                        <p>Request sent!</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </AnimationWrapper>
              </div>
            </>
          )}
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}
