import React from "react";
import { createMuiTheme, Modal, Slide, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme({
  palette: {
    primary: { main: "#cd4545" },
  },
});

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      bottom: "0 !important",
      background: "none",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiTypography-body1": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
    },
    ".MuiInputBase-input": {
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif !important",
      width: "100%",
      border: 0,
      display: "block",
      padding: "6px 0 7px",
      minWidth: 0,
      background: "none",
      boxSizing: "content-box",
      animationName: "mui-auto-fill-cancel",
      lineHeight: "1.2",
    },
  },
})(() => null);
const SignInToContinue = ({
  signInPopUp,
  where,
  url,
  handleClose,
  state,
  addToCartAutomatically,
}) => {
  const mystyle = {
    color: "black",
    fontSize: "1.3em",
    background: "FFFFFF !important",
  };
  const history = useHistory();
  const popupHandle = () => {
    history.push("/sign-in", {
      redirectTo: url,
      ...state,
      addToCartAutomatically,
    });
  };
  return (
    <Modal
      open={signInPopUp}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="modal-style"
    >
      <div className="modal-bg font-family">
        {/* <div
          onTouchStart={() => {
            handleClose();
          }}
          className="thin-line-div new-thin-line-div"
        >
          <div className="thin-line"></div>
        </div> */}
        <div className="headertext-style" style={{ textAlign: "center" }}>
          <span>Please Sign-in {where}</span>
        </div>
        <div className="new-footer-pop-btn-css">
          <div className="new-cancel-css">
            <button
              style={{ minWidth: "100px" }}
              className="btn btn-round cancel-button new-cnacel-btn-css"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
          <div className="new-yes-css">
            <button
              style={{ minWidth: "100px" }}
              className={"btn btn-round save-button"}
              onClick={() => {
                popupHandle();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SignInToContinue;
