import React from 'react';
import SplashLogo from "../logo/splash";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";

export default function Header() {
  return (
    <div className="header-container">
      <div className="success-logo-container new-discover-logo-container">
        <SplashLogo />
      </div>
    </div>
  );
}
