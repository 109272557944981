import React, { useEffect, useState } from "react";
import "./PAResultsTastePreferenceCard.scss";
import * as constants from "../../constants/constants";
import { isMobile } from "react-device-detect";
import { get } from "../../lib/fetch";
import { isNumber } from "../../lib/common";
import useSWR from "swr";

function PAResultsTastePreferenceCard({ name, varietal, wineType, propStyle }) {
  const [expert, setExpert] = useState(undefined);
  const [profile, setProfile] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles) {
      setProfile(varietalProfiles[varietal.trimEnd()]);
    }
  }, [varietalProfiles, varietal]);

  const { data: wineExperts } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllExperts`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (wineExperts && profile) {
      setExpert(
        wineExperts.find((expert) => {
          return expert.id == profile.wine_expert_id;
        })
      );
    }
  }, [wineExperts, profile]);

  if (!profile) return <></>;

  return (
    <div
      className={isMobile ? "patpCard" : "patpCard-desktop"}
      style={propStyle}
    >
      <div className="tp-stats">
        <div className="tp-stats-name">
          <p>{wineType.split(",")[0]}</p>
          <p className="wine-varietal-text">{varietal}</p>
        </div>

        <div className="flavor-profile-list">
          {Object.keys(profile?.flavors)
            .reverse()
            .map((flavor, i) => {
              if (i >= 3) return <></>;
              return (
                <div className="flavor-profile-list-item">
                  <img
                    src={
                      [flavor] in profile?.flavors
                        ? profile?.flavors[flavor][
                            Math.floor(
                              Math.random() * profile?.flavors[flavor].length
                            )
                          ]
                        : undefined
                    }
                    className="flavor-profile-icon"
                  />
                  {flavor}
                </div>
              );
            })}
        </div>

        {isNumber(profile?.sweetness) && (
          <MeasureBar
            value={profile?.sweetness}
            maxValue={5}
            minName="Dry"
            maxName="Sweet"
          />
        )}
        <br />
        {isNumber(profile?.body) && (
          <MeasureBar
            value={profile?.body}
            maxValue={5}
            minName="Light"
            maxName="Bold"
          />
        )}
        <br />
        {isNumber(profile?.tannins) && (
          <MeasureBar
            value={profile?.tannins}
            maxValue={5}
            minName="Low Tannin"
            maxName="High Tannin"
          />
        )}
      </div>

      <div className="dashed-separator" />
      {expert && (
        <div className="reviews">
          <div className="reviews-list">
            <div className="reviews-list-item">
              <div>
                <img src={expert.img_url} className="flavor-profile-icon" />
              </div>
              <div className="reviews-list-item-text">
                <p style={{ color: "#343434" }}>"{profile.pairing_notes}"</p>
                <i>{expert.name}</i>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const MeasureBar = ({ value, maxValue, minName, maxName }) => {
  value = value < 0 ? 0 : value > 5 ? 5 : value;

  return (
    <>
      <div className="value-container">
        {[...new Array(value)].map((_) => {
          return <div className="valued-bar" />;
        })}
        {[...new Array(maxValue - value)].map((_) => {
          return <div className="not-valued-bar" />;
        })}
      </div>
      <div className="value-name-container">
        <p className={value < 4 ? "value-reached" : "value-not-reached"}>
          {minName}
        </p>
        <p className={value >= 4 ? "value-reached" : "value-not-reached"}>
          {maxName}
        </p>
      </div>
    </>
  );
};

export default PAResultsTastePreferenceCard;
