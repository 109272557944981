import React, { Component } from "react";
import logo from "../../../assets/images/splash.png";
import orderSuccess from "../../../assets/images/orderSuccess.svg";
import "./StripePaymentResponse.scss";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import { convertToCamelCase } from "../../constants/commonFunctions";

class StripePaymentSuccess extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {};
  }

  componentDidMount = () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  componentDidUpdate(prevProps, prevState) {}

  onContinueShoppingClick = () => {
    this.props.history.push(
      "/" + convertToCamelCase(localStorage.getItem("winery_name")) + "/wines"
    );
    // this.props.history.push("/available-wines");
  };

  render() {
    // const { onClickToSignIn, signUpSuccesWineImage } = this.props;
    return (
      <div className="success-main-container font-family">
        <div className="success-inner-container">
          <div className="page-content-container">
            <div className="header-container">
              {/* <div className="back-btn-container"> */}
              {/* <img className="" src={backBtn} onClick = { this.gotoSignInPage }/> */}
              {/* </div> */}
              <div className="success-logo-container">
                <img alt="PairAnything" className="logo-wwidth" src={logo} />
              </div>
              {/* <div className="set-password-title-success">Thank You!</div> */}
            </div>
            <div className="success-body-container">
              <div className="thanks-fonts-style">
                <div className="mt-4">
                  <span className="order-success-txt">Order Successful!</span>
                </div>
                <div className="confirmation-mail-txt mt-4">
                  <span>
                    We have sent you a confirmation mail with the order details.
                  </span>
                </div>
              </div>

              <div className="order-success-logo-container mt-4">
                <img alt="OrderSuccessImage" className="" src={orderSuccess} />
              </div>
            </div>

          </div>
          <div className="success-footer-container">
            <div className="continue-shpng-btn-container">
              {" "}
              <button
                type="button"
                onClick={() => this.onContinueShoppingClick()}
                className="btn btn-round view-summary-btn"
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StripePaymentSuccess;
