import React, { Component } from "react";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import * as constants from "../../constants/constants";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import ShoppingCartHeader from "../../components/ShoppingCartHeader/ShoppingCartHeader";
import "./ShoppingCartCheckout.scss";
import redAddIcon from "../../../assets/images/red-add-icon.svg";
import locationIcon from "../../../assets/images/ShippingAddress/address.svg";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from "react-toastify";
import * as commonFunctions from "../../constants/commonFunctions";
import promoTagIcon from "../../../assets/images/promoTagIcon.svg";
import rightArrowBtn from "../../../assets/images/right-arrow-profile.svg";
import axiosCall from "../../constants/index";
import { isMobile } from "react-device-detect";
toast.configure();
let consumerData;
let wineryId;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(constants.PUBLISHABLE_KEY);

class ShoppingCartCheckout extends Component {
  constructor(props) {
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    wineryId = JSON.parse(localStorage.getItem("winery_id"));
    super(props);
    this.state = {
      defaultShippingAddress: {
        id: "",
        userId: "",
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateName: "",
        stateId: "",
        zipcode: "",
        isPrimary: false,
      },
      paymentDetails: {},
      getCartCheckoutDetailsAPICallDone: false,
      userDetails: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
      },
      addressError: "",
      wineryDiscount: 0,
      promoCode: "",
      isSameBillingAndShippingAddress: true,
      billingAddressDetails: {},
      initialBillingAddressDetails: {},
      // cartDetails: []
    };
  }

  componentDidMount() {
    const isMounted = true;
    if (isMounted) {
      GoogleAnalyticsFunctions.logPagesVisitedByUser();
      this.onGetCartCheckoutDetails();
      // this.onGetWineryDiscount();
    }
  }

  onGetCartCheckoutDetails = () => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    try {
      let getCartCheckoutApiUrl =
        constants.SHOPPING_CART_API +
        "/api/v1/users/winery/" +
        wineryId +
        "/cart/checkout";
      fetch(getCartCheckoutApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getCartCheckoutApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.setState({
              getCartCheckoutDetailsAPICallDone: true,
            });
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          console.log(json);
          this.setState({
            getCartCheckoutDetailsAPICallDone: true,
            defaultShippingAddress: json.addressDetails,
            paymentDetails: json.paymentDetails,
            userDetails: json.userDetails,
            initialBillingAddressDetails: json.billingAddressDetails,
            billingAddressDetails:
              Object.entries(json.billingAddressDetails).length > 0
                ? json.billingAddressDetails
                : json.addressDetails,
            isSameBillingAndShippingAddress:
              Object.entries(json.billingAddressDetails).length > 0
                ? false
                : true,
            // cartDetails: json.cartDetails.rows
          });
          if (
            Object.entries(json.addressDetails).length === 0 &&
            !json.paymentDetails.isAvailableForShipping
          ) {
            this.setState({
              addressError: "Please add/modify address to continue.",
            });
          } else if (
            !json.paymentDetails.isAvailableForShipping &&
            !json.paymentDetails.isExcludedZipCode
          ) {
            this.setState({
              addressError:
                "Sorry, we don't ship to " +
                " " +
                json.addressDetails.stateName +
                ".",
            });
          } else if (
            !json.paymentDetails.isAvailableForShipping &&
            json.paymentDetails.isExcludedZipCode
          ) {
            this.setState({
              addressError:
                "Sorry, we don't ship to " +
                " " +
                json.addressDetails.zipcode +
                ".",
            });
          }
        })
        .catch((error) => {
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          this.setState({
            getCartCheckoutDetailsAPICallDone: true,
          });
          console.error(error);
        });
    } catch (e) {
      toast.info("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.setState({
        getCartCheckoutDetailsAPICallDone: false,
      });
      // console.log(e);
    }
  };

  onGetWineryDiscount = async () => {
    let discountUrl =
      constants.WINERY_API + "/api/v1/winery/getDiscount/" + wineryId;
    try {
      const wineryDiscount = await axiosCall("get", discountUrl);
      this.setState({
        promoCode: wineryDiscount.data.promoCode,
        wineryDiscount: wineryDiscount.data.discount
          ? parseFloat(wineryDiscount.data.discount)
          : 0,
      });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: discountUrl,
        operation: "GET",
        status: e.status,
      };
      commonFunctions.postCaughtException(
        JSON.stringify(apiErrorObj),
        consumerData,
        wineryId
      );
    }
  };

  onShippingAddressSelectionPage = () => {
    GoogleAnalyticsFunctions.onClickAddorModifyAddress();
    this.props.history.push("/shopping-cart/shipping-address");
  };

  onBillingAddressSelectionPage = () => {
    GoogleAnalyticsFunctions.onClickAddorModifyBillingAddress();
    this.props.history.push("/shopping-cart/billing-address");
  };

  render() {
    const addressData = this.state.defaultShippingAddress;
    const paymentData = this.state.paymentDetails;
    const userData = this.state.userDetails;
    const billingAddressData = this.state.billingAddressDetails;
    return (
      <React.Fragment>
        <div className="success-main-container ">
          <div className=" success-inner-container  new-discover-inner-container">
            <div className="overall-top-container">
              <ShoppingCartHeader />
              <div
                className={isMobile ? null : "desktop-div-scroll "}
                style={{ backgroundColor: "#f2f2f2" }}
              >
                {this.state.getCartCheckoutDetailsAPICallDone && (
                  <div className="font-family">
                    <div className="cart-stepper-header">
                      <div className="">
                        {/* <span className="dot-cart-stepper-select completed-cart-stepper"></span> */}
                        <span className="cart-name-new-css mr-3">Cart</span>
                      </div>{" "}
                      <div className="separator-stepper-select">
                        <span className="dot-cart-stepper-select"></span>
                        <span className="ml-3 mr-3">Address</span>
                      </div>{" "}
                      <div className="separator-stepper final-stepper-css">
                        <span className="dot-cart-non-select-stepper"></span>
                        <span className="ml-3 stepper-label-non-select">
                          Payment
                        </span>
                      </div>{" "}
                    </div>
                    <div className="font-family outer-container-div">
                      {this.renderShippingAddressDetails(
                        addressData,
                        paymentData,
                        userData
                      )}
                      {this.renderBillingAddressDetails(billingAddressData)}
                      {this.state.wineryDiscount > 0 &&
                        this.renderPromoDetails(paymentData)}
                      {this.renderPaymentDetails(paymentData)}
                    </div>
                    <div className="continue-button-container mb-2">
                      <button
                        type="button"
                        onClick={this.onClickProceedToPay}
                        disabled={
                          !paymentData.isAvailableForShipping &&
                          Object.entries(this.state.billingAddressDetails)
                            .length === 0
                        }
                        className={
                          paymentData.isAvailableForShipping &&
                          Object.entries(this.state.billingAddressDetails)
                            .length > 0
                            ? "btn btn-round continue-button"
                            : "btn btn-round continue-button disabled-btn-css"
                        }
                      >
                        Proceed to Pay
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderShippingAddressDetails = (addressData, paymentData, userData) => {
    return (
      <div className="shpng-adrs-div">
        <label className="shpng-adrs-label mt-2">Shipping Address</label>
        <div className="mt-3">
          <div className="default-address-div">
            {Object.entries(addressData).length != 0 && (
              <React.Fragment>
                <div className="mt-4 ml-3 mr-3">
                  <img
                    alt="Shipping Location"
                    className="shpng-lctn"
                    src={locationIcon}
                  />
                  <span className="address-type-label">
                    {commonFunctions.capitalize(addressData.name)}
                  </span>
                </div>
                <div className="shpng-address-details mt-3 mb-3 mr-3">
                  {/* <div>
                        <span className="address-type-label ml-0">
                          {commonFunctions.capitalize(userData.firstName) + ' '}{' '}
                          {userData.lastName
                            ? commonFunctions.capitalize(userData.lastName)
                            : ''}
                        </span>
                      </div> */}
                  <div className="mt-1 inner-address">
                    <span className="">
                      {addressData.addressLine1
                        ? commonFunctions.capitalize(addressData.addressLine1)
                        : " "}{" "}
                      {/* {addressData.addressLine2 ? commonFunctions.capitalize(addressData.addressLine2) + "," : " "} */}
                      {/* {addressData.city ? commonFunctions.capitalize(addressData.city) + "," : " "}{" "}
                                        {addressData.stateName} */}
                    </span>
                  </div>
                  <div className="inner-address">
                    <span className="">
                      {addressData.addressLine2
                        ? commonFunctions.capitalize(addressData.addressLine2)
                        : " "}{" "}
                    </span>
                  </div>
                  <div className="inner-address">
                    <span className="">
                      {addressData.city
                        ? commonFunctions.capitalize(addressData.city)
                        : " "}
                      {", "}
                      {addressData.state.abbreviation + " "}
                      {addressData.zipcode}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )}
            {!paymentData.isAvailableForShipping && (
              <div className="form-error-shipping-address-container mt-4 mb-0">
                <span className="form-error-shipping-address new-addrss-err-font-size">
                  {this.state.addressError}
                </span>
              </div>
            )}
            <div className="mt-4 ml-3 mr-3 mb-4">
              <button
                className="btn btn-round modify-add-address-btn ptr-clss"
                onClick={this.onShippingAddressSelectionPage}
              >
                Modify or Add Address
              </button>
            </div>
            <div className="mt-4 ml-3 mr-3 mb-4 blng-chckbox-div">
              <div className="chckbox-input-style">
                <input
                  type="checkbox"
                  name="isSameBillingAndShippingAddress"
                  // defaultChecked={this.state.checked}
                  onChange={(e) => {
                    this.handleCheckBoxChange(e);
                  }}
                  checked={this.state.isSameBillingAndShippingAddress}
                  className="new-sign-up-input-design ptr-clss new-billing-chckbox"
                />
                <label className="chck-box-label">
                  Billing address is same as shipping address{" "}
                </label>
              </div>
              {/* <div className="label-chckbx-style">
                                <label className="chck-box-label">
                                    Billing address is same as shipping address{' '}
                                </label>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderBillingAddressDetails = (billingAddressData) => {
    if (!this.state.isSameBillingAndShippingAddress) {
      return (
        <div className="shpng-adrs-div mt-3">
          <label className="shpng-adrs-label mt-2">Billing Address</label>
          <div className="mt-3">
            <div className="default-address-div">
              {Object.entries(billingAddressData).length != 0 ? (
                <React.Fragment>
                  <div className="mt-4 ml-3 mr-3">
                    <img
                      alt="Shipping Location"
                      className="shpng-lctn"
                      src={locationIcon}
                    />
                    <span className="address-type-label">
                      {commonFunctions.capitalize(billingAddressData.name)}
                    </span>
                  </div>
                  <div className="shpng-address-details mt-3 mb-3 mr-3">
                    <div className="mt-1 inner-address">
                      <span className="">
                        {billingAddressData.addressLine1
                          ? commonFunctions.capitalize(
                              billingAddressData.addressLine1
                            )
                          : " "}{" "}
                      </span>
                    </div>
                    <div className="inner-address">
                      <span className="">
                        {billingAddressData.addressLine2
                          ? commonFunctions.capitalize(
                              billingAddressData.addressLine2
                            )
                          : " "}{" "}
                      </span>
                    </div>
                    <div className="inner-address">
                      <span className="">
                        {billingAddressData.city
                          ? commonFunctions.capitalize(billingAddressData.city)
                          : " "}
                        {", "}
                        {billingAddressData.state.abbreviation + " "}
                        {billingAddressData.zipcode}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="form-error-shipping-address-container mt-4 mb-0">
                  <span className="form-error-shipping-address new-addrss-err-font-size">
                    Please add billing address to continue.
                  </span>
                </div>
              )}
              {/* {!paymentData.isAvailableForShipping && (
                        <div className="form-error-shipping-address-container mt-4 mb-0">
                            <span className="form-error-shipping-address new-addrss-err-font-size">
                                {this.state.addressError}
                            </span>
                        </div>
                    )} */}
              <div className="mt-4 ml-3 mr-3 mb-4">
                <button
                  className="btn btn-round modify-add-address-btn ptr-clss"
                  onClick={this.onBillingAddressSelectionPage}
                >
                  Modify or Add Address
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderPromoDetails = (paymentData) => {
    return (
      <div className="applied-promo-div">
        <div className="applied-promo-code-div ptr-clss">
          <div className="promo-label-div">
            <div className="promo-label">
              <img alt="Go to promo page" className="" src={promoTagIcon} />
              <span className="ml-3">
                Promo code - {this.state.promoCode} applied!
              </span>
            </div>
            <div className="applied-promo-code-label">
              <span>
                You saved{" "}
                {commonFunctions.formatter.format(paymentData.discountAmount)}
              </span>
            </div>
          </div>
          {/* <img
                    alt="Go to promo page"
                    className="promo-right-icon-div"
                    src={rightArrowBtn}
                /> */}
        </div>
      </div>
    );
  };

  renderPaymentDetails = (paymentData) => {
    return (
      <div className="shpng-adrs-div paym-details-div">
        <label className="shpng-adrs-label">Payment Details</label>
        <div className="mt-3">
          <div className="default-address-div">
            <div className="mt-4 ml-3 mr-3">
              <div className="total-brkup-div mt-3 mb-3">
                <div className="inner-address">
                  <span>Sub Total</span>
                </div>
                <div className="brkup-amnt-div">
                  <span className="brkup-amnt-color">
                    {commonFunctions.formatter.format(
                      paymentData.orderedAmount
                    )}
                  </span>
                </div>
              </div>
              {paymentData.discountAmount == 0 ? null : (
                <div className="total-brkup-div mt-3 mb-3">
                  <div className="inner-address">
                    <span>Discount</span>
                  </div>
                  <div className="brkup-amnt-div">
                    <span className="promo-code-amnt-color">
                      {commonFunctions.formatter.format(
                        -paymentData.discountAmount
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="total-brkup-div mt-3 mb-3">
                <div className="inner-address">
                  <span>Sales Tax</span>
                </div>
                <div className="brkup-amnt-div">
                  <span className="brkup-amnt-color">
                    {commonFunctions.formatter.format(
                      paymentData.salesTaxAmount
                    )}
                  </span>
                </div>
              </div>

              {paymentData.shippingCost == 0 ? null : (
                <div className="total-brkup-div mt-3 mb-3">
                  <div className="inner-address">
                    <span>Shipping Charges</span>
                  </div>
                  <div className="brkup-amnt-div">
                    <span className="brkup-amnt-color">
                      {commonFunctions.formatter.format(
                        paymentData.shippingCost
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="total-brkup-div brder-btm-brkup mt-3 mb-3">
                {""}
              </div>
              <div className="total-brkup-div mt-3 mb-3 ttl-amnt-color">
                <div className="">
                  <span>Total Amount</span>
                </div>
                <div className="brkup-amnt-div">
                  <span className="">
                    {commonFunctions.formatter.format(paymentData.totalAmount)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickProceedToPay = async (event) => {
    GoogleAnalyticsFunctions.onClickProceedToPay();
    // Get Stripe.js instance
    if (
      this.state.paymentDetails.isAvailableForShipping &&
      Object.entries(this.state.billingAddressDetails).length > 0
    ) {
      const stripe = await stripePromise;
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;
      let postObj = {
        addressLine1: this.state.paymentDetails.addressLine1,
        addressLine2: this.state.paymentDetails.addressLine2,
        city: this.state.paymentDetails.city,
        zipcode: this.state.paymentDetails.zipcode,
        userAddressId: this.state.paymentDetails.userAddressId,
        stateId: this.state.paymentDetails.stateId,
        stateName: this.state.paymentDetails.stateName,
        shippingCost: this.state.paymentDetails.shippingCost,
        orderedAmount: this.state.paymentDetails.orderedAmount,
        discountPercent: this.state.paymentDetails.discountPercent,
        discountAmount: this.state.paymentDetails.discountAmount,
        salesTaxAmount: this.state.paymentDetails.salesTaxAmount,
        salesTaxPercent: this.state.paymentDetails.salesTaxPercent,
        userBillingAddressId: this.state.billingAddressDetails.id,
        isSameBillingAndShippingAddress:
          this.state.isSameBillingAndShippingAddress,
        billingName: this.state.billingAddressDetails.name,
        billingAddressLine1: this.state.billingAddressDetails.addressLine1,
        billingAddressLine2: this.state.billingAddressDetails.addressLine2,
        billingCity: this.state.billingAddressDetails.city,
        billingStateName: this.state.billingAddressDetails.stateName,
        billingStateId: this.state.billingAddressDetails.stateId,
        billingZipCode: this.state.billingAddressDetails.zipcode,
        // "cartDetails": this.state.cartDetails
      };
      let checkoutSessionUrl =
        constants.SHOPPING_CART_API +
        "/api/v1/users/winery/" +
        wineryId +
        "/cart/" +
        this.state.paymentDetails.cartId +
        "/orders/place";
      // Call your backend to create the Checkout Session
      try {
        const response = await fetch(checkoutSessionUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: bearer,
          },
          body: JSON.stringify(postObj),
        });
        // console.log('Checkout REs', response);
        const session = await response.json();

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          // console.log(result.error);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          let apiErrorObj = {
            statusText: result.error.message,
            type: "",
            status: "",
            url: checkoutSessionUrl,
            operation: "POST",
            status: "",
          };
          commonFunctions.postCaughtException(
            JSON.stringify(apiErrorObj),
            consumerData,
            wineryId
          );
          toast.info(result.error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      } catch (e) {
        // console.log(e);
        toast.info("Something went wrong. Please try again later.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      // this.setState({
      //     addressError: 'Sorry, we don\'t ship to ' + " " + this.state.addressDetails.stateName + '.'
      // })
      toast.info("Please check shipping and billing address details.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  handleCheckBoxChange = (e) => {
    const checked = e.target.checked;
    this.setState({
      isSameBillingAndShippingAddress:
        !this.state.isSameBillingAndShippingAddress,
      billingAddressDetails: checked
        ? this.state.defaultShippingAddress
        : this.state.initialBillingAddressDetails,
    });
  };
}

export default ShoppingCartCheckout;
