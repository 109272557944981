import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { logPagesVisitedByUser } from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./wine-details.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import ChevronLeft from "../../../assets/images/PAIcons/chevron-left.svg";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import { post, get } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import { formatCurrency } from "../../lib/common/convertCurrency";
import ActionsRow from "../../components/TargetComponents/ActionsRow/ActionsRow";
import FlavorProfileCard from "../../components/TargetComponents/FlavorProfileCard/FlavorProfileCard";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";
import WineTypePill from "../../components/TargetComponents/WineTypePill/WineTypePill";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";
import TargetLogo from "../../../assets/images/PALogos/targetlogo.png";

export default function WineDetails() {
  const { wineId } = useParams();
  const history = useHistory();
  const food = history.location.state?.food;
  const from = history.location.state?.from;
  const returnPage = history.location.state?.returnPage;
  const [wineDetailsLoaded, setWineDetailsLoaded] = useState(false);

  // ---------- initialize GA, set wine url for SWR, call SWR for wine details, set wine details loaded  -------------

  const [wineUrl, setWineUrl] = useState(null);

  useEffect(() => {
    logPagesVisitedByUser();
    setWineUrl(`${constants.WINERY_API}/api/v1/public/wine/${wineId}`);
  }, []);

  const [varietalName, setVarietalName] = useState(undefined);

  const { data: wineDetails } = useSWR(wineUrl, (u) =>
    get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (wineDetails) {
      setVarietalName(wineDetails?.specifications?.varietal);
      setWineDetailsLoaded(true);
    }
  }, [wineDetails]);

  // ---------- onClick functions -----------------

  const clickBack = (e) => {
    e.preventDefault();
    if (!!returnPage) {
      history.push({
        pathname: returnPage,
        state: { from: "left", food: food },
      });
    }
  };

  // ---------- end onClick functions -------------

  if (!wineDetailsLoaded) return <></>;

  return (
    <Layout white>
      <AnimationWrapper animation="fromRight">
        <LayoutContents>
          <PAHeader left />

          <div className={`mc`}>
            {!!returnPage ? (
              <button
                className={styles.backToResultBtn}
                onClick={(e) => clickBack(e)}
              >
                <img src={ChevronLeft} alt="chevron left" />
                <p className={`p1-reg ${styles.backToResultBtnTxt}`}>Back</p>
              </button>
            ) : (
              <div className={styles.padding} />
            )}
          </div>

          <div className={`mc`}>
            <div className={`${styles.imgFrame} flex-row`}>
              <div className={`${styles.logoFrame}`}>
                <img
                  className={`styles.logoImg`}
                  src={TargetLogo}
                  alt="Target"
                />
              </div>
              <img
                className={styles.wineImg}
                src={
                  wineDetails?.wineImageSmall
                    ? wineDetails?.wineImageSmall
                    : wineBottlePlaceholder
                }
              />
            </div>
          </div>
          <div className={styles.mainContainerSection}>
            <div className={`mc`}>
              <div className={`${styles.info}`}>
                <h1 className={`h1-30`}>
                  {wineDetails?.specifications?.varietal}
                </h1>
                <div className={`${styles.infoLine2}`}>
                  <div>
                    <p className={`p1-reg`}>{wineDetails?.wineName}</p>
                  </div>
                  <WineTypePill type={wineDetails?.wineType} />
                </div>
                <div className={styles.priceBox}>
                  <div className={styles.price}>
                    <p className={`p3-bold`}>
                      {formatCurrency(wineDetails?.price[0]?.price)}
                    </p>
                    <p className={`p1-reg`}>
                      {wineDetails?.price[0]?.quantity}ml
                    </p>
                  </div>
                  <div className={styles.grayVert} />
                </div>
              </div>
            </div>
            <div className={`mc`}>
              <button
                className={styles.buyBtn}
                onClick={() => {
                  window.open(wineDetails.wineUrl, "_blank");
                }}
              >
                <p className={`pb-lg`}>Buy at Target</p>
                <svg
                  className={`no-shrink`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                >
                  <path
                    d="M10.6663 7.11161V10.7781C10.6663 11.1023 10.5376 11.4131 10.3084 11.6423C10.0792 11.8715 9.76829 12.0003 9.44415 12.0003H2.72218C2.39804 12.0003 2.08717 11.8715 1.85797 11.6423C1.62876 11.4131 1.5 11.1023 1.5 10.7781V4.05616C1.5 3.73202 1.62876 3.42115 1.85797 3.19195C2.08717 2.96275 2.39804 2.83398 2.72218 2.83398H6.38871"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.83325 1H12.4998V4.66653"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.7771 7.72198L12.4991 1"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <ActionsRow itemType="wine" wineId={wineId} />
            <div className={`mc`}>
              <FlavorProfileCard varietal={varietalName} />
            </div>
            <div className={`mc`}>
              <div className={styles.detailsBox}>
                <h3 className={`h3-16`} style={{ margin: 0 }}>
                  Details
                </h3>
                <p className={`p1-reg`}>{wineDetails?.wineDescription}</p>
              </div>
            </div>
          </div>
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}
