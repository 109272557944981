import React, { Component } from "react";

import "./SignUp.scss";
import logo from "../../../assets/images/splash.png";
import facebookLogo from "../../../assets/images/facebook-logo1.png";
import { Redirect } from "react-router-dom";
import { FormErrors } from "../../components/FormErrors/FormErrors";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserSignUpAction from "../../redux/actions/UserSignUpAction";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      createdBy: "",
      updatedBY: "",
      wineryId: "",

      signInPageLoaded: false,
      tastePreferencePageLoaded: false,
      signUpPageStatus: false,

      formErrors: { email: "", password: "", confirmPassword: "" },
      emailValid: false,
      passwordValid: false,
      confirmPasswordValid: false,
      formValid: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
      this
    );
  }
  componentDidMount() {}

  handleSubmit = (event) => {
    event.preventDefault();
  };

  //   onChange(field, value) {
  //     // parent class change handler is always called with field name and value
  //     this.setState({[field]: value});
  // }

  handleEmailChange(event) {
    let val = event.target.value;
    this.setState({ email: event.target.value }, () => {
      this.validateField("email", val);
    });
  }

  handlePasswordChange(event) {
    let val = event.target.value;
    this.setState({ password: event.target.value }, () => {
      this.validateField("password", val);
    });
  }
  handleConfirmPasswordChange(event) {
    let val = event.target.value;
    this.setState({ confirmPassword: event.target.value }, () => {
      this.validateField("confirmPassword", val);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //nextProps - new val from reducer
    // prevState - state value

    if (nextProps.signupSuccess != undefined) {
      // coming from mapStateToProps
      if (nextProps.signupSuccess) {
        // valid user

        return {
          tastePreferencePageLoaded: true,
        };
      } else {
        return null;
      }
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldName = "Email";
        fieldValidationErrors.email = emailValid ? "" : " invalid.";
        break;
      case "password":
        passwordValid = value.length >= 4;
        // passwordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
        fieldValidationErrors.password = passwordValid
          ? ""
          : " invalid. Enter atleast 4 characters";
        break;
      case "confirmPassword":
        confirmPasswordValid =
          this.state.password == this.state.confirmPassword;
        // confirmPasswordValid = value.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
        // fieldValidationErrors.confirmPassword = confirmPasswordValid ? '': ' and password do not match';
        fieldValidationErrors.password = passwordValid
          ? ""
          : " and confirm password do not match";

        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        confirmPasswordValid: confirmPasswordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.confirmPasswordValid,
    });
  }

  gotoSignInPage = () => {
    this.setState({ signInPageLoaded: true });
  };

  signUpSubmit = () => {
    // let email , password , firstName , lastName, phoneNumber ,
    //            address1 , address2 , city, state, country ,zipcode;

    const data = {
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zipcode: this.state.zipcode,
      createdBy: this.state.createdBy,
      updatedBY: this.state.updatedBY,
      wineryId: this.state.wineryId,
    };

    // this.setState({
    //     tastePreferencePageLoaded: true

    // })

    this.props.UserSignUpAction.UserSignUp(data);
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.state.signInPageLoaded) {
      // return <Redirect push to="/sign-in" />;
      this.props.history.push("/sign-in");
    } else if (this.state.tastePreferencePageLoaded) {
      // return <Redirect push to="/taste-preferences" />;
      this.props.history.push("/taste-preferences");
    } else if (this.state.signUpPageStatus) {
      // return <Redirect push to="/profile" />;
      this.props.history.push("/profile");
    }
  }

  render() {
    return (
      <div className="sign-up-main-container">
        <div className="header">
          <div className="sign-in-text-container">
            <div className="sign-in-text">
              <span onClick={this.gotoSignInPage}>Sign In</span>
            </div>
          </div>
          <div className="logo-container">
            <img alt="PairAnything" className="logo" src={logo} />
          </div>
          <div className="hello-title">Hello</div>
        </div>

        <div className="form-errors-display">
          <FormErrors formErrors={this.state.formErrors} />
        </div>

        <div className="sign-up-form-parent">
          <div className="sign-up-form-container">
            <form className="sign-up-form" onSubmit={this.handleSubmit}>
              <input
                // className="form-row"
                className={`form-row ${
                  this.state.emailValid ? "" : " form-row-error"
                }`}
                type="text"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
              <input
                //  className="form-row"
                className={`form-row ${
                  this.state.passwordValid ? "" : " form-row-error"
                }`}
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
              />
              <input
                //  className="form-row"
                className={`form-row ${
                  this.state.confirmPasswordValid ? "" : " form-row-error"
                }`}
                type="password"
                name="confirmpassword"
                placeholder="Confirm Password"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPasswordChange}
              />

              <button
                type="button"
                onClick={this.signUpSubmit}
                disabled={!this.state.formValid}
                className="btn btn-round sign-up-btn"
              >
                Sign Up
              </button>
            </form>
          </div>
        </div>

        {/* <div className = "footer">
                    <div className = "sign-up-using-text" >or Sign up using</div>
                    <div className = "logo-container">
                            <img className="facebook-logo" src={facebookLogo} />
                    </div>
                </div> */}
      </div>
    );
  }
}

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    signupSuccess: state.SignUpReducer.signupSuccess,
    signupFail: state.SignUpReducer.signupFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    UserSignUpAction: bindActionCreators(UserSignUpAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

// export default SignUp;
