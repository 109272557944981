import React, { Component } from "react";
import logo from "../../../assets/images/splash.png";
import signUpSuccesWineImage from "../../../assets/images/SignUp/wines-signup-image.svg";
import { convertToCamelCase } from "../../constants/commonFunctions";

class StripePaymentCancel extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {};
  }

  componentDidMount = () => {};

  componentDidUpdate(prevProps, prevState) {}

  goToPairingPage = () => {
    let wineryName = JSON.parse(localStorage.getItem("winery_id"));
    // let url = "/search-wine" + "/" + wineryId;
    let url = "/" + convertToCamelCase(wineryName) + "/pairings";
    if (wineryName) {
      this.props.history.push(url);
    } else {
      this.props.history.push("/sign-in");
    }
  };

  render() {
    // const { onClickToSignIn, signUpSuccesWineImage } = this.props;
    return (
      <div className="set-password-success-container-background font-family">
        <div className="set-password-success-main-container">
          <div className="header">
            <div className="back-btn-container">
              {/* <img className="" src={backBtn} onClick = { this.gotoSignInPage }/> */}
            </div>
            <div className="logo-container">
              <img alt="PairAnything" className="logo-wwidth" src={logo} />
            </div>
            {/* <div className="set-password-title-success">Thank You!</div> */}
          </div>

          <div className="thanks-fonts-style">
            <div>
              <span>Oops!</span>
            </div>
            <div>
              <span>
                Your order was cancelled. Please try again after sometime.
              </span>
            </div>
          </div>

          <div className="forgot-password-form-parent">
            <div className="set-password-form-container">
              <div className="">
                <div>
                  <img
                    alt="PairAnythingWineLogo"
                    className=""
                    src={signUpSuccesWineImage}
                  />
                </div>
                <div className="set-password-success-btn-container">
                  <div className="set-password-success-btn-sub-container">
                    {" "}
                    <button
                      type="button"
                      onClick={() => this.goToPairingPage()}
                      //   onClick={this.gotoSignInPage}
                      //  disabled={!this.state.formValid}
                      className="btn btn-round set-password-success-btn forgot-password-btn"
                    >
                      Go to home page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StripePaymentCancel;
