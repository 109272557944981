import React, { useEffect, useState, useRef } from "react";
import { useSessionUrl } from "../../../lib/hooks/useSession";
import * as constants from "../../../constants/constants";
import useSWR, { preload } from "swr";
import { get, post, postPilotEvent } from "../../../lib/fetch";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./dd-landing.module.scss";
import DDWineCard from "./dd-wine-card";
import PALogo from "../../../../assets/images/PALogos/PairAnything_logo_full.svg";
import IdleTimer from "../../../components/IdleTimer/IdleTimer";

export default function DDLanding() {
  const dd_wineIds = [1944, 1945, 1943, 1947, 1946];
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  preload(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  // ::::::::: Get Winery Details from url params :::::::::::

  const [sortedWines, setSortedWines] = useState(undefined);

  // const wineryName = "CortiBrothers";

  // const { data: winery } = useSWR(
  //   `${constants.WINERY_API}/api/v1/public/winery/get/wineryByUniqueUrl/${wineryName}`,
  //   (u) => get(u).then((res) => res.json())
  // );

  // wines --------------------
  const wineryId = 415;

  const { data: wines } = useSWR(
    `${constants.WINERY_API}/api/v1/public/wine/winery/consumer?wineryId=${wineryId}`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (wines) {
      setSortedWines(
        mapOrder(
          wines.rows.filter((wine) => {
            return dd_wineIds.includes(wine.id);
          }),
          dd_wineIds,
          "id"
        )
      );
    }
  }, [wines]);

  //---------------------------

  const createNewPilotSession = async (wine, page) => {
    let url = constants.IDENTITY_API + "/api/v1/public/user/post/pilotSession";
    let pilotSessionBody = {
      userAgent: window.navigator.userAgent,
    };

    try {
      const response = await post(
        url,
        {
          "Content-Type": "application/json",
        },
        pilotSessionBody
      );
      const result = await response.json();

      localStorage.setItem("pilot_session", JSON.stringify(result?.id));
      postPilotEvent({
        eventName: "session_startClick",
        pageUrl: page,
      });
      postPilotEvent({
        eventName: "click_pilotCard",
        eventDetails: `${wine?.id}`,
        pageUrl: page,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLocalPilotSession = () => {
    localStorage.setItem("pilot_session", JSON.stringify(null));
  };

  // oooooooooo onClick Functions oooooooooooooooooooooooooooooo

  function mapOrder(array, order, key) {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }

  return (
    <div className={`${styles.main}`}>
      <IdleTimer key={2} time={30} cb={handleLocalPilotSession} />
      <h1 className={`${styles.titleTxt}`}>Tap on a wine to get a pairing!</h1>
      <div className={`${styles.wineRow}`}>
        {sortedWines &&
          sortedWines.map((wine, i) => (
            <DDWineCard key={i} wine={wine} cb={createNewPilotSession} />
          ))}
      </div>
      <img className={`${styles.logo}`} src={PALogo} alt="PA logo" />
    </div>
  );
}
