import React, { useState, useEffect } from "react";
import styles from "./feedback.module.scss";
import AnimationWrapper from "../AnimationWrapper/AnimationWrapper";
import * as constants from "../../../constants/constants";
import { put, post, postPilotEvent, postEvent } from "../../../lib/fetch";
import { sessionUrl } from "../../../lib/common/sessionUrl";

export default function Feedback() {
  const [clicked, setClicked] = useState(undefined);
  const [activateTransition, setActivateTransition] = useState(false);

  const submitFeedback = async (feedback) => {
    try {
      const response = await postEvent({
        eventName: "clicked_feedback",
        eventDetails: feedback,
        pageUrl: window.location.href,
      });
      const result = await response.json();
      console.log(`result:`);
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }

    if (feedback == "yes") {
      setClicked(1);
    } else {
      setClicked(2);
    }

    setTimeout(() => {
      setActivateTransition(true);
    }, 500);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.textBox}`}>
        <p className={`h3-16`} style={{ textAlign: "center" }}>
          Was this flavor profile helpful?
        </p>
        <p className={`p1-reg`} style={{ textAlign: "center" }}>
          Your feedback helps us improve our wine flavor profiles.
        </p>
      </div>
      <div className={`${styles.btnBox}`}>
        <button
          className={`${styles.btn} ${clicked == 1 ? styles.btnClicked : null}`}
          onClick={() => {
            submitFeedback("yes");
          }}
        >
          Yes
        </button>
        <button
          className={`${styles.btn} ${clicked == 2 ? styles.btnClicked : null}`}
          onClick={() => {
            submitFeedback("no");
          }}
        >
          No
        </button>
      </div>
      <AnimationWrapper
        animation={"expand"}
        active={activateTransition}
        gapOnInactive="top"
        gapDelete={"-24px"}
      >
        <div className={`${styles.thanksBox}`}>
          <p className={`${styles.thanks}`}>Thanks for letting us know!</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M9.5 18.6948C14.4706 18.6948 18.5 14.6654 18.5 9.69482C18.5 4.72426 14.4706 0.694824 9.5 0.694824C4.52944 0.694824 0.5 4.72426 0.5 9.69482C0.5 14.6654 4.52944 18.6948 9.5 18.6948Z"
              fill="#1E7ED7"
            />
            <path
              d="M6.5 10.2998L8.83333 12.1664L13.5 7.49976"
              stroke="white"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </AnimationWrapper>
    </div>
  );
}
