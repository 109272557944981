import React, { useEffect, useState, useRef } from "react";

import * as constants from "../../../constants/constants";
import useSWR from "swr";
import { get, post, postPilotEvent } from "../../../lib/fetch";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./landing.module.scss";
//import TDFoodCard from "./td-food-card";
import PALogo from "../../../../assets/images/PALogos/PairAnything_logo_full.svg";
import IdleTimer from "../../../components/IdleTimer/IdleTimer";
import pairingData from "../pairingData";
import { useLocation, useHistory } from "react-router-dom";

export default function TDLanding() {
  const td_foodIds = [1, 2, 3, 4, 5];
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  let { search } = useLocation();
  let searchParams = new URLSearchParams(search);
  let fromQR = searchParams.get("qr");
  const history = useHistory();

  useEffect(() => {
    let pilot_session = JSON.parse(localStorage.getItem("pilot_session"));
    if (!pilot_session || pilot_session == null || pilot_session < 146) {
      let eventBody;
      let pageBody;
      if (fromQR == "2") {
        eventBody = {
          eventName: "session_startQR",
          eventDetails: "techstarsDemoDay",
          pageUrl: window.location.href,
        };
        pageBody = {
          eventName: "page_fromQR",
          eventDetails: "techstarsDemoDay",
          pageUrl: window.location.href,
        };
      } else {
        eventBody = {
          eventName: "session_start",
          pageUrl: window.location.href,
        };
        pageBody = {
          eventName: "page_view",
          pageUrl: window.location.href,
        };
      }
      createNewPilotSession(eventBody, pageBody);
    } else {
      if (fromQR == "2") {
        postPageView({
          eventName: "page_fromQR",
          eventDetails: "techstarsDemoDay",
          pageUrl: window.location.href,
        });
      } else {
        postPageView({
          eventName: "page_view",
          pageUrl: window.location.href,
        });
      }
    }
  }, [fromQR]);

  const createNewPilotSession = async (eventBody, pageBody) => {
    let url = constants.IDENTITY_API + "/api/v1/public/user/post/pilotSession";
    let pilotSessionBody = {
      userAgent: window.navigator.userAgent,
    };

    try {
      const response = await post(
        url,
        {
          "Content-Type": "application/json",
        },
        pilotSessionBody
      );
      const result = await response.json();

      console.log(`result:`);
      console.log(result);

      localStorage.setItem("pilot_session", JSON.stringify(result?.id));
      console.log(JSON.parse(localStorage.getItem("pilot_session")));
      postPilotEvent(eventBody);
      postPilotEvent(pageBody);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleLocalPilotSession = () => {
  //   localStorage.setItem("pilot_session", JSON.stringify(null));
  // };

  // oooooooooo onClick Functions oooooooooooooooooooooooooooooo

  const postCardClick = async (foodId) => {
    try {
      postPilotEvent({
        eventName: "click_demoCard",
        eventDetails: `${foodId}`,
        pageUrl: window.location.href,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const postPageView = async (body) => {
    try {
      postPilotEvent(body);
    } catch (e) {
      console.log(e);
    }
  };

  const postClickWinery = async (body) => {
    try {
      postPilotEvent(body);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={`${styles.main}`}>
      <div className={styles.stickyHeader}>
        <a
          className={styles.stickyHeaderTxt}
          href={`https://www.pairanything.com/techstars`}
          onClick={() => {
            postClickWinery({
              eventName: "click_knowAWinery",
              pageUrl: window.location.href,
            })

          }}
        >
          Know a winery? Let us know!
        </a>
      </div>
      <div className={styles.box}>
        <h1 className={styles.titleTxt}>What pairs with...</h1>
        <div className={styles.wineGrid}>
          {td_foodIds.map((foodId, i) => (
            <div
              className={styles.card}
              onClick={() => {
                let pilot_session = JSON.parse(
                  localStorage.getItem("pilot_session")
                );
                if (!pilot_session || pilot_session == null) {
                } else {
                  postCardClick(foodId);
                  history.push({
                    pathname: `/techstars/pairing/${foodId}`,
                  });
                }
              }}
            >
              <a
                href={`/techstars/pairing/${foodId}`}
                className={styles.cardTitle}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  postCardClick(foodId);
                  history.push({
                    pathname: `/techstars/pairing/${foodId}`,
                  });
                }}
              >
                {pairingData[foodId]?.foodName}
              </a>
              <div className={styles.cardImg}>
                <img
                  src={pairingData[foodId]?.foodImg}
                  alt={pairingData[foodId]?.foodName}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.logo}>
          <img src={PALogo} alt="Pair Anything" />
        </div>
      </div>
    </div>
  );
}
