import React, { useEffect, useState, useRef } from "react";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./dd-landing.module.scss";
import { useHistory } from "react-router-dom";
import * as constants from "../../../constants/constants";
import { post, postPilotEvent } from "../../../lib/fetch";

export default function DDWineCard({ wine = undefined, cb }) {
  const history = useHistory();
  const handleCardClick = async () => {
    // take us to the pairing result for wine page

    history.push({
      pathname: `/dd-wine/${wine.id}`,
    });
  };

  // onClicks

  const pendo_wineClick = async () => {
    let eventBody = {
      type: "track",
      event: "click_pilotCard",
      visitorId: "dd_pilot_kiosk_1",
      timestamp: Date.now(),
      properties: {
        wineId: `${wine?.id}`,
      },
      context: {},
    };

    let url = `https://app.pendo.io/data/track`;
    try {
      await post(
        url,
        {
          "X-Pendo-Integration-Key": constants.PENDO_TRACK_KEY,
          "Content-Type": "application/json",
        },
        eventBody
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!wine) return <></>;

  return (
    <div
      className={`${styles.card}`}
      onClick={() => {
        let pilot_session = JSON.parse(localStorage.getItem("pilot_session"));
        if (!pilot_session || pilot_session == null) {
          cb(wine, window.location.href);
        } else {
          postPilotEvent({
            eventName: "click_pilotCard",
            eventDetails: `${wine?.id}`,
            pageUrl: window.location.href,
          });
        }
        pendo_wineClick();
        handleCardClick();
      }}
    >
      {/* <WineTypePill type={wine.wineType} /> */}
      <div
        className={`${styles.wineImgBg}`}
        style={{
          backgroundColor:
            wine.wineType == "Red"
              ? "#EEE1E1"
              : wine.wineType == "White"
              ? "#F4ECE3"
              : "#EFEFEF",
        }}
      >
        <img
          className={`${styles.wineImg}`}
          src={wine.wineImageSmall}
          alt={wine.wineName}
        />
      </div>
      <p className={`${styles.text}`}>{wine.wineName}</p>
      <p className={`${styles.wineTypeText}`}>{wine.wineType}</p>
    </div>
  );
}
