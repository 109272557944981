import React, { Component } from "react";
import { osName } from "react-device-detect";
import "./WineryDetails.scss";

import backBtn from "../../../assets/images/back-btn.svg";
import wineryLogo from "../../../assets/images/winery-logo.png";
import {
   isEmbedded
} from "../../constants/commonFunctions"
import facebookLogo from "../../../assets/images/WineryDetails/facebook.svg";
import instagramLogo from "../../../assets/images/WineryDetails/instagram.svg";
import twitterLogo from "../../../assets/images/WineryDetails/twitter.svg";
import defaultWineryCoverPic from "../../../assets/images/default_cover_pic.png";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import wineryLocationBtn from "../../../assets/images/winery-location.png";
import { isMobile } from "react-device-detect";
import { bindActionCreators } from "redux";
import { getAllWineryAddress } from "./action";
import { connect } from "react-redux";
import * as WineryAction from "../../redux/actions/WineryAction";
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import CouponModal from "../CouponModal/CouponModal";
import * as constants from "../../constants/constants";
import HeaderBar from "../CommonComponents/HeaderBar";
import TitleCard from "../WineryDetailsCards/TitleCard";
import LocationCard from "../WineryDetailsCards/LocationCard";
import AboutUsCard from "../WineryDetailsCards/AboutUsCard";
import axiosCall from "../../constants";
import PromotionModal from "../PromotionModal/PromotionModal";
import moment from "moment";
import Slider from "react-slick";
import HeaderWithBurgerIcon from "../CommonComponents/HeaderWithBurgerIcon";
import { camelToName } from "../../constants/commonFunctions";
import CheckInPopup from "../../components/CheckInPopUp/CheckInPopUp";
import * as commonFunctions from "../../constants/commonFunctions";

import YouTubeImg from './images/youtube.png'
import FacebookImg from './images/facebook.png'
import TwitterImg from './images/twitter.png'
import YelpImg from './images/yelp.png'
import InstagramImg from './images/instagram.png'
import MapImg from './images/map.png'
import MarkerImg from './images/marker.png'
import CheckInImg from './images/checkin.png'
import ChatImg from './images/chat.png'
import PairingImg from './images/pairing.png'
import PhoneImg from './images/phone.png'
import EmailImg from './images/mail.png'
import DuihaoImg from './images/duihao.png'
import WebsiteImg from './images/website.png'
import BackgroundImg from './images/background.png'
import LogoImg from './images/logo.png'
import ClockImg from './images/clock.png'

const SHW_WINERY_NAME = "Scott Harvey Wines";
const LIMIT = 5;

// return the length of all strings in an array
function displayTextWithLimit(str, maxLen) {
  return str.length > maxLen ? str.slice(0, maxLen) + `...` : str
}

class WineryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wineryData: {
        wineryName: "",
        wineryAbout: "",
        wineryPic: "",
        wineryWebsiteUrl: "",
        wineryPhoneNumber: "",
        wineryFacebookUrl: "",
        wineryInstagramUrl: "",
        wineryTwitterUrl: "",
        wineryMessage: "",
        wineryMail: "",
        wineryPromotions: [],
      },
      finalWineryAddress: [],
      backBtnStatus: false,

      // fields for data to be fetched from scottharveywines.com/
      wineryID: JSON.parse(localStorage.getItem("winery_id")),
      loading: true,
      locations: null,
      heading: null,
      events: [],
      aboutUs: null,
      socialMediaUrls: null,
      meetAssistant: null,
      virtualExperience: [],
      showCheckInPopUp: false,
      checkinMessage: "",
      checkinStatus: false,
    };
  }

  gotoProfile = () => {
    this.setState({
      backBtnStatus: true,
    });
  };

  socialMediaIconOnClick = (url) => () => {
    if (url) {
      if (!url.includes("http://") && !url.includes("https://")) {
        url = "https://" + url;
      }
    }
    var newWindow = window.open(url, "_blank");
  };

  mailClicked = () => {
    var newWindow = window.open(
        "mailto:" + this.state.wineryData.wineryMail,
        "_blank"
    );
    newWindow.focus();
  };

  phoneClicked = () => {
    var newWindow = window.open(
        "tel:" + this.state.wineryData.wineryPhoneNumber,
        "_blank"
    );
    newWindow.focus();
  };

  async componentDidMount() {

    // const responseVisit = await fetch("https://pairanything-api.herokuapp.com/shw/visit");
    // const responseFromVisit = await responseVisit.json();

    // const responseAboutUs = await fetch("https://pairanything-api.herokuapp.com/shw/About-Us");
    // const responseFromAboutUs = await responseAboutUs.json();

    // const responseMeet = await fetch("https://pairanything-api.herokuapp.com/shw/About-Us/Meet-our-Assistant-Wine-Maker");
    // const responseFromMeet = await responseMeet.json();


    this.setState({
      loading: false,
      /*
      heading: responseFromVisit['heading'],
      locations: responseFromVisit['locations'],
      events: responseFromVisit['events'],
      socialMediaUrls: responseFromVisit.socialMediaUrls,
      aboutUs: responseFromAboutUs,
      meetAssistant: responseFromMeet,
      */
      // virtualExperience: responseVirtualFromExperience,
    });
    // this.props.WineryAction.GetWineryDetails();
    // this.props.WineryAction.GetWineryDetailsWithPublicAPI();
    // let wineryName = localStorage.getItem("winery_name");
    // if (
    //   wineryName &&
    //   wineryName == camelToName(this.props.match.params.wineryName)
    // ) {
    //   this.getWineryAddress();

    //   GoogleAnalyticsFunctions.logPagesVisitedByUser();
    //   this.getWineryPromotions();
    // } else {
    this.fetchWineryDetailsFromDB();
    GoogleAnalyticsFunctions.logPagesVisitedByUser();

    // }

  };

  fetchWineryDetailsFromDB = () => {
    let url =
        constants.WINERY_API +
        "/api/v1/public/winery/get/wineryByUniqueUrl/" +
        this.props.match.params.wineryName;
    axiosCall("GET", url)
        .then((resp) => {
          console.log(resp);
          if (resp.statusText == "OK" && !resp.data.msg) {
            // window.location.reload();
            localStorage.setItem("winery_id", JSON.stringify(resp.data.id));
            localStorage.setItem("winery_name", resp.data.displayName);
            this.props.dispatch(WineryAction.setWineryList([{ ...resp.data }]));
            this.props.dispatch(
                WineryAction.setCurrentWineryDetails({ ...resp.data })
            );
            if (
                localStorage.getItem("winery_list") == undefined ||
                localStorage.getItem("winery_list") == null
            ) {
              let winery = [];
              let element = resp.data;
              let obj = {
                wineryId: element.wineryId,
                wineryUserStatus: element.statusId,
                displayName: element.displayName,
                shoppingCartOptions: element.shoppingCartOptions,
                website: element.website,
                name: element.name,
                ...element,
              };
              winery.push(obj);
              localStorage.setItem("winery_list", JSON.stringify(winery));
            }
            this.setState({
              wineryData: {
                ...resp.data,
                wineryName: resp.data.displayName,
                wineryPic: resp.data.logo,
                wineryCoverPic: resp.data.coverPicture,
                wineryAbout: resp.data.description,
                wineryWebsiteUrl: resp.data.website,
                wineryPhoneNumber: resp.data.phoneNumber,
                wineryFacebookUrl: resp.data.facebookUrl,
                wineryInstagramUrl: resp.data.instagramUrl,
                wineryTwitterUrl: resp.data.twitterUrl,
                wineryMessage: resp.data.message,
                wineryMail: resp.data.email,
              },
            });
            this.getWineryAddress();
            this.getWineryPromotions();
          } else {
            let apiErrorObj = {
              statusText: resp.statusText,
              type: resp.type,
              status: resp.status,
              url,
              operation: "GET",
              status: resp.status,
            };

            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(resp.statusText);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    console.log(camelToName(this.props.match.params.wineryName).trim());
  };
  getWineryAddress = async () => {
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    const data = await getAllWineryAddress(wineryId);

    this.setState({
      finalWineryAddress: data,
    });
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   //nextProps - new val from reducer
  //   // prevState - state value
  //   let finalWineryDescription;

  //   if (nextProps.getWineryDetailsSuccess !== undefined) {
  //     // coming from mapStateToProps

  //     finalWineryDescription = nextProps.getWineryDetailsSuccess.description;

  //     // empty and null validations for abouts us section
  //     if (finalWineryDescription == null || finalWineryDescription === " ") {
  //       finalWineryDescription = "Coming Soon";
  //     }

  // return {
  //   wineryData: {
  //     // object that we are going to update
  //     ...prevState.wineryData, // retains all other state key-value data
  //     wineryName: nextProps.getWineryDetailsSuccess.displayName, // update value of specific key
  //     wineryPic: nextProps.getWineryDetailsSuccess.logo,
  //     wineryCoverPic: nextProps.getWineryDetailsSuccess.coverPicture,
  //     // wineryAddress: '',
  //     wineryAbout: finalWineryDescription,
  //     wineryWebsiteUrl: nextProps.getWineryDetailsSuccess.website,
  //     wineryPhoneNumber: nextProps.getWineryDetailsSuccess.phoneNumber,
  //     wineryFacebookUrl: nextProps.getWineryDetailsSuccess.facebookUrl,
  //     wineryInstagramUrl: nextProps.getWineryDetailsSuccess.instagramUrl,
  //     wineryTwitterUrl: nextProps.getWineryDetailsSuccess.twitterUrl,
  //     wineryMessage: nextProps.getWineryDetailsSuccess.message,
  //     wineryMail: nextProps.getWineryDetailsSuccess.email,
  //   },
  //   };
  // }
  // }

  wineryLocationBtnClicked = (item) => {
    const fullAddress =
      item.addressLine1 +
      "" +
      item.addressLine2 +
      "" +
      item.city +
      "," +
      ((item.stateName && item.stateName !== "UNKNOWN") ? "" : item.stateName) +
      "," +
      item.zipcode;

    let modifiedAddr = fullAddress.replace(/&+/g, "");

    if (modifiedAddr !== "") {
      if (osName === "Android") {
        var newWindow = window.open(
            "https://maps.google.com/?q=" + modifiedAddr,
            "_blank"
        );
      } else if (osName === "iOS") {
        var newWindow = window.open(
            "http://maps.apple.com/?q=" + modifiedAddr,
            "_blank"
        );
      } else {
        var newWindow = window.open(
            "https://maps.google.com/?q=" + modifiedAddr,
            "_blank"
        );
      }
      // newWindow.focus();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.backBtnStatus) {
      // return <Redirect push to="/profile" />;
      this.props.history.goBack();
    }
  }
  getWineryPromotions = () => {
    let url =
        constants.WINERY_API +
        "/api/v1/public/promotion/get/" +
        localStorage.getItem("winery_id") +
        "/getPromotionBasedOnDate?dateSearch=" +
        moment().utc().format("YYYY-MM-DD");
    axiosCall("GET", url)
        .then((resp) => {
          if (resp.statusText == "OK" && resp.data && resp.data.length > 0) {
            this.setState({
              wineryPromotions: resp.data,
              showPromotion: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
  };
  handlePromotionClose = () => {
    this.setState({ showPromotion: false });
  };

  socialMediaIconOnClick = (url) => {
    if (url) {
      if (!url.includes("http://") && !url.includes("https://")) {
        url = "https://" + url;
      }
    }

    var newWindow = window.open(url, "_blank");
  };
  mailClicked = (winery) => {
    var newWindow = window.open("mailto:" + winery.wineryMail, "_blank");
    newWindow.focus();
  };
  phoneClicked = (winery) => {
    var newWindow = window.open("tel:" + winery.wineryPhone, "_blank");
    newWindow.focus();
  };

  getDistance(lat1, lon1, lat2, lon2) {
    // lat1, lon1: winery
    // lat2, lon2: user
    const R = 3958.8; // earth radius in miles

    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      // if (unit=="K") { dist = dist * 1.609344 }
      // if (unit=="N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  notifyWinery = (checkinDetails) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let notifyWineryApiUrl =
        constants.IDENTITY_API + `/api/v1/winery/${wineryId}/checkin`;

    fetch(notifyWineryApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(checkinDetails),
    })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: notifyWineryApiUrl,
              operation: "POST",
            };
            commonFunctions.postCaughtException(
                JSON.stringify(apiErrorObj),
                consumerData,
                wineryId
            );
            throw Error(res.statusText);
          }
        })
        .then((json) => { })
        .catch((error) => console.error(error));
  };
   isExternalReferrer = () => {
    const isExternalReferrer = document.referrer && document.referrer.indexOf(window.location.hostname) < 0;
    console.log('check external referrer', isExternalReferrer);
    return isExternalReferrer;
  }
  locationSuccess = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let wineryLat = -1;
    let wineryLng = -1;
    let wineryAddressList = this.state.finalWineryAddress;
    let wineryLocation;
    let msg, status;

    if (wineryAddressList.length > 0) {
      for (const e of wineryAddressList) {
        if (this.getDistance(e.latitude, e.longitude, lat, lon) < LIMIT) {
          msg = "Thank you for checking in";
          status = true;
          wineryLat = e.latitude;
          wineryLng = e.longitude;
          wineryLocation = e.addressLine1;
        }
      }
    }

    if (wineryLng === -1) {
      msg = `Sorry, check-in is only available within ${LIMIT} mile radius`;
      status = false;
    } else {
      const checkinDetails = {
        eventId: -1,
        userLat: lat,
        userLon: lon,
        wineryLat: wineryLat,
        wineryLon: wineryLng,
        wineryLocation: wineryLocation,
      };
      // API for back-end implementation
      this.notifyWinery(checkinDetails);
    }

    this.setState({
      showCheckInPopUp: true,
      checkinMessage: msg,
      checkinStatus: status,
    });
  };

  locationError = (position) => {
    let msg = "Sorry, your location is not available!";
    this.setState({
      showCheckInPopUp: true,
      checkinMessage: msg,
      checkinStatus: false,
    });
  };

  handleCancel = () => {
    this.setState({
      showCheckInPopUp: false,
    });
  };

  checkIn = () => {
    navigator.geolocation.getCurrentPosition(
        this.locationSuccess,
        this.locationError,
        { enableHighAccuracy: true }
    );
    setTimeout(() => {
      this.handleCancel();
    }, 4000);
  };

  render() {

    // wait for the data to be fetched from www.scottharveywines.com/
    if (this.state.loading) {
      return <div></div>;
    }

    const { finalWineryAddress } = this.state;
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    let { wineryData } = this.state;
    let winery = this.state.wineryData;
    if (imagePreviewUrl) {
      $imagePreview = (
          <img
              alt=""
              className="card-img-top winery-logo"
              src={imagePreviewUrl}
          />
      );
    } else if (this.state.wineryData.wineryPic == undefined) {
      $imagePreview = (
          <img alt="" className="card-img-top winery-logo" src={wineryLogo} />
      ); // we set it to a default pic
    } else if (this.state.wineryData.wineryPic != "") {
      // alert(1);
      $imagePreview = (
          <img
              alt=""
              className="card-img-top winery-logo"
              src={this.state.wineryData.wineryPic}
          />
      ); // if an image is uploaded already
    } else {
      $imagePreview = (
          <img alt="" className="card-img-top winery-logo" src={wineryLogo} />
      ); // we set it to a default pic
    }
    const settings = {
      className: "slick-slider-overwrite-promo ",
      // dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const aboutUsJSX = <AboutUsCard
        winery={{
          wineryAbout: SHW_WINERY_NAME == JSON.parse(localStorage.getItem("winery_id")),
          wineryEmail: this.state.wineryData.wineryMail,
          wineryMessage: this.state.wineryData.wineryMessage,
          wineryPhoneNumber: this.state.wineryData.wineryPhoneNumber,
          wineryFacebook: wineryData.wineryFacebookUrl,
          wineryInstagram: wineryData.wineryInstagramUrl,
          winerytwitter: wineryData.wineryTwitterUrl,
        }}
        wineryStreetText="Coming Soon"
        finalWineryAddress={finalWineryAddress}
        wineryLocationBtnClicked={() =>
            finalWineryAddress && finalWineryAddress.length > 0
                ? this.wineryLocationBtnClicked.bind(this)
                : {}
        }
    />;
  

    const headerSHWJSX = <div class="header">
      <img
          alt=""
          className="headerImg1"
          src={BackgroundImg}
      />

      <img
          alt=""
          className="headerImg2"
          src={LogoImg}
      />
    </div>

    const addressJSX = finalWineryAddress.length > 0 ? (
      <div className="card card-post card-round winery-details-card shadow p-3 mb-5 bg-white rounded">
        <div className="winery-message-title">{"Location"}</div>
        {finalWineryAddress &&
          finalWineryAddress.map((item, index) => {
            console.log(item);
            let wineryStreetAddress =
              item.addressLine1 && item.addressLine1 != null
                ? item.addressLine1.trim()
                : "";
            wineryStreetAddress +=
              item.addressLine2 && item.addressLine2 != null
                ? " " + item.addressLine2.trim()
                : "";
            wineryStreetAddress +=
              item.city && item.city != null
                ? "\n" + item.city.trim()
                : "";
            wineryStreetAddress +=
              item.state && item.state.abbreviation && item.state.abbreviation !== "UNKNOWN"
                ? ", " + item.state.abbreviation.trim()
                : item.stateName && item.stateName != null && item.stateName !== "UNKNOWN"
                  ? ", " + item.stateName.trim()
                  : "";
            wineryStreetAddress += " " + item.zipcode;

            return (
              <LocationCard
                wineryLocationBtnClicked={() =>
                  this.wineryLocationBtnClicked(item)
                }
                winery={{
                  wineryStreetAddress,
                }}
              />
            );
          })}
      </div>
    ) : (
      <div className="card card-post card-round winery-location-details shadow p-3 mb-5 bg-white rounded">
        <div className="winery-message-title">{"Location"}</div>
        <LocationCard
          wineryLocationBtnClicked={() => { }}
          winery={{
            wineryPhoneNumber:
              this.state.wineryData.wineryPhoneNumber,
            wineryStreetAddress: "Coming Soon",
          }}
        />
      </div>
    );


    const promotionElement = this.state.wineryPromotions ? (
        <div className="card card-post card-round winery-details-card shadow p-3 mb-5 bg-white rounded">
          <Slider {...settings} style={{ height: "fit-content" }}>
            {this.state.wineryPromotions.map((promo) => {
              return (
                  <PromotionModal
                      promotionData={promo}
                      openDialog={this.state.showPromotion}
                      initial={true}
                      handleClose={() => this.handlePromotionClose()}
                      plaidFriday={true}
                  />
              );
            })}
          </Slider>
        </div>
    ) : null;

    let wineryBodyJSX = null;
    wineryBodyJSX =
        <div
            className="winery-details-main-container"
            style={{ backgroundColor: "#f2f2f2" }}
        >
          <div
              style={{
                backgroundImage: wineryData.wineryCoverPic
                    ? `url(${wineryData.wineryCoverPic})`
                    : `url(${defaultWineryCoverPic})`,
              }}
          >
            <TitleCard
                winery={{
                  wineryCoverImage: wineryData.wineryCoverPic,
                  wineryName: wineryData.wineryName,
                  wineryImage: wineryData.wineryPic,
                  wineryUrl: wineryData.wineryWebsiteUrl,
                  wineryPhone: wineryData.wineryPhoneNumber,
                }}
            />
          </div>
          {this.state.wineryData.wineryMessage && (
              <div className="card card-post card-round winery-details-card shadow p-3 mb-5 bg-white rounded">
                <div>
                  {" "}
                  <span className="winery-about-message">
                  {this.state.wineryData.wineryMessage}
                </span>
                </div>
              </div>
          )}
          {promotionElement}
          {aboutUsJSX}
          {addressJSX}

          {this.state.wineryData.wineryFacebookUrl ||
          this.state.wineryData.wineryInstagramUrl ||
          this.state.wineryData.wineryTwitterUrl ? (
              <div className="card card-post card-round winery-location-details shadow p-3 mb-5 bg-white rounded">
                <div className="winery-about-container">
                  <span className="winery-contact-title">Social Media</span>
                  <div className="winery-social-media-container ">
                    {this.state.wineryData.wineryFacebookUrl ? (
                        <img
                            alt="Facebook"
                            onClick={() =>
                                this.socialMediaIconOnClick(
                                    this.state.wineryData.wineryFacebookUrl
                                )
                            }
                            className="social-media-logo ptr-clss"
                            src={facebookLogo}
                        />
                    ) : (
                        ""
                    )}



                    {this.state.wineryData.wineryInstagramUrl ? (
                        <img
                            alt="Instagram"
                            onClick={() =>
                                this.socialMediaIconOnClick(
                                    this.state.wineryData.wineryInstagramUrl
                                )
                            }
                            className="social-media-logo ptr-clss"
                            src={instagramLogo}
                        />
                    ) : (
                        ""
                    )}
                    {this.state.wineryData.wineryTwitterUrl ? (
                        <img
                            alt="Twitter"
                            onClick={() =>
                                this.socialMediaIconOnClick(
                                    this.state.wineryData.wineryTwitterUrl
                                )
                            }
                            className="social-media-logo ptr-clss"
                            src={twitterLogo}
                        />
                    ) : (
                        ""
                    )}
                  </div>
                </div>
              </div>
          ) : null}
          {this.state.wineryData.wineryMail ||
          this.state.wineryData.wineryPhoneNumber ||
          this.state.wineryData.wineryURL ? (
              <div className="card card-post card-round winery-location-details shadow p-3 mb-5 bg-white rounded">
                <div className="contact-container">
                  <span className="winery-contact-title">Contact Us</span>
                  {this.state.wineryData.wineryMail ? (
                      <div
                          className="winery-contact-contents ptr-clss"
                          onClick={() => {
                            this.mailClicked(this.state.wineryData);
                          }}
                      >
                        {this.state.wineryData.wineryMail}
                      </div>
                  ) : null}
                  {this.state.wineryData.wineryPhoneNumber ? (
                      <div>
                    <span
                        className="winery-about-text ptr-clss"
                        onClick={() =>
                            this.phoneClicked(this.state.wineryData)
                        }
                    >
                      {this.state.wineryData.wineryPhoneNumber}
                    </span>
                      </div>
                  ) : null}
                  {this.state.wineryData.wineryURL ? (
                      <span
                          onClick={() =>
                              this.socialMediaIconOnClick(
                                  this.state.wineryData.wineryURL
                              )
                          }
                          className="winery-website-url ptr-clss"
                      >
                    {this.state.wineryData.wineryURL}{" "}
                  </span>
                  ) : null}
                </div>
              </div>
          ) : null}
        </div>
    return (
        <div
            className="success-main-container"
            style={{ backgroundColor: "#f2f2f2" }}
        >
          <div
              // className="success-inner-container new-discover-inner-container-induvidual-wines"
              className=" success-inner-container  new-discover-inner-container"
          >
            <HeaderWithBurgerIcon
                title={"Winery Details"}
                arrowIcon={true}
                goBackFunc={() => this.props.history.goBack()}
            />

            <div
                className={isMobile ? null : "desktop-div-scroll "}
                // className="success-inner-container new-discover-inner-container-induvidual-wines"
                style={{ backgroundColor: "#f2f2f2" }}
            >
              {wineryBodyJSX}
            </div>
            <CheckInPopup
                open={this.state.showCheckInPopUp}
                handleCancel={this.handleCancel}
                checkinMsg={this.state.checkinMessage}
                checkinStatus={this.state.checkinStatus}
            />
            {!isEmbedded() &&(this.isExternalReferrer().length == 0) && <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
              <BottomNavigation />
            </div>}
          </div>
        </div>
    );
  }
}

// export default WineryDetails;

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    getWineryDetailsSuccess: state.WineryReducer.getWineryDetailsSuccess,
    getWineryDetailsFail: state.WineryReducer.getWineryDetailsFail,
  };
}

// function mapDispatchToProps(dispatch) {
//   return {
//     WineryAction: bindActionCreators(WineryAction, dispatch),
//   };
// }

export default connect(mapStateToProps, null)(WineryDetails);
