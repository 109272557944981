
import * as types from './action-types';
import * as constants from '../../constants/constants'

export const FindPreferenceByCustomer = () => {

    let consumerData = (JSON.parse(localStorage.getItem('user_data')));
    // let wineryId =  ( JSON.parse(localStorage.getItem('winery_id'))  );
    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;
    let consumerId = consumerData.id;

    return dispatch => {

        fetch(constants.CONSUMER_API + '/api/v1/taste_preference/findDefaultTastePreferenceByCustomer?userId=' + consumerId, { // new updated API url
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            }

        }).then(response => {
						return response.json();
        }).then(respJSON => {
					if (!respJSON.message) {
            dispatch({ type: types.FIND_TASTE_PREF_BY_CUST_SUCCEEDED, payload: respJSON })
					}
					dispatch({ type: types.FIND_TASTE_PREF_BY_CUST_FAILED })
        }).catch(error => {
            dispatch({ type: types.FIND_TASTE_PREF_BY_CUST_FAILED })
        })
    }
}

// export const FindDefaultTastePreferenceByCustomer = () => {

//     let consumerData = (JSON.parse(localStorage.getItem('user_data')));
//     let wineryId =  ( JSON.parse(localStorage.getItem('winery_id'))  );
//     let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
//     let bearer = 'Bearer ' + signInToken;
//     let consumerId = consumerData.id;

//     return dispatch => {

//         fetch(constants.CONSUMER_API + '/api/v1/taste_preference/getTastePreference', { // new updated API url
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": bearer
//             }

//         }).then(response => {
//             return response.json();
//         }).then(respJSON => {
//             dispatch({ type: types.FIND_DEFAULT_TASTE_PREF_BY_CUST_SUCCEEDED, payload: respJSON })
//         }).catch(error => {
//             dispatch({ type: types.FIND_DEFAULT_TASTE_PREF_BY_CUST_FAILED, payload: error })
//         })
//     }
// }



