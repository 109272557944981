import React, { Component } from "react";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import "./HelpPage.scss";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import { isMobile } from "react-device-detect";
class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  goBack = () => {
    this.props.history.goBack();
  };

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  emailClicked = () => {
    var newWindow = window.open("mailto:info@pairanything.com", "_blank");
    newWindow.focus();
  };

  phoneClicked = () => {
    var newWindow = window.open("tel:925-587-9627", "_blank");
    newWindow.focus();
  };

  render() {
    return (
      <div className="help-main-container" id="parentShoppingCartDiv">
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div
            className="success-inner-container new-discover-inner-container"
            id="parentShoppingCartDiv"
          >
            {" "}
            <HeaderWithBurgerIcon
              title="Help!"
              arrowIcon={true}
              goBackFunc={this.goBack}
            />
            <div className={isMobile ? null : "desktop-div-scroll "}>
              <div className="you-tube-container">
                <div className="youtube-video-container">
                  {/* <div className="you-tube-card resp-margin-css-to-overlap-with-header">
              <div className="youtube-responsive-container-help-page">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/WswTB5p6n3U?rel=0&modestbranding=1&iv_load_policy=3"
                  frameBorder="0"
                  // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="link-header">
                <span>Account Activation</span>
              </div>
              <div className="link-sub-header mt-1">
                <span>
                  Need help activating your PairAnything account? It's quick and
                  easy! Find out the steps on how to activate your account.
                </span>
              </div>
            </div> */}
                  {/* <div className="you-tube-card2 resp-margin-css-to-overlap-with-header">
              <div className="youtube-responsive-container-help-page">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/bjEc4zwhbXA?rel=0&modestbranding=1&iv_load_policy=3"
                  frameBorder="0"
                  // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="link-header">
                <span>Requesting a Pairing Recommendation</span>
              </div>
              <div className="link-sub-header mt-1">
                <span>
                  The recommendation feature allows you to find the perfect wine
                  for any of your dishes.
                </span>
              </div>
            </div> */}
                </div>
                {/* <div className="help-page-header-content">
                  <div className="new-margin-and-css">
                    {" "}
                    Videos and useful links to help you get started with PairAnything.
                    Please Contact us to help you get started with PairAnything!
                  </div>
                </div> */}
                <div className="contact-us-container-help ">
                  <div className="contact-us-header-help">Contact Us</div>
                  <div className="help-thin-divider-line"></div>
                  <div className="contact-us-contents-help ptr-clss">
                    Please Contact us to help you get started with PairAnything!
                  </div>
                  <div
                    className="contact-us-contents-help ptr-clss"
                    onClick={this.emailClicked}
                  >
                    Email: support@pairanything.com
                  </div>
                  <div
                    className="contact-us-contents-help ptr-clss"
                    onClick={this.phoneClicked}
                  >
                    Phone: 925-587-9627
                  </div>
                </div>
                <div className="bottom-scroll-padding"></div>
              </div>
              <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
                <BottomNavigation />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpPage;
