import React from "react";

export default function PasswordDots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="17"
      viewBox="0 0 86 17"
      fill="none"
    >
      <circle cx="3" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="13" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="23" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="33" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="43" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="53" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="63" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="73" cy="8.30469" r="3" fill="#7D7D7D" />
      <circle cx="83" cy="8.30469" r="3" fill="#7D7D7D" />
    </svg>
  );
}
