import * as types from '../actions/action-types';

const initialState = {
    cartTotalQuantity: 0
}
export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CART_TOTAL_QTY_SUCCESS:
            return {
                ...state,
                cartTotalQuantity: action.payload.quantity
            };
        case types.GET_CART_TOTAL_QTY_FAILED:
            return {
                ...state,
                // getCartTotalQtyFail: action.payload
            };
        default:
            return state;
    }
};