import React, { Component } from 'react';
import { isMobile } from 'react-device-detect'

import './ForgotPassword.scss';

import { Redirect } from 'react-router';
import { FormErrors } from '../../components/FormErrors/FormErrors';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ForgotPasswordAction from '../../redux/actions/ForgotPasswordAction'
import * as constants from '../../constants/constants';
import backBtn from '../../../assets/images/back-btn.svg'

import logo from '../../../assets/images/splash.png';

import * as GoogleAnalyticsFunctions from '../../components/PAGoogleAnalytics';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {

      signInPageLoaded: false,
      email: '',

      formErrors: { email: '', password: '' },
      emailValid: false,

      inValidMessageFromResponse: '',
      formValid: false,
      forgotPasswordSuccessful: false,// done successfully - existing email was entered by user
      disabledBtn: false
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)

  }

  componentDidMount = () => {

    this.setState({
      forgotPasswordSuccessful: false
    })

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    GoogleAnalyticsFunctions.clickedForgotPassword();


  }


  handleEmailChange(event) {
    let val = event.target.value;
    this.setState(
      {
        email: event.target.value,
        inValidMessageFromResponse: '',
        buttonClick: false
      },
      () => { this.validateField("email", val) });
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    //nextProps - new val from reducer
    // prevState - state value




    if (nextProps.forgotPasswordSuccess != undefined) {  // coming from mapStateToProps


      // if (nextProps.forgotPasswordSuccess) {


      if (nextProps.forgotPasswordSuccess.msg == 'success' && prevState.formValid
        && prevState.inValidMessageFromResponse != "User doesn't exist" && prevState.email != "" &&
        prevState.forgotPasswordSuccessful != true) {


        if (prevState.buttonClick) { // display the toast message only when the button is clicked
          toast.info("Mail has been sent to reset your password!", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }

        return {
          disabledBtn: false,
          forgotPasswordSuccessful: true
        }

      } else if (nextProps.forgotPasswordSuccess.msg == 'Wrong password') {
        return {
          // signInPageStatus: false,
          inValidMessageFromResponse: '',
          disabledBtn: false
        }
      } else {

        if (prevState.inValidMessageFromResponse == "Please enter a valid email address! ") {

          return {
            inValidMessageFromResponse: 'Please enter a valid email address! ',
            disabledBtn: false
          }
        }
        else if (prevState.inValidMessageFromResponse == "User doesn't exist") {

          return {
            inValidMessageFromResponse: "User doesn't exist ",
            disabledBtn: false
          }
        } else if (prevState.email == "") {
          return {
            disabledBtn: false
            // inValidMessageFromResponse: "User doesn't exist "    
          }
        }
        else if (prevState.inValidMessageFromResponse == "success") {

          return {
            inValidMessageFromResponse: "User doesn't exist!",
            forgotPasswordSuccessful: false,
            signInPageLoaded: false,
            disabledBtn: false
          }
        }
        else {

          return {

            disabledBtn: false,
            inValidMessageFromResponse: nextProps.forgotPasswordSuccess.msg
          }

        }
      }

      // } else {

      //   // alert('User does not exist')
      //   return {
      //     // formErrors: 'User does not exist',
      //     // signInPageStatus: false,


      //   }


      // }


    } else {
      return;
    }

  }


  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Please enter a valid email address! ';
        break;

      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid
    });
  }


  sendResetPasswordLink = () => {


    const username = this.state.email;
    // this.setState({
    //   disabledBtn: true
    // })
    if (this.state.formErrors.email != '') {
      this.setState({
        inValidMessageFromResponse: this.state.formErrors.email
      })
    } else if (username != '') {
      this.setState({
        inValidMessageFromResponse: '',
        forgotPasswordSuccessful: false,
        disabledBtn: true
      })
      this.props.ForgotPasswordAction.ForgetApiDummyCall();
      this.props.ForgotPasswordAction.ForgotPassword(username.toLowerCase())
    } else {
      this.setState({
        inValidMessageFromResponse: 'Please enter your email address! '
      })
    }

    this.setState({
      buttonClick: true,
      // disabledBtn: false
    });

  }


  gotoSignInPage = () => {

    this.setState(
      {
        signInPageLoaded: true
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.signInPageLoaded) {

      // return <Redirect push to="/sign-in" />;
      this.props.history.push('/sign-in');
    } else if (this.state.forgotPasswordSuccessful && this.state.buttonClick) { // email entered by user is valid

      // return <Redirect push to="/sign-in" />;
      this.props.history.push('/sign-in');

    }
  }


  render() {
    return (
      <div className="forgot-password-main-container-background">
        <div className="forgot-password-main-container">

          {/* <div className = "forgot-password-header">
         
                    <div>  <img className="" src={backBtn} onClick = { this.gotoSignInPage }/> </div>
                    <span className = "forgot-password-title">Forgot Password</span>
      
                </div>  */}

          <div className="header">
            {
              isMobile &&
              <div className="back-btn-container">
                <img alt="Go back" className="" src={backBtn} onClick={this.gotoSignInPage} />
              </div>
            }

            <div className="logo-container">
              <img alt="PairAnything" className="logo" src={logo} />
            </div>
            <div className="set-password-title">
              Forgot Password
          </div>

          </div>

          <div className="form-errors-display">

            <span>
              {/* <FormErrors formErrors={this.state.userExists} /> */}
              {/* {
                     (!this.state.userExists && this.state.buttonClick)? 'User does not exist' : ''
                   } */}
              {
                (this.state.inValidMessageFromResponse != '' && this.state.inValidMessageFromResponse != 'success' && this.state.buttonClick) ? this.state.inValidMessageFromResponse : ''
              }

            </span>
          </div>



          <div className="forgot-password-form-parent">
            <div className="forgot-password-form-container">
              <form className="forgot-password-form">


                {/* <input
                     className="forgot-password-form-row"
                    // className={`form-row ${this.state.emailValid? '' : ' form-row-error'}`}
                     type="text" name="email" placeholder="Email"
                     value={this.state.email} onChange={ this.handleEmailChange } />  */}

                {/* CSS taken from signin-form-group form-group classes in signin.js  */}
                <div className="signin-form-group form-group">
                  {
                    !isMobile &&
                    <div className="form-email-text">Email address</div>
                  }

                  <input className="form-control signin-form-row-input"
                    type="text" name="email"
                    placeholder={isMobile ? "Email" : ""}
                    id="forgotpasswordemail"
                    value={this.state.email} onChange={this.handleEmailChange} />
                  <label htmlFor="forgotpasswordemail"></label>
                </div>



                <div className="reset-password-text-container">
                  {/* <span className = "reset-password-text">We will send you the reset password link via Email</span> */}
                </div>
                <button type="button" onClick={this.sendResetPasswordLink}
                  disabled={this.state.disabledBtn}
                  className="btn btn-round forgot-password-btn">Reset Password</button>
                {
                  !isMobile &&
                  <div
                    onClick={this.gotoSignInPage}
                    className="back-to-signin-text"
                  >
                    Back to Sign in
              </div>}
              </form>

            </div>
          </div>

          {/* <div className = "footer">
                    <div className = "forgot-password-using-text" >or Sign in using</div>
                    <div className = "logo-container">
                            <img className="facebook-logo" src={facebookLogo} />
                    </div>
                </div> */}

        </div>

      </div>

    );
  }
}



// REDUX

function mapStateToProps(state, props) {  // getDerivedStateFromProps gets this returned value


  return {
    forgotPasswordSuccess: state.ForgotPasswordReducer.forgotPasswordSuccess,
    forgotPasswordFail: state.ForgotPasswordReducer.forgotPasswordFail
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ForgotPasswordAction: bindActionCreators(ForgotPasswordAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

