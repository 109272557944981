import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import "./RateRecommendation.scss";
import rateRecommendationLogo from "../../../assets/images/Rating/rate-recommendation.svg";
import * as constants from "../../constants/constants";
import backBtn from "../../../assets/images/back-btn.svg";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import { isMobile } from "react-device-detect";
import { updateFoodRequestDetails } from "../../screens/PARecommendation/action";

let ratingStatus = 0;

class RateRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wineryId: "",
      rating: 1,
      sample: 0,
      gotoRecommendationPageStatus: false,
      ratingStarError: "",
      errorOccurred: false,
      recommendationData: "",
    };
  }
  componentDidMount() {
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    this.setState({
      wineryId: wineryId,
    });
    if (this.props.location) {
      this.setState({
        recommendationData: this.props.location.state.recommendationData,
      });
    } else if (this.props.recommendationData) {
      this.setState({
        recommendationData: this.props.recommendationData,
      });
    }

    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  }

  displayRatingBar = (count) => {
    let stars = [];

    for (var i = 1; i <= count; i++) {
      var ratingStarClass = "star-rating-ratingpage__star rate-reco-star";

      if (
        this.state.sample != 0 &&
        this.state.rating >= i &&
        this.state.rating != null
      ) {
        ratingStatus = 1;
        ratingStarClass += " is-selected";
      }

      stars.push(
        <label
          key={i}
          className={ratingStarClass}
          onClick={this.rate.bind(this, i)}
        >
          ★
        </label>
      );
    }

    if (ratingStatus == 1) {
      // this.saveRecommendationRating();
    }
    return stars;
  };

  rate(rating) {
    this.setState({
      rating: rating,
      sample: 1,
    });
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  saveRecommendationRating = async () => {
    if (ratingStatus === 1) {
      if (this.state.recommendationData) {
        let consumerData = JSON.parse(localStorage.getItem("user_data"));
        let wineryId = JSON.parse(localStorage.getItem("winery_id"));

        let signInToken = JSON.parse(localStorage.getItem("signin_token"));
        let bearer = "Bearer " + signInToken;

        var date = new Date();
        var timeStamp = date.toISOString();
        let questionId = this.state.recommendationData.id;
        // if (this.props.recommendationData) {
        // questionId = this.props.recommendationData.id;
        // }
        // if (this.props.location) {
        //     if (this.props.location.state.recommendationData) {
        //         questionId = this.props.location.state.recommendationData.id;
        //     }
        // }

        if (this.state.recommendationData.request) {
          let url =
            constants.GOOGLE_SEARCH_API +
            "/api/v1/recommendation/paRequest/" +
            this.state.recommendationData.id;

          try {
            const path = `${url}`;
            let apiBodyData = {
              rating: this.state.rating,
              ratedOn: timeStamp,
              lastUpdatedAt: timeStamp,
              lastUpdatedBy: consumerData.id,
            };
            const updateRating = await updateFoodRequestDetails(
              path,
              apiBodyData
            );
            ratingStatus = 0;
            GoogleAnalyticsFunctions.clickedRecommendationRating();
            if( this.props.exitRateRecommendation)
                this.props.exitRateRecommendation()
              else 
                this.props.history.goBack();
          } catch (e) {
            console.log(e);
            let apiErrorObj = {
              statusText: e.statusText,
              type: e.type,
              status: e.status,
              url: url,
              operation: "PUT",
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            this.setState({
              ratingStarError:
                "Could not save your rating. Something went wrong. Please try again later. ",
              errorOccurred: true,
            });
          }
        } else {
          let putRatingStarApiUrl =
            constants.IDENTITY_API +
            "/api/v1/recommendation/question/consumer/" +
            questionId +
            "?wineryId=" +
            wineryId;

          let apiBodyData = {
            pairingRating: this.state.rating,
            pairingRatingTimestamp: timeStamp,
          };

          fetch(putRatingStarApiUrl, {
            // dev
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearer,
            },
            body: JSON.stringify(apiBodyData),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                let apiErrorObj = {
                  statusText: res.statusText,
                  type: res.type,
                  status: res.status,
                  url: putRatingStarApiUrl,
                  operation: "PUT",
                };

                this.postCaughtException(JSON.stringify(apiErrorObj));
                this.setState({
                  ratingStarError:
                    "Could not save your rating. Something went wrong. Please try again later. ",
                  errorOccurred: true,
                });

                throw Error(res.statusText);
              }
            })
            .then((json) => {
              ratingStatus = 0;
              GoogleAnalyticsFunctions.clickedRecommendationRating();
              if( this.props.exitRateRecommendation)
                this.props.exitRateRecommendation()
              else 
                this.props.history.goBack();
              // this.setState({
              //     gotoRecommendationPageStatus: true
              // })
            })
            .catch((error) => console.error(error));
        }
      } else {
      }
    } else {
      this.setState({
        ratingStarError: "Please add the rating to continue",
      });
    }
  };

  gotoRecommendationPage = () => {
    // this.setState({
    //     gotoRecommendationPageStatus: true
    // });
    if( this.props.exitRateRecommendation)
    this.props.exitRateRecommendation()
  else 
    this.props.history.goBack();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.gotoRecommendationPageStatus) {
      //     return <Redirect to={{
      //       pathname: '/search-wine' + '/' + this.state.wineryId,
      //       state: {
      //              }

      //   }}/>;

      this.props.history.replace({
        pathname: "/search-wine" + "/" + this.state.wineryId,
        state: {},
      });
    }
  }

  render() {
    const { recommendationData } = this.state;
    return (
      <div>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className=" success-inner-container  new-discover-inner-container">
            {/* <HeaderWithBurgerIcon title="Pairing Recommendations" /> */}
            <div
              className={isMobile ? null : "desktop-div-scroll "}
              style={{ backgroundColor: "#f2f2f2" }}
            >
              <img
                alt="Go back"
                className="rate-recommendation-back-btn"
                src={backBtn}
                onClick={() => {
                  this.gotoRecommendationPage();
                }}
              />

              <div className="rate-recommendation-container">
                <img
                  alt="Rate Recommendation Icon"
                  className={"rate-recommendation-icon"}
                  src={rateRecommendationLogo}
                />
                <div className={"rate-recommendation-text-container"}>
                  <div className="rate-recommendation-title-container">
                    {recommendationData && (
                      <span className="rate-recommendation-text1 rate-reco-text">
                        {recommendationData.question ||
                        recommendationData.request
                          ? "Rate the recommendation for "
                          : "Rate the recommendation for your dish"}
                      </span>
                    )}
                    {/* {
                                this.props.recommendationData &&
                                <span className="rate-recommendation-text1-desktop">
                                    {
                                        this.props.recommendationData.question ?
                                            'Rate the recommendation for ' : 'Rate the recommendation for your dish'
                                    }
                                </span>
                            } */}
                    {recommendationData && (
                      <span className="rate-recommendation-dishname">
                        {recommendationData.question
                          ? recommendationData.question
                          : recommendationData.request
                          ? recommendationData.request
                          : ""}
                      </span>
                    )}
                    {/* {
                                this.props.recommendationData &&
                                <span className="rate-recommendation-dishname-desktop">
                                    {
                                        this.props.recommendationData.question ? this.props.recommendationData.question : ''
                                    }
                                </span>
                            } */}
                  </div>
                  <div className="rate-recommendation-text-subcontainer">
                    <div
                      className={
                        "rating-star-container rating-star-recommendation-page"
                      }
                    >
                      {this.displayRatingBar(5)}
                    </div>
                    {this.state.ratingStarError != "" && ratingStatus != 1 ? (
                      <span className="form-errors-display">
                        {this.state.ratingStarError}
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.errorOccurred ? (
                      <span className="form-errors-display">
                        {this.state.ratingStarError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="rate-recommendation-bottom">
                  <div className="recommendation-rating-btn-container">
                    <button
                      onClick={this.saveRecommendationRating.bind(this)}
                      className={"btn btn-round rate-recommendation-submit-btn"}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="feedback-text-container">
                    <span className="rate-recommendation-feedback-text">
                      Your feedback means a lot to us.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RateRecommendation;
