import React from "react";
import wineryLogo from "../../../assets/images/winery-logo.png";

const TitleCard = ({ winery }) => {
  return (
    <div className="winery-logo-parent-div">
      <div
        // className="card card-post card-round winery-details-card shadow p-3 mb-5 bg-white rounded"
        className="winery-logo-div"
      >
        <div className="winery-details-card-subheader">
          <img
            className="winery-logo-max-dimensions"
            src={winery.wineryImage != "" ? winery.wineryImage : wineryLogo}
          />

          <span className="winery-name-title">{winery.wineryName} </span>
        </div>
      </div>
    </div>
  );
};
export default TitleCard;
