import React, { Component } from "react";
import "./SelectedTastePreference.scss";
import EditTastePreference from "../../components/EditTastePreference/EditTastePreference";

import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import editBtn from "../../../assets/images/taste-preference-edit-icon.svg";
import { isMobile } from "react-device-detect";
import * as constants from "../../constants/constants";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as FindTastePreferenceByCustomerAction from "../../redux/actions/FindTastePreferenceByCustomerAction";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";

class SelectedTastePreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditComponent: false,
    };
  }
  componentDidMount = () => {
    this.props.FindTastePreferenceByCustomerAction.FindPreferenceByCustomer();
  };

  gotoPreviousPage = () => {
    this.props.history.goBack();
  };

  editComponentHandler = () => {
    this.props.FindTastePreferenceByCustomerAction.FindPreferenceByCustomer();
    setTimeout(() => {
      this.setState({
        showEditComponent: !this.state.showEditComponent,
      });
    }, 1800);
  };

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  toggleEdit = () => {
    this.setState({
      showEditComponent: !this.state.showEditComponent,
      // RenderAgain: !this.state.RenderAgain
    });
  };

  showEditComponent = () => {
    this.setState({
      showEditComponent: !this.state.showEditComponent,
    });
  };

  renderWineType = () => {
    let tastePreference = this.props.preference.taste_preference;
    let result = [];
    let i = 0;
    if (tastePreference[i].category === "wine_type") {
      for (let j = 0; j < tastePreference[i].options.length; ++j) {
        let options = tastePreference[i].options;
        let selected = tastePreference[i].options[j].value;

        if (selected === 1) {
          let resultName = options[j].name;

          resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
          result.push(
            <div className="result-container" key={resultName}>
              <button
                key={resultName}
                type="button"
                className="btn btn-round results"
              >
                {resultName}
              </button>
            </div>
          );
        }
      }
    }
    return result;
  };

  renderWineCharacteristics = () => {
    let tastePreference = this.props.preference.taste_preference;
    let result = [];
    let i = 1;
    if (tastePreference[i].category === "wine_characteristics") {
      for (let j = 0; j < tastePreference[i].options.length; ++j) {
        let options = tastePreference[i].options;
        let selected = tastePreference[i].options[j].value;

        if (selected === 1) {
          let resultName = options[j].name;
          resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
          result.push(
            <div className="result-container" key={resultName}>
              <button
                key={resultName}
                type="button"
                className="btn btn-round results"
              >
                {resultName}
              </button>
            </div>
          );
        }
      }
    }
    return result;
  };

  renderWineKnowledge = () => {
    let tastePreference = this.props.preference.taste_preference;
    // debugger;
    let result = [];
    let i = 2;
    if (tastePreference[i].category === "wine_knowledge") {
      for (let j = 0; j < tastePreference[i].options.length; ++j) {
        let options = tastePreference[i].options;
        let selected = tastePreference[i].options[j].value;

        if (selected === 1) {
          let resultName = options[j].name;
          resultName = resultName.charAt(0).toUpperCase() + resultName.slice(1);
          result.push(
            <div className="result-container" key={resultName}>
              <button
                key={resultName}
                type="button"
                className="btn btn-round results"
              >
                {resultName}
              </button>
            </div>
          );
        }
      }
    }
    return result;
  };

  render() {
    // console.log(this.props, 'props')
    let { preference } = this.props;
    return (
      <div className="taste-main-container">
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className=" success-inner-container  new-discover-inner-container">
            <HeaderWithBurgerIcon
              title="Taste Preference"
              arrowIcon={true}
              goBackFunc={() =>
                this.state.showEditComponent
                  ? this.editComponentHandler()
                  : this.props.history.goBack()
              }
            />
            <div
              className={isMobile ? null : "desktop-div-scroll "}
              style={{ backgroundColor: "#f2f2f2" }}
            >
              {/* <div>
								<div className="common-page-header">
										<div className="common-page-sub-header new-common-header-change">
												<img
														alt="Open Recommendations page"
														className="common-sub-header-back-icon ptr-clss"
														onClick={() => { this.gotoPreviousPage() }}
														src={backBtn}
												/>
												<div className='new-header-text'>
													<span className="common-sub-header-text new-search-wine-for-dish-text">
														Taste Preference
													</span>
												</div>
												<img
														alt="PairAnything"
														className="common-sub-header-logo new-logo-header-design ml-0"
														src={pairAnythingLogo}
														onClick={() => { this.openHomePage() }}
												/>
										</div>
								</div>
							</div> */}
              {this.state.showEditComponent ? (
                <EditTastePreference
                  handleClick={this.editComponentHandler}
                  handleEditedData={this.toggleEdit}
                  selectedTastePreferenceJson={preference}
                />
              ) : (
                <div>
                  <div
                    className="sub-title ptr-clss"
                    onClick={() => {
                      this.showEditComponent();
                    }}
                  >
                    <div className="edit-text">Edit</div>
                    <img className="edit-icon" src={editBtn} alt="Edit Icon" />
                  </div>
                  <div className="taste-card-container">
                    <div className="card card-post card-round wine-type-card shadow p-3 mb-5 bg-white rounded">
                      <div className="card-header">Wine Type</div>
                      <div className="display-results-row">
                        {preference ? this.renderWineType() : null}
                      </div>
                    </div>
                    <div className="card card-post card-round wine-type-card shadow p-3 mb-5 bg-white rounded">
                      <div className="card-header">Wine Characteristics</div>
                      <div className="display-results-row">
                        {preference ? this.renderWineCharacteristics() : null}
                      </div>
                    </div>
                    <div className="card card-post card-round wine-type-card shadow p-3 mb-5 bg-white rounded">
                      <div className="card-header">Wine Knowledge</div>
                      <div className="display-results-row">
                        {preference ? this.renderWineKnowledge() : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//REDUX
function mapStateToProps(state, props) {
  return {
    preference: state.FindTastePreferenceByCustomerReducer.preference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    FindTastePreferenceByCustomerAction: bindActionCreators(
      FindTastePreferenceByCustomerAction,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedTastePreference);
