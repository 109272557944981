import React, { Component } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import "./DateOfBirthCheck.scss";
import * as constants from "../../constants/constants";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import logo from "../../../assets/images/splash.png";
import moment from "moment";
import { toast } from "react-toastify";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import * as signoutAction from "../../redux/actions/Signout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  convertToCamelCase,
  fetchCampaignDetails,
} from "../../constants/commonFunctions";
import { Link } from "react-router-dom";
toast.configure();

class DateOfBirthCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      isValidToken: null,
      userPairAnythingStatus: "",
      userWineryStatus: null,
      formError: "",
      userDOBStatus: false,
      dobMonth: "",
      dobDay: "",
      dobYear: "",
      dobFormError: "",
      dob: "",
      firstName: "",
      lastName: "",
      validAge: false,
      submitDobButtonStatus: false,
      gotoNextPageStatus: false,
      dobError: "",
      firstNameFormError: "",
      lastNameFormError: "",
      consumerEmailIdFromUrl: "",
      consumerIdFromEmail: "",
      statusName: "",
      getUserByEmailApiHit: false,
      wineryId: "",
      urlToken: "",
      commonErrorDisplay: "",
      agreeFormError: "",
      passwordFormError: "",
      confirmPasswordCommonError: "",
      password: "",
      confirmPassword: "",
      agree: false,
      agreeFormError: "",
      showTermsAndConditions: false,
      signUpNewUserCompleted: false,
      signUpApiResponseDetails: "",
      // email: '',
      emailFormError: "",
      encodedWineryId: "",
      emailCampaignId: null,
      onBoardingChannelWineryId: "",
      showTerms: false,
      showPrivacy: false,
    };
    this.handleChange = this.handleChange.bind(this);
    console.log(this.props);
  }
  componentDidMount() {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    this.validateEmailAndTokenThenGetDataFromIt();
    // this.checkUserDOBStatus();
  }

  validateEmailAndTokenThenGetDataFromIt() {
    //due to change in url, the pathname has changed, hence changein the url array.
    //the wineryId now comes as the queryString and not queryParameter, decoding of the wineryId no longer needed.
    let urlTemp = []; // splitting the URL
    urlTemp = this.props.location.pathname.split("/");

    console.log(urlTemp);
    let emailFromUrl = urlTemp[1];
    let tokenFromUrl = urlTemp[3];
    let encodedWineryId = urlTemp[4] ? urlTemp[4] : undefined;
    let emailData = jwt.decode(tokenFromUrl.toString(), {
      alg: "HS256",
      typ: "JWT",
    });

    let emailCampaignId = emailData.emailCampaignId || null;
    // console.log('Winery Id Encoded', encodedWineryId);

    // let buff = new Buffer("139", 'utf-8');
    // let encodedWineryId = buff.toString('base64');
    // console.log('Manual Winery Id Encoded', encodedWineryId);
    let decodedWineryId = "";
    if (encodedWineryId) {
      //   // base64 to string
      let buff = Buffer(encodedWineryId, "base64");
      decodedWineryId = buff.toString("utf8");
      // console.log('Winery Id Decoded', decodedWineryId);
    }

    this.setState({
      consumerEmailIdFromUrl: emailFromUrl, // email
      urlToken: tokenFromUrl, // token
      encodedWineryId: encodedWineryId, // encoded wineryId
      emailCampaignId, // from token
      onBoardingChannelWineryId: decodedWineryId,
    });

    // Getting data from email and validating token based on user id in this method.
    this.getDataFromEmail(emailFromUrl, tokenFromUrl);
  }

  getDataFromEmail(emailFromUrl, tokenFromUrl) {
    if (this.validateEmail(emailFromUrl)) {
      let getUserDetailsUrl =
        constants.IDENTITY_API +
        "/api/v1/public/user/getUserByEmail/" +
        emailFromUrl;
      fetch(getUserDetailsUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getUserDetailsUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj), tokenFromUrl);
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          // Checking Pair Anything status
          console.log(json);
          let pariAnythingStatus = json.user.pairanything_status.name;
          let userId = json.user.id;
          this.setState({
            userPairAnythingStatus: pariAnythingStatus,
            userId,
          });
          if (json.wineryData && json.wineryData.length > 0) {
            localStorage.setItem(
              "winery_name",
              this.state.signUpApiResponseDetails.user.wineryData[0].displayName
            );
            localStorage.setItem(
              "winery_id",
              JSON.stringify(
                this.state.signUpApiResponseDetails.user.wineryData[0].wineryId
              )
            );
          }

          // Checking Winery status
          let wineryStatus;

          // if (json.user.wineryData.length > 0) {
          //   json.user.wineryData.forEach((element) => {
          //     if (element.wineryUserStatus == 1) {
          //       wineryStatus = element.wineryUserStatus;
          //       this.setState({
          //         userWineryStatus: wineryStatus,
          //       });
          //     }
          //   });

          //   // If pair Anything status is invited and wineryStatus is 1 (invited) then we are validating token.

          // }
          if (pariAnythingStatus === "invited") {
            this.validateToken(json, tokenFromUrl);
          } else if (!wineryStatus) {
            this.setState({
              userWineryStatus: 0, // if user had no invited wineries
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        commonErrorDisplay: "Email address is invalid !",
      });
      //  toast.error("Email address is invalid! ", {
      //   position: toast.POSITION.BOTTOM_CENTER
      // });
    }
  }

  validateToken(userData, tokenFromUrl) {
    let bearer = "Bearer " + tokenFromUrl;
    let validateTokenUrl = "/api/v1/verification/verify/" + userData.user.id;
    fetch(constants.IDENTITY_API + validateTokenUrl, {
      // verifying url token using user id.
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: validateTokenUrl,
            operation: "PUT",
            status: res.status,
          };
          this.postCaughtException(JSON.stringify(apiErrorObj), tokenFromUrl);
          this.setState({
            isValidToken: false,
          });
        }
      })
      .then((response) => {
        if (response[0] === 1) {
          this.setState({
            isValidToken: true,
          });
          this.checkUserDetailsAndSetData(userData);
        } else {
          this.setState({
            isValidToken: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isValidToken: false,
        });
      });
  }

  checkUserDetailsAndSetData(userData) {
    this.setState({
      firstName: userData.user.firstName,
      lastName: userData.user.lastName,
      // getUserByEmailApiHit: true,
      consumerIdFromEmail: userData.user.id,
      // createdBy: userData.user.createdBy,
      // roleId: userData.user.roleId,
      // // wineryId: userData.wineryId,
    });
    if (userData.user.dateOfBirth != null) {
      let date1 = userData.user.dateOfBirth;
      // Parsing and setting state for dob input fields
      let dataObjects = this.parseDateStringAndSetStateFields(date1);
      this.setState({
        ...this.state,
        dobDay: dataObjects.day,
        dobMonth: dataObjects.month,
        dobYear: dataObjects.year,
        dob: dataObjects.fullDate,
      });
    }
  }

  // updateUserDetails = (userObj) => {
  //   let bearer = "Bearer " + this.state.urlToken;
  //   let putUserDetailsUrl =
  //     constants.IDENTITY_API + "/api/v1/user/" + this.state.consumerIdFromEmail;
  //   try {
  //     fetch(putUserDetailsUrl, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: bearer,
  //       },
  //       body: JSON.stringify(userObj),
  //     })
  //       .then((res) => {
  //         if (res.ok) {
  //           return res.json();
  //         } else {
  //           let apiErrorObj = {
  //             statusText: res.statusText,
  //             type: res.type,
  //             status: res.status,
  //             url: putUserDetailsUrl,
  //             operation: "PUT",
  //             status: res.status,
  //           };
  //           this.postCaughtException(
  //             JSON.stringify(apiErrorObj),
  //             this.state.urlToken,
  //             this.state.consumerIdFromEmail
  //           );
  //           this.setState({
  //             dobError:
  //               "Something went wrong. Could not save your details. Please try again later. ",
  //           });
  //           throw Error(res.statusText);
  //         }
  //       })
  //       .then((res) => {
  //         //   if successful, update state
  //         this.setState({
  //           gotoNextPageStatus: true,
  //         });
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         this.setState({
  //           dobError:
  //             "Something went wrong. Could not save your details. Please try again later. ",
  //         });
  //       });
  //   } catch (e) {
  //   }
  // };

  handleDayBlur = (event) => {
    let dayCheckValue = event.target.value;
    if (
      dayCheckValue != "" &&
      dayCheckValue != undefined &&
      dayCheckValue.length < 2
    ) {
      dayCheckValue = "0" + dayCheckValue;
      this.setState({
        ...this.state,
        dobDay: dayCheckValue,
      });
    }
  };
  handleChange = (event) => {
    let { name, value } = event.target;

    if (name == "firstName" && this.state.firstNameFormError != "") {
      this.setState({
        firstNameFormError: "",
      });
    }

    if (name == "lastName" && this.state.lastNameFormError != "") {
      this.setState({
        lastNameFormError: "",
      });
    }

    if (name == "password" && this.state.password != "") {
      // if (value) { value = value.trim(); }
      this.setState({
        passwordFormError: "",
      });
    }

    if (name == "confirmPassword" && this.state.confirmPassword != "") {
      // if (value) { value = value.trim(); }
      this.setState({
        confirmPasswordCommonError: "",
      });
    }

    if (name == "dob" && this.state.dobFormError != "") {
      this.setState({
        dobFormError: "",
      });
    }

    if (name == "dobDay") {
      if (value.length > 2) value = value.slice(0, 2);
    }
    if (name == "dobYear") {
      if (value.length > 4) value = value.slice(0, 4);
    }

    this.setState({
      formError: "",
      [name]: value,
    });
  };

  checkValidDateFormat(dob) {
    var aDate = moment(dob, "YYYY-MM-DD", true);
    var isValid = aDate.isValid();
    return isValid;
  }

  calculateAge(dob) {
    let date1 = new Date();
    let date2 = new Date(dob);
    let ageDiff = date1.getUTCFullYear() - date2.getUTCFullYear();
    let date2Year = date2.getUTCFullYear();
    if (ageDiff == 21) {
      let monthDiff = date1.getUTCMonth() - date2.getUTCMonth();
      if (monthDiff == 0) {
        if (date2.getDate() <= date1.getDate()) {
          return true;
        } else {
          return false;
        }
      } else if (monthDiff > 0) {
        return true;
      } else {
        return false;
      }
    } else if (ageDiff > 21 && date2Year > 1900) {
      return true;
    } else {
      return false;
    }
  }

  handleCheckBoxChange = () => {
    this.setState({
      agree: !this.state.agree,
      agreeFormError: "",
      formError: "",
    });
  };

  handleAllFieldBlur = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (value) {
      value = value.trim();
      // console.log(name, value);
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  submitUserDetails = () => {
    let validForm = true;

    if (!this.state.firstName) {
      validForm = false;
      this.setState({
        firstNameFormError: "First name cannot be empty.",
      });
    }
    if (!this.state.lastName) {
      validForm = false;
      this.setState({
        lastNameFormError: "Last name cannot be empty.",
      });
    }

    let dateOfBirth;
    // if (this.state.dobMonth && this.state.dobDay && this.state.dobYear) {
    //   dateOfBirth =
    //     this.state.dobYear +
    //     "-" +
    //     this.state.dobMonth +
    //     "-" +
    //     this.state.dobDay;
    // this.setState({ dob: dateOfBirth });
    // let isValidDateFormat = this.checkValidDateFormat(dateOfBirth);
    //   if (isValidDateFormat) {
    //     let validDob = this.calculateAge(dateOfBirth);
    //     if (validDob) {
    //       this.setState({
    //         validAge: true,
    //         dobFormError: "",
    //       });
    //     } else if (!validDob) {
    //       validForm = false;
    //       this.setState({
    //         dobFormError:
    //           "Sorry, you must be at least 21 years old and year should be greater than 1900.",
    //         validAge: false,
    //       });
    //     }
    //   } else {
    //     validForm = false;
    //     this.setState({
    //       dobFormError: "Sorry, please enter a valid date.",
    //       validAge: false,
    //     });
    //   }
    // } else {
    //   validForm = false;
    //   this.setState({
    //     dobFormError: "Sorry, please enter a valid date.",
    //     validAge: false,
    //   });
    // }

    if (!this.state.password) {
      validForm = false;
      this.setState({
        passwordFormError: "Please enter your password.",
      });
    } else if (this.state.password.length < 4) {
      validForm = false;
      this.setState({
        passwordFormError: "Please enter atleast 4 characters. ",
      });
    }
    // else if (!this.state.confirmPassword) {
    //   validForm = false;
    //   this.setState({
    //     confirmPasswordCommonError: "Please enter your confirm password. ",
    //   });
    // } else if (this.state.password != this.state.confirmPassword) {
    //   validForm = false;
    //   this.setState({
    //     confirmPasswordCommonError: "Passwords do not match.",
    //   });
    // }
    else if (
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password == this.state.confirmPassword
    ) {
      this.setState({
        passwordFormError: "",
        confirmPasswordCommonError: "",
      });
    }

    // if (!this.state.agree) {
    //   validForm = false;
    //   this.setState({
    //     agreeFormError: "Please agree to the Terms of Use.",
    //   });
    // }

    if (validForm) {
      localStorage.setItem("ageConfirmed", true);
      this.state.dob = dateOfBirth;
      let obj = {
        id: this.state.consumerIdFromEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
        confirmPassword: this.state.password,
        dateOfBirth: dateOfBirth,
        onBoardingChannelWineryId: this.state.onBoardingChannelWineryId,
        emailCampaignId: this.state.emailCampaignId,
        email: this.state.consumerEmailIdFromUrl,
        isAgeVerified: true,
        ageVerifiedOn: moment().utc(),
      };
      this.singUpExistingUser(obj);
      // this.postDateOfBirthCheck(dateOfBirth);
      // GoogleAnalyticsFunctions.clickedSaveDobVerification();
    } else {
      this.setState({
        formError: "",
      });
    }

    // this.setState({
    //   submitDobButtonStatus: true,
    // });
  };

  postCaughtException = (errorData, token, userId = null) => {
    // let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + token;
    // let consumerData = JSON.parse(localStorage.getItem("user_data"));
    // let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: userId,
      // wineryId : wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.userDOBStatus) {
    //   try {
    //     // let wineryId =  ( JSON.parse(localStorage.getItem('winery_id'))  );
    //     // return <Redirect push to="/select-winery" />;
    //     this.props.history.replace("/select-winery");
    //     // this.props.history.goBack();
    //   } catch (e) {}
    // }

    // if (this.state.statusName == "active" && this.state.getUserByEmailApiHit) {
    //   toast.info(
    //     "You have already set your password. Please signin to continue!",
    //     {
    //       position: toast.POSITION.BOTTOM_CENTER,
    //     }
    //   );
    //   this.props.history.push("/sign-in");
    // }

    //if the user has been directed from email campaign
    let query = queryString.parse(this.props.location.search);
    if (this.state.userPairAnythingStatus === "active") {
      toast.info(
        "You have already set your details and password. Please sign in to continue!",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
      //pass the campaign details to the user.
      if (Object.keys(query).length == 0) {
        if (!localStorage.getItem("winery_id"))
          this.props.history.push("/sign-in", { redirectTo: "/wineries" });
        else
          this.props.history.push("/sign-in", {
            redirectTo:
              "/" +
              convertToCamelCase(localStorage.getItem("winery_name")) +
              "/wines",
          });
      } else this.props.history.push("/sign-in", { queryString: query });
    }
    if (this.state.gotoNextPageStatus) {
      // return <Redirect push to="/user-taste-preference" />;
      let setPasswordPageUrl =
        "/" +
        this.state.consumerEmailIdFromUrl +
        "/set-password/" +
        this.state.urlToken;
      this.props.history.push(setPasswordPageUrl, { queryString });
    }
  }

  showSignUpFromTerms = () => {
    this.setState({
      showTermsAndConditions: false,
    });
  };
  cancelClickFromTerms = () => {
    this.setState({
      showTermsAndConditions: false,
    });
  };
  acceptTermsAndConditions = () => {
    this.setState({
      showTermsAndConditions: false,
      agree: true,
      agreeFormError: "",
    });
  };

  termsAndConditionClick = (e) => {
    e.preventDefault();
    this.setState({
      showTermsAndConditions: true,
      showTerms: true,
      showPrivacy: false,
    });
  };
  privacyClick = (e) => {
    e.preventDefault();
    this.setState({
      showTermsAndConditions: true,
      showTerms: false,
      showPrivacy: true,
    });
  };
  // onClickToSignIn = () => {
  //   console.log('Sign In');
  //   // GoogleAnalyticsFunctions.clickedSignUpFromInviteWinery();
  //   GoogleAnalyticsFunctions.clickedSetPasswordSubmit();
  //   this.props.signoutAction.ResetApp();
  // }

  singUpExistingUser = (data) => {
    console.log(data);
    let existingSignUpCustomerUrl =
      constants.IDENTITY_API + "/auth/v1/customer/existingSignUp";
    try {
      fetch(existingSignUpCustomerUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          //   if successful, update state
          // console.log(response);
          if (!response.errors) {
            // console.log(response);
            this.setState({
              signUpApiResponseDetails: response,
              signUpNewUserCompleted: true,
            });
            // console.log(this.state.signUpApiResponseDetails);
          } else {
            // console.log(response);
            // console.log(response.errors);
            if (response.errors.length > 0) {
              response.errors.forEach((element) => {
                // const {param} = element
                // const errState = param + 'FormError'
                // this.setState({
                //     [errState]: element.message
                // })
                // if (element.param === "email") {
                //   this.setState({
                //     emailFormError: element.message,
                //   });
                // }
                if (element.param === "firstName") {
                  this.setState({
                    firstNameFormError: element.message,
                  });
                }
                if (element.param === "lastName") {
                  this.setState({
                    lastNameFormError: element.message,
                  });
                }
                if (element.param === "password") {
                  this.setState({
                    confirmPasswordCommonError: element.message,
                  });
                }
                if (element.param === "confirmPassword") {
                  this.setState({
                    confirmPasswordCommonError: element.message,
                  });
                }
                if (element.param === "dateOfBirth") {
                  this.setState({
                    dobFormError: element.message,
                  });
                }
              });
              // this.setState({
              //     formError: "* The above form has errors. ",
              // });
            }
          }
          this.onClickToSignIn();
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            signUpNewUserCompleted: false,
            formError: "Something went wrong. Please try again later. ",
          });
        });
    } catch (e) {
      // console.log(e);
      this.setState({
        signUpNewUserCompleted: false,
        formError: "Something went wrong. Please try again later. ",
      });
    }
    // this.setState({
    //     signUpNewUserCompleted: true
    // })
  };
  onClickToSignIn = () => {
    // console.log('Let\'s get started', this.state.signUpApiResponseDetails);
    GoogleAnalyticsFunctions.clickedSetPasswordSubmit();
    this.props.signoutAction.ResetApp();
    if (this.state.signUpApiResponseDetails) {
      if (
        this.state.signUpApiResponseDetails.user &&
        this.state.signUpApiResponseDetails.user.pairanything_status.name ==
          "active"
      ) {
        localStorage.clear();
        localStorage.setItem(
          "signin_token",
          JSON.stringify(this.state.signUpApiResponseDetails.token)
        );
        localStorage.setItem(
          "user_data",
          JSON.stringify(this.state.signUpApiResponseDetails.user)
        );
        localStorage.setItem(
          "user_id",
          JSON.stringify(this.state.signUpApiResponseDetails.user.id)
        );
        localStorage.setItem(
          "winery_id",
          JSON.stringify(this.state.signUpApiResponseDetails.wineryId)
        );
        localStorage.setItem(
          "reward_points",
          JSON.stringify(this.state.signUpApiResponseDetails.rewardPoints)
        );
        localStorage.setItem(
          "winery_list",
          JSON.stringify(this.state.signUpApiResponseDetails.user.wineryData)
        );
        localStorage.setItem("ageConfirmed", true);

        let signinWineryUserStatus = "";
        let disabledWineryCount = 0;
        for (
          let i = 0;
          i < this.state.signUpApiResponseDetails.user.wineryData.length;
          ++i
        ) {
          if (
            this.state.signUpApiResponseDetails.user.wineryData[i]
              .wineryUserStatus == 3
          ) {
            disabledWineryCount++;
          }
        }
        if (this.state.signUpApiResponseDetails.user.wineryData.length == 1) {
          localStorage.setItem(
            "winery_name",
            this.state.signUpApiResponseDetails.user.wineryData[0].displayName
          );
          localStorage.setItem(
            "winery_id",
            JSON.stringify(
              this.state.signUpApiResponseDetails.user.wineryData[0].wineryId
            )
          );
        }
        if (
          this.state.signUpApiResponseDetails.user.wineryData.length == 1 &&
          this.state.signUpApiResponseDetails.user.wineryData[0]
            .wineryUserStatus == 2
        ) {
          signinWineryUserStatus = "single winery-enabled";
        } else if (
          this.state.signUpApiResponseDetails.user.wineryData.length == 1 &&
          this.state.signUpApiResponseDetails.user.wineryData[0]
            .wineryUserStatus == 3
        ) {
          // user is part of 1 winery and is disabled in that
          localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "single winery-disabled";
          // return {
          //   inValidMessageFromResponse: 'Your winery has disabled you. Please contact the winery admin.'
          // }
        } else if (
          disabledWineryCount ==
          this.state.signUpApiResponseDetails.user.wineryData.length
        ) {
          // user is disabled in all wineries which he/she is a part of
          localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "multiple winery-disabled";
        }

        //navigate to the winery list that sent the email campaign
        if (
          Object.keys(queryString.parse(this.props.location.search)).length > 0
        )
          fetchCampaignDetails(
            queryString.parse(this.props.location.search),
            this.props.history
          );
        // this.props.history.push("/available-wines");
        else {
          if (this.state.signUpApiResponseDetails.user.wineryData.length == 1)
            this.props.history.push(
              "/" +
                convertToCamelCase(localStorage.getItem("winery_name")) +
                "/wines"
            );
          else {
            this.props.history.push("/discover");
          } // This is the first page as per PAIR-1895
        }
        // Commenting below logic - now as per PAIR-1895 Customer passport Discover page is the first page

        // if (signinWineryUserStatus == 'single winery-enabled') {
        //   //user is a member of one winery and that wineryuser status is enabled
        //   let wineryId = JSON.parse(localStorage.getItem('winery_id'));
        //   let url = '/search-wine' + '/' + wineryId;
        //   this.props.history.push(url);
        // } else if (signinWineryUserStatus == 'single winery-disabled') {
        //   //user is a member of one winery and that winery is disabled
        //   this.props.history.push('/profile');
        // } else if (signinWineryUserStatus == 'multiple winery-disabled') {
        //   this.props.history.push('/profile');
        // } else {
        //   this.props.history.push('/select-winery');
        // }
      } else {
        // console.log(this.state.signUpApiResponseDetails);
      }
    }
  };

  googleResponse = async (googleValues) => {
    const {
      tokenId: idToken,
      accessToken,
      googleId,
      profileObj,
    } = googleValues;
    const { email, familyName: lastName, givenName: firstName } = profileObj;
    this.setState({
      email,
      firstName,
      lastName,
    });
    try {
      const { data: googleData } = await axios({
        url: `${constants.IDENTITY_API}/auth/v1/google/existing-signup`,
        method: "post",
        data: {
          idToken,
          accessToken,
          googleId,
          onBoardingChannelWineryId: this.state.onBoardingChannelWineryId,
          refUserId: this.state.userId,
          emailCampaignId: this.state.emailCampaignId,
        },
      });
      GoogleAnalyticsFunctions.clickedGoogleSignIn();
      this.setState({
        firstName: firstName,
        lastName: lastName,
        email: email,
        dob: googleData.user.dateOfBirth,
        signUpNewUserCompleted: true,
        signUpApiResponseDetails: googleData,
      });
    } catch ({ response }) {
      if (response.status === 409) {
        this.setState(
          {
            signUpApiResponseDetails: response.data,
            firstName: firstName,
            lastName: lastName,
            email: email,
            dob: response.data.user.dateOfBirth,
          },
          () => {
            this.onClickToSignIn();
          }
        );
        return;
      }
      if (response.status === 404 || response.status === 500) {
        toast.info("Please try again after a while.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (response.status === 401) {
        const errMsg =
          response.data.message.length > 0
            ? response.data.message
            : "Please try again after a while.";
        this.setState({
          inValidMessageFromResponse: errMsg,
        });
        return;
      } else {
        response.data.errors.map(({ message, param }, index) => {
          if (param) {
            const errorParam = param + "FormError";
            this.setState({
              ...this.state,
              [errorParam]: message,
            });
          }
        });
      }
    }
  };

  facebookResponse = async (facebookValues) => {
    const { accessToken, userID } = facebookValues;
    try {
      const { data: facebookData } = await axios({
        url: `${constants.IDENTITY_API}/auth/v1/facebook/existing-signup`,
        method: "post",
        data: {
          accessToken,
          userID,
          onBoardingChannelWineryId: this.state.onBoardingChannelWineryId,
          refUserId: this.state.userId,
          emailCampaignId: this.state.emailCampaignId,
        },
      });
      this.setState({
        firstName: facebookData.user.firstName,
        lastName: facebookData.user.lastName,
        email: facebookData.user.email,
        dob: facebookData.user.dateOfBirth,
        signUpNewUserCompleted: true,
        signUpApiResponseDetails: facebookData,
      });
      this.onClickToSignIn();
    } catch ({ response }) {
      if (response.status === 409) {
        this.setState({
          signUpApiResponseDetails: response.data,
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          dob: response.data.user.dateOfBirth,
        });
        this.onClickToSignIn();
        return;
      }
      if (response.status === 404 || response.status === 500) {
        toast.info("Please try again after a while.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (response.status === 401) {
        const errMsg =
          response.data.message.length > 0
            ? response.data.message
            : "Please try again after a while.";
        this.setState({
          inValidMessageFromResponse: errMsg,
        });
        return;
      } else {
        response.data.errors.map(({ message, param }, index) => {
          if (param) {
            const errorParam = param + "FormError";
            if (message === "Email is Required") {
              this.setState({
                ...this.state,
                [errorParam]: message,
              });
              toast.info(message, {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            } else {
              this.setState({
                ...this.state,
                [errorParam]: message,
              });
            }
          }
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.signUpNewUserCompleted &&
          !this.state.showTermsAndConditions && (
            <div className="date-of-birth-check-card-background-container">
              <div className="date-of-birth-check-main-container dob-verification-container-padding">
                {/* <div className="logo-container">
              <img alt="PairAnything" className="logo" src={logo} />
            </div> */}
                <div className="logo-container new-paddin-style-logo-header">
                  <img
                    alt="PairAnything"
                    className="logo new-pa-logo-width"
                    src={logo}
                  />
                </div>
                {this.renderUserEntryDetails()}
              </div>
            </div>
          )}
        {this.state.showTermsAndConditions && (
          <TermsAndConditions
            showSignUpFromTerms={this.showSignUpFromTerms}
            acceptTermsAndConditions={this.acceptTermsAndConditions}
            cancelClickFromTerms={this.cancelClickFromTerms}
            showPrivacy={this.state.showPrivacy}
            showTerms={this.state.showTerms}
          />
        )}
        {/* {this.state.signUpNewUserCompleted &&
          !this.state.showTermsAndConditions && (
            <SingUpPasswordSuccess
              onClickToSignIn={this.onClickToSignIn}
              signUpSuccesWineImage={signUpSuccesWineImage}
            />
          )} */}
      </React.Fragment>
    );
  }

  renderUserEntryDetails() {
    const {
      userPairAnythingStatus,
      isValidToken,
      userWineryStatus,
      commonErrorDisplay,
    } = this.state;
    if (userPairAnythingStatus === "invited" && isValidToken === true) {
      return (
        <React.Fragment>
          {/* <div className="date-of-birth-check-container"> */}
          {/* <div
              className="date-of-birth-check-title"
              style={{
                marginBottom: "1em",
              }}
            > */}
          {/* <span className="date-of-birth-check-text1"> Sign Up</span> */}
          {/* <span className="date-of-birth-check-text2">
                {" "}
                You must be 21 years of age or older to enter.
              </span> */}
          {/* </div> */}
          <div
            className="new-sing-up-address-container"
            // style={{ marginTop: "0em" }}
          >
            <form className="sign-up-form mt-2">
              {/* <div className="singup-form-card"> */}
              {/* <div className="shipping-address-card-header">
                  <img alt="user profile pic" src={userProfileImage} />
                  <span className="form-row-label-header">
                    Personal Details
                  </span>
                </div> */}
              {/* <div className="form-group"> */}
              {/* <label className="form-row-label">Name</label> */}
              {/* <p>{this.state.firstName} {this.state.lastName}</p> */}
              {/* <input
                      type="name"
                      className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                      name="firstName"
                      value={
                        this.state.firstName + " " + this.state.lastName || ""
                      }
                      onChange={this.handleChange}
                      onBlur={this.handleAllFieldBlur}
                      placeholder="First Name"
                      disabled
                    />

                    {this.state.firstNameFormError != "" ? (
                      <span className="form-error-shipping-address new-error-msg-font">
                        {this.state.firstNameFormError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}

              {/* <div className="form-group">
                    <label className="form-row-label">Last Name</label>
                    <input
                      type="name"
                      className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                      name="lastName"
                      value={this.state.lastName || ""}
                      onChange={this.handleChange}
                      onBlur={this.handleAllFieldBlur}
                      placeholder="Last Name"
                    />

                    {this.state.lastNameFormError != "" ? (
                      <span className="form-error-shipping-address new-error-msg-font">
                        {this.state.lastNameFormError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}

              {/* <div className="form-group">
                    <label className="form-row-label new-signup-label">Email</label>
                    <input
                      type="text"
                      className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                      name="email"
                      value={this.state.consumerEmailIdFromUrl}
                      // onChange={this.handleChange}
                      // onBlur={this.handleAllFieldBlur}
                      placeholder="Email"
                      readOnly={true}
                    />
                    {this.state.emailFormError != "" ? (
                      <span className="form-error-shipping-address new-error-msg-font">
                        {this.state.emailFormError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}

              <div className="form-group" stye={{ width: "100%" }}>
                <label className="form-row-label new-signup-label">
                  Please enter a password for your account:
                </label>
                <input
                  className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                  type="password"
                  name="password"
                  style={{ marginBottom: "10px" }}
                  value={this.state.password}
                  onChange={this.handleChange}
                  // onBlur={this.handleAllFieldBlur}
                  placeholder="Password"
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {this.state.passwordFormError != "" ? (
                    <span className=" passErrorFont form-error-shipping-address">
                      {this.state.passwordFormError}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="terms-and-conditions">
                    By clicking on the Submit button you verify that you are 21
                    years of age or older and agree to our{" "}
                    <Link to="/terms-of-use">Terms of Use </Link> and
                    <Link to="/privacy-policy"> Privacy Policy.</Link>
                  </div>
                </div>
              </div>

              {/* <div className="form-group dob-before-margin-bottom">
                    <label className="form-row-label new-signup-label">Confirm Password</label>
                    <input
                      className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                      type="password"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                      // onBlur={this.handleAllFieldBlur}
                      placeholder="Confirm Password"
                    />
                    {this.state.confirmPasswordCommonError != "" ? (
                      <span className="form-error-shipping-address new-error-msg-font">
                        {this.state.confirmPasswordCommonError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}

              {/* <label className="form-row-label new-signup-label dob-fnt-size new-grey-font ft-weight-normal">
                    DOB
                  </label>
                  <span className="dob-msg-css">
                    (You must be 21 years of age or older to enter)
                  </span> */}
              {/* <div className="col-xs-12 col-sm-12 p-0">
                    <div className="col-xs-12 col-sm-12 mt-5">
                    <div className={this.getDobMonthFormGroupClasses()}>
                      <select
                        className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                        name="dobMonth"
                        value={this.state.dobMonth}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Month
                        </option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                    <div className={this.getDobDayFormGroupClasses()}>
                      <input
                        type="number"
                        className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                        name="dobDay"
                        placeholder="Day"
                        maxLength="2"
                        value={this.state.dobDay}
                        onChange={this.handleChange}
                        onBlur={this.handleDayBlur}
                      />
                    </div>
                    <div className={this.getDobYearFormGroupClasses()}>
                      <input
                        type="number"
                        className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                        name="dobYear"
                        maxLength="4"
                        placeholder="Year"
                        value={this.state.dobYear}
                        onChange={this.handleChange}
                      />
                    </div>
                    {this.state.dobFormError != "" ? (
                      <span className="dob-error-message-text new-error-msg-font">
                        {this.state.dobFormError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}

              {/* {this.state.agreeFormError != "" ? (
                <span className="form-error-shipping-address new-error-msg-font">
                  {this.state.agreeFormError}
                </span>
              ) : (
                ""
              )} */}
              {/* </div> */}
            </form>

            {/* </div> */}

            {/* {this.state.dobError && (
            <div className="form-error-shipping-address-container">
              <span className="form-error-shipping-addressnew-error-msg-font  mb-2">
                {this.state.dobError}
              </span>
            </div>
          )}

          {this.state.formError && (
            <div className="form-error-shipping-address-container">
              <span className="form-error-shipping-address mb-2">
                {this.state.formError}
              </span>
            </div>
          )} */}

            <div className="signup-button-div">
              <button
                type="button"
                onClick={this.submitUserDetails}
                //  disabled={!this.state.formValid}
                className="btn btn-round signup-button"
              >
                Submit
              </button>
            </div>
          </div>
          {/* <div
            className="col-xs-12 col-sm-12 p-0 social-media-container-signin"
            style={{
              marginTop: "2em !important",
              marginBottom: "0em !important",
            }}
          >
            <GoogleButton
              scope={"https://www.googleapis.com/auth/user.birthday.read"}
              // cookiePolicy={'None'}
              clientId={constants.GOOGLE_CLIENT_ID}
              disabled={false}
              // isMobile={isMobile}
              onSuccess={this.googleResponse}
            />
            <FaceBookButton
              appId={constants.FACEBOOK_APP_ID}
              isDisabled={false}
              scope={"public_profile, email, user_birthday, user_age_range"}
              fields={"name,email,picture,birthday,age-range"}
              cssClass={"facebook-btn"}
              autoLoad={false}
              textButton={"Facebook"}
              // isMobile={isMobile}
              callback={this.facebookResponse}
            />
          </div> */}
          {/* <div className="submit-dob-container">
            <button
              type="button"
              onClick={this.submitUserDetails}
              className="btn btn-round confirm-dob-btn"
            >
              Next
            </button>
          </div> */}
        </React.Fragment>
      );
    } else if (isValidToken === false) {
      return (
        <div className="form-error-shipping-address-container">
          <span className="form-error-shipping-address mb-2">
            Oops, Sorry your token is not valid or expired.
          </span>
        </div>
      );
    } else if (userPairAnythingStatus === "inactive") {
      // If user is disabled by that winery admin or disable by pair anything admin he cannot access account.
      return (
        <div className="form-error-shipping-address-container">
          <span className="form-error-shipping-address mb-2">
            You have no access to setup your account. Please contact the winery
            admin.
          </span>
        </div>
      );
    } else if (commonErrorDisplay != "") {
      return (
        <div className="form-error-shipping-address-container">
          <span className="form-error-shipping-address mb-2">
            {commonErrorDisplay}
          </span>
        </div>
      );
    }
  }
  parseDateStringAndSetStateFields = (date) => {
    if (date != null || date != undefined) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let obj = {
        month: month,
        day: day,
        year: year,
        fullDate: [month, day, year].join("/"),
      };
      // obj.fullDate = [month, day, year].join("/");
      return obj;
    } else {
      return "";
    }
  };

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  getDobMonthFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 pl-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  getDobDayFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 p-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  getDobYearFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 pr-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signoutAction: bindActionCreators(signoutAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DateOfBirthCheck);
