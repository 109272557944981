import React, { Component } from "react";
import "./RecommendationHistoryCardAccordian.scss";
import { isMobile } from "react-device-detect";
import * as constants from "../../constants/constants";
import { withRouter } from "react-router-dom";
import RateRecommendationPopup from "../../components/RateRecommendationPopup/RateRecommendationPopup";
import ShareButton from "../../components/ShareButton/ShareButton";
import moment from "moment";
let ratingStatus = 0;

class RecommendationHistoryCardAccordian extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: true,
      responseForQuestion: "",
      recommendationPageStatus: false,
      rateRecommendationPageStatus: false,
      showRateRecommendationPopup: false,
      rating: 1,
      sample: 0,
    };
  }

  componentDidMount = () => {};

  parseISOString(s) {
    if ((s != null || s != undefined) && s.includes("-")) {
      var b = s.split(/\D+/);
      return new Date(
        Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])
      ).toLocaleTimeString();
      // toLocaleTimeString is for hh:mm:ss
    }
  }

  renderTime = (recommendationQueryData) => {
    let result = [];

    if (recommendationQueryData.recommendationTimestamp) {
      let date = this.parseISOString(
        recommendationQueryData.recommendationTimestamp
      );

      let date1 = new Date(recommendationQueryData.recommendationTimestamp);

      let mm = date1.getMonth() + 1;
      let dd = date1.getDate();
      let yy = date1.getFullYear();

      const month = date1.toLocaleString("default", { month: "long" });
      let i = 0;
      result.push(
        <div key={i++} className="">
          <span className="recommendation-history-card-accordion-date">
            {mm + "/" + dd + "/" + yy}
          </span>
          <span className="recommendation-history-card-accordion-date">
            {/* {date} */}
          </span>
        </div>
      );
    } else if (recommendationQueryData && recommendationQueryData.createdAt) {
      var date = moment
        .utc(recommendationQueryData.createdAt)
        .format("MM/DD/YYYY");
      let i = 0;
      result.push(
        <div key={i++} className="">
          <span className="recommendation-history-card-accordion-date">
            {date}
          </span>
        </div>
      );
    }

    return result;
  };

  gotoRecommendedWinePage = () => {
    if (isMobile) {
      this.setState({
        recommendationPageStatus: true,
      });
    }
  };
  rate(rating) {
    this.setState({
      rating: rating,
      sample: 1,
    });
  }

  saveRecommendationRating = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    var date = new Date();
    var timeStamp = date.toISOString();
    let questionId = this.props.recommendationQueryData.id;
    let putRatingStarApiUrl =
      constants.IDENTITY_API +
      "/api/v1/recommendation/question/consumer/" +
      questionId +
      "?wineryId=" +
      wineryId;

    let apiBodyData = {
      pairingRating: this.state.rating,
      pairingRatingTimestamp: timeStamp,
    };

    fetch(putRatingStarApiUrl, {
      // dev
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiBodyData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        ratingStatus = 0;
      })
      .catch((error) => console.error(error));
  };

  displayExistingRating = () => {
    if (
      this.props.recommendationQueryData.pairingRating != null &&
      this.props.recommendationQueryData.pairingRating != undefined &&
      this.props.recommendationQueryData.pairingRating > 0
    ) {
      let result = [];
      let ratingText = "";
      if (this.props.recommendationQueryData.pairingRating == 5) {
        ratingText = "Loved it";
      } else if (this.props.recommendationQueryData.pairingRating == 4) {
        ratingText = "Very Good";
      } else if (this.props.recommendationQueryData.pairingRating == 3) {
        ratingText = "Good";
      } else if (this.props.recommendationQueryData.pairingRating == 2) {
        ratingText = "Moderate";
      } else if (this.props.recommendationQueryData.pairingRating == 1) {
        ratingText = "Bad";
      }

      var ratingStarClass =
        "star-rating__star rating-star-accordion is-selected";

      result.push(
        <div className="already-rated-ratingbar" key="Rating Bar">
          <label className={ratingStarClass}>★</label>
          <label className="already-rated-rating">
            {Math.floor(this.props.recommendationQueryData.pairingRating)}
          </label>
          <label className="already-rated-rating">
            {ratingText != "" ? "  - " + ratingText : ""}
          </label>
        </div>
      );

      return result;
    }
  };

  displayRatingBar = (count) => {
    let stars = [];

    for (var i = 1; i <= count; i++) {
      var ratingStarClass = "star-rating__star";

      if (
        this.state.sample != 0 &&
        this.state.rating >= i &&
        this.state.rating != null
      ) {
        ratingStatus = 1;
        ratingStarClass += " is-selected";
      }

      stars.push(
        <label className={ratingStarClass} onClick={this.rate.bind(this, i)}>
          ★
        </label>
      );
    }

    if (ratingStatus == 1) {
      this.saveRecommendationRating();
    }
    return stars;
  };

  gotoRateRecommendationPage = () => {
    if (isMobile) {
      this.setState({
        rateRecommendationPageStatus: true,
      });
    } else {
      this.setState({
        showRateRecommendationPopup: true,
      });
    }
  };

  exitRateRecommendation = () => {
    this.setState({
      showRateRecommendationPopup: false,
    });
    this.props.history.go(0);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rateRecommendationPageStatus) {
      //   return <Redirect to={{
      //     pathname: '/rate-recommendedation/' + this.props.recommendationQueryData.id,
      //     state: {
      //               recommendationData: this.props.recommendationQueryData
      //           }

      // }}/>;
      this.props.history.push({
        pathname:
          "/rate-recommendedation/" + this.props.recommendationQueryData.id,
        state: {
          recommendationData: this.props.recommendationQueryData,
        },
      });
    } else if (this.state.recommendationPageStatus) {
      try {
        let wineryId = JSON.parse(localStorage.getItem("winery_id"));

        // return <Redirect to={{
        //     pathname: '/winery/' + wineryId  + '/recommended-wines/' + this.props.recommendationQueryData.id,
        //     state: {
        //               recommendationHistoryData: this.props.recommendationQueryData,
        //               questionId: this.props.recommendationQueryData.id
        //           }

        // }}/>;
        this.props.history.push({
          pathname:
            "/winery/" +
            wineryId +
            "/recommended-wines/" +
            this.props.recommendationQueryData.id,
          state: {
            recommendationHistoryData: this.props.recommendationQueryData,
            questionId: this.props.recommendationQueryData.id,
          },
        });
      } catch (e) {}
    }
  }
  
  render() {
    let  consumerData = JSON.parse(localStorage.getItem("user_data"));
    const {
      props: { recommendationQueryData },
      state: { opened },
    } = this;
    return (
      /*
                          #NOTE:
                                 We are checking if the API Call is done SearchWineForDish page so 
                                 that an empty RecommendationHistoryCard isn't displayed before 
                                 the API Data is displayed.
                                 apiCallDone props value is sent from SearchWineForDish

           */

      this.props.apiCallDone ? (
        <div
          // onClick = {this.gotoRecommendedWinePage.bind(this)}
          // style={
          //   !isMobile && this.props.isSelected
          //     ? {
          //         background: "#FFF3F3 0% 0% no-repeat padding-box",
          //         boxShadow: "2px 2px 16px #00000029",
          //         borderRight: "3px solid#a84551",
          //       }
          //     : null
          // }
          {...{
            className: `card recommendation-history-main-container shadow accordion-item ptr-clss, ${
              opened && "accordion-item--opened"
            }`,

            // onClick : (e) => {

            //   this.getResponseForQuestionFromWineryAdmin(recommendationQueryData.id)

            //   this.setState({
            //     opened: !opened
            //   })
            //   }
          }}
        >
          {/* {console.log(recommendationQueryData.recommendation)} */}
          <div
            {...{
              className: "accordion-item__line",

              onClick: (e) => {
                if (
                  recommendationQueryData &&
                  recommendationQueryData.recommendation
                ) {
                  this.setState({
                    recommendationPageStatus: true, // to enable gotoRecommendedWinePage
                  });
                }
              },
            }}
          >
            <div className="recommendation-history-card-accordion-header">
              {/* {recommendationQueryData && recommendationQueryData.recommendation !== undefined &&
                <span className="recommendation-history-card-dishname">
                  {recommendationQueryData && recommendationQueryData.question && recommendationQueryData.question.length < 25
                    ? recommendationQueryData.question
                    : recommendationQueryData.question.slice(0, 24)}
                </span>}
                {recommendationQueryData && recommendationQueryData.recommendation == undefined &&
                <span className="recommendation-history-card-dishname">
                  {recommendationQueryData.wineType}, {recommendationQueryData.varietalName}
                </span>}

              {this.renderTime(recommendationQueryData)} */}
              {recommendationQueryData &&
              recommendationQueryData.recommendation !== undefined ? (
                <span className="recommendation-history-card-dishname">
                  {recommendationQueryData &&
                    recommendationQueryData.question &&
                    recommendationQueryData.question}
                </span>
              ) : (
                <span className="recommendation-history-card-dishname">
                  {recommendationQueryData.wineType},{" "}
                  {recommendationQueryData.varietalName}
                </span>
              )}
              {this.renderTime(recommendationQueryData)}
            </div>

            {/* <span style={{justifyContent: 'right'}}> */}
            <span
              style={{
                paddingLeft: "80px",
                display: isMobile ? "none" : "block",
              }}
            >
            {recommendationQueryData && recommendationQueryData.recommendation ? (
                <ShareButton
                  wine={recommendationQueryData.recommendation.map(
                    (el, index) => ({
                      name: el.wineName,
                      type: el.wineType,
                      varietal: el.specifications.varietal,
                      appellation: el.specifications.appellation,
                      dish: recommendationQueryData.question,
                      dish_pairing: true,
                      wineryId: JSON.parse(localStorage.getItem('winery_id')),
                      url: `${window.location.origin + "/winery/" + el.wineryId + "/recommended-wines/" + this.props.recommendationQueryData.id}`,
                    })
                  )}
                />
              ) : null}
            </span>
            {/* {
              <span
                {...{
                  className: "accordion-item__icon",

                  onClick: (e) => {
                    // this.getResponseForQuestionFromWineryAdmin(recommendationQueryData.id)

                    this.setState({
                      opened: !opened,
                    });
                    e.stopPropagation(); // to stop parent div onclick to get fired so as to enable arrow up/down
                  },
                }}
              />
            } */}
          </div>

          <div {...{ className: "accordion-item__inner ptr-clss" }}>
            <div
              {...{ className: "accordion-item__content" }}
              onClick={
                recommendationQueryData &&
                recommendationQueryData.recommendation &&
                this.gotoRecommendedWinePage.bind(this)
              }
            >
              <span {...{ className: "accordion-item__paragraph" }}>
                {/* {paragraph} */}

                {recommendationQueryData &&
                recommendationQueryData.recommendation ? (
                  recommendationQueryData.recommendation.map((el, index) => (
                    <div className="accordion-card-inner-content" key={index}>
                      {/* "This wine is not available anymore" */}

                      {el.message ? (
                        el.message == "This wine is not available anymore" ? (
                          ""
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="accordion-card-wine-data">
                          {el && el.wineName !== null && (
                            <div className="accordion-card-wine-name-container">
                              <div className="li-dot-container">
                                <div className={"li-dot"}></div>
                              </div>
                              <div className={"accordion-card-wine-name"}>
                                {/* Jana Cathedral Cabernet Sauvignon */}
                                {el.wineName}
                              </div>
                            </div>
                          )}

                          <span className="accordian-card-wine-specifications">
                            {el.wineType}
                            {/* { ( (el.specifications? (el.specifications.varietal!=undefined && el.specifications.varietal!=null)? (el.specifications.varietal : '' ) ) :  '' }  */}
                            {el.specifications
                              ? el.specifications.varietal != undefined &&
                                el.specifications.varietal != null
                                ? ", " + el.specifications.varietal
                                : ""
                              : ""}

                            {el.specifications
                              ? el.specifications.appellation != undefined &&
                                el.specifications.appellation != null
                                ? ", " + el.specifications.appellation
                                : ""
                              : ""}

                            {/* {el.specifications.appellation} */}
                          </span>
                        </div>
                      )}
                      {/* <span className="accordion-card-wine-data">{el.wineDescription}</span> */}
                    </div>
                  ))
                ) : recommendationQueryData &&
                  recommendationQueryData.recommendation == undefined ? (
                  <>
                    {recommendationQueryData &&
                      recommendationQueryData.foodName && (
                        <div className="accordion-card-wine-name-container">
                          <div className="li-dot-container">
                            <div className={"li-dot"}></div>
                          </div>
                          <div className={"accordion-card-wine-name"}>
                            {" "}
                            {/*sss*/}
                            {/* Jana Cathedral Cabernet Sauvignon */}
                            {recommendationQueryData.foodName}
                          </div>
                        </div>
                      )}
                    {recommendationQueryData &&
                      recommendationQueryData.recipeUrl && (
                        <div
                          className="accordion-card-wine-name-container"
                          style={{
                            width: "90% ",
                            paddingTop: "2%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <div className="li-dot-container">
                            <div
                              className={
                                isMobile ? 'li-dot' : 'li-dot-desktop'
                              }
                            ></div>
                          </div> */}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className={"accordion-card-wine-tab-name"}
                          >
                            {/* Jana Cathedral Cabernet Sauvignon */}
                            {recommendationQueryData &&
                            recommendationQueryData.recipeUrl ? (
                              <a
                                className="btn btn-round rate-recommendation-history-btn"
                                href={recommendationQueryData.recipeUrl}
                                style={{ color: "#CD4545", width: "50%" }}
                                target="_blank"
                              >
                                {/* <button
                                  className="btn btn-round rate-recommendation-history-btn"
                                  // style={{
                                  //   width: "90%",
                                  // }}
                                > */}
                                View Recipe
                                {/* </button> */}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                  </>
                ) : (
                  "Please try again later."
                )}

                {/* { recommendationQueryData.recommendation } */}
              </span>
            </div>

            {/* 
              #NOTE: If recommendation has been made, display rating star 
            */}
            {recommendationQueryData &&
            recommendationQueryData.recommendation && consumerData && consumerData!=null? (
              <div className="rating-recommendation-container">
                <span className="rating-recommendation-text">
                  {this.props.recommendationQueryData.pairingRating != null &&
                  this.props.recommendationQueryData.pairingRating !=
                    undefined &&
                  this.props.recommendationQueryData.pairingRating != "" ? (
                    "Your recommendation rating"
                  ) : (
                    //  'Rate the Recommendation'

                    <div className="rate-recommendation-btn-container">
                      <button
                        onClick={this.gotoRateRecommendationPage.bind(this)}
                        className="btn btn-round rate-recommendation-btn"
                      >
                        Rate Recommendation
                      </button>
                    </div>
                  )}
                </span>
                <span>
                  {this.props.recommendationQueryData.pairingRating != null &&
                  this.props.recommendationQueryData.pairingRating !=
                    undefined &&
                  this.props.recommendationQueryData.pairingRating != ""
                    ? this.displayExistingRating()
                    : // this.displayRatingBar(5)
                      ""}
                </span>
                {/* <div
                  className="share-food-pairing-button"
                  style={{ display: isMobile ? "block" : "none" }}
                >
                  <ShareButton
                    wine={recommendationQueryData.recommendation.map(
                      (el, index) => ({
                        name: el.wineName,
                        type: el.wineType,
                        varietal: el.specifications.varietal,
                        appellation: el.specifications.appellation,
                        dish: recommendationQueryData.question,
                        dish_pairing: true,
                        wineryId: JSON.parse(localStorage.getItem("winery_id")),
                        url: `${constants.CONSUMER_APP_URL}`,
                      })
                    )}
                  />
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
          <RateRecommendationPopup
            open={this.state.showRateRecommendationPopup}
            recommendationData={this.props.recommendationQueryData}
            exitRateRecommendation={() => {
              this.exitRateRecommendation();
            }}
            history={this.props.history}
          />
        </div>
      ) : (
        ""
      )
    );
  }
}

RecommendationHistoryCardAccordian = withRouter(
  RecommendationHistoryCardAccordian
);
export default RecommendationHistoryCardAccordian;
