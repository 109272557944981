import React, { Component } from "react";

import "./SingUpPasswordSuccess.scss";

import { Redirect } from "react-router-dom";

import * as constants from "../../constants/constants";
import backBtn from "../../../assets/images/back-btn.svg";

import logo from "../../../assets/images/splash.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import signUpSuccesWineImage from "../../../assets/images/SignUp/wines-signup-image.svg";
toast.configure();

class SingUpPasswordSuccess extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
    };
  }

  componentDidMount = () => { 
		const { initialCallback, showGetStarted } = this.props;
		if (showGetStarted) initialCallback();
	};

  gotoSignInPage = () => {
	};
	


  componentDidUpdate(prevProps, prevState) {

  }

  render() {
    const { onClickToSignIn, signUpSuccesWineImage } = this.props;
    return (
      <div className="set-password-success-container-background font-family">
        <div className="set-password-success-main-container">
          <div className="header">
            <div className="back-btn-container">
              {/* <img className="" src={backBtn} onClick = { this.gotoSignInPage }/> */}
            </div>
            <div className="logo-container">
              <img alt="PairAnything" className="logo-wwidth" src={logo} />
            </div>
            {/* <div className="set-password-title-success">Thank You!</div> */}
          </div>

          <div className='thanks-fonts-style'>
            <div>
              <span>Thank you</span>
            </div>
            <div>
              <span>for signing up to PairAnything!</span>
            </div>
          </div>

          <div className="forgot-password-form-parent">
            <div className="set-password-form-container">
              <div className="">
                <div>
                  <img alt="PairAnythingWineLogo" className="" src={signUpSuccesWineImage} />
                </div>
                <div className="set-password-success-btn-container">
                  <div className="set-password-success-btn-sub-container">
                    {" "}
                    <button
                      type="button"
                      onClick={() => onClickToSignIn()}
                      //   onClick={this.gotoSignInPage}
                      //  disabled={!this.state.formValid}
                      className="btn btn-round set-password-success-btn forgot-password-btn"
                    >
                      Get Started
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className = "set-password-success-btn-container"> 
                <div>  <button type="button" onClick={this.gotoSignInPage}
                                    //  disabled={!this.state.formValid}
                                    className="btn btn-round set-password-success-btn forgot-password-btn">Go to Sign In</button>  
                </div>
            </div> */}
        </div>
      </div>
    );
  }
}

export default SingUpPasswordSuccess;
