export const YOLO_COUNTY_WINERIES = [
    {
        id: 1,
        wineName: 'Baker Family Wines',
        winerUrl: 'https://www.bakerfamilywines.com/new-index',
        wineImage: require('../../../assets/images/Visit/Yolo_County/baker_family_wines.jpeg')
    },
    {
        id: 25,
        wineName: 'Scott Harvey Wines',
        winerUrl: 'https://www.scottharveywines.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Scott_Harvey.jpeg')
    },
    {
        id: 17,
        wineName: 'Heringer Estates',
        winerUrl: 'https://heringerestates.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Heringer_Estates.png')
    },
    {
        id: 2,
        wineName: 'Batia Vineyards',
        winerUrl: 'https://www.batiavineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Batia.png')
    },
    {
        id: 3,
        wineName: 'Berryessa Gap Vineyards',
        winerUrl: 'https://www.berryessagap.com',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Berryessa_Gap.jpeg')
    },
    {
        id: 4,
        wineName: 'Bogle Vineyards & Winery',
        winerUrl: 'https://boglewinery.com/#',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Bogle.png')
    },
    {
        id: 5,
        wineName: 'Bump City Wine Co.',
        winerUrl: 'http://www.bumpcitywineco.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Bump_City.jpg')
    },
    {
        id: 6,
        wineName: 'Capay Valley Vineyards',
        winerUrl: 'http://www.capayvalleyvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Capay_Valley.gif')
    },
    {
        id: 7,
        wineName: 'Carvalho Family Winery',
        winerUrl: 'http://www.carvalhofamilywinery.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Carvalho_Family.jpg')
    },
    {
        id: 8,
        wineName: 'Clarksburg Wine Company',
        winerUrl: 'http://clarksburgwineco.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Clarksburg_Wine.jpg')
    },
    
    {
        id: 10,
        wineName: 'Draconis',
        winerUrl: 'https://www.draconisvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Draconis.png')
    },
    
    {
        id: 11,
        wineName: 'Due Vigne Winery',
        winerUrl: 'https://duevignewinery.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Due_Vigne.png')
    },
    
    {
        id: 12,
        wineName: 'Elevation Ten',
        winerUrl: 'https://www.elevationten.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Elevation_Ten.jpeg')
    },
    
    {
        id: 13,
        wineName: 'Grand Island Vineyards',
        winerUrl: 'http://www.grandislandvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Grand_Island.png')
    },
    {
        id: 14,
        wineName: 'Great Bear Vineyards',
        winerUrl: 'https://greatbearvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Great_Bear.png')
    },
    {
        id: 15,
        wineName: 'Grindstone Wines',
        winerUrl: 'https://www.grindstonewines.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Grindstone.png')
    },
    {
        id: 16,
        wineName: 'Haarmeyer Wine Cellars',
        winerUrl: 'https://haarmeyerwinecellars.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Haarmeyer.png')
    },
   
    {
        id: 18,
        wineName: 'Julietta Winery',
        winerUrl: 'https://juliettawinery.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Julietta.png')
    },
    {
        id: 19,
        wineName: 'Kirchhoff Family Wines',
        winerUrl: 'https://www.kirchhoffwines.com',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Kirchhoff.jpg')
    },
    {
        id: 20,
        wineName: 'Matchbook Wine Company',
        winerUrl: 'https://www.matchbookwines.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Matchbook.jpg')
    },
    {
        id: 21,
        wineName: 'Miner\'s Leap Winery',
        winerUrl: 'https://www.minersleap.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Miners_Leap.png')
    },
    {
        id: 22,
        wineName: 'Putah Creek Winery',
        winerUrl: 'http://www.putahcreekwinery.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Putah_Creek.jpg')
    },
    {
        id: 23,
        wineName: 'Rendez-Vous Winery',
        winerUrl: 'https://www.rendez-vouswinery.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/RendezVous.jpeg')
    },
    {
        id: 24,
        wineName: 'Satiety Winery & Cafe',
        winerUrl: '',
        wineImage: ''
    },
    
    {
        id: 26,
        wineName: 'Scribner Bend Vineyards, Inc',
        winerUrl: 'https://www.scribnerbend.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Scribner.jpg')
    },
    {
        id: 27,
        wineName: 'Seka Hills',
        winerUrl: 'https://www.sekahills.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Seka_Hills.jpg')
    },
    {
        id: 28,
        wineName: 'Seka Hills Tasting Room',
        winerUrl: 'https://www.sekahills.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Seka_Hills.jpg')
    },
    {
        id: 29,
        wineName: 'Silt Wine Company',
        winerUrl: 'https://www.siltwineco.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Silt.png')
    },
    {
        id: 30,
        wineName: 'Simas Family Vineyard',
        winerUrl: 'http://www.simasfamilyvineyard.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Simas_Family.jpg')
    },
    {
        id: 31,
        wineName: 'Sundstrom Hill Winery',
        winerUrl: 'https://sundstromhill.com/',
        wineImage: ''
    },
    {
        id: 32,
        wineName: 'Taber Ranch Vineyard',
        winerUrl: 'https://taberranch.com/',
        wineImage: ''
    },
    {
        id: 33,
        wineName: 'Three Wine Company',
        winerUrl: 'https://threewinecompany.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Three_Wine.jpeg')
    },
    {
        id: 34,
        wineName: 'Todd Taylor',
        winerUrl: 'https://www.toddtaylor.wine/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Todd_Taylor.jpg')
    },
    {
        id: 34,
        wineName: 'Turkovich Family Wines',
        winerUrl: 'https://turkovichwines.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Turkovich.jpg')
    },
    {
        id: 34,
        wineName: 'Wilson Vineyards',
        winerUrl: 'http://www.wilsonvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Wilson.png')
    },
    {
        id: 34,
        wineName: 'Windmill Vineyards',
        winerUrl: 'https://www.windmillvineyards.com/',
        wineImage: require('../../../assets/images/Visit/Yolo_County/Windmill_logo.png')
    },
]

