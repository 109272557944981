import React from "react";

export default function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M4.50879 1.17324C4.29825 0.664672 3.74319 0.393981 3.21275 0.538896L0.806605 1.19512C0.330845 1.32636 0 1.75837 0 2.25054C0 9.01509 5.48491 14.5 12.2495 14.5C12.7416 14.5 13.1736 14.1692 13.3049 13.6934L13.9611 11.2873C14.106 10.7568 13.8353 10.2018 13.3268 9.99121L10.7019 8.89751C10.2562 8.71158 9.73941 8.84009 9.43591 9.21469L8.33127 10.5627C6.40636 9.65217 4.84783 8.09364 3.93733 6.16873L5.28531 5.06682C5.65991 4.76059 5.78842 4.24655 5.60249 3.80086L4.50879 1.17598V1.17324Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
