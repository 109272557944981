import "./CartQtyIncrementDecrement.scss";
import React, { Component } from "react";

class CartQtyIncrementDecrement extends Component {
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps != prevState) {
  //     return nextProps;
  //   }
  // }

  render() {
    let { divStyles, onIncrement, onDecrement, quantity, onChangeQty } = this.props;

    return (
      <React.Fragment>
        <div className={divStyles}>
          <span className="input-group-btn">
            <button
              type="button"
              className="quantity-left-minus btn"
              data-type="minus"
              data-field=""
              onClick={onDecrement}
            >
              <span className="glyphicon glyphicon-minus"></span>
            </button>
          </span>
          <input
            type="text"
            id="quantity"
            name="quantity"
            className="form-control cart-input"
            value={quantity}
            min="1"
            onChange={onChangeQty}
          />
          <span className="input-group-btn">
            <button
              type="button"
              className="quantity-right-plus btn"
              data-type="plus"
              data-field=""
              onClick={onIncrement}
            >
              <span className="glyphicon glyphicon-plus"></span>
            </button>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default CartQtyIncrementDecrement;
