import React from "react";
import LocationCard from "./LocationCard";
import { osName } from "react-device-detect";
import facebookLogo from "../../../assets/images/WineryDetails/facebook.svg";
import instagramLogo from "../../../assets/images/WineryDetails/instagram.svg";
import twitterLogo from "../../../assets/images/WineryDetails/twitter.svg";
const AboutUsCard = ({
  winery,
  finalWineryAddress,
  wineryLocationBtnClicked,
  wineryStreetText,
}) => {
  const mailClicked = () => {
    var newWindow = window.open("mailto:" + winery.wineryMail, "_blank");
    newWindow.focus();
  };
  const phoneClicked = () => {
    var newWindow = window.open("tel:" + winery.wineryPhone, "_blank");
    newWindow.focus();
  };
  const socialMediaIconOnClick = (url) => () => {
    if (url) {
      if (!url.includes("http://") && !url.includes("https://")) {
        url = "https://" + url;
      }
    }

    var newWindow = window.open(url, "_blank");
  };

  const wineryLocation = (item) => {
    const fullAddress =
      item.addressLine1 +
      "" +
      item.addressLine2 +
      "" +
      item.city +
      "," +
      item.stateName +
      "," +
      item.zipcode;

    let modifiedAddr = fullAddress.replace(/&+/g, "");

    if (modifiedAddr !== "") {
      if (osName === "Android") {
        var newWindow = window.open(
          "https://maps.google.com/?q=" + modifiedAddr,
          "_blank"
        );
      } else if (osName === "iOS") {
        var newWindow = window.open(
          "http://maps.apple.com/?q=" + modifiedAddr,
          "_blank"
        );
      } else {
        var newWindow = window.open(
          "https://maps.google.com/?q=" + modifiedAddr,
          "_blank"
        );
      }
      // newWindow.focus();
    }
  };
  return (
    <div className="card card-post card-round winery-details-card shadow p-3 mb-5 bg-white rounded">
      {/* <div className="winery-about-container"> */}
      {winery.wineryAbout && winery.wineryAbout != "" ? (
        <div className="winery-about-title-container">
          <div className="winery-about-title">About us</div>
          <div className="winery-about-text">{winery.wineryAbout} </div>{" "}
        </div>
      ) : null}
      {/* {winery.wineryEmail || winery.wineryPhoneNumber || winery.wineryURL ? (
        <div className="contact-container">
          <span className="winery-contact-title">Contact Us</span>
          {winery.wineryEmail ? (
            <div
              className="winery-contact-contents ptr-clss"
              onClick={() => {
                mailClicked();
              }}
            >
              {winery.wineryEmail}
            </div>
          ) : null}
          {winery.wineryPhoneNumber ? (
            <div>
              <span
                className="winery-about-text ptr-clss"
                onClick={() => phoneClicked()}
              >
                {winery.wineryPhoneNumber}
              </span>
            </div>
          ) : null}
          {winery.wineryURL ? (
            <span
              onClick={socialMediaIconOnClick(winery.wineryURL)}
              className="winery-website-url ptr-clss"
            >
              {winery.wineryURL}{" "}
            </span>
          ) : null}
        </div>
      ) : null} */}
      {/* {winery.wineryFacebook ||
        winery.wineryInstagram ||
        winery.wineryTwitter ? (
          <div>
            <span className="winery-contact-title">Social Media</span>
            <div className="winery-social-media-container ">
              {winery.wineryFacebook ? (
                <img
                  alt="Facebook"
                  onClick={socialMediaIconOnClick(winery.wineryFacebook)}
                  className="social-media-logo ptr-clss"
                  src={facebookLogo}
                />
              ) : (
                ""
              )}

              {winery.wineryInstagram ? (
                <img
                  alt="Instagram"
                  onClick={socialMediaIconOnClick(winery.wineryInstagram)}
                  className="social-media-logo ptr-clss"
                  src={instagramLogo}
                />
              ) : (
                ""
              )}
              {winery.wineryTwitter ? (
                <img
                  alt="Twitter"
                  onClick={socialMediaIconOnClick(winery.wineryTwitter)}
                  className="social-media-logo ptr-clss"
                  src={twitterLogo}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ) : null} */}
      {/* {winery.wineryMessage && (
          <div>
            {" "}
            <div className="winery-message-title">{"Message"}</div>
            <span className="winery-about-message">{winery.wineryMessage}</span>
          </div>
        )} */}
      {/* {finalWineryAddress.length > 0 ? (
          <div>
            <div className="winery-message-title">{"Location"}</div>
            {finalWineryAddress &&
              finalWineryAddress.map((item) => {
                let wineryStreetAddress =
                  item.addressLine1 && item.addressLine1 != null
                    ? item.addressLine1.trim()
                    : "";
                wineryStreetAddress += " ";
                wineryStreetAddress +=
                  item.addressLine2 && item.addressLine2 != null
                    ? item.addressLine2.trim()
                    : "";
                wineryStreetAddress += ", ";
                wineryStreetAddress +=
                  item.city && item.city != null ? item.city.trim() : "";
                wineryStreetAddress += "-";
                wineryStreetAddress += item.zipcode;
                return (
                  <LocationCard
                    wineryLocationBtnClicked={() => wineryLocation(item)}
                    item={item}
                    winery={{
                      wineryStreetAddress,
                    }}
                  />
                );
              })}
          </div>
        ) : (
          <div>
            <div className="winery-message-title">{"Location"}</div>
            <LocationCard
              wineryLocationBtnClicked={wineryLocationBtnClicked}
              winery={{
                wineryPhoneNumber: winery.wineryPhoneNumber,
                wineryStreetAddress: wineryStreetText,
              }}
              wineryStreetText={wineryStreetText}
            />
          </div>
        )} */}
    </div>
    // </div>
  );
};

export default AboutUsCard;
