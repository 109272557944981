import React, { Component } from "react";
import "./ShippingAddress.scss";
import { Redirect } from "react-router-dom";

import backBtn from "../../../assets/images/back-btn.svg";
import userProfileImage from "../../../assets/images/ShippingAddress/user-profile.svg";
import addressImage from "../../../assets/images/ShippingAddress/address.svg";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ShippingAddressAction from "../../redux/actions/ShippingAddressAction";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import * as constants from "../../constants/constants";
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import AlertDialogSlide from "../AlertDialog/AlertDialog";
import moment from "moment";
import redAddIcon from "../../../assets/images/red-add-icon.svg";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import AddressSelectionComponent from "../../components/AddressSelectionComponent/AddressSelectionComponent";
import ShippingAddressFormComponent from "../../components/ShippingAddressFormComponent/ShippingAddressFormComponent";
import InputMask from "react-input-mask";
import NewConfirmCommonPopUp from "../../components/NewConfirmCommonPopup/NewConfirmCommonPopup";
import * as commonFunctions from "../../constants/commonFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "@material-ui/core";
import HeaderWithBurgerIcon from "../CommonComponents/HeaderWithBurgerIcon";
toast.configure();

let consumerData;
let wineryId;
let currentDate;
class ShippingAddress extends Component {
  constructor(props) {
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    wineryId = JSON.parse(localStorage.getItem("winery_id"));
    currentDate = new Date();
    super(props);

    this.state = {
      formError: "",
      firstNameformError: "",
      lastNameformError: "",
      dobFormError: "",
      zipcodeformError: "",
      contactNumberFormError: "",
      shippingAddressDataEdited: false,
      showAddressFillingForm: false,
      shippingAddress: {
        firstName:
          consumerData != undefined && consumerData != null
            ? consumerData.firstName != undefined &&
              consumerData.firstName != null
              ? consumerData.firstName
              : ""
            : "",
        lastName:
          consumerData != undefined && consumerData != null
            ? consumerData.lastName != undefined &&
              consumerData.lastName != null
              ? consumerData.lastName
              : ""
            : "",
        displayName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        contactNumber: "",
        dobMonth: "",
        dobDay: "",
        dobYear: "",
        dob: "",
      },
      backBtnStatus: false,
      backBtnFinalStatus: false,
      saveChangesBtnStatus: false,

      // For shipping address new design and address form popup component.
      addressDetails: [],
      shippingAddressFormData: {
        formErrors: {
          addressFormError: "",
          zipcodeformError: "",
          addressLine1formError: "",
          cityformError: "",
          stateformError: "",
          saveAsformError: "",
        },
        shippingAddressForm: {
          id: "",
          address1: "",
          address2: "",
          city: "",
          stateId: "",
          zipcode: "",
          name: "",
          isPrimary: false,
        },
      },
      showDeleteConfirmPopUp: false,
      removeAddressConfirmText: "Delete",
      removeAddressCancelText: "Cancel",
      deleteHeaderText: "Are you sure you want to delete this address?",
      removeAddressId: "",
      defaultAddressSelectError: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleDayBlur = (event) => {
    let dayCheckValue = event.target.value;
    if (
      dayCheckValue != "" &&
      dayCheckValue != undefined &&
      dayCheckValue.length < 2
    ) {
      dayCheckValue = "0" + dayCheckValue;
      this.setState({
        shippingAddress: {
          ...this.state.shippingAddress,
          dobDay: dayCheckValue,
        },
      });
    }
  };
  handleChange(event) {
    let name = event.target.name;
    let val = event.target.value;

    if (name == "firstName" && this.state.firstNameFormError != "") {
      this.setState({
        firstNameFormError: "",
      });
    }
    if (name == "lastName" && this.state.lastNameFormError != "") {
      this.setState({
        lastNameFormError: "",
      });
    }
    if (name == "zipcode" && this.state.zipcodeFormError != "") {
      this.setState({
        zipcodeFormError: "",
      });
    }

    if (name == "dob" && this.state.dobFormError != "") {
      this.setState({
        dobFormError: "",
      });
    }

    if (name == "dobDay") {
      if (val.length > 2) val = val.slice(0, 2);
    }

    if (name == "dobYear") {
      if (val.length > 4) val = val.slice(0, 4);
    }

    if (name == "contactNumber" && this.state.contactNumberFormError != "") {
      this.setState({
        contactNumberFormError: "",
      });
    }

    this.setState((prevState) => ({
      formError: "",
      shippingAddressDataEdited: true,
      shippingAddress: {
        // object that we are going to update
        ...prevState.shippingAddress, // retains all other state key-value data
        [name]: [val], // update value of specific key
      },
    }));
  }

  // isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val);

  gotoProfile = () => {
    this.setState({
      backBtnStatus: true,
    });
  };

  saveChangesBtn = () => {
    let validForm = true;

    // Below commented zipcode validation not used for new UI logic
    // if (this.state.shippingAddress.zipcode) {
    //   let isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
    //     // let isValidZip = /(^\d{1,6}$)/.test(
    //     this.state.shippingAddress.zipcode
    //   );
    //   let formError = "";
    //   if (!isValidZip) {
    //     validForm = false;

    //     this.setState({
    //       zipcodeFormError:
    //         "Zipcode format is incorrect.",
    //     });
    //   }
    // }

    if (this.state.shippingAddress.contactNumber) {
      if (Array.isArray(this.state.shippingAddress.contactNumber)) {
        this.state.shippingAddress.contactNumber =
          this.state.shippingAddress.contactNumber[0].replace(/_/gi, "");
      }
      if (this.state.shippingAddress.contactNumber.length != 12) {
        validForm = false;
        this.setState({
          contactNumberFormError: "Phone no. format is incorrect.",
        });
      }
    }

    if (!this.state.shippingAddress.firstName) {
      validForm = false;
      this.setState({
        firstNameFormError: "First name cannot be empty.",
      });
    }
    if (!this.state.shippingAddress.lastName) {
      validForm = false;
      this.setState({
        lastNameFormError: "Last name cannot be empty.",
      });
    }
    let dateOfBirth;
    if (
      this.state.shippingAddress.dobMonth != "" &&
      this.state.shippingAddress.dobDay != "" &&
      this.state.shippingAddress.dobYear != ""
    ) {
      dateOfBirth =
        this.state.shippingAddress.dobMonth +
        "/" +
        this.state.shippingAddress.dobDay +
        "/" +
        this.state.shippingAddress.dobYear;
      // this.setState({
      //   shippingAddress: {
      //     dob: dateOfBirth
      //   }
      // });
      let isValidDateFormat = this.checkValidDateFormat(dateOfBirth);
      if (isValidDateFormat) {
        let validDOB = this.calculateAge(dateOfBirth);
        if (validDOB) {
          this.setState({
            validAge: true,
          });
        } else if (!validDOB) {
          validForm = false;
          this.setState({
            dobFormError: "Sorry, you must be atleast 21 years old.",
          });
        }
      } else {
        validForm = false;
        this.setState({
          dobFormError: "Sorry, please enter a valid date.",
        });
      }
    } else {
      validForm = false;
      this.setState({
        dobFormError: "Sorry, please enter a valid date.",
      });
    }

    // Checking if any one of the address is selected or not
    let isPrimaryFalseCount = 0;
    if (this.state.addressDetails.length > 0) {
      this.state.addressDetails.forEach((element) => {
        if (element.isPrimary === false) {
          isPrimaryFalseCount++;
        }
      });
      if (isPrimaryFalseCount === this.state.addressDetails.length) {
        validForm = false;
        this.setState({
          defaultAddressSelectError:
            "* Please select primary address for shipping.",
        });
      }
    }

    if (validForm) {
      this.state.shippingAddress.dob = dateOfBirth;

      this.props.ShippingAddressAction.ShippingAddressUpdateDetails(
        this.state.shippingAddress
      );
      this.setState({
        saveChangesBtnStatus: true,
      });
      GoogleAnalyticsFunctions.clickedUpdatePersonalInfo();
    } else {
      this.setState({
        formError: "* The above form has errors. ",
      });
    }
  };

  updateAddressDefaultSelection = (updateObj, addressId) => {
    GoogleAnalyticsFunctions.onSavePrimaryAddress();
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let updateAddressApiUrl =
        constants.IDENTITY_API + "/api/v1/user/address/" + addressId;
      fetch(updateAddressApiUrl, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(updateObj),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: updateAddressApiUrl,
              operation: "PUT",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            // Currently not doing anything with this success method
          } else {
            // console.log(respJSON.errors);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (e) {
      // console.log(e);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    //nextProps - new val from reducer
    // prevState - state value

    if (nextProps.putShippingAddressSuccess != undefined) {
      // coming from mapStateToProps

      return null;
      //    return {

      //     saveChangesBtnStatus: true

      //         }
    } else {
      return null;
    }

    return null;
  }

  componentDidMount = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let consumerId = consumerData.id;
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    try {
      let getUserDetailsApiUrl =
        constants.IDENTITY_API + "/api/v1/user/getCustomerById/" + consumerId;
      fetch(getUserDetailsApiUrl, {
        // updated new URL
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getUserDetailsApiUrl,
              operation: "GET",
              status: res.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state

          if (json.user.dateOfBirth != null) {
            let date1 = json.user.dateOfBirth;
            // Parsing and setting state for dob input fields
            let dataObjects = this.parseDateStringAndSetStateFields(date1);
            // date1 = date1.slice(1, 11);
            // const yourDate = new Date(date1).toDateString();
            // var dateString = Date.parse(date1);
            // var date14 = moment(date1, "MM/DD/YYYY");
            // var dateObject = date14.toDate();

            this.setState((prevState) => ({
              shippingAddress: {
                // object that we are going to update
                ...prevState.shippingAddress, // retains all other state key-value data
                firstName: json.user.firstName,
                lastName: json.user.lastName ? json.user.lastName : "",
                displayName:
                  json.user.firstName +
                  " " +
                  (json.user.lastName ? json.user.lastName : ""), // update value of specific key
                address1: json.user.address1 ? json.user.address1 : "",
                address2: json.user.address2 ? json.user.address2 : "",
                city: json.user.city ? json.user.city : "",
                state: json.user.state ? json.user.state : "",
                country: json.user.country ? json.user.country : "",
                zipcode: json.user.zipcode ? json.user.zipCode : "",
                contactNumber: json.user.phoneNumber
                  ? json.user.phoneNumber
                  : "",
                dobDay: dataObjects.day,
                dobMonth: dataObjects.month,
                dobYear: dataObjects.year,
                dob: dataObjects.fullDate,
              },
            }));
          }
        })
        .catch((error) => console.error(error));
    } catch (e) {}
    this.onGetShippingAddressDetails(
      consumerData,
      consumerId,
      signInToken,
      bearer
    );
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  onGetShippingAddressDetails = (
    consumerData,
    consumerId,
    signInToken,
    bearer
  ) => {
    try {
      let getUserShippingAddressDetailsApiUrl =
        constants.IDENTITY_API + "/api/v1/user/address";
      fetch(getUserShippingAddressDetailsApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getUserShippingAddressDetailsApiUrl,
              operation: "GET",
              status: res.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          // console.log(json);
          this.setState({
            addressDetails: json.rows,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      // console.log(e);
    }
  };
  parseDateStringAndSetStateFields = (date) => {
    if (date != null || date != undefined) {
      // var d = new Date(date),
      // month = "" + (d.getMonth() + 1),
      // day = "" + d.getDate(),
      // year = d.getFullYear();
      let day = moment.utc(date).format("DD");
      let month = moment.utc(date).format("MM");
      let year = moment.utc(date).format("YYYY");
      // if (month.length < 2) month = "0" + month;
      // if (day.length < 2) day = "0" + day;
      let obj = {
        month: month,
        day: day,
        year: year,
        fullDate: [month, day, year].join("/"),
      };
      // obj.fullDate = [month, day, year].join("/");
      return obj;
    } else {
      return "";
    }
  };
  getShippingAddressStatus = (status) => {
    if (status) {
      // goto profile page
      this.setState({
        backBtnFinalStatus: true,
      });
    } else {
      // window.location.reload();
      this.setState({
        backBtnStatus: false,
      });
    }
  };

  checkValidDateFormat(dob) {
    // let dateRegex = "/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/i";
    // let isValidZip = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/i.test(dob);
    // let date = new Date(dob);
    var aDate = moment(dob, "MM/DD/YYYY", true);
    var isValid = aDate.isValid();
    return isValid;
  }
  calculateAge(dob) {
    let date1 = new Date();
    let date2 = new Date(dob);

    let ageDiff = date1.getUTCFullYear() - date2.getUTCFullYear();

    if (ageDiff == 21) {
      let monthDiff = date1.getUTCMonth() - date2.getUTCMonth();

      if (monthDiff == 0) {
        if (date2.getDate() <= date1.getDate()) {
          return true;
        } else {
          return false;
        }
      } else if (monthDiff > 0) {
        return true;
      } else {
        return false;
      }
    } else if (ageDiff > 21 && ageDiff <= 120) {
      return true;
    } else {
      return false;
    }
  }
  getDobMonthFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 pl-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  getDobDayFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 p-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  getDobYearFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 pr-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.backBtnFinalStatus) {
      // return <Redirect push to="/profile" />;
      this.props.history.push("/profile");
    }
    if (this.state.saveChangesBtnStatus) {
      // return <Redirect push to="/profile" />;
      // this.props.history.push('/profile');
      this.props.history.push({
        pathname: "/profile",
        // search: '?query=abc',
        state: { showLoading: true },
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div
            className="success-inner-container new-discover-inner-container"
            id="parentShoppingCartDiv"
          >
            {" "}
            <HeaderWithBurgerIcon
              title="Personal Info"
              arrowIcon={true}
              goBackFunc={() =>
                this.state.showAddressFillingForm
                  ? this.onCloseShippingAddressForm()
                  : this.props.history.goBack()
              }
            />
            <div className={isMobile ? null : "desktop-div-scroll "}>
              {this.state.showAddressFillingForm ? (
                // <div className="shipping-address-container">
                <ShippingAddressFormComponent
                  fromShippingAddressPage={true}
                  fromShoppingCartShippingAddressPage={false}
                  goBackFromAddressForm={this.onCloseShippingAddressForm}
                  addressDetails={
                    this.state.shippingAddressFormData.shippingAddressForm
                  }
                  // onChangeAddressForm={this.onChangeAddressForm}
                  // onShippingAddressFormSave={this.onShippingAddressSavingForm}
                />
              ) : (
                // </div>
                <div className="shipping-address-main-container">
                  {this.state.backBtnStatus ? (
                    <AlertDialogSlide
                      className="modal-style"
                      shippingAddressStatus={this.getShippingAddressStatus.bind(
                        this
                      )}
                      shippingAddressData="true"
                    />
                  ) : null}

                  <div
                    className="shipping-address-container"
                    style={{ backgroundColor: "#f2f2f2" }}
                  >
                    <form
                      className="shipping-address-form"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="card shadow shipping-address-card">
                        <div className="shipping-address-card-header">
                          <img alt="user profile pic" src={userProfileImage} />
                          <span className="form-row-label-header">
                            User Details
                          </span>
                        </div>
                        <div className="form-group">
                          <label className="form-row-label">First Name</label>
                          <input
                            type="name"
                            className="form-control form-row-input "
                            name="firstName"
                            value={this.state.shippingAddress.firstName || ""}
                            onChange={this.handleChange}
                          />

                          {this.state.firstNameFormError != "" ? (
                            <span className="form-error-shipping-address">
                              {this.state.firstNameFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label className="form-row-label">Last Name</label>
                          <input
                            type="name"
                            className="form-control form-row-input "
                            name="lastName"
                            value={this.state.shippingAddress.lastName || ""}
                            onChange={this.handleChange}
                          />

                          {this.state.lastNameFormError != "" ? (
                            <span className="form-error-shipping-address">
                              {this.state.lastNameFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <label className="form-row-label">DOB</label>
                        <div className="col-xs-12 col-sm-12 p-0">
                          <div className={this.getDobMonthFormGroupClasses()}>
                            <select
                              className="form-control"
                              name="dobMonth"
                              value={this.state.shippingAddress.dobMonth}
                              onChange={this.handleChange}
                            >
                              <option value="" disabled>
                                Month
                              </option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                          </div>
                          <div className={this.getDobDayFormGroupClasses()}>
                            <input
                              type="number"
                              className="form-control"
                              name="dobDay"
                              placeholder="Day"
                              maxLength="2"
                              value={this.state.shippingAddress.dobDay}
                              onChange={this.handleChange}
                              onBlur={this.handleDayBlur}
                            />
                          </div>
                          <div className={this.getDobYearFormGroupClasses()}>
                            <input
                              type="number"
                              className="form-control"
                              name="dobYear"
                              maxLength="4"
                              placeholder="Year"
                              value={this.state.shippingAddress.dobYear}
                              onChange={this.handleChange}
                            />
                          </div>
                          {this.state.dobFormError != "" ? (
                            <span className="form-error-shipping-address">
                              {this.state.dobFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label className="form-row-label">Phone Number</label>
                          <InputMask
                            mask="999-999-9999"
                            className="form-control form-row-input txt-format-none"
                            placeholder="e.g xxx-xxx-xxxx"
                            value={
                              this.state.shippingAddress.contactNumber || ""
                            }
                            name="contactNumber"
                            onChange={this.handleChange}
                          />
                          {this.state.contactNumberFormError != "" ? (
                            <span className="form-error-shipping-address">
                              {this.state.contactNumberFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="shipping-addresses mt-5">
                        <div>
                          <span>Select Shipping Address</span>
                        </div>
                        <div
                          className="add-new-address ptr-clss"
                          onClick={this.onClickAddNewAddress}
                        >
                          <img
                            alt="Add New Address"
                            className="add-new-icon"
                            src={redAddIcon}
                          />
                          <span> Add New</span>
                        </div>
                      </div>

                      {this.state.addressDetails.length > 0 ? (
                        <ErrorBoundary>
                          {this.state.addressDetails.map((el, index) => (
                            <AddressSelectionComponent
                              addressData={el}
                              key={index}
                              onAddressSelect={this.onSelectingAddress}
                              onAddressEdit={this.onAddressEdit}
                              onAddressDelete={this.onAddressDelete}
                            />
                          ))}
                        </ErrorBoundary>
                      ) : (
                        <div className="empty-data mt-4">
                          <span>No address has been added yet.</span>
                        </div>
                      )}
                    </form>

                    {this.state.defaultAddressSelectError && (
                      <div className="form-error-shipping-address-container">
                        <span className="form-error-shipping-address">
                          {this.state.defaultAddressSelectError}
                        </span>
                      </div>
                    )}

                    <div
                      className="confirm-shipping-address-btn-container"
                      style={{ backgroundColor: "#f2f2f2" }}
                    >
                      <button
                        type="button"
                        onClick={this.saveChangesBtn}
                        //  disabled={!this.state.shippingAddressDataEdited}
                        className="btn btn-round confirm-shipping-address-btn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
                    <BottomNavigation />
                  </div>
                </div>
              )}
              {this.state.showDeleteConfirmPopUp && (
                <NewConfirmCommonPopUp
                  open={this.state.showDeleteConfirmPopUp}
                  handleYes={this.handleYes}
                  handleCancel={this.handleCancel}
                  headerText={this.state.deleteHeaderText}
                  confirmText={this.state.removeAddressConfirmText}
                  cancelText={this.state.removeAddressCancelText}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  onSelectingAddress = (adressData) => {
    GoogleAnalyticsFunctions.onSelectDefaultShippingAddress();
    this.setState({
      defaultAddressSelectError: "",
    });
    const addressDetails = [...this.state.addressDetails];
    addressDetails.forEach((element, i) => {
      addressDetails[i] = { ...element };
      if (element.id === adressData.id) {
        addressDetails[i].isPrimary = true; // make selected address isPrimary true
        let updateObj = {
          isPrimary: true,
        };
        // Calling update API when user selects default address
        this.updateAddressDefaultSelection(updateObj, adressData.id);
      } else {
        addressDetails[i].isPrimary = false; // make selected address isPrimary false for other addresses
      }
    });
    this.setState({ addressDetails });
  };

  onAddressEdit = (e, id) => {
    GoogleAnalyticsFunctions.onEditShippingAddress();
    e.stopPropagation();
    const addressData = this.state.addressDetails.find((x) => x.id === id);
    this.setState({
      shippingAddressFormData: {
        shippingAddressForm: {
          id: addressData.id ? addressData.id : "",
          address1: addressData.addressLine1 ? addressData.addressLine1 : "",
          address2: addressData.addressLine2 ? addressData.addressLine2 : "",
          city: addressData.city ? addressData.city : "",
          stateId: addressData.stateId ? addressData.stateId : "",
          zipcode: addressData.zipcode ? addressData.zipcode : "",
          name: addressData.name ? addressData.name : "",
          isPrimary: addressData.isPrimary ? addressData.isPrimary : false,
        },
      },
      showAddressFillingForm: true,
    });
  };
  onAddressDelete = (e, id) => {
    GoogleAnalyticsFunctions.onDeleteShippingAddress();
    e.stopPropagation();
    // console.log('Address Delete Id', id);
    this.setState({
      removeAddressId: id,
      showDeleteConfirmPopUp: true,
    });
  };

  handleYes = () => {
    // console.log('Yes Click');
    this.onDeleteAddressAPICall();
  };
  handleCancel = () => {
    // console.log('Cancel Click');
    this.setState({
      removeAddressId: null,
      showDeleteConfirmPopUp: false,
    });
  };

  onDeleteAddressAPICall = () => {
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let deleteAddressApiUrl =
        constants.IDENTITY_API +
        "/api/v1/user/address/" +
        this.state.removeAddressId;
      fetch(deleteAddressApiUrl, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: deleteAddressApiUrl,
              operation: "DELETE",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            // toast.info("Something went wrong. Please try again later.", {
            //   position: toast.POSITION.BOTTOM_CENTER,
            // });
            // Remove address from state
            this.setState({
              removeAddressId: null,
              showDeleteConfirmPopUp: false,
            });
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            let consumerData = JSON.parse(localStorage.getItem("user_data"));
            let consumerId = consumerData.id;
            let signInToken = JSON.parse(localStorage.getItem("signin_token"));
            let bearer = "Bearer " + signInToken;
            this.onGetShippingAddressDetails(
              consumerData,
              consumerId,
              signInToken,
              bearer
            );
            this.setState({
              removeAddressId: null,
              showDeleteConfirmPopUp: false,
            });
          } else {
            this.setState({
              removeAddressId: null,
              showDeleteConfirmPopUp: false,
            });
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            // console.log(respJSON.errors);
          }
        })
        .catch((error) => {
          this.setState({
            removeAddressId: null,
            showDeleteConfirmPopUp: false,
          });
          toast.info("Something went wrong. Please try again later.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          // console.log(error);
        });
    } catch (e) {
      this.setState({
        removeAddressId: null,
        showDeleteConfirmPopUp: false,
      });
      toast.info("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      // console.log(e);
    }
  };

  onClickAddNewAddress = () => {
    this.setState({
      shippingAddressFormData: {
        shippingAddressForm: {
          id: "",
          address1: "",
          address2: "",
          city: "",
          stateId: "",
          zipcode: "",
          name: "",
          isPrimary: false,
        },
      },
      showAddressFillingForm: true,
    });
  };

  onCloseShippingAddressForm = () => {
    this.setState({
      showAddressFillingForm: false,
    });
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let consumerId = consumerData.id;
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    this.onGetShippingAddressDetails(
      consumerData,
      consumerId,
      signInToken,
      bearer
    );
  };
}

// REDUX

function mapStateToProps(state, props) {
  // getDerivedStateFromProps gets this returned value
  return {
    putShippingAddressSuccess:
      state.ShippingAddressReducer.putShippingAddressSuccess,
    putShippingAddressFail: state.ShippingAddressReducer.putShippingAddressFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ShippingAddressAction: bindActionCreators(ShippingAddressAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);

// export default ShippingAddress;
