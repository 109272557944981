import React from "react";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import styles from "./layout.module.scss";
import { isMobile } from "react-device-detect";

export default function Layout({
  children,
  gray = false,
  white = false,
}) {
  return (
    <div
      className="content-container new-discover-inner-container font-family"
      style={
        gray
          ? { backgroundColor: "#EFEFEF" }
          : white
          ? { backgroundColor: "white" }
          : null
      }
    >
      <div className={`${isMobile ? null : styles.verticalScroll}`}>
        {children}
      </div>
    </div>
  );
}
