import React, { Component } from "react";
import "./CartWineDetailsCard.scss";
import { withRouter } from "react-router-dom";
import CartQtyIncrementDecrement from "../CartQuantityIncrementDecrement/CartQuantityIncrementDecrement";
import winePlaceholder from "../../../assets/images/wine-placeholder.jpg";
import cartDeleteIcon from "../../../assets/images/cart-delete-icon.svg";
import * as commonFunctions from "../../constants/commonFunctions";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";
import greyDeleteIcon from "../../../assets/images/greyDeleteIcon.svg";
import { ToastContainer, toast } from "react-toastify";
// wine_bottle_placeholder1 , wine_bottle_placeholder

toast.configure();
const QTY_DROPDOWN_OPTIONS = [
  {
    value: 1,
    qty: 1,
  },
  {
    value: 2,
    qty: 2,
  },
  {
    value: 3,
    qty: 3,
  },
  {
    value: 4,
    qty: 4,
  },
  {
    value: 5,
    qty: 5,
  },
  {
    value: 6,
    qty: 6,
  },
  {
    value: 7,
    qty: 7,
  },
  {
    value: 8,
    qty: 8,
  },
  {
    value: 9,
    qty: 9,
  },
  {
    value: '10+',
    qty: '10+',
  }
]
class CartWineDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualWineDataPageStatus: false,
      loaded: false,
      wineQuantity: '',
      addedToCart: false,
      quantity_dropdown_options: QTY_DROPDOWN_OPTIONS,
      showQtyInputBox: false,
      showUpdateBtn: false,
      numberRegex: /^[1-9]+[0-9]*$/
    };
  }

  componentDidMount = () => {
    const isMounted = true;
    if (isMounted && this.props.wineData.quantity) {
      this.setState({
        wineQuantity: this.props.wineData.quantity,
        showQtyInputBox: this.props.wineData.quantity > 9 ? true : false
      });
    }
  };

  skipPreferencesOnClick = () => { };

  gotoPrevScreen = () => { };

  viewIndividualWineDetails = (e) => {
    // GoogleAnalyticsFunctions.clickedRecommendationForDish();
    // console.log(e);
    e.preventDefault();
    this.setState({
      individualWineDetailsPageLoaded: true,
    });
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.state.individualWineDetailsPageLoaded) {
      this.props.history.push({
        pathname: "/individual-wine-details",
        state: {
          wineIndividualData: this.props.wineData.wine,
        },
      });
    }
    if (prevProps.wineData.quantity !== this.props.wineData.quantity) {
      this.setState({
        wineQuantity: this.props.wineData.quantity,
        showQtyInputBox: this.props.wineData.quantity > 9 ? true : false
      });
    }
  }

  render() {
    let qtyOptionTemplate = this.state.quantity_dropdown_options.map((v, i) => (
      <option key={i} value={v.value}>{v.qty}</option>
    ));
    // console.log(this.props.wineData);
    const wineObject = this.props.wineData;
    // console.log(wineObject);
    return (
      // <span>
      //     This wine is not available anymore
      // </span>
      <div className="cart-wine-detail-container">
        <div className="wine-details ft-size-wine-details mt-3 mb-3">
          {/* <div className="wine-details-data-holder"> */}

          {/* <div className="col-sm-12 col-xs-12 cart-delete-icon">
              <img
                alt="Delete"
                className="cart-delete ptr-clss"
                src={greyDeleteIcon}
                onClick={(e) => {this.props.removeWineFromCart(e, this.props.wineData)}}
              />
            </div> */}
          <div
            className="col-sm-12 col-xs-12 cart-container mb-2 ptr-clss"
          >
            <div className="col-sm-2 col-xs-2 col-lg-2 col-md-2 wine-bottle-desgin pl-0" onClick={(e) => this.viewIndividualWineDetails(e)}>
              <img
                alt="Wine Logo"
                className={
                  wineObject.wine.wineImageSmall
                    ? "wine-bottle-logo-for-api new-wine-width"
                    : "wine-bottle-logo no-wine-image-width"
                }
                src={
                  wineObject.wine.wineImageSmall
                    ? wineObject.wine.wineImageSmall
                    : wineBottlePlaceholder
                }
              />
            </div>
            <div className="col-sm-10 col-xs-10 ol-lg-10 col-md-10 p-0 wine-name-dtls-div">
              <div className="wine-details ft-size-wine-details cursor-text flx-d-none pb-4" onClick={this.skipNavigatingToWineDetails}>
                <span className='ellipsis-wine-name' onClick={(e) => this.viewIndividualWineDetails(e)}>
                  <p>
                    {wineObject.wine
                      ? wineObject.wine.wineName
                      : ""}
                  </p>
                </span>
                <img
                  alt="Delete"
                  className="cart-delete ptr-clss"
                  src={greyDeleteIcon}
                  onClick={(e) => { this.props.removeWineFromCart(e, wineObject) }}
                />
              </div>

              {/* <div className="wine-sub-titles-container cursor-text mt-2" onClick={this.skipNavigatingToWineDetails}>
                <span className="sub-titles" onClick={(e) => this.viewIndividualWineDetails(e)}>
                  {wineObject.wine
                    ? wineObject.wine.wineType
                    : ""} */}
              {/* {wineObject.wine
                      ? wineObject.wine.specifications
                        ? wineObject.wine.specifications
                          .varietal
                          ? ", " +
                          wineObject.wine
                            .specifications.varietal
                          : ""
                        : ""
                      : ""}
                    {wineObject.wine
                      ? wineObject.wine.specifications
                        ? wineObject.wine.specifications
                          .appellation
                          ? ", " +
                          wineObject.wine
                            .specifications.appellation
                          : ""
                        : ""
                      : ""} */}
              {/* </span>
              </div> */}
              {/* <div className="wine-details">
                <span className="sub-titles">
                  {wineObject.wine
                    ? wineObject.wine.specifications
                      ? wineObject.wine.specifications
                          .varietal
                      : ""
                    : ""}
                </span>
                <span className="sub-titles">
                  {wineObject.wine
                    ? wineObject.wine.specifications
                        .appellation
                    : ""}
                </span>
              </div> */}
              {/* <div class="form-group row">
                <label class="col-sm-2 col-form-label text-sm-right text-left">
                  Label
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" />
                </div>
              </div> */}
              {/* <div className="wine-details ft-size-wine-details mt-3">
                <span className="sub-title-price">
                  {wineObject.wine.price
                    ? commonFunctions.formatter.format(
                        wineObject.wine.price[0].price
                      )
                    : " "}
                </span>
              </div> */}
              <div className="wine-qty-details">
                <div className="mr-2">
                  <span className="qty-label">Quantity: </span>
                  {/* <input
                    type="number"
                    className="form-control cart-qty-input input-padding"
                    onClick={this.skipNavigatingToWineDetails}
                    value="1000"
                  /> */}
                </div>
                <div>
                  {this.state.showQtyInputBox ? (
                    <input
                      type="text"
                      className="form-control cart-qty-input input-padding"
                      onClick={this.skipNavigatingToWineDetails}
                      value={this.state.wineQuantity}
                      name='wineQuantity'
                      onChange={(e) => { this.onChangeQty(e) }}
                      onFocus={this.onQtyInputFocus}
                      onKeyDown={this.handleonKeyDown}
                      onBlur={(e) => { this.onCartQtyBlurEvent(e, wineObject) }}
                    />
                  ) : (
                      <select
                        className="form-control qyt-drop-option-input"
                        // name="state"
                        onClick={this.skipNavigatingToWineDetails}
                        value={this.state.wineQuantity}
                        onChange={(e) => { this.onCartDropDownValueChange(e, wineObject) }}
                      >
                        {qtyOptionTemplate}
                      </select>
                    )}
                </div>
                {/* {this.state.showQtyInputBox && this.state.showUpdateBtn && (<div className='pl-3'>
                  <button type="button" onClick={() => this.onUpdateQty(wineObject)} className="btn btn-secondary">Update</button>
                </div>)} */}
                <div>
                  <span className="cross-sign ml-3 mr-1"> X </span>
                </div>
                <div className="price-div">
                  {/* <img
                      alt="Delete"
                      className="cart-delete-newqty-row-width"
                      src={cartDeleteIcon}
                      onClick={this.removeWineFromCart}
                    /> */}
                          {wineObject.wine.price[0]
                            .price ? (
                            parseInt(wineObject.discount) !=0 ? (
                              <div className="render-flex">
                                <span className="type-text-price">
                                  {wineObject.wine.price[0].price != null &&
                                  wineObject.wine.price[0].price != undefined
                                    ? commonFunctions.formatter.format(
                                        parseFloat(wineObject.unitPrice) 
                                      )
                                    : " "}
                                </span>
                                <span className="spot-wine-price-text strike-text cart-icon">
                                  <del>
                                    {wineObject.wine.price[0].price !=
                                      null &&
                                      wineObject.wine.price != undefined
                                      ? commonFunctions.formatter.format(
                                        wineObject.wine.price[0]
                                        .price
                                        )
                                      : " "}
                                  </del>
                                </span>
                              </div>
                            ) : (
                              <span className="type-text-price">
                                {wineObject.wine.price[0].price
                                  ? commonFunctions.formatter.format(
                                    wineObject.wine.price[0]
                                    .price
                                    )
                                  : ""}
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        
                  {/* <span className="sub-title-price">
                    {wineObject.wine.price
                      ? commonFunctions.formatter.format(
                        wineObject.wine.price[0].price
                      )
                      : " "}
                    Commenting below code bcx cart table unit price not updating based on wine price updates
                    {wineObject.unitPrice
                      ? commonFunctions.formatter.format(
                        wineObject.unitPrice
                      )
                      : " "}
                  </span> */}
                </div>
              </div>

              {this.state.showQtyInputBox && this.state.showUpdateBtn && (<div className='update-qty'>
                <button type="button" onClick={() => this.onUpdateQty(wineObject)} className="btn btn-secondary update-qty-btn">Update</button>
              </div>)}

              {/* <div className='price-div-new'>
                  <span className="sub-title-price mt-2">
                    {wineObject.wine.price
                      ? commonFunctions.formatter.format(
                        wineObject.wine.price[0]
                          .price
                      )
                      : " "}
                  </span>
                </div> */}

              {/* <div className="col-sm-12 col-xs-12 p-0 mt-3">
                <div className="col-sm-5 col-xs-5 p-0 qty-div">
                  <span className="sub-titles">Qty: </span>
                </div>
                <div className="col-sm-7 col-xs-7 p-0 cart-input-div">
                  <input
                    type="number"
                    className="form-control cart-qty-input"
                    onClick={this.skipNavigatingToWineDetails}
                    value="1"
                  />
                </div>
              </div> */}
            </div>
            {/* <div className="col-sm-1 col-xs-1 p-0 cart-delete-icon">
              <img
                alt="Delete"
                className="cart-delete"
                src={cartDeleteIcon}
                onClick={this.removeWineFromCart}
              />
            </div> */}


          </div>

          {/* <div className="col-sm-12 col-xs-12 mt-2 mb-4 cart-price">
            <span>
              {wineObject.wine.price
                ? commonFunctions.formatter.format(
                    wineObject.wine.price[0].price
                  )
                : " "}{" "}
            </span>
          </div> */}

          {/* <div className="col-sm-12 col-xs-12 mt-4 mb-4">
            <div className="form-group">
            <div className="col-sm-6 col-xs-6 mr-2">
              <span className="qty-label">Quantity:</span>
                <input
                  type="number"
                  className="cart-qty-input"
                  value="1"
                /> 
              </div>
               <span className="qty-label mr-1">Qty:</span>{" "}
              <input type="number" className="form-control" value="1" />
            </div>
            <div className="col-sm-4 col-xs-4">
              <button className="cart-remove-wine">Delete</button>

            </div>
            <div className="col-sm-6 col-xs-6 cart-price">
              <span>
                {" "}
                $34.00
                {wineObject.wine.price
                  ? "$" +
                    wineObject.wine.price[0].price +
                    "  "
                  : " "} 
              </span>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
  handleIncrement = () => {
    let wineQuantity = this.state.wineQuantity;
    wineQuantity++;
    this.setState({
      wineQuantity,
    });
  };
  handleDecrement = () => {
    let wineQuantity = this.state.wineQuantity;
    if (wineQuantity > 1) {
      wineQuantity--;
      this.setState({
        wineQuantity,
      });
    }
  };
  onChangeQty = (e) => {
    let { value } = e.target;
    if (value < 1000 && value.length < 4 && (value === '' || this.state.numberRegex.test(value))) {
      this.setState({
        wineQuantity: value,
      });
    }
  };
  // removeWineFromCart = (e) => {
  //   e.stopPropagation();
  // };
  skipNavigatingToWineDetails = (e) => {
    e.stopPropagation();
  };
  onCartDropDownValueChange = (e, wineObject) => {
    if (e.target.value === '10+') {
      this.setState({ showQtyInputBox: true, showUpdateBtn: true })
    } else {
      this.props.onCartValueChange(e.target.value, wineObject)
    }
  }
  onQtyInputFocus = () => {
    this.setState({ showUpdateBtn: true });
  }
  onUpdateQty = (wineObject) => {
    if (!this.state.wineQuantity || this.state.wineQuantity === 0) {
      toast.error("Quantity should be greater than 0.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      if (Number(wineObject.quantity) != Number(this.state.wineQuantity)) {
        this.props.onCartValueChange(this.state.wineQuantity, wineObject);
        this.setState({ showUpdateBtn: false });
      } else {
        this.setState({ showUpdateBtn: false, showQtyInputBox: this.state.wineQuantity > 9 ? true : false });
      }
    }
  }
  handleonKeyDown = (e) => {
    if (e.key === '.') {
      e.preventDefault();
    }
  }
  onCartQtyBlurEvent = (e, wineObject) => {
    if (Number(wineObject.quantity) === Number(this.state.wineQuantity)) {
      this.setState({ showUpdateBtn: false })
    }
  }
}

CartWineDetails = withRouter(CartWineDetails);
export default CartWineDetails;
