import React from 'react'
import GoogleLogin from 'react-google-login'
import GoogleIcon from './GoogleIcon'
import './GoogleButton.scss'
import PropTypes from 'prop-types'

const GoogleButton = ({
  clientId,
  disabled = false,
  scope,
  autoLoad = false,
  render,
  isSignedIn = false,
  cookiePolicy,
  isMobile = false,
  onSuccess,
  ...others
}) => {
  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        autoLoad={autoLoad}
        disabled={disabled}
        scope={scope}
        render={(renderProps) =>
          render ? (
            render
          ) : (
            <span>
              <button
                type={'button'}
                className="btn-round google-btn mb-sm-5"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <GoogleIcon />
                {'Google'}
              </button>
            </span>
          )
        }
        isSignedIn={isSignedIn}
        cookiePolicy={cookiePolicy}
        isMobile={isMobile}
        onSuccess={onSuccess}
        {...others}
      />
    </div>
  );
};

GoogleButton.propTypes = {
  clientId: PropTypes.string.isRequired,
  autoload: PropTypes.bool,
  render: PropTypes.func,
  isSignedIn: PropTypes.bool,
  cookiePolicy: PropTypes.string,
  isMobile: PropTypes.bool,
  onSuccess: PropTypes.func,
};

export default GoogleButton
