import React, { Component } from "react";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import * as constants from "../../constants/constants";
import { ToastContainer, toast } from "react-toastify";
import locationIcon from "../../../assets/images/location-icon.svg";
import "./ShoppingCart.scss";
import "react-toastify/dist/ReactToastify.css";
import CartWineDetailsCard from "../../components/CartWineDetailsCard/CartWineDetailsCard";
import emptyShoppingCartImg from "../../../assets/images/empty-cart-updated-img.svg";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import ShoppingCartHeader from "../../components/ShoppingCartHeader/ShoppingCartHeader";
import * as commonFunctions from "../../constants/commonFunctions";
import NewConfirmCommonPopUp from "../../components/NewConfirmCommonPopup/NewConfirmCommonPopup";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import rightArrowBtn from "../../../assets/images/right-arrow-profile.svg";
import promoTagIcon from "../../../assets/images/promoTagIcon.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as GetCartTotalQtyAction from "../../redux/actions/GetCartTotalQtyAction";
import axiosCall from "../../constants/index";
import { isMobile } from "react-device-detect";
toast.configure();

let consumerData;
let wineryId;

class ShoppingCart extends Component {
  constructor(props) {
    consumerData = JSON.parse(localStorage.getItem("user_data"));
    wineryId = JSON.parse(localStorage.getItem("winery_id"));
    super(props);
    this.state = {
      cartWinesList: [],
      emptyCartMessage: "There are no items in your cart.",
      showDeleteConfirmPopUp: false,
      deleteHeaderText: "Are you sure you want to remove this selection?",
      cartRemoveConfirmText: "Remove",
      cartRemoveCancelText: "Cancel",
      cartSubTotal: 0,
      cartCount: "",
      totalQuantity: "",
      cartId: "",
      removeitemId: "",
      getCartListApiCallDone: false,
      removableQuantity: "",
      wineryDiscount: 0,
    };
  }

  componentDidMount() {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    this.onGetCartList();
    // this.onGetWineryDiscount();
  }

  componentDidUpdate() {
    this.totalCalculationsFromCartList();
  }

  onGetCartList = () => {
    try {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;
      let getCartListApiUrl =
        constants.SHOPPING_CART_API +
        "/api/v1/users/winery/" +
        wineryId +
        "/cart/items";
      fetch(getCartListApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: getCartListApiUrl,
              operation: "GET",
              status: response.status,
            };
            this.setState({
              getCartListApiCallDone: true,
            });
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (Object.entries(respJSON).length != 0 && !respJSON.errors) {
            console.log(respJSON);
            this.setState({
              getCartListApiCallDone: true,
              cartSubTotal: respJSON.cartSubTotal,
              totalQuantity: respJSON.totalQuantity,
              cartCount: respJSON.count,
              cartWinesList: respJSON.rows,
              // wineryDiscount :respJSON.rows.reduce(
              //   (acc, cartItem) => acc + parseFloat(cartItem.discount),
              //   0
              // )
            });
          } else {
            this.setState({
              getCartListApiCallDone: true,
            });
            // console.log(respJSON.errors);
          }
        })
        .catch((error) => {
          this.setState({
            getCartListApiCallDone: true,
          });
          // console.log(error);
        });
    } catch (e) {
      this.setState({
        getCartListApiCallDone: true,
      });
    }
  };
  // onGetWineryDiscount = async () => {
  //   let discountUrl =
  //     constants.WINERY_API + "/api/v1/winery/getDiscount/" + wineryId;
  //   try {
  //     const wineryDiscount = await axiosCall("get", discountUrl);
  //     this.setState({
  //       wineryDiscount: wineryDiscount.data.discount
  //         ? parseFloat(wineryDiscount.data.discount)
  //         : 0,
  //     });
  //   } catch (e) {
  //     let consumerData = JSON.parse(localStorage.getItem("user_data"));
  //     let apiErrorObj = {
  //       statusText: e.statusText,
  //       type: e.type,
  //       status: e.status,
  //       url: discountUrl,
  //       operation: "GET",
  //       status: e.status,
  //     };
  //     commonFunctions.postCaughtException(
  //       JSON.stringify(apiErrorObj),
  //       consumerData,
  //       wineryId
  //     );
  //   }
  // };
  render() {
    let wineryName = localStorage.getItem("winery_name");
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div
            className="success-inner-container new-discover-inner-container"
            id="parentShoppingCartDiv"
          >
            {" "}
            <ShoppingCartHeader />
            <div
              className={isMobile ? null : "desktop-div-scroll "}
              style={{ backgroundColor: "#f2f2f2" }}
            >
              {this.state.getCartListApiCallDone &&
              this.state.cartWinesList.length > 0 ? (
                <React.Fragment>
                  {/* <div className="current-shipping-address">
            <img alt="Shipping Location" src={locationIcon} />
            <span className="shipping-location">
              Deliver to Home - San Fransisco, 94103.
            </span>
          </div> */}
                  <div className="cart-stepper-header">
                    {/* <div className="line">Cart</div>{" "} */}
                    {/* <div className=''>
                  <img className="" src={stepperOnSelect} alt="stepper image" />
                  <span className='ml-2'>Cart</span>
                </div>
                <div>
                  <img className="ml-2" src={stepperNonSelect} alt="stepper non select image" />
                  <span className='ml-2'>Address</span>
                </div>
                <div>
                  <img className="ml-2" src={stepperNonSelect} alt="stepper non select image" />
                  <span className='ml-2'>Payment</span>
                </div> */}
                    {/* <div> */}
                    <div className="">
                      {/* <span className="dot-cart-stepper-select"></span> */}
                      <span className="cart-name-new-css mr-3">Cart</span>
                      {/* <span className='ml-2 mr-2'>Cart</span> */}
                    </div>{" "}
                    <div className="separator-stepper">
                      <span className="dot-cart-non-select-stepper"></span>
                      <span className="ml-3 mr-3 stepper-label-non-select">
                        Address
                      </span>
                    </div>{" "}
                    <div className="separator-stepper final-stepper-css">
                      <span className="dot-cart-non-select-stepper"></span>
                      <span className="ml-3 stepper-label-non-select">
                        Payment
                      </span>
                    </div>{" "}
                    {/* </div>  */}
                  </div>
                  <div className="available-wines-container new-cntr-dsgn row">
                    <div className="col-sm-12 col-xs-12 mt-4 p-0">
                      <div className="separator">{wineryName}</div>{" "}
                    </div>
                    <ErrorBoundary>
                      {this.state.cartWinesList.map((el, index) => (
                        <CartWineDetailsCard
                          wineData={el}
                          id={el.id}
                          key={index}
                          onCartValueChange={this.onCartValueChange}
                          onCartQtyBlurEvent={this.onCartQtyBlurEvent}
                          removeWineFromCart={this.removeWineFromCart}
                        />
                      ))}
                    </ErrorBoundary>
                  </div>

                  {/* <div className="continue-button-container flex-prop mb-2">
                <div className="total-display">
                  <div className="total-label">Sub Total:</div>
                  <div className="total-amount">
                    {commonFunctions.formatter.format(2500)}
                  </div>
                </div>
                <button
                  type="button"
                  // onClick={this.buyNowClicked.bind(this)}
                  onClick={this.onClickNext}
                  className="btn btn-round continue-button"
                >
                  Select Address
              </button>
              </div> */}
                  {this.state.wineryDiscount > 0 && (
                    <div className="promo-code-div ptr-clss">
                      <div className="promo-label-div">
                        <div className="promo-label">
                          <img
                            alt="Go to promo page"
                            className=""
                            src={promoTagIcon}
                          />
                          <span className="ml-3">
                            {/* Discount of {this.state.wineryDiscount}% */}
                            Discount of ${this.state.wineryDiscount}
                          </span>
                        </div>
                      </div>
                      {/* <img
                  alt="Go to promo page"
                  className="promo-right-icon-div"
                  src={rightArrowBtn}
                /> */}
                    </div>
                  )}
                  <div className=" bottom-nav-induvidual-wines cart-total">
                    <div className="cart-total-value">
                      <div className="total-label">
                        <span>Sub Total</span>
                      </div>
                      <div className="total-price-label">
                        <span>
                          {commonFunctions.formatter.format(
                            this.state.cartSubTotal
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="cart-proceed-div ptr-clss"
                      onClick={this.onClickNext}
                    >
                      <div className="slct-address">
                        <span className="proceed-lable">Select Address</span>
                        <img
                          alt="Checkout"
                          className="proceed-to-next"
                          src={backBtn}
                        />
                      </div>
                      {/* <img alt="Checkout" className="proceed-to-next" src={backBtn} /> */}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                this.state.getCartListApiCallDone && this.renderEmptyCartImg()
              )}
            </div>
            {this.state.showDeleteConfirmPopUp && (
              <NewConfirmCommonPopUp
                open={this.state.showDeleteConfirmPopUp}
                handleYes={this.handleYes}
                handleCancel={this.handleCancel}
                headerText={this.state.deleteHeaderText}
                confirmText={this.state.cartRemoveConfirmText}
                cancelText={this.state.cartRemoveCancelText}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleYes = () => {
    GoogleAnalyticsFunctions.onDeleteItemsFromCart();
    this.removeWineFromCartAPI(this.state.cartId, this.state.removeitemId);
  };
  handleCancel = () => {
    GoogleAnalyticsFunctions.onCancelDeleteItemsFromCart();
    // Removing item id and quantity details from state
    this.setState({
      cartId: null,
      removeitemId: null,
      removableQuantity: null,
      showDeleteConfirmPopUp: false,
    });
  };

  removeWineFromCart = (e, wineData) => {
    GoogleAnalyticsFunctions.onClickDeleteItemsFromCart();
    // console.log(e, wineData);
    e.stopPropagation();
    // On item remove storing removable item id and quantity details in state
    this.setState({
      cartId: wineData.cartId,
      removeitemId: wineData.id,
      removableQuantity: wineData.quantity, // to update in redux we are storing qty value also
      showDeleteConfirmPopUp: true,
    });
  };

  removeWineFromCartAPI = (cartId, itemId) => {
    // console.log(cartId, itemId);
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let removeItemFromCartAPIUrl =
      constants.SHOPPING_CART_API +
      "/api/v1/users/winery/" +
      wineryId +
      "/cart/" +
      cartId +
      "/items/" +
      itemId +
      "/delete";

    try {
      fetch(removeItemFromCartAPIUrl, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: removeItemFromCartAPIUrl,
              operation: "DELETE",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            // toast.info("Something went wrong. Please try again later.", {
            //   position: toast.POSITION.BOTTOM_CENTER,
            // });
            // Removing item id and quantity details from state
            this.setState({
              cartId: null,
              removeitemId: null,
              removableQuantity: null,
              showDeleteConfirmPopUp: false,
            });
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            // console.log(respJSON);
            // toast.info("Successfully, item removed from cart.", {
            //   position: toast.POSITION.BOTTOM_CENTER,
            // });
            this.onGetCartList();
            // Removing item id and quantity details from state
            this.setState({
              cartId: null,
              removeitemId: null,
              removableQuantity: null,
              showDeleteConfirmPopUp: false,
            });
          } else {
            // Removing item id and quantity details from state
            this.setState({
              cartId: null,
              removeitemId: null,
              removableQuantity: null,
              showDeleteConfirmPopUp: false,
            });
            // console.log(respJSON.errors);
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        })
        .catch((error) => {
          // Removing item id and quantity details from state
          this.setState({
            cartId: null,
            removeitemId: null,
            removableQuantity: null,
            showDeleteConfirmPopUp: false,
          });
          // console.log(error);
        });
    } catch (e) {
      // Removing item id and quantity details from state
      this.setState({
        cartId: null,
        removeitemId: null,
        removableQuantity: null,
        showDeleteConfirmPopUp: false,
      });
      toast.info("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      // console.log(e);
    }
  };

  // Calculating total cart quantity from cart list and updating to redux (reducer)
  totalCalculationsFromCartList = () => {
    let totalCartQuantity = 0;
    if (this.state.cartWinesList.length > 0) {
      totalCartQuantity = this.state.cartWinesList.reduce(
        (qty, v) => (qty += parseInt(v.quantity)),
        0
      );
    }
    this.props.GetCartTotalQtyAction.UpdateCartQuantity(totalCartQuantity);
  };

  // Calculating subtotal from cartlist
  subTotalCalculationFromCartList = () => {
    let cartSubTotal = 0;
    if (this.state.cartWinesList.length > 0) {
      cartSubTotal = this.state.cartWinesList.reduce(
        (amount, v) => (amount += parseInt(v.quantity) * parseInt(v.unitPrice)),
        0
      );
    }
    // console.log(cartSubTotal);
    this.setState({
      cartSubTotal: cartSubTotal,
    });
  };

  onCartValueChange = (changedQty, wineData) => {
    // console.log(e.target.value, wineData);
    // let changedQty = e.target.value
    GoogleAnalyticsFunctions.cartQtyChange();
    this.onUpdateQuantityAPICall(changedQty, wineData);
  };

  onUpdateQuantityAPICall = (changedQty, wineData) => {
    // console.log(wineData);
    let wineUnitePrice = wineData.wine.price[0].price;
    // console.log(wineUnitePrice);
    let putObj = {
      wineId: wineData.wineId,
      quantity: parseInt(changedQty),
      unitPrice: parseInt(wineData.unitPrice), // need to delete this in db table
      totalAmount: parseInt(changedQty) * parseInt(wineUnitePrice),
    };
    // console.log(putObj);
    let cartId = wineData.cartId;
    let itemId = wineData.id;

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let updateQtyAPIUrl =
      constants.SHOPPING_CART_API +
      "/api/v1/users/winery/" +
      wineryId +
      "/cart/" +
      cartId +
      "/items/" +
      itemId;

    try {
      fetch(updateQtyAPIUrl, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(putObj),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // console.log(response);
            let apiErrorObj = {
              statusText: response.statusText,
              type: response.type,
              status: response.status,
              url: updateQtyAPIUrl,
              operation: "PUT",
              status: response.status,
            };
            commonFunctions.postCaughtException(
              JSON.stringify(apiErrorObj),
              consumerData,
              wineryId
            );
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            throw Error(response.statusText);
          }
        })
        .then((respJSON) => {
          if (!respJSON.errors) {
            // console.log(respJSON);
            // if success updating qty in cart list
            this.onUpdateQuantityInItemCard(changedQty, wineData);
            this.setState({
              cartSubTotal: respJSON.cartSubTotal, // getting wrong cartSubTotal while cart having multiple items so not updating this
              totalQuantity: respJSON.totalQuantity,
              cartCount: respJSON.count,
              // cartWinesList: respJSON.rows not sending wine obj so commenting now
            });
            // this.subTotalCalculationFromCartList(); // updating subCartTotal in frontend commenting now bcz table change is there
          } else {
            // console.log(respJSON.errors);
            toast.info("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (e) {
      // console.log(e);
    }
  };

  onUpdateQuantityInItemCard = (changedQty, wineData) => {
    // console.log(changedQty, wineData);
    const cartWinesList = [...this.state.cartWinesList];
    const index = cartWinesList.indexOf(wineData);
    cartWinesList[index] = { ...wineData };
    cartWinesList[index].quantity = changedQty;
    this.setState({ cartWinesList });
  };

  onClickNext = () => {
    GoogleAnalyticsFunctions.onClickSelectAddress();
    this.props.history.push("/shopping-cart/checkout");
  };

  renderEmptyCartImg = () => {
    return (
      <React.Fragment>
        <div className="error-boundary-image-container empty-cart">
          <img
            alt="Empty Cart"
            id="emptyCart"
            className="error-boundary-image new-empty-cart-width"
            src={emptyShoppingCartImg}
          />
        </div>
        <div className="empty-cart-error-msg-div mt-5">
          <span className="error-boundary-title">
            {this.state.emptyCartMessage}
          </span>
        </div>
      </React.Fragment>
    );
  };
}

// REDUX

function mapStateToProps(state, props) {
  return {
    cartTotalQuantity: state.GetCartTotalQtyReducer.cartTotalQuantity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetCartTotalQtyAction: bindActionCreators(GetCartTotalQtyAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
