import React , {Component} from 'react';
import './TastePreferenceOptionCard.scss';
import * as constants from '../../constants/constants';
 
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import notSelectedLogo from '../../../assets/images/not-selected-heart.svg'; 
import selectedLogo from '../../../assets/images/selected-heart.svg'; 
 import winePlaceholder from '../../../assets/images/wine-placeholder.jpg';


let currentStatus , selectedStatus;
class TastePreferenceOptionCard extends Component {
   

    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            loaded: false // for the taste pref option image
        }
        
    }
    componentDidMount = () => { 
               
    }

    showImage = () => {
        this.setState({
                        loaded: true
                     });
      }
       
    toggleImage = () => {
      
        
        currentStatus = this.state.selected? true : false;
        selectedStatus = !currentStatus;
        this.setState(state => ({ selected: !state.selected }));

        this.props.sendTastePrefOptionCardStatus(this.props.preferenceCardDataObject.name , this.props.preferenceCardDataObject.img_url , selectedStatus );

        this.postPreferenceData(this.props.preferenceCardDataObject.name , this.props.category)

      }



	postCaughtException = (errorData) => {

		let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
        let bearer = 'Bearer ' + signInToken;
		let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );
		// let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );

		let postCaughtExceptionApiUrl = constants.IDENTITY_API + "/api/v1/frontendException/create";
		let apiPostData = {
			portal : "consumerportal",
			page:  window.location.href,
			error: errorData,
			userId : consumerData.id,
			// wineryId : wineryId,
            operation: JSON.parse(errorData).operation,
            statusCode: JSON.parse(errorData).status
			
		}
		fetch(postCaughtExceptionApiUrl , {  
	  
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Authorization" : bearer
				} ,
				body: JSON.stringify(apiPostData)
				})
				.then(res => {
					if (res.ok) {
					
					return res.json();
					
					} else {          
					throw Error(res.statusText);
					}
				})
				.then(json => {				
				})
				.catch(error => console.error(error));
  }
  

      postPreferenceData = (name, category) => {

        GoogleAnalyticsFunctions.clickedTastePreferenceOptionCard();


        let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );
        // let wineryId = ( JSON.parse(localStorage.getItem('winery_id'))  );
        let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
        let bearer = 'Bearer ' + signInToken;
        let value = selectedStatus? 1 : 0

        let postPreferenceApiUrl = constants.CONSUMER_API + '/api/v1/taste_preference/create';
        let apiPostData = {
            userId: consumerData.id,
            // wineryId: wineryId,
            tastePreferenceQuestionId: 1,
            category: category,
            option: name,
            value: value
        }
       
        fetch(postPreferenceApiUrl, {  
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization" : bearer
        },
        body: JSON.stringify(
            apiPostData
        )
      })
        .then(res => {
          if (res.ok) {
            
            return res.json();
            
          } else {     
            
                        let apiErrorObj = {
                            statusText: res.statusText,
                            type: res.type,
                            status: res.status,
                            url: postPreferenceApiUrl,
                            operation: 'POST'
                        }

                        this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .then(json => {
        

        })
        .catch(error => console.error(error));


      }


  static getDerivedStateFromProps(nextProps, prevState) {
 
    if(currentStatus === true && (nextProps.preferenceCardDataObject.selectionStatus !== null || nextProps.preferenceCardDataObject.selectionStatus !== undefined)) {

        return {
            selected : nextProps.preferenceCardDataObject.selectionStatus
        }

    }

    return null;
    
}

    render() {

        return(

            <div  onClick={this.toggleImage} onDoubleClick = {this.toggleImage} className="card shadow taste-preference-option-card-main-container">
                <div className="taste-preference-option-card-sub-container">
                    <div className="taste-preference-option-card-image-container">
                        <img alt = "Wine options"
                         className="option-card-image"
                         onLoad={this.showImage}
                         src = {
                            this.props.preferenceCardDataObject.img_url? 
                                 (this.state.loaded? this.props.preferenceCardDataObject.img_url : winePlaceholder ) : winePlaceholder
                               }
                         
                         />
                    </div>
                    <div className="taste-preference-option-card-name-container">
                        <span className="option-card-name">
                            {this.props.preferenceCardDataObject.name? this.props.preferenceCardDataObject.name : '' }
                        </span>


                    </div>


                    <div className="taste-preference-option-card-heart-image-container">
                    <img alt = "Like"
                            className="heart-status-image"
                            id="optionSelectedImageId"                       
                                                
                            src = { `${this.state.selected? selectedLogo : notSelectedLogo  }` } />
                </div>
                </div>
              
           </div>
        )

    }
  
}

export default TastePreferenceOptionCard;