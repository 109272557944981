import React from "react";

export default function Lock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <g opacity="0.5">
        <path
          d="M1 9.88811C1 9.43346 1.18061 8.99742 1.5021 8.67593C1.82359 8.35444 2.25963 8.17383 2.71429 8.17383H11.2857C11.7404 8.17383 12.1764 8.35444 12.4979 8.67593C12.8194 8.99742 13 9.43346 13 9.88811V15.031C13 15.4856 12.8194 15.9217 12.4979 16.2432C12.1764 16.5646 11.7404 16.7453 11.2857 16.7453H2.71429C2.25963 16.7453 1.82359 16.5646 1.5021 16.2432C1.18061 15.9217 1 15.4856 1 15.031V9.88811Z"
          stroke="#7D7D7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.57126 8.16183V4.73326C3.57126 3.82395 3.93248 2.95187 4.57546 2.30889C5.21845 1.66591 6.09052 1.30469 6.99983 1.30469C7.90914 1.30469 8.78121 1.66591 9.4242 2.30889C10.0672 2.95187 10.4284 3.82395 10.4284 4.73326V8.16183M6.14269 12.4475C6.14269 12.6749 6.23299 12.8929 6.39374 13.0536C6.55448 13.2144 6.7725 13.3047 6.99983 13.3047C7.22716 13.3047 7.44518 13.2144 7.60592 13.0536C7.76667 12.8929 7.85697 12.6749 7.85697 12.4475C7.85697 12.2202 7.76667 12.0022 7.60592 11.8415C7.44518 11.6807 7.22716 11.5904 6.99983 11.5904C6.7725 11.5904 6.55448 11.6807 6.39374 11.8415C6.23299 12.0022 6.14269 12.2202 6.14269 12.4475Z"
          stroke="#7D7D7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
