// Images
// import img1_1 from "../../../assets/images/TastePreferenceQuiz/img1_1.png";
import Q1_Opt1 from "../../../assets/images/TastePreferQuizImg/Q1-Opt1.png";
import Q1_Opt2 from "../../../assets/images/TastePreferQuizImg/Q1-Opt2.png";
import Q1_Opt3 from "../../../assets/images/TastePreferQuizImg/Q1-Opt3.png";

import Q2_Opt1 from "../../../assets/images/TastePreferQuizImg/Q2-Opt1.png";
import Q2_Opt2 from "../../../assets/images/TastePreferQuizImg/Q2-Opt2.png";
import Q2_Opt3 from "../../../assets/images/TastePreferQuizImg/Q2-Opt3.png";
import Q2_Opt4 from "../../../assets/images/TastePreferQuizImg/Q2-Opt4.png";

// import img2_1 from "../../../assets/images/TastePreferenceQuiz/img2_1.png";
// import img2_2 from "../../../assets/images/TastePreferenceQuiz/img2_2.png";
// import img2_3 from "../../../assets/images/TastePreferenceQuiz/img2_3.png";
// import img2_4 from "../../../assets/images/TastePreferenceQuiz/img2_4.png";

import Q3_Opt1 from "../../../assets/images/TastePreferQuizImg/Q3-Opt1.png";
import Q3_Opt2 from "../../../assets/images/TastePreferQuizImg/Q3-Opt2.png";
import Q3_Opt3 from "../../../assets/images/TastePreferQuizImg/Q3-Opt3.png";
import Q3_Opt4 from "../../../assets/images/TastePreferQuizImg/Q3-Opt4.png";
import Q3_Opt5 from "../../../assets/images/TastePreferQuizImg/Q3-Opt5.png";
import Q3_Opt6 from "../../../assets/images/TastePreferQuizImg/Q3-Opt6.png";
import Q3_Opt7 from "../../../assets/images/TastePreferQuizImg/Q3-Opt7.png";
import Q3_Opt8 from "../../../assets/images/TastePreferQuizImg/Q3-Opt8.png";

import Q4_Opt1 from "../../../assets/images/TastePreferQuizImg/Q4-Opt1.png";
import Q4_Opt2 from "../../../assets/images/TastePreferQuizImg/Q4-Opt2.png";
import Q4_Opt3 from "../../../assets/images/TastePreferQuizImg/Q4-Opt3.png";
import Q4_Opt4 from "../../../assets/images/TastePreferQuizImg/Q4-Opt4.png";
import Q4_Opt5 from "../../../assets/images/TastePreferQuizImg/Q4-Opt5.png";
import Q4_Opt6 from "../../../assets/images/TastePreferQuizImg/Q4-Opt6.png";

import Q5_Opt1 from "../../../assets/images/TastePreferQuizImg/Q5-Opt1.png";
import Q5_Opt2 from "../../../assets/images/TastePreferQuizImg/Q5-Opt2.png";
import Q5_Opt3 from "../../../assets/images/TastePreferQuizImg/Q5-Opt3.png";
import Q5_Opt4 from "../../../assets/images/TastePreferQuizImg/Q5-Opt4.png";

import Q6_Opt1 from "../../../assets/images/TastePreferQuizImg/Q6-Opt1.png";
import Q6_Opt2 from "../../../assets/images/TastePreferQuizImg/Q6-Opt2.png";
import Q6_Opt3 from "../../../assets/images/TastePreferQuizImg/Q6-Opt3.png";

import Q7_Opt1 from "../../../assets/images/TastePreferQuizImg/Q7-Opt1.png";
import Q7_Opt2 from "../../../assets/images/TastePreferQuizImg/Q7-Opt2.png";
import Q7_Opt3 from "../../../assets/images/TastePreferQuizImg/Q7-Opt3.png";
import Q7_Opt4 from "../../../assets/images/TastePreferQuizImg/Q7-Opt4.png";

import Q8_Opt1 from "../../../assets/images/TastePreferQuizImg/Q8-Opt1.png";
import Q8_Opt2 from "../../../assets/images/TastePreferQuizImg/Q8-Opt2.png";
import Q8_Opt3 from "../../../assets/images/TastePreferQuizImg/Q8-Opt3.png";
import Q8_Opt4 from "../../../assets/images/TastePreferQuizImg/Q8-Opt4.png";
import Q8_Optional from "../../../assets/images/TastePreferQuizImg/Q8_Optional.png";

import Q9_Opt1 from "../../../assets/images/TastePreferQuizImg/Q9-Opt1.png";
import Q9_Opt2 from "../../../assets/images/TastePreferQuizImg/Q9-Opt2.png";
import Q9_Opt3 from "../../../assets/images/TastePreferQuizImg/Q9-Opt3.png";
import Q9_Opt4 from "../../../assets/images/TastePreferQuizImg/Q9-Opt4.png";

import Q10_Opt1 from "../../../assets/images/TastePreferQuizImg/Q10-Opt1.png";
import Q10_Opt2 from "../../../assets/images/TastePreferQuizImg/Q10-Opt2.png";
import Q10_Opt3 from "../../../assets/images/TastePreferQuizImg/Q10-Opt3.png";
import Q10_Opt4 from "../../../assets/images/TastePreferQuizImg/Q10-Opt4.png";

import Q11_Opt1 from "../../../assets/images/TastePreferQuizImg/Q11-Opt1.png";
import Q11_Opt2 from "../../../assets/images/TastePreferQuizImg/Q11-Opt2.png";
import Q11_Opt3 from "../../../assets/images/TastePreferQuizImg/Q11-Opt3.png";
import Q11_Opt4 from "../../../assets/images/TastePreferQuizImg/Q11-Opt4.png";

import Q12_Opt1 from "../../../assets/images/TastePreferQuizImg/Q12-Opt1.png";
import Q12_Opt2 from "../../../assets/images/TastePreferQuizImg/Q12-Opt2.png";
import Q12_Opt3 from "../../../assets/images/TastePreferQuizImg/Q12-Opt3.png";
import Q12_Opt4 from "../../../assets/images/TastePreferQuizImg/Q12-Opt4.png";

import Q13_Opt1 from "../../../assets/images/TastePreferQuizImg/Q13-Opt1.png";
import Q13_Opt2 from "../../../assets/images/TastePreferQuizImg/Q13-Opt2.png";

import Q14_Opt1 from "../../../assets/images/TastePreferQuizImg/Q14-Opt1.png";
import Q14_Opt2 from "../../../assets/images/TastePreferQuizImg/Q14-Opt2.png";

import Q15_Opt1 from "../../../assets/images/TastePreferQuizImg/Q15-Opt1.png";
import Q15_Opt2 from "../../../assets/images/TastePreferQuizImg/Q15-Opt2.png";

import Q16_Opt1 from "../../../assets/images/TastePreferQuizImg/Q16-Opt1.png";
import Q16_Opt2 from "../../../assets/images/TastePreferQuizImg/Q16-Opt2.png";
import Q16_Opt3 from "../../../assets/images/TastePreferQuizImg/Q16-Opt3.png";
import Q16_Opt4 from "../../../assets/images/TastePreferQuizImg/Q16-Opt4.png";
import Q16_Opt5 from "../../../assets/images/TastePreferQuizImg/Q16-Opt5.png";
import Q16_Opt6 from "../../../assets/images/TastePreferQuizImg/Q16-Opt6.png";

import Q17_Opt1 from "../../../assets/images/TastePreferQuizImg/Q17-Opt1.png";
import Q17_Opt2 from "../../../assets/images/TastePreferQuizImg/Q17-Opt2.png";
import Q17_Opt3 from "../../../assets/images/TastePreferQuizImg/Q17-Opt3.png";

import Q18_Optional from "../../../assets/images/TastePreferQuizImg/Q18-Optional.png";

import Q19_Opt1 from "../../../assets/images/TastePreferQuizImg/Q19-Opt1.png";
import Q19_Opt2 from "../../../assets/images/TastePreferQuizImg/Q19-Opt2.png";
import Q19_Opt3 from "../../../assets/images/TastePreferQuizImg/Q19-Opt3.png";
import Q19_Opt4 from "../../../assets/images/TastePreferQuizImg/Q19-Opt4.png";
import Q19_Opt5 from "../../../assets/images/TastePreferQuizImg/Q19-Opt5.png";
import Q19_Opt6 from "../../../assets/images/TastePreferQuizImg/Q19-Opt6.png";
import Q19_Opt7 from "../../../assets/images/TastePreferQuizImg/Q19-Opt7.png";
import Q19_Opt8 from "../../../assets/images/TastePreferQuizImg/Q19-Opt8.png";

import Mushrooms from "../../../assets/images/TastePreferQuizImg/mashroom.png";

import Q21_Opt1 from "../../../assets/images/TastePreferQuizImg/Q21-Opt1.png";
import Q21_Opt2 from "../../../assets/images/TastePreferQuizImg/Q21-Opt2.png";
import Q21_Opt3 from "../../../assets/images/TastePreferQuizImg/Q21-Opt3.png";

import Q22_Opt1 from "../../../assets/images/TastePreferQuizImg/Q22-Opt1.png";
import Q22_Opt2 from "../../../assets/images/TastePreferQuizImg/Q22-Opt2.png";
import Q22_Opt3 from "../../../assets/images/TastePreferQuizImg/Q22-Opt3.png";
import Q22_Opt4 from "../../../assets/images/TastePreferQuizImg/Q22-Opt4.png";
import Q22_Opt5 from "../../../assets/images/TastePreferQuizImg/Q22-Opt5.png";

import Money from "../../../assets/images/TastePreferQuizImg/Money.png";

import Choco from "../../../assets/images/TastePreferQuizImg/choco.png";

import Q23_Opt1 from "../../../assets/images/TastePreferQuizImg/Q23-Opt1.png";
import Q23_Opt2 from "../../../assets/images/TastePreferQuizImg/Q23-Opt2.png";
import Q23_Opt3 from "../../../assets/images/TastePreferQuizImg/Q23-Opt3.png";

import Q24_Opt1 from "../../../assets/images/TastePreferQuizImg/Q24-Opt1.png";
import Q24_Opt2 from "../../../assets/images/TastePreferQuizImg/Q24-Opt2.png";
import Q24_Opt3 from "../../../assets/images/TastePreferQuizImg/Q24-Opt3.png";
import Q24_Opt4 from "../../../assets/images/TastePreferQuizImg/Q24-Opt4.png";

//25 missing not done
import Q25_Opt1 from "../../../assets/images/TastePreferQuizImg/Q25-Opt1.png";
// import Q25_Opt2 from "../../../assets/images/TastePreferQuizImg/Q25-Opt2.png";
import Q25_Opt3 from "../../../assets/images/TastePreferQuizImg/Q25-Opt3.png";
import Q25_Opt4 from "../../../assets/images/TastePreferQuizImg/Q25-Opt4.png";
import Q25_Opt5 from "../../../assets/images/TastePreferQuizImg/Q25-Opt5.png";
import Q25_Opt6 from "../../../assets/images/TastePreferQuizImg/Q25-Opt6.png";

import Q26_Opt1 from "../../../assets/images/TastePreferQuizImg/Q26-Opt1.png";
import Q26_Opt2 from "../../../assets/images/TastePreferQuizImg/Q26-Opt2.png";
import Q26_Opt3 from "../../../assets/images/TastePreferQuizImg/Q26-Opt3.png";
import Q26_Opt4 from "../../../assets/images/TastePreferQuizImg/Q26-Opt4.png";

import { LiveTv } from "@material-ui/icons";
import sessionStorage from "redux-persist/es/storage/session";

let questionList = [
  {
    questionTitle: "How experienced with wine are you?",
    questionID: 1,
    level: [1, 2, 3],
    choices: [
      ["I’m new and only had wine a few times", Q1_Opt1, 1, 0, 0, 0, 0,""],
      ["I’ve had my fair share of wine", Q1_Opt2, 2, 0, 0, 0, 0,""],
      [
        "I’m an expert - people ask me for recommendations",
        Q1_Opt3,
        3,
        0,
        0,
        0,
        "",
      ],
    ],
    selection: 1,
  },
  {
    questionTitle: "How adventurous with wine are you?",
    questionID: 30,
    level: [1, 2, 3],
    choices: [
      ["Classic and traditional", Q2_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["Hint of something different", Q2_Opt2, 2, 0, 0, 0, 0, 0,""],
      ["Take me to the next level", Q2_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Bring it on!", Q2_Opt4, 4, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "What attributes would you like to see in your wine?",
    questionID: 18,
    level: [1, 2, 3],
    choices: [
      ["Fruity", Q3_Opt1, 2, 0, 0, 0, 0, 0,""],
      ["Earthy", Q3_Opt2, 1, 0, 0, 0, 0, 0,""],
      ["Light", Q3_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Bold", Q3_Opt4, 4, 0, 0, 0, 0, 0,""],
      ["Sparkling", Q3_Opt5, 5, 0, 0, 0, 0, 0,""],
      ["Still", Q3_Opt6, 6, 0, 0, 0, 0, 0,""],
      ["Nuttiness", Q3_Opt7, 6, 0, 0, 0, 0, 0,""],
      ["Citric", Q3_Opt8, 6, 0, 0, 0, 0, 0,""],
    ],
    additional: "I DON'T KNOW",
    selection: 3,
  },
  {
    questionTitle: "What type of milk are you grabbing?",
    questionID: 3,
    level: [1, 2, 3],
    choices: [
      ["Whole Milk", Q4_Opt1, 1, 0, 1, 0, 0, 0,""],
      ["Fat Free", Q4_Opt2, 2, 0, 5, 0, 0, 0,""],
      ["1-2%", Q4_Opt3, 3, 0, 3, 0, 0, 0,""],
      ["Nut milk", Q4_Opt4, 4, 0, 4, 0, 0, 0,""],
      ["Other milk substitute", Q4_Opt6, 6, 0, 0, 0, 0, 0,""],
      ["I don't like milk", Q4_Opt5, 5, 0, 4, 0, 0, 0,""],
      
    ],
    additional: "I DON'T KNOW", //Changed
    selection: 1,
  },
  {
    questionTitle: "How do you like your tea?",
    questionID: 2,
    level: [1, 2, 3],
    choices: [
      ["Black, or really close to it", Q5_Opt1, 1, 1, 0, 0, 0, 0, "Black Tea"],
      ["Creamy and sweet", Q5_Opt2, 2, 5, 0, 0, 0, 0, "Cream"],
      ["With a little lemon", Q5_Opt3, 3, 0, 0, 0, 3, 0, "Lemon,Lemon Zest"],
      ["Herbal teas", Q5_Opt4, 4, 0, 0, 3, 0, 0, "Herbal Tea"],
    ],
    selection: 1,
  },
  {
    questionTitle: "You're on the beach, what are you sippin'?",
    questionID: 4,
    level: [1, 2, 3],
    choices: [
      ["Bloody Mary", Q6_Opt1, 1, 0, 2, 0, 2, 0,""],
      ["Mojito", Q6_Opt2, 2, 4, 3, 0, 2, 0,""],
      ["Pina Colada", Q6_Opt3, 3, 4, 3, 0, 0, 0, "Pineapple,Coconut",""],
    ],
    additional: "SOMETHING ELSE",
    selection: 1,
  },
  {
    questionTitle:
      "Are there any dietary restriction we should be keeping in mind?",
    questionID: 16,
    level: [1, 2, 3],
    choices: [
      ["Low Calorie Wines Only", Q7_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["Vegan Wines Only", Q7_Opt2, 2, 0, 0, 0, 0, 0,""],
      ["Keto Wines Only", Q7_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Natural Wines Only", Q7_Opt4, 4, 0, 0, 0, 0, 0,""],
    ],
    additional: "NONE",
    selection: 1,
  },
  //I have doubt on this?? figma
  {
    //17
    questionTitle: "What’s your price range?",
    questionID: 17,
    level: [1, 2, 3],
    image: Q8_Optional,
    choices: [
      ["up to $15", "", 1, 0, 0, 0, 0, 0,""],
      ["$16-25", " ", 2, 0, 0, 0, 0, 0,""],
      ["$26-35", " ", 3, 0, 0, 0, 0, 0,""],
      ["$35-50", " ", 4, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    //5
    questionTitle: "Happy Birthday! Dessert's on the house, what'll ya have?",
    questionID: 5,
    level: [1, 2, 3],
    choices: [
      ["Lemon Bar", Q9_Opt1, 1, 1, 3, 0, 5, 0, "Lemon"],
      ["Tres Leches", Q9_Opt2, 2, 0, 3, 0, 0, 0, "Milk"],
      ["Oreo Coffee Mousse ", Q9_Opt3, 3, 4, 3, 0, 0, 0, "Coffee"],
      ["Pecan Pie", Q9_Opt4, 4, 5, 5, 5, 0, 0, "Pecan,Nuts"],
    ],
    selection: 1,
  },
  {
    questionTitle:
      "You catch yourself with an empty fridge, what juice are you grabbing at the grocery store",
    questionID: 6,
    level: [1, 2, 3],
    choices: [
      ["Grapefruit Juice", Q10_Opt1, 1, 2, 2, 0, 5, 0, "Grapefruits"],
      ["Apple Juice", Q10_Opt2, 2, 4, 3, 0, 2, 0, "Apple"],
      ["Orange Juice", Q10_Opt3, 3, 3, 3, 0, 4, 0, "Orange"],
      ["Grape Juice", Q10_Opt4, 4, 4, 4, 0, 2, 0, "Grape"],
    ],
    selection: 1,
  },
  {
    questionTitle: "What late night snack are you stocking your pantry with?",
    questionID: 7,
    level: [1, 2, 3],
    choices: [
      ["Hershey's Bar", Q11_Opt1, 1, 4, 0, 0, 0, 0, "Milk Chocolate"],
      ["Dark Chocolate", Q11_Opt2, 2, 2, 0, 0, 0, 0, "Dark Chocolate"],
      ["Pretzels", Q11_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Chips", Q11_Opt4, 4, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "Which of these would be your favorite fruit",
    questionID: 8,
    level: [1, 2, 3],
    choices: [
      ["Lemon", Q12_Opt1, 1, 0, 0, 0, 5, 0, "Lemon"],
      ["Pineapple", Q12_Opt2, 2, 4, 0, 0, 4, 0, "Pineapple"],
      ["Banana", Q12_Opt3, 3, 0, 0, 0, 0, 0, "Banana"],
      ["Orange", Q12_Opt4, 4, 3, 0, 0, 4, 0, "Orange"],
    ],
    selection: 1,
  },
  {
    questionTitle:
      "Given the choice, are you going with a rich molten lava cake or a light fluffy angel food cake?",
    questionID: 9,
    level: [1, 2, 3],
    choices: [
      ["Molten Lava Cake", Q13_Opt1, 1, 4, 5, 4, 0, 0,""],
      ["Angel Food Cake   ", Q13_Opt2, 2, 2, 3, 3, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "What flavor ice cream are you putting on that lava cake",
    questionID: 10,
    level: [1, 2, 3],
    choices: [
      ["Vanilla", Q14_Opt1, 1, 0, 5, 0, 0, 0, "Vanilla"],
      ["Chocolate", Q14_Opt2, 2, 0, 5, 0, 0, 0, "Chocolate"],
    ],
    selection: 1,
  },
  {
    questionTitle: "What are you topping that angel food cake with?",
    questionID: 11,
    level: [1, 2, 3],
    choices: [
      ["Fresh Fruit", Q15_Opt1, 1, 2, 0, 0, 3, 0,""],
      ["Ganache", Q15_Opt2, 2, 0, 4, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "What are you grabbing at the brewery ?",
    questionID: 12,
    level: [1, 2, 3],
    choices: [
      ["Larger & Pilsner", Q16_Opt1, 1, 0, 4, 0, 0, 0,""],
      ["Cream Ale", Q16_Opt2, 2, 0, 0, 0, 0, 0,""],
      ["Pale Ales And India Pale Ale", Q16_Opt3, 3, 0, 2, 0, 0, 0,""],
      ["Porters or Dry Stout", Q16_Opt4, 4, 0, 0, 0, 0, 0,""],
      ["Hefeweizens or Wits ", Q16_Opt5, 5, 0, 0, 0, 0, 0,""],
      ["I don't like beer", Q16_Opt6, 6, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle:
      " Oh no, you caught a cold! How much honey are you adding to your tea?",
    questionID: 13,
    level: [1],
    choices: [
      ["Call me Winnie the Pooh", Q17_Opt1, 1, 5, 0, 0, 0, 0,""],
      ["Just a touch", Q17_Opt2, 2, 2, 0, 0, 0, 0,""],
      ["Leave the honey aside", Q17_Opt3, 3, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle:
      "How many bottles of wine do you buy a month? (we promise we wont tell anyone)  ",
    questionID: 19,
    level: [1, 2, 3],
    image: Q18_Optional,
    choices: [
      ["0-5", "", 1, 0, 0, 0, 0, 0,""],
      ["5-10", "", 2, 0, 0, 0, 0, 0,""],
      ["10-15", "", 3, 0, 0, 0, 0, 0,""],
      ["15+", "", 4, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "Where have you been?",
    questionID: 20,
    level: [1, 2],
    choices: [
      ["Rome", Q19_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["Bali", Q19_Opt2, 2, 0, 0, 0, 0, 0,""],
      ["London", Q19_Opt3, 7, 0, 0, 0, 0, 0,""],
      ["Tokyo", Q19_Opt7, 3, 0, 0, 0, 0, 0,""],
      ["Machu Picchu", Q19_Opt6, 4, 0, 0, 0, 0, 0,""],
      ["Yosemite", Q19_Opt5, 5, 0, 0, 0, 0, 0,""],
      ["New York", Q19_Opt4, 6, 0, 0, 0, 0, 0,""],
    
      // ["None of these", Q19_Opt8, 8, 0, 0, 0, 0, 0,""],
    ],
    additional: "NONE",
    selection: 7, // CHANGED
  },
  {
    questionTitle: "Let's start out easy, Mushrooms: Yay or Nay? ",
    questionID: 19,
    level: [1, 3],
    image: Mushrooms,
    choices: [
      ["Yay!", "", 1, 0, 0, 0, 0, 0,""],
      ["Nay", "", 2, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "What type of body can we bring to the table? ",
    questionID: 14,
    level: [3],
    choices: [
      ["Light (under 12.5%)", Q21_Opt1, 1, 2, 1, 2, 3, 0,""],
      ["Medium (12.5% - 13.5% alc)", Q21_Opt2, 2, 3, 3, 3, 3, 0,""],
      ["Full (over 13.5% alc) ", Q21_Opt3, 3, 4, 5, 4, 3, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "Here's a simple one",
    questionID: 14,
    level: [2],
    image: Choco,
    choices: [
      ["Dark Chocolate", "", 1, 2, 1, 4, 3, 3,""],
      ["Milk Chocolate", "", 2, 3, 3, 3, 3, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "You just had a long day, what's your tried and true wine?",
    questionID: 21,
    level: [2, 3],
    choices: [
      ["White", Q22_Opt1, 0, 0, 0, 0, 0,""],
      ["Red", Q22_Opt2, 0, 0, 0, 0, 0,""],
      ["Rose", Q22_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Dessert/Sweet Wine", Q22_Opt4, 4, 0, 0, 0, 0, 0,""],
      ["Sparkling", Q22_Opt5, 5, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "Do you enjoy aged wines?",
    questionID: 5,
    level: [3],
    image: Money,
    choices: [
      ["1-3 years ", "", 1, 0, 0, 0, 0, 0,""],
      ["3-5 years", "", 2, 0, 0, 0, 0, 0,""],
      ["5-10 year", "", 3, 0, 0, 0, 0, 0,""],
      ["10-20 years", "", 4, 0, 0, 0, 0, 0,""],
      ["20-30 years ", "", 5, 0, 0, 0, 0, 0,""],
      ["30+ years ", "", 6, 0, 0, 0, 0, 0,""],
      ["Just let it taste good, No Preference*", "", 7, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "How much sugar you puttin' your tea?",
    questionID: 5,
    level: [3],
    choices: [
      ["A lot ", Q23_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["A little ", Q23_Opt2, 0, 0, 0, 0, 0,""],
      ["No thank you ", Q23_Opt3, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle:
      "You're going out for a splurge meal, what are you getting at the restaurant?",
    questionID: 5,
    level: [3],
    choices: [
      [
        "A Baked Salmon With Asparagus, Onions, Bell Peppers ",
        Q24_Opt1,
        1,
        0,
        0,
        0,
        0,
        0,
        "",
      ],
      ["Filet Mignon  ", Q24_Opt2, 2, 0, 0, 0, 0, 0,""],
      [
        "Smoked Duck Breast With Spiced Cranberry Chutney ",
        Q24_Opt3,
        3,
        0,
        0,
        0,
        0,
        0,
        "",
      ],
      ["Shakshuka ", Q24_Opt4, 4, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },

  {
    questionTitle:"Let's take a trip the vine country, where are you taking us?",
    questionID: 29,
    level: [2],
    choices: [
      ["Napa/Sonoma, California", Q25_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["Verda Valley, Arizona", Q25_Opt1, 2, 0, 0, 0, 0,""],
      ["Monticello, Virginia", Q25_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Texas Hill Country", Q25_Opt4, 4, 0, 0, 0, 0, 0,""],
      ["Finger Lakes, New York", Q25_Opt5, 5, 0, 0, 0, 0, 0,""],
      ["No Preference ", Q25_Opt6, 6, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  {
    questionTitle: "What wine would you pair with that Baked Salmon?",
    questionID: 25,
    level: [3],
    choices: [
      ["Beaujolais", Q26_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["Pinot Noir", Q26_Opt2, 2, 0, 0, 0, 0, 0,""],
      ["Rosé", Q26_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Chardonnay", Q26_Opt4, 4, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },

  {
    questionTitle: "What is your dream vacay?",
    questionID: 20,
    level: [2],
    choices: [
      ["Rome", Q19_Opt1, 1, 0, 0, 0, 0, 0,""],
      ["Bali", Q19_Opt2, 2, 0, 0, 0, 0, 0,""],
      ["London ", Q19_Opt3, 3, 0, 0, 0, 0, 0,""],
      ["Tokyo ", Q19_Opt4, 4, 0, 0, 0, 0, 0,""],
      ["Machu Picchu ", Q19_Opt5, 5, 0, 0, 0, 0, 0,""],
      ["Yosemite ", Q19_Opt6, 6, 0, 0, 0, 0, 0,""],
      ["New York, NewYork  ", Q19_Opt7, 7, 0, 0, 0, 0, 0,""],
      ["None of these ", Q19_Opt8, 8, 0, 0, 0, 0, 0,""],
    ],
    selection: 1,
  },
  //  not done yet
  // {
  //   questionTitle:
  //     "Do you like to double down on sweet, or look for something to compliment it?",
  //   questionID: 31,
  //   level: [3],
  //   choices: [["Throw some pretzels in my ice cream ", 1, 0, 0, 0, 0, 0, ""]],
  //   selection: 1,
  // },
  // {
  //   questionTitle:
  //     "Let's take a trip the wine country, where are you taking us?",
  //   questionID: 29,
  //   level: [3],
  //   choices: [["Central Valley", 1, 0, 0, 0, 0, 0, ""]],
  //   selection: 1,
  // },
];

localStorage.removeItem('count');
    localStorage.removeItem('questionsAnswered');
    localStorage.removeItem('visits');

export default questionList;
