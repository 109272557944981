import React from "react";

export default function Cheers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="135"
      viewBox="0 0 125 135"
      fill="none"
    >
      <g opacity="0.5">
        <path
          d="M64.7382 35.8587C66.8712 27.693 92.0228 22.5999 95.9524 27.5206C95.9524 27.5206 115.275 63.9138 101.816 85.7474C100.634 91.6272 105.429 108.535 109.374 108.079C113.318 107.623 119.499 106.229 123.606 108.629C126.41 110.267 120.994 115.47 107.742 118.65C89.9912 122.91 83.8019 120.471 86.7301 116.873C88.3193 114.92 96.7667 112.167 97.9478 110.758C101.305 106.752 97.2089 90.5331 92.5008 87.8259C73.6432 81.3879 64.7382 35.8587 64.7382 35.8587ZM96.8546 85.24C111.542 74.6347 97.7915 38.1778 97.7915 38.1778C86.9779 32.0434 92.2345 59.7197 71.0109 49.0587C71.0109 49.0587 78.9169 81.341 96.8546 85.24Z"
          fill="#7D7D7D"
        />
        <path
          d="M60.7145 48.7663C58.6259 40.5891 33.5023 35.3595 29.546 40.2588C29.546 40.2588 10.0261 76.5465 23.3661 98.4529C24.5165 104.339 19.6294 121.221 15.6875 120.743C11.7454 120.266 5.57208 118.839 1.45193 121.216C-1.36078 122.839 4.02725 128.071 17.2619 131.323C34.9891 135.679 41.1915 133.274 38.2829 129.66C36.7044 127.698 28.272 124.899 27.0986 123.484C23.7636 119.46 27.9474 103.264 32.6701 100.582C51.5623 94.2464 60.7145 48.7663 60.7145 48.7663ZM28.3304 97.9725C13.7009 87.2875 27.649 50.9058 27.649 50.9058C38.4958 44.8303 33.089 72.4776 54.3702 61.932C54.3702 61.932 46.2889 94.1709 28.3304 97.9725Z"
          fill="#7D7D7D"
        />
        <path
          d="M44 2.2207L52.5117 0.00035299L54.5205 17.6582L50.8726 18.6098L44 2.2207Z"
          fill="#7D7D7D"
        />
        <path
          d="M64.9219 4L71.0075 7.46106L62.3466 18.6434L59.7385 17.1601L64.9219 4Z"
          fill="#7D7D7D"
        />
        <path
          d="M31 22.7031L33.0197 15.9998L45.8493 21.9545L44.9837 24.8274L31 22.7031Z"
          fill="#7D7D7D"
        />
      </g>
    </svg>
  );
}
