import React, { Component } from "react";
import "./EventList.scss";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import EventCard from "../../components/EventCard/EventCard";

import backBtn from "../../../assets/images/left-arrow-white.svg";
import switchWineryIcon from "../../../assets/images/switch_winery_icon.svg";
import chatIcon from "../../../assets/images/chat-icon-switch.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import emptyImage from "../../../assets/images/Events/events-empty-state.svg";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as GetEventsAction from "../../redux/actions/GetEventsAction";
import * as WineryAction from "../../redux/actions/WineryAction";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import backBtnBlack from "../../../assets/images/back-btn.svg";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import MenuIcon from "@material-ui/icons/Menu";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import { convertToCamelCase } from "../../constants/commonFunctions";

class EventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEventDesc: false,
      getEventDetailSuccess: "",
      getWineryDetailsSuccess: "",
      getWineryDetailFail: "",
      getEventDetailFail: "",
      eventData: "",
      wineryLogo: "",
      wineryName: "",
      wineryMessage: "",
      opened: false,
      gotoWineryDetailsPageStatus: false,
      chatStatus: false,
      switchWineryStatus: false,
      userWineryStatus: "",
    };
  }

  componentDidMount = () => {
    this.props.GetEventsAction.GetEventsDummyApi();
    this.props.GetEventsAction.GetEvents();
    this.props.WineryAction.GetWineryDetails();
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.state.switchWineryStatus) {
      this.props.history.push({
        pathname: "/wineries",
        state: {},
      });
    }
    if (this.state.gotoWineryDetailsPageStatus) {
      this.props.history.push({
        pathname:
          "/" +
          convertToCamelCase(localStorage.getItem("winery_name")) ,
        // pathname: "/winery-details",
        state: {},
      });
    }
    if (this.state.chatStatus) {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      this.props.history.push({
        pathname: "/chat" + "/" + wineryId,
        state: {},
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.getEventDetailSuccess && nextProps.getWineryDetailsSuccess) {
      return {
        eventData: nextProps.getEventDetailSuccess.results.rows,
        userWineryStatus:
          nextProps.getEventDetailSuccess.userWineryStatus.isActive,
        wineryName: nextProps.getWineryDetailsSuccess.displayName,
        wineryMessage: nextProps.getWineryDetailsSuccess.message,
        wineryLogo: nextProps.getWineryDetailsSuccess.logo,
      };
    } else if (nextProps.getEventDetailSuccess) {
      return {
        eventData: nextProps.getEventDetailSuccess.results.rows,
        userWineryStatus:
          nextProps.getEventDetailSuccess.userWineryStatus.isActive,
      };
    } else {
      return null;
    }
  }

  gotoPreviousPage = () => {
    this.props.history.goBack();
  };

  openHomePage = () => {
    this.props.history.push("/discover");
  };

  renderEventCards = () => {
    let result = [];
    if (this.state.eventData.length > 0) {
      result.push(
        this.state.eventData.map((el, index) => (
          <EventCard
            getEventsAgain={this.getEventsAgain}
            wineryData={this.state.wineryData}
            eventData={this.state.eventData[index]}
            id={index}
            key={el.id}
          />
        ))
      );
    }
    return result;
  };

  renderEmptyState = () => {
    let result = [];
    result.push(
      <div
        className="empty-state-master-container"
        key={this.state.eventData.length}
      >
        <div className="empty-state-container">
          <img
            className="empty-state-image"
            src={emptyImage}
            alt="empty state image"
          />
        </div>
        <div className="empty-state-text">{"Events Coming Soon"}</div>
      </div>
    );
    return result;
  };

  chatClicked = () => {
    this.setState({
      chatStatus: true,
    });
  };
  switchWineryClicked = () => {
    this.setState({
      switchWineryStatus: true,
    });
  };

  gotoWineryDetailsPage = () => {
    this.setState({
      gotoWineryDetailsPageStatus: true,
    });
  };

  getEventsAgain = () => {
    this.props.GetEventsAction.GetEvents();
  };

  render() {
    const {
      state: { opened },
    } = this;
    let id = this.state.eventData.id;
    let wineryName = localStorage.getItem("winery_name");
    return (
      <React.Fragment>
        <SideNavBar
          closeMenu={this.closeMenu}
          showSideMenu={this.state.showSideMenu}
        ></SideNavBar>
        <div
          className={
            this.state.eventData.length > 0
              ? "events-main-container-grey"
              : "events-main-container-white"
          }
        >
          {!isMobile && (
            <React.Fragment>
              <div className="top-navigation">
                <TopNavigation eventsActive="true" />
              </div>
              <div className="dsktp-header"></div>
            </React.Fragment>
          )}
          {isMobile && (
            <div className="accordian-container">
              <div className="common-page-header">
                <div className="common-page-sub-header new-common-header-change">
                  {/* 16-Oct-2020 Commenting back arrow - main pages doesn't need it */}
                  {/* <img
                alt="Open Recommendations page"
                className="common-sub-header-back-icon"
                onClick={() => { this.gotoPreviousPage() }}
                src={backBtn}
              /> */}
                  <div className="hamburger-menu">
                    <MenuIcon
                      style={{ fill: "white", fontSize: "20" }}
                      onClick={() => {
                        this.toggleMenu();
                      }}
                    ></MenuIcon>
                  </div>
                  <div className="new-header-text">
                    <span className="common-sub-header-text new-search-wine-for-dish-text">
                      Events
                    </span>
                  </div>
                  <img
                    alt="PairAnything"
                    className="common-sub-header-logo new-logo-header-design ml-0"
                    src={pairAnythingLogo}
                    onClick={() => {
                      this.openHomePage();
                    }}
                  />
                </div>
              </div>
              <div
                {...{
                  className: `switch-winery-main-container shadow accordion-item , ${
                    opened && "accordion-item--opened"
                  }`,
                }}
              >
                <div
                  {...{
                    className:
                      "switch-winery-switch-winery-accordion-item__line accordion-item__line",
                  }}
                  style={{ background: "#ffffff" }}
                >
                  <div className="recommendation-history-card-accordion-header">
                    <span className="switch-winery-summary-container">
                      <img
                        src={this.state.wineryLogo}
                        className="switch-winery-logo"
                      />
                      <span className="switch-winery-name">
                        {this.state.wineryName}
                      </span>
                    </span>
                  </div>

                  <span
                    {...{
                      className:
                        "switch-winery-accordion-item__icon accordion-item__icon ptr-clss",

                      onClick: (e) => {
                        // this.getResponseForQuestionFromWineryAdmin(recommendationQueryData.id)

                        this.setState({
                          opened: !opened,
                        });
                        e.stopPropagation(); // to stop parent div onclick to get fired so as to enable arrow up/down
                      },
                    }}
                  />
                </div>

                <div className="switch-winery-accordion-item__inner  accordion-item__inner">
                  <div className="col-xs-12 show-grid mb-3 p-0">
                    <div
                      className="col-xs-10 switch-winery-description"
                      onClick={this.gotoWineryDetailsPage.bind(this)}
                    >
                      {/* <span onClick={this.gotoWineryDetailsPage.bind(this)}> */}
                      {/* {paragraph} */}
                      {this.state.wineryMessage != "" &&
                      this.state.wineryMessage != null
                        ? this.state.wineryMessage
                        : "Winery message coming soon"}
                      {/* </span> */}
                    </div>
                    <div className="col-xs-2 chat-switch-winery">
                      <img
                        className={
                          this.state.errorMessage ===
                          "Something went wrong. Please try again later"
                            ? "chat-winery-icon-disabled"
                            : "switch-winery-icon ptr-clss"
                        }
                        src={chatIcon}
                        onClick={this.chatClicked.bind(this)}
                      />
                      <img
                        className="switch-winery-icon ptr-clss mt-5"
                        src={switchWineryIcon}
                        onClick={this.switchWineryClicked.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.eventData ? (
            this.state.userWineryStatus === false ? (
              <div className="events-main-container-white">
                <div className="recommendation-history-main-container-events-page">
                  <div className="error-boundary-main-container mt-5">
                    <span className="error-boundary-title">
                      {"Your email is no longer associated with " +
                        wineryName +
                        ", please contact the winery to gain access again."}
                    </span>
                    <div className="error-boundary-image-container">
                      <img
                        alt="Something went wrong"
                        className="error-boundary-image"
                        src={errorBoundaryImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.eventData.length > 0 ? (
              <div className="available-events-container column" key={id}>
                {this.renderEventCards()}
              </div>
            ) : (
              <div className="empty-state-main-container">
                {this.renderEmptyState()}
              </div>
            )
          ) : null}
          {isMobile && (
            <div className="bottom-navigation shadow p-3 bg-white rounded">
              <BottomNavigation eventsActive="true" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  toggleMenu = () => {
    this.setState({
      showSideMenu: !this.state.showSideMenu,
    });
  };
  closeMenu = () => {
    this.setState({
      showSideMenu: false,
    });
  };
}

// REDUX

function mapStateToProps(state, props) {
  return {
    getEventDetailSuccess: state.GetEventsReducer.getEventDetailSuccess,
    getEventDetailFail: state.GetEventsReducer.getEventDetailFail,
    getWineryDetailsSuccess: state.WineryReducer.getWineryDetailsSuccess,
    getWineryDetailFail: state.WineryReducer.getWineryDetailsFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetEventsAction: bindActionCreators(GetEventsAction, dispatch),
    WineryAction: bindActionCreators(WineryAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
