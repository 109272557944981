import React from "react";
import styles from "./animation-wrapper.module.scss";

export default function AnimationWrapper({
  children,
  active,
  animation,
  gray = false,
  gapDelete,
  gapOnActive = "",
  gapOnInactive = ""
}) {

  if (animation == "collapseHorizontal") {
    return (
      <div
        className={`${active ? styles.collapseH : null}`}
      >
        {<div className={`${styles.collapseHorizontalItem}`}>{children}</div>}
      </div>
    );
  }
  if (animation == "collapse") {
    return (
      <div
        style={gray ? { backgroundColor: "#EFEFEF" } : null}
        className={`${styles.collapseWrapper} ${
          active ? styles.collapse : null
        }`}
      >
        <div className={`${styles.animationExpandable}`}>{children}</div>
      </div>
    );
  }

  if (animation == "expand" && gapOnInactive == "top") {
    return (
      <div
        className={`${styles.expandWrapper} ${active ? styles.expand : null}`}
        style={!active && gapDelete ? { marginTop: gapDelete } : null}
      >
        <div className={`${styles.animationExpandable}`}>{children}</div>
      </div>
    );
  }

  if (animation == "expand" && gapOnActive == "bottom") {
    return (
      <div
        className={`${styles.expandWrapper} ${active ? styles.expand : null}`}
        style={active && gapDelete ? { marginBottom: gapDelete } : null}
      >
        <div className={`${styles.animationExpandable}`}>{children}</div>
      </div>
    );
  }

  if (animation == "expand") {
    return (
      <div
        className={`${styles.expandWrapper} ${active ? styles.expand : null}`}
      >
        <div className={`${styles.animationExpandable}`}>{children}</div>
      </div>
    );
  }

  if (animation == "expand" && gapOnInactive == "top") {
    return (
      <div
        className={`${styles.expandWrapper} ${active ? styles.expand : null}`}
        style={!active && gapDelete ? { marginTop: gapDelete } : null}
      >
        <div className={`${styles.animationExpandable}`}>{children}</div>
      </div>
    );
  }

  if (animation == "fromLeft") {
    return <div className={`${styles.slideFromLeft}`}>{children}</div>;
  }

  if (animation == "fromRight") {
    return <div className={`${styles.slideFromRight}`}>{children}</div>;
  }

  if (animation == "fadeIn") {
    return <div className={`${styles.faded} ${active ? styles.unfaded : null}`}>{children}</div>;
  }

  if (animation == "fadeOut") {
    return <div className={`${styles.unfaded2} ${active ? styles.faded2 : null}`}>{children}</div>;
  }

  if (animation == "none") {
    return <div>{children}</div>;
  }
}
