import * as types from './action-types';
import * as constants from '../../constants/constants'

export const GetMyEvents = () => {

    // let wineryId = (JSON.parse(localStorage.getItem('winery_id')));

    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;


    return dispatch => {
        fetch(constants.EVENT_API + '/api/v1/users/events?sortBy=startTime&sortType=ASC&isAttending=true', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            },

        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.GET_MY_EVENT_DETAIL_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.GET_MY_EVENT_DETAIL_FAILED, payload: error })
        })
    }

}
