import WineHist from "./WineHistory.js";
import React, { Component } from "react";
import ReactGA from "react-ga4";
import "./PARecommendationDesktop.scss";
import PALogo from "../../../assets/images/search-wine-pa-logo.svg";
import wineIconGrey from "../../../assets/images/wine_icon_grey.svg";
import wineIconRed from "../../../assets/images/wine_icon_red.svg";
import foodIconGrey from "../../../assets/images/food_icon_grey.svg";
import foodIconRed from "../../../assets/images/food_icon_red.svg";
import NewSearchIcon from "../../../assets/images/newSearchIcon.svg";
import * as constants from "../../constants/constants";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import recommendationResponseImage from "../../../assets/images/EmptyPageCard/recommendation-response-image.svg";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import {
  getAllFoodRequestDetails,
  getFoodRequestDetails,
  postFoodRequest,
  wineFoodPairingAdd,
  getWinePairingHistory,
  getFoodResult,
  addNoData,
  getVarietalResult,
} from "./action";
import axiosCall from "../../constants";
import moment from "moment";
import RecommendationHistoryCardAccordian from "../../components/RecommendationHistoryCardAccordian/RecommendationHistoryCardAccordian";
import PARecommendationHistoryAccordian from "../../components/PARecommendationHistoryAccordian/PARecommendationHistoryAccordian";
import MenuIcon from "@material-ui/icons/Menu";
import emptyCardSearchImage from "../../../assets/images/EmptyPageCard/recommendation-screen-image.svg";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import WineSearchCard from "../../components/WineSearchCard/WineSearchCard";
import * as commonFunctions from "../../constants/commonFunctions";
import { isMobile } from "react-device-detect";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import RateRecommendationPopup from "../../components/RateRecommendationPopup/RateRecommendationPopup";
import QuizUsersPopup from "../../components/QuizUsersPopup/QuizUsersPopup";
import ScottHarveyHeader from "../../components/CommonComponents/ScottHarveyHeader";
import SignInToContinue from "../../components/CommonComponents/SignInToContinue";
import PAResultsComponent from "../../components/PairingResults/PairingResultsComponent";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon.js";
import bottle from "../../../assets/images/individual-wine-bottle.png";
import { connect } from "react-redux";
import { async } from "rxjs";
import { recomposeColor } from "@material-ui/core";
import PoweredByHeader from "../../components/PoweredByHeader/PoweredByHeader";
import { isEmbedded } from "../../constants/commonFunctions";
import PARecommendationEntrance from "../../components/PARecommendationEntrance/PARecommendationEntrance";
// import { setpaReco } from "../../redux/actions/PARecommendationAction";
import { getPriority } from "../../constants/priority";
import { get } from "../../lib/fetch.jsx";

class PARecommendationDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 900,
      height: 0,
      idxFood: 0,
      idxWine: 0,
      idx: 0,
      capIdx: 3,
      topClearance: 200,
      getFoodReqHistoryApiCallDone: false,
      getWinePairingHistoryApiCallDone: false,
      showSideMenu: false,
      foodRecommendationHistory: [],
      otherRecommendationHistory: [],
      errorMessage: "",
      buttonFoodActive: true,
      buttonWineActive: false,
      wineSearchResult: null,
      emptyWineresult: false,
      searchResult: [],
      wineRecommendationHistory: [],
      foundFoods: [],
      foundVarietals: [],
      userInput: "",
      showResult: false,
      showVarietalResult: false,
      showNoFood: false,
      dishName: "",
      dishNameStatus: "",

      query: "",
      entrance: true,
      showWineHist: true,
      showWineResult: false,
      showFoodResult: false,
      showFoodHist: true,
      uniqueWines: [],
      itemClicked: "",
      itemRecommended: "",
      recipeUrl: "",
      wineImageUrl: "",
      winePicUrls: [],
      foodRecipeUrls: [],
      showCheckBackLater: false,
      showNoReco: false,
      emptyCardData: {},
    };
    this.onFoodHistClick = this.onFoodHistClick.bind(this);
    this.onWineClick = this.onWineClick.bind(this);
  }

  setEntranceState = (state) => {
    this.setState({
      ...this.state,
      entrance: state,
    });
  };
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount = async () => {
    window.addEventListener("resize", this.updateDimensions);
    GoogleAnalyticsFunctions.initializeGA();
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    this.getWinePictures();

    let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/foodPairings/allFoodPairings`;
    get(url)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          foodRecipeUrls: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    if (
      this.props.location.state &&
      this.props.location.state.addToCartAutomatically
    ) {
      if (this.props.location.state.addToCartAutomatically.buttonFoodActive)
        this.onSuggestedFoodClick({
          currentTarget: {
            innerText:
              this.props.location.state.addToCartAutomatically.dishName,
          },
        });
      else {
        this.setState(
          {
            buttonFoodActive:
              this.props.location.state.addToCartAutomatically.buttonFoodActive,
            buttonWineActive:
              !this.props.location.state.addToCartAutomatically
                .buttonFoodActive,
            dishName: this.props.location.state.addToCartAutomatically.dishName,
          },
          () => this.getWineryRecommendations()
        );
      }
    }
    console.log(this.props.paReco);
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let sessionData = JSON.parse(localStorage.getItem("anonymous_session"));
    let getFoodRecoUrl =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/public/recommendation/getAllPARecoRequests";
    let getOtherRecoURL =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/public/recommendation/getAllPARecoRequests";

    if (consumerData && consumerData != null) {
      getFoodRecoUrl = getFoodRecoUrl + "?userId=" + consumerData.id;
    } else {
      getFoodRecoUrl = getFoodRecoUrl + "?userSessionId=" + sessionData.id;
    }
    try {
      const foodHistory = await getAllFoodRequestDetails(getFoodRecoUrl);
      let RfoodHistory = foodHistory.filter((element, index) => {
        if (index == 0) {
          return true;
        }
        if (
          element.request == foodHistory[index - 1].request &&
          element.userId == foodHistory[index - 1].userId
        ) {
          return false;
        } else {
          return true;
        }
      });
      console.log(RfoodHistory);
      this.setState({
        foodRecommendationHistory: RfoodHistory,
        getFoodReqHistoryApiCallDone: true,
      });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      if (consumerData && consumerData != null)
        this.setState({
          errorMessage: "Something went wrong.Please try again later.",
        });
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getFoodRecoUrl,
        operation: "GET",
      };
      if (consumerData && consumerData != null)
        commonFunctions.postCaughtException(
          JSON.stringify(apiErrorObj),
          consumerData,
          null
        );
    }
    try {
      let otherHistory = await getAllFoodRequestDetails(getOtherRecoURL);
      otherHistory = otherHistory.filter((x) => x.rating > 2);
      if (
        this.state.foodRecommendationHistory.length > 0 &&
        otherHistory[0].userId == this.state.foodRecommendationHistory[0].userId
      ) {
        otherHistory.shift();
      }
      this.setState({
        otherRecommendationHistory: otherHistory,
      });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      if (consumerData && consumerData != null)
        this.setState({
          errorMessage: "Something went wrong.Please try again later.",
        });
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getFoodRecoUrl,
        operation: "GET",
      };
      if (consumerData && consumerData != null)
        commonFunctions.postCaughtException(
          JSON.stringify(apiErrorObj),
          consumerData,
          null
        );
    }
    // } else {
    //   this.setState({
    //     foodRecommendationHistory: this.props.paReco,
    //     getFoodReqHistoryApiCallDone: true,
    //   });
    // }
    const history = await getWinePairingHistory();
    this.setState({
      wineRecommendationHistory: history,
      getWinePairingHistoryApiCallDone: true,
      uniqueWines: [
        ...new Map(
          history.map((item) => [item["foodPairingId"], item])
        ).values(),
      ],
    });

    // get clearance for recommendation items
    // let headerClearance = document.getElementById('search-wine-for-dish-header').clientHeight;
    // let mainClearance = document.getElementById('switch-winery-main-container').clientHeight
    // subtract 65 for ipad, min clearance of 235 for small size device (iPhone 5 / SE)
    // let totalClearance = Math.max(headerClearance - 65, 235);
    this.setState({
      topClearance: 190,
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  //Function for when a wine in the wine search history is clicked
  onWineClick(e) {
    console.log(e);
    this.setState({
      showWineHist: false,
      showWineResult: true,
      showNoReco: false,
      showCheckBackLater: false,
      itemClicked: e.target.getAttribute("data-name"),
      recipeUrl: e.target.getAttribute("data-url"),
      itemRecommended: e.target.getAttribute("data-reco"),
      wineImageUrl: e.target.getAttribute("data-image-url"),
    });
  }

  //Function that gets all the wine pictures from Scott Harvey
  getWinePictures = () => {
    let url = `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`;
    get(url)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          winePicUrls: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  findWineImage(varietalName, varietalProfiles) {
    return varietalProfiles[varietalName.trimEnd()].wine_url;
  }

  openHomePage = () => {
    this.props.history.push("/discover");
  };
  async handleChange(event) {
    const keyword = event.target.value;
    this.setState({
      dishName: keyword,
      showWineResult: false,
    });

    if (keyword.length >= 2) {
      const foundVarietals = await getVarietalResult(keyword);

      if (foundVarietals.length === 0) {
        this.setState({ showNoWine: true });
      } else {
        this.setState({ showNoWine: false });
      }

      this.setState({ foundVarietals, showVarietalResult: true });
    } else {
      this.setState({ showVarietalResult: false });
    }
  }
  async onInputChangeFood(e) {
    this.setState({
      dishName: e.currentTarget.value,
      showFoodResult: false,
      showCheckBackLater: false,
      showNoReco: false,
    });

    const keyword = e.currentTarget.value;
    if (keyword.length >= 2) {
      const foundFoods = await getFoodResult(keyword);

      if (foundFoods.length === 0) {
        this.setState({ showNoFood: true }, async () => {
          // const addData = await addNoData(this.state.dishName, {userId: id});
        });
      } else {
        this.setState({ showNoFood: false });
      }

      this.setState({ foundFoods, showResult: true });
    } else {
      this.setState({ showResult: false });
    }

    if (this.state.dishName.length === 0 && this.state.foundFoods.length > 0) {
      this.setState({ showResult: false });
    } else if (
      this.state.dishName.length === 0 &&
      this.state.foundFoods.length === 0
    ) {
      this.setState({ showResult: false, showNoFood: false });
    }
  }

  hidePopup() {
    this.setState({
      showResult: false,
      showVarietalResult: false,
      showNoFood: false,
    });
  }

  onSuggestedWineClick(value) {
    this.setState(
      {
        dishName: value.currentTarget.innerText,
        itemClicked: value.currentTarget.innerText,
      },
      () => {
        this.renderWineResult();
      }
    );
    this.hidePopup();
  }

  onSuggestedFoodClick(value) {
    this.setState(
      {
        dishName: value.currentTarget.innerText,
        itemClicked: value.currentTarget.innerText,
      },
      () => {
        this.renderFoodResult();
      }
    );
    this.hidePopup();
  }

  //Function for clicking the food button
  foodclick = async () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let sessionData = JSON.parse(localStorage.getItem("anonymous_session"));
    let getFoodRecoUrl =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/public/recommendation/getAllPARecoRequests";
    if (consumerData && consumerData != null) {
      getFoodRecoUrl = getFoodRecoUrl + "?userId=" + consumerData.id;
    } else {
      getFoodRecoUrl = getFoodRecoUrl + "?userSessionId=" + sessionData.id;
    }
    this.setState({
      showNoReco: false,
      buttonFoodActive: true,
      buttonWineActive: false,
      showFoodHist: true,
      showFoodResult: false,
      dishName: "",
      emptyWineresult: false,
      wineSearchResult: null,
      showWineResult: false,
      showCheckBackLater: false,
      showVarietalResult: false,
      showNoFood: false,
      foundFoods: [],
      foundVarietals: [],
    });
    const foodHistory = await getAllFoodRequestDetails(getFoodRecoUrl);
    this.setState({
      foodRecommendationHistory: foodHistory,
      getFoodReqHistoryApiCallDone: true,
    });
  };

  //Function for clicking the wine button
  wineclick = () => {
    this.setState({
      buttonFoodActive: false,
      showWineHist: true,
      showNoReco: false,
      showWineResult: false,
      showFoodResult: false,
      showFoodHist: false,
      buttonWineActive: true,
      foundFoods: [],
      foundVarietals: [],
      dishName: "",
      showCheckBackLater: false,
      showNoFood: false,
    });
  };

  //Function for clicking on an item in the food search history
  onFoodHistClick = (e) => {
    let rUrl = this.getRecipeUrl(e.target.getAttribute("data-name"));
    this.setState({
      showFoodHist: false,
      showFoodResult: true,
      recipeUrl: rUrl,
      itemClicked: e.target.getAttribute("data-name"),
      itemRecommended: e.target.getAttribute("data-reco"),
      wineImageUrl: e.target.getAttribute("data-image-url"),
    });
  };

  getWineryRecommendations = async () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let { dishName } = this.state;
    if (dishName !== "") {
      if (dishName.length >= 3) {
        const data = await wineFoodPairingAdd(dishName);
        if (data.length == 0) {
          this.setState({
            showNoReco: true,
            showWineResult: false,
            emptyCardData: {
              title: "No Recommendations available",
              text: "Please Enter another search",
              image: emptyCardSearchImage,
            },
          });
        } else {
          this.setState(
            { wineSearchResult: data, emptyWineresult: false },
            async () => {
              const history = await getWinePairingHistory();
              this.setState({
                showNoReco: false,
                wineRecommendationHistory: history,
                getWinePairingHistoryApiCallDone: true,
                uniqueWines: [
                  ...new Map(
                    history.map((item) => [item["foodPairingId"], item])
                  ).values(),
                ],
              });
            }
          );
        }
      } else {
        this.setState({ emptyWineresult: true });
      }
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.location.state,
        addToCartAutomatically: undefined,
      });
    }
  };

  getFoodClickedData = async (requestId) => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let getRequestDetailsUrl =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/public/recommendation/paRequest/" +
      requestId;
    try {
      const recommendedDetails = await getFoodRequestDetails(
        getRequestDetailsUrl
      );
      // if (consumerData && consumerData == null) {
      // } else this.props.dispatch(setpaReco([recommendedDetails]));
      console.log(recommendedDetails);
      return recommendedDetails;
    } catch (e) {
      this.setState({
        errorMessage: "Something went wrong.Please try again later.",
      });

      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getRequestDetailsUrl,
        operation: "GET",
        status: e.status,
      };
      if (consumerData && consumerData != null)
        commonFunctions.postCaughtException(
          JSON.stringify(apiErrorObj),
          consumerData,
          null
        );
    }
  };

  getRecipeUrl = (dishName) => {
    let list = this.state.foodRecipeUrls;
    for (let i = 0; i < list.length; i++) {
      if (list[i].foodName == dishName) {
        return list[i].RecipeUrl;
      }
    }
  };

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  //Function to render food result when searching/clicking on suggested food
  renderFoodResult = async () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let sessionData = JSON.parse(localStorage.getItem("anonymous_session"));

    // if (consumerData && consumerData != null) {
    if (this.state.dishName != "") {
      console.log("here2");
      if (this.state.showNoFood) {
        const addData = await addNoData(this.state.dishName, {
          userSessionId: sessionData ? sessionData?.id : null,
        });
      }
      this.hidePopup();
      GoogleAnalyticsFunctions.clickedAskPARecommendation();

      let url =
        constants.GOOGLE_SEARCH_API +
        "/api/v1/public/recommendation/create/paRequest";
      try {
        let postData = {};
        if (consumerData && consumerData != null) {
          postData = {
            request: this.state.dishName,
            userId:
              consumerData && consumerData != null
                ? consumerData.id
                : undefined,
          };
        } else {
          postData = {
            request: this.state.dishName,
            userSessionId: sessionData.id,
          };
        }
        const path = `${url}`;
        const recommendationDetails = await postFoodRequest(path, postData);
        let requestId;
        if (recommendationDetails.id) {
          requestId = recommendationDetails.id;
        } else {
          requestId = recommendationDetails.match.params.id;
        }
        const recommendationData = await this.getFoodClickedData(requestId);
        let items = recommendationData.pa_reco_items;

        if (localStorage.getItem("checkOut") === "false") {
          const checkedInTime = localStorage.getItem("checkIn");
          const currentTime = new Date().getTime() - checkedInTime;
          const duration = this.millisToMinutesAndSeconds(currentTime);
          ReactGA.event({
            category: "engagement",
            action: "search_bar",
            value: duration,
          });
          setTimeout(() => {
            localStorage.removeItem("checkIn");
            localStorage.removeItem("checkOut");
            localStorage.removeItem("firstVisit");
          }, 1000);
        }

        let trackedItems = localStorage.getItem("recommendation_tracked");
        if (!trackedItems) trackedItems = {};
        else trackedItems = JSON.parse(trackedItems);

        if (items?.length > 0) {
          let trackedRecommendationData =
            trackedItems[recommendationData.request] || [];
          if (trackedRecommendationData.length < items.length) {
            items = items.filter(
              (i) => !trackedRecommendationData.includes(i.varietalId)
            );
          } else {
            trackedRecommendationData = [];
          }

          const selectedItem = items[0];
          let winePic = this.findWineImage(
            selectedItem.varietalName,
            this.state.winePicUrls
          );
          let rUrl = this.getRecipeUrl(recommendationData.request);
          this.setState({
            itemClicked: recommendationData.request,
            recipeUrl: rUrl,
            wineImageUrl: winePic,
            itemRecommended:
              selectedItem.wineTypeName + " " + selectedItem.varietalName,
            showFoodResult: true,
            showFoodHist: false,
          });

          trackedRecommendationData.push(selectedItem.varietalId);
          trackedItems[recommendationData.request] = trackedRecommendationData;
          localStorage.setItem(
            "recommendation_tracked",
            JSON.stringify(trackedItems)
          );
        } else {
          if (recommendationData.noRecoExcludedFoodName) {
            this.setState({
              emptyCardData: {
                title: "",
                text: `We don't recognize '${commonFunctions.capitalize(
                  recommendationData.request
                )}' as a food item. Please enter a different search.`,
                image: "",
              },
              showCheckBackLater: true,
              showFoodHist: false,
            });
          } else {
            this.setState({
              emptyCardData: {
                title: "",
                text: "Thank you for your request. Check back shortly for your recommendation.",
                image: recommendationResponseImage,
              },
              showCheckBackLater: true,
              showFoodHist: false,
            });
          }
        }
      } catch (e) {
        console.log("here4");
        this.props.history.replace(this.props.location.pathname, {
          ...this.props.location.state,
          addToCartAutomatically: undefined,
        });
        this.setState({
          errorMessage: "Something went wrong.Please try again later.",
        });
        let consumerData = JSON.parse(localStorage.getItem("user_data"));
        let apiErrorObj = {
          statusText: e.statusText,
          type: e.type,
          status: e.status,
          url: url,
          operation: "POST",
          status: e.status,
        };
        if (consumerData && consumerData != null)
          commonFunctions.postCaughtException(
            JSON.stringify(apiErrorObj),
            consumerData,
            null
          );
      }
    } else {
      this.setState({
        dishNameStatus: "Please enter a dish name",
      });
    }
  };

  renderWineResult = async () => {
    let { dishName } = this.state;
    if (dishName !== "") {
      if (dishName.length >= 3) {
        let data = await wineFoodPairingAdd(dishName);
        if (data.length == 0) {
          this.setState({
            showNoReco: true,
            emptyCardData: {
              title: "No Recommendations available",
              text: "Please Enter another search",
              image: emptyCardSearchImage,
            },
          });
        } else {
          let trackedItems = localStorage.getItem(
            "recommendation_tracked_wine"
          );
          if (!trackedItems) trackedItems = {};
          else trackedItems = JSON.parse(trackedItems);

          let trackedRecommendationData = trackedItems[dishName] || [];
          if (trackedRecommendationData.length < data.length) {
            data = data.filter(
              (i) => !trackedRecommendationData.includes(i.id)
            );
          } else {
            trackedRecommendationData = [];
          }

          data = data.sort((a, b) => {
            return -(getPriority(a.vendor) - getPriority(b.vendor));
          });

          const selectedItem = data[0];

          trackedRecommendationData.push(selectedItem.id);
          trackedItems[dishName] = trackedRecommendationData;
          localStorage.setItem(
            "recommendation_tracked_wine",
            JSON.stringify(trackedItems)
          );

          this.setState(
            {
              wineSearchResult: data,
              emptyWineresult: false,
              showWineHist: false,
              showWineResult: true,
              itemClicked:
                selectedItem.wineType + " " + selectedItem.varietalName,
              recipeUrl: selectedItem.RecipeUrl,
              itemRecommended: selectedItem.foodName,
              wineImageUrl: this.findWineImage(
                selectedItem.varietalName,
                this.state.winePicUrls
              ),
            },
            async () => {
              const history = await getWinePairingHistory();
              this.setState({
                showNoReco: false,
                wineRecommendationHistory: history,
                getWinePairingHistoryApiCallDone: true,
                uniqueWines: [
                  ...new Map(
                    history.map((item) => [item["foodPairingId"], item])
                  ).values(),
                ],
              });
            }
          );
        }
      } else {
        this.setState({ emptyWineresult: true });
      }
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.location.state,
        addToCartAutomatically: undefined,
      });
    }
  };

  render() {
    const {
      foundFoods,
      foundVarietals,
      showResult,
      showVarietalResult,
      entrance,
      showFoodResult,
      showWineResult,
      showNoFood,
      buttonFoodActive,
      query,
    } = this.state;
    const recStyle = {
      paddingTop: `${this.state.topClearance}px`,
    };

    // if (isEmbedded() && entrance) return <PARecommendationEntrance setShowResult={this.setEntranceState} query={query}/>

    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className="pairings-main-container-desktop">
            {!isEmbedded() && (
              <HeaderWithBurgerIcon title="Pairing Recommendations" />
            )}

            {/* <QuizUsersPopup openDialog={true} initial={true} /> */}
            <div
              className={
                isEmbedded() ? "search-main-desktop" : "search-main-desktop"
              }
              style={{ width: this.state.width - 300 }}
            >
              {/*Powered by pairanything flavor text*/}
              {/* <div className = "pairing-result-logo-container">
                            <span className="logo-desc">POWERED BY</span>
                            <img src = {PALogo} alt="PA-logo" className= "PA-logo-pairing"/>
                            <span className="logo-desc" style={{color: "#e7482a"}}>PAIR</span>
                            <span className="logo-desc" style={{color: "#93939"}}>ANYTHING</span>
                        </div> */}
              <div className="pairing-result-search-container">
                <span className="pairing-result-search-bar-text">
                  WHAT PAIRS WITH...
                </span>
                <div className="result-search-bar-container">
                  <input
                    className="pairing-search-bar"
                    placeholder={
                      this.state.buttonFoodActive
                        ? "Search by Food"
                        : "Search by Wine type or varietal"
                    }
                    type="text"
                    name="dishname"
                    value={this.state.dishName}
                    onChange={
                      buttonFoodActive == true
                        ? this.onInputChangeFood.bind(this)
                        : this.handleChange.bind(this)
                    }
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? this.state.buttonFoodActive
                          ? this.renderFoodResult()
                          : this.renderWineResult()
                        : ""
                    }
                  ></input>
                  <img
                    src={NewSearchIcon}
                    alt="search-icon"
                    className="new-search-icon"
                  ></img>
                </div>
              </div>
              <div className="food-wine-btn-wrapper">
                <div className="btn-group recommendation-tab-input search-wine-form-tab-input food-wine-btn-container-desktop">
                  <button
                    className="btn btn-round search-btn-tab"
                    style={{
                      backgroundColor: `rgba(${
                        this.state.buttonFoodActive
                          ? "168,69,81"
                          : "255,255,255"
                      }, 0.08)`,
                      color: this.state.buttonFoodActive
                        ? "#951901"
                        : "#7D7D7D",
                      borderRadius: "50px 0px 0px 50px",
                      ...(isEmbedded() && {
                        borderColor: this.state.buttonFoodActive
                          ? "#951901"
                          : "#7D7D7D",
                      }),
                    }}
                    onClick={() => this.foodclick()}
                  >
                    FOODS
                    <img
                      className="btn-icons"
                      src={
                        this.state.buttonFoodActive ? foodIconRed : foodIconGrey
                      }
                    ></img>
                  </button>
                  <button
                    className="btn btn-round search-btn-tab"
                    style={{
                      backgroundColor: `rgba(${
                        this.state.buttonWineActive
                          ? "168,69,81"
                          : "255,255,255"
                      }, 0.08)`,
                      color: this.state.buttonWineActive
                        ? "#951901"
                        : "#7D7D7D",
                      borderRadius: "0px 50px 50px 0px",
                      ...(isEmbedded() && {
                        borderColor: this.state.buttonWineActive
                          ? "#951901"
                          : "#7D7D7D",
                      }),
                    }}
                    onClick={() => this.wineclick()}
                  >
                    WINES
                    <img
                      className="btn-icons"
                      src={
                        this.state.buttonWineActive ? wineIconRed : wineIconGrey
                      }
                    ></img>
                  </button>
                </div>
              </div>
              {showWineResult && (
                <PAResultsComponent
                  name={this.state.itemClicked}
                  wineType={this.state.itemClicked}
                  recipeUrl={this.state.recipeUrl}
                  wineImageUrl={this.state.wineImageUrl}
                  recommendation={this.state.itemRecommended}
                  isFood={false}
                ></PAResultsComponent>
              )}

              {showFoodResult && (
                <PAResultsComponent
                  name={this.state.itemClicked}
                  wineType={this.state.itemClicked}
                  recipeUrl={this.state.recipeUrl}
                  wineImageUrl={this.state.wineImageUrl}
                  recommendation={this.state.itemRecommended}
                  isFood={true}
                ></PAResultsComponent>
              )}

              {this.state.showCheckBackLater && (
                <EmptyPageCard emptyPageCardData={this.state.emptyCardData} />
              )}

              {this.state.showNoReco && (
                <EmptyPageCard emptyPageCardData={this.state.emptyCardData} />
              )}
              <div className="desktop-div-scroll" style={{ height: "65vh" }}>
                <div className="form-group search-box">
                  {foundVarietals.length > 0 && showVarietalResult && (
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: "10%",
                        paddingRight: "10%",
                      }}
                    >
                      {foundVarietals &&
                        foundVarietals.map((item, idx) => {
                          return (
                            <div
                              className="varietal-suggestions"
                              data-name={item}
                              key={idx}
                              onClick={this.onSuggestedWineClick.bind(this)}
                            >
                              {item}
                              <div className="suggestions-bar"></div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {foundFoods.length > 0 &&
                    showResult &&
                    !showFoodResult &&
                    !showWineResult && (
                      <div
                        style={{
                          width: "100%",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                        }}
                      >
                        {foundFoods &&
                          foundFoods.map((item, idx) => {
                            return (
                              <div
                                className="food-suggestions"
                                data-name={item}
                                key={idx}
                                onClick={this.onSuggestedFoodClick.bind(this)}
                              >
                                {item}
                                <div className="suggestions-bar"></div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  {showNoFood && (
                    <div className="food-suggestions">
                      <div>No Food Matches!</div>
                      <div className="suggestions-bar"></div>
                    </div>
                  )}
                  {this.state.dishNameStatus && (
                    <div className="recommendation-input-error-message-container">
                      <span className="recommendation-input-error-message">
                        {this.state.dishName == ""
                          ? this.state.dishNameStatus
                          : ""}

                        {/* {
                                            this.state.errorMessage
                                        } */}
                      </span>
                    </div>
                  )}
                </div>
                {/*
                        #NOTE: renderRecommendationHistory renders the recommendation history card if there is
                        any recommendation. Otherwise, the EmptyPageCard is rendered
                    */}

                <div>
                  <div
                    className="search-wine-for-dish-container"
                    // style={recStyle}
                  >
                    <ErrorBoundary>
                      {this.state.buttonFoodActive
                        ? this.renderRecommendationHistory()
                        : this.renderWineRecommendationHistory()}
                    </ErrorBoundary>
                    {this.state.buttonFoodActive
                      ? this.state.idxFood <
                          this.state.foodRecommendationHistory.length -
                            this.state.capIdx && (
                          <div
                            class="reco-main"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5%",
                            }}
                          >
                            {!showFoodResult &&
                              !this.state.showCheckBackLater && (
                                <button
                                  className="btn btn-round
                                                          confirm-shipping-address-btn1 search-btn"
                                  onClick={this.renderBatch.bind(this)}
                                  style={{ fontStyle: "italic" }}
                                >
                                  More
                                </button>
                              )}
                          </div>
                        )
                      : this.state.idxWine <
                          this.state.uniqueWines.length - this.state.capIdx && (
                          <div
                            className="reco-main"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5%",
                            }}
                          >
                            {!showWineResult && (
                              <button
                                className="btn btn-round
                                                          confirm-shipping-address-btn1 search-btn"
                                onClick={this.renderBatch.bind(this)}
                                style={{ fontStyle: "italic" }}
                              >
                                More
                              </button>
                            )}
                          </div>
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SignInToContinue
            signInPopUp={this.state.signInPopUp}
            url={this.props.location.pathname}
            addToCartAutomatically={{
              buttonFoodActive: this.state.buttonFoodActive,
              dishName: this.state.dishName,
            }}
            where={"to search for Recommendations."}
            handleClose={() => this.setState({ signInPopUp: false })}
          />
        </div>
      </React.Fragment>
    );
  }

  renderRecommendationHistory = () => {
    let result = [];

    if (this.state.errorMessage) {
      return (
        <div className="recommendation-history-main-container new-error-css">
          <div className="error-boundary-main-container mt-5">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (
      this.state.foodRecommendationHistory.length > 0 &&
      this.state.showFoodHist
    ) {
      // atleast one recommendation history is there
      // let len = this.state.foodRecommendationHistory.length
      // let togo = Math.min(this.state.idx + this.state.capIdx, this.state.foodRecommendationHistory.length)
      // let tmp = []
      let togo = Math.min(
        this.state.idxFood + this.state.capIdx,
        this.state.foodRecommendationHistory.length
      );
      for (let index = 0; index < togo; ++index) {
        // for (let index = 0; index < len; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div key={index} className="recommendation-history-main-container">
              <PARecommendationHistoryAccordian
                key={index}
                apiCallDone={this.state.getFoodReqHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                foodHistClick={this.onFoodHistClick}
                pictureUrl={this.state.winePicUrls}
                recommendationQueryData={
                  this.state.foodRecommendationHistory[index]
                }
              />
            </div>
          </div>
        );
      }
      let togo1 = Math.min(
        this.state.idxFood + this.state.capIdx,
        this.state.otherRecommendationHistory.length
      );
      for (let index = 0; index < togo1; ++index) {
        // for (let index = 0; index < len; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div key={index} className="recommendation-history-main-container">
              <PARecommendationHistoryAccordian
                key={index}
                apiCallDone={this.state.getFoodReqHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                isOther={true}
                foodHistClick={this.onFoodHistClick}
                pictureUrl={this.state.winePicUrls}
                recommendationQueryData={
                  this.state.otherRecommendationHistory[index]
                }
              />
            </div>
          </div>
        );
      }
    } else if (
      this.state.getFoodReqHistoryApiCallDone &&
      this.state.showFoodHist
    ) {
      // let emptyCardData = {
      //   title: "No recent recommendations",
      //   text: "Your recent recommendations will appear here",
      //   image: emptyCardSearchImage,
      // };
      // return (
      //   <EmptyPageCard emptyPageCardData={emptyCardData} />
      // );
      let togo1 = Math.min(
        this.state.idxFood + this.state.capIdx,
        this.state.otherRecommendationHistory.length
      );
      for (let index = 0; index < togo1; ++index) {
        // for (let index = 0; index < len; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div key={index} className="recommendation-history-main-container">
              <PARecommendationHistoryAccordian
                key={index}
                apiCallDone={this.state.getFoodReqHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                isOther={true}
                foodHistClick={this.onFoodHistClick}
                pictureUrl={this.state.winePicUrls}
                recommendationQueryData={
                  this.state.otherRecommendationHistory[index]
                }
              />
            </div>
          </div>
        );
      }
    }
    return result;
  };

  renderWineRecommendationHistory = () => {
    let { wineSearchResult, emptyWineresult } = this.state;
    let result = [];
    let emptyCardData = {
      title: "No result found",
      text: "Please enter minimum three letter",
      image: emptyCardSearchImage,
    };
    const isError = this.state.errorMessage;
    const haveHistory = this.state.wineRecommendationHistory.length > 0;
    if (isError) {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container mt-5">
            {/* errorBoundaryImage */}
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (haveHistory) {
      // atleast one recommendation history is there
      // let len = this.state.wineRecommendationHistory.length
      // let togo = Math.min(this.state.idx + this.state.capIdx, this.state.wineRecommendationHistory.length)
      // let tmp = []
      let togo = Math.min(
        this.state.idxWine + this.state.capIdx,
        this.state.uniqueWines.length
      );
      for (let index = 0; index < togo; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div key={index} className="recommendation-history-main-container">
              {this.state.showWineHist && (
                <WineHist
                  key={index}
                  apiCallDone={this.state.getWinePairingHistoryApiCallDone}
                  title="hi"
                  paragraph="sample"
                  pictureUrl={this.state.winePicUrls}
                  wineClick={this.onWineClick}
                  recommendationQueryData={this.state.uniqueWines[index]}
                />
              )}
            </div>
          </div>
        );
      }
    }
    return [result];
  };

  renderBatch() {
    let togo = this.state.buttonFoodActive
      ? Math.min(
          this.state.idxFood + this.state.capIdx,
          this.state.foodRecommendationHistory.length
        )
      : Math.min(
          this.state.idxWine + this.state.capIdx,
          this.state.wineRecommendationHistory.length
        );
    let result = [];
    for (let index = 0; index < togo; ++index) {
      result.push(
        <div className="reco-main" key={index}>
          <div className="recommendations-custom-line"></div>
          <div
            key={index}
            className="recommendation-history-main-container"
            style={{ width: "93% !important" }}
            onClick={() => {
              this.changeIndex(index);
            }}
          >
            <RecommendationHistoryCardAccordian
              key={index}
              apiCallDone={
                this.state.buttonFoodActive
                  ? this.state.getFoodReqHistoryApiCallDone
                  : this.state.getWinePairingHistoryApiCallDone
              }
              title="hi"
              paragraph="sample"
              recommendationQueryData={
                // this.state.recommendationHistoryInReverse[index]
                this.state.buttonFoodActive
                  ? this.state.foodRecommendationHistory[index]
                  : this.state.wineRecommendationHistory[index]
              }
            />
          </div>
        </div>
      );
    }
    this.setState({
      idxFood: this.state.buttonFoodActive ? togo : this.state.idxFood,
      idxWine: this.state.buttonFoodActive ? this.state.idxWine : togo,
    });
  }

  toggleMenu = () => {
    this.setState({
      showSideMenu: !this.state.showSideMenu,
    });
  };
  closeMenu = () => {
    this.setState({
      showSideMenu: false,
    });
  };
}
// function mapStateToProps(state, props) {
//   // getDerivedStateFromProps gets this returned value
//   return {
//     paReco: state.paRecommendationReducer.paReco,
//   };
// }
export default connect(null, null)(PARecommendationDesktop);
