import React, { useEffect, useState, useRef } from "react";

import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./dd-pairing.module.scss";
import QRCode from "react-qr-code";
import DDPhoneForm from "./dd-phone-form";

export default function DDShareModal({ closeModal }) {
  const stopParentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`${styles.modalBackground}`} onClick={() => closeModal()}>
      <div className={styles.modalBox} onClick={(e) => stopParentClick(e)}>
        <svg
          onClick={() => closeModal()}
          xmlns="http://www.w3.org/2000/svg"
          className={styles.closeBtn}
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13 1L1 13"
            stroke="#7D7D7D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L13 13"
            stroke="#7D7D7D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className={`${styles.modalContent}`}>
          <div className={styles.qrBox}>
            <QRCode
              title="Pairing"
              value={`${window.location.href}?qr=true`}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            />
          </div>

          <DDPhoneForm type={"modal"} />
        </div>
      </div>
    </div>
  );
}
