import React, { useEffect, useState, useRef } from "react";

import * as constants from "../../../constants/constants";
import useSWR from "swr";
import { get, post, postPilotEvent } from "../../../lib/fetch";
import "../../../styles/global.scss";
import "../../../styles/util.scss";
import "../../../styles/animation.scss";
import styles from "./pairing.module.scss";
//import TDFoodCard from "./td-food-card";
import PALogo from "../../../../assets/images/PALogos/PairAnything_logo_full.svg";
import IdleTimer from "../../../components/IdleTimer/IdleTimer";
import pairingData from "../pairingData";
import { useParams } from "react-router-dom";
import TDRating from "./rating";
import { useHistory } from "react-router-dom";

export default function TDPairing() {
  const { foodId } = useParams();
  const history = useHistory();

  const [foodPairing, setFoodPairing] = useState(pairingData[Number(foodId)]);

  useEffect(() => {
    const event = async () => {
      try {
        postPilotEvent({
          eventName: "page_view",
          pageUrl: window.location.href,
        });
      } catch (e) {
        console.log(e);
      }
    };
    event();
    console.log("hi");
  }, []);

  // oooooooooo onClick Functions oooooooooooooooooooooooooooooo
  const handleBackBtnClick = async () => {
    try {
      postPilotEvent({
        eventName: "click_backButton",
        pageUrl: window.location.href,
      });
    } catch (e) {
      console.log(e);
    }

    history.push({
      pathname: `/techstars`,
    });
  };

  const postClickWinery = async (body) => {
    try {
      postPilotEvent(body);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={`${styles.main}`}>
      <div className={styles.stickyHeader}>
        <a
          className={styles.stickyHeaderTxt}
          href={`https://www.pairanything.com/techstars`}
          onClick={() => {
            postClickWinery({
              eventName: "click_knowAWinery",
              pageUrl: window.location.href,
            });
          }}
        >
          Know a winery? Let us know!
        </a>
      </div>
      {foodPairing && (
        <div className={styles.box}>
          <div className={`${styles.pairingImgBox}`}>
            <button
              className={`${styles.backBtn}`}
              style={{ position: "absolute", left: "0", top: "0" }}
              onClick={() => handleBackBtnClick()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"42"}
                height={"42"}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 12H5"
                  stroke="#7D7D7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#7D7D7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div className={`${styles.pairingImg}`}>
              <img
                className={styles.foodImg}
                src={foodPairing?.foodImg}
                alt="food"
              />
              <img
                className={styles.wineImg}
                src={foodPairing?.wineImg}
                alt="wine"
              />
            </div>
          </div>
          <h1 className={styles.titleTxt}>{foodPairing?.foodName}</h1>
          <p className={styles.pairsWithTxt}>Pairs with</p>
          <h1 className={styles.titleTxt} style={{ color: "#951901" }}>
            {foodPairing?.wineName}
          </h1>
          <p className={styles.wineDescriptionTxt}>
            {foodPairing?.wineDescription}
          </p>
          <div className={styles.lineDivider}></div>
          <TDRating foodPairing={foodPairing} />
        </div>
      )}
    </div>
  );
}
