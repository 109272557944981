import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.PUT_SHIPPING_ADDRESS_SUCCEEDED:
            return {
                ...state,
                putShippingAddressSuccess: action.payload
            };

        case types.PUT_SHIPPING_ADDRESS_FAILED:
            return {
                ...state,
                putShippingAddressFail: action.payload
            };

        default:
            return state;
    }
};
