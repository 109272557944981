import React , {Component} from 'react';
import './WineNewsFeedCard.scss';
import { Redirect } from 'react-router';
import document from '../../../assets/images/WineNewsFeed/document.svg'
import wineEvent from '../../../assets/images/WineNewsFeed/wine-event.png'
import * as constants from '../../constants/constants';


class WineNewsFeedCard extends Component {

   constructor(props) {
       super(props);
       this.state =  {

    }
   }

   
  componentDidMount = () => {

    
  }


openWineNewsFeedIndividualContent = () => {

}
 

    render() {


        // if (this.props.wineData!=null && this.props.wineData!=undefined) {
           
        //     return <Redirect to={{
        //         pathname: '/individual-wine-details',
        //         state: { 
        //                     individualWineDetailsFromAvailableWines: true,
        //                     wineIndividualData: this.props.wineData
        //                }
        //     }}/>;

        //   }


      
        return(
                        
            <div onClick = {this.openWineNewsFeedIndividualContent.bind(this)} className="card wine-news-feed-card-container shadow">               

                                    {/* { this.renderWineNewsFeedCards() } */}

             <div className="wine-news-feed-card-details">
                    
                <div className="wine-news-feed-card-header">
                    <img 
                        className = "wine-news-feed-card-feed-logo"
                        alt = "Wine news feed"
                            src = { this.props.wineFeedData.image ?  this.props.wineFeedData.image : document }
                       
                        />
                    <span className = "wine-news-feed-card-title">
                                {/* Wine articles */}
                                {
                                this.props.wineFeedData.title ?  this.props.wineFeedData.title : 'Wine articles'

                                }
                    </span>
                </div>
                   
                

                <div className="wine-news-feed-card-container">
                     
                        <span className = "wine-news-feed-card-content">
                        {
                                this.props.wineFeedData.content ?  this.props.wineFeedData.content : 'Wine Content'
                        }
                        </span>
       
                    </div>
               
            
            </div>
        </div>

                                  
        

        );
    }
}

export default WineNewsFeedCard;