/* For JIRA PAIR-1570 */

import React, {useState, useEffect, useRef} from 'react';
import styles from './ShareEventButtonPopUp.scss'
import {FacebookShareButton, FacebookIcon, 
    TwitterShareButton, TwitterIcon, 
    WhatsappShareButton, WhatsappIcon,
    LinkedinShareButton, LinkedinIcon
} from 'react-share';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { WINERY_API } from '../../constants/constants';
import { withStyles } from "@material-ui/core/styles";
import Slide from '@material-ui/core/Slide';
import copyImage from '../../../assets/images/copy-btn.svg'; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import moment from "moment";

// Transition for the pop up
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const contentStyle = {
    fontFamily: "Poppins, Regular 14px/24px sans-serif",
    fontSize: "18px",
    background: "#FFFFFF",
    justifyContent: "left",
    alignContent: "left",
    textAlign: "left",
    overflow: "hidden",
};
  
const iconStyle = {
    margin: '5px 20px 25px 0px',
    flex: '0 10% 15%'
};

const textStyle = { 
    fontSize: "18px",
    display: "flex",
    flexDirection: "row",
    padding: "10px 0 0 0",
    margin: "10px 0 6.5px 0"
}

const copyURLStyle = { 
    display: "flex",
    flexDirection: "row",
    padding: "10px 0 10px 0",
    margin: "6px 0 10px 0"
}

const copySuccessStyle = { 
    fontSize: "12px",
    margin: "-15px 0 -5px 0",
    padding: "-15px 0 -5px 0"
}

const dividerStyle = {
    marginTop: '2px',
    width: "50%",
    height: "2px",
    textAlign: "center"
}

const inputStyle = {
    display: "flex",
    background: "FFFFFF",
    border: "1px solid #CDCDCD",
    borderRadius: "3px",
    opacity: "1",
    padding: "10px 10px 10px 10px",
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    color: "#a9a9a9",
    overflow: "hidden",
    minWidth: "270px",
    width: isMobile ? "270px" : "350px",
    maxHeight: "40px",
    resize: "none",
    whiteSpace: "nowrap",
    overflowX: "scroll",
};
  
  
const GlobalCss = withStyles({
    "@global": {
    ".MuiPaper-root": {
        background: "FFFFFF",
        
        boxRadius: '10px 10px 0 0',
    },
    ".MuiBackdrop-root": {
        background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiDialog-paperFullWidth": {
        width: "100vw",
        // height: "38vh",
        height: isMobile ? "38vh" : "275px",
        marginRight: "auto",
        marginLeft: "auto"
    },
},
})(() => null);

const ShareEventButtonPopUp = ( { openDialog, initial, eventData }) => { 
    
    // useState and useEffect for handling opening/closing of Dialog box
    const [open, setOpen] = useState();
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('xs');
    const [copySuccess, setCopySuccess] = useState('');
    const url = useRef(eventData.category == "Online" ? `${eventData.liveUrl}`
                    :  (eventData.winery.displayName === "Scott Harvey Wines" ? `https://www.scottharveywines.com/Visit#events`
                    : `https://www.bakerfamilywines.com/events-2-1`))
    const txt = useRef(`Pair Anything and ${eventData.winery.displayName} are hosting ${eventData.title} event`
                    + ` on ${moment.utc(eventData.startTime).local().format("DD MMM YYYY")}.`
                    + (eventData.category == `Online` ? ` You should check it out at ${eventData.liveUrl}`
                    : ` Check out ` + (eventData.winery.displayName === "Scott Harvey Wines" ? `https://www.scottharveywines.com/Visit#events`
                    : `https://www.bakerfamilywines.com/events-2-1`) + ` website for details.`))
    // const urlText = useRef(null);
    // console.log(eventData.winery)

    // const url = eventData.category == "Online" ? `${eventData.liveUrl}` : `${eventData.venue}`
    
    useEffect(() => {
        initial ? setOpen(false) : setOpen(true)
    }, [openDialog]);

    
    const copyToClipboard = (e) => {
        setCopySuccess('Copied!');
    };

    const handleClose = () => { 
        setOpen(false);
        setCopySuccess('');
    }
    
    // const shareWineDetails = (twitter) => { 
    //     // Description of wine to share to social media
    //     let wineDetails = "";
    //     if(!wine) { 
    //         return "";
    //     }
    //     wineDetails = twitter ? "@PairAnything recommended me " : "Pair Anything recommended me "
    //     for(let i = 0; i < wine.length; i++) { 
    //         wineDetails += wine[i].name + ", a " + 
    //         wine[i].type + " wine made with " + wine[i].varietal + " grapes from " + 
    //         wine[i].appellation;

    //         if(i < wine.length - 1) { 
    //             wineDetails += " and "
    //         }
    //     }
    //     wineDetails += " from " + winery;
    //     if(wine[0].dish_pairing) {
    //         wineDetails += " to enjoy with my " + wine[0].dish;
    //     }
    //     wineDetails += ". Check it out!"
        
    //     return wineDetails;
    // }

    // const getText = () => {
    //     let txt = `Pair Anything and ${eventData.winery.displayName} are hosting ${eventData.title} event `
    //     txt += `on ${moment.utc(eventData.startTime).local().format("DD MMM YYYY")}. `
    //     txt += category == "Online" ? `You should check it out at `
    //         : `Check out ${eventData.winery.displayName} website for details.`
    //     // txt += this.props.eventData.category == "Online" ? `${this.props.eventData.liveUrl}` : `${this.props.eventData.venue}`
    //     return txt
    // }

    // getUrl() {
    //     return category == "Online" ? `${eventData.liveUrl}` : `${eventData.venue}`
    // }
    // const url = category == "Online" ? `${eventData.liveUrl}` : `${eventData.venue}`
    // const txt = this.getText()
    
    return (
        <React.Fragment>
        <GlobalCss />
        <Dialog 
            fullWidth={true}
            maxWidth={'xs'}
            open={open} 
            TransitionComponent={Transition}
            onClose={handleClose}>

            <hr className="top-divider" style={dividerStyle} /> 
            <DialogContent style={contentStyle} dividers={false} >
                <div className="copy-title-text" style={textStyle}>
                    Copy URL
                </div>
           
                {/* // url that users can copy and paste for sharing */}
                
                <div className='copy-url-area' style={copyURLStyle}>
                    <textarea style={inputStyle}
                        disabled={true}
                        readOnly={true} label="Link" align="center"
                        defaultValue={url.current} 
                    />
                    <CopyToClipboard text={url.current}>
                        <button className="copy-button" onClick={copyToClipboard}>
                            <img className="copy-image" src={copyImage} />
                        </button>
                    </CopyToClipboard>
                   
                </div>

                <div className="copy-success-text" style={copySuccessStyle}>{copySuccess}</div>
            
            <div className='sharing-title-text' style={textStyle}>
                Share with Friends
            </div>
                
                <WhatsappShareButton style={iconStyle}
                    url={txt.current}>
                    <WhatsappIcon size = {40} round={true} />
                </WhatsappShareButton>
                <FacebookShareButton style={iconStyle}
                    url={url.current}
                    quote={txt.current}
                    hashtag={"#pairanything"}> 
                    <FacebookIcon size = {40} round={true} /> 
                </FacebookShareButton>

                <TwitterShareButton style={iconStyle}
                    url={txt.current}>
                    <TwitterIcon size = {40} round={true}/>
                </TwitterShareButton>

                <LinkedinShareButton style={iconStyle}
                     url={url.current} >
                    <LinkedinIcon size = {40} round={true} />
                </LinkedinShareButton>
                

                 {/* Facebook Messenger not functional at the moment because there is no available appID */}
                
                {/* <FacebookMessengerShareButton style={iconStyle}
                     url={wine.length == 1 ?  `https://app.pairanything.com/search-wine/${wine[0].id}` : 
                    `https://app.pairanything.com`}> 
                    <FacebookMessengerIcon size = {36} />
                </FacebookMessengerShareButton> */}
                
                </DialogContent>
        </Dialog>
        </React.Fragment>
    )
}

export default ShareEventButtonPopUp