import React, { Component } from "react";

import "./SetPasswordSuccessPage.scss";

import { Redirect } from "react-router-dom";

import * as constants from "../../constants/constants";
import backBtn from "../../../assets/images/back-btn.svg";

import logo from "../../../assets/images/splash.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class SetPasswordSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInPageLoaded: false,
    };
  }

  componentDidMount = () => { };

  gotoSignInPage = () => {
    this.setState({
      signInPageLoaded: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.signInPageLoaded) {
      // return <Redirect push to="/sign-in" />;
      this.props.history.push("/sign-in");
    }
  }

  render() {
    return (
      <div className="set-password-success-container-background">
        <div className="set-password-success-main-container">
          <div className="header">
            <div className="back-btn-container">
              {/* <img className="" src={backBtn} onClick = { this.gotoSignInPage }/> */}
            </div>
            <div className="logo-container">
              <img alt="PairAnything" className="logo" src={logo} />
            </div>
            <div className="set-password-title-success">Thank You!</div>
          </div>

          <div className="forgot-password-form-parent">
            <div className="set-password-form-container">
              <div className="onboarding-text-container">
                <span className="onboarding-text">
                  Your account has been activated. Log in now for your new wine
                  experience with PairAnything.
              </span>

                <div className="set-password-success-btn-container">
                  <div className="set-password-success-btn-sub-container">
                    {" "}
                    <button
                      type="button"
                      onClick={this.gotoSignInPage}
                      //  disabled={!this.state.formValid}
                      className="btn btn-round set-password-success-btn forgot-password-btn"
                    >
                      Log In
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className = "set-password-success-btn-container"> 
                <div>  <button type="button" onClick={this.gotoSignInPage}
                                    //  disabled={!this.state.formValid}
                                    className="btn btn-round set-password-success-btn forgot-password-btn">Go to Sign In</button>  
                </div>
            </div> */}
        </div>
      </div>
    );
  }
}

export default SetPasswordSuccessPage;
