import React, { Component } from "react";
import "./SelectWinery.scss";
import "../../components/SpotlightDealsCard/SpotlightDealsCard.scss";
import { isMobile } from "react-device-detect";

import * as constants from "../../constants/constants";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import SelectWineryCard from "../../components/SelectWineryCard/SelectWineryCard";
import foodomLogo from "../../../assets/images/FoodomLogo/foodom_logo.jpg";
import vinifiedLogo from "../../../assets/images/VinifiedLogo/vinified_logo.jpg";
import axiosCall from "../../constants";
import {
  setWineryList,
  setSpotLigthDealList,
} from "../../redux/actions/WineryAction";
import { connect } from "react-redux";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import Slider from "react-slick";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import moment from "moment";
import SpotlightDealsCard from "../../components/SpotlightDealsCard/SpotlightDealsCard";
class SelectWinery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiHit: false,
      chatBtnClickedStatus: false,
      wineData: [],
      availableWineList: [],
      wineryDetails: "",
      userWineryArray: [],
      wineriesPerSwipe: [],
      wineriesPerSwipeDesktop: [],
      wineryDetails: [],
      spotlightDeals: [],
      errorDataFromSelectedWinery: "",
      staticFoodomData: {
        displayName: "Foodom",
        isActive: true,
        logo: foodomLogo,
        name: "Foodom",
        statusId: 2,
        wineryId: null,
        wineryUrl: "https://www.myfoodom.com/",
        wineryType: "Foodom",
      },
      staticVinifiedData: {
        displayName: "Vinified",
        isActive: true,
        logo: vinifiedLogo,
        name: "vinified",
        statusId: 2,
        wineryId: null,
        wineryUrl: "https://vinified.com/",
        wineryType: "Vinified",
      },
    };
  }

  componentDidMount = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));

    if (consumerData && consumerData != null) {
      this.getWineries();
      this.getDeals();
    } else {
      this.getUserWineryData();
      this.getSpotLightDeals();
    }
  };
  getWineries = () => {
    this.getTestTrailWineryData();
  };
  getDeals = () => {
    this.getSpotLightDealsByUserId();
  };
  getDataFromSelectedWinery = (val) => {
    this.setState({
      errorDataFromSelectedWinery: val,
    });
  };
  getTestTrailWineryData = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));

    let getWineryDetailsForUserApiUrl =
      constants.IDENTITY_API +
      "/api/v1/user/get/allWineryInTrailMode/" +
      consumerData.id;

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    axiosCall("GET", getWineryDetailsForUserApiUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        let wineriesListForLocalStorage = [];
        console.log(res.data);
        if (res.data.length > 0) {
          wineriesListForLocalStorage = res.data.map((element) => {
            return {
              id: element.winery.id,
              wineryId: element.winery.id,
              wineryUserStatus: element.winery.statusId,
              displayName: element.winery.displayName,
              shoppingCartOptions: element.winery.shoppingCartOptions,
              website: element.winery.website,
              name: element.winery.name,
              ...element,
            };
          });
          localStorage.setItem(
            "winery_list",
            JSON.stringify(wineriesListForLocalStorage)
          );
          this.props.dispatch(setWineryList(wineriesListForLocalStorage));
        }
        let newObject = [...wineriesListForLocalStorage];
        this.setState(
          {
            wineryDetails: [...this.state.wineryDetails, ...newObject],
          },
          () => this.getUserWineryData()
        );
      })
      .catch((error) => console.error(error));
  };
  getUserWineryData = () => {
    let getWineryDetailsForUserApiUrl =
      constants.WINERY_API + "/api/v1/public/winery/get/allActiveWinery";
    axiosCall("GET", getWineryDetailsForUserApiUrl)
      .then((res) => {
        let wineriesListForLocalStorage = [];
        // let wineriesFromStorage = localStorage.getItem("winery_list");
        // wineriesFromStorage = wineriesFromStorage && JSON.parse(wineriesFromStorage)!=null ?wineriesFromStorage :[]
        if (res.data.length > 0) {
          wineriesListForLocalStorage = res.data.map((element) => {
            return {
              id: element.id,
              wineryId: element.id,
              wineryUserStatus: element.statusId,
              displayName: element.displayName,
              shoppingCartOptions: element.shoppingCartOptions,
              website: element.website,
              name: element.name,
              ...element,
            };
          });
          localStorage.setItem(
            "winery_list",
            JSON.stringify(wineriesListForLocalStorage)
          );
          this.props.dispatch(setWineryList(wineriesListForLocalStorage));
        }
        let newObject = [...wineriesListForLocalStorage];
        newObject = newObject.filter(
          (data) =>
            data.wines &&
            data.wines.filter((data) => data.isDeleted == false).length > 0
        );
        // const allWineries1 = [...newObject];
        // const allWineries2 = [...newObject];
        // const n1 = 4; //tweak this to add more items per swipe
        // const wineriesPerSwipe = new Array(Math.ceil(allWineries1.length / n1))
        //   .fill()
        //   .map((_) => allWineries1.splice(0, n1));
        // const n2 = 5; //tweak this to add more items per swipe
        // const wineriesPerSwipeDesktop = new Array(
        //   Math.ceil(allWineries2.length / n2)
        // )
        //   .fill()
        //   .map((_) => allWineries2.splice(0, n2));
        // console.log(wineriesPerSwipeDesktop);
        this.setState({
          wineryDetails: [...this.state.wineryDetails, ...newObject],
          // wineriesPerSwipeDesktop,
          // wineriesPerSwipe,
        });
      })
      .catch((error) => console.error(error));
  };
  getSpotLightDeals = () => {
    let getSpotLightDealsApiUrl =
      constants.WINERY_API +
      "/api/v1/public/spotlightDeal/getDealsBasedOnDateActiveWinery?dateSearch=" +
      moment().utc().format("YYYY-MM-DD");
    axiosCall("GET", getSpotLightDealsApiUrl)
      .then((res) => {
        let spotlightDeals = [];
        if (res.data.length > 0) {
          spotlightDeals = res.data;
          // let spotlightFromStorage = localStorage.getItem("spotlight_deals")
          // spotlightFromStorage = spotlightFromStorage && JSON.parse(spotlightFromStorage)!=null ?spotlightFromStorage :[]
          localStorage.setItem(
            "spotlight_deals",
            JSON.stringify(spotlightDeals)
          );
          this.props.dispatch(setSpotLigthDealList(spotlightDeals));
        }
        this.setState({
          spotlightDeals: [...this.state.spotlightDeals, ...spotlightDeals],
        });
      })
      .catch((error) => console.error(error));
  };
  getSpotLightDealsByUserId = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let getSpotLightDealsApiUrl =
      constants.WINERY_API +
      "/api/v1/spotlightDeal/getAllSpotlightDealsBasedOnTimeTrailWinery/" +
      consumerData.id +
      "?dateSearch=" +
      moment().utc().format("YYYY-MM-DD");
    axiosCall("GET", getSpotLightDealsApiUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        let spotlightDeals = [];
        if (res.data.length > 0) {
          spotlightDeals = res.data;
          // let spotlightFromStorage = localStorage.getItem("spotlight_deals")
          // spotlightFromStorage = spotlightFromStorage && JSON.parse(spotlightFromStorage)!=null ?spotlightFromStorage :[]
          localStorage.setItem(
            "spotlight_deals",
            JSON.stringify(spotlightDeals)
          );
          this.props.dispatch(setSpotLigthDealList(spotlightDeals));
        }
        this.setState(
          {
            spotlightDeals,
          },
          () => this.getSpotLightDeals()
        );
      })
      .catch((error) => console.error(error));
  };

  renderSwipingDiv = (wineryDetails, index) => {
    return (
      wineryDetails &&
      wineryDetails.map((el, j) => {
        return (
          <div
            key={j}
            // onClick={() => this.onClickWinery(el)}
            className="card select-winery-card-card shadow ptr-clss"
          >
            <ErrorBoundary>
              <SelectWineryCard
                key={index}
                sendDataToSelectWinery={this.getDataFromSelectedWinery.bind(
                  this
                )}
                wineryData={el}
                navigateTo={""}
              ></SelectWineryCard>
            </ErrorBoundary>
          </div>
        );
      })
    );
  };
  renderUserWineryCards = () => {
    const settings = {
      className: "slick-slider-overwrite",
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return this.state.wineryDetails.map((el, index) => {
      return (
        <div
          key={index}
          // onClick={() => this.onClickWinery(el)}
          className="card select-winery-card-display-mob shadow ptr-clss"
        >
          <ErrorBoundary>
            <SelectWineryCard
              key={index}
              sendDataToSelectWinery={this.getDataFromSelectedWinery.bind(this)}
              wineryData={el}
              navigateTo={""}
            ></SelectWineryCard>
          </ErrorBoundary>
        </div>
      );
    });
    // return isMobile ? (
    //   <Slider {...settings}>
    //     {this.state.wineriesPerSwipe.map((el, index) => {
    //       return (
    //         <div key={index} className="swipe-container">
    //           {this.renderSwipingDiv(el, index)}
    //         </div>
    //       );
    //     })}
    //   </Slider>
    // ) : (
    //   <Slider {...settings}>
    //     {this.state.wineriesPerSwipeDesktop.map((el, index) => {
    //       return (
    //         <div key={index} className="swipe-container">
    //           {this.renderSwipingDiv(el, index)}
    //         </div>
    //       );
    //     })}
    //   </Slider>
    // );
  };
  renderHorizontalSections = (array) => {
    return array.map((el, index) => {
      return (
        <div className="card select-winery-card-display-mob shadow ptr-clss display-horizontal">
          <SelectWineryCard
            key={index}
            sendDataToSelectWinery={this.getDataFromSelectedWinery.bind(this)}
            wineryData={el}
            navigateTo={""}
          ></SelectWineryCard>
        </div>
      );
    });
  };
  renderSpotlightDeals = () => {
    return this.state.spotlightDeals.map((el, index) => {
      return (
        <div className="spot-wine-card display-horizontal ">
          <SpotlightDealsCard dealData={el} id={el.id} key={el.id} />
        </div>
      );
    });
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <ErrorBoundary>
            <div className=" success-inner-container  new-discover-inner-container">
              <HeaderWithBurgerIcon title="Wines" />
              <div
                className={isMobile ? null : "desktop-div-scroll "}
                style={{ backgroundColor: "#f2f2f2" }}
              >
                {this.state.spotlightDeals.length > 0 && (
                  <div className="select-wineries-section-div">
                    <div className="select-wineries-heading-div">
                      <span className="select-wineries-heading">
                        Spotlight Deals
                      </span>
                    </div>
                    <div className="horizontal-scroll">
                      {this.renderSpotlightDeals()}
                    </div>
                  </div>
                )}
                {this.state.wineryDetails.length > 0 && (
                  <div className="select-wineries-section-div">
                    <div className="select-wineries-heading-div">
                      <span className="select-wineries-heading">Wineries</span>
                    </div>
                    <div className="select-winery">
                      {" "}
                      {this.renderUserWineryCards()}
                    </div>
                  </div>
                )}
                {/* <div className="select-wineries-section-div">
                <div className="select-wineries-heading-div">
                  <span className="select-wineries-heading">
                    MARKETPLACE PARTNERS
                  </span>
                </div>
                <div className="horizontal-scroll">
                  {this.renderHorizontalSections([
                    this.state.staticVinifiedData,
                  ])}
                </div>
              </div> */}{" "}
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { wineryList: state.WineryReducer.wineryList };
};
export default connect(mapStateToProps, null)(SelectWinery);
