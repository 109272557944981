import React, { Component } from "react";
import SignInToContinue from "../../components/CommonComponents/SignInToContinue";
import "./PARecommendationHistoryAccordian.scss";
import { isMobile } from "react-device-detect";
import * as constants from "../../constants/constants";
import { withRouter } from "react-router-dom";
import RateRecommendationPopup from "../../components/RateRecommendationPopup/RateRecommendationPopup";
import ShareButton from "../../components/ShareButton/ShareButton";
import moment from "moment";
import bottle from "../../../assets/images/individual-wine-bottle.png";
import { updateFoodRequestDetails } from "../../screens/PARecommendation/action";

let ratingStatus = 0;

class PARecommendationHistoryAccordian extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: true,
      showRateRecommendationPopup: false,
      rating: 1,
      sample: 0,
      signInPopUp: false
    };
  }

  componentDidMount = () => {};

  parseISOString(s) {
    if ((s != null || s != undefined) && s.includes("-")) {
      var b = s.split(/\D+/);
      return new Date(
        Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])
      ).toLocaleTimeString();
      // toLocaleTimeString is for hh:mm:ss
    }
  }

  renderTime = (recommendationQueryData) => {
    let result = [];
    const pictureUrls = this.props.pictureUrl
    let imgUrl = this.findWineImage(recommendationQueryData.pa_reco_items[0].varietalName, pictureUrls)
    if (recommendationQueryData && recommendationQueryData.createdAt) {
      var date = moment
        .utc(recommendationQueryData.createdAt)
        .format("MM/DD/YYYY");
      let i = 0;
      result.push(
        <div
        data-name={recommendationQueryData.request}
        data-image-url={imgUrl}
        data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
        onClick={this.props.foodHistClick} 
        key={i++} className="">
          <span
          data-name={recommendationQueryData.request}
          data-image-url={imgUrl}
          data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
          onClick={this.props.foodHistClick} 
          className="recommendation-history-card-accordion-date">
            {date}
          </span>
        </div>
      );
    }
    return result;
  };

  rate(rating) {
    this.setState({
      rating: rating,
      sample: 1,
    });
  }

  // saveRecommendationRating = () => {
  //     let consumerData = JSON.parse(localStorage.getItem('user_data'));
  //     let wineryId = JSON.parse(localStorage.getItem('winery_id'));

  //     let signInToken = JSON.parse(localStorage.getItem('signin_token'));
  //     let bearer = 'Bearer ' + signInToken;

  //     var date = new Date();
  //     var timeStamp = date.toISOString();
  //     let questionId = this.props.recommendationQueryData.id;
  //     let putRatingStarApiUrl =
  //         constants.IDENTITY_API +
  //         '/api/v1/recommendation/question/consumer/' +
  //         questionId +
  //         '?wineryId=' +
  //         wineryId;

  //     let apiBodyData = {
  //         pairingRating: this.state.rating,
  //         pairingRatingTimestamp: timeStamp,
  //     };

  //     fetch(putRatingStarApiUrl, {
  //         // dev
  //         method: 'PUT',
  //         headers: {
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',
  //             Authorization: bearer,
  //         },
  //         body: JSON.stringify(apiBodyData),
  //     })
  //         .then((res) => {
  //             if (res.ok) {
  //                 return res.json();
  //             } else {
  //                 throw Error(res.statusText);
  //             }
  //         })
  //         .then((json) => {
  //             ratingStatus = 0;
  //         })
  //         .catch((error) => console.error(error));
  // };

  displayExistingRating = () => {
    if (
      this.props.recommendationQueryData.rating != null &&
      this.props.recommendationQueryData.rating != undefined &&
      this.props.recommendationQueryData.rating > 0
    ) {
      let result = [];
      let ratingText = "";
      if (this.props.recommendationQueryData.rating == 5) {
        ratingText = "Excellent";
      } else if (this.props.recommendationQueryData.rating == 4) {
        ratingText = "Very Good";
      } else if (this.props.recommendationQueryData.rating == 3) {
        ratingText = "Good";
      } else if (this.props.recommendationQueryData.rating == 2) {
        ratingText = "Okay";
      } else if (this.props.recommendationQueryData.rating == 1) {
        ratingText = "Bad";
      }

      var ratingStarClass =
        "star-rating__star rating-star-accordion is-selected";
      var star = "*";
      var starnum = Math.floor(this.props.recommendationQueryData.rating);
      result.push(
        <div className="already-rated-ratingbar" key="Rating Bar">
          <label className={ratingStarClass}>{star.repeat(starnum)}</label>
          <label className="already-rated-rating">
            {ratingText != "" ? "  - " + ratingText : ""}
          </label>
        </div>
      );

      return result;
    }
  };

  gotoRateRecommendationPage = () => {
    this.props.history.push({
      pathname:
        "/rate-recommendedation/" + this.props.recommendationQueryData.id,
      state: {
        recommendationData: this.props.recommendationQueryData,
      },
    });
  };

  exitRateRecommendation = () => {
    this.setState({
      showRateRecommendationPopup: false,
    });
    this.props.getRecommendationHistory();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rateRecommendationPageStatus) {
      //   return <Redirect to={{
      //     pathname: '/rate-recommendedation/' + this.props.recommendationQueryData.id,
      //     state: {
      //               recommendationData: this.props.recommendationQueryData
      //           }

      // }}/>;
      this.props.history.push({
        pathname:
          "/rate-recommendedation/" + this.props.recommendationQueryData.id,
        state: {
          recommendationData: this.props.recommendationQueryData,
        },
      });
    } else if (this.state.recommendationPageStatus) {
      try {
        let wineryId = JSON.parse(localStorage.getItem("winery_id"));

        // return <Redirect to={{
        //     pathname: '/winery/' + wineryId  + '/recommended-wines/' + this.props.recommendationQueryData.id,
        //     state: {
        //               recommendationHistoryData: this.props.recommendationQueryData,
        //               questionId: this.props.recommendationQueryData.id
        //           }

        // }}/>;
        this.props.history.push({
          pathname:
            "/winery/" +
            wineryId +
            "/recommended-wines/" +
            this.props.recommendationQueryData.id,
          state: {
            recommendationHistoryData: this.props.recommendationQueryData,
            questionId: this.props.recommendationQueryData.id,
          },
        });
      } catch (e) {}
    }
  }

  findWineImage(varietalName, varietalProfiles) {
    return varietalProfiles[varietalName.trimEnd()]?.wine_url
  }

  render() {
    let  consumerData = JSON.parse(localStorage.getItem("user_data"));
    const {
      props: { recommendationQueryData },
      state: { opened },
    } = this;
    if(recommendationQueryData.pa_reco_items.length != 0) {
      const pictureUrls = this.props.pictureUrl
      let imgUrl = this.findWineImage(recommendationQueryData.pa_reco_items[0].varietalName, pictureUrls)
      return (
        /*
                                  #NOTE:
                                        We are checking if the API Call is done in reco page so 
                                        that an empty RecommendationHistoryCard isn't displayed before 
                                        the API Data is displayed.
                                        apiCallDone props value is sent from the reco page
        
                  */

        this.props.apiCallDone ? (
          <div
            data-name={recommendationQueryData.request}
            data-image-url={imgUrl}
            data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
            {...{
              className: `card recommendation-history-main-container shadow accordion-item ptr-clss, ${
                opened && "accordion-item--opened"
              }`,
            }}
          >
            {/* {console.log(recommendationQueryData.recommendation)} */}
            <div
            >
              <div
                className="recommendation-history-card-accordion-header"
              >
                {recommendationQueryData &&
                  recommendationQueryData.pa_reco_items !== undefined && (
                    <span
                    data-name={recommendationQueryData.request}
                    data-image-url={imgUrl}
                    data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                    onClick={this.props.foodHistClick} 
                    className="recommendation-history-card-dishname"
                    >
                      {recommendationQueryData &&
                      recommendationQueryData.request &&
                      recommendationQueryData.request.length < 25
                        ? recommendationQueryData.request :recommendationQueryData.request
                        }
                    </span>
                  )}
                {this.renderTime(recommendationQueryData)}
              </div>

              {/* <span style={{justifyContent: 'right'}}> */}
              <span
                style={{
                  paddingLeft: "80px",
                  display: "none",
                }}
              >
                {recommendationQueryData && recommendationQueryData.recommendation ? (
                                  <ShareButton
                                      wine={recommendationQueryData.recommendation.map(
                                          (el, index) => ({
                                              name: el.wineName,
                                              type: el.wineType,
                                              varietal: el.specifications.varietal,
                                              appellation: el.specifications.appellation,
                                              dish: recommendationQueryData.question,
                                              dish_pairing: true,
                                              wineryId: JSON.parse(localStorage.getItem('winery_id')),
                                              url: `${constants.CONSUMER_APP_URL}`,
                                          })
                                      )}
                                  />
                              ) : null}
              </span>
            </div>

            <div {...{ className: "accordion-item__inner ptr-clss" }}>
              <div
                data-name={recommendationQueryData.request}
                data-image-url={imgUrl}
                data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                onClick={this.props.foodHistClick}
                {...{ className: "accordion-item__content" }}
              >
                <span {...{ className: "accordion-item__paragraph" }}>
                  {/* {paragraph} */}

                  {recommendationQueryData &&
                  recommendationQueryData.pa_reco_items.length > 0
                    ? recommendationQueryData.pa_reco_items.map((el, index) => (
                        <div
                        data-name={recommendationQueryData.request}
                        data-image-url={imgUrl}
                        data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                        onClick={this.props.foodHistClick} 
                        className="accordion-card-inner-content" key={index}>
                          <div
                          data-name={recommendationQueryData.request}
                          data-image-url={imgUrl}
                          data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                          onClick={this.props.foodHistClick} 
                          className="accordion-card-wine-data">
                            {el && el.varietalName !== null && (
                              <div 
                              data-name={recommendationQueryData.request}
                              data-image-url={imgUrl}
                              data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                              onClick={this.props.foodHistClick}
                              className="accordion-card-wine-name-container">
                                <div 
                                data-name={recommendationQueryData.request}
                                data-image-url={imgUrl}
                                data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                                onClick={this.props.foodHistClick}
                                className="li-dot-container">
                                  <div className={"li-dot"}></div>
                                </div>
                                <div
                                data-name={recommendationQueryData.request}
                                data-image-url={imgUrl}
                                data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                                onClick={this.props.foodHistClick}
                                className={"accordion-card-wine-name"}>
                                  {el.varietalName}
                                </div>
                              </div>
                            )}

                            <span 
                            data-name={recommendationQueryData.request}
                            data-image-url={imgUrl}
                            data-reco={recommendationQueryData.pa_reco_items[0].wineTypeName + ", " + recommendationQueryData.pa_reco_items[0].varietalName}
                            onClick={this.props.foodHistClick}
                            className="accordian-card-wine-specifications">
                              {el.wineTypeName}
                            </span>
                          </div>
                        </div>
                      ))
                    : "Please try again later."}
                </span>
              </div>

              {/* 
                              #NOTE: If recommendation has been made, display rating star 
                          */}
              {
              recommendationQueryData &&
              recommendationQueryData.pa_reco_items.length > 0 ? (
                <div className="rating-recommendation-container">
                  <span className="rating-recommendation-text">
                    {this.props.recommendationQueryData.rating != null &&
                    this.props.recommendationQueryData.rating != undefined &&
                    this.props.recommendationQueryData.rating != "" ? (
                      (this.props.isOther) ? (this.props.recommendationQueryData.displayName) ? `${this.props.recommendationQueryData.displayName} recommended this`:"A user recommended this" : "Your Recommendation Rating"
                    ) : (
                      //  'Rate the Recommendation'
                      <div className="rate-recommendation-btn-container">
                        <button
                          onClick={()=>{if (consumerData && consumerData!=null){this.gotoRateRecommendationPage()} else {this.setState({signInPopUp : true})}}}
                          className="btn btn-round rate-recommendation-btn"
                        >
                          Rate Recommendation
                        </button>
                      </div>
                    )}
                  </span>
                  <span>
                    {this.props.recommendationQueryData.rating != null &&
                    this.props.recommendationQueryData.rating != undefined &&
                    this.props.recommendationQueryData.rating != ""
                      ? this.displayExistingRating()
                      : ""}
                  </span>
                  {/* <div
                                      className="share-food-pairing-button"
                                      style={{ display: isMobile ? 'block' : 'none' }}
                                  >
                                      <ShareButton
                                          wine={recommendationQueryData.recommendation.map(
                                              (el, index) => ({
                                                  name: el.wineName,
                                                  type: el.wineType,
                                                  varietal: el.specifications.varietal,
                                                  appellation: el.specifications.appellation,
                                                  dish: recommendationQueryData.question,
                                                  dish_pairing: true,
                                                  wineryId: JSON.parse(localStorage.getItem('winery_id')),
                                                  url: `${constants.CONSUMER_APP_URL}`,
                                              })
                                          )}
                                      />
                                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <SignInToContinue
            signInPopUp={this.state.signInPopUp}
            url={this.props.location.pathname}
            where={"to search for Recommendations."}
            handleClose={() => this.setState({ signInPopUp: false })}
            state= {this.props.state}
          />
            <RateRecommendationPopup
              open={this.state.showRateRecommendationPopup}
              recommendationData={this.props.recommendationQueryData}
              exitRateRecommendation={() => {
                this.exitRateRecommendation();
              }}
              history={this.props.history}
            />
          </div>
        ) : (
          ""
        )
      );
    }
    
    else{
      return(
          /*
                                    #NOTE:
                                          We are checking if the API Call is done in reco page so 
                                          that an empty RecommendationHistoryCard isn't displayed before 
                                          the API Data is displayed.
                                          apiCallDone props value is sent from the reco page
          
                    */
  
          this.props.apiCallDone ? (
            <div
              {...{
                className: `card recommendation-history-main-container shadow accordion-item ptr-clss, ${
                  opened && "accordion-item--opened"
                }`,
              }}
            >
              {/* {console.log(recommendationQueryData.recommendation)} */}
              <div
              >
                <div
                  className="recommendation-history-card-accordion-header"
                >
                  {recommendationQueryData &&
                    recommendationQueryData.pa_reco_items !== undefined && (
                      <span
                      className="recommendation-history-card-dishname"
                      >
                        {recommendationQueryData &&
                        recommendationQueryData.request &&
                        recommendationQueryData.request.length < 25
                          ? recommendationQueryData.request :recommendationQueryData.request
                          }
                      </span>
                    )}
                </div>
  
                {/* <span style={{justifyContent: 'right'}}> */}
                <span
                  style={{
                    paddingLeft: "80px",
                    display: "none",
                  }}
                >
                  {recommendationQueryData && recommendationQueryData.recommendation ? (
                                    <ShareButton
                                        wine={recommendationQueryData.recommendation.map(
                                            (el, index) => ({
                                                name: el.wineName,
                                                type: el.wineType,
                                                varietal: el.specifications.varietal,
                                                appellation: el.specifications.appellation,
                                                dish: recommendationQueryData.question,
                                                dish_pairing: true,
                                                wineryId: JSON.parse(localStorage.getItem('winery_id')),
                                                url: `${constants.CONSUMER_APP_URL}`,
                                            })
                                        )}
                                    />
                                ) : null}
                </span>
              </div>
  
              <div {...{ className: "accordion-item__inner ptr-clss" }}>
                <div
                  {...{ className: "accordion-item__content" }}
                >
                  <span {...{ className: "accordion-item__paragraph" }}>
                    {/* {paragraph} */}
  
                    {recommendationQueryData &&
                    recommendationQueryData.pa_reco_items.length > 0
                      ? recommendationQueryData.pa_reco_items.map((el, index) => (
                          <div
                          className="accordion-card-inner-content" key={index}>
                            <div
                            className="accordion-card-wine-data">
                              {el && el.varietalName !== null && (
                                <div 
                                className="accordion-card-wine-name-container">
                                  <div 
                                  className="li-dot-container">
                                    <div className={"li-dot"}></div>
                                  </div>
                                  <div
                                  className={"accordion-card-wine-name"}>
                                    {el.varietalName}
                                  </div>
                                </div>
                              )}
  
                              <span 
                              className="accordian-card-wine-specifications">
                                {el.wineTypeName}
                              </span>
                            </div>
                          </div>
                        ))
                      : "Please try again later."}
                  </span>
                </div>
  
                {/* 
                                #NOTE: If recommendation has been made, display rating star 
                            */}
                {recommendationQueryData &&
                recommendationQueryData.pa_reco_items.length > 0  && consumerData && consumerData!=null? (
                  <div className="rating-recommendation-container">
                    <span className="rating-recommendation-text">
                      {this.props.recommendationQueryData.rating != null &&
                      this.props.recommendationQueryData.rating != undefined &&
                      this.props.recommendationQueryData.rating != "" ? (
                        "Your recommendation rating"
                      ) : (
                        //  'Rate the Recommendation'
  
                        <div className="rate-recommendation-btn-container">
                          <button
                            className="btn btn-round rate-recommendation-btn"
                          >
                            Rate Recommendation
                          </button>
                        </div>
                      )}
                    </span>
                    <span>
                      {this.props.recommendationQueryData.rating != null &&
                      this.props.recommendationQueryData.rating != undefined &&
                      this.props.recommendationQueryData.rating != ""
                        ? this.displayExistingRating()
                        : ""}
                    </span>
                    {/* <div
                                        className="share-food-pairing-button"
                                        style={{ display: isMobile ? 'block' : 'none' }}
                                    >
                                        <ShareButton
                                            wine={recommendationQueryData.recommendation.map(
                                                (el, index) => ({
                                                    name: el.wineName,
                                                    type: el.wineType,
                                                    varietal: el.specifications.varietal,
                                                    appellation: el.specifications.appellation,
                                                    dish: recommendationQueryData.question,
                                                    dish_pairing: true,
                                                    wineryId: JSON.parse(localStorage.getItem('winery_id')),
                                                    url: `${constants.CONSUMER_APP_URL}`,
                                                })
                                            )}
                                        />
                                    </div> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <RateRecommendationPopup
                open={this.state.showRateRecommendationPopup}
                recommendationData={this.props.recommendationQueryData}
                exitRateRecommendation={() => {
                  this.exitRateRecommendation();
                }}
                history={this.props.history}
              />
            </div>
          ) : (
            ""
          )
        );
    }
  }
}

PARecommendationHistoryAccordian = withRouter(PARecommendationHistoryAccordian);
export default PARecommendationHistoryAccordian;

