import React, { Component } from 'react';

import './PageNotFound.scss';
import pageNotFoundLogo from '../../../assets/images/page-not-found.svg'; // with import


import * as GoogleAnalyticsFunctions from '../../components/PAGoogleAnalytics';


class PageNotFound extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            
        }
        
    }
    componentDidMount() { 

        GoogleAnalyticsFunctions.logPagesVisitedByUser();
        

      }

    
    render() {

        return (
          
            <div className = "page-not-found-container main-container">
                <img alt = "Uh, Oh!" className="page-not-found-icon" src={pageNotFoundLogo} />
                <div className="page-not-found-text-container">
                  <div className="page-not-found-text-subcontainer">
                      <span className="page-not-found-text1">Uh, Oh!</span>
                  </div>  
                  <div className="page-not-found-text-subcontainer">
                      <span className="page-not-found-text2">The page you requested couldn't be found!</span>
                  </div>
                </div>
           </div>
                
        );
    }
}

export default PageNotFound; 
