import * as io from "socket.io-client";

import { Observable } from "rxjs";
import * as constants from "../../constants/constants";

// let url = 'http://44.228.40.232:3000';
let url = constants.CHAT_API;
let socket;
export class ChatService {
  constructor() {
    socket = io(url, {
      reconnection: true,             // whether to reconnect automatically
      reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
      reconnectionDelay: 1000,        // how long to initially wait before attempting a new reconnection
      reconnectionDelayMax: 5000,     // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
      randomizationFactor: 0.5,
      // forceNew: true
    });

    socket.on("connect", function () {
      //socket.connect()
    });

    socket.on("connect_failed", function (msg) {
      // console.log('connect_failed', msg);
    });
    socket.on("connect_timeout", (timeout) => {
    });

    socket.on("disconnect", function () {
      // socket.connect();
      // this.createConnection(roomDetails)
    });

    socket.on("error", (error) => {
      // console.log('error', error);
    });
    socket.on('reconnect_attempt', function (msg) {
      // console.log('reconnect_attempt', msg);
    });
    socket.on('reconnect_error', function (msg) {
      // console.log('reconnect_error', msg);
    });
    socket.on('reconnect_failed', function (msg) {
      // console.log('reconnect_failed', msg);
    });
    socket.on('connect_error', function (msg) {
      // console.log('connect_error', msg);
    });
  }

  disconnect(roomDetails) {
    socket.disconnect();
  }

  createConnection(roomDetails) {
    // console.log(roomDetails);
    socket.emit("create", roomDetails);
  }

  sendMessage(messageObject) {
    socket.emit("new-message", messageObject);
  }

  getMessages = () => {
    return Observable.create((observer) => {
      socket.on("new-message", (message) => {
        observer.next(message);
      });
    });
  };

  connectSocket = () => {
    socket.connect();
  };

  getConnectionStatus = () => {
    return Observable.create((observer) => {
      socket.on("disconnect", function () {
        observer.next("disconnected");
      });

      socket.on("connect", function () {
        observer.next("connected");
      });
    });
  };
}
