import * as React from "react";
import { SampleBase } from "../../sample-base";
import { closest, Touch, isNullOrUndefined } from "@syncfusion/ej2-base";
import PreferenceScreenCard from "../PreferenceScreenCard/PreferenceScreenCard";
import "./SwipeableCard.scss";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

// below imports are for dots - denotes number of pages
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import MagicSliderDots from "react-magic-slider-dots";
// import 'react-magic-slider-dots/magic-dots.css';
// import "react-magic-slider-dots/dist/magic-dots.css";

import * as constants from "../../constants/constants";

import swipeLeftArrow from "../../../assets/images/swipe-left-arrow.png";
import swipeRightArrow from "../../../assets/images/swipe-right-arrow.png";

let response = new SampleBase();
// tslint:disable:max-line-length
// *  Sample for CSS Basic Layout Cards.
function swipeable() {
  let horizontalProductECard = document.querySelectorAll(
    "#horizontal_product .e-card"
  );
  let len = horizontalProductECard.length;
  [].slice.call(horizontalProductECard).forEach((ele) => {
    ele.style.removeProperty("transform");
  });
  // Below code is for tilting the cards

  // let transformRatio = 0.5;
  // let temp;
  // let divide = (parseInt((len / 2).toString(), 10));
  // temp = transformRatio;
  // for (let i = divide - 1; i >= 0; i--) {
  //     horizontalProductECard[i].style.transform = 'rotate(' + (temp) + 'deg)';
  //     temp += transformRatio;
  // }
  // transformRatio = 0.5;
  // temp = transformRatio;
  // for (let i = divide + 1; i < len; i++) {
  //     horizontalProductECard[i].style.transform = 'rotate(' + (-temp) + 'deg)';
  //     temp += transformRatio;
  // }
}

let swipeCount = 0;
let nextBtnClickedStatus = false;

export class Swipeable extends SampleBase {
  constructor(props) {
    super(props);

    this.state = {
      swipeableCardQuestionsOver: false,
      lastCardText: "",
      lastPreferenceOptionScreen: false,
      profileScreenLoaded: false,
      preferenceMasterJson: "",
      cardData: {
        categoryName: "",
        questionName: "",
        selected: false,
        optionType: "",
      },
      nextBtnClicked: true,
      exitBtnClicked: false,
    };
  }

  renderComplete() {
    let ele = document.getElementById("horizontal_product");

    swipeable();
    new Touch(ele, { swipe: this.touchSwipeHandler });

    let cards = document.querySelectorAll("#horizontal_product .e-card");
    [].slice.call(cards).forEach((ele) => {
      ele.querySelector("img").onmousedown = () => {
        return false;
      };
    });

    this.getPreferenceData();
  }
  touchSwipeHandler(e) {
    if (nextBtnClickedStatus) {
      swipeCount++;
      let ele = closest(e.originalEvent.target, ".e-card");

      // No of Swipe Cards - 1
      // if(swipeCount<=9) {

      if (isNullOrUndefined(ele)) {
        return;
      }

      if (ele.parentElement.querySelector(".card-out")) {
        ele.parentElement
          .querySelector(".card-out")
          .classList.remove("card-out");
      }
      if (ele.parentElement.querySelector(".card-out-left")) {
        ele.parentElement
          .querySelector(".card-out-left")
          .classList.remove("card-out-left");
      }

      /*
            #NOTE: Commenting out if (e.swipeDirection === 'Right') and if (e.swipeDirection === 'Left') 
                   since slider dots are added. That'll tc of the swipe right/left feature. 
                   Adding this piece of code will creatre an issue in swiping left 

         */
      // if (e.swipeDirection === 'Right') {

      //     try {

      //     if(document.getElementById("question-name").innerHTML == "The more we know what you like, The better your PairAnything experience will be.") {

      //             /*
      //                 #NOTE: If user tries to swipe left in the first screen
      //             */

      //     } else {

      //     ele.classList.add('card-out');
      //     ele.parentElement.insertBefore(ele.parentElement.children[0] , ele.parentElement.children.lastChild);

      // }
      //     }catch(e) {
      //     }

      //  }
      // else if (e.swipeDirection === 'Left') {

      //     try {
      //         if(ele.parentElement.lastChild.lastChild.lastChild.children[0].children[0].innerHTML == "The more we know what you like, The better your PairAnything experience will be.") {
      //             /*
      //                 #NOTE: If user tries to swipe right in the last screen
      //             */
      //         } else  {

      //             ele.classList.add('card-out-left');
      //             ele.parentElement.insertBefore(ele, ele.parentElement.children[0]);

      //         }
      //     }catch(e) {
      //     }
      // }
      else {
        return;
      }

      swipeable();
      ele.style.removeProperty("left");
    }
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };

    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  getPreferenceData = () => {
    // getdefault

    let masterJsonAPIurl =
      constants.CONSUMER_API +
      "/api/v1/taste_preference/newUserTastePreference";
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    fetch(masterJsonAPIurl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: masterJsonAPIurl,
            operation: "GET",
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          //   this.setState({
          //     errorOccurred: 'getRecommendationHistory',
          //     errorMessage: 'Something went wrong. Please try again later'
          //   })

          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state

        if (this.state.preferenceMasterJson != "") {
          this.setState((prevState) => ({
            preferenceMasterJson: json.questions.taste_preference,

            // cardData: {                   // object that we are going to update
            //     ...prevState.cardData,    // retains all other state key-value data
            //     categoryName: json.Item.taste_prefrence[2].category,    // update value of specific key
            //     questionName: json.Item.taste_prefrence[2].options[0]
            // }
          }));
        } else {
          this.setState({
            preferenceMasterJson: json.questions.taste_preference,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  getUpdatedDataFromPreferenceScreenCard = (
    categoryName,
    questionName,
    optionType,
    selectedStatus
  ) => {
    let tempResponse = this.state.preferenceMasterJson;

    for (let i = 0; i < tempResponse.length; ++i) {
      let str1 = tempResponse[i].category.toUpperCase();
      let str2 = tempResponse[i].option_type.toUpperCase();
      // check if category name, optiontype match. If yes, loop through sub options
      if (
        str1 == categoryName.toUpperCase() &&
        str2 == optionType.toUpperCase()
      ) {
        for (let j = 0; j < tempResponse[i].options.length; ++j) {
          let str3 = tempResponse[i].options[j].answer.toUpperCase();

          if (str3 == questionName.toUpperCase()) {
            // if yes, update selectedStatus

            tempResponse[i].options[j].selected = selectedStatus;
          }
        }
      }
    }

    this.setState({
      preferenceMasterJson: tempResponse,
    });

    this.postPreferenceData();
  };

  postPreferenceData = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let consumerId = consumerData.id;

    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    // let postPreferencesApiUrl = constants.CONSUMER_API + `/api/v1/taste_preference/user/${userId}​/winery/${wineryId}/create​`;
    let postPreferencesApiUrl =
      constants.CONSUMER_API +
      "/api/v1/taste_preference/user/" +
      consumerId +
      "/winery/" +
      wineryId +
      "/create";

    // - api call for master json
    var CurrentDate = new Date().valueOf();
    let tastePreferenceDataObject = {
      taste_prefrence: this.state.preferenceMasterJson,
    };
    let apiPostTempData = {
      taste_preference: tastePreferenceDataObject,
      createdAt: CurrentDate,
    };
    let apiPostData = apiPostTempData;

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    fetch(postPreferencesApiUrl, {
      // dev
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: postPreferencesApiUrl,
            operation: "POST",
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state
      })
      .catch((error) => console.error(error));
  };

  getNextBtnStatus = (status) => {
    if (status) {
      // if clicked, swipe right

      nextBtnClickedStatus = true;
      this.setState({
        nextBtnClicked: true,
      });
    }
  };

  getExitBtnStatus = (status) => {
    if (status) {
      // if clicked, swipe right

      this.setState({
        exitBtnClicked: true,
      });
    }
  };

  renderContainerForIntroSwipeExitScreens = () => {
    let result = [];
    if (this.state.nextBtnClicked) {
      result.push();
    } else {
      result.push();
    }
  };

  renderCards = () => {
    let path = "";
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.path !== undefined
    ) {
      path = this.props && this.props.location && this.props.location.state;
    }

    if (
      this.state.preferenceMasterJson != null &&
      this.state.preferenceMasterJson.length > 0
    ) {
      let result = [];

      // result.push (

      //     <div className="e-card e-card-horizontal">
      //          <PreferenceScreenCard status = "intro" sendNextBtnStatus = { this.getNextBtnStatus }
      //                        />
      //     </div>

      // );

      let sliderOptionData = [];
      // for (let i =0; i < this.state.preferenceMasterJson.length; ++i) {

      if (this.state.nextBtnClicked) {
        // https://patricktran.github.io/react-magic-slider-dots/ - link from which slider dots library is used

        const settings = {
          dots: isMobile ? true : false,
          // arrows: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        };

        for (let i = 0; i < 3; ++i) {
          // first 3 categories

          result.push(
            // e-car1d e-card-horizontal

            // (<Slider {...settings}>
            <div className="e-card e-card-horizontal">
              (
              <Slider {...settings}>
                {/*
                    #NOTE: Dynamic render of PreferenceScreenCard with loop i value for the slider dots 
                           does not work. So adding them one by one. 
                 */}
                {/* <PreferenceScreenCard status = "swipeablecards"
                      tastePreferenceCardObject = {this.state.preferenceMasterJson[i]} /> */}

                <PreferenceScreenCard
                  status="swipeablecards"
                  tastePreferenceCardObject={this.state.preferenceMasterJson[0]}
                />
                <PreferenceScreenCard
                  status="swipeablecards"
                  tastePreferenceCardObject={this.state.preferenceMasterJson[1]}
                />
                <PreferenceScreenCard
                  status="swipeablecards"
                  tastePreferenceCardObject={this.state.preferenceMasterJson[2]}
                />

                {/*
                       #NOTE: adding exit screen here  - slider dot last screen
              */}
                <PreferenceScreenCard
                  status="exit"
                  sendExitBtnStatus={this.getExitBtnStatus}
                  path={path}
                />
              </Slider>
              )
            </div>

            // </Slider>)
          );

          for (
            let j = 0;
            j < this.state.preferenceMasterJson[i].options.length;
            ++j
          ) {
            let preferenceCardDataObj;

            let preferenceCardDataObject = {
              name: this.state.preferenceMasterJson[i].options[j].name,
              imageUrl: this.state.preferenceMasterJson[i].options[j].img_url,
            };

            if (this.state.nextBtnClicked) {
            } else {
              /*
                        #NOTE:
                             Intro card has been removed for now - as per clients request. If intro card
                             is needed, add status = "intro" to <PreferenceScreenCard> component - uncomment
                             the below code

                             Displaying swipeable cards fow now in else section as well.

                    */

              result.push(
                <div id="e-card" className="e-card e-card-horizontal">
                  <PreferenceScreenCard
                    status="intro"
                    sendNextBtnStatus={this.getNextBtnStatus}
                    // path={path}
                  />
                </div>
              );
            }
          } //  end of j Loop
        }
      } else {
        result.push(
          <div id="e-card" className="e-card e-card-horizontal">
            <PreferenceScreenCard
              status="intro"
              sendNextBtnStatus={this.getNextBtnStatus}
              // path={path}
            />
          </div>
        );
      }

      let lastScreenCardDataObj = {
        categoryName: "Thank You",
        questionName: "Thanks!",
        selected: false,
        option_type: "",
      };

      // result.push (

      //     <div className="e-card e-card-horizontal">
      //          <PreferenceScreenCard status = "exit" sendExitBtnStatus = { this.getExitBtnStatus }
      //          />
      //     </div>

      // )

      for (let i = 0; i < result.length; ++i) {}
      let finalResult = result.reverse(); // reverse it
      return finalResult;
    } else return [];
  };

  skipPreferencesOnClick = () => {
    this.setState({
      profileScreenLoaded: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.profileScreenLoaded) {
      // return <Redirect to={{
      //     pathname: '/select-winery',
      //     state: {

      //            }

      // }}/>;
      this.props.history.push({
        pathname: "/wineries",
        // pathname: "/select-winery",
        state: {},
      });
    } else if (this.state.exitBtnClicked) {
      GoogleAnalyticsFunctions.clickedTastePreferenceFinished();
      this.props.history.push(this.props.location.state.path);
      // try {
      //   let winery_list = JSON.parse(localStorage.getItem("winery_list"));
      //   if (winery_list != null && winery_list != undefined) {
      //     if (winery_list.length == 1) {
      //       if (winery_list[0].wineryUserStatus == 2) {
      //         let wineryId = winery_list[0].wineryId;
      //         let url = "/search-wine" + "/" + wineryId;
      //         this.props.history.replace(url);
      //       } else if (winery_list[0].wineryUserStatus == 3) {
      //         this.props.history.replace("/profile");
      //       }
      //     } else {
      //       this.props.history.replace("/select-winery");
      //     }
      //   }
      // } catch (e) { }
    }
  }

  render() {
    // let count = this.renderCards(this.state.preferenceMasterJson);

    // if(swipeCount > 8) {
    //     return <Redirect to={{
    //         pathname: '/search-wine',
    //         state: {

    //                }

    //     }}/>;
    // }

    // search-wine changed to select-winery
    // console.log('props>>>>>>>>>', this.props)
    //   let {from, } = this.props&& this.props.location && this.props.location.state

    return (
      <div className="card-desktop-bg">
        <div className="control-pane">
          <div className="swipe-header-section">
            {/* <div className = "swipe-card-sub-header-section"> */}

            <div className="header-text-row">
              <span className="taste-preference-text">
                {/*
                                     Display "Pop Quiz" in the intro screen of taste pref
                                     Display "Taste Profile" in the other screens - once next btn is clicked
                                */}
                {this.state.nextBtnClicked ? "" : ""}
              </span>
              {/* <span  onClick={this.skipPreferencesOnClick} className="skip">Done</span> */}
            </div>
            <span className="taste-preference-sub-text">
              {this.state.nextBtnClicked ? "" : ""}
            </span>

            {/* </div> */}
          </div>
          {/* <div className="swipe-left-right-arrow-container">
                                <img className="swipe-left-arrow" src = {swipeLeftArrow} />
                                <img className="swipe-right-arrow" src = {swipeRightArrow} />
                            </div>  */}

          <div className="control-section card-control-section swipe_card_layout">
            <div className="e-card-resize-container">
              {/* {
                            this.renderContainerForIntroSwipeExitScreens()
                        } */}
              <div
                //  className='row card-sub-container'
                className={
                  this.state.nextBtnClicked
                    ? "row swipeablecard-sub-container"
                    : "row card-sub-container"
                }
              >
                <div className="row card-layout">
                  {/* <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6" id="horizontal_product"> */}
                  <div className="col-sm-12" id="horizontal_product">
                    {this.renderCards()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
