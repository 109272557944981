import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./AvailableWinesDesktop.scss";

import profileCartBtn from "../../../assets/images/profile-cart.svg";
import emptyCardWineImage from "../../../assets/images/EmptyPageCard/available-wines-screen-image.svg";

import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";

import * as constants from "../../constants/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import chatBtn from "../../../assets/images/chat-selected-icon.svg";
import chatWithBackgroundBtn from "../../../assets/images/chat-with-circle.svg";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";

import AvailableWineCard from "../../components/AvailableWineCard/AvailableWineCard";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import backBtn from "../../../assets/images/left-arrow-white.svg";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import CartIconComponent from "../../components/CartIcon/CartIcon";
import { camelToName } from "../../constants/commonFunctions";
import axiosCall from "../../constants";
import { connect } from "react-redux";
import {
  setWineryList,
  setCurrentWineryDetails,
} from "../../redux/actions/WineryAction";
import moment from "moment";

toast.configure();

let availableWineList = [1, 2, 3, 4, 5];
class AvailableWinesDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wineryName: "",
      apiHit: false,
      chatBtnClickedStatus: false,
      wineData: [],
      availableWineList: [],
      errorOccurred: "",
      searchValue: "",
    };
  }
  getSpotLightDeals = () => {
    try {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let renderSoptlightDealApiUrl =
        constants.WINERY_API +
        "/api/v1/public/spotlightDeal/getDealsBasedOnWineryForCurrentDate/" +
        wineryId +
        "?dateSearch=" +
        moment().utc().format("YYYY-MM-DD");
      axiosCall("GET", renderSoptlightDealApiUrl)
        .then((res) => {
          if (res.statusText == "OK") {
            let { availableWineList } = this.state;
            if (availableWineList.length > 0) {
              let rows = availableWineList;
              rows = availableWineList.map((wine) => {
                return {
                  ...wine,
                  deal: res.data.filter((deal) => deal.wineId == wine.id),
                };
              });
              this.setState({
                availableWineList: rows,
                spotLightDeals: res.data,
              },()=>this.getWineryDiscount());
            }
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: renderSoptlightDealApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .catch((error) => console.log(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "renderWines",
          errorMessage:
            "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
        });
      }
    }
  };
  getWineryDiscount = () => {
    try {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let renderSoptlightDealApiUrl =
        constants.WINERY_API +
        "/api/v1/public/discount/getDiscountsBasedOnWineryForCurrentDate/" +
        wineryId +
        "?dateSearch=" +
        moment().utc().format("YYYY-MM-DD");
      axiosCall("GET", renderSoptlightDealApiUrl)
        .then((res) => {
          if (res.statusText == "OK") {
            let { availableWineList } = this.state;
            let rows = availableWineList;
            if (availableWineList.length > 0) {
              console.log(res.data);
              rows = availableWineList;
              rows = availableWineList.map((wine) => {
                if(wine.deal && wine.deal.length > 0)
                return { ...wine}
                else return {
                  ...wine,
                  deal: res.data.filter((deal) => deal.discountWines.some(x => x.wineId === wine.id)),
                };
              });
            }
            this.setState({
              availableWineList: rows,
              discount: res.data
            });
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: renderSoptlightDealApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .catch((error) => console.log(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "renderWines",
          errorMessage:
            "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
        });
      }
    }
  };
  getWineryDetails = (wineryId) => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    // let wineryId = localStorage.getItem("winery_id");
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let getWineryDetailsApiUrl =
      constants.WINERY_API +
      "/api/v1/public/winery/consumer?wineryId=" +
      wineryId;
    fetch(getWineryDetailsApiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getWineryDetailsApiUrl,
            operation: "GET",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state
        this.setState({
          wineryName: json.displayName,
          wineryLogo: json.logo,
          wineryDescription: json.description,
        });
        // sessionStorage.setItem("wineryName", this.state.wineryName);
      })
      .catch((error) => console.error(error));
  };

  componentDidMount = () => {
    let wineryName = localStorage.getItem("winery_name");
    if (
      wineryName &&
      wineryName == camelToName(this.props.match.params.wineryName)
    ) {
      GoogleAnalyticsFunctions.logPagesVisitedByUser();
      GoogleAnalyticsFunctions.clickedAvailableWine();
      this.getWineryDetails();
      this.renderWinesForCurrentWinery();
    } else {
      this.fetchWineryDetailsFromDB();
      GoogleAnalyticsFunctions.logPagesVisitedByUser();
      GoogleAnalyticsFunctions.clickedAvailableWine();
      // this.props.history.replace("/select-winery");
      // window.location.reload();
    }
  };
  fetchWineryDetailsFromDB = () => {
    let url =
      constants.WINERY_API +
      "/api/v1/public/winery/get/wineryByUniqueUrl/" +
      this.props.match.params.wineryName
    axiosCall("GET", url)
      .then((resp) => {
        console.log(resp);
        if (resp.statusText == "OK" && !resp.data.msg) {
          // window.location.reload();
          localStorage.setItem("winery_id", JSON.stringify(resp.data.id));
          localStorage.setItem("winery_name", resp.data.displayName);
          this.props.dispatch(setWineryList([{ ...resp.data }]));
          this.props.dispatch(setCurrentWineryDetails({ ...resp.data }));
          if (
            localStorage.getItem("winery_list") == undefined ||
            localStorage.getItem("winery_list") == null
          ) {
            let winery = [];
            let element = resp.data;
            let obj = {
              wineryId: element.wineryId,
              wineryUserStatus: element.statusId,
              displayName: element.displayName,
              shoppingCartOptions: element.shoppingCartOptions,
              website: element.website,
              name: element.name,
              ...element,
            };
            winery.push(obj);
            localStorage.setItem("winery_list", JSON.stringify(winery));
          }

          this.getWineryDetails();
          this.renderWinesForCurrentWinery();
        } else {
          let apiErrorObj = {
            statusText: resp.statusText,
            type: resp.type,
            status: resp.status,
            url,
            operation: "GET",
            status: resp.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          throw Error(resp.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(camelToName(this.props.match.params.wineryName).trim());
  };
  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          // error in POST API also.
          localStorage.clear();
          localStorage.setItem("ageConfirmed", true);
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  renderWinesForCurrentWinery = () => {
    try {
      let wineryData = localStorage.getItem("winery_name");
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let offset = 0;
      let limit = 10;
      //let renderWinesApiUrl = constants.WINERY_API + '/api/v1/wine/winery/consumer?wineryId=' + wineryId + "&offset=" + offset + "&limit=" + limit;
      let renderWinesApiUrl =
        constants.WINERY_API +
        "/api/v1/public/wine/winery/consumer?wineryId=" +
        wineryId;
      fetch(renderWinesApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: renderWinesApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            this.setState({
              errorOccurred: "renderWines",
              // errorMessage: 'Something went wrong. Please try again later. '
              errorMessage:
                "Your email is no longer associated with " +
                wineryData +
                " winery, please contact the winery to gain access again. ",
            });

            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state

          this.setState(
            {
              wineData: json,
              availableWineList: json.rows,
              apiHit: true,
            },
            () => this.getSpotLightDeals()
          );
        })
        .catch((error) => console.error(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "renderWines",
          errorMessage:
            "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
        });
      }
    }
  };

  renderAvailableWineCards = () => {
    let result = [];

    if (this.state.errorOccurred == "renderWines") {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container mt-5em">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.availableWineList.length > 0) {
      //display AvailableWineCard if wines have been added
      if (this.state.searchValue.length >= 3) {
        //only apply filter when the searchValue has at least 3 characters
        result.push(
          this.state.availableWineList
            .filter((wineItem) =>
              wineItem.wineName
                .toLowerCase()
                .includes(this.state.searchValue.toLowerCase())
            ) //searching wines is not case-sensitive
            .map((el, index) => (
              <AvailableWineCard wineData={el} id={el.id} key={el.id} />
            ))
        );
      } else {
        result.push(
          this.state.availableWineList.map((el, index) => (
            <AvailableWineCard wineData={el} id={el.id} key={el.id} />
          ))
        );
      }

      //when there is no matching wines
      if (result[0].length === 0) {
        result.push(
          <span className="empty-state-text" style={{ marginTop: "50px" }}>
            We are always updating our wine selections. Please try again later.
          </span>
        );
      }
    } else {
      // else display a message

      if (this.state.apiHit) {
        let emptyCardData = {
          title: "Empty Cellar!",
          text: "Your favorite wines are coming up!",
          image: emptyCardWineImage,
        };
        result.push(
          <div className="empty-page-main-container desktop-error-container-margin">
            <EmptyPageCard emptyPageCardData={emptyCardData} />
          </div>
        );
      }
    }
    return result;
  };

  chatBtnClicked = () => {
    this.setState({
      chatBtnClickedStatus: true,
    });
  };

  gotoPreviousPage = () => {
    this.props.history.goBack();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.chatBtnClickedStatus) {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      // return <Redirect to={{
      //     pathname: '/chat' + '/' + wineryId,
      //     state: {
      //                 chatFromAvailableWinesPage: true
      //            }
      // }}/>;
      this.props.history.push({
        pathname: "/chat" + "/" + wineryId,
        state: {
          chatFromAvailableWinesPage: true,
        },
      });
    }
  }
  render() {
    // if(this.state.wineData.rows.length > 0) {
    //     availableWineList = this.state.wineData.rows;
    // }
    return (
      <React.Fragment>
        <div>
          <div className="top-navigation">
            <TopNavigation availableWinesActive="true" />
          </div>
          <div className="available-wines-main desktop-av-wines-main">
            <div className="available-wines-main-container desktop-av-wines-main-container">
              {/* <div className="search-wine-for-dish-header">
            <div className="search-wine-for-dish-subheader">
              <span className="search-wine-for-dish-text">Wines</span>
              <img
                alt="PairAnything"
                className="search-wine-for-dish-pair-anything-logo"
                src={pairAnythingLogo}
              />
            </div>
          </div> */}

              <div className="col-xs-12 col-sm-12 mt-4">
                {/* <div className="form-group col-sm-10 col-xs-10  available-wines-search-input">
              <input
                type="text"
                className="form-control shadow input-height"
                name="searchWines"
                placeholder="Search..."
              />
            </div> */}

                {/* Cart Icon with total quantity badge */}
                <CartIconComponent
                  fromRecommendedPage={false}
                  fromAvailableWinesPage={true}
                />
              </div>

              <div className="available-wines-container row mt-0">
                <ErrorBoundary>
                  {this.state.availableWineList.length > 0 && (
                    <div className="recommendation-input form-control search-wine-input shadow">
                      <i
                        className="fa fa-search pointer"
                        aria-hidden="true"
                      ></i>

                      <input
                        placeholder={"Search wines"}
                        type="text"
                        name="dishname"
                        className="input-search-box"
                        value={this.state.searchValue}
                        onChange={(e) =>
                          this.setState({ searchValue: e.target.value })
                        }
                        style={{ paddingLeft: "14px", width: "100%" }}
                      />
                    </div>
                  )}

                  {this.renderAvailableWineCards()}
                </ErrorBoundary>
              </div>

              {/* <div className="bottom-navigation shadow p-3 bg-white rounded">
            <BottomNavigation availableWinesActive="true" />
          </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, null)(AvailableWinesDesktop);
