/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { osName } from "react-device-detect";
import "./EventDescription.scss";
import AddedToRsvpPopUp from "../../components/AddedToRsvpPopUp/AddedToRsvpPopUp";
import RsvpFormPopup from "../../components/RsvpFormPopup/RsvpFormPopup";
import * as constants from "../../constants/constants";
import moment from "moment";
import * as momenttz from "moment-timezone";
import ShareEventButton from "../../components/ShareEventButton/ShareEventButton";

import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import calendarIcon from "../../../assets/images/Events/black-calendar.svg";
import timerIcon from "../../../assets/images/Events/timer-icon.svg";
import locationIcon from "../../../assets/images/Events/room-24px.svg";
import eventDefaultImage from "../../../assets/images/Events/default-image-individual-events.jpg";
import checkIcon from "../../../assets/images/Events/check-green.svg";
import onlineIcon from "../../../assets/images/Events/online-icon.svg";

import * as AddToRsvpAction from "../../redux/actions/AddToRsvpAction";
import * as EditRsvpAction from "../../redux/actions/EditRsvpAction";
import * as CancelRsvpAction from "../../redux/actions/CancelRsvpAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EventDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddedToRsvpPopUp: false,
      addToRsvpSuccess: "",
      addToRsvpFail: "",
      editRsvpSuccess: "",
      editRsvpFail: "",
      cancelRsvpSuccess: "",
      cancelRsvpFail: "",
      addToRsvpResponse: "",
      showFormPopup: false,
      isRegistered: false,
      eventDetails: "",
      editClicked: false,
      editRsvpResponse: "",
      cancelRsvpResponse: "",
      addedToRsvpSuccessfully: false,
      editedRsvpSuccessfully: false,
      userWineryStatus: "",
      disableFormSubmit: "",
      cancelledSuccessfully: false,
    };
  }

  componentDidMount = () => {
    this.getEventDetails();
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
  };

  getEventDetails = () => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let wineryId;
    if (this.props.location.state) {
      if (this.props.location.state.fromMyEvents) {
        wineryId = this.props.location.state.wineryId;
      } else {
        wineryId = JSON.parse(localStorage.getItem("winery_id"));
      }
    } else if (this.props.match.params.wineryId) {
      localStorage.setItem(
        "winery_id",
        JSON.stringify(parseInt(this.props.match.params.wineryId))
      );
      wineryId = this.props.match.params.wineryId;
      this.props.history.replace("/events/" + this.props.match.params.eventId, {
        wineryId: this.props.match.params.wineryId,
        fromEmail: true,
      });
    } else {
      let wineryId = localStorage.getItem("winery_id");
      wineryId =
        wineryId != "undefined" && wineryId != null
          ? JSON.parse(localStorage.getItem("winery_id"))
          : wineryId;
    }

    let getEventDetailsApiUrl =
      constants.EVENT_API +
      "/api/v1/users/winery/" +
      wineryId +
      "/events/" +
      this.props.match.params.eventId;
    fetch(getEventDetailsApiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getEventDetailsApiUrl,
            operation: "GET",
            status: res.status,
          };
          if (res.status == 422) {
            localStorage.clear();
            localStorage.setItem("ageConfirmed", true);
            this.props.history.replace("/sign-in");
            toast.info("Event Does Not Exist for Winery", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
          if (res.statusText === "Unauthorized") {
            this.props.history.replace("/sign-in");
          }
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        this.setState({
          eventDetails: json.results,
          userWineryStatus: json.userWineryStatus.isActive,
        });
      })
      .catch((error) => console.error(error));
  };

  openMaps = () => {
    let modifiedAddr;
    if (this.state.eventDetails.venue) {
      modifiedAddr = this.state.eventDetails.venue.replace(/\s/g, "+");
      var newWindow;
      if (osName === "Android" && this.state.eventDetails.isActive) {
        newWindow = window.open("geo:0,0?q=" + modifiedAddr, "_blank");
        newWindow.focus();
      } else if (osName === "iOS" && this.state.eventDetails.isActive) {
        newWindow = window.open(
          "http://maps.apple.com/?q=" + modifiedAddr,
          "_blank"
        );
        newWindow.focus();
      } else if (this.state.eventDetails.isActive) {
        newWindow = window.open(
          "https://google.com/maps/place/" + this.state.eventDetails.venue,
          "_blank"
        );
        newWindow.focus();
      }
    }
  };

  openLiveUrl = (eventUrlContents) => {
    var newWindow,
      url = this.state.eventDetails.liveUrl;
    if (
      eventUrlContents.startsWith("Click") &&
      this.state.eventDetails.isActive
    ) {
      if (url) {
        if (!url.includes("http://") && !url.includes("https://")) {
          url = "https://" + url;
        }
      }
      newWindow = window.open(url, "_blank");
      newWindow.focus();
    }
  };

  rsvpClickHandler = () => {
    this.setState({
      showFormPopup: true,
      disableFormSubmit: false,
      cancelledSuccessfully: false,
    });
  };

  handleCancelRsvp = () => {
    if (this.state.eventDetails.event_booking) {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      this.props.history.push({
        pathname: "/chat/" + wineryId,
        state: {},
      });
    }
  };

  handleCancel = () => {
    this.setState({
      showFormPopup: !this.state.showFormPopup,
    });
  };

  cancelledRsvp = () => {
    this.setState({
      showFormPopup: false,
      isRegistered: false,
      editClicked: false,
      cancelledSuccessfully: true,
    });
    this.getEventDetails();
  };

  saveClickHandler = (cancelRsvp, slotId, passCount, cancelReason) => {
    GoogleAnalyticsFunctions.clickedSubmitInRsvpFormPopup();
    if (this.state.editClicked) {
      this.props.EditRsvpAction.EditRsvp(
        this.state.eventDetails.id,
        slotId,
        this.state.eventDetails.event_booking[0].id,
        passCount
      );
      setTimeout(() => {
        if (this.state.editRsvpResponse.errors) {
          this.setState({
            showFormPopup: false,
          });
          toast.info(this.state.editRsvpResponse.errors[0].message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else if (this.state.editRsvpResponse.id) {
          this.setState({
            editedRsvpSuccessfully: true,
          });
          setTimeout(() => {
            this.setState({
              editedRsvpSuccessfully: false,
            });
          }, 4000);
          setTimeout(() => {
            this.setState({
              showFormPopup: false,
            });
          }, 3750);
          this.getEventDetails();
        }
      }, 3000);
    } else {
      this.props.AddToRsvpAction.AddToRsvp(
        this.state.eventDetails.id,
        slotId,
        passCount
      );
      setTimeout(() => {
        if (this.state.addToRsvpResponse) {
          if (this.state.addToRsvpResponse.id !== undefined) {
            this.setState({
              addedToRsvpSuccessfully: true,
            });
            this.getEventDetails();
            setTimeout(() => {
              this.setState({
                addedToRsvpSuccessfully: false,
              });
            }, 4000);
            setTimeout(() => {
              this.setState({
                addToRsvpResponse: "",
                showFormPopup: false,
                isRegistered: true,
              });
            }, 3750);
          } else if (this.state.addToRsvpResponse.errors) {
            this.setState({
              showFormPopup: false,
            });
            this.getEventDetails();
            toast.info(this.state.addToRsvpResponse.errors[0].message, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            this.setState({
              addToRsvpResponse: "",
            });
          }
        }
      }, 3000);
    }
  };

  editClickHandler = () => {
    GoogleAnalyticsFunctions.clickedEditRsvp();
    if (this.state.eventDetails.event_booking) {
      this.setState({
        disableFormSubmit: false,
        showFormPopup: true,
        editClicked: true,
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.addToRsvpSuccess ||
      nextProps.editRsvpSuccess ||
      nextProps.cancelRsvpSuccess
    ) {
      return {
        addToRsvpResponse: nextProps.addToRsvpSuccess,
        editRsvpResponse: nextProps.editRsvpSuccess,
        cancelRsvpResponse: nextProps.cancelRsvpSuccess,
      };
    } else {
      return null;
    }
  }

  parseDate = (item) => {
    let momentStart = moment
      .utc(item.startAt, [moment.ISO_8601, "h:mm A"])
      .local()
      .format("h:mm A");
    let momentEnd = moment
      .utc(item.endAt, [moment.ISO_8601, "h:mm A"])
      .local()
      .format("h:mm A");
    let newDate = [];
    var zone = momenttz.tz.guess();
    var timeZone = momenttz.tz(zone).format("z");
    newDate.push(
      <div className="date-slot-container" key={item.id}>
        <div className="event-times">
          <div className="time-contents">
            {momentStart} - {momentEnd} {timeZone}
          </div>
          {item.isAttended && this.state.eventDetails.isActive ? (
            <button className="btn btn-round attending-btn-details">
              <img className="check-icon" src={checkIcon} alt="check icon" />
              Attended
            </button>
          ) : item.isAttending && this.state.eventDetails.isActive ? (
            <button className="btn btn-round attending-btn-details">
              <img className="check-icon" src={checkIcon} alt="check icon" />
              Attending
            </button>
          ) : null}
        </div>
      </div>
    );
    return newDate;
  };

  renderEventDesc = (btnText, isAttended) => {
    let result = [];
    // let rawDate = Date(this.state.eventDetails.event_slots[0].startAt);
    let rawDate = new Date();
    let times = this.state.eventDetails.event_slots[0].startAt.split(":");
    rawDate.setHours(parseInt(times[0]));
    rawDate.setMinutes(parseInt(times[1]));
    rawDate.setSeconds(parseInt(times[2]));
    // let eventDate = moment(this.state.eventDetails.startDate).format('DD');
    // let eventMonth = moment(this.state.eventDetails.startDate).format('MMM');
    // let eventYear = moment(this.state.eventDetails.startDate).format('YYYY');
    let eventDate = moment
      .utc(this.state.eventDetails.startTime)
      .local()
      .format("DD MMM YYYY");
    let eventUrlContents;
    {
      if (btnText === "Attending Event") {
        eventUrlContents = "Click here to open the link";
      } else {
        eventUrlContents = "Link visible to Attendees";
      }
      if (isAttended) {
        eventUrlContents = "Event has ended";
      }
    }

    let picture;
    {
      this.state.eventDetails.coverPicture !== ""
        ? (picture = this.state.eventDetails.coverPicture)
        : (picture = eventDefaultImage);
    }
    let slotLength = this.state.eventDetails.event_slots.length - 1;
    let lastId = this.state.eventDetails.event_slots[slotLength].id;
    if (this.state.eventDetails) {
      result.push(
        <div className="event-desc-container" key={this.state.eventDetails.id}>
          <div className="event-coverpic">
            <img
              className="cover-picture-desc card-img-top"
              src={picture}
              alt="CoverPicture"
            />
          </div>
          <div className="event-winery-container">
            {this.state.eventDetails.winery.logo && (
              <img
                src={this.state.eventDetails.winery.logo}
                className="events-winery-logo"
                alt="winery logo"
              />
            )}
            <div className="event-winery-name">
              {this.state.eventDetails.winery.displayName}
            </div>
          </div>
          <div className="box">
            <div className="event-details">
              <div className="event-title">
                <span className="event-name">
                  {this.state.eventDetails.title}
                </span>
              </div>

              {this.state.eventDetails.liveUrl && (
                <div
                  className="event-url ptr-clss"
                  onClick={() => {
                    this.openLiveUrl(eventUrlContents);
                  }}
                >
                  <span>
                    <img
                      className="online-icon"
                      src={onlineIcon}
                      alt="Online Icon"
                    />
                  </span>
                  <span className="event-url-title">Online Event</span>
                  <div className="event-url-contents">{eventUrlContents}</div>
                </div>
              )}

              {this.state.eventDetails.venue && (
                <div
                  className="event-venue ptr-clss"
                  onClick={() => {
                    this.openMaps();
                  }}
                >
                  <div>
                    <img
                      className="location-icon"
                      src={locationIcon}
                      alt="Location Icon"
                    />
                  </div>
                  <div className="event-venue-contents">
                    {this.state.eventDetails.venue}
                  </div>
                </div>
              )}
              <div className="date-details-container">
                <div className="event-date" key={rawDate}>
                  <span>
                    <img
                      className="calendar-icon-desc"
                      src={calendarIcon}
                      alt="calendar icon"
                    />
                  </span>
                  <span className="event-date-content">{eventDate}</span>
                </div>
              </div>

              {this.state.eventDetails.description && (
                <div className="event-desc">
                  <span className="event-desc-title">{"About the Event:"}</span>
                  <div className="event-desc-content">
                    {" "}
                    {this.state.eventDetails.description}
                  </div>
                </div>
              )}
              <div className="time-slot-header-container">
                <img className="time-icon" src={timerIcon} alt="timer icon" />

                <div className="time-slot-header">Time Slots:</div>
              </div>

              {this.state.eventDetails.event_slots.map((item) => (
                <div className="event-date-container" key={item.id}>
                  {lastId !== item.id && (
                    <div className="recommendations-custom-line-events"></div>
                  )}
                  <div
                    className={lastId !== item.id ? "dot" : "dot-without-line"}
                  ></div>
                  {this.parseDate(item)}
                </div>
              ))}
            </div>
            <div className="share-event">
              <ShareEventButton eventData={this.state.eventDetails} />
              {/* <ShareEventButtonTmp eventData = {this.state.eventDetails}/> */}
            </div>
          </div>
        </div>
      );
    }

    return result;
  };

  gotoPreviousPage = () => {
    if (this.props.history.length > 1) {
      if (this.props.location.state.fromEmail) {
        this.props.history.push("/discover");
      } else this.props.history.goBack();
    } else {
      this.props.history.push("/events");
    }
  };

  render() {
    let btnText = "RSVP",
      totalCapacity = 0,
      totalReservations = 0,
      detailsApiIsAttending = false;
    if (this.state.eventDetails) {
      for (
        let loop = 0;
        loop < this.state.eventDetails.event_slots.length;
        loop++
      ) {
        if (this.state.eventDetails.event_slots[loop].isAttending === true) {
          detailsApiIsAttending = true;
        }
      }
    }
    if (this.state.eventDetails) {
      this.state.eventDetails.event_slots.map((event) => {
        totalCapacity = totalCapacity + event.capacity;
        totalReservations = totalReservations + parseInt(event.reservedCount);
      });
      if (totalCapacity === totalReservations) {
        btnText = "Fully Booked";
      }
    }
    if (detailsApiIsAttending) {
      btnText = "Attending Event";
    }
    if (this.state.eventDetails.event_booking) {
      if (
        this.state.eventDetails.event_booking.length > 0 &&
        detailsApiIsAttending
      ) {
        btnText = "Attending Event";
      }
    }
    if (this.state.isRegistered) {
      btnText = "Attending Event";
    }
    let bookedSlotId, bookedPassCount;
    if (this.state.eventDetails.event_booking) {
      if (this.state.eventDetails.event_booking.length > 0) {
        if (this.state.editClicked) {
          bookedSlotId = this.state.eventDetails.event_booking[0].eventSlotId;
        }
        bookedPassCount = this.state.eventDetails.event_booking[0].count;
      }
    }
    if (btnText === "RSVP") {
      bookedPassCount = 1;
    }
    if (this.state.cancelledSuccessfully) {
      btnText = "RSVP";
    }
    let isAttended = false;
    if (this.state.eventDetails.event_slots) {
      this.state.eventDetails.event_slots.map((item) => {
        isAttended = item.isAttended || isAttended;
      });
    }
    let showButton = true;
    if (!this.state.userWineryStatus) {
      showButton = false;
    }
    if (!this.state.eventDetails.isActive) {
      showButton = false;
    }
    if (isAttended) {
      showButton = false;
    }
    return (
      <div className="event-desc-root-container">
        <div>
          <div className="accordian-container">
            <div className="common-page-header">
              <div className="common-page-sub-header new-common-header-change">
                <img
                  alt="Open Recommendations page"
                  className="common-sub-header-back-icon ptr-clss"
                  onClick={() => {
                    this.gotoPreviousPage();
                  }}
                  src={backBtn}
                />
                <div className="new-header-text">
                  <span className="common-sub-header-text new-search-wine-for-dish-text">
                    Event Details
                  </span>
                </div>
                <img
                  alt="PairAnything"
                  className="common-sub-header-logo new-logo-header-design ml-0"
                  src={pairAnythingLogo}
                  onClick={() => {
                    this.openHomePage();
                  }}
                />
              </div>
            </div>
          </div>
          {this.state.eventDetails && (
            <div>
              <div className="event-desc-card-container">
                <div className="event-desc-card card shadow">
                  {this.renderEventDesc(btnText, isAttended)}
                </div>
              </div>
              {showButton && (
                <div className="bottom-btn-container">
                  {(btnText === "Attending Event" ||
                    this.state.isRegistered) && (
                    <button
                      className="btn btn-round rsvp-button-event-desc"
                      onClick={() => {
                        this.editClickHandler();
                      }}
                    >
                      Edit RSVP
                    </button>
                  )}
                  {(btnText === "RSVP" || btnText === "Fully Booked") && (
                    <button
                      className="btn btn-round rsvp-button-event-desc"
                      onClick={() => {
                        if (btnText === "RSVP") {
                          this.rsvpClickHandler();
                        }
                      }}
                    >
                      {btnText}
                    </button>
                  )}
                </div>
              )}

              <RsvpFormPopup
                open={this.state.showFormPopup}
                eventData={this.state.eventDetails}
                handleSave={this.saveClickHandler}
                handleCancel={() => {
                  this.handleCancel();
                }}
                editClicked={this.state.editClicked}
                handleCancelRsvp={() => {
                  this.handleCancelRsvp();
                }}
                bookedSlotId={bookedSlotId}
                bookedPassCount={bookedPassCount}
                cancelledRsvp={this.cancelledRsvp}
                editedRsvpSuccessfully={this.state.editedRsvpSuccessfully}
                addedToRsvpSuccessfully={this.state.addedToRsvpSuccessfully}
                disableFormSubmit={this.state.disableFormSubmit}
              />
              <AddedToRsvpPopUp open={this.state.showAddedToRsvpPopUp} />
            </div>
          )}
        </div>
      </div>
    );
  }
  openHomePage = () => {
    this.props.history.push("/discover");
  };
}

//REDUX

function mapStateToProps(state, props) {
  return {
    addToRsvpSuccess: state.AddToRsvpReducer.addToRsvpSuccess,
    addToRsvpFail: state.AddToRsvpReducer.addToRsvpFail,
    editRsvpSuccess: state.EditRsvpReducer.editRsvpSuccess,
    editRsvpFail: state.EditRsvpReducer.editRsvpFail,
    cancelRsvpSuccess: state.CancelRsvpReducer.cancelRsvpSuccess,
    cancelRsvpFail: state.CancelRsvpReducer.cancelRsvpFail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AddToRsvpAction: bindActionCreators(AddToRsvpAction, dispatch),
    EditRsvpAction: bindActionCreators(EditRsvpAction, dispatch),
    CancelRsvpAction: bindActionCreators(CancelRsvpAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDescription);
