import React, { useState, useEffect } from "react";
import "./CouponModal.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { isMobile } from "react-device-detect";
import bestOfSac from "../../../assets/images/best_of_sacramento.png";
import amadorVisit from "../../../assets/images/behind_the_cellar_door.png";
import behindTheCellarDoorHeader from "../../../assets/images/behind_the_cellar_door_header.png";
import laMesa from "../../../assets/images/Visit/Amador_County/La_Mesa_wine_image.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CouponModal = ({
  openDialog,
  initial,
  wineryId,
  wineryName,
  bestOfSacPromo,
}) => {
  const modalHeight = () => {
    if (isMobile) {
      // if(wineryName == "Scott Harvey Wines" && plaidFriday) {
      //     return "95vh";
      // }
      if (wineryName == "Scott Harvey Wines") {
        return "65vh";
      } else if (wineryName == "Matthew Gibson Winery") {
        return "70vh";
      } else if (wineryName == "Young's Vineyard") {
        return "85vh";
      } else if (wineryName == "Amador County") {
        return "65vh";
      } else {
        return "85vh";
      }
    } else if (!isMobile) {
      // if(wineryName == "Scott Harvey Wines" && plaidFriday) {
      //     return "97vh";
      // }
      if (wineryName == "Scott Harvey Wines") {
        return "75vh";
      } else if (wineryName == "Matthew Gibson Winery") {
        return "75vh";
      } else if (wineryName == "Young's Vineyard") {
        return "65vh";
      } else if (wineryName == "Amador County") {
        return "70vh";
      } else {
        return "85vh";
      }
    }
  };

  const modalWidth = () => {
    if (wineryName == "Amador County") {
      return "610px";
    } else {
      return isMobile ? "80vw" : "430px !important";
    }
  };
  const bottomSpacing = () => {
    if (wineryName == "Amador County") {
      return "25%";
    } else {
      return "5%";
    }
  };
  const popupStyle = {
    fontFamily: "Poppins, Regular sans-serif",
    position: "fixed",
    bottom: "30%",
  };

  const GlobalCss = withStyles({
    "@global": {
      ".MuiPaper-root": {
        background: "FFFFFF",
        borderRadius: "10px",
      },
      ".MuiBackdrop-root": {
        background: "#F2F2F299 0% 0% no-repeat padding-box",
      },
      ".MuiDialog-paperWidthFalse": {
        width: isMobile ? "80vw" : "430px !important",
        height: modalHeight(),
        position: "fixed",
        bottom: isMobile ? bottomSpacing() : "5%",
        marginRight: "auto",
        marginLeft: "auto",
      },
    },
  })(() => null);

  // useState and useEffect for handling opening/closing of coupon box
  const [open, setOpen] = useState(true);

  useEffect(() => {
    initial ? setOpen(true) : setOpen(false);
  }, [openDialog]);

  const fetchCookies = (name) => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let d = new Date();
    let currentDate = Date.parse(d.toISOString());
    let lastSignIn = 0;

    if (localStorage.getItem("switchPromo") == null) {
      localStorage.setItem("switchPromo", 0);
    }

    if (localStorage.getItem(wineryName) == null && consumerData != null) {
      localStorage.setItem(wineryName, currentDate);
      setOpen(true);
      return;
    } else {
      lastSignIn = JSON.parse(localStorage.getItem(wineryName));
      localStorage.setItem(wineryName, Date.parse(new Date().toISOString()));
    }
    // if user has logged on within the past 1 day (FOR PLAID FRIDAY) then do not show the coupon again
    if (lastSignIn != null && parseInt((currentDate - lastSignIn) / 1000) < 3) {
      setOpen(false);
      localStorage.setItem("coupon_popup", true);
    }

    // if the user has NOT logged on within the past 3 days, show the coupon again
    // CHANGED TO 1 DAY FOR PLAID FRIDAY PROMO
    else if (
      lastSignIn != null &&
      parseInt((currentDate - lastSignIn) / 1000) >= 3
    ) {
      const currentPromo = JSON.parse(localStorage.getItem("switchPromo"));
      let newPromo = currentPromo == 2 ? 0 : currentPromo + 1;

      localStorage.setItem("switchPromo", newPromo);
      setOpen(true);
      d = new Date();
      currentDate = Date.parse(d.toISOString());
      localStorage.setItem(wineryName, currentDate);

      // localStorage.setItem("signInTimes", 0);
    }

    // OLD INFORMATION THAT SAVED WHEN THE USER LAST LOGGED IN
    // // if user is new, create a local storage object with date of login
    // if(localStorage.getItem("last_user_login") == null && consumerData != null) {
    //     const userLoginData = {
    //         lastLogin: currentDate,
    //         showCoupon: true,
    //         signInTimes: 0,

    //     }
    //     localStorage.setItem("last_user_login", currentDate);
    //     localStorage.setItem("coupon_popup", true);
    //     localStorage.setItem("signInTimes", 0);
    //     localStorage.setItem("wineryName", true);
    // }
    // // if user is existing, create a local storage object with date of login
    // else {
    //     if(JSON.parse(localStorage.getItem("signInTimes")) == null) {
    //         localStorage.setItem("signInTimes", 0);
    //     }
    //     lastSignIn = JSON.parse(localStorage.getItem("last_user_login"));
    //     localStorage.setItem("last_user_login", Date.parse(new Date().toISOString()));

    // }

    // // if the user has logged on within the past 3 days, do not show the coupon modal
    // // the "timer" resets every time the user visits the page again
    // if(lastSignIn != null &&
    //     parseInt((currentDate-lastSignIn)/ (1000)) < 30) {
    //     setOpen(false);
    //     localStorage.setItem("coupon_popup", true);
    // }

    // // if the user has NOT logged on within the past 3 days, show the coupon again
    // else if(lastSignIn != null &&
    //     parseInt((currentDate-lastSignIn)/ (1000)) >= 30) {
    //     setOpen(true);
    //     d = new Date();
    //     currentDate = Date.parse(d.toISOString());
    //     localStorage.setItem("last_user_login", currentDate);
    //     localStorage.setItem("signInTimes", 0);

    // }
    //}
  };

  useEffect(() => {
    fetchCookies(wineryName);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const renderScottHarveyPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="xs"
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="content-style">
          <div style={{ background: "#FFFFFF", overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <div className="text-one">Congratulations!</div>
          <div className="text-two">As a PairAnything VIP user, you get</div>
          <div className="discount-percent-off">
            20% OFF + $5 flat shipping fee
          </div>
          <div className="text-two">
            on your purchase with Scott Harvey Wines{" "}
          </div>
          <div className="text-three">
            Your exclusive Promo Code has been applied.
          </div>

          <div className="discount-code-style">PA20</div>
          <a href="/ScottHarveyWines/wines">
            {/* <a href="/available-wines"> */}
            <button className="shop-now-button">Shop now</button>
          </a>
          <a href={`/chat/${wineryId}`}>
            <button className="questions-button">Questions?</button>
          </a>
        </DialogContent>
      </Dialog>
    );
  };

  const renderLaMesaPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={laMesa} />
          <div className="text-one font-bold">
            Welcome to La Mesa Vineyards!{" "}
          </div>
          <div className="text-two">
            For us, wine is personal. We love to meet people who are interested
            in drinking great wine... we’d like to meet you!
          </div>
          {/* <div className="text-two">Come visit our new tasting room and help us celebrate our Grand Opening Thursday through Sunday through the month of December!</div> */}
        </DialogContent>
      </Dialog>
    );
  };
  const renderIronHubBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-one font-bold">
            Receive an Iron Hub logo gift with wine purchase.{" "}
          </div>
          <div className="text-two">
            Expires February 28, 2021. While quantities last.
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderCGDiArieBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-one font-bold">
            Free Tasting of our Flagship list of flights ONLY.{" "}
          </div>
          <div className="text-three">
            A 15% discount on a purchase of 2 bottles, 20% on purchase of 3-6
            bottles of wine, and a 25% discount on 7+ bottles of wine. Applies
            only to our Flagship wines, does not apply to our wine club wine
            collection.
          </div>
          <div className="text-three top-spacing">
            Show your Sacramento Passport to redeem offer.{" "}
          </div>
          <div className="text-three font-bold">
            Offer will expire on February 28, 2021.{" "}
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderHelwigBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-one">
            Enjoy a complimentary flight for two guests of our current release
            and 10% off wine purchases.
          </div>
          <div className="text-two top-spacing">
            <span className="font-bold">
              Reservations are required for tastings.{" "}
            </span>
            Please visit helwigwinery.com to make your appointment.
          </div>
          <div className="text-two top-spacing">
            Discount cannot be combined with any current promotions.
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderProspectBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-one font-bold">
            Complimentary tasting for Passport holders!
          </div>
          <div className="text-one top-spacing">
            Please call for advanced reservations: 209-680-9003.
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderAmadorBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-one font-bold">
            Complimentary Reserve Tasting and Cheese Plate for Two and 15% off
            your purchase of wine.
          </div>
          <div className="text-one top-spacing">Reservations are required.</div>
          <div className="text-three top-spacing">
            Show your Sacramento Passport to redeem offer.{" "}
          </div>
          <div className="text-three">
            Offer will expire on February 28, 2021.
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderRombauerBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-three">
            Celebrating the “Best of Sacramento” this year Rombauer Vineyards is
            pleased to offer all guests the chance to be members for a day!
          </div>

          <div className="text-two font-bold top-spacing">
            When mentioning the “Best of Sacramento” offer upon reservation all
            guests are able to enjoy Wine Club Member discounts, and
            complimentary tasting for up to 4 people.
          </div>

          <div className="text-three top-spacing">
            This offer is valid one time only Monday-Friday with prior
            reservations. Offer expires 2/28/2021.
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  const renderVinoNocetoBOSPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img className="bos-image" src={bestOfSac} />
          <div className="text-one font-bold">10% off bottle purchases. </div>
          <div className="text-two top-spacing">
            {" "}
            Souvenir stemless logo glass with bottle purchase.{" "}
          </div>
          <div className="text-two top-spacing">
            Club pricing on wine flights and wine by the glass.{" "}
          </div>
          <div className="text-three top-spacing">
            Show your Sacramento Passport to redeem offer. Offer will expire on
            February 28, 2021.
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  const renderPromo = () => {
    // if (wineryName == "Iron Hub Winery") {
    //   return renderIronHubBOSPromo();
    // } else if (wineryName == "C.G. DiArie Vineyard and Winery") {
    //   return renderCGDiArieBOSPromo();
    // } else if (wineryName == "Helwig Vineyards and Winery") {
    //   return renderHelwigBOSPromo();
    // } else if (wineryName == "Prospect Cellars") {
    //   return renderProspectBOSPromo();
    // } else if (wineryName == "Amador Cellars") {
    //   return renderAmadorBOSPromo();
    // } else if (wineryName == "Rombauer Vineyards") {
    //   return renderRombauerBOSPromo();
    // } else if (wineryName == "Vino Noceto") {
    //   return renderVinoNocetoBOSPromo();
    // } else if (wineryName == "La Mesa Vineyards & Winery") {
    //   return renderLaMesaPromo();
    // } else if (wineryName == "Scott Harvey Wines") {
    //   return renderScottHarveyPromo();
    // } else if (wineryName == "Amador County") {
    //   return renderAmadorVisitPromo();
    // }
  };

  const renderAmadorVisitPromo = () => {
    return (
      <Dialog
        open={open}
        // onClose={handleClose}
        style={popupStyle}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <DialogContent className="plaid-friday-content-style">
          <div style={{ overflowY: "visible" }}>
            <button
              className={isMobile ? "exit-button" : "exit-button-desktop"}
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <img
            className={isMobile ? "mobile-amador-image" : "amador-image"}
            src={amadorVisit}
          />
          <div className="text-one font-bold" style={{ fontSize: "25px" }}>
            Behind the Cellar Door
          </div>
          <div className="text-two" style={{ fontSize: "20px" }}>
            Experience the Art of Winemaking in Amador County!
          </div>

          <div
            className="text-two font-bold"
            style={{ color: "#00344c", fontSize: "18px" }}
          >
            March 26th - 28th, 2021
          </div>
          <div
            className="text-two"
            style={{ color: "#00344c", fontSize: "18px" }}
          >
            10:30am - 4:30 pm
          </div>
          <a
            href="https://www.cellarpass.com/events/behind-the-cellar-door-2021-7042?utm_source=website&utm_medium=cellarpass&utm_campaign=BehindtheCellarDoor_2021_03_03&referrer=pair_anything&utm_content=hyperlink"
            target="_blank"
          >
            <button className="amador-button">Buy Passport!</button>
          </a>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <React.Fragment>
      <GlobalCss />
      {renderPromo()}
    </React.Fragment>
  );
};

export default CouponModal;
