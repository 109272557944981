import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import styles from "./help.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import ChevronRight from "../../components/Icons/Arrows/chev-right";
import Cheers from "../../components/Icons/PA/cheers";

export default function HelpSuccess() {
  const history = useHistory();

  useEffect(() => {
    logPagesVisitedByUser();
  }, []);

  return (
    <Layout>
      <LayoutContents>
        <PAHeader left />

        <div className={`mc ${styles.mainTY}`}>
          <Cheers />
          <section className={`${styles.head}`}>
            <div className={`${styles.title}`}>
              <h1 className={`h1-30`}>Thank you for letting us know!</h1>

              <p className={`p1-reg`}>
                We’ve received your help request and will get back to you
                shortly.
              </p>
            </div>
          </section>
          <section className={`${styles.box}`}>
            <button
              className={`${styles.editBox}`}
              onClick={() => {
                history.push({
                  pathname: `/help`,
                });
              }}
            >
              <p className={`p1-bold-red`}>Submit Another Request</p>
              <ChevronRight />
            </button>
            <button
              className={`${styles.editBox}`}
              onClick={() => {
                history.push({
                  pathname: `/feedback`,
                });
              }}
            >
              <p className={`p1-bold-red`}>Leave Feedback</p>
              <ChevronRight />
            </button>
          </section>
        </div>
      </LayoutContents>
    </Layout>
  );
}
