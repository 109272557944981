import React from 'react';
import logo from "../../../assets/images/splash.png";
import styles from "./logo.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";

export default function SplashLogo() {
  return (
    <img
      draggable="false"
      alt="PairAnything"
      className={styles.width}
      src={logo}
    />
  );
}
