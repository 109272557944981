import React from "react";
import styles from "./results-scroll-bar.module.scss";

export default function ResultsScrollBar({
  itemList = undefined,
  food = false,
  currentSelection,
  setCurrentSelection,
}) {
  return (
    <>
      {" "}
      <div className={`mc lh0`}>
        <div className={styles.wineResultBar}>
          <div className={styles.scrollMore}>
            {!!itemList ? (
              itemList.map((item, idx) => (
                <div
                  className={`${styles.scrollItem} ${
                    currentSelection == idx ? styles.selected : null
                  }`}
                  onClick={() => {
                    setCurrentSelection(idx);
                  }}
                  key={idx}
                >
                  <div
                    className={`${styles.scrollItemNumber} ${
                      currentSelection == idx ? styles.selectedText : null
                    }`}
                  >
                    {idx + 1}
                  </div>
                  <div
                    className={`${styles.scrollItemText} ${
                      currentSelection == idx ? styles.selectedText : null
                    }`}
                  >
                    {food
                      ? item?.foodPairing?.foodName
                      : item?.varietal?.varietal}
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.grayLine}`} />
    </>
  );
}
