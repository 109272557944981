import React, { Component } from "react";
import "./RecommendationsDesktop.scss";

import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import emptyCardSearchImage from "../../../assets/images/EmptyPageCard/recommendation-screen-image.svg";
import switchWineryIcon from "../../../assets/images/switch_winery_icon.svg";
import chatIcon from "../../../assets/images/chat-icon-switch.svg";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import {
  foodPairingAdd,
  getHistory,
  getFoodResult,
} from "../SearchWineForDish/action";

import TopNavigation from "../../components/TopNavigation/TopNavigation";
import * as constants from "../../constants/constants";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import RateRecommendationPopup from "../../components/RateRecommendationPopup/RateRecommendationPopup";
import RecommendationHistoryCardAccordian from "../../components/RecommendationHistoryCardAccordian/RecommendationHistoryCardAccordian";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import RecommendedWinesDesktop from "../../screens/RecommendedWinesDesktop/RecommendedWinesDesktop";
import CouponModal from "../../components/CouponModal/CouponModal";
import QuizUsersPopup from "../../components/QuizUsersPopup/QuizUsersPopup";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import TastePreferencePopUp from "../../components/TastePreferencePopUp/TastePreferencePopUp";
import axiosCall from "../../constants/index";
import * as commonFunctions from "../../constants/commonFunctions";
class RecommendationsDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idxFood: 0,
      idxWine: 0,
      idx: 0,
      capIdx: 5,
      rs: [],
      wineryDisabledResponseFromAPI: true,
      gotoWineryDetailsPageStatus: false,
      invalidWineryIdInUrl: false,
      chatStatus: false,
      switchWineryStatus: false,
      opened: false,
      wineryName: "",
      wineryLogo: "",
      wineryDescription: "",
      wineryMessage: "",
      dishName: "",
      dishNameStatus: "",
      errorOccurred: "",
      errorMessage: "",
      recommendationsPageLoaded: false,
      recommendationSearchResponse: "",
      getRecommendationHistoryApiCallDone: false,
      getRecommendationGetHistoryApiCallDone: false,
      searchClicked: false,
      buttonFoodActive: true,
      buttonWineActive: false,
      showRateRecommendationPopup: false,
      wineSearchResult: null,
      emptyWineresult: false,
      tabChange: false,
      getHistory: [],
      foundFoods: [],
      userInput: "",
      showResult: false,
      showNoFood: false,
      showTastePreferencePopUp: false,
      recommendationHistory: [
        {
          createdAt: "",
          id: "",
          isAttended: "",
          question: "",
          timestamp: "",
          updatedAt: "",
          userId: "",
          wineryId: "",
        },
      ],
      recommendationHistoryInReverse: [
        {
          createdAt: "",
          id: "",
          isAttended: "",
          question: "",
          timestamp: "",
          updatedAt: "",
          userId: "",
          wineryId: "",
        },
      ],

      block1: true,
      block2: false,
      block3: false,
      disableSearchBtn: false,
      currentIndex: 0,
    };
  }

  sendPairingQuestion = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let sessionData = JSON.parse(localStorage.getItem("anonymous_session"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    var date = new Date();
    var timeStamp = date.toISOString();

    let postPairingQuestionApiUrl =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/recommendation/create/question?" +
      "questionRaisedBy=" +
      sessionData ? sessionData?.id : consumerData?.id +
      "&wineryId=" +
      wineryId;
    let apiPostData = {
      question: this.state.dishName,
      timestamp: timeStamp,
    };
    fetch(postPairingQuestionApiUrl, {
      // dev
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: postPairingQuestionApiUrl,
            operation: "POST",
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          // PAException.postCaughtAPIException(apiErrorObj);
          this.setState({
            disableSearchBtn: false,
            errorMessage: "Something went wrong. Please try again later",
            errorOccurred: "postPairingQuestion",
          });
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        this.setState({
          disableSearchBtn: false,
          // recommendationsPageLoaded: true,
          recommendationSearchResponse: json,
          dishName: "",
          idx: 0,
          currentIndex: 0,
        });
      })
      .catch((error) => {
        this.setState({
          disableSearchBtn: false,
        });
        console.error(error);
      });
  };

  wineclick = () => {
    this.setState(
      {
        buttonFoodActive: false,
        buttonWineActive: true,
        dishName: "",
        showResult: false,
        showNoFood: false,
      },
      () => {
        this.getWineryRecommendations();
      }
    );
  };

  foodclick = () => {
    this.setState({
      buttonFoodActive: true,
      buttonWineActive: false,
      dishName: "",
    });
  };

  gotoRecommendationsPage = () => {
    if (this.state.dishName != "") {
      this.hidePopup();
      GoogleAnalyticsFunctions.clickedAskRecommendation();
      this.setState({
        disableSearchBtn: true,
      });
      this.sendPairingQuestion();
      setTimeout(() => {
        this.onCheckTastePreference();
      }, 3000);
    } else {
      this.setState({
        dishNameStatus: "Please enter a dish name",
      });
    }
  };

  getWineryRecommendations = async () => {
    let { dishName } = this.state;
    if (dishName !== "") {
      if (dishName.length >= 3) {
        const data = await foodPairingAdd(dishName);
        if (data.length > 0) {
          this.setState(
            {
              wineSearchResult: data,
              emptyWineresult: false,
              tabChange: false,
            },
            async () => {
              const history = await getHistory();
              this.setState({
                getHistory: history,
                getRecommendationGetHistoryApiCallDone: true,
              });
            }
          );
        } else {
          this.setState({ emptyWineresult: true, tabChange: false });
        }
      } else {
        this.setState({ emptyWineresult: true, tabChange: false });
      }
    } else {
      this.setState({ tabChange: true, emptyWineresult: false });
    }
  };

  // componentDidUpdate(){

  //   window.onpopstate  = (e) => {

  //     alert(1);
  //     alert("route: ", this.props.location.state);
  //  }

  //     }
  onCheckTastePreference = async () => {
    let popUp = localStorage.getItem("do_it_later");
    if (popUp === null) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let url =
        constants.CONSUMER_API +
        "/api/v1/taste_preference/findDefaultTastePreferenceByCustomer?userId=" +
        consumerData.id;
      try {
        await axiosCall("get", url);
        this.setState({ showTastePreferencePopUp: false });
      } catch (e) {
        // If it throws error means no taste preference is present for the use
        // console.log('err response', e);
        this.setState({ showTastePreferencePopUp: true });
      }
    }
  };
  // Go to select taste preference
  handleYes = () => {
    GoogleAnalyticsFunctions.clickedSelectTastePreference();
    this.setState({
      showTastePreferencePopUp: false,
    });
    this.props.history.push({
      pathname: "/user-taste-preference",
      state: {
        path: this.props.location.pathname,
      },
    });
  };

  //Do it later
  handleCancel = () => {
    GoogleAnalyticsFunctions.clickedDoItLater();
    this.setState({
      showTastePreferencePopUp: false,
    });
    localStorage.setItem("do_it_later", true);
  };

  componentDidMount = async () => {
    const history = await getHistory();
    this.setState({
      getHistory: history,
      getRecommendationGetHistoryApiCallDone: true,
    });
    try {
      // let wineryIdFromUrl = this.props.match.params.wineryId;
      // let wineryIdFromUrl = localStorage.getItem("winery_id");
      // let wineryList = JSON.parse(localStorage.getItem("winery_list"));

      // let wineryIdValidity = 0;
      // for (let i = 0; i < wineryList.length; ++i) {
      //   if (
      //     wineryIdFromUrl == wineryList[i].wineryId
      //     // &&
      //     // wineryList[i].wineryUserStatus == 2
      //   ) {
      //     // wineruuserstatus is enabled
      //     wineryIdValidity++;
      //   }
      // }

      // if (wineryIdValidity != 0) {
      //   // valid winery ID URL param
      //   // user might have clicked on notif because for some other winery
      //   let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      //   if (wineryIdFromUrl == wineryId) {
      //   } else {
      //     localStorage.setItem("winery_id", wineryIdFromUrl);
      //     window.location.reload();
      //   }
      // }

      // let consumerWineryStatus = localStorage.getItem("user_winery_status");

      // if (wineryIdValidity == 0) {
      //   // invalid Winery Id in the URL. User might have tried to change URL
      //   this.setState({
      //     errorMessage: "You do not have access to this page.",
      //   });
      // } else if (
      //   consumerWineryStatus != null &&
      //   consumerWineryStatus != undefined &&
      //   consumerWineryStatus == "disabled"
      // ) {
      //   this.setState({
      //     errorOccurred: "getRecommendationHistory",
      //     errorMessage:
      //       "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
      //   });
      // } else {
      GoogleAnalyticsFunctions.logPagesVisitedByUser();

      // inside onclicks -     GoogleAnalyticsFunctions.inputToSearchCampaign();

      this.getRecommendationHistory();
      this.getWineryDetails();
      // }
    } catch (e) {}
  };

  changeIndex = (newIndex) => {
    this.setState({
      currentIndex: newIndex,
    });
  };

  getWineryDetails = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let getWineryDetailsApiUrl =
      constants.WINERY_API +
      "/api/v1/public/winery/consumer?wineryId=" +
      wineryId;
    fetch(getWineryDetailsApiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: getWineryDetailsApiUrl,
            operation: "GET",
            status: res.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        //   if successful, update state
        // console.log(json);
        this.setState({
          wineryName: json.displayName,
          wineryLogo: json.logo,
          wineryDescription: json.description,
          wineryMessage: json.message,
        });
        // sessionStorage.setItem("wineryName", this.state.wineryName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange(event) {
    let val = event.target.value;

    this.setState({
      dishName: val,
    });
  }

  async onInputChange(e) {
    this.setState({ dishName: e.currentTarget.value });

    const keyword = e.currentTarget.value;

    if (keyword.length >= 2) {
      const foundFoods = await getFoodResult(keyword);

      if (foundFoods.length === 0) {
        this.setState({ showNoFood: true });
      } else {
        this.setState({ showNoFood: false });
      }

      this.setState({ foundFoods, showResult: true });
    } else {
      this.setState({ showResult: false });
    }

    if (this.state.dishName.length === 0 && this.state.foundFoods.length > 0) {
      this.setState({ showResult: false });
    } else if (
      this.state.dishName.length === 0 &&
      this.state.foundFoods.length === 0
    ) {
      this.setState({ showResult: false, showNoFood: false });
    }
  }

  hidePopup() {
    this.setState({ showResult: false, showNoFood: false });
  }

  onClick(value) {
    this.setState({ dishName: value.currentTarget.innerText }, () => {
      this.gotoRecommendationsPage();
    });
    this.hidePopup();
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };

    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          localStorage.clear();
          localStorage.setItem("ageConfirmed", true);
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  getRecommendationHistory = () => {
    try {
      let wineryData = localStorage.getItem("winery_name");
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;
      let getRecommendationHistoryApiUrl =
        constants.IDENTITY_API +
        "/api/v1/recommendation/getQuestions?questionRaisedBy=" +
        consumerData.id +
        "&wineryId=" +
        wineryId;
      fetch(getRecommendationHistoryApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getRecommendationHistoryApiUrl,
              operation: "GET",
            };
            let errMsg =
              "Your email is no longer associated with " +
              wineryData +
              " winery, please contact the winery to gain access again. ";
            this.postCaughtException(JSON.stringify(apiErrorObj));
            this.setState({
              wineryDisabledResponseFromAPI: true,
              errorOccurred: "getRecommendationHistory",
              errorMessage: errMsg,
            });
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state

          this.setState({
            wineryDisabledResponseFromAPI: false,
            recommendationHistory: json,
            recommendationHistoryInReverse: json.reverse(),
            getRecommendationHistoryApiCallDone: true,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      let wineryData = localStorage.getItem("winery_name");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          wineryDisabledResponseFromAPI: true,
          errorOccurred: "getRecommendationHistory",
          errorMessage:
            "Your email is no longer associated with " +
            wineryData +
            " winery, please contact the winery to gain access again. ",
        });
      }
    }
  };

  renderRecommendationHistory = () => {
    // set to reverse order
    let result = [];
    let ctr = 0;
    let allWinesDeletedStatus;
    // for(let i =0; i< this.state.recommendationHistoryInReverse.length;++i) {
    //   if(this.state.recommendationHistoryInReverse[i].message!=null
    //     ) {

    //        if(this.state.recommendationHistoryInReverse[i].message == "This wine is not available anymore") {
    //          ctr++;
    //        }

    //     }
    // }

    // allWinesDeletedStatus = ctr == this.state.recommendationHistoryInReverse.length ? 1 : 0
    //   // all wines for recommendations have been deleted

    if (this.state.errorOccurred == "getRecommendationHistory") {
      result.push(
        <div className="recommendation-history-main-container dsk-err">
          <div className="error-boundary-main-container mt-5">
            {/* errorBoundaryImage */}
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.recommendationHistoryInReverse.length > 0) {
      // atleast one recommendation history is there
      // let len = this.state.recommendationHistoryInReverse.length
      // let togo = Math.min(this.state.idx + this.state.capIdx, this.state.recommendationHistoryInReverse.length)
      let togo = Math.min(
        this.state.idxFood + this.state.capIdx,
        this.state.recommendationHistoryInReverse.length
      );
      let tmp = [];
      for (let index = 0; index < togo; ++index) {
        // for (let index = 0; index < len; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div
              key={index}
              className="recommendation-history-main-container"
              style={{ width: "93% !important" }}
              onClick={() => {
                this.changeIndex(index);
              }}
            >
              <RecommendationHistoryCardAccordian
                key={index}
                apiCallDone={this.state.getRecommendationHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                recommendationQueryData={
                  this.state.recommendationHistoryInReverse[index]
                }
                getRecommendationHistory={this.getRecommendationHistory}
                isSelected={index === this.state.currentIndex}
              />
            </div>
          </div>
        );
      }
      // this.state.recommendationHistoryInReverse.map((el, index) => {

      //   result.push(
      //     <div className="reco-main" key={index} >
      //       <div  className="recommendations-custom-line"></div>
      //       <div key={index} className="recommendation-history-main-container" onClick={() => { this.changeIndex(index) }}>
      //         <RecommendationHistoryCardAccordian
      //           key={index}
      //           apiCallDone={this.state.getRecommendationHistoryApiCallDone}
      //           title="hi"
      //           paragraph="sample"
      //           recommendationQueryData={
      //             this.state.recommendationHistoryInReverse[index]
      //           }
      //           getRecommendationHistory={this.getRecommendationHistory}
      //           isSelected={index === this.state.currentIndex}
      //         />
      //       </div>
      //     </div>
      //   );
      // });
    } else {
      let emptyCardData = {
        title: "No recent recommendations",
        text: "Your recent recommendations will appear here",
        image: emptyCardSearchImage,
      };
      result.push(
        <div className="search-wine-empty-page-main-container">
          <EmptyPageCard emptyPageCardData={emptyCardData} />
        </div>
      );
    }

    return result;
  };

  renderWineRecommendationHistory = () => {
    // set to reverse order
    let result = [];

    if (this.state.errorOccurred == "getRecommendationHistory") {
      result.push(
        <div className="recommendation-history-main-container dsk-err">
          <div className="error-boundary-main-container mt-5">
            {/* errorBoundaryImage */}
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.getHistory.length > 0) {
      // atleast one recommendation history is there
      let len = this.state.getHistory.length;
      // let togo = Math.min(this.state.idx + this.state.capIdx, this.state.getHistory.length)
      let togo = Math.min(
        this.state.idxWine + this.state.capIdx,
        this.state.getHistory.length
      );
      let tmp = [];
      for (let index = 0; index < togo; ++index) {
        // for (let index = 0; index < len; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div
              key={index}
              className="recommendation-history-main-container"
              style={{ width: "93% !important" }}
              onClick={() => {
                this.changeIndex(index);
              }}
            >
              <RecommendationHistoryCardAccordian
                key={index}
                apiCallDone={this.state.getRecommendationGetHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                recommendationQueryData={this.state.getHistory[index]}
                isSelected={index === this.state.currentIndex}
              />
            </div>
          </div>
        );
      }
    } else {
      let emptyCardData = {
        title: "No recent recommendations",
        text: "Your recent recommendations will appear here",
        image: emptyCardSearchImage,
      };
      result.push(
        <div className="search-wine-empty-page-main-container">
          <EmptyPageCard emptyPageCardData={emptyCardData} />
        </div>
      );
    }

    return result;
  };

  renderRecommendedWines = () => {
    let result = [];
    let resultIndex = this.state.currentIndex;
    let { buttonFoodActive, buttonWineActive, emptyWineresult } = this.state;
    if (buttonFoodActive == true) {
      result.push(
        this.state.recommendationHistoryInReverse && (
          <div
            className="recommended-wines-desktop-main-container"
            key="Recommended Wines"
          >
            {this.state.recommendationHistoryInReverse[resultIndex] && (
              <RecommendedWinesDesktop
                isWineryBased={true}
                afterRecommendationResponse={this.afterRecommendationResponse}
                questionId={
                  this.state.recommendationHistoryInReverse[resultIndex].id
                }
              />
            )}
          </div>
        )
      );
    } else if (buttonWineActive == true && emptyWineresult == false) {
      result.push(
        <div
          className="recommended-wines-desktop-main-container"
          key="Recommended Wines"
        >
          {
            <RecommendedWinesDesktop
              wineResult={this.state.wineSearchResult}
              active={this.state.buttonWineActive}
              tab={this.state.tabChange}
              isWineryBased={true}
            />
          }
        </div>
      );
    } else if (emptyWineresult == true) {
      let emptyCardData = {
        title: "No recent recommendations",
        text: "Your recent recommendations will appear here",
        image: emptyCardSearchImage,
      };
      result.push(
        <div
          className="recommended-wines-desktop-main-container"
          key="Recommended Wines"
        >
          {<EmptyPageCard emptyPageCardData={emptyCardData} />}
        </div>
      );
    }

    return result;
  };

  toggle = (index) => () => {
    this.setState({ [`block${index}`]: !this.state[`block${index}`] });
  };

  chatClicked = () => {
    this.setState({
      chatStatus: true,
    });
  };
  switchWineryClicked = () => {
    this.setState({
      switchWineryStatus: true,
    });
  };

  gotoWineryDetailsPage = () => {
    if (this.state.errorMessage.startsWith("Your email is no longer")) {
      this.setState({
        gotoWineryDetailsPageStatus: false,
      });
    } else {
      this.setState({
        gotoWineryDetailsPageStatus: true,
      });
    }
  };

  componentDidUpdate(prevProps, prevStates) {
    //let wineryData = sessionStorage.getItem('wineryName');
    // let errMsg =  "Your email is no longer associated with " + wineryData +" winery, please contact the winery to gain access again. "
    if (this.state.switchWineryStatus) {
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/select-winery",
      //       state: {},
      //     }}
      //   />
      // );
      this.props.history.push({
        pathname: "wineries",
        state: {},
      });
    }
    if (this.state.gotoWineryDetailsPageStatus) {
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/winery-details",
      //       state: {},
      //     }}
      //   />
      // );
      this.props.history.push({
        pathname:
          "/" +
          commonFunctions.convertToCamelCase(
            localStorage.getItem("winery_name")
          ) ,
        // pathname: "/winery-details",
        state: {},
      });
    }

    if (
      this.state.chatStatus &&
      this.state.errorMessage != "Something went wrong. Please try again later"
    ) {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/chat" + "/" + wineryId,
      //       state: {},
      //     }}
      //   />
      // );
      this.props.history.push({
        pathname: "/chat" + "/" + wineryId,
        state: {},
      });
    }

    if (this.state.recommendationsPageLoaded) {
      try {
        let wineryId = JSON.parse(localStorage.getItem("winery_id"));

        // return (
        //   <Redirect
        //     to={{
        //       pathname:
        //         "/winery/" + wineryId + "/recommended-wines/" +
        //         this.state.recommendationSearchResponse.id,
        //       state: {
        //         dishName: this.state.dishName,
        //         questionId: this.state.recommendationSearchResponse.id,
        //       },
        //     }}
        //   />
        // );
        this.props.history.push({
          pathname:
            "/winery/" +
            wineryId +
            "/recommended-wines/" +
            this.state.recommendationSearchResponse.id,
          state: {
            dishName: this.state.dishName,
            questionId: this.state.recommendationSearchResponse.id,
          },
        });
      } catch (e) {}
    }
    if (
      prevStates.recommendationSearchResponse !==
      this.state.recommendationSearchResponse
    ) {
      this.getNewRecommendationResponse(
        this.state.recommendationSearchResponse
      );
    }
  }

  renderBatch() {
    // let len = this.state.recommendationHistoryInReverse.length
    // let togo = Math.min(this.state.idx + this.state.capIdx, this.state.recommendationHistoryInReverse.length)
    let togo = this.state.buttonFoodActive
      ? Math.min(
          this.state.idxFood + this.state.capIdx,
          this.state.recommendationHistoryInReverse.length
        )
      : Math.min(
          this.state.idxWine + this.state.capIdx,
          this.state.getHistory.length
        );
    let result = [];
    for (let index = 0; index < togo; ++index) {
      result.push(
        <div className="reco-main" key={index}>
          <div className="recommendations-custom-line"></div>
          <div
            key={index}
            className="recommendation-history-main-container"
            style={{ width: "93% !important" }}
            onClick={() => {
              this.changeIndex(index);
            }}
          >
            <RecommendationHistoryCardAccordian
              key={index}
              apiCallDone={
                this.state.buttonFoodActive
                  ? this.state.getRecommendationHistoryApiCallDone
                  : this.getRecommendationGetHistoryApiCallDone
              }
              title="hi"
              paragraph="sample"
              recommendationQueryData={
                // this.state.recommendationHistoryInReverse[index]
                this.state.buttonFoodActive
                  ? this.state.recommendationHistoryInReverse[index]
                  : this.state.getHistory[index]
              }
              getRecommendationHistory={this.getRecommendationHistory}
              isSelected={index === this.state.currentIndex}
            />
          </div>
        </div>
      );
    }
    this.setState({
      idxFood: this.state.buttonFoodActive ? togo : this.state.idxFood,
      idxWine: this.state.buttonFoodActive ? this.state.idxWine : togo,
      rs: result,
    });
  }

  render() {
    // let wineryData = sessionStorage.getItem('wineryName');
    // let errMsg =  "Your email is no longer associated with " + wineryData +" winery, please contact the winery to gain access again. "
    const { foundFoods, showResult, showNoFood, buttonFoodActive } = this.state;

    const {
      state: { opened },
    } = this;
    const wineryId = JSON.parse(localStorage.getItem("winery_id"));
    return (
      <div className="search-wine-for-dish-main-desktop">
        <div className="search-wine-for-dish-main-container-desktop">
          {/* <div className="search-wine-for-dish-header">
            <div className="search-wine-for-dish-subheader">
              <span className="search-wine-for-dish-text">
                {" "}
                Pairing Recommendations
              </span>
              <img
                alt="PairAnything"
                className="search-wine-for-dish-pair-anything-logo"
                src={pairAnythingLogo}
              />
            </div>
          </div> */}
          <div className="top-navigation">
            <TopNavigation searchWineActive="true" />
          </div>
          {/* {
            // this.state.errorMessage != '' ?
            // this.state.errorMessage

            // this.state.errorMessage != "" ? (
              this.state.errorMessage ==
              "You do not have access to this page." ? (
                <PageNotFound></PageNotFound>
              ) : (
                ""
              )
            // ) :  ''
            } */}
          {/* <div
            {...{
              className: `switch-winery-main-container shadow accordion-item , ${
                opened && "accordion-item--opened"
                }`,
            }}
          >
            <div
              {...{
                className:
                  "switch-winery-switch-winery-accordion-item__line accordion-item__line",
              }}
            >
              <div className="recommendation-history-card-accordion-header">
                <span className="switch-winery-summary-container">
                  <img
                    src={this.state.wineryLogo}
                    className="switch-winery-logo"
                  />
                  <span className="switch-winery-name">
                    {this.state.wineryName}
                  </span>
                </span>
              </div>

              <span
                {...{
                  className:
                    "switch-winery-accordion-item__icon accordion-item__icon",

                  onClick: (e) => {
                    // this.getResponseForQuestionFromWineryAdmin(recommendationQueryData.id)

                    this.setState({
                      opened: !opened,
                    });
                    e.stopPropagation(); // to stop parent div onclick to get fired so as to enable arrow up/down
                  },
                }}
              />
            </div>

            <div className="switch-winery-accordion-item__inner  accordion-item__inner">
              <div className="col-xs-12 show-grid mb-3 p-0">
                <div
                  className="col-xs-10 switch-winery-description"
                  onClick={this.gotoWineryDetailsPage.bind(this)}
                > */}
          {/* <span onClick={this.gotoWineryDetailsPage.bind(this)}> */}
          {/* {paragraph} */}
          {/* {this.state.wineryMessage != "" &&
                    this.state.wineryMessage != null
                    ? this.state.wineryMessage
                    : "Winery message coming soon"}
                  {/* </span>
                </div>
                <div className="col-xs-2 chat-switch-winery">
                  <img
                    className={
                      this.state.errorMessage ===
                        "Something went wrong. Please try again later"
                        ? "chat-winery-icon-disabled"
                        : "switch-winery-icon"
                    }
                    src={chatIcon}
                    onClick={this.chatClicked.bind(this)}
                  />
                  <img
                    className="switch-winery-icon mt-5"
                    src={switchWineryIcon}
                    onClick={this.switchWineryClicked.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div> */}

          {/*

 */}
          {this.state.wineryName === "Scott Harvey Wines" ? (
            <CouponModal openDialog={true} initial={true} wineryId={wineryId} />
          ) : null}
          {/* <QuizUsersPopup openDialog={true} initial={true} /> */}
          {
            // this.state.errorMessage == 'Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin'?

            // '' :

            this.state.wineryDisabledResponseFromAPI == false &&
              this.state.errorMessage == "" && (
                <div className="recommendations-desktop-main-container">
                  <div className="pairing-recommendations-main-header">
                    Pairing Recommendations
                  </div>
                  <div className="thin-divider-line-recommendations"></div>
                  {/* <div className="search-dish-header-text">
                  Enter Dish or Recipe name
                </div> */}
                  {/* <div style={{ width: "48%", marginLeft: "2%", marginTop: "1%", display: "flex" }}>
                  <div className="recommendation-tab-input form-control search-wine-form-tab-input">
                    <button
                      className="btn btn-round search-btn-tab"
                      style={{ backgroundColor: this.state.buttonFoodActive ? '#cd4545' : 'white', color: this.state.buttonFoodActive ? 'white' : '#cd4545' }}
                      onClick={() => this.foodclick()}
                    >
                      Food
                    </button>
                    <button
                      className="btn btn-round search-btn-tab"
                      style={{ backgroundColor: this.state.buttonWineActive ? '#cd4545' : 'white', color: this.state.buttonWineActive ? 'white' : '#cd4545' }}
                      onClick={() => this.wineclick()}
                    >
                      Wine
                    </button>

                  </div>
                </div> */}

                  {/* <div className="form-group search-box-desktop">
                  <div className="recommendation-input form-control search-wine-form-row-input-desktop shadow">
                    <i class="fa fa-search" onClick={() => this.state.buttonFoodActive ? this.gotoRecommendationsPage() : this.getWineryRecommendations()} aria-hidden="true"></i>

                    <input
                      placeholder={this.state.buttonFoodActive ? "Search by Food" : "Search by Wine type or Wine varietal"}
                      type="text"
                      name="dishnhttp://localhost:3000/search-wine/97ame"
                      className="input-search-box"
                      value={this.state.dishName}
                      onChange={this.handleChange.bind(this)}
                      onKeyDown={(e) => e.key === 'Enter'? this.state.buttonFoodActive ? this.gotoRecommendationsPage() : this.getWineryRecommendations():''}
                      style={{ paddingLeft: '18px' }}
                    />
                  </div>

                </div> */}
                  {this.state.errorOccurred == "postPairingQuestion" && (
                    <div className="recommendation-input-error-message-container">
                      <span className="recommendation-input-error-message">
                        {this.state.dishName == ""
                          ? this.state.dishNameStatus
                          : ""}

                        {
                          this.state.errorOccurred == "postPairingQuestion"
                            ? this.state.errorMessage
                            : "" // this.state.errorMessage
                        }
                      </span>
                    </div>
                  )}
                </div>
              )
          }
          {/*
                #NOTE: renderRecommendationHistory renders the recommendation history card if there is
                       any recommendation. Otherwise, the EmptyPageCard is rendered
              */}
          <div>
            <div className={"search-wine-for-dish-container-desktop"}>
              <div style={{ marginTop: "1%", display: "flex" }}>
                <div className="recommendation-tab-input form-control search-wine-form-tab-desktop-input">
                  <button
                    className="btn btn-round search-btn-tab"
                    style={{
                      backgroundColor: this.state.buttonFoodActive
                        ? "#cd4545"
                        : "white",
                      color: this.state.buttonFoodActive ? "white" : "#cd4545",
                    }}
                    onClick={() => this.foodclick()}
                  >
                    Food
                  </button>
                  <button
                    className="btn btn-round search-btn-tab"
                    style={{
                      backgroundColor: this.state.buttonWineActive
                        ? "#cd4545"
                        : "white",
                      color: this.state.buttonWineActive ? "white" : "#cd4545",
                    }}
                    onClick={() => this.wineclick()}
                  >
                    Wine
                  </button>
                </div>
              </div>
              <div className="form-group search-box-desktop">
                <div className="recommendation-input form-control search-wine-form-row-input-desktop shadow">
                  <i
                    className="fa fa-search pointer"
                    onClick={() =>
                      this.state.buttonFoodActive
                        ? this.gotoRecommendationsPage()
                        : this.getWineryRecommendations()
                    }
                    aria-hidden="true"
                  ></i>

                  <input
                    placeholder={
                      this.state.buttonFoodActive
                        ? "Search by Food"
                        : "Search by Wine type or Wine varietal"
                    }
                    type="text"
                    name="dishname"
                    className="input-search-box"
                    value={this.state.dishName}
                    onChange={
                      buttonFoodActive == true
                        ? this.onInputChange.bind(this)
                        : this.handleChange.bind(this)
                    }
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? this.state.buttonFoodActive
                          ? this.gotoRecommendationsPage()
                          : this.getWineryRecommendations()
                        : ""
                    }
                    style={{ paddingLeft: "14px", width: "100%" }}
                  />
                </div>
                {foundFoods.length > 0 && showResult && (
                  <div className="recommendation-input form-control search-wine-form-row-recommand-input-desktop shadow">
                    {foundFoods &&
                      foundFoods.map((item, idx) => {
                        // console.log(foundFoods)
                        return (
                          <div
                            className="dropText"
                            key={idx}
                            onClick={this.onClick.bind(this)}
                          >
                            {item}
                          </div>
                        );
                      })}
                  </div>
                )}
                {showNoFood && (
                  <div className="recommendation-input form-control search-wine-form-row-recommand-input-desktop shadow">
                    <div className="dropText">No Food Matches!</div>
                  </div>
                )}
              </div>
              <ErrorBoundary>
                {this.state.buttonFoodActive
                  ? this.renderRecommendationHistory()
                  : this.renderWineRecommendationHistory()}
              </ErrorBoundary>
              {this.state.buttonFoodActive
                ? this.state.idxFood <
                    this.state.recommendationHistoryInReverse.length -
                      this.state.capIdx && (
                    <div
                      className="reco-main"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "5%",
                      }}
                    >
                      <button
                        className="btn btn-round
                                    confirm-shipping-address-btn1 search-btn"
                        disabled={this.state.disableSearchBtn}
                        onClick={this.renderBatch.bind(this)}
                        style={{ fontStyle: "italic" }}
                      >
                        More
                      </button>
                    </div>
                  )
                : this.state.idxWine <
                    this.state.getHistory.length - this.state.capIdx && (
                    <div
                      class="reco-main"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "5%",
                      }}
                    >
                      <button
                        className="btn btn-round
                                    confirm-shipping-address-btn1 search-btn"
                        disabled={this.state.disableSearchBtn}
                        onClick={this.renderBatch.bind(this)}
                        style={{ fontStyle: "italic" }}
                      >
                        More
                      </button>
                    </div>
                  )}
            </div>
            {/* <div> */}
            {this.state.errorOccurred != "getRecommendationHistory" &&
              this.renderRecommendedWines()}
            {/* </div> */}
          </div>
        </div>
        {this.state.showTastePreferencePopUp && (
          <TastePreferencePopUp
            open={this.state.showTastePreferencePopUp}
            handleYes={this.handleYes} // go to select taste preference
            handleCancel={this.handleCancel} // do it later
          />
        )}
        <RateRecommendationPopup
          open={this.state.showRateRecommendationPopup}
        />
      </div>
    );
  }
  afterRecommendationResponse = (data) => {
    const recommendationHistoryInReverse = [
      ...this.state.recommendationHistoryInReverse,
    ];
    if (
      recommendationHistoryInReverse[this.state.currentIndex].recommendation ==
        null &&
      data.recommendation != null
    ) {
      recommendationHistoryInReverse[this.state.currentIndex] = { ...data };
      recommendationHistoryInReverse[this.state.currentIndex] = data;
      this.setState({ recommendationHistoryInReverse });
    }
  };
  getNewRecommendationResponse = async (searchResponse) => {
    let wineryIdForGetResponse = JSON.parse(localStorage.getItem("winery_id"));
    let getResponseForQuestionApiUrl =
      constants.IDENTITY_API +
      "/api/v1/recommendation?questionId=" +
      searchResponse.id +
      "&wineryId=" +
      wineryIdForGetResponse;
    try {
      const recommendedDetails = await axiosCall(
        "GET",
        getResponseForQuestionApiUrl
      );
      let recommendationHistoryInReverse = [
        ...this.state.recommendationHistoryInReverse,
      ];
      recommendationHistoryInReverse.unshift(recommendedDetails.data);
      this.setState({
        recommendationHistoryInReverse,
        idx: 0,
        currentIndex: 0,
      });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getResponseForQuestionApiUrl,
        operation: "GET",
        status: e.status,
      };
      commonFunctions.postCaughtException(
        JSON.stringify(apiErrorObj),
        consumerData,
        null
      );
    }
  };
}

export default RecommendationsDesktop;
