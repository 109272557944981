import * as types from './action-types';
import * as constants from '../../constants/constants'
import { dispatch } from 'rxjs/internal/observable/pairs';


export const GetCartTotalQty = (wineryId) => {
    let signInToken = (JSON.parse(localStorage.getItem('signin_token')));
    let bearer = 'Bearer ' + signInToken;
    // console.log('GetCartTotalQty', wineryId);
    return dispatch => {
        // return dispatch({ type: types.ADD_TO_CART_SUCCEED, payload: "Hi" })

        fetch(
            constants.SHOPPING_CART_API + 
            '/api/v1/users/winery/' + wineryId + '/cart/count', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            }

        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.GET_CART_TOTAL_QTY_SUCCESS, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.GET_CART_TOTAL_QTY_FAILED, payload: error })
        })
    }
}

export const UpdateCartQuantity = (cartTotalQuantity) => {
    let obj = {
        quantity: cartTotalQuantity
    }
    // console.log(obj);
    return dispatch => {
        dispatch({ type: types.GET_CART_TOTAL_QTY_SUCCESS, payload: obj })
    }
}