import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.USER_SIGNUP_SUCCEEDED:
            return {
                ...state,
                signupSuccess: action.payload
            };

        case types.USER_SIGNUP_FAILED:
            return {
                ...state,
                signupFail: action.payload
            };

        default:
            return state;
    }
};
