export const PLACER_COUNTY_WINERIES = [
    // {
    //     id: 1,
    //     wineName: 'Baker Family Wines',
    //     winerUrl: 'https://www.bakerfamilywines.com/new-index',
    //     wineImage: require('../../../assets/images/Visit/Placer_County/baker_family_wines.png')
    // },
    {
        id: 2,
        wineName: 'Bonitata Winery',
        winerUrl: 'https://www.winesbymark.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Bonitata.jpg')
    },
    {
        id: 3,
        wineName: 'Bear River Winery & Distillery',
        winerUrl: 'http://www.bearriverwinery.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Bear_River.jpg')
    },
    {
        id: 4,
        wineName: 'Cante Ao Vinho',
        winerUrl: 'https://canteaovinho.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Cante_Ao_Vinho.jpeg')
    },
    {
        id: 5,
        wineName: 'Casque Wines',
        winerUrl: 'https://www.casquewines.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Le_Casque.png')
    },
    {
        id: 6,
        wineName: 'Ciotti Cellars',
        winerUrl: 'https://www.ciotticellars.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Ciotti.jpg')
    },
    {
        id: 7,
        wineName: 'Cristaldi Vineyards',
        winerUrl: 'http://www.cristaldivineyards.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Cristaldi.jpg')
    },
    {
        id: 8,
        wineName: 'Dono dal Cielo',
        winerUrl: 'https://donodalcielo.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Dono_Dal_Cielo.png')
    },
    {
        id: 9,
        wineName: 'Dora Dain Wines',
        winerUrl: 'https://doradainwines.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Dora_Dain.jpeg')
    },
    {
        id: 10,
        wineName: 'Fawnridge Winery',
        winerUrl: 'https://www.fawnridgewine.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Fawnridge.jpg')
    },
    {
        id: 11,
        wineName: 'Lone Buffalo Vineyards',
        winerUrl: 'https://lonebuffalovineyards.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Lone_Buffalo.png')
    },
    {
        id: 12,
        wineName: 'Mount Saint Joseph Wines',
        winerUrl: 'https://www.mountsaintjosephwines.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Mount_Saint_Joseph.png')
    },
    {
        id: 13,
        wineName: 'Mt Vernon Winery',
        winerUrl: 'https://mtvernonwinery.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Mt_Vernon.jpg')
    },
    {
        id: 14,
        wineName: 'PaZa Estate Winery',
        winerUrl: 'https://www.pazawines.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/PaZa.PNG')
    },
    {
        id: 20,
        wineName: 'Pescatore Vineyard & Winery',
        winerUrl: 'http://www.pescatorewines.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Pescatore.jpeg')
    },
    {
        id: 15,
        wineName: 'Popie Wines',
        winerUrl: 'http://www.popiewines.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Popie.png')
    },
    {
        id: 16,
        wineName: 'Rancho Roble Vineyards',
        winerUrl: 'https://www.ranchoroble.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Rancho_Roble.png')
    },
    {
        id: 21,
        wineName: 'Rock Hill Winery',
        winerUrl: 'http://rockhillwine.com/index.html',
        wineImage: require('../../../assets/images/Visit/Placer_County/Rock_Hill.jpg')
    },
    {
        id: 17,
        wineName: 'Secret Ravine Vineyards',
        winerUrl: 'https://www.secretravine.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Secret_Ravine.png')
    },
    {
        id: 18,
        wineName: 'Viña Castellano Winery',
        winerUrl: 'https://vinacastellano.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Vina_Castellano.png')
    },
    {
        id: 19,
        wineName: 'Wise Villa Winery & Bistro',
        winerUrl: 'https://wisevillawinery.com/',
        wineImage: require('../../../assets/images/Visit/Placer_County/Wise_Villa.jpg')
    },
    {
        id: 22,
        wineName: 'Fortezza Winery',
        winerUrl: 'http://www.fortezzawinery.com/',
        wineImage: ''
    }
]