// For JIRA PAIR-1571
import React, {useState, Component} from 'react';
import './ShareButton.scss';
import shareIcon from "../../../assets/images/shareIcon.svg";
import shareIconDesktop from "../../../assets/images/shareIconDesktop.svg";
import ShareButtonPopUp from '../ShareButtonPopUp/ShareButtonPopUp'
import * as constants from "../../constants/constants";
import { isMobile } from 'react-device-detect';

export default class ShareButton extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
          open: true,
          initial_load: true,
          wineryApiCallDone: false,
          wineryName: "h"
        };
    }

    handleOpen = (e) => {
      e.stopPropagation();
        this.setState({open: !this.state.open, 
            initial_load: false});
    }

    getWineryDetails = (wineryId) => {
      // Get winery name from API call
      let consumerData = JSON.parse(localStorage.getItem('user_data'));
      let signInToken = JSON.parse(localStorage.getItem('signin_token'));
      let bearer = 'Bearer ' + signInToken;
      let getWineryDetailsApiUrl =
        constants.WINERY_API + '/api/v1/public/winery/consumer?wineryId=' + wineryId;
      fetch(getWineryDetailsApiUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getWineryDetailsApiUrl,
              operation: 'GET',
              status: res.status,
            };
  
            this.postCaughtException(JSON.stringify(apiErrorObj));
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          this.setState({
            wineryApiCallDone: true,
            wineryName: json.name
          });
        })
        .catch((error) => console.error(error));
    };
  
    render() {
      const {wine} = this.props;
      if(!this.state.wineryApiCallDone && wine[0].wineryId != "") { 
        this.getWineryDetails(wine[0].wineryId);
      }
      return (
        <span>
          <button className="share-button" onClick={this.handleOpen}>
             <img className="share-image" src={isMobile ? shareIcon : shareIconDesktop} />
           </button>
            <ShareButtonPopUp openDialog={this.state.open} initial={this.state.initial_load}
                wine={wine} winery={this.state.wineryName} />
        </span>
      );
    };
  }
