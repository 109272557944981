import React, { Component } from "react";

import "./CheckInPopUp.scss";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import checkinSuccess from "../../../assets/images/checkin_success.svg";
import checkinFailure from "../../../assets/images/checkin_failure.svg";
import { isMobile } from 'react-device-detect';


const theme = createMuiTheme({
    palette: {
        primary: { main: "#cd4545" },
    },
});


const GlobalCss = withStyles({
    "@global": {
        ".MuiPaper-root": {
            bottom: "0 !important",
            background: "none",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
        },
        ".MuiBackdrop-root": {
            background: "#F2F2F299 0% 0% no-repeat padding-box",
        },
        ".MuiTypography-body1": {
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif !important"
        },
        ".MuiInputBase-input": {
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif !important",
            width: "100%",
            border: 0,
            display: "block",
            padding: "6px 0 7px",
            minWidth: 0,
            background: "none",
            boxSizing: "content-box",
            animationName: "mui-auto-fill-cancel",
            lineHeight: "1.2",
        }
    },
})(() => null);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const contentStyle = {
    justify: "center",
    paddingRight: "19px",
    display: "flex",
    flexDirection: "column",
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const popupStyle = { 
    fontFamily: "Poppins, Regular sans-serif",
    position: "fixed",
    right: "25%",
    left: "25%",
}

class CheckInPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onDrawerOpen = () => {

    }

    onDrawerClose = () => {

    }

    render() {
        // console.log('rendering checkinpopup')
        const { open, handleCancel, checkinMsg, checkinStatus } = this.props;
        // console.log(checkinMsg)
        return (
            <React.Fragment>
                {isMobile ? 
                    <MuiThemeProvider theme={theme}>
                        <SwipeableDrawer
                            onBackdropClick={() => { handleCancel() }}
                            anchor="bottom"
                            open={open}
                            style={contentStyle}
                            onOpen={this.onDrawerOpen}
                            onClose={this.onDrawerClose}
                        >
                            <GlobalCss />
                            <div className="bg font-family" style={{ padding: "50px 25px 50px 25px", textAlign: "center" }}>
                                <div>
                                    <img src={ checkinStatus ? checkinSuccess : checkinFailure }/>
                                </div>
                                <br/>
                                <div className='checkintext-style'>
                                    <span>{checkinMsg}</span>
                                </div>                                
                            </div>
                        </SwipeableDrawer>
                    </MuiThemeProvider>
                    :
                    <Dialog
                        onBackdropClick={() => { handleCancel() }}
                        open={open}
                        style={popupStyle} 
                        TransitionComponent={Transition}
                        >
                        <DialogContent className="content-style">
                            <div style={{padding: "25px 25px 25px 25px"}}>
                                <div style={{background: "#FFFFFF", overflowY: "visible"}}>
                                    <button className={isMobile ? "exit-button" : "exit-button-desktop" }
                                            onClick={handleCancel} style={{justifyContent: "right", alignContent: "right", alignItems: "right"}}>
                                                X
                                    </button>
                                </div>
                                <div>
                                    <img src={ checkinStatus ? checkinSuccess : checkinFailure }/>
                                </div>
                                <br/>
                                <div className='checkintext-style'>
                                    <span>{checkinMsg}</span>
                                </div>                                
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </React.Fragment>
        )
    }
}

export default CheckInPopUp;
