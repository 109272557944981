import React from 'react';
import './ReviewWineModal.scss';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CheckImage from "../../../../assets/images/Events/check-green.svg";

const ThankYouModal = (props) =>{

  const {open, handleClose} = props;

  return(
    <Dialog open={open} onClose={handleClose}>
    <DialogContent>
      <div className="wine-review-thank-you-container">
        <img src={CheckImage} alt="green-check"/>
        <p>Thank you for submitting review</p>
      </div>
    </DialogContent>
  </Dialog>
  )
}

export default ThankYouModal;