import axiosCall from "../../constants/index";
import * as constants from "../../constants/constants";

export const getAllFoodRequestDetails = async (url) => {
  // let url = constants.GOOGLE_SEARCH_API + '/api/v1/recommendation/getAllPARecoRequests';
  let response = await axiosCall("get", url);
  return response.data;
};

export const getFoodRequestDetails = async (url, paRequestId) => {
  let response = await axiosCall("get", url);
  return response.data;
};

export const postFoodRequest = async (url, data) => {
  let response = await axiosCall("post", url, data);
  return response.data;
};

export const updateFoodRequestDetails = async (url, data) => {
  let response = await axiosCall("put", url, data);
  return response.data;
};

export const wineFoodPairingAdd = async (dishName) => {
  let consumerData = JSON.parse(localStorage.getItem("user_data"));
  let sessionData = JSON.parse(localStorage.getItem("anonymous_session"))
  let url = constants.IDENTITY_API + "/api/v1/public/foodPairing/add";
  if(consumerData && consumerData !=null)
    url = url + "?userId=" + consumerData.id;
  else
    url = url + "?userSessionId=" + sessionData.id;
  const path = `${url}&name=${dishName}`;
  let response = await axiosCall("get", path);
  return response.data;
};

export const getWinePairingHistory = async () => {
  let consumerData = JSON.parse(localStorage.getItem("user_data"));
  let sessionData = JSON.parse(localStorage.getItem("anonymous_session"))
  let url = constants.IDENTITY_API + "/api/v1/public/foodPairing/allSearchResults";
  if(consumerData && consumerData !=null)
    url = url + "?userId=" + consumerData.id;
  else
    url = url + "?userSessionId=" + sessionData.id
  const path = `${url}`;
  let response = await axiosCall("get", path);
  return response.data;
};

export const getFoodResult = async (dishName) => {
  let url =
    constants.GOOGLE_SEARCH_API + "/api/v1/public/foodNamesDetails/getPhonetic";
  const path = `${url}?value=${dishName}`;
  let response = await axiosCall("get", path);
  return response.data;
};

export const getVarietalResult = async (wineQuery) => {
  let url =
      constants.GOOGLE_SEARCH_API + "/api/v1/public/varietalNames/getPhonetic";
  const path = `${url}?value=${wineQuery}`;
  let response = await axiosCall("get", path);
  return response.data;
};

export const addNoData = async (dishName, userSessionId) => {
  let url =
    constants.GOOGLE_SEARCH_API + "/api/v1/public/foodNamesDetails/createNewFood";
  const path = `${url}?value=${dishName}`;
  let response = await axiosCall("post", path, userSessionId);
  return response.data;
};
