import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../assets/images/splash.png";
import "./SideNavBar.scss";
import rightArrowBtn from "../../../assets/images/right-arrow-profile.svg";
import signOutBtn from "../../../assets/images/sign-out-profile.svg";
import AlertDialogSlide from "../../components/AlertDialog/AlertDialog";
import * as signoutAction from "../../redux/actions/Signout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as constants from "../../constants/constants";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#cd4545" },
  },
});

const GlobalCss = withStyles({
  "@global": {
    ".MuiPaper-root": {
      background: "#FFFFFF !important",
      borderTopLeftRadius: isMobile ? "unset !important" : "25px !important",
      borderTopRightRadius: "unset !important",
      borderBottomLeftRadius: isMobile ? "unset !important" : "25px !important",
      borderBottomRightRadius: "unset !important",
      width: isMobile ? "83% !important" : "400px !important",
      position: "fixed",
      marginTop: isMobile ? null : "5vh",
      height: isMobile ? null : "90vh",
    },
    ".MuiBackdrop-root": {
      background: "#F2F2F299 0% 0% no-repeat padding-box",
    },
    ".MuiDrawer-root": {
      marginLeft: "auto !important",
      marginRight: "auto !important",
      borderRadius: "25px",
      width: "430px !important",
    },
  },
})(() => null);

class SideNavBar extends Component {
  state = {
    alertDialogStatusSuccess: false,
  };

  onDrawerOpen = () => {};

  onDrawerClose = () => {
    document.body.style.overflow = null;
  };

  // componentDidMount() {
  //     document.addEventListener('mousedown', this.handleClick, false);
  // }

  // componentWillUnmount() {
  //     document.removeEventListener('mousedown', this.handleClick, false);
  // }

  // handleClick = (e) => {
  //     if (this.node) {
  //         if (this.node.contains(e.target)) {
  //             return
  //         }
  //         this.props.closeMenu();
  //     }
  // }

  onNavClick = (routeName) => {
    if (routeName === "Sign Out") {
      this.setState({
        alertDialogStatusSuccess: true,
      });
      this.props.closeMenu();
    } else if (routeName === "App Feedback") {
      GoogleAnalyticsFunctions.clickedAppFeedback();
      // var newWindow = window.open(
      //   "https://docs.google.com/forms/d/e/1FAIpQLSfgO2d3kRDup9jUuAyDawBx2QKj61n8Ce5NKoG1YGXKbejdrw/viewform",
      //   "_blank"
      // );
      // newWindow.focus();
      this.props.history.push("/feedback");
    } else if (routeName === "Help") {
      GoogleAnalyticsFunctions.clickedHelpPage();
      this.props.history.push("/help-page");
    } else if (routeName === "Discover") {
      GoogleAnalyticsFunctions.clickedDiscoverPage();
      this.props.history.push("/discover");
    }
  };

  render() {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    const { closeMenu, showSideMenu } = this.props;
    let navOptions = ["Discover", "App Feedback", "Help"];
    navOptions = signInToken ? [...navOptions, "Sign Out"] : [...navOptions];
    return (
      <React.Fragment>
        <div
          className="success-main-container"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div>
            <div
            // style={
            //   isMobile
            //     ? { backgroundColor: "#f2f2f2" }
            //     : {
            //         backgroundColor: "#f2f2f2",
            //         height: "80vh",
            //         overflowY: "scroll",
            //         overflowX: "hidden",
            //       }
            // }
            >
              {this.state.alertDialogStatusSuccess ? (
                <AlertDialogSlide
                  signOutData="true"
                  signOutStatus={this.getSignOutStatus.bind(this)}
                />
              ) : (
                ""
              )}
              <MuiThemeProvider theme={theme}>
                <SwipeableDrawer
                  onBackdropClick={() => {
                    closeMenu();
                  }}
                  anchor="center"
                  open={showSideMenu}
                  // style={contentStyle}
                  onOpen={this.onDrawerOpen}
                  onClose={this.onDrawerClose}
                >
                  <GlobalCss />
                  {/* ref={node => this.node = node} */}
                  <div className="font-family mt-5">
                    {/* <a href="#" className="closebtn" onClick={() => closeMenu()}>×</a> */}
                    <div className="side-nav-logo-container">
                      <img
                        alt="PairAnything"
                        className="side-nav-logo-width"
                        src={logo}
                      />
                    </div>
                    <div className="side-nav-links-container">
                      {navOptions.map((el, index) => {
                        return (
                          <div
                            key={el}
                            onClick={(e) => {
                              this.onNavClick(el);
                              e.stopPropagation();
                            }}
                            className="nav-link-container ptr-clss"
                          >
                            <div>
                              <span className="">{el}</span>
                            </div>
                            <img
                              alt="Go to page"
                              className="nav-right-arrow"
                              src={
                                el === "Sign Out" ? signOutBtn : rightArrowBtn
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </SwipeableDrawer>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getSignOutStatus = (status) => {
    let consumerWineryStatus = localStorage.getItem("user_winery_status");
    if (status) {
      if (
        consumerWineryStatus != "" &&
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus != "disabled"
      ) {
        this.deleteNotificationSubscription();
      }

      try {
        localStorage.clear();
        localStorage.setItem("ageConfirmed", true);
      } catch (e) {}
      localStorage.setItem("signout", "yes");
      this.props.signoutAction.ResetApp();
      this.props.history.push({
        pathname: "/discover",
        state: {
          signOutDone: true,
        },
      });
    } else {
      this.setState({
        alertDialogStatusSuccess: false,
      });
    }
  };

  deleteNotificationSubscription = () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));
    let fcmToken = JSON.parse(localStorage.getItem("fcm_token"));

    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    let deleteNotificationSubscriptionApiUrl =
      constants.IDENTITY_API + "/api/v1/notificationSubscription/delete";

    if (fcmToken) {
      let apiData = {
        userId: consumerData.id,
        wineryId: wineryId,
        fcmToken: fcmToken,
      };

      fetch(deleteNotificationSubscriptionApiUrl, {
        // dev
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(apiData),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: deleteNotificationSubscriptionApiUrl,
              operation: "DELETE",
              status: res.status,
            };

            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .then((json) => {})
        .catch((error) => console.error(error));
    }
  };
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    // FindTastePreferenceByCustomerAction: bindActionCreators(FindTastePreferenceByCustomerAction, dispatch),
    signoutAction: bindActionCreators(signoutAction, dispatch),
  };
}

SideNavBar = withRouter(SideNavBar);
export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
