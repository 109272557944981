import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import "./TermsAndConditions.scss";
import backBtnBlack from "../../../assets/images/back-btn.svg";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { showSignUpFromTerms, acceptTermsAndConditions, cancelClickFromTerms } = this.props;
        return (
            <div className="sign-in-main-page overall-font">
                <div className="sign-in-main-container-background">
                    <div className="sign-in-main-container padng-style height-for-terms-page mt-0">
                        <div className='terms-header'>
                            {/* <div className='p-terms-width'> */}
                            <img
                                className="ptr-clss back-btn-width"
                                alt="Go Back"
                                src={backBtnBlack}
                                onClick={() => showSignUpFromTerms()}
                            />
                            {/* </div> */}
                            <div className='term-header-label'>
                                <span>{this.props.showTerms ? 'Terms of Use' : 'Privacy Policy'}</span>
                            </div>
                            <div>
                                {""}
                            </div>
                        </div>
                        {this.props.showPrivacy ? this.renderPrivacyPolicy() : this.renderTermsOfUse()}
                        <div className='terms-btns-div mt-4'>
                            <div className='mr-3'>
                                <button type="button"
                                    className="btn terms-cancel-click noHover"
                                    onClick={() => { cancelClickFromTerms() }}>
                                    Close
                                </button>
                            </div>
                            <div>
                                <button type="button" onClick={() => { acceptTermsAndConditions() }}
                                    className="btn terms-accept-click noHover">
                                    Accept
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }

    renderPrivacyPolicy = () => {
        return (
            <div className='terms-conditions-justify mt-4'>
                <div>
                    {/* <h4 className='all-terms-header-font terms-o'>Privacy</h4> */}
                    <p>
                        At Pair Anything, accessible from pairanything.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Pair Anything and how we use it
                </p>
                    <p>
                        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                </p>
                    <p>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Pair Anything. This policy is not applicable to any information collected offline or via channels other than this website.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Consent</h4>
                    <p>
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Information We Collect</h4>
                    <p>
                        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                    </p>
                    <p>
                        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                    </p>
                    <p>
                        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>How We Use Your Information</h4>
                    <p>
                        We use the information we collect in various ways, including to:
                    </p>
                    <ul>
                        <li>
                            Provide, operate, and maintain our website
                        </li>
                        <li>
                            Improve, personalize, and expand our website
                        </li>
                        <li>
                            Understand and analyze how you use our website
                        </li>
                        <li>
                            Develop new products, services, features, and functionality
                        </li>
                        <li>
                            Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes
                        </li>
                        <li>
                            Send you emails
                        </li>
                        <li>
                            Find and prevent fraud
                        </li>
                    </ul>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Log Files</h4>
                    <p>
                        Pair Anything follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Cookies and Web Beacons</h4>
                    <p>
                        Like any other website, Pair Anything uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
                    </p>
                    <p>
                        For more general information on cookies, please read <a href="#"
                            onClick={() => window.open("https://www.cookieconsent.com/what-are-cookies/", "_blank")}>"What Are Cookies"</a>.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Our Advertising Partners</h4>
                    <p>
                        Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
                    </p>
                    <p>
                        Google
                        <p>
                            <a href="#"
                                onClick={() => window.open("https://policies.google.com/technologies/ads", "_blank")}>
                                https://policies.google.com/technologies/ads
                                    </a>
                        </p>
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Advertising Partners Privacy Policies</h4>
                    <p>
                        You may consult this list to find the Privacy Policy for each of the advertising partners of Pair Anything.
                </p>
                    <p>
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Pair Anything, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                </p>
                    <p>
                        Note that Pair Anything has no access to or control over these cookies that are used by third-party advertisers.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Thirdy Party Privacy Policies</h4>
                    <p>
                        Pair Anything's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                    </p>
                    <p>
                        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
                    <p>
                        Under the CCPA, among other rights, California consumers have the right to:
                    </p>
                    <p>
                        Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                    </p>
                    <p>
                        Request that a business delete any personal data about the consumer that a business has collected.
                    </p>
                    <p>
                        Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
                    </p>
                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>GDPR Data Protection Rights</h4>
                    <p>
                        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                    </p>
                    <p>
                        The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                    </p>
                    <p>
                        The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                    </p>
                    <p>
                        The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                    </p>
                    <p>
                        The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                    </p>
                    <p>
                        The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                    </p>
                    <p>
                        The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </p>
                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                    </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Children's Information</h4>
                    <p>
                        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                    </p>
                    <p>
                        Pair Anything does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>
                </div>
            </div>);
    }
    renderTermsOfUse = () => {
        return (
            <div className='terms-conditions-justify mt-4'>
                <div>
                    {/* <h4 className='all-terms-header-font terms-o'>Terms of Use</h4> */}
                    <p>
                        Please read these terms of use carefully before using the services offered by Pair Anything, Inc. (hereinafter “Pair Anything”, “we” or us”), These terms of use set forth the legally binding terms and conditions (hereafter referred to as “Terms”) between Pair Anything and the User for all use of our websites, <a href="#"
                            className='pl-1 pr-1' onClick={() => window.open("https://www.pairanything.com/", "_blank")}>www.PairAnything.com,</a> app.pairanything.com and winerypairanything.com   (hereinafter “Internet Sites”), and all use of our application for mobile devices (hereinafter “App”). By using the functionalities of Pair Anything’s Internet Sites and/or App,  (hereinafter collectively called the “Platform”), the User agrees with the terms and conditions described in these Terms for using our Platform as described herein, and as may be amended from time to time by us. The date of the most recent changes or revisions is September 9, 2020.
                </p>
                    <p>
                        Pair Anything provides an online platform that enables registered users (hereinafter singular “User” and plural “Users”) to get recommendations, shop and learn about wine via website or mobile application. It also enables wineries and service provider partners (“hereinafter singular “Partner and plural “Partners”) to connect directly with Users through the platform.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Scope of terms</h4>
                    <p>
                        In order to use our Platform, the User must either sign in using a third-party application log-in, or fill out a form and create a profile without a third-party application log-in.
                </p>
                    <p>
                        When registering via our online Platform, the User provide us with certain information, including email, password, username, name, country of origin, preferred language. When registering and signing in via third-party application login (such as Facebook), and the User authorize us to access certain account information from that third-party application, such as basic profile information; name, e-mail address, current city (optional). Some of this information will never be made public, such as password.
                </p>
                    <p>
                        The Platform is intended for use by adults of legal drinking age in the jurisdiction in which you reside. If we receive notice or reasonably believe that someone under the legal drinking age has provided us with personal information, we will promptly delete the account and all personal information that has been provided to us.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Disclaimer</h4>
                    <p>
                        The materials contained on the App are provided as a service to you for your use on an "as is, as available" basis. You acknowledge that you are using the App at your own risk. Pair Anything, Inc. assumes no responsibility for error or omissions in these materials. Pair Anything, Inc. makes no commitment to update the information contained herein. Pair Anything, Inc. makes no, and expressly disclaims any and all, representations or warranties, express or implied, regarding the App, including without limitation the accuracy, completeness, timeliness, or reliability of text, graphics, links, products and services, and other items accessed from or via the App, or that the App will be uninterrupted, error-free, or free of viruses or other harmful components. No advice or information given by Pair Anything, Inc. or any other party on the App shall create any warranty or liability. the App and the materials provided herein are for informational purposes only and are not legal advice. No reader should rely on or act or refrain from acting on the basis of any matter or information contained in the App without seeking appropriate legal or other professional advice.
                </p>
                    <p>
                        Under no circumstances shall Pair Anything, Inc. or any of its parents, subsidiaries, affiliates, members, advisors or their respective partners, officers, directors, employees, or agents be held liable for any damages, whether direct, incidental, indirect, special, or consequential, and including, without limitation, lost data, revenues, or profits, loss of or damage to property and claims of third parties, arising from or in connection with your use or performance of the App or your reliance on the information on the App.
                </p>
                    <p>
                        Pair Anything, Inc. periodically schedules system downtime for maintenance and other purposes. Unplanned outages also may occur. Pair Anything, Inc. shall have no liability for the resulting unavailability of the App or for any loss of data or transactions caused by planned or unplanned system outages, or any outages of web host providers or the Internet infrastructure and network external to the App.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>
                        Copyright
                </h4>
                    <p>
                        All materials published on the App, including the compilation (meaning the collection, arrangement and assembly) of all content on the App, are protected by U.S. and international copyright laws. You may not reproduce, republish, distribute, transmit, resell, display, broadcast, or otherwise exploit such material in any manner without the express written permission of either Pair Anything, Inc. and, in the case of content licensed by Pair Anything, Inc. from third parties, the entity that is credited as the copyright holder of such licensed content.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>
                        Trademarks
                </h4>
                    <p>
                        Pair Anything, and all page headers, custom graphics and button icons are service marks, trademarks, and/or trade dress of Pair Anything, Inc. and may not be used in connection with any product or service that is not offered by Pair Anything, Inc. All other trademarks and company names or logos cited herein are the property of their respective owners.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Partners</h4>
                    <p>
                        The App provides information from or about wineries and other service providers . The companies that Pair Anything, Inc. selects as featured partners offer products and services that Site visitors may find useful. Pair Anything, Inc. in certain cases may have a business relationship with certain third parties that it selects as featured partners. Pair Anything, Inc. is not responsible for the accuracy or reliability of any advice or information given, or of any statement made, by or regarding such featured service providers, or for the content, services, products, or advertising on or available from their websites, or for the availability and operations of their websites.
                </p>
                </div>
                <div>
                    <h4 className='all-terms-header-font'>Jurisdiction</h4>
                    <p>
                        You agree that any claim, action, or proceeding arising out of these Terms and Conditions, or your use of the App, shall be governed by and construed in accordance with the laws of the State of Delaware applicable to contracts to be wholly performed therein, and any action based on or alleging a breach of this Agreement must be brought in a state or federal court in Delaware. In addition, you agree to submit to the personal jurisdiction of such courts.
                </p>
                </div>
            </div>
        )
    }
}

export default TermsAndConditions;
