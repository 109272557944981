import React from "react";
import "./PAResults.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PALogo from "../../../assets/images/PA-small-logo.png";
import NewSearchIcon from "../../../assets/images/newSearchIcon.svg";
import Vector3 from "../../../assets/images/NewPairingResultImage/vector3.svg";
import Vector4 from "../../../assets/images/NewPairingResultImage/vector4.svg";
import emptyCardSearchImage from "../../../assets/images/EmptyPageCard/recommendation-response-image.svg";
import EmptyPageCard from "../EmptyPageCard/EmptyPageCard";
import PAResultsTastePreferenceCard from "../PAResultsTastePreferenceCard/PAResultsTastePreferenceCard";
import { isMobile } from "react-device-detect";
import DefaultFoodImage from "../../../assets/images/PAImages/default-food-SHW-PA.webp";

class PairingResultsComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      wineType: props.wineType,
      recipeUrl: props.recipeUrl,
      recommendation: props.recommendation,
      wineImageUrl: props.wineImageUrl,
      key: props.key,
      isFood: props.isFood,
    };
  }

  componentDidMount = () => {};

  redirect = (url) => {
    window.location.href = url;
  };

  render() {
    const {
      name,
      wineType,
      recipeUrl,
      recommendation,
      wineImageUrl,
      key,
      isFood,
    } = this.state;
    if (isMobile) {
      if (isFood) {
        let justVarietal = "";
        for (let i = 1; i < recommendation.split(" ").length; i++) {
          justVarietal += recommendation.split(" ")[i] + " ";
        }
        return (
          <div>
            {!this.state.showEmpty && (
              <div>
                <div key={key} className="pairing-result-title">
                  <span className="cheers">Cheers!</span>
                  <div className="pairing-result-name">
                    <span style={{ fontWeight: 700 }}>{name}</span>&nbsp;
                    <span> pairs with </span>&nbsp;
                    <span style={{ fontWeight: 700 }}>{justVarietal}</span>
                  </div>
                </div>
                <div className="pairing-result-combo-container">
                  <div className="wine-image-container-foodres">
                    <img
                      className="wine-image"
                      src={wineImageUrl}
                      alt="scott harvey wine"
                    ></img>
                  </div>
                  <div className="food-image-container-foodres">
                    <img
                      className="food-image"
                      src={DefaultFoodImage}
                      alt="bbq shrimp salad"
                    ></img>
                  </div>
                </div>

                <div className="wine-title-container">
                  <img className="pointer" src={Vector3}></img>
                  <div className="wine-text">
                    <div className="food-text">{name}</div>
                    <p className="wine-desc"></p>
                  </div>
                </div>
                <div className="food-title-container">
                  <img className="pointer-reco" src={Vector4}></img>
                  <div className="wine-text">
                    <div className="food-desc">{justVarietal}</div>
                  </div>
                </div>

                <div className="new-pairing-result-buttons">
                  {typeof this.state.recipeUrl != "undefined" ? (
                    <button
                      onClick={() => this.redirect(recipeUrl)}
                      className="shop-btn"
                      style={{ width: "100%" }}
                    >
                      VIEW RECIPE
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={() => this.redirect("/wineries")}
                    className="recipe-btn"
                    style={{ width: "100%" }}
                  >
                    SHOP WINE
                  </button>
                </div>
                <PAResultsTastePreferenceCard
                  name={name}
                  wineType={wineType}
                  varietal={justVarietal}
                />
              </div>
            )}
          </div>
        );
      } else {
        let justVarietal = "";
        for (let i = 1; i < name.split(" ").length; i++) {
          justVarietal += name.split(" ")[i] + " ";
        }
        console.log(name);
        return (
          <div>
            <div key={key} className="pairing-result-title">
              <span className="cheers">Cheers!</span>
              <div className="pairing-result-name">
                <span style={{ fontWeight: 700 }}>{justVarietal}</span>&nbsp;
                <span> pairs with </span>&nbsp;
                <span style={{ fontWeight: 700 }}>{recommendation}</span>
              </div>
            </div>
            <div className="pairing-result-combo-container">
              <div className="wine-image-container">
                <img
                  className="wine-image"
                  src={wineImageUrl}
                  alt="scott harvey wine"
                ></img>
              </div>
              <div className="food-image-container">
                <img
                  className="food-image"
                  src={DefaultFoodImage}
                  alt="bbq shrimp salad"
                ></img>
              </div>
            </div>

            <div className="wine-title-container">
              <img className="pointer" src={Vector3}></img>
              <div className="wine-text">
                <span className="food-text">{justVarietal}</span>
                <p className="wine-desc"></p>
              </div>
            </div>
            <div className="food-title-container">
              <img className="pointer-reco" src={Vector4}></img>
              <span className="food-desc">{recommendation}</span>
            </div>

            <form className="new-pairing-result-buttons">
              <button formAction="/wineries" className="shop-btn">
                SHOP WINE
              </button>
              <button formAction={recipeUrl} className="recipe-btn">
                VIEW RECIPE
              </button>
            </form>

            <PAResultsTastePreferenceCard
              name={name}
              wineType={wineType}
              varietal={justVarietal}
            />
          </div>
        );
      }
    } else {
      if (isFood) {
        let justVarietal = "";
        for (let i = 1; i < recommendation.split(" ").length; i++) {
          justVarietal += recommendation.split(" ")[i] + " ";
        }
        return (
          <div
            className={
              !isMobile ? "pairing-result-main-desktop" : "pairing-result-main"
            }
          >
            <div key={key} className="pairing-result-title">
              <span className="cheers">Cheers!</span>
              <div className="pairing-result-name">
                <span style={{ fontWeight: 700 }}>{name}</span>&nbsp;
                <span> pairs with </span>&nbsp;
                <span style={{ fontWeight: 700 }}>{justVarietal}</span>
              </div>
            </div>
            {!this.state.showEmpty && (
              <div className={!isMobile ? "combo-patp-container" : ""}>
                <div className="pairing-result-combo-container-desktop">
                  <div className="pairing-result-combo-container-images">
                    <div className="wine-image-container-foodres">
                      <img
                        className="wine-image"
                        src={wineImageUrl}
                        alt="scott harvey wine"
                      ></img>
                    </div>
                    <div className="food-image-container-foodres">
                      <img
                        className="food-image"
                        src={DefaultFoodImage}
                        alt="bbq shrimp salad"
                      ></img>
                    </div>
                  </div>

                  <div className="wine-title-container">
                    <img className="pointer" src={Vector3}></img>
                    <div className="wine-text">
                      <div className="food-text">{name}</div>
                      <p className="wine-desc"></p>
                    </div>
                  </div>
                  <div className="food-title-container">
                    <img className="pointer-reco" src={Vector4}></img>
                    <div className="wine-text">
                      <div className="food-desc">{justVarietal}</div>
                    </div>
                  </div>

                  <div className="new-pairing-result-buttons">
                    {typeof this.state.recipeUrl != "undefined" ? (
                      <button
                        onClick={() => this.redirect(recipeUrl)}
                        className="shop-btn"
                        style={{ width: "50%" }}
                      >
                        VIEW RECIPE
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={() => this.redirect("/wineries")}
                      className="recipe-btn"
                      style={{ width: "50%" }}
                    >
                      SHOP WINE
                    </button>
                  </div>
                </div>
                <PAResultsTastePreferenceCard
                  name={name}
                  wineType={wineType}
                  varietal={justVarietal}
                />
              </div>
            )}
          </div>
        );
      } else {
        let justVarietal = "";
        for (let i = 1; i < name.split(" ").length; i++) {
          justVarietal += name.split(" ")[i] + " ";
        }
        console.log(name);
        return (
          ///
          <div
            className={
              !isMobile ? "pairing-result-main-desktop" : "pairing-result-main"
            }
          >
            <div key={key} className="pairing-result-title">
              <span className="cheers">Cheers!</span>
              <div className="pairing-result-name">
                <span style={{ fontWeight: 700 }}>{justVarietal}</span>&nbsp;
                <span> pairs with </span>&nbsp;
                <span style={{ fontWeight: 700 }}>{recommendation}</span>
              </div>
            </div>
            {!this.state.showEmpty && (
              <div className={!isMobile ? "combo-patp-container" : ""}>
                <div className="pairing-result-combo-container-desktop">
                  <div className="pairing-result-combo-container-images">
                    <div className="wine-image-container-foodres">
                      <img
                        className="wine-image"
                        src={wineImageUrl}
                        alt="scott harvey wine"
                      ></img>
                    </div>
                    <div className="food-image-container-foodres">
                      <img
                        className="food-image"
                        src={DefaultFoodImage}
                        alt="bbq shrimp salad"
                      ></img>
                    </div>
                  </div>

                  <div className="wine-title-container">
                    <img className="pointer" src={Vector3}></img>
                    <div className="wine-text">
                      <div className="food-text">{recommendation}</div>
                      <p className="wine-desc"></p>
                    </div>
                  </div>
                  <div className="food-title-container">
                    <img className="pointer-reco" src={Vector4}></img>
                    <div className="wine-text">
                      <div className="food-desc">{justVarietal}</div>
                    </div>
                  </div>

                  <div className="new-pairing-result-buttons">
                    {typeof this.state.recipeUrl != "undefined" ? (
                      <button
                        onClick={() => this.redirect(recipeUrl)}
                        className="shop-btn"
                        style={{ width: "50%" }}
                      >
                        VIEW RECIPE
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={() => this.redirect("/wineries")}
                      className="recipe-btn"
                      style={{ width: "50%" }}
                    >
                      SHOP WINE
                    </button>
                  </div>
                </div>
                <PAResultsTastePreferenceCard
                  name={name}
                  wineType={wineType}
                  varietal={justVarietal}
                />
              </div>
            )}
          </div>
        );
      }
    }
  }
}

export default PairingResultsComponent;
