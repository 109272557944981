import useSWR from 'swr';
import * as constant from '../../constants/constants';
//import dayjs from 'dayjs';
import { useLocalStorage } from './useLocalStorage';
import { useEffect, useState } from 'react';
import { get } from '../fetch';
import { reportFrontendException } from '../report';

// export function useUserSession() {
//     const { data, error } = useSWR(`${constant.IDENTITY_API}/api/v1/public/user/post/mySessionId`, url => fetch(url, {
//         method: "POST",
//         body: JSON.stringify({
//             userAgent: window.navigator.userAgent,
//             sessionStartedAt: dayjs(new Date()).utc()
//         })
//     }));

//     return { data, error };
// }


// Automatically handles caching, fetching, and reporting error to the backend. Beautiful.
export function useSessionUrl(url, config = {
    query: undefined,
    wineryId: undefined
}) {
    //useLocalStorage sets a state - checks if the key is in local, otherwise returns undefined
    const [consumerData] = useLocalStorage("user_data", undefined);
    const [sessionData] = useLocalStorage("anonymous_session", undefined);
    const [token] = useLocalStorage("signin_token", undefined);
    const [actualUrl, setActualUrl] = useState(undefined);

    //console.log(consumerData, sessionData, token, actualUrl)

    //set url to session or consumer
    useEffect(() => {
        if (consumerData || sessionData) setActualUrl(`${url}?${!!consumerData ? `userId=${consumerData.id}` : `userSessionId=${sessionData.id}`}${!!config.query ? `&${config.query}` : ""}`);
    }, [consumerData, sessionData, url]);

    //once we find out session or user, we set url for caching
    //console.log(actualUrl)
    const { data, error } = useSWR(actualUrl ? actualUrl : null, u => get(u).then(res => res.json()));
    //const { data, error } = useSWR(actualUrl ? [actualUrl, token] : null, u => get(u).then(res => res.json()));

    //handles error reports
    useEffect(() => {
        //if (error && !!consumerData) reportFrontendException(error, consumerData, config.wineryId);
    }, [error, consumerData]);

    return { data, error };
}
