import { Component, useState, useEffect, useRef } from "react";
import React from "react";
import ReactDOM from "react-dom";
import logo from "../../../assets/images/search-wine-pa-logo.svg";

import * as constants from "../../constants/constants";

class MessagesDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wineryLogo: "",
      loaded: false,
      commonMessageDate: [],
    };
  }

  parseISOString(s) {
    if ((s != null || s !== undefined) && s.includes("-")) {
      var b = s.split(/\D+/);
      return new Date(
        Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])
      ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      // toLocaleTimeString is for hh:mm:ss
    }
  }

  showImage = () => {
    if (this.state.wineryLogo !== "") {
      this.setState({
        loaded: true,
      });
    }
  };

  componentDidMount = () => {
    this.getWineryIcon();
    this.refs.bottomOfPageRef.scrollIntoView({
      // block: "end",
      // behavior: "smooth",
    });
  };

  componentDidUpdate = () => {
    this.refs.bottomOfPageRef.scrollIntoView({
      // block: "end",
      // behavior: "smooth",
    });
  };

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };
    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((json) => {
      })
      .catch((error) => console.error(error));
  };

  getWineryIcon = () => {
    try {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;
      let getWineryDetailsApiUrl =
        constants.WINERY_API + "/api/v1/winery/consumer?wineryId=" + wineryId;
      fetch(getWineryDetailsApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getWineryDetailsApiUrl,
              operation: "GET",
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));

            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state
          this.setState({
            wineryLogo: json.logo,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus !== null &&
        consumerWineryStatus !== undefined &&
        consumerWineryStatus === "disabled"
      ) {
        // this.setState({
        // errorOccurred: 'getRecommendationHistory',
        // errorMessage: 'Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin'
        // })
      }
    }
  };

  renderMessage(message) {
    if (message.text !== "") {
      let text = message.text;
      let userType = message.userType;
      let dateParse = this.parseISOString(message.timestamp);
      let prevDates = [];
      if (message.timestamp) {
        let msgDate = new Date(message.timestamp).toDateString();
        prevDates = this.state.commonMessageDate;
        if (prevDates.length > 0) {
          if (!prevDates.includes(msgDate)) {
            message.commonChatDate = msgDate;
            prevDates.push(msgDate);
            this.setState({ commonMessageDate: prevDates });
          }
        } else {
          message.commonChatDate = msgDate;
          prevDates.push(msgDate);
          this.setState({ commonMessageDate: prevDates });
        }
      } else {
        message.commonChatDate = "";
      }

      if (userType === undefined) userType = "customer";
      if (userType === "customer") {
        return (
          <React.Fragment>
            {message.commonChatDate && (
              <div className="commonMessageDate"><span>{message.commonChatDate}</span></div>
            )}
            <div className="message-content-wrapper">
              <div className="message message-out">
                <div className="message-body">
                  {/* <div className="avatar avatar-sm message-avatar">
                            <img src= {logo} alt="..." 
                            className="avatar-img rounded-circle border border-white" />
                        </div> */}

                  <div className="message-content">
                    <div className="name">{/* {userType} */}</div>
                    <div className="content-desktop">{text}</div>
                  </div>
                  <div className="date">
                    {dateParse}
                    {/* {timeStamp} */}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          /* <span
                  className="avatar"
                  style={{backgroundColor: member.color}}
                />
                <div className="Message-content">
                  <div className="username">
                    {member.username}
                  </div>
                  <div className="text">{text}</div>
                </div>
              </li>
               */
        );
      } else {
        // superadminowner , wineryowneradmin
        // if(userType == 'wineryOwnerAdmin' || userType == 'wineryAdmin') {
        // winery

        return (
          <React.Fragment>
            {message.commonChatDate && (
              <div className="commonMessageDate"><span>{message.commonChatDate}</span></div>
            )}
            <div className="message-content-wrapper">
              <div className="message message-in">
                <div className="avatar avatar-sm">
                  <img
                    alt="..."
                    className={
                      this.state.wineryLogo !== ""
                        ? this.state.loaded
                          ? "avatar-img rounded-circle border border-white"
                          : "chat-winery-logo-placeholder"
                        : "chat-winery-logo-placeholder"
                    }
                    // onLoad={this.showImage}
                    // src = {
                    //          this.state.wineryLogo!=''?
                    //         (this.state.loaded? this.state.wineryLogo : logo ) : logo
                    //       }
                    src={this.state.wineryLogo ? this.state.wineryLogo : logo}
                  />
                </div>
                <div className="message-body">
                  <div className="message-content">
                    {/* <div className="name"> { userType }</div> */}
                    <div className="winery-admin-content">{text}</div>
                  </div>
                  <div className="date">
                    {dateParse}
                    {/* { timeStamp } */}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  }

  render() {
    const { messages } = this.props;
    return (
      // changed form ul to span
      <React.Fragment>
        <span className="Messages-list">
          {/* { this.renderMessage(messages) } */}
          {messages.map((m) => this.renderMessage(m))}
          <span ref="bottomOfPageRef">

          </span>

        </span>
      </React.Fragment>
    );
  }
}

export default MessagesDesktop;
