import React , {Component} from 'react';
import './EmptyPageCard.scss';
import { Redirect } from 'react-router';

import recommendationImage from '../../../assets/images/EmptyPageCard/recommendation-screen-image.svg'
import * as constants from '../../constants/constants';


class EmptyPageCard extends Component {

   constructor(props) {
       super(props);
       this.state =  {

    }
   }

   
  componentDidMount = () => {  }
   

    render() {

     
        return(
                        
            <div 
            className= { this.props.emptyCardFromChat? "card empty-page-card-container"
                : "card empty-page-card-container shadow" }
            >       
                    

                 <div className="empty-page-card-logo-container">
                    <img  alt = "Not found"
                    className = "empty-page-card-logo"
                    src = { this.props.emptyPageCardData.image? this.props.emptyPageCardData.image : recommendationImage }
                    />
                </div>

                <div className="empty-page-card-data-container">

                                                
                    <div className="empty-page-card-subcontainer">

                    
                        <span className = "empty-page-card-title">
                            { this.props.emptyPageCardData.title ? this.props.emptyPageCardData.title : '' }
                        </span>
                        <div className="empty-page-card-content-container">
                            <span className = "empty-page-card-content">
                                { this.props.emptyPageCardData.text ? this.props.emptyPageCardData.text : '' }
                            </span>
                        </div>
       
                    </div>
               
            
            </div>
     

                                  
            </div>

        );
    }
}

export default EmptyPageCard;