
import * as types from './action-types';
import * as constants from '../../constants/constants'
 
export const ShippingAddressUpdateDetails = (res) => {

    let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );
    let consumerId = consumerData.id;
    let consumerEmailId = consumerData.email;
    let consumerCreatedBy = consumerData.createdBy;
    let consumerRoleId = consumerData.roleId;   
   
    let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
    let bearer = 'Bearer ' + signInToken;
    const data = {

                id: consumerId,
                email: consumerEmailId,
                firstName: (res.firstName).toString(),
                lastName: (res.lastName).toString(),
                dateOfBirth: (res.dob).toString(),
                displayName: (res.displayName).toString(),
                phoneNumber: (res.contactNumber).toString(),
                // address1: (res.address1).toString(),
                // address2: (res.address2).toString(),
                // city: (res.city).toString(),
                // state: (res.state).toString(),
                // country: (res.country).toString(),
                // zipcode: (res.zipcode).toString(),
                createdBy: consumerCreatedBy,
                updatedBy: consumerId,
                roleId: consumerRoleId

    }

    return dispatch => { 

            fetch(constants.IDENTITY_API + '/api/v1/user/' + consumerId , {   

            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            },
            body: JSON.stringify(data)
        }).then(response => {
            let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );

            // let persons = JSON.parse(localStorage.persons);
//             for (let i = 0; i < consumerData.length; i++) {
//             if(consumerData[i].name === 'firstName'){  //look for match with name
//                 consumerData[i].age += 2;  //add two
//                 break;  //exit loop since you found the person
//             }
//             }
// localStorage.setItem("user_data", JSON.stringify(consumerData)); 

            return response.json();
            
        }).then(respJSON => {
            dispatch({ type: types.PUT_SHIPPING_ADDRESS_SUCCEEDED, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.PUT_SHIPPING_ADDRESS_FAILED, payload: error })
        })
    }
}
