
import * as types from './action-types';
import * as constants from '../../constants/constants'
 

// let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
// let bearer = 'Bearer ' + signInToken;

export const ResetPassword = (dataValue) => {
    const data = {
        id: dataValue.id,
        password: dataValue.password
    }
    return dispatch => {

         fetch(constants.IDENTITY_API + '/api/v1/public/user/' + data.id + '/changePassword' , { // new updated URL
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                // "Authorization" : bearer
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.RESET_PASSWORD_SUCCEEDED, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.RESET_PASSWORD_FAILED, payload: error })
        })
    }
}
