import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.EDIT_TASTE_PREFERENCE_SUCCESS:
            return {
                ...state,
                editTastePreferenceSuccess: action.payload
            };

        case types.EDIT_TASTE_PREFERENCE_FAILED:
            return {
                ...state,
                editTastePreferenceFail: action.payload
            };

        default:
            return state;
    }
};