import * as types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case types.SET_PASSWORD_SUCCEEDED:
            return {
                ...state,
                setPasswordSuccess: action.payload
            };

        case types.SET_PASSWORD_FAILED:
            return {
                ...state,
                setPasswordFail: action.payload
            };

        default:
            return state;
    }
};
