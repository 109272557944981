import React, { useEffect, useState } from "react";
import styles from "./spotlight-card.module.scss";
import wineBottlePlaceholder from "../../../assets/images/wine_bottle_placeholder.png";
import { useHistory } from "react-router-dom";
import * as constants from "../../constants/constants";
import { get, post } from "../../lib/fetch";
import { sessionUrl } from "../../lib/common/sessionUrl";

export default function LandingPageCard({ wine, num, ghost = false }) {
  const history = useHistory();
  const [wineTypeColor, setWineTypeColor] = useState("#EFEFEF");
  const wineId = wine?.wine?.id;

  useEffect(() => {
    const wineType = wine?.wine?.wineType.trimEnd().toLowerCase();
    if (wineType == "red") {
      setWineTypeColor("#f6efef");
    } else if (wineType == "white") {
      setWineTypeColor("#F4ECE3");
    }
  }, [wine]);

  const createWineReco = async (wineId) => {
    const recoBody = {
      wineryId: localStorage.getItem("winery_id") ?? 0
    }
    let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/wine/byId/${wineId}`;
    try {
      const response = await post(sessionUrl(url), {
        Accept: "application/json",
        "Content-Type": "application/json",
      }, recoBody);
      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCardClick = async () => {
    // take us to the pairing result for wine page

    try {
      const res = await createWineReco(wineId);
      console.log(res);
      history.push({
        pathname: `/pairing-results-wine/${res.recoId}`,
      })
    } catch (e) {
      console.log(e);
    }
  };

  if (ghost) return <div className={`${styles.ghostCard}`}></div>;

  return (
    <button
      className={`${styles.card}`}
      aria-label={`Wine Card ${num}`}
      onClick={() => handleCardClick()}
    >
      <div
        className={`${styles.wineImgBg}`}
        style={{ backgroundColor: wineTypeColor }}
      >
        <img
          className={`${styles.wineImg}`}
          src={wine?.wine?.wineImageSmall}
          alt={wine?.wine?.wineName}
        />
      </div>
      <p className={`p1-reg`}>{wine?.wine?.wineName}</p>
    </button>
  );
}
