import React, { Component } from "react";
// import axiosCall from "../../constants";
import "./TastePreferenceQuizCard.scss";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import * as constants from "../../constants/constants";
import questionList from "./questionsList";

// import { log } from "handlebars/runtime";

//value reference
// let dsScaleSum;
// let bodyScoreSum;
// let tanninsScoreSum;
// let aciditySum;
// let avbSum;
// let flavorProfiles;

// Variables & data for questions and selection
let counter = 0;
let selections = {
  1: "ONE",
  2: "TWO",
  3: "THREE",
  7: "ALL THAT APPLY",
};
let newQuestionsList;

class TastePreferenceQuizCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0, // id to keep track of current question
      randKey: "",
      answer: [],
      changeDisplay: true,
      selected: [],
      fadeOut: false,
      continueFade: false,
      questionsAnswered: [],
      questionAnswered: JSON.parse(localStorage.getItem("questionsAnswered")),
      questionID: [],
      ip: "",
      visits: 0,
      questionsAnsweredLocalData: [],
      questionList: [],
      CurrVisitCount: localStorage.getItem("visits"),
      count: 1,
      openEmailInput: false,
    };
    this.cardClicked = this.cardClicked.bind(this);
    let questionsAnsweredLocalLength = 0;
    let questionsAnsweredLocalData;
    let questionsAnsweredLocal = localStorage.getItem("questionsAnswered");

    console.log(`this.props.history.location.state:`);
    console.log(this.props.history.location.state);

    if (questionsAnsweredLocal) {
      questionsAnsweredLocalData = JSON.parse(questionsAnsweredLocal, true);
      questionsAnsweredLocalLength = questionsAnsweredLocalData.length;
    }
    if (this.state.CurrVisitCount >= 6 && questionsAnsweredLocalLength >= 4) {
      this.cardClicked(questionsAnsweredLocalData);
    }
    let currCount = +localStorage.getItem("count");
    if (currCount < 5) {
      let questionList_1 = questionList.filter(function (value) {
        return value.level.includes(1);
      });
      newQuestionsList = questionList_1
        .sort(() => Math.random() - 0.5)
        .slice(0, 5);
      localStorage.setItem("questionList", JSON.stringify(newQuestionsList));
    }
    if (currCount >= 5 && currCount < 10) {
      let questionList_2 = questionList.filter(function (value) {
        return value.level.includes(2);
      });
      newQuestionsList = questionList_2
        .sort(() => Math.random() - 0.5)
        .slice(0, 5);
      localStorage.setItem("questionList", JSON.stringify(newQuestionsList));
    }
    if (currCount >= 10) {
      let questionList_3 = questionList.filter(function (value) {
        return value.level.includes(3);
      });
      newQuestionsList = questionList_3
        .sort(() => Math.random() - 0.5)
        .slice(0, 5);
      localStorage.setItem("questionList", JSON.stringify(newQuestionsList));
    }
    this.state.questionList.push(...newQuestionsList);
  }
  //Life Cycle
  componentDidMount = async () => {
    let fixedQuestion = JSON.parse(localStorage.getItem("questionsAnswered"));
    const questionList = JSON.parse(localStorage.getItem("questionList"));
    const qid = questionList[0].questionID;
    if (fixedQuestion !== null && fixedQuestion.length >= 4) {
      localStorage.removeItem("questionsAnswered");
    }
    if (fixedQuestion !== null && fixedQuestion.length <= 2) {
      this.state.id = fixedQuestion.length;

      if (localStorage.getItem("id") === null) {
        localStorage.setItem("id", this.state.id);
      } else {
        let cc = +localStorage.getItem("id");
        let Increments = cc + 1;
        localStorage.setItem("id", Increments);
      }
    }

  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.cardClicked) {
      return true;
    } else {
      return false;
    }
  }
  cardClicked(quesAnsData = []) {
    if (localStorage.getItem("count") === null) {
      localStorage.setItem("count", this.state.count);
    } else {
      let counter = localStorage.getItem("count");
      counter++;
      localStorage.setItem("count", counter);
      this.setState((prevState) => ({
        count: prevState.count + 1,
      }));
    }
    if (this.state.id + 1 >= newQuestionsList.length) {
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let email = JSON.parse(localStorage.getItem("email"));
      if (signInToken || email) {
        this.props.history.push("/TestResults");
      } else {
        this.props.history.push({
          pathname: "/sign-in",
          state: "anonymous",
        });
      }
    }
    setTimeout(() => {
      this.setState((prevState) => ({
        id: this.state.id + 1, // Increments to the next question
        changeDisplay: true, // Hides continue button
        selected: [], // Empties list of selected values when moving to next question
        fadeOut: false, // Reset opacity of cards back to 1
        randKey: Math.random(), // Generates a random number to return a key, resulting in transition animation
        questionsAnswered: [...prevState.questionsAnswered, prevState.id],
      }));
    }, 500);
    if (localStorage.getItem("count") === 5) {
      var visits;

      if (!localStorage.getItem("visits")) {
        if (this.state.questionAnswered) localStorage.setItem("visits", 2);
      }
      visits = +localStorage.getItem("visits"); //+ operator change string into number
      var incrementedCount = visits + 1; // incrementing visit of the user when it refreshing the website

      localStorage.setItem("visits", incrementedCount);
      let CurrVisitCount = localStorage.getItem("visits");
      this.setState(() => ({ CurrVisitCount }));
    }
  }
  openHomePage = () => {
    localStorage.removeItem("count");
    localStorage.removeItem("visits");
    localStorage.removeItem("questionsAnswered");

    if (this.props.history.location.state?.from) {
      this.props.history.push(this.props.history.location.state?.from);
    } else {
      this.props.history.push("/discover");
    }
  };

  handleAction(value, numSelect, index, cardData = []) {
    if (numSelect > 1) {
      // Makes the continue button appear after first selection and fades out the rest of the cards
      if (this.state.selected.length >= 0) {
        this.setState({
          changeDisplay: false,
          fadeOut: true,
          temp: numSelect,
        });
      }
      if (!this.state.selected.includes(index) && counter < numSelect) {
        counter++;
        // Insert index of selected card into selected array
        this.setState((prevState) => ({
          selected: [...prevState.selected, index],
          answer: [...prevState.answer, value],
        }));

        this.saveLocalStoreData(
          cardData,
          localStorage.getItem("questionsAnsweredMul"),
          "questionsAnsweredMul"
        );
        if ((counter >= 1 && numSelect === 7) || counter === numSelect) {
          this.setState({
            continueFade: true,
          });
        }
      }
      // Dealing with unselecting cards
      else if (this.state.selected.includes(index)) {
        const newSelected = this.state.selected.filter(
          (item) => item !== index
        );
        const newAnswer = this.state.answer.filter((item) => item !== value);
        this.setState({
          selected: newSelected,
          answer: newAnswer,
        });
        counter--;
        if (
          (counter < 1 && numSelect === 7) ||
          (counter < numSelect && numSelect === 3)
        ) {
          // Fade out the continue button if the required # of selections is not reached.
          // If numSelect == 4 (select all that applies) then ignore
          this.setState({
            continueFade: false,
          });
        }
        // Hides the continue button and fade in the cards if everything is deselected
        if (counter === 0) {
          this.setState({
            changeDisplay: true,
            fadeOut: false,
          });
        }
      }
    } else if (
      value === "NONE" ||
      value === "I DON'T KNOW" ||
      value === "SOMETHING ELSE"
    ) {
      // We change this value to something else in the future
      this.state.answer.push({
        value: value,
        ds: 0,
        tannins: 0,
        body: 0,
        acidity: 0,
        avb: 0,
        flavourProfile: "",
      });
      this.cardClicked();
    }
    // Fixed number of selection. At least more than one card.
    else if (this.state.temp === counter) {
      if (value === "continue") {
        counter = 0;
        this.state.answer.push({
          value: value,
          ds: cardData[3],
          tannins: cardData[4],
          body: cardData[5],
          acidity: cardData[6],
          avb: cardData[7],
          flavourProfile: cardData[8],
        });
        if (localStorage.getItem("questionsAnsweredMul")) {
          this.saveLocalStoreMultipleTotalData(
            localStorage.getItem("questionsAnsweredMul"),
            localStorage.getItem("questionsAnswered"),
            "questionsAnswered"
          );
        }
        this.cardClicked();
      }
    }
    // Select all that applies
    else if (value === "continue") {
      counter = 0;
      this.state.answer.push({
        value: value,
        ds: cardData[3],
        tannins: cardData[4],
        body: cardData[5],
        acidity: cardData[6],
        avb: cardData[7],
        flavourProfile: cardData[8],
      });
      if (localStorage.getItem("questionsAnsweredMul")) {
        this.saveLocalStoreMultipleTotalData(
          localStorage.getItem("questionsAnsweredMul"),
          localStorage.getItem("questionsAnswered"),
          "questionsAnswered"
        );
      }
      this.cardClicked();
    }
    // Select one card
    else if (numSelect === 1) {
      this.setState((prevState) => ({
        selected: [...prevState.selected, index],
        fadeOut: true,
      }));
      this.state.answer.push({
        value: value,
        ds: cardData[3],
        tannins: cardData[4],
        body: cardData[5],
        acidity: cardData[6],
        avb: cardData[7],
        flavourProfile: cardData[8],
      });
      this.saveLocalStoreData(
        cardData,
        localStorage.getItem("questionsAnswered"),
        "questionsAnswered"
      );
      this.cardClicked();
    }
  }

  //close of handle action

  saveLocalStoreData(cardData, questionsAnsweredLocal, localKey) {
    let ds = 0;
    let tannins = 0;
    let body = 0;
    let acidity = 0;
    let avb = 0;
    let flavourProfile = "";
    if (cardData.length > 0) {
      ds = cardData[3];
      tannins = cardData[4];
      body = cardData[5];
      acidity = cardData[6];
      avb = cardData[7];
      flavourProfile = cardData[8];
    }
    if (questionsAnsweredLocal === null) {
      localStorage.setItem(
        localKey,
        JSON.stringify([
          {
            questionID: newQuestionsList[this.state.id]["questionID"],
            ds: ds,
            tannins: tannins,
            body: body,
            acidity: acidity,
            avb: avb,
            flavourProfile: flavourProfile,
          },
        ])
      );
    } else {
      let questionAnsweredArr = [];
      questionAnsweredArr = JSON.parse(questionsAnsweredLocal) || [];
      questionAnsweredArr.push({
        questionID: newQuestionsList[this.state.id]["questionID"],
        ds: ds,
        tannins: tannins,
        body: body,
        acidity: acidity,
        avb: avb,
        flavourProfile: flavourProfile,
      });
      localStorage.setItem(localKey, JSON.stringify(questionAnsweredArr));
    }
  }

  saveLocalStoreMultipleTotalData(
    questionsAnsweredMulLocal,
    questionsAnsweredLocal,
    localKey
  ) {
    const questionAnsweredMulArr = JSON.parse(questionsAnsweredMulLocal) || [];
    const ds = questionAnsweredMulArr.reduce((total, obj) => obj.ds + total, 0);
    const tannins = questionAnsweredMulArr.reduce(
      (total, obj) => obj.tannins + total,
      0
    );
    const body = questionAnsweredMulArr.reduce(
      (total, obj) => obj.body + total,
      0
    );
    const acidity = questionAnsweredMulArr.reduce(
      (total, obj) => obj.acidity + total,
      0
    );
    const avb = questionAnsweredMulArr.reduce(
      (total, obj) => obj.avb + total,
      0
    );
    const flavourProfile = "";
    if (questionsAnsweredLocal === null) {
      localStorage.setItem(
        localKey,
        JSON.stringify([
          {
            questionID: newQuestionsList[this.state.id]["questionID"],
            ds: ds,
            tannins: tannins,
            body: body,
            acidity: acidity,
            avb: avb,
            flavourProfile: flavourProfile,
          },
        ])
      );
    } else {
      let questionAnsweredArr = [];
      questionAnsweredArr = JSON.parse(questionsAnsweredLocal) || [];
      questionAnsweredArr.push({
        questionID: newQuestionsList[this.state.id]["questionID"],
        ds: ds,
        tannins: tannins,
        body: body,
        acidity: acidity,
        avb: avb,
        flavourProfile: flavourProfile,
      });
      localStorage.setItem(localKey, JSON.stringify(questionAnsweredArr));
    }
    localStorage.removeItem("questionsAnsweredMul");
  }
  handlePopupClose = () => {
    this.setState({ openEmailInput: false });
  };

  render() {
    return (
      <div className="tastePreferenceCard">
        <Card className="mainCard">
          <button
            className="close-btn"
            onClick={() => {
              this.openHomePage();
            }}
          >
            X
          </button>

          <div className="titleContainer">
            <div className="questionNumber">
              Q. {this.state.id + 1} OF {newQuestionsList.length}
            </div>

            <div className="title">TASTE QUIZ</div>
          </div>
          <ProgressBar
            className="bar"
            now={((this.state.id + 1) / newQuestionsList.length) * 100}
          />

          <div key={this.state.randKey} className="transition">
            {/* Level: {this.state.level} */}
            <h1 className="AppTitle">
              {newQuestionsList[this.state.id]["questionTitle"]}
            </h1>
            <h2 className="selectTitle">
              SELECT {selections[newQuestionsList[this.state.id]["selection"]]}{" "}
            </h2>
            <div className="mainContainer">
              <div className="main">
                <img
                  className="image1"
                  // alt="img"
                  src={newQuestionsList[this.state.id].image}
                />

                {newQuestionsList[this.state.id].choices.map((card, index) => (
                  <div
                    className={`card-style ${
                      this.state.fadeOut === true ? "fade_Out" : ""
                    }`}
                    onClick={() =>
                      this.handleAction(
                        card[0],
                        newQuestionsList[this.state.id]["selection"],
                        index,
                        card
                      )
                    }
                    style={{
                      animation: this.state.selected.includes(index)
                        ? "fadeIn 0.2s forwards"
                        : "",
                    }}
                  >
                    <img class="card-image" src={card[1]} />
                    <div className="card-body">
                      <div className="card-text">{card[0]}</div>
                    </div>
                  </div>
                ))}
              </div>
              <h2
                className="additionalOption"
                onClick={() =>
                  this.handleAction(
                    newQuestionsList[this.state.id]["additional"]
                  )
                }
                style={{ display: this.state.changeDisplay ? "block" : "none" }}
              >
                {newQuestionsList[this.state.id]["additional"]}
              </h2>
              <div
                className="continueBtn"
                onClick={() => this.handleAction("continue")}
                style={{
                  display: this.state.changeDisplay ? "none" : "flex",
                  animation: this.state.continueFade
                    ? "fadeIn 0.2s forwards"
                    : "fadeOut 0.2s forwards",
                }}
              >
                CONTINUE
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
export default TastePreferenceQuizCard;
