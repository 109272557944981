import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import styles from "./app-feedback.module.scss";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import * as constants from "../../constants/constants";
import { sessionUrl } from "../../lib/common/sessionUrl";
import { post } from "../../lib/fetch";

export default function AppFeedback() {
  // ========== Set up formik ==============================

  const [formSubmittable, setFormSubmittable] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.rating) {
      errors.rating = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      rating: null,
      features: [],
      improvements: null,
      recommend: null,
      ambassador: [],
    },
    validate,
    onSubmit: (values) => submitForm(values),
  });

  useEffect(() => {
    console.log(`formik.values:`);
    console.log(formik.values);
    if (JSON.stringify(formik?.errors) === "{}" && !!formik.values.email) {
      setFormSubmittable(true);
    } else {
      setFormSubmittable(false);
    }
  }, [formik]);

  const submitForm = async (values) => {
    if (formSubmittable) {
      let feedbackRequestBody = {
        email: values.email,
        rating: values.rating,
        features: values.features,
        improvements: values.improvements,
        recommend: values.recommend,
        ambassador: values.ambassador,
      };

      let url = `${constants.IDENTITY_API}/api/v1/public/support/appFeedback`;

      try {
        const response = await post(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          feedbackRequestBody
        );

        setFormSubmitted(true);

        const result = await response.json();
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className={styles.main}>
      {!formSubmitted ? (
        <>
          <div className={styles.heading}>
            <h1 className={`h1-30`}>Tell Us What You Think</h1>
            <p className={`p1-reg`} style={{ paddingTop: "2rem" }}>
              Thanks for using PairAnything! We want to make sure that we're
              doing our best to delight you in all things related to wine
              discovery and enjoyment. If you have 2 minutes, we'd really
              appreciate if you could let us know your thoughts!
              <br />
              <br />
              Grateful,
              <br />
              The PairAnything Team
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className={`${styles.formContainer}`}
          >
            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="email">
                Email<span className={styles.asterisk}>*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={`${styles.formInput} p1-reg`}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={styles.formFieldError}>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="rating">
                Overall, how would you rate your user experience with
                PairAnything app?<span className={styles.asterisk}>*</span>
              </label>
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="rating"
                name="rating"
                style={{ padding: "0 1rem" }}
              >
                <option disabled selected value>
                  -- Select an option --
                </option>
                <option value={1}>1 - Very dissatisfied</option>
                <option value={2}>2 - Somewhat dissatisfied</option>
                <option value={3}>3 - Neutral</option>
                <option value={4}>4 - Somewhat satisfied</option>
                <option value={5}>5 - Very satisfied</option>
              </select>
              {formik.touched.rating && formik.errors.rating ? (
                <div className={styles.formFieldError}>
                  {formik.errors.rating}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="features">
                Which PairAnything features are valuable or important to you?
              </label>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt1"
                  type="checkbox"
                  name="features"
                  value="get wine recommendation for dish"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt1">
                  Get wine recommendations for dish
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt2"
                  type="checkbox"
                  name="features"
                  value="get recipe suggestion for a wine varietal"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt2">
                  Get recipe suggestion for a wine varietal
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt3"
                  type="checkbox"
                  name="features"
                  value="Shop for wines conveniently"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt3">
                  Shop for wines conveniently
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt4"
                  type="checkbox"
                  name="features"
                  value="discover wine regions"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt4">
                  Discover wine regions
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt5"
                  type="checkbox"
                  name="features"
                  value="access wine and event promotions"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt5">
                  Access wine and event promotions
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt6"
                  type="checkbox"
                  name="features"
                  value="chat with winery"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt6">
                  Chat with winery
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt7"
                  type="checkbox"
                  name="features"
                  value="save favorite wines"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt7">
                  Save favorite wines
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt8"
                  type="checkbox"
                  name="features"
                  value="rate pairing recommendations"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt8">
                  Rate pairing recommendations
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt9"
                  type="checkbox"
                  name="features"
                  value="rsvp for a winery event"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt9">
                  RSVP for a winery event
                </label>
              </div>

              <div className={styles.checkBoxOption}>
                <input
                  id="opt10"
                  type="checkbox"
                  name="features"
                  value="learn about wine, wellness and food pairings"
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="opt10">
                  Learn about wine, wellness and food pairings
                </label>
              </div>
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="requestText">
                What would you like to add or improve on the PairAnything app?
              </label>
              <textarea
                placeholder=""
                id="improvements"
                name="improvements"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.improvements}
                className={`${styles.formInputTextArea} p1-reg`}
              />
              {formik.touched.improvements && formik.errors.improvements ? (
                <div className={styles.formFieldError}>
                  {formik.errors.improvements}
                </div>
              ) : null}
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="reco">
                Would you recommend PairAnything to a friend?
              </label>
              <div className={`${styles.radioBtn}`}>
                <input
                  type="radio"
                  id="recoYes"
                  name="recommend"
                  value={1}
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="recoYes">
                  Yes
                </label>
              </div>

              <div className={`${styles.radioBtn}`}>
                <input
                  type="radio"
                  id="recoNo"
                  name="recommend"
                  value={0}
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="recoNo">
                  Not at this time
                </label>
              </div>
            </div>

            <div className={`${styles.formField}`}>
              <label className={`p1-bold`} htmlFor="ambassador">
                How much do you love PairAnything... want to be our Brand
                Ambassador?
              </label>

              <div className={styles.checkBoxOption}>
                <input
                  id="amb_opt1"
                  type="checkbox"
                  name="ambassador"
                  value={1}
                  onChange={formik.handleChange}
                />
                <label className={`p1-reg`} htmlFor="amb_opt1">
                  Yes
                </label>
              </div>
            </div>

            <button
              className={`${styles.submitBtn} ${
                !formSubmittable ? styles.grayedOut : null
              }`}
              type="submit"
            >
              Submit
            </button>

            {/* <button type="submit">Submit</button> */}
          </form>
        </>
      ) : (
        <div className={styles.heading}>
          <h1 className={`h1-30`}>Thank you for your Feedback!</h1>
          <p className={`p1-reg`} style={{ paddingTop: "2rem" }}>
            Your opinion helps us deliver the best experience in our app to make exploring wine pairings exciting!
          </p>
        </div>
      )}
    </div>
  );
}
