// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from "../constants/constants";

export const initializeGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

// denotes pages visited by user
export const logPagesVisitedByUser = () => {
  // ReactGA.set({ page: window.location.pathname });
  // ReactGA.pageview(window.location.pathname);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
};

// if user clicks on any button

export const clickedAskRecommendation = () => {
  ReactGA.event({
    category: "AskRecommendation",
    action: "click",
    label: "ask_recommendation",
  });
};

export const clickedAskPARecommendation = () => {
  ReactGA.event({
    category: "AskRecommendation",
    action: "click",
    label: "ask_pa_recommendation",
  });
};

export const clickedRecommendationRating = () => {
  ReactGA.event({
    category: "RateRecommendation",
    action: "click",
    label: "rate_a_recommendation",
  });
};

export const clickedChat = () => {
  ReactGA.event({
    category: "Chat",
    action: "click",
    label: "contact_winery",
  });
};

export const clickedAppFeedback = () => {
  ReactGA.event({
    category: "AppFeedback",
    action: "click",
    label: "app_feedback",
  });
};

export const clickedAvailableWine = () => {
  ReactGA.event({
    category: "AvailableWines",
    action: "click",
    label: "available_wine",
  });
};

export const clickedSaveDobVerification = () => {
  ReactGA.event({
    category: "DOBVerificationSave",
    action: "click",
    label: "dob_verification",
  });
};

export const clickedForgotPassword = () => {
  ReactGA.event({
    category: "ForgotPassword",
    action: "click",
    label: "forgot_password",
  });
};
export const clickedPersonalInfo = () => {
  ReactGA.event({
    category: "PersonalInfo",
    action: "click",
    label: "personal_info",
  });
};
export const clickedHelpPage = () => {
  ReactGA.event({
    category: "HelpPage",
    action: "click",
    label: "help_page",
  });
};
export const clickedUpdatePersonalInfo = () => {
  ReactGA.event({
    category: "UpdatePersonalInfo",
    action: "click",
    label: "update_personal_info",
  });
};
export const clickedUpdatePrimaryAddress = () => {
  ReactGA.event({
    category: "UpdatePrimaryAddress",
    action: "click",
    label: "update_primary_address",
  });
};
export const addNewAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "add_new_address",
  });
};
export const updateAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "update_address",
  });
};
export const cartQtyChange = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "quantity_change",
  });
};
export const onClickDeleteItemsFromCart = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "click_delete_item",
  });
};
export const onDeleteItemsFromCart = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "confirm_delete_item",
  });
};
export const onCancelDeleteItemsFromCart = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "cancel_delete_item",
  });
};
export const onClickSelectAddress = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "select_shipping_address",
  });
};
export const onClickAddorModifyAddress = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "add_or_modify_shipping_address",
  });
};
export const onClickAddorModifyBillingAddress = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "add_or_modify_billing_address",
  });
};
export const onClickProceedToPay = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "proceed_to_pay",
  });
};
export const onClickGoToShoppingCart = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "go_to_shopping_cart",
  });
};
export const onSelectDefaultShippingAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "primary_address_select",
  });
};
export const onSelectDefaultBillingAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "billing_address_select",
  });
};
export const onSavePrimaryAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "save_primary_address",
  });
};
export const onSaveBillingAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "save_billing_address",
  });
};
export const onEditShippingAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "edit_address",
  });
};
export const onDeleteShippingAddress = () => {
  ReactGA.event({
    category: "Address",
    action: "click",
    label: "delete_address",
  });
};
export const clickedSetPasswordSubmit = () => {
  ReactGA.event({
    category: "SetPasswordSubmit",
    action: "click",
    label: "set_password_submit",
  });
};

export const clickedSignIn = () => {
  ReactGA.event({
    category: "SignInSubmit",
    action: "click",
    label: "signin_submit",
  });
};

export const clickedFacebookSignIn = () => {
  ReactGA.event({
    category: "FacebookSignInSubmit",
    action: "click",
    label: "facebook_signin_submit",
  });
};

export const clickedGoogleSignIn = () => {
  ReactGA.event({
    category: "GoogleSignInSubmit",
    action: "click",
    label: "google_signin_submit",
  });
};

export const clickedSignUp = () => {
  ReactGA.event({
    category: "SignUpSubmit",
    action: "click",
    label: "signup_submit",
  });
};

export const clickedFacebookSignUp = () => {
  ReactGA.event({
    category: "FacebookSignUpSubmit",
    action: "click",
    label: "facebook_signup_submit",
  });
};

export const clickedGoogleSignUp = () => {
  ReactGA.event({
    category: "GoogleSignUpSubmit",
    action: "click",
    label: "google_signup_submit",
  });
};

export const clickedSignUpFromInviteWinery = () => {
  ReactGA.event({
    category: "SignUpSubmit",
    action: "click",
    label: "signup_submit_from_invite_winery",
  });
};

export const clickedRecommendationForDish = () => {
  ReactGA.event({
    category: "OpenRecommendationsForDish",
    action: "click",
    label: "pairing_recommendation",
  });
};

export const clickedBuyNowForWine = () => {
  ReactGA.event({
    category: "BuyNow-IndividualWineDetails",
    action: "click",
    label: "individual_wine_details_buynow",
  });
};

export const addToCartForWine = () => {
  ReactGA.event({
    category: "AddToCart",
    action: "click",
    label: "wine_details",
  });
};

export const clickedWineryDetails = () => {
  ReactGA.event({
    category: "WineryDetails",
    action: "click",
    label: "winery_details",
  });
};

export const clickedEnabledWinery = (name) => {
  ReactGA.event({
    category: "EnabledSwitchWinery",
    action: "click",
    label: name,
  });
};

export const clickedDisabledWinery = () => {
  ReactGA.event({
    category: "DisabledSwitchWinery",
    action: "click",
    label: "switch_winery_disabled",
  });
};

export const clickedTastePreferenceGetStarted = () => {
  ReactGA.event({
    category: "TastePreferenceStarted",
    action: "click",
    label: "taste_preference_get_started",
  });
};

export const clickedTastePreferenceFinished = () => {
  ReactGA.event({
    category: "TastePreferenceFinished",
    action: "click",
    label: "taste_preference_finished",
  });
};

export const clickedTastePreferenceOptionCard = () => {
  ReactGA.event({
    category: "TastePreferenceOptionSelected",
    action: "click",
    label: "taste_preference_option_selected",
  });
};

export const clickedNotification = () => {
  ReactGA.event({
    category: "OpenedNotification",
    action: "click",
    label: "opened_notification",
  });
};

export const clickedEditRsvp = () => {
  ReactGA.event({
    category: "OpenedEditRsvpPopup",
    action: "click",
    label: "opened_edit_rsvp_popup",
  });
};

export const addedToRsvp = () => {
  ReactGA.event({
    category: "AddedtoRsvp",
    action: "click",
    label: "added_to_rsvp",
  });
};

export const clickedRsvp = () => {
  ReactGA.event({
    category: "OpenedRsvpForm",
    action: "click",
    label: "opened_rsvp_form",
  });
};

export const clickedCancelInRsvpFormPopup = () => {
  ReactGA.event({
    category: "ClosedRsvpForm",
    action: "click",
    label: "closed_rsvp_form",
  });
};

export const clickedSubmitInRsvpFormPopup = () => {
  ReactGA.event({
    category: "SavedRsvpForm",
    action: "click",
    label: "saved_rsvp_form",
  });
};

export const clickedCantMakeItRsvpFormPopup = () => {
  ReactGA.event({
    category: "CantMakeItFormOpened",
    action: "click",
    label: "cant_make_it_clicked",
  });
};

export const clickedFoodom = () => {
  ReactGA.event({
    category: "SwitchWinery",
    action: "click",
    label: "foodom_click",
  });
};
export const clickedVinified = () => {
  ReactGA.event({
    category: "SwitchWinery",
    action: "click",
    label: "vinified_click",
  });
};
export const proceedToBuy = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "proceed_to_buy",
  });
};
export const continueShopping = () => {
  ReactGA.event({
    category: "ShoppingCart",
    action: "click",
    label: "continue_shopping",
  });
};
export const clickedDiscoverPage = () => {
  ReactGA.event({
    category: "DiscoverPage",
    action: "click",
    label: "discover_page",
  });
};
export const clickedEnjoy = () => {
  ReactGA.event({
    category: "DiscoverPage",
    action: "click",
    label: "enjoy",
  });
};
export const clickedKnow = () => {
  ReactGA.event({
    category: "DiscoverPage",
    action: "click",
    label: "know",
  });
};
export const clickedShop = () => {
  ReactGA.event({
    category: "DiscoverPage",
    action: "click",
    label: "shop",
  });
};
export const clickedVisit = () => {
  ReactGA.event({
    category: "DiscoverPage",
    action: "click",
    label: "visit",
  });
};
export const clickedVirtualTasting = () => {
  ReactGA.event({
    category: "DiscoverPage",
    action: "click",
    label: "virtualTasting",
  });
};
export const clickedMyOrders = () => {
  ReactGA.event({
    category: "Orders",
    action: "click",
    label: "my_orders",
  });
};
export const clickedOrderDetails = () => {
  ReactGA.event({
    category: "Orders",
    action: "click",
    label: "order_details",
  });
};
export const clickedSelectTastePreference = () => {
  ReactGA.event({
    category: "TastePreference",
    action: "click",
    label: "select_taste_preference",
  });
};
export const clickedDoItLater = () => {
  ReactGA.event({
    category: "TastePreference",
    action: "click",
    label: "do_it_later",
  });
};
