import React from 'react';
import './PoweredByHeader.scss';
import logo from "../../../assets/images/PA-small-logo.png";

export default function PoweredByHeader() {
    return (
        <div className="powered-by-container">
            <p className="powered-by-pre">POWERED BY</p>
            <img className="powered-by-logo" src={logo}/>
            <p className="powered-by-pair">PAIR</p>
            <p className="powered-by-anything">ANYTHING</p>
        </div>
    )
}