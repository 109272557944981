
import * as types from './action-types';
import * as constants from '../../constants/constants'
 
let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
let bearer = 'Bearer ' + signInToken;

export const ForgotPassword = (emailId) => {
    // const data = {
    //     id: dataValue.id,
    //     password: dataValue.password
    // }
    return dispatch => {

        // https://pairanything-identity-api-dev.redblacktree.net/api/v1/super_admin/forgotPassword/prashanthguru.17@gmail.com

         fetch(constants.IDENTITY_API + '/api/v1/public/user/forgotPassword/' + emailId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : bearer
            },
            // body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.FORGOT_PASSWORD_SUCCEEDED, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.FORGOT_PASSWORD_FAILED, payload: error })
        })
    }
}

// Calling Dummy method before calling post forget api
export const ForgetApiDummyCall = () => {
    return dispatch => {
        dispatch({ type: types.FORGOT_PASSWORD_DUMMY, payload: undefined })
    }
}
