import React, { Component } from "react";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import { isMobile } from "react-device-detect";
import "./NewSignUp.scss";
import logo from "../../../assets/images/splash.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import backBtnBlack from "../../../assets/images/back-btn.svg";
import moment from "moment";
import SingUpPasswordSuccess from "../SingUpPasswordSuccess/SingUpPasswordSuccess";
import * as signoutAction from "../../redux/actions/Signout";
import * as constants from "../../constants/constants";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import signUpSuccesWineImage from "../../../assets/images/SignUp/wines-signup-image.svg";
import FaceBookButton from "../../components/FacebookButton";
import GoogleButton from "../../components/GoogleButton";
import { withRouter } from "react-router-dom";
import { fetchCampaignDetails } from "../../constants/commonFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
toast.configure();
class NewSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: "",
      dobMonth: "",
      dobDay: "",
      dobYear: "",
      dobFormError: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      agree: false,
      formError: "",
      confirmPassword: "",
      firstNameFormError: "",
      lastNameFormError: "",
      agreeFormError: "",
      passwordFormError: "",
      confirmPasswordCommonError: "",
      inValidMessageFromResponse: "",
      emailFormError: "",
      signUpNewUserCompleted: false,
      signUpApiResponseDetails: "",
      showTermsAndConditions: false,
      showTerms: false,
      showPrivacy: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    console.log("location.state", this.props.location);
  }

  goToSignInPage = () => {
    if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/sign-in", this.props.location.state);
    }
  };

  onNavigateToSignIn = () => {
    console.log(this.props.match.params)
    if(this.props.match.params && this.props.match.params.wineryName){
      let newUrl = this.props.match.params.wineryName
      if(this.props.match.params.page)
        newUrl = newUrl + "/" + this.props.match.params.page
      this.props.history.push("/sign-in", {redirectTo:newUrl});
    }
    else if (
      this.props.location.state &&
      (this.props.location.state.queryString ||
        this.props.location.state.redirectTo)
    ) {
      this.props.history.push("/sign-in", this.props.location.state);
    } else this.props.history.push("/sign-in", this.props.location.state);
  };

  handleCheckBoxChange = () => {
    this.setState({
      agree: !this.state.agree,
      agreeFormError: "",
      formError: "",
    });
  };

  handleDayBlur = (event) => {
    let dayCheckValue = event.target.value.trim();
    if (
      dayCheckValue != "" &&
      dayCheckValue != undefined &&
      dayCheckValue.length < 2
    ) {
      dayCheckValue = "0" + dayCheckValue;
      this.setState({
        ...this.state,
        dobDay: dayCheckValue,
      });
    }
  };
  handleAllFieldBlur = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (value) {
      value = value.trim();
      // console.log(name, value);
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  handleChange = (event) => {
    let { name, value } = event.target;

    // if (name == "firstName" && this.state.firstNameFormError != "") {
    //   // if (value) { value = value.trim(); }
    //   this.setState({
    //     firstNameFormError: "",
    //   });
    // }

    // if (name == "lastName" && this.state.lastNameFormError != "") {
    //   // if (value) { value = value.trim(); }
    //   this.setState({
    //     lastNameFormError: "",
    //   });
    // }

    if (name == "email" && this.state.email != "") {
      // if (value) { value = value.trim(); }
      this.setState({
        emailFormError: "",
      });
    }

    if (name == "password" && this.state.password != "") {
      // if (value) { value = value.trim(); }
      this.setState({
        passwordFormError: "",
      });
    }

    // if (name == "confirmPassword" && this.state.confirmPassword != "") {
    //   // if (value) { value = value.trim(); }
    //   this.setState({
    //     confirmPasswordCommonError: "",
    //   });
    // }

    // if (name == "agree" && this.state.agreeFormError != "") {
    //     this.setState({
    //         agreeFormError: "",
    //     });
    // }

    // if (name == "dobMonth" && this.state.dobFormError != "") {
    //   this.setState({
    //     dobFormError: "",
    //   });
    // }

    // if (name == "dobDay" && this.state.dobFormError != "") {
    //   this.setState({
    //     dobFormError: "",
    //   });
    // }

    // if (name == "dobYear" && this.state.dobFormError != "") {
    //   this.setState({
    //     dobFormError: "",
    //   });
    // }

    // if (name == "dobDay") {
    //   if (value.length > 2) value = value.slice(0, 2);
    // }
    // if (name == "dobYear") {
    //   if (value.length > 4) value = value.slice(0, 4);
    // }

    this.setState({
      formError: "",
      inValidMessageFromResponse: "",
      [name]: value,
    });
  };

  checkValidDateFormat(dob) {
    // console.log(dob);
    var aDate = moment(dob, "YYYY-MM-DD", true);
    var isValid = aDate.isValid();
    // console.log(isValid);
    return isValid;
  }

  calculateAge(dob) {
    // console.log(dob);
    let date1 = new Date();
    let date2 = new Date(dob);
    let ageDiff = date1.getUTCFullYear() - date2.getUTCFullYear();
    let date2Year = date2.getUTCFullYear();
    if (ageDiff == 21) {
      let monthDiff = date1.getUTCMonth() - date2.getUTCMonth();
      if (monthDiff == 0) {
        if (date2.getDate() <= date1.getDate()) {
          return true;
        } else {
          return false;
        }
      } else if (monthDiff > 0) {
        return true;
      } else {
        return false;
      }
    } else if (ageDiff > 21 && date2Year > 1900) {
      return true;
    } else {
      return false;
    }
  }

  getDobMonthFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 pl-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  getDobDayFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 p-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  getDobYearFormGroupClasses() {
    let classes = "form-group col-xs-4 col-sm-4 pr-0 ";
    classes += this.state.dobFormError != "" ? " mb-0" : "";
    return classes;
  }
  googleResponse = async (googleValues) => {
    const {
      tokenId: idToken,
      accessToken,
      googleId,
      profileObj,
    } = googleValues;
    const { email, familyName: lastName, givenName: firstName } = profileObj;
    this.setState({
      email,
      firstName,
      lastName,
    });
    try {
      const { data: googleData } = await axios({
        url: `${constants.IDENTITY_API}/auth/v1/google`,
        method: "post",
        data: {
          idToken,
          accessToken,
          googleId,
        },
      });
      GoogleAnalyticsFunctions.clickedGoogleSignIn();
      this.setState({
        firstName: firstName,
        lastName: lastName,
        email: email,
        dob: googleData.user.dateOfBirth,
        // signUpNewUserCompleted: true,
        signUpApiResponseDetails: googleData,
      });
    } catch ({ response }) {
      if (response.status === 409) {
        this.setState(
          {
            signUpApiResponseDetails: response.data,
            firstName: firstName,
            lastName: lastName,
            email: email,
            dob: response.data.user.dateOfBirth,
          },
          () => {
            this.onClickToSignIn();
          }
        );
        return;
      }
      if (response.status === 404 || response.status === 500) {
        toast.info("Please try again after a while.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (response.status === 401) {
        const errMsg =
          response.data.message.length > 0
            ? response.data.message
            : "Please try again after a while.";
        this.setState({
          inValidMessageFromResponse: errMsg,
        });
        return;
      } else {
        response.data.errors.map(({ message, param }, index) => {
          if (param) {
            const errorParam = param + "FormError";
            this.setState({
              ...this.state,
              [errorParam]: message,
            });
          }
        });
      }
    }
  };

  facebookResponse = async (facebookValues) => {
    const { accessToken, userID } = facebookValues;
    try {
      const { data: facebookData } = await axios({
        url: `${constants.IDENTITY_API}/auth/v1/facebook`,
        method: "post",
        data: {
          accessToken,
          userID,
        },
      });
      this.setState({
        firstName: facebookData.user.firstName,
        lastName: facebookData.user.lastName,
        email: facebookData.user.email,
        dob: facebookData.user.dateOfBirth,
        signUpNewUserCompleted: true,
        signUpApiResponseDetails: facebookData,
      });
    } catch ({ response }) {
      if (response.status === 409) {
        this.setState({
          signUpApiResponseDetails: response.data,
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          dob: response.data.user.dateOfBirth,
        });
        this.onClickToSignIn();
        return;
      }
      if (response.status === 404 || response.status === 500) {
        toast.info("Please try again after a while.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (response.status === 401) {
        const errMsg =
          response.data.message.length > 0
            ? response.data.message
            : "Please try again after a while.";
        toast.info(errMsg, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      } else {
        response.data.errors.map(({ message, param }, index) => {
          if (param) {
            const errorParam = param + "FormError";
            if (message === "Email is Required") {
              this.setState({
                ...this.state,
                [errorParam]: message,
              });
              toast.info(message, {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            } else {
              this.setState({
                ...this.state,
                [errorParam]: message,
              });
            }
          }
        });
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        {!this.state.signUpNewUserCompleted &&
          !this.state.showTermsAndConditions && (
            <div className="sign-in-main-page overall-font">
              <div className="sign-in-main-container-background">
                <div className="sign-in-main-container padng-style mt-0">
                  <div className="header">
                    <div className="logo-container new-paddin-style-logo-header">
                      <img
                        alt="PairAnything"
                        className="logo new-pa-logo-width"
                        src={logo}
                      />
                    </div>
                    <div className="form-errors-display">
                      <span className="invalid-messages">
                        {/* <FormErrors formErrors={this.state.userExists} /> */}
                        {/* {
                     (!this.state.userExists && this.state.buttonClick)? 'User does not exist' : ''
                   } */}
                        {this.state.inValidMessageFromResponse
                          ? this.state.inValidMessageFromResponse
                          : null}
                      </span>
                    </div>
                    <div className="new-sign-up-title mb-4">Sign Up</div>
                    {/* <div
                      className="col-xs-12 col-sm-12 p-0 social-media-container-dob mb-0"
                      style={{
                        marginTop: "1em !important",
                      }}
                    >
                      <GoogleButton
                        scope={
                          "https://www.googleapis.com/auth/user.birthday.read"
                        }
                        // cookiePolicy={'None'}
                        clientId={constants.GOOGLE_CLIENT_ID}
                        disabled={false}
                        isMobile={false}
                        onSuccess={this.googleResponse}
                      />
                      <FaceBookButton
                        appId={constants.FACEBOOK_APP_ID}
                        isDisabled={false}
                        scope={
                          "public_profile, email, user_birthday, user_age_range"
                        }
                        fields={"name,email,picture,birthday,age-range"}
                        cssClass={"facebook-btn"}
                        autoLoad={false}
                        textButton={"Facebook"}
                        isMobile={false}
                        callback={this.facebookResponse}
                      />
                    </div> */}

                    {/* <div className='back-div three-space-even-width'> */}
                    {/* <img
                                    className="ptr-clss back-btn-width"
                                    alt="Go Back"
                                    src={backBtnBlack}
                                    onClick={() => { this.goToSignInPage() }}
                                /> */}
                  </div>
                  {/* <div className="sign-up-title three-space-even-width">
                                        <span>Sign Up</span>
                                    </div> */}
                  {/* <div className='three-space-even-width'>
                                        <img alt="PairAnything" className="new-sign-up-logo" src={logo} />
                                    </div> */}
                  {/* </div> */}
                  <div className="new-sing-up-address-container">
                    <form
                      className=""
                      // onSubmit={this.singUpSubmit}
                    >
                      <div className="singup-form-card">
                        {/* <div className="shipping-address-card-header">
                                        <img alt="user profile pic" src={userProfileImage} />
                                        <span className="form-row-label-header">User Details</span>
                                    </div> */}
                        {/* <div className="form-group">
                          <label className="form-row-label new-signup-label">First Name</label>
                          <input
                            type="name"
                            className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            onBlur={this.handleAllFieldBlur}
                            placeholder="First Name"
                          />
                          {this.state.firstNameFormError != "" ? (
                            <span className="form-error-shipping-address new-error-msg-font">
                              {this.state.firstNameFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div> */}

                        {/* <div className="form-group">
                          <label className="form-row-label new-signup-label">Last Name</label>
                          <input
                            type="name"
                            className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            onBlur={this.handleAllFieldBlur}
                            placeholder="Last Name"
                          />
                          {this.state.lastNameFormError != "" ? (
                            <span className="form-error-shipping-address new-error-msg-font">
                              {this.state.lastNameFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div> */}

                        <div className="form-group">
                          {/* <label className="form-row-label new-signup-label">Email</label> */}
                          <input
                            type="text"
                            className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            onBlur={this.handleAllFieldBlur}
                            placeholder="Email"
                          />
                          {this.state.emailFormError != "" ? (
                            <span className="form-error-shipping-address new-error-msg-font">
                              {this.state.emailFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          {/* <label className="form-row-label new-signup-label">Password</label> */}
                          <input
                            className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            // onBlur={this.handleAllFieldBlur}
                            placeholder="Password"
                          />
                          {this.state.passwordFormError != "" ? (
                            <span className="form-error-shipping-address new-error-msg-font">
                              {this.state.passwordFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* 
                        <div className="form-group dob-before-margin-bottom">
                          <label className="form-row-label new-signup-label">Confirm Password</label>
                          <input
                            className="form-control form-row-input new-sign-up-input-design new-input-form-height txt-transform-none"
                            type="password"
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            // onBlur={this.handleAllFieldBlur}
                            placeholder="Confirm Password"
                          />
                          {this.state.confirmPasswordCommonError != "" ? (
                            <span className="form-error-shipping-address new-error-msg-font">
                              {this.state.confirmPasswordCommonError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div> */}

                        {/* <label className="form-row-label new-signup-label dob-fnt-size new-grey-font ft-weight-normal">
                          DOB
                        </label>
                        <span className="dob-msg-css">
                          (You must be 21 years of age or older to enter)
                        </span> */}
                        {/* <div className="col-xs-12 col-sm-12 p-0">
                          <div className={this.getDobMonthFormGroupClasses()}>
                            <select
                              className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                              name="dobMonth"
                              value={this.state.dobMonth}
                              onChange={this.handleChange}
                            >
                              <option value="" disabled>
                                Month
                              </option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                          </div>
                          <div className={this.getDobDayFormGroupClasses()}>
                            <input
                              type="number"
                              className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                              name="dobDay"
                              placeholder="Day"
                              maxLength="2"
                              value={this.state.dobDay}
                              onChange={this.handleChange}
                              onBlur={this.handleDayBlur}
                            />
                          </div>
                          <div className={this.getDobYearFormGroupClasses()}>
                            <input
                              type="number"
                              className="form-control form-row-input new-sign-up-input-design new-input-form-height"
                              name="dobYear"
                              maxLength="4"
                              placeholder="Year"
                              value={this.state.dobYear}
                              onChange={this.handleChange}
                            />
                          </div>
                          {this.state.dobFormError != "" ? (
                            <span className="dob-error-message-text new-error-msg-font">
                              {this.state.dobFormError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="terms-and-conditions">
                          By clicking on the Sign up button you verify that you
                          are 21 years of age or older and agree to our{" "}
                          <a
                            href="#"
                            onClick={(e) => {
                              this.termsAndConditionClick(e);
                            }}
                          >
                            Terms of Use
                          </a>
                          <span className="pl-2 pr-2">and</span>
                          <a
                            href="#"
                            onClick={(e) => {
                              this.privacyClick(e);
                            }}
                          >
                            Privacy Policy.
                          </a>
                        </div>
                        {/* <div className="chck-box-div">
                          <div className="chckbox-input-style">
                            <input
                              type="checkbox"
                              id="agreeId"
                              name="agree"
                              onChange={() => {
                                this.handleCheckBoxChange();
                              }}
                              // defaultChecked={this.state.checked}
                              checked={this.state.agree}
                              className="new-sign-up-input-design ptr-clss"
                            />
                          </div>
                        
                          <div className="label-chckbx-style">
                            <label className="chck-box-label">
                              By continuing, you are indicating that you have
                              read and agreed to the{" "}
                              <a
                                href="#"
                                onClick={(e) => {
                                  this.termsAndConditionClick(e);
                                }}
                              >
                                Terms of Use
                              </a>
                              <span className="pl-2 pr-2">and</span>
                              <a
                                href="#"
                                onClick={(e) => {
                                  this.privacyClick(e);
                                }}
                              >
                                Privacy Policy.
                              </a>
                            </label>
                          </div>
                        </div> */}
                        {this.state.agreeFormError != "" ? (
                          <span className="form-error-shipping-address new-error-msg-font">
                            {this.state.agreeFormError}
                          </span>
                        ) : (
                          ""
                        )}

                        {this.state.formError && (
                          <div className="new-sign-upform-error-shipping-address-container mt-3">
                            <span className="common-form-error-text new-error-msg-font">
                              {this.state.formError}
                            </span>
                          </div>
                        )}

                        <button
                          type="button"
                          onClick={this.singUpSubmit}
                          //  disabled={!this.state.formValid}
                          className="btn btn-round sign-in-btn mb-2 mt-4"
                        >
                          Sign Up
                        </button>
                        <div
                          // onClick={this.forgotPassword}
                          className="already-div"
                        >
                          <span className="alrdy-title new-grey-font">
                            Already have an account?{" "}
                          </span>
                          <span
                            className="alrdy-sing-in-text ml-2 ptr-clss"
                            onClick={() => {
                              this.onNavigateToSignIn();
                            }}
                          >
                            Sign In
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        {this.state.signUpNewUserCompleted &&
          !this.state.showTermsAndConditions && (
            <SingUpPasswordSuccess
              onClickToSignIn={this.onClickToSignIn}
              signUpSuccesWineImage={signUpSuccesWineImage}
            />
          )}
        {this.state.showTermsAndConditions && (
          <TermsAndConditions
            showSignUpFromTerms={this.showSignUpFromTerms}
            acceptTermsAndConditions={this.acceptTermsAndConditions}
            cancelClickFromTerms={this.cancelClickFromTerms}
            showPrivacy={this.state.showPrivacy}
            showTerms={this.state.showTerms}
          />
        )}
      </React.Fragment>
    );
  }
  showSignUpFromTerms = () => {
    this.setState({
      showTermsAndConditions: false,
    });
  };
  cancelClickFromTerms = () => {
    this.setState({
      showTermsAndConditions: false,
    });
  };
  acceptTermsAndConditions = () => {
    this.setState({
      showTermsAndConditions: false,
      agree: true,
      agreeFormError: "",
    });
  };
  termsAndConditionClick = (e) => {
    e.preventDefault();
    this.setState({
      showTermsAndConditions: true,
      showTerms: true,
      showPrivacy: false,
    });
  };
  privacyClick = (e) => {
    e.preventDefault();
    this.setState({
      showTermsAndConditions: true,
      showTerms: false,
      showPrivacy: true,
    });
  };
  singUpSubmit = () => {
    let validForm = true;
    // if (!this.state.firstName) {
    //   validForm = false;
    //   this.setState({
    //     firstNameFormError: "First name cannot be empty.",
    //   });
    // }
    // if (!this.state.lastName) {
    //   validForm = false;
    //   this.setState({
    //     lastNameFormError: "Last name cannot be empty.",
    //   });
    // }
    if (!this.state.email) {
      validForm = false;
      this.setState({
        emailFormError: "Email cannot be empty.",
      });
    } else {
      const emailValid = this.state.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (!emailValid) {
        validForm = false;
        this.setState({
          emailFormError: "Please enter a valid email address.",
        });
      }
    }
    // let dateOfBirth;
    // let isAgeVerified = false;
    // if (this.state.dobMonth && this.state.dobDay && this.state.dobYear) {
    //   dateOfBirth =
    //     this.state.dobYear +
    //     "-" +
    //     this.state.dobMonth +
    //     "-" +
    //     this.state.dobDay;
    //   let isValidDateFormat = this.checkValidDateFormat(dateOfBirth);
    //   if (isValidDateFormat) {
    //     let validDob = this.calculateAge(dateOfBirth);
    //     isAgeVerified = validDob;
    //     if (validDob) {
    //       this.setState({
    //         validAge: true,
    //         dobFormError: "",
    //       });
    //     } else if (!validDob) {
    //       validForm = false;
    //       this.setState({
    //         dobFormError:
    //           "Sorry, you must be at least 21 years old and year should be greater than 1900.",
    //         validAge: false,
    //       });
    //     }
    //   } else {
    //     validForm = false;
    //     this.setState({
    //       dobFormError: "Sorry, please enter a valid date.",
    //       validAge: false,
    //     });
    //   }
    // } else {
    //   validForm = false;
    //   this.setState({
    //     dobFormError: "Sorry, please enter a valid date.",
    //     validAge: false,
    //   });
    // }

    if (!this.state.password) {
      validForm = false;
      this.setState({
        passwordFormError: "Please enter your password.",
      });
    } else if (this.state.password.length < 4) {
      validForm = false;
      this.setState({
        passwordFormError: "Please enter atleast 4 characters. ",
      });
    }
    //  else if (!this.state.confirmPassword) {
    //   validForm = false;
    //   this.setState({
    //     confirmPasswordCommonError: "Please enter your confirm password. ",
    //   });
    // } else if (this.state.password != this.state.confirmPassword) {
    //   validForm = false;
    //   this.setState({
    //     confirmPasswordCommonError: "Passwords do not match.",
    //   });
    // }
    else if (
      this.state.password
      //  &&
      // this.state.confirmPassword &&
      // this.state.password == this.state.confirmPassword
    ) {
      this.setState({
        passwordFormError: "",
        confirmPasswordCommonError: "",
      });
    }

    // if (!this.state.agree) {
    //   validForm = false;
    //   this.setState({
    //     agreeFormError: "Please agree to the Terms of Use.",
    //   });
    // }

    if (validForm) {
      // this.state.dob = dateOfBirth;
      let obj = {
        // firstName: this.state.firstName,
        // lastName: this.state.lastName,
        // dateOfBirth: dateOfBirth,
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.password,
        isAgeVerified: true,
        ageVerifiedOn: moment().utc(),
      };
      // console.log(obj);
      this.singUpNewUser(obj);
      GoogleAnalyticsFunctions.clickedSignUp();
    } else {
      // this.setState({
      //     formError: "* The above form has errors. ",
      // });
    }
    // this.setState({
    //     signUpNewUserCompleted: true
    // })
  };

  singUpNewUser = (data) => {
    // console.log(data);
    let signUpNewCustomerUrl =
      constants.IDENTITY_API + "/auth/v1/customer/signUp";
    try {
      fetch(signUpNewCustomerUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          //   if successful, update state
          console.log(response);
          if (
            response.message ==
            "This user account already exists. Please try logging in."
          ) {
            {
              toast.info(
                "This user account already exists. Please try logging in.",
                {
                  position: toast.POSITION.BOTTOM_CENTER,
                }
              );
              if(this.props.match.params && this.props.match.params.wineryName){
                let newUrl = this.props.match.params.wineryName
                if(this.props.match.params.page)
                  newUrl = newUrl + "/" + this.props.match.params.page
                this.props.history.push("/sign-in", {redirectTo:newUrl});
              }

              else this.props.history.push("/sign-in", this.props.location.state);
            }
          } else if (!response.errors) {
            // console.log(response);
            this.setState({
              signUpApiResponseDetails: response,
              signUpNewUserCompleted: true,
            });
            // console.log(this.state.signUpApiResponseDetails);
          } else {
            // console.log(response.errors);
            if (response.errors.length === 1) {
              response.errors.forEach((element) => {
                if (
                  element.param === "email" &&
                  element.message === "User already Exists"
                ) {
                  toast.info(
                    "This user account already exists. Please try logging in.",
                    {
                      position: toast.POSITION.BOTTOM_CENTER,
                    }
                  );
                  //navigate from email campaign
                  if(this.props.match.params && this.props.match.params.wineryName){
                    let newUrl = this.props.match.params.wineryName
                    if(this.props.match.params.page)
                      newUrl = newUrl + "/" + this.props.match.params.page
                    this.props.history.push("/sign-in", {redirectTo:newUrl});
                  }
                  else if (
                    this.props.location.state &&
                    this.props.location.state.queryString
                  ) {
                    this.props.history.push(
                      "/sign-in",
                      this.props.location.state
                    );
                  } else
                    this.props.history.push(
                      "/sign-in",
                      this.props.location.state
                    );
                }
              });
            } else if (response.errors.length > 0) {
              response.errors.forEach((element) => {
                // const {param} = element
                // const errState = param + 'FormError'
                // this.setState({
                //     [errState]: element.message
                // })
                if (element.param === "email") {
                  this.setState({
                    emailFormError: element.message,
                  });
                }
                if (element.param === "firstName") {
                  this.setState({
                    firstNameFormError: element.message,
                  });
                }
                if (element.param === "lastName") {
                  this.setState({
                    lastNameFormError: element.message,
                  });
                }
                if (element.param === "password") {
                  this.setState({
                    confirmPasswordCommonError: element.message,
                  });
                }
                if (element.param === "confirmPassword") {
                  this.setState({
                    confirmPasswordCommonError: element.message,
                  });
                }
                if (element.param === "dateOfBirth") {
                  this.setState({
                    dobFormError: element.message,
                  });
                }
              });
              // this.setState({
              //     formError: "* The above form has errors. ",
              // });
            }
          }
        })
        .catch((error) => {
          console.log(error.message);

          this.setState({
            signUpNewUserCompleted: false,
            formError: "Something went wrong. Please try again later. ",
          });
        });
    } catch (e) {
      // console.log(e);
      if (
        e.message == "This user account already exists. Please try logging in."
      ) {
        {
          toast.info(
            "This user account already exists. Please try logging in.",
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
          if(this.props.match.params && this.props.match.params.wineryName){
            let newUrl = this.props.match.params.wineryName
            if(this.props.match.params.page)
              newUrl = newUrl + "/" + this.props.match.params.page
            this.props.history.push("/sign-in", {redirectTo:newUrl});
          }
          else this.props.history.push("/sign-in", this.props.location.state);
        }
      } else
        this.setState({
          signUpNewUserCompleted: false,
          formError: "Something went wrong. Please try again later. ",
        });
    }
    // this.setState({
    //     signUpNewUserCompleted: true
    // })
  };
  onClickToSignIn = () => {
    // console.log('Let\'s get started', this.state.signUpApiResponseDetails);
    GoogleAnalyticsFunctions.clickedSignIn();
    // this.props.signoutAction.ResetApp();
    if (this.state.signUpApiResponseDetails) {
      console.log(this.state.signUpApiResponseDetails);
      if (
        this.state.signUpApiResponseDetails.user &&
        this.state.signUpApiResponseDetails.user.pairanything_status.name ==
          "active"
      ) {
        localStorage.clear();
        localStorage.setItem(
          "signin_token",
          JSON.stringify(this.state.signUpApiResponseDetails.token)
        );
        localStorage.setItem(
          "user_data",
          JSON.stringify(this.state.signUpApiResponseDetails.user)
        );
        localStorage.setItem(
          "user_id",
          JSON.stringify(this.state.signUpApiResponseDetails.user.id)
        );
        // localStorage.setItem(
        //   "winery_id",
        //   JSON.stringify(this.state.signUpApiResponseDetails.wineryId)
        // );
        localStorage.setItem(
          "reward_points",
          JSON.stringify(this.state.signUpApiResponseDetails.rewardPoints)
        );
        // localStorage.setItem(
        //   "winery_list",
        //   JSON.stringify(this.state.signUpApiResponseDetails.user.wineryData)
        // );
        localStorage.setItem("ageConfirmed", true);

        let signinWineryUserStatus = "";
        let disabledWineryCount = 0;
        for (
          let i = 0;
          i < this.state.signUpApiResponseDetails.user.wineryData.length;
          ++i
        ) {
          if (
            this.state.signUpApiResponseDetails.user.wineryData[i]
              .wineryUserStatus == 3
          ) {
            disabledWineryCount++;
          }
        }
        //fetch campaign data if coming from email campaign
        if (this.state.signUpApiResponseDetails.user.wineryData.length == 1) {
          localStorage.setItem(
            "winery_name",
            this.state.signUpApiResponseDetails.user.wineryData[0].displayName
          );
          localStorage.setItem(
            "winery_id",
            JSON.stringify(
              this.state.signUpApiResponseDetails.user.wineryData[0].wineryId
            )
          );
        }
        if (
          this.state.signUpApiResponseDetails.user.wineryData.length == 1 &&
          this.state.signUpApiResponseDetails.user.wineryData[0]
            .wineryUserStatus == 2
        ) {
          signinWineryUserStatus = "single winery-enabled";
        } else if (
          this.state.signUpApiResponseDetails.user.wineryData.length == 1 &&
          this.state.signUpApiResponseDetails.user.wineryData[0]
            .wineryUserStatus == 3
        ) {
          // user is part of 1 winery and is disabled in that
          localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "single winery-disabled";
          // return {
          //   inValidMessageFromResponse: 'Your winery has disabled you. Please contact the winery admin.'
          // }
        } else if (
          this.state.signUpApiResponseDetails.user.wineryData.length > 0 &&
          disabledWineryCount ==
            this.state.signUpApiResponseDetails.user.wineryData.length
        ) {
          // user is disabled in all wineries which he/she is a part of
          localStorage.setItem("user_winery_status", "disabled");
          signinWineryUserStatus = "multiple winery-disabled";
        } else {
          //navigate to sign up from email campaign
          if(this.props.match.params && this.props.match.params.wineryName){
            let newUrl = "/" + this.props.match.params.wineryName
            if(this.props.match.params.page)
              newUrl = newUrl + "/" + this.props.match.params.page
            this.props.history.push(newUrl);
          }
          else if (
            this.props.location.state &&
            this.props.location.state.queryString
          ) {
            fetchCampaignDetails(
              this.props.location.state.queryString,
              this.props.history
            );
          } else if (
            this.props.location.state &&
            this.props.location.state.redirectTo
          )
            this.props.history.push(
              this.props.location.state.redirectTo,
              this.props.location.state
            );
          else this.props.history.push("/discover", this.props.location.state);
        } // This is the first page as per PAIR-1895

        // Commenting below logic - now as per PAIR-1895 Customer passport Discover page is the first page
        // if (signinWineryUserStatus == "single winery-enabled") {
        //     //user is a member of one winery and that wineryuser status is enabled
        //     let wineryId = JSON.parse(localStorage.getItem("winery_id"));
        //     let url = "/search-wine" + "/" + wineryId;
        //     this.props.history.push(url);
        // } else if (signinWineryUserStatus == "single winery-disabled") {
        //     //user is a member of one winery and that winery is disabled
        //     this.props.history.push("/profile");
        // } else if (signinWineryUserStatus == "multiple winery-disabled") {
        //     this.props.history.push("/profile");
        // } else {
        //     this.props.history.push("/select-winery");
        // }
      } else {
        // console.log(this.state.signUpApiResponseDetails);
      }
    }
  };
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signoutAction: bindActionCreators(signoutAction, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewSignUp)
);
