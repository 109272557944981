import PairingsIcon from "../../../assets/images/Discover/pairing.svg";
import WineriesIcon from "../../../assets/images/Discover/wineries v2.svg";
import TasteQuizIcon from "../../../assets/images/Discover/tastequiz.svg";
import ShopIcon from "../../../assets/images/Discover/shop.svg";
import {
  clickedEnjoy,
  clickedShop,
  clickedVisit,
} from "../../components/PAGoogleAnalytics";

const routes = [
  {
    title: "Pairings",
    link: "/pa-recommendations",
    icon: PairingsIcon,
    onClick: () => clickedEnjoy()
  },
  {
    title: "Taste Quiz",
    link: "/TastePreferenceQuiz",
    icon: TasteQuizIcon,
    onClick: () => clickedEnjoy()
  },
  {
    title: "Wineries",
    link: "/visit",
    icon: WineriesIcon,
    onClick: () => clickedVisit(),
  },
  {
    title: "Shop",
    link: "/wineries",
    icon: ShopIcon,
    onClick: () => clickedShop()
  },
  /*
    {
        link: "/virtual-tasting",
        title: "Virtual Tasting",
        icon: VirtualTastingIcon,
        onClick: () => clickedVirtualTasting()
    },
     
  {
    title: "Knowledge",
    icon: KnowledgeIcon,
    onClick: () => {
      clickedKnow();
      window.open(BLOG_URL, "_blank");
    },
  },
  */
];

export default routes;
