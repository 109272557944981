import React, { Component } from "react";
import emailjs from "@emailjs/browser";
import "./TestResults.scss";
import * as constants from "../../constants/constants";
import { get } from "../../lib/fetch";

class QuizResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      varietalProfiles: {},
      DataisLoaded: false,
      hh: [],
      CurrVisitCount: +localStorage.getItem("visits") + 1,
    };
    var visits;

    if (!localStorage.getItem("visits")) {
      if (this.state.questionAnswered) localStorage.setItem("visits", 2);
    }
    visits = +localStorage.getItem("visits"); //+ operator change string into number
    var incrementedCount = visits + 1; // incrementing visit of the user when it refreshing the website

    localStorage.setItem("visits", incrementedCount);
    let CurrVisitCount = localStorage.getItem("visits");
    this.setState(() => ({ CurrVisitCount }));
  }

  componentDidMount() {
    // this.handleAction();

    // Simple POST request with a JSON body using fetch
    const quesAnsDataRes = JSON.parse(
      localStorage.getItem("questionsAnswered")
    );
    let dsScaleSum;
    let bodyScoreSum;
    let tanninsScoreSum;
    let aciditySum;
    let avbSum;
    let flavorProfiles;
    let flavorData = [];
    quesAnsDataRes.map((items) => {
      if (items.flavourProfile !== "") {
        flavorData.push(items.flavourProfile);
      }
    });
    dsScaleSum = quesAnsDataRes.reduce((prevVal, currVal) => {
      return prevVal + currVal.ds;
    }, 0);
    bodyScoreSum = quesAnsDataRes.reduce(function (prevVal, currVal) {
      return prevVal + currVal.body;
    }, 0);
    tanninsScoreSum = quesAnsDataRes.reduce(function (prevVal, currVal) {
      return prevVal + currVal.tannins;
    }, 0);
    aciditySum = quesAnsDataRes.reduce(function (prevVal, currVal) {
      return prevVal + currVal.acidity;
    }, 0);
    avbSum = quesAnsDataRes.reduce(function (prevVal, currVal) {
      return prevVal + currVal.avb;
    }, 0);
    flavorProfiles = flavorData.join();

    const endPoints = `DSscale=${dsScaleSum}&bodyScore=${bodyScoreSum}&tanninsScore=${tanninsScoreSum}&acidity=${aciditySum}&abv=${avbSum}&flavorProfile=${flavorProfiles}&wineType=white&id=1`;
    const url = `${constants.TASTE_PREF_API}/api/v1/taste/create_score?${endPoints}`;

    const requestOptions = {
      method: "POST",
    };

    Promise.all([
      fetch(url, requestOptions),
      get(
        `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`
      ),
    ])
      .then((r) => Promise.all(r.map((a) => a.json())))
      .then(([r1, r2]) => {
        this.setState({
          items: r1,
          varietalProfiles: r2,
          DataisLoaded: true,
        });
      });
  }

  SegmentatedBar = (x) => {
    return (
      <div class="container">
        {Array(x).fill(<div className="segmented-list elem-flag"></div>)}
        {Array(5 - x).fill(<div className="segmented-list elem"></div>)}
      </div>
    );
  };
  Taste_notes = (varietalName) => {
    const profile = this.state.varietalProfiles[varietalName.trimEnd()];

    return Object.keys(profile?.flavors)
      .reverse()
      .slice(0, 3)
      .map((flavor, index) => {
        return (
          <div className="taste-pics">
            <img
              alt="img"
              height="50"
              width="50"
              style={{ "border-radius": "50%" }}
              src={
                [flavor] in profile?.flavors
                  ? profile?.flavors[flavor][
                      Math.floor(
                        Math.random() * profile?.flavors[flavor].length
                      )
                    ]
                  : undefined
              }
            />
            <div className="taste-notes">
              <div>
                <span style={{ "margin-right": "0px" }}>{flavor}</span>
              </div>
            </div>
          </div>
        );
      });
  };

  RecommendationDisplay = () => {
    const latestData = JSON.parse(localStorage.getItem("latestRecords"));
    console.log(`latestData:`);
    console.log(latestData);
    console.log(`latestData[0]:`);
    console.log(latestData[0]);
    console.log(`latestData[1]:`);
    console.log(latestData[1]);
    console.log(`latestData[2]:`);
    console.log(latestData[2]);
    let x = "dry";
    let x1 = "light";
    let y = latestData[0].wine_varietal;
    let y1 = latestData[1].wine_varietal;
    let y2 = latestData[2].wine_varietal;
    let z = latestData[0].scale;
    let z2 = latestData[1].scale;
    let z22 = latestData[2].scale;
    let z1 = latestData[0].body;
    let z3 = latestData[1].body;
    let z11 = latestData[2].body;

    return (
      <div className="rec-container">
        <div className="container-item">
          <div>
            <img
              className="wineImage"
              alt="img"
              src={latestData[0].img_url}
            />
          </div>
          <div className="wineRec">
            <div>
              <span className="test-result-small-highlight1">
                Since you like <span className="flavors">{x}</span> flavors...
              </span>
            </div>
            <div>
              <span
                className="order-success-txt"
                style={{ color: "#951901", "font-weight": "700" }}
              >
                {y}
              </span>
            </div>
            <div className="taste-pics-container">
              {this.Taste_notes(latestData[0].wine_varietal)}
            </div>
            <div style={{ position: "relative", left: "-18px" }}>
              {this.SegmentatedBar(z)}
            </div>
            <span className={z <= 3 ? "scaleBold" : ""}>Dry</span>
            <span className={`scaleNormal ${z > 3 ? "scaleBold" : ""}`}>
              Sweet
            </span>
            <div style={{ position: "relative", left: "-18px" }}>
              {this.SegmentatedBar(z1)}
            </div>
            <span className={z1 <= 3 ? "scaleBold" : ""}>Light</span>
            <span className={`scaleNormal ${z1 > 3 ? "scaleBold" : ""}`}>
              Bold
            </span>
          </div>
        </div>
        <div className="container-item">
          <div className="wineRec" style={{ position: "relative" }}>
            <div>
              <span className="test-result-small-highlight1">
                Since you like <span className="flavors">{x1}</span> flavors...
              </span>
            </div>
            <div>
              <span
                className="order-success-txt"
                style={{ color: "#951901", "font-weight": "700" }}
              >
                {y1}
              </span>
            </div>
            <div className="taste-pics-container">
              {this.Taste_notes(latestData[1].wine_varietal)}
            </div>
            <div style={{ position: "relative", left: "-18px" }}>
              {this.SegmentatedBar(z2)}
            </div>
            <span className={z2 <= 3 ? "scaleBold" : ""}>Dry</span>
            <span className={`scaleNormal ${z2 > 3 ? "scaleBold" : ""}`}>
              Sweet
            </span>
            <div style={{ position: "relative", left: "-18px" }}>
              {this.SegmentatedBar(z3)}
            </div>
            <span className={z3 <= 3 ? "scaleBold" : ""}>Light</span>
            <span className={`scaleNormal ${z3 > 3 ? "scaleBold" : ""}`}>
              Bold
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <img
              className="wineImage1"
              alt="img"
              src={latestData[1].img_url}
            />
          </div>
        </div>
        <div className="container-item">
          <div>
            <img
              className="wineImage"
              alt="img"
              src={latestData[2].img_url}
            />
          </div>
          <div className="wineRec">
            <div>
              <span className="test-result-small-highlight1">
                Since you like <span className="flavors">{x}</span> flavors...
              </span>
            </div>
            <div>
              <span
                className="order-success-txt"
                style={{ color: "#951901", "font-weight": "700" }}
              >
                {y2}
              </span>
            </div>
            <div className="taste-pics-container">
              {this.Taste_notes(latestData[2].wine_varietal)}
            </div>
            <div style={{ position: "relative", left: "-18px" }}>
              {this.SegmentatedBar(z22)}
            </div>
            <span className={z22 <= 3 ? "scaleBold" : ""}>Dry</span>
            <span className={`scaleNormal ${z22 > 3 ? "scaleBold" : ""}`}>
              Sweet
            </span>
            <div style={{ position: "relative", left: "-18px" }}>
              {this.SegmentatedBar(z11)}
            </div>
            <span className={z11 <= 3 ? "scaleBold" : ""}>Light</span>
            <span className={`scaleNormal ${z11 > 3 ? "scaleBold" : ""}`}>
              Bold
            </span>
          </div>
        </div>
      </div>
    );
  };
  // handleAction = () => {
  //   if (this.state.CurrVisitCount === 2 || this.state.CurrVisitCount === 4) {
  //     this.props.history.push("/tastepreferencequiz");
  //   }
  // };
  openHomePage = () => {
    let counts = localStorage.getItem("count");
    if (counts >= 15) {
      localStorage.removeItem("questionsAnswered");
      localStorage.removeItem("count");
      localStorage.removeItem("visits");
    }
    this.props.history.push("/discover");
  };

  render() {
    let page = 0;
    if (!this.state.DataisLoaded)
      return (
        <div>
          <h1> Please wait some time.... </h1>{" "}
        </div>
      );
    let newFinalScore = this.state.items.data.sort((a, b) =>
      a.finalScore < b.finalScore ? 1 : a.finalScore > b.finalScore ? -1 : 0
    );
    console.log(`newFinalScore:`);
    console.log(newFinalScore);
    localStorage.setItem(
      "latestRecords",
      JSON.stringify(newFinalScore.slice(0, 3))
    );
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let email = JSON.parse(localStorage.getItem("email"));
    if (email && !signInToken) {
      emailjs.send(
        constants.EMAILJS_SERVICE_API_KEY,
        constants.EMAILJS_TEMPLATE_API_KEY,
        {
          email: email,
          V1: newFinalScore[0].wine_varietal,
          V2: newFinalScore[1].wine_varietal,
          V3: newFinalScore[2].wine_varietal,
        },
        constants.EMAILJS_API_KEY
      );
    }

    return (
      <React.Fragment>
        <div className="success-main-container font-family">
          <div className="success-inner-container taste-quiz-container">
            <button
              className="closeBtn"
              onClick={() => {
                this.openHomePage();
              }}
            >
              X
            </button>
            <div className="your-matches">
              <span className="test-result-small-highlight">YOUR MATCHES</span>
            </div>
            <div>
              <div className="resultTitle">
                <p>Based on your answers, we think you'll enjoy...</p>
                <p className="drumRoll">*drumroll*</p>
              </div>
            </div>
            <div>{this.RecommendationDisplay()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default QuizResults;
