import React, { Component } from "react";
import "./BottomNavigation.scss";
import { withRouter } from "react-router-dom";

import { Navbar, NavItem, NavDropdown, MenuItem, Nav } from "react-bootstrap";

import chatBtnNotSelected from "../../../assets/images/BottomNavigationIcons/chat-not-selected-icon.svg";

import chatBtnSelected from "../../../assets/images/chat-selected-icon.svg";

import eventsNotSelected from "../../../assets/images/BottomNavigationIcons/events-icon-not-selected.svg";
import eventsSelected from "../../../assets/images/BottomNavigationIcons/events-icon-selected.svg";

import wineGlassNotSelectedBtn from "../../../assets/images/BottomNavigationIcons/wine-glass-not-selected.svg";
import wineGlassSelectedBtn from "../../../assets/images/BottomNavigationIcons/wine-glass-selected.svg";

import userProfileSelectedBtn from "../../../assets/images/BottomNavigationIcons/human-selected.svg";
import userProfileNotSelectedBtn from "../../../assets/images/BottomNavigationIcons/human.svg";
import pairAnythingLogo from "../../../assets/images//BottomNavigationIcons/home.svg";
import pairAnythingNotSelectedLogoBtn from "../../../assets/images/BottomNavigationIcons/pair-anything-not-selected-icon.png";
import pairAnythingSelectedLogoBtn from "../../../assets/images/BottomNavigationIcons/pair-anything-selected-logo.png";
import SignInToContinue from "../CommonComponents/SignInToContinue";
import { convertToCamelCase } from "../../constants/commonFunctions";
import { connect } from "react-redux";

class BottomNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumerWineryStatus: "",
      wineryId: "",
    };
  }

  changeRoute = () => {};

  componentDidMount() {
    // this.displayNotificationCount();

    let consumerWineryStatus = localStorage.getItem("user_winery_status");
    // let wineryId = localStorage.getItem("winery_id");

    if (this.props.wineryDetails !== null) {
      this.setState({
        wineryId: this.props.wineryDetails.id,
      });
    }

    if (consumerWineryStatus != null && consumerWineryStatus != undefined) {
      this.setState({
        consumerWineryStatus: consumerWineryStatus,
      });
    }
  }

  profileIconClicked = () => {
    // document.getElementById("profile-icon").src = {userProfileBtn}
  };

  render() {
    if (this.props.searchWineActive) {
    } else if (this.props.chatActive) {
    } else if (this.props.availableWinesActive) {
    } else if (this.props.profileActive) {
    } else if (this.props.eventsActive) {
    } else {
    }

    return (
      <Nav justify variant="tabs" defaultActiveKey="/home">
        <Nav.Item>
          <Nav.Link
            className="nav-link-bottom-nav"
            onClick={() => this.handleNavClick("/discover", "Discover")}
          >
            <img
              className={"wine-glass-btn"}
              alt="Home"
              //  src={wineGlassNotSelectedBtn}
              src={pairAnythingLogo}
              style={{ maxWidth: "20px", maxHeight: "16x" }}
            />
          </Nav.Link>
          <small className={"d-inline bottom-nav-title"}>Home</small>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="nav-link-bottom-nav"
            onClick={
              () =>
                this.handleNavClick(
                  "/" +
                    convertToCamelCase(localStorage.getItem("winery_name")) +
                    "/pairings",
                  "to view Pairings."
                )
              // : this.handleNavClick(
              //     "/search-wine" + "/" + this.state.wineryId,
              //     "to view Pairings."
              //   )
            }
            // href={
            //   this.state.consumerWineryStatus == "disabled"
            //     ? ""
            //     : "/search-wine" + "/" + this.state.wineryId
            // }
          >
            <img
              className={"pair-anything-logo-btn"}
              alt="Recommendations"
              src={
                this.props.searchWineActive
                  ? pairAnythingSelectedLogoBtn
                  : pairAnythingNotSelectedLogoBtn
              }
            />
          </Nav.Link>
          <small
            className={
              this.props.searchWineActive
                ? "d-inline bottom-nav-active-title "
                : "d-inline bottom-nav-title"
            }
          >
            Pairings
          </small>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            className="nav-link-bottom-nav"
            onClick={() =>
              this.handleNavClick(
                "/" +
                  convertToCamelCase(localStorage.getItem("winery_name")) +
                  "/wines",
                "Wines"
              )
            }
            // href={
            //   this.state.consumerWineryStatus == "disabled"
            //     ? ""
            //     : "/available-wines"
            // }
            eventKey="link-3"
          >
            <img
              className={"wine-glass-btn"}
              alt="Wines"
              //  src={wineGlassNotSelectedBtn}
              src={
                this.props.availableWinesActive
                  ? wineGlassSelectedBtn
                  : wineGlassNotSelectedBtn
              }
            />
          </Nav.Link>
          <small
            className={
              this.props.availableWinesActive
                ? "d-inline bottom-nav-active-title"
                : "d-inline bottom-nav-title"
            }
          >
            Wines
          </small>
        </Nav.Item>

        {/* 
                                        Commenting out profile and chat icons from the bottom nav
                                        Also the displayNotificationCount for now
                                 */}

        {/* <Nav.Item>
                                        <Nav.Link className="nav-link-bottom-nav" href = "/wine-news-feed" eventKey="link-4">
                                            <img className="dashboard-btn" 
                                      
                                            // src = { this.props.wineEducationActive? archiveSelectedBtn : archiveNotSelectedBtn } />
                                               src = { this.props.wineNewsFeedActive ? dashboardSelectedBtn : dashboardNotSelectedBtn } /> 
                                            />
                                        </Nav.Link>
                                    </Nav.Item> */}

        {/* <Nav.Item>
          <Nav.Link
            className="nav-link-bottom-nav"
            eventKey="link-2"
            onClick={() =>
              this.state.consumerWineryStatus == "disabled"
                ? ""
                : this.handleNavClick(
                    "/chat" + "/" + this.state.wineryId,
                    "to Chat with our representatives.", true
                  )
            }
          >
            <img
              className={
                this.state.consumerWineryStatus == "disabled"
                  ? "bottom-nav-icon-disabled"
                  : "wine-glass-btn"
              }
              alt="Chat"
              src={this.props.chatActive ? chatBtnSelected : chatBtnNotSelected}
            />
          </Nav.Link>
          <small
            className={
              this.props.chatActive
                ? "d-inline bottom-nav-active-title"
                : this.state.consumerWineryStatus == "disabled"
                ? "d-inline bottom-nav-title bottom-nav-icon-disabled"
                : "d-inline bottom-nav-title"
            }
          >
            Chat
          </small>
        </Nav.Item> */}

        {/* <Nav.Item>
          <Nav.Link
            className="nav-link-bottom-nav"
            eventKey="link-2"
            onClick={() =>
              this.state.consumerWineryStatus == "disabled"
                ? ""
                : this.handleNavClick("/events", "to view Events.")
            }
          >
            <img
              className={
                this.state.consumerWineryStatus == "disabled"
                  ? "bottom-nav-icon-disabled"
                  : this.props.eventsActive
                  ? "events-btn-selected"
                  : "events-btn-not-selected"
              }
              alt="Events"
              src={this.props.eventsActive ? eventsSelected : eventsNotSelected}
            />
          </Nav.Link>
          <small
            className={
              this.props.eventsActive
                ? "d-inline bottom-nav-active-title"
                : this.state.consumerWineryStatus == "disabled"
                ? "d-inline bottom-nav-title bottom-nav-icon-disabled"
                : "d-inline bottom-nav-title"
            }
          >
            Events
          </small>
        </Nav.Item> */}

        <Nav.Item>
          <Nav.Link
            className="nav-link-bottom-nav"
            onClick={() =>
              this.handleNavClick("/profile", "to view your Profile.", true)
            }
            eventKey="link-5"
          >
            <img
              onClick={this.profileIconClicked.bind(this)}
              id="profile-icon"
              className="user-profile-btn"
              alt="Profile"
              src={
                this.props.profileActive
                  ? userProfileSelectedBtn
                  : userProfileNotSelectedBtn
              }
            />
          </Nav.Link>

          <small
            className={
              this.props.profileActive
                ? "d-inline bottom-nav-active-title"
                : "d-inline bottom-nav-title"
            }
          >
            Account
          </small>

          {/* <Nav.Link eventKey="link-4" disabled>
                                    Disabled
                                    </Nav.Link> */}
        </Nav.Item>
        <SignInToContinue
          handleClose={this.handlePopupClose}
          signInPopUp={this.state.signInPopUp}
          where={this.state.where}
          url={this.state.url}
        />
      </Nav>
    );
  }
  handlePopupClose = () => {
    this.setState({ signInPopUp: false });
  };
  handleNavClick = (url, where, askSignIn) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    if (!signInToken && askSignIn) {
      this.setState({ signInPopUp: true, url, where });
    } else this.props.history.push(url);
  };
}
const mapStateToProps = (state) => {
  return { wineryDetails: state.WineryReducer.currentWineryDetails };
};
BottomNavigation = withRouter(BottomNavigation);
export default connect(mapStateToProps, null)(BottomNavigation);
