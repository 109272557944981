import React, { useEffect, useState } from "react";
import styles from "./role-pill.module.scss";

export default function WineTypePill({ roleId }) {
  const [ text, setText ] = useState("User");

  useEffect(() => {
    if (!!roleId) {
      if (roleId == 3) {
        setText("Brand Ambassador")
      } else if (roleId == 4) {
        setText("Brand Ambassador")
      }
    }
  }, [roleId]);

  if (!roleId) return <></>;

  return (
    <div className={`${styles.pill}`} >
      {text}
    </div>
  );
}
