import React, { Component } from "react";
import redAddIcon from "../../../assets/images/red-add-icon.svg";
import locationIcon from "../../../assets/images/ShippingAddress/address.svg";
import "./PaymentCardSelectionComponent.scss";

export default class PaymentCardSelectionComponent extends Component {
  render() {
    const { cardData, selecteCardId, onCardSelect } = this.props;
    return (
      <div className="address-selection-container col-sm-12 col-xs-12 mt-4">
        <div className="select-address-radio col-sm-1 col-xs-1 p-0">
          <input
            type="radio"
            value={cardData.id}
            checked={cardData.id === selecteCardId}
            onChange={() => onCardSelect(cardData.id)}
          />
        </div>
        <div
          className="col-sm-11 col-xs-11 p-0 radio-btn-address-container"
          onClick={() => onCardSelect(cardData.id)}
        >
          <div className="ml-3 mt-3 mb-3 mr-3">
            <span className="card-value">{cardData.cardNumber}</span>
          </div>
          {/* <input type="text" className="form-control" value={cardData.cardNumber} /> */}
        </div>
      </div>
    );
  }
}
