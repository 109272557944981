import React, { Component } from "react";
import "./FavoriteWines.scss";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";

import backBtn from "../../../assets/images/left-arrow-white.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import emptyCardWineImage from "../../../assets/images/EmptyPageCard/available-wines-screen-image.svg";

import { ToastContainer, toast } from "react-toastify";

import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import * as constants from "../../constants/constants";
import AvailableWineCard from "../../components/AvailableWineCard/AvailableWineCard";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import CartIconComponent from "../../components/CartIcon/CartIcon";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";

toast.configure();

let favorite = [];

class FavoriteWine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wineryName: "",
      apiHit: false,
      chatBtnClickedStatus: false,
      wineData: [],
      availableWineList: [],
      errorOccurred: "",
    };
  }

  componentDidMount = () => {
    GoogleAnalyticsFunctions.logPagesVisitedByUser();
    GoogleAnalyticsFunctions.clickedAvailableWine();
  };

  componentWillMount = () => {
    favorite = [];
    this.renderWinesForCurrentWinery();
  };

  gotoPreviousPage = () => {
    this.props.history.goBack();
  };

  renderWinesForCurrentWinery = () => {
    try {
      let wineryData = localStorage.getItem("winery_name");
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;

      let username = "user" + consumerData.id;
      let toBeFavorite = Cookies.getJSON(username);
      for (const tmp in toBeFavorite) {
        favorite.push(parseInt(tmp));
      }

      let offset = 0;
      let limit = 10;
      //let renderWinesApiUrl = constants.WINERY_API + '/api/v1/wine/winery/consumer?wineryId=' + wineryId + "&offset=" + offset + "&limit=" + limit;
      let renderWinesApiUrl =
        constants.WINERY_API +
        "/api/v1/public/wine/winery/consumer?wineryId=" +
        wineryId;
      fetch(renderWinesApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: renderWinesApiUrl,
              operation: "GET",
              status: res.status,
            };
            this.postCaughtException(JSON.stringify(apiErrorObj));
            this.setState({
              errorOccurred: "renderWines",
              // errorMessage: 'Something went wrong. Please try again later. '
              errorMessage:
                "Your email is no longer associated with " +
                wineryData +
                " winery, please contact the winery to gain access again. ",
            });

            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state
          let tmp = [];
          for (let i = 0; i < json.rows.length; ++i) {
            if (favorite.includes(json.rows[i]["id"])) {
              tmp.push(json.rows[i]);
            }
          }
          this.setState({
            wineData: json,
            availableWineList: tmp,
            apiHit: true,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          errorOccurred: "renderWines",
          errorMessage:
            "Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin",
        });
      }
    }
  };

  renderAvailableWineCards = () => {
    let result = [];

    if (this.state.errorOccurred == "renderWines") {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container mt-5em">
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.availableWineList.length > 0) {
      // display AvailableWineCard if wines have been added

      result.push(
        this.state.availableWineList.map((el) => (
          <AvailableWineCard wineData={el} id={el.id} key={el.id} />
        ))
      );
    } else {
      // else display a message

      if (this.state.apiHit) {
        let emptyCardData = {
          title: "Empty Cellar!",
          text: "Your favorite wines are coming up!",
          image: emptyCardWineImage,
        };
        result.push(
          <div className="empty-page-main-container desktop-error-container-margin">
            <EmptyPageCard emptyPageCardData={emptyCardData} />
          </div>
        );
      }
    }
    return result;
  };

  render() {
    return (
      <div
        className="success-main-container"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div
          className="success-inner-container new-discover-inner-container"
          id="parentShoppingCartDiv"
        >
          {" "}
          <HeaderWithBurgerIcon
            title="Favorite Wines"
            arrowIcon={true}
            goBackFunc={this.gotoPreviousPage}
          />
          <div className={isMobile ? null : "desktop-div-scroll "}>
            {/* <div className="available-wines-main desktop-av-wines-main"> */}
            <div>
              <div className="available-wines-main-container desktop-av-wines-main-container">
                <div className="col-xs-12 col-sm-12 mt-4">
                  {/* Cart Icon with total quantity badge */}
                  <CartIconComponent
                    fromRecommendedPage={false}
                    fromAvailableWinesPage={true}
                  />
                </div>

                <div className="available-wines-container row mt-0">
                  <ErrorBoundary>
                    {this.renderAvailableWineCards()}
                  </ErrorBoundary>
                </div>
              </div>
            </div>
            <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
              <BottomNavigation />
            </div>
          </div>
        </div>
      </div>
    );
  }
  openHomePage = () => {
    this.props.history.push("/discover");
  };
}

export default FavoriteWine;
