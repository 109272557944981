import React, { Component } from "react";
import "./CartIcon.scss";
import cartIcon from "../../../assets/images/cart-icon-black.svg";
import { withRouter } from "react-router-dom";
import * as GetCartTotalQtyAction from "../../redux/actions/GetCartTotalQtyAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as commonFunctions from "../../constants/commonFunctions";
import { isMobile } from "react-device-detect";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import SignInToContinue from "../CommonComponents/SignInToContinue";

class CartIconComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // wineryId: JSON.parse(localStorage.getItem("winery_id")),
      showCartIcon: false,
    };
  }

  componentDidMount = () => {
    // API to get total cart quantity
    if (this.props.wineryDetails != null) {
      this.props.GetCartTotalQtyAction.GetCartTotalQty(
        this.props.wineryDetails.id
      );
      this.checkWineryUsesPAShoppingCart();
    }
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.wineryDetails != null &&
      prevProps.wineryDetails.id != this.props.wineryDetails.id
    ) {
      this.props.GetCartTotalQtyAction.GetCartTotalQty(
        this.props.wineryDetails.id
      );
      this.checkWineryUsesPAShoppingCart();
    }
  }

  checkWineryUsesPAShoppingCart = () => {
    let selectedWineryData = commonFunctions.getWineryDetailsFromLocalStorage(
      this.props.wineryDetails.id,
      this.props.wineryListFromRedux
    );
    console.log(selectedWineryData)
    if (
      selectedWineryData &&
      selectedWineryData.shoppingCartOptions ===
        commonFunctions.shoppingCartOptions.usePAShoppingCart
    ) {
      this.setState({
        showCartIcon: true,
      });
    }
  };

  goToShoppingCart = () => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    if (!signInToken) {
      this.setState({ signInPopUp: true });
    } else {
      GoogleAnalyticsFunctions.onClickGoToShoppingCart();
      this.props.history.push("/shopping-cart");
    }
  };

  render() {
    let { cartTotalQuantity } = this.props;
    // console.log(cartTotalQuantity);
    let { fromRecommendedPage, fromAvailableWinesPage } = this.props; // checking its coming from wines/recommendation page (Note: for mobile and desktop different UI is there)
    return (
      <React.Fragment>
        {/* Below design for recommended wines and recommended wines desktop pages */}
        {this.state.showCartIcon && fromRecommendedPage && (
          <div
            className="add-to-cart-icon-div ptr-clss ml-3"
            onClick={this.goToShoppingCart}
          >
            <img
              src={cartIcon}
              className={
                isMobile
                  ? "cart-icon cart-icon-position"
                  : "cart-icon cart-icon-position mt-0"
              }
            />
            <span
              className={
                isMobile ? "badge badge-notify" : "badge badge-notify-desktop"
              }
            >
              {cartTotalQuantity}
            </span>
            {/* {cartTotalQuantity > 0 ? (<span className={isMobile ? "badge badge-notify" : "badge badge-notify-desktop"}>{cartTotalQuantity}</span>) : <span className={isMobile ? "badge badge-notify-empty" : "badge badge-notify-desktop-empty"}>{" "}</span>} */}
          </div>
        )}
        {this.state.showCartIcon && fromAvailableWinesPage && (
          <div
            className="add-to-cart-icon-div ptr-clss ml-3"
            onClick={this.goToShoppingCart}
          >
            <img
              src={cartIcon}
              className={
                isMobile
                  ? "cart-icon cart-icon-position"
                  : "cart-icon cart-icon-position mt-0"
              }
            />
            <span
              className={
                isMobile ? "badge badge-notify" : "badge badge-notify-desktop"
              }
            >
              {cartTotalQuantity}
            </span>
            {/* {cartTotalQuantity > 0 ? (<span className={isMobile ? "badge badge-notify" : "badge badge-notify-desktop"}>{cartTotalQuantity}</span>) : <span className={isMobile ? "badge badge-notify-empty" : "badge badge-notify-desktop-empty"}>{" "}</span>} */}
          </div>
          // <div
          //     className="col-sm-2 col-xs-2 pr-0 mt-1 float-right cart-icon-padding ptr-clss"
          //     onClick={this.goToShoppingCart}
          // >
          //     <img
          //         className="cart-icon cart-icon-position cart-icon-margin"
          //         src={cartIcon}
          //     />
          //     <span className="badge available-wines-badge-notify">{cartTotalQuantity}</span>
          // </div>
        )}
        <SignInToContinue
          signInPopUp={this.state.signInPopUp}
          url={"/shopping-cart"}
          where={"to view Shopping cart."}
          handleClose={() => this.setState({ signInPopUp: false })}
        />
      </React.Fragment>
    );
  }
}

//REDUX
function mapStateToProps(state, props) {
  return {
    cartTotalQuantity: state.GetCartTotalQtyReducer.cartTotalQuantity,
    wineryListFromRedux: state.WineryReducer.wineryList,
    wineryDetails: state.WineryReducer.currentWineryDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetCartTotalQtyAction: bindActionCreators(GetCartTotalQtyAction, dispatch),
  };
}

CartIconComponent = withRouter(CartIconComponent);
export default connect(mapStateToProps, mapDispatchToProps)(CartIconComponent);
