import * as types from './action-types';
import * as constants from '../../constants/constants'

export const AddToCart = (wineryId, wineId) => {
 
//     let consumerData = ( JSON.parse(localStorage.getItem('user_data'))  );

    let signInToken = ( JSON.parse(localStorage.getItem('signin_token'))  );
    let bearer = 'Bearer ' + signInToken;

//     let consumerId = consumerData.id;
//    if(consumerId==undefined || consumerId == null) {
//        consumerId = 3;
//    }
    return dispatch => {
        // return dispatch({ type: types.ADD_TO_CART_SUCCEED, payload: "Hi" })

         fetch(constants.WINERY_API + '/api/v1/winery/getPreferredShoppingCart/winery/' +wineryId+ '/wine/' + wineId, { // new updated API url
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": bearer
            }
            
        }).then(response => {
            return response.json();
        }).then(respJSON => {
            dispatch({ type: types.ADD_TO_CART_SUCCEED, payload: respJSON })
        }).catch(error => {
            dispatch({ type: types.ADD_TO_CART_FAILED, payload: error })
        })
    }
}

// Calling Dummy method before calling add to cart api
export const AddToCartDummyApi = () => {
    return dispatch => {
        dispatch({ type: types.ADD_TO_CART_DUMMY, payload: undefined })
    }
}