import React, { Component } from "react";
import "./SearchWineForDish.scss";

import PAResultsComponent from "../../components/PairingResults/PairingResultsComponent";
import NewSearchIcon from "../../../assets/images/newSearchIcon.svg";
import pairAnythingLogo from "../../../assets/images/search-wine-pa-logo.svg";
import emptyCardSearchImage from "../../../assets/images/EmptyPageCard/recommendation-screen-image.svg";
import switchWineryIcon from "../../../assets/images/switch_winery_icon.svg";
import chatIcon from "../../../assets/images/chat-icon-switch.svg";
import { isMobile } from "react-device-detect";
import * as GoogleAnalyticsFunctions from "../../components/PAGoogleAnalytics";
import { foodPairingAdd, getHistory, getFoodResult } from "./action";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import * as constants from "../../constants/constants";
import EmptyPageCard from "../../components/EmptyPageCard/EmptyPageCard";
import RecommendationHistoryCardAccordian from "../../components/RecommendationHistoryCardAccordian/RecommendationHistoryCardAccordian";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import CouponModal from "../../components/CouponModal/CouponModal";
import errorBoundaryImage from "../../../assets/images/error-fixing.svg";
import MenuIcon from "@material-ui/icons/Menu";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import WineSearchCard from "../../components/WineSearchCard/WineSearchCard";
import { ConsoleView } from "react-device-detect";
import QuizUsersPopup from "../../components/QuizUsersPopup/QuizUsersPopup";
import { connect } from "react-redux";
import HeaderWithBurgerIcon from "../../components/CommonComponents/HeaderWithBurgerIcon";
import SignInToContinue from "../../components/CommonComponents/SignInToContinue";
import {
  camelToName,
  convertToCamelCase, isEmbedded,
  postCaughtException,
} from "../../constants/commonFunctions";
import axiosCall from "../../constants";
import {
  setCurrentWineryDetails,
  setWineryList,
} from "../../redux/actions/WineryAction";
import PoweredByHeader from '../../components/PoweredByHeader/PoweredByHeader';
import foodIconRed from '../../../assets/images/food_icon_red.svg';
import foodIconGrey from '../../../assets/images/food_icon_grey.svg';
import wineIconRed from '../../../assets/images/wine_icon_red.svg';
import wineIconGrey from '../../../assets/images/wine_icon_grey.svg';
import PARecommendationEntrance from '../../components/PARecommendationEntrance/PARecommendationEntrance';

class SearchWineForDish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entrance: false,
      query: "",
      idxFood: 0,
      idxWine: 0,
      capIdx: 3,
      topClearance: 200,
      wineryDisabledResponseFromAPI: true,
      gotoWineryDetailsPageStatus: false,
      invalidWineryIdInUrl: false,
      chatStatus: false,
      switchWineryStatus: false,
      opened: false,
      wineryName: "",
      wineryLogo: "",
      wineryDescription: "",
      wineryMessage: "",
      dishName: "",
      dishNameStatus: "",
      errorOccurred: "",
      errorMessage: "",
      buttonFoodActive: true,
      buttonWineActive: false,
      recommendationsPageLoaded: false,
      showFoodResult: false,
      recommendationSearchResponse: "",
      getRecommendationHistoryApiCallDone: false,
      getRecommendationGetHistoryApiCallDone: false,
      wineSearchResult: null,
      emptyWineresult: false,
      searchResult: [],
      getHistory: [],
      foundFoods: [],
      userInput: "",
      showResult: false,
      showNoFood: false,
      recommendationHistory: [
        {
          createdAt: "",
          id: "",
          isAttended: "",
          question: "",
          timestamp: "",
          updatedAt: "",
          userId: "",
          wineryId: "",
        },
      ],
      recommendationHistoryInReverse: [
        {
          createdAt: "",
          id: "",
          isAttended: "",
          question: "",
          timestamp: "",
          updatedAt: "",
          userId: "",
          wineryId: "",
        },
      ],

      block1: true,
      block2: false,
      block3: false,
      disableSearchBtn: false,
      showSideMenu: false,
    };
  }

  setEntranceState = (entrance) => {
    this.setState({
      ...this.state,
      entrance: entrance
    })
  }
  openHomePage = () => {
    this.props.history.push("/discover");
  };

  sendPairingQuestion = () => {
    let wineryData = this.props.wineryDetails;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let sessionData = JSON.parse(localStorage.getItem("anonymous_session"));
    let wineryId = wineryData.id;
    // if (consumerData && consumerData != null) {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;

    var date = new Date();
    var timeStamp = date.toISOString();
    let postPairingQuestionApiUrl =
      constants.GOOGLE_SEARCH_API +
      "/api/v1/public/recommendation/create/question?";
    if (consumerData && consumerData != null) {
      postPairingQuestionApiUrl =
        constants.GOOGLE_SEARCH_API +
        "/api/v1/public/recommendation/create/question?" +
        "questionRaisedBy=" +
        consumerData.id +
        "&wineryId=" +
        wineryId;
    } else
      postPairingQuestionApiUrl =
        constants.GOOGLE_SEARCH_API +
        "/api/v1/public/recommendation/create/question?userSessionId=" +
        sessionData.id +
        "&wineryId=" +
        wineryId;
    let apiPostData = {
      question: this.state.dishName,
      timestamp: timeStamp,
    };
    fetch(postPairingQuestionApiUrl, {
      // dev
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          let apiErrorObj = {
            statusText: res.statusText,
            type: res.type,
            status: res.status,
            url: postPairingQuestionApiUrl,
            operation: "POST",
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          // PAException.postCaughtAPIException(apiErrorObj);
          this.setState({
            disableSearchBtn: false,
            errorMessage: "Something went wrong. Please try again later",
            errorOccurred: "postPairingQuestion",
          });
          throw Error(res.statusText);
        }
      })
      .then((json) => {
        this.setState({
          disableSearchBtn: false,
          recommendationsPageLoaded: true,
          showFoodResult: true,
          showResult: false,
          idx: 0,
          recommendationSearchResponse: json,
        });
      })
      .catch((error) => {
        this.setState({
          disableSearchBtn: false,
        });
        console.error(error);
      });
    // } else {
    //   this.setState({ signInPopUp: true });
    // }
  };

  gotoRecommendationsPage = () => {
    if (this.state.dishName != "") {
      GoogleAnalyticsFunctions.clickedAskRecommendation();
      this.setState({
        disableSearchBtn: true,
      });
      this.sendPairingQuestion();
    } else {
      this.setState({
        dishNameStatus: "Please enter a dish name",
      });
    }
  };

  // componentDidUpdate(){

  //   window.onpopstate  = (e) => {

  //     alert(1);
  //     alert("route: ", this.props.location.state);
  //  }

  //     }

  componentDidMount = async () => {
    let wineryName = localStorage.getItem("winery_name");
    if (
      // (this.props.wineryDetails.displayName &&
      //   this.props.wineryDetails.displayName ==
      //     camelToName(this.props.match.params.wineryName)) ||
      wineryName &&
      wineryName == camelToName(this.props.match.params.wineryName)
    ) {
      this.getHistoryDetails();
      this.getWineryDetails();
    } else {
      this.fetchWineryDetailsFromDB();
    }
    if (
      this.props.location.state &&
      this.props.location.state.addToCartAutomatically
    ) {
      if (this.props.location.state.addToCartAutomatically.buttonFoodActive)
        this.onClick({
          currentTarget: {
            innerText:
              this.props.location.state.addToCartAutomatically.dishName,
          },
        });
      else {
        this.setState(
          {
            buttonFoodActive:
              this.props.location.state.addToCartAutomatically.buttonFoodActive,
            buttonWineActive:
              !this.props.location.state.addToCartAutomatically
                .buttonFoodActive,
            dishName: this.props.location.state.addToCartAutomatically.dishName,
          },
          () => this.getWineryRecommendations()
        );
      }
    }
  };

  fetchWineryDetailsFromDB = () => {
    let url =
      constants.WINERY_API +
      "/api/v1/public/winery/get/wineryByUniqueUrl/" +
      this.props.match.params.wineryName
    axiosCall("GET", url)
      .then((resp) => {
        console.log(resp);
        if (resp.statusText == "OK" && !resp.data.msg) {
          // window.location.reload();
          localStorage.setItem("winery_id", JSON.stringify(resp.data.id));
          localStorage.setItem("winery_name", resp.data.displayName);
          this.props.dispatch(setWineryList([{ ...resp.data }]));
          this.props.dispatch(setCurrentWineryDetails({ ...resp.data }));
          if (
            localStorage.getItem("winery_list") == undefined ||
            localStorage.getItem("winery_list") == null
          ) {
            let winery = [];
            let element = resp.data;
            let obj = {
              wineryId: element.wineryId,
              wineryUserStatus: element.statusId,
              displayName: element.displayName,
              shoppingCartOptions: element.shoppingCartOptions,
              website: element.website,
              name: element.name,
              ...element,
            };
            winery.push(obj);
            localStorage.setItem("winery_list", JSON.stringify(winery));
          }
          this.getWineryDetails();
          this.getHistoryDetails();
        } else {
          let apiErrorObj = {
            statusText: resp.statusText,
            type: resp.type,
            status: resp.status,
            url,
            operation: "GET",
            status: resp.status,
          };

          this.postCaughtException(JSON.stringify(apiErrorObj));

          throw Error(resp.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(camelToName(this.props.match.params.wineryName).trim());
  };
  getHistoryDetails = async () => {
    let token = JSON.parse(localStorage.getItem("signin_token"));
    if (token !== null && token !== undefined) {
      this.setState({ signInToken: token });
    }

    try {
      GoogleAnalyticsFunctions.logPagesVisitedByUser();

      this.getRecommendationHistory();
      this.getWineryDetails();
      const history = await getHistory();
      this.setState({
        getHistory: history,
        getRecommendationGetHistoryApiCallDone: true,
      });

      // get clearance for recommendation items
      let headerClearance = document.getElementById(
        "search-wine-for-dish-header"
      ).clientHeight;
      let mainClearance = document.getElementById(
        "switch-winery-main-container"
      ).clientHeight;
      // subtract 65 for ipad, min clearance of 235 for small size device (iPhone 5 / SE)
      let totalClearance = Math.max(
        headerClearance + mainClearance + mainClearance - 65,
        235
      );
      this.setState({
        topClearance: totalClearance,
      });
      // }
    } catch (e) {
      console.log(e);
    }
  };
  getWineryDetails = () => {
    console.log(this.props);
    if (this.props.wineryDetails != null) {
      let json = this.props.wineryDetails;
      this.setState({
        wineryName: json.displayName,
        wineryLogo: json.logo,
        wineryDescription: json.description,
        wineryMessage: json.message,
      });
    }
  };

  handleChange(event) {
    let val = event.target.value;

    this.setState({ dishName: val });
  }
  async onInputChange(e) {
    this.setState({ dishName: e.currentTarget.value });

    const keyword = e.currentTarget.value;

    if (keyword.length >= 2) {
      const foundFoods = await getFoodResult(keyword);

      if (foundFoods.length === 0) {
        this.setState({ showNoFood: true });
      } else {
        this.setState({ showNoFood: false });
      }

      this.setState({ foundFoods, showResult: true });
    } else {
      this.setState({ showResult: false });
    }

    if (this.state.dishName.length === 0 && this.state.foundFoods.length > 0) {
      this.setState({ showResult: false });
    } else if (
      this.state.dishName.length === 0 &&
      this.state.foundFoods.length === 0
    ) {
      this.setState({ showResult: false, showNoFood: false });
    }
  }

  onClick(value) {
    this.setState({ dishName: value.currentTarget.innerText }, () => {
      this.gotoRecommendationsPage();
    });
  }

  postCaughtException = (errorData) => {
    let signInToken = JSON.parse(localStorage.getItem("signin_token"));
    let bearer = "Bearer " + signInToken;
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    let wineryId = JSON.parse(localStorage.getItem("winery_id"));

    let postCaughtExceptionApiUrl =
      constants.IDENTITY_API + "/api/v1/frontendException/create";
    let apiPostData = {
      portal: "consumerportal",
      page: window.location.href,
      error: errorData,
      userId: consumerData.id,
      wineryId: wineryId,
      operation: JSON.parse(errorData).operation,
      statusCode: JSON.parse(errorData).status,
    };

    fetch(postCaughtExceptionApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      body: JSON.stringify(apiPostData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          localStorage.clear();
          localStorage.setItem("ageConfirmed", true);
          throw Error(res.statusText);
        }
      })
      .then((json) => {})
      .catch((error) => console.error(error));
  };

  foodclick = () => {
    this.setState({
      buttonFoodActive: true,
      buttonWineActive: false,
      dishName: "",
      emptyWineresult: false,
      wineSearchResult: null,
    });
  };

  wineclick = () => {
    this.setState({
      buttonFoodActive: false,
      buttonWineActive: true,
      dishName: "",
      showResult: false,
      showNoFood: false,
    });
  };

  isExternalReferrer = () => {
    const isExternalReferrer = document.referrer && document.referrer.indexOf(window.location.hostname) < 0;
    console.log('check external referrer', isExternalReferrer);
    return isExternalReferrer;
  }

  getWineryRecommendations = async () => {
    let consumerData = JSON.parse(localStorage.getItem("user_data"));
    // if (consumerData && consumerData != null) {
    let { dishName } = this.state;
    if (dishName !== "") {
      if (dishName.length >= 3) {
        const data = await foodPairingAdd(dishName);
        this.setState(
          { wineSearchResult: data, emptyWineresult: false },
          async () => {
            const history = await getHistory();
            this.setState({
              getHistory: history,
              getRecommendationGetHistoryApiCallDone: true,
            });
          }
        );
      } else {
        this.setState({ emptyWineresult: true });
      }
      this.props.history.replace(this.props.location.pathname, {
        ...this.props.location.state,
        addToCartAutomatically: undefined,
      });
    }
    // } else {
    //   this.setState({ signInPopUp: true });
    // }
  };
  isExternalReferrer = () => {
    const isExternalReferrer = document.referrer && document.referrer.indexOf(window.location.hostname) < 0;
    console.log('check external referrer', isExternalReferrer);
    return isExternalReferrer;
  }

  getRecommendationHistory = () => {
    try {
      let wineryData = this.props.wineryDetails;
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let sessionData = JSON.parse(localStorage.getItem("anonymous_session"));
      let wineryId = wineryData.id;
      let signInToken = JSON.parse(localStorage.getItem("signin_token"));
      let bearer = "Bearer " + signInToken;
      let getRecommendationHistoryApiUrl = constants.IDENTITY_API;
      if (consumerData && consumerData != null)
        getRecommendationHistoryApiUrl =
          getRecommendationHistoryApiUrl +
          "/api/v1/public/recommendation/getQuestions?questionRaisedBy=" +
          consumerData.id;
      else
        getRecommendationHistoryApiUrl =
          getRecommendationHistoryApiUrl +
          "/api/v1/public/recommendation/getQuestions?userSessionId=" +
          sessionData.id;
      getRecommendationHistoryApiUrl =
        getRecommendationHistoryApiUrl + "&wineryId=" + wineryId;
      fetch(getRecommendationHistoryApiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization: bearer,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            let apiErrorObj = {
              statusText: res.statusText,
              type: res.type,
              status: res.status,
              url: getRecommendationHistoryApiUrl,
              operation: "GET",
            };
            let errMsg =
              "Your email is no longer associated with " +
              wineryData.displayName +
              " winery, please contact the winery to gain access again. ";
            this.postCaughtException(JSON.stringify(apiErrorObj));
            this.setState({
              wineryDisabledResponseFromAPI: true,
              errorOccurred: "getRecommendationHistory",
              errorMessage: errMsg,
            });
            throw Error(res.statusText);
          }
        })
        .then((json) => {
          //   if successful, update state
          console.log(json);
          this.setState({
            wineryDisabledResponseFromAPI: false,
            recommendationHistory: json,
            recommendationHistoryInReverse: json.reverse(),
            getRecommendationHistoryApiCallDone: true,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      let consumerWineryStatus = localStorage.getItem("user_winery_status");
      let wineryData = this.props.wineryDetails.displayName;
      if (
        consumerWineryStatus != null &&
        consumerWineryStatus != undefined &&
        consumerWineryStatus == "disabled"
      ) {
        this.setState({
          wineryDisabledResponseFromAPI: true,
          errorOccurred: "getRecommendationHistory",
          errorMessage:
            "Your email is no longer associated with " +
            wineryData +
            " winery, please contact the winery to gain access again. ",
        });
      }
    }
  };

  renderRecommendationHistory = () => {
    // set to reverse order
    let result = [];
    let ctr = 0;
    let allWinesDeletedStatus;
    // for(let i =0; i< this.state.recommendationHistoryInReverse.length;++i) {
    //   if(this.state.recommendationHistoryInReverse[i].message!=null
    //     ) {

    //        if(this.state.recommendationHistoryInReverse[i].message == "This wine is not available anymore") {
    //          ctr++;
    //        }

    //     }
    // }

    // allWinesDeletedStatus = ctr == this.state.recommendationHistoryInReverse.length ? 1 : 0
    //   // all wines for recommendations have been deleted

    if (this.state.errorOccurred == "getRecommendationHistory") {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container mt-5">
            {/* errorBoundaryImage */}
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.recommendationHistoryInReverse.length > 0) {
      // atleast one recommendation history is there
      let togo = Math.min(
        this.state.idxFood + this.state.capIdx,
        this.state.recommendationHistoryInReverse.length
      );
      for (let index = 0; index < togo; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div key={index} className="recommendation-history-main-container">
              <RecommendationHistoryCardAccordian
                key={index}
                apiCallDone={this.state.getRecommendationHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                recommendationQueryData={
                  this.state.recommendationHistoryInReverse[index]
                }
              />
            </div>
          </div>
        );
      }
    } else {
      let emptyCardData = {
        title: "No recent recommendations",
        text: "Your recent recommendations will appear here",
        image: emptyCardSearchImage,
      };
      result.push(
        <div className="search-wine-empty-page-main-container">
          <EmptyPageCard emptyPageCardData={emptyCardData} />
        </div>
      );
    }

    return result;
  };

  renderWineRecommendationHistory = () => {
    let { wineSearchResult, emptyWineresult } = this.state;
    let result = [];
    let emptyCardData = {
      title: "No result found",
      text: "Please enter minimum three letter",
      image: emptyCardSearchImage,
    };
    const isError = this.state.errorOccurred == "getRecommendationHistory";
    const haveHistory = this.state.getHistory.length > 0;
    if (isError) {
      result.push(
        <div className="recommendation-history-main-container">
          <div className="error-boundary-main-container mt-5">
            {/* errorBoundaryImage */}
            <span className="error-boundary-title">
              {this.state.errorMessage}
            </span>
            <div className="error-boundary-image-container">
              <img
                alt="Something went wrong"
                className="error-boundary-image"
                src={errorBoundaryImage}
              />
            </div>
          </div>
        </div>
      );
    } else if (haveHistory) {
      // atleast one recommendation history is there
      let togo = Math.min(
        this.state.idxWine + this.state.capIdx,
        this.state.getHistory.length
      );
      for (let index = 0; index < togo; ++index) {
        result.push(
          <div className="reco-main" key={index}>
            <div className="recommendations-custom-line"></div>
            <div key={index} className="recommendation-history-main-container">
              <RecommendationHistoryCardAccordian
                key={index}
                apiCallDone={this.state.getRecommendationGetHistoryApiCallDone}
                title="hi"
                paragraph="sample"
                recommendationQueryData={this.state.getHistory[index]}
              />
            </div>
          </div>
        );
      }
    }
    // else {
    //   let emptyCardData = {
    //     title: "No recent recommendations",
    //     text: "Your recent recommendations will appear here",
    //     image: emptyCardSearchImage,
    //   };
    //   result.push(
    //     <div className="search-wine-empty-page-main-container">
    //       <EmptyPageCard emptyPageCardData={emptyCardData} />
    //     </div>
    //   );
    // }
    return [
      emptyWineresult == false && wineSearchResult && (
        <WineSearchCard result={wineSearchResult} />
      ),
      // emptyWineresult == true && (
      //   <EmptyPageCard emptyPageCardData={emptyCardData} />
      // ),
      result,
    ];
  };

  toggle = (index) => () => {
    this.setState({ [`block${index}`]: !this.state[`block${index}`] });
  };

  chatClicked = () => {
    this.setState({
      chatStatus: true,
    });
  };
  switchWineryClicked = () => {
    this.setState({
      switchWineryStatus: true,
    });
  };

  gotoWineryDetailsPage = () => {
    if (this.state.errorMessage.startsWith("Your email is no longer")) {
      this.setState({
        gotoWineryDetailsPageStatus: false,
      });
    } else {
      this.setState({
        gotoWineryDetailsPageStatus: true,
      });
    }
  };

  componentDidUpdate(prevProps, prevStates) {
    //let wineryData = sessionStorage.getItem('wineryName');
    // let errMsg =  "Your email is no longer associated with " + wineryData +" winery, please contact the winery to gain access again. "
    if (this.state.switchWineryStatus) {
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/select-winery",
      //       state: {},
      //     }}
      //   />
      // );
      this.props.history.push({
        pathname: "/wineries",
        state: {},
      });
    }
    if (prevProps.wineryDetails != this.props.wineryDetails) {
      this.getWineryDetails();
    }
    if (this.state.gotoWineryDetailsPageStatus) {
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/winery-details",
      //       state: {},
      //     }}
      //   />
      // );
      this.props.history.push({
        pathname:
          "/" +
          convertToCamelCase(localStorage.getItem("winery_name")),
        // pathname: "/winery-details",
        state: {},
      });
    }

    if (
      this.state.chatStatus &&
      this.state.errorMessage != "Something went wrong. Please try again later"
    ) {
      let wineryId = JSON.parse(localStorage.getItem("winery_id"));
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/chat" + "/" + wineryId,
      //       state: {},
      //     }}
      //   />
      // );
      this.props.history.push({
        pathname: "/chat" + "/" + wineryId,
        state: {},
      });
    }

    if (this.state.recommendationsPageLoaded) {
      try {
        let wineryId = JSON.parse(localStorage.getItem("winery_id"));
        this.props.history.replace(this.props.location.pathname, {
          ...this.props.location.state,
          addToCartAutomatically: undefined,
        });
        // return (
        //   <Redirect
        //     to={{
        //       pathname:
        //         "/winery/" + wineryId + "/recommended-wines/" +
        //         this.state.recommendationSearchResponse.id,
        //       state: {
        //         dishName: this.state.dishName,
        //         questionId: this.state.recommendationSearchResponse.id,
        //       },
        //     }}
        //   />
        // );
        this.props.history.push({
          pathname:
            "/winery/" +
            wineryId +
            "/recommended-wines/" +
            this.state.recommendationSearchResponse.id,
          state: {
            dishName: this.state.dishName,
            questionId: this.state.recommendationSearchResponse.id,
          },
        });
      } catch (e) {}
    }
    if (
      prevStates.recommendationSearchResponse !==
      this.state.recommendationSearchResponse
    ) {
      this.getNewRecommendationResponse(
        this.state.recommendationSearchResponse
      );
    }
  }
  getNewRecommendationResponse = async (searchResponse) => {
    let wineryIdForGetResponse = JSON.parse(localStorage.getItem("winery_id"));
    let getResponseForQuestionApiUrl =
      constants.IDENTITY_API +
      "/api/v1/public/recommendation?questionId=" +
      searchResponse.id +
      "&wineryId=" +
      wineryIdForGetResponse;
    try {
      const recommendedDetails = await axiosCall(
        "GET",
        getResponseForQuestionApiUrl
      );
      let recommendationHistoryInReverse = [
        ...this.state.recommendationHistoryInReverse,
      ];
      recommendationHistoryInReverse.unshift(recommendedDetails.data);
      this.setState({
        recommendationHistoryInReverse,
        idx: 0,
        currentIndex: 0,
      });
    } catch (e) {
      let consumerData = JSON.parse(localStorage.getItem("user_data"));
      let apiErrorObj = {
        statusText: e.statusText,
        type: e.type,
        status: e.status,
        url: getResponseForQuestionApiUrl,
        operation: "GET",
        status: e.status,
      };
      postCaughtException(JSON.stringify(apiErrorObj), consumerData, null);
    }
  };
  renderBatch() {
    let togo = this.state.buttonFoodActive
      ? Math.min(
          this.state.idxFood + this.state.capIdx,
          this.state.recommendationHistoryInReverse.length
        )
      : Math.min(
          this.state.idxWine + this.state.capIdx,
          this.state.getHistory.length
        );
    let result = [];
    for (let index = 0; index < togo; ++index) {
      result.push(
        <div className="reco-main" key={index}>
          <div className="recommendations-custom-line"></div>
          <div
            key={index}
            className="recommendation-history-main-container"
            onClick={() => {
              this.changeIndex(index);
            }}
          >
            <RecommendationHistoryCardAccordian
              key={index}
              apiCallDone={
                this.state.buttonFoodActive
                  ? this.state.getRecommendationHistoryApiCallDone
                  : this.getRecommendationGetHistoryApiCallDone
              }
              title="hi"
              paragraph="sample"
              recommendationQueryData={
                this.state.buttonFoodActive
                  ? this.state.recommendationHistoryInReverse[index]
                  : this.state.getHistory[index]
              }
              getRecommendationHistory={this.getRecommendationHistory}
              isSelected={index === this.state.currentIndex}
            />
          </div>
        </div>
      );
    }
    this.setState({
      idxFood: this.state.buttonFoodActive ? togo : this.state.idxFood,
      idxWine: this.state.buttonFoodActive ? this.state.idxWine : togo,
    });
  }

  render() {
    const { foundFoods, query, entrance, showResult, showNoFood, buttonFoodActive, showFoodResult, redirect } =
      this.state;

    // let wineryData = sessionStorage.getItem('wineryName');
    // let errMsg =  "Your email is no longer associated with " + wineryData +" winery, please contact the winery to gain access again. "
    const {
      state: { opened },
    } = this;
    const recStyle = {
      paddingTop: `${this.state.topClearance}px`,
    };
    const wineryId = JSON.parse(localStorage.getItem("winery_id"));

    return (
      <React.Fragment>
          <div className=" success-inner-container  new-discover-inner-container" style={{backgroundColor: "rgb(242, 242, 242)"}}>
            {!isEmbedded() &&
                <HeaderWithBurgerIcon title="Pairing Recommendations" hideBackInEmbedded/>
            }
            <div
              className={isMobile ? null : "desktop-div-scroll "}
              style={{ backgroundColor: isEmbedded() ? "#fff" : "#f2f2f2", ...(isEmbedded() && { height: "100vh" }) }}
            >
              <div className={isEmbedded() ? "search-main-embedded" : "search-main-swfd"}>
                {!isEmbedded() &&
                  <>
                    {this.state.wineryName === "Scott Harvey Wines" ? (
                        <CouponModal
                            openDialog={true}
                            initial={true}
                            wineryId={wineryId}
                            wineryName={this.state.wineryName}
                            plaidFriday={true}
                        />
                    ) : null}
                  </>
                }
                {/* <QuizUsersPopup openDialog={true} initial={true} /> */}

                {/* {
                // this.state.errorMessage != '' ?
                // this.state.errorMessage

                // this.state.errorMessage != "" ? (
                this.state.errorMessage ==
                "You do not have access to this page." ? (
                  <PageNotFound></PageNotFound>
                ) : (
                  ""
                )
                // ) :  ''
                } */}
                <div
                  {...{
                    className: `switch-winery-main-container accordion-item , ${
                      opened && "accordion-item--opened"
                    }`,
                    id: "switch-winery-main-container",
                  }}
                  onClick={() => {
                    if ((!isEmbedded()) && wineryId != 381) this.gotoWineryDetailsPage()
                  }}
                >
                  <div style={{ backgroundColor: "#fff", padding: isEmbedded() ? "0" : "5px" }}>
                    {isEmbedded() && entrance &&
                        <PARecommendationEntrance setShowResult={this.setEntranceState} query={query}/>
                    }
                    {!entrance &&
                      <>
                        {isEmbedded() &&
                            <PoweredByHeader/>
                        }
                        {!isEmbedded() &&
                            <div className="recommendation-history-card-accordion-header">
                            <span className="switch-winery-summary-container">

                            <img
                                src={this.state.wineryLogo}
                                className="switch-winery-logo"
                            />
                            <span className="switch-winery-name">
                              {this.state.wineryName}
                            </span>
                          </span>
                            </div>
                        }
                      </>
                    }

                    {/* <span
                      {...{
                        className:
                          "switch-winery-accordion-item__icon accordion-item__icon",

                        onClick: (e) => {
                          // this.getResponseForQuestionFromWineryAdmin(recommendationQueryData.id)

                          this.setState({
                            opened: !opened,
                          });
                          e.stopPropagation(); // to stop parent div onclick to get fired so as to enable arrow up/down
                        },
                      }}
                    /> */}
                  </div>

                  {(!isEmbedded() || !entrance) &&
                      <div className="switch-winery-accordion-item__inner  accordion-item__inner">
                        <div className="col-xs-12 show-grid mb-3 p-0">
                          <div
                              className="col-xs-10 switch-winery-description"
                              onClick={() => {
                                if (!isEmbedded()) this.gotoWineryDetailsPage()
                              }}
                          >
                            {/* <span onClick={this.gotoWineryDetailsPage.bind(this)}> */}
                            {/* {paragraph} */}
                            {this.state.wineryMessage != "" &&
                            this.state.wineryMessage != null
                                ? this.state.wineryMessage
                                : "Winery message coming soon"}
                            {/* </span> */}
                          </div>
                          {/* <div className="col-xs-2 chat-switch-winery">
                        <img
                          className={
                            this.state.errorMessage ===
                            "Something went wrong. Please try again later"
                              ? "chat-winery-icon-disabled"
                              : "switch-winery-icon"
                          }
                          src={chatIcon}
                          onClick={this.chatClicked.bind(this)}
                        />
                        <img
                          className="switch-winery-icon mt-5"
                          src={switchWineryIcon}
                          onClick={this.switchWineryClicked.bind(this)}
                        />
                      </div> */}
                        </div>
                      </div>
                  }
                </div>

                {/**/}

                {/* {
                  this.state.errorMessage == 'Your winery has disabled you. You do not have permissions to access this page. Please contact the winery admin'?

                  '' :

                  this.state.wineryDisabledResponseFromAPI === false &&
                    this.state.errorMessage === "" && ( */}

                {(!isEmbedded() || !entrance) &&
                    <div className="form-group search-box">
                      <div>What Pairs With...</div>
                      <div className="srch-bar-container">
                        <input
                            placeholder={
                              this.state.buttonFoodActive
                                  ? "Search by Food"
                                  : "Search by Wine type or varietal"
                            }
                            type="text"
                            name="dishname"
                            className="srch-bar-input"
                            value={this.state.dishName}
                            onChange={
                              buttonFoodActive == true
                                  ? this.onInputChange.bind(this)
                                  : this.handleChange.bind(this)
                            }
                            onKeyDown={(e) =>
                                e.key === "Enter"
                                    ? this.state.buttonFoodActive
                                        ? this.gotoRecommendationsPage()
                                        : this.getWineryRecommendations()
                                    : ""
                            }
                            style={{fontSize: "18px"}}
                        />
                        <img src={NewSearchIcon} alt="search-icon" className="new-search-icon"></img>
                      </div>
                      <div className="recommendation-tab-input form-control search-wine-form-tab-input" style={{
                        ...(isEmbedded() && {
                          boxShadow: "none"
                        })
                      }}>
                        
                        <button
                            className="btn btn-round search-btn-tab"
                            style={{
                              backgroundColor: `rgba(${this.state.buttonFoodActive
                                  ? "168,69,81"
                                  : "255,255,255"}, 0.08)`,
                              color: this.state.buttonFoodActive ? "#951901" : "#7D7D7D",
                              borderRadius: "50px 0px 0px 50px",
                              ...(isEmbedded() && {
                                borderColor: this.state.buttonFoodActive ? "#951901" : "#7D7D7D"
                              })
                            }}
                            onClick={() => this.foodclick()}
                        >
                          FOODS
                          <img className="btn-icons" src={this.state.buttonFoodActive ? foodIconRed : foodIconGrey}></img>
                        </button>
                        <button
                            className="btn btn-round search-btn-tab"
                            style={{
                              backgroundColor: `rgba(${this.state.buttonWineActive
                                  ? "168,69,81"
                                  : "255,255,255"}, 0.08)`,
                              color: this.state.buttonWineActive ? "#951901" : "#7D7D7D",
                              borderRadius: "0px 50px 50px 0px",
                              ...(isEmbedded() && {
                                borderColor: this.state.buttonWineActive ? "#951901" : "#7D7D7D"
                              })
                            }}
                            onClick={() => this.wineclick()}
                        >
                          WINES
                          <img className="btn-icons" src={this.state.buttonWineActive ? wineIconRed : wineIconGrey}></img>
                        </button>
                      </div>

                      {showFoodResult && (
                      <PAResultsComponent
                      name={this.state.dishName}
                      wineType={this.state.dishName}
                      recipeUrl={"https://cdn.britannica.com/22/187222-050-07B17FB6/apples-on-a-tree-branch.jpg?w=400&h=300&c=crop"}
                      wineImageUrl={"https://cdn.britannica.com/22/187222-050-07B17FB6/apples-on-a-tree-branch.jpg?w=400&h=300&c=crop"}
                      recommendation={this.state.dishName}
                      isFood={true}
                      ></PAResultsComponent>)}
                      
                      {foundFoods.length > 0 && showResult && (
                          <div className="recommendation-input form-control search-wine-form-row-recommand-input shadow">
                            {foundFoods &&
                                foundFoods.map((item, idx) => {
                                  return (
                                      <div
                                          className="dropText"
                                          key={idx}
                                          onClick={this.onClick.bind(this)}
                                      >
                                        {item}
                                      </div>
                                  );
                                })}
                          </div>
                      )}
                      {showNoFood && (
                          <div className="recommendation-input form-control search-wine-form-row-recommand-input shadow">
                            <div className="dropText">No Food Matches!</div>
                          </div>
                      )}
                      {this.state.errorOccurred == "postPairingQuestion" && (
                          <div className="recommendation-input-error-message-container">
                      <span className="recommendation-input-error-message">
                        {this.state.dishName == ""
                            ? this.state.dishNameStatus
                            : ""}

                        {
                          this.state.errorOccurred == "postPairingQuestion"
                              ? this.state.errorMessage
                              : "" // this.state.errorMessage
                        }
                      </span>
                          </div>
                      )}
                    </div>
                }
                {/* )
                } */}
                {/*
                  #NOTE: renderRecommendationHistory renders the recommendation history card if there is
                        any recommendation. Otherwise, the EmptyPageCard is rendered
                */}


                {(!isEmbedded() || !entrance) && !showFoodResult &&
                    <div
                        className={
                          this.state.recommendationHistoryInReverse.length > 0
                              ? "search-wine-for-dish-container"
                              : "search-wine-for-dish-empty-page-container"
                        }
                        // style={recStyle}
                    >
                      <ErrorBoundary>
                        {this.state.buttonFoodActive
                            ? this.renderRecommendationHistory()
                            : this.renderWineRecommendationHistory()}
                      </ErrorBoundary>
                      {this.state.buttonFoodActive
                          ? this.state.idxFood <
                          this.state.recommendationHistoryInReverse.length -
                          this.state.capIdx && (
                              <div
                                  className="reco-main"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingRight: "5%",
                                  }}
                              >
                                <button
                                    className="btn btn-round
                                        confirm-shipping-address-btn1 search-btn"
                                    disabled={this.state.disableSearchBtn}
                                    onClick={this.renderBatch.bind(this)}
                                    style={{fontStyle: "italic"}}
                                >
                                  More
                                </button>
                              </div>
                          )
                          : this.state.idxWine <
                          this.state.getHistory.length - this.state.capIdx && (
                              <div
                                  className="reco-main"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingRight: "5%",
                                  }}
                              >
                                <button
                                    className="btn btn-round
                                        confirm-shipping-address-btn1 search-btn"
                                    disabled={this.state.disableSearchBtn}
                                    onClick={this.renderBatch.bind(this)}
                                    style={{fontStyle: "italic"}}
                                >
                                  More
                                </button>
                              </div>
                          )}
                    </div>
                }
            </div>
            {  !this.isExternalReferrer() && !isEmbedded() && (wineryId && wineryId != 381 && wineryId != 390 && wineryId != 6) &&
                    <div className="bottom-nav-induvidual-wines shadow p-3 bg-white rounded">
                      <BottomNavigation searchWineActive="true" />
                    </div>
                }
          </div>
        </div>
        <SignInToContinue
          signInPopUp={this.state.signInPopUp}
          url={this.props.location.pathname}
          addToCartAutomatically={{
            buttonFoodActive: this.state.buttonFoodActive,
            dishName: this.state.dishName,
          }}
          where={"to search for Recommendations."}
          handleClose={() => this.setState({ signInPopUp: false })}
        />
      </React.Fragment>
    );
  }

  toggleMenu = () => {
    this.setState({
      showSideMenu: !this.state.showSideMenu,
    });
  };
  closeMenu = () => {
    this.setState({
      showSideMenu: false,
    });
  };
}
const mapStateToProps = (state) => {
  return { wineryDetails: state.WineryReducer.currentWineryDetails };
};
export default connect(mapStateToProps, null)(SearchWineForDish);
