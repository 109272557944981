import React from "react";
import wineryLocationBtn from "../../../assets/images/winery-location.png";
import bullet from "../../../assets/images/bullet.png";
const LocationCard = ({ winery, wineryLocationBtnClicked, item }) => {
  return (
    <div>
      {winery.wineryStreetAddress != "" ? (
        // <div>
        // <img
        //   alt="Location"
        //   className="winery-location-btn ptr-clss"
        //   src={wineryLocationBtn}
        //   onClick={() => wineryLocationBtnClicked(item)}
        // />
        //   <span
        //     className="winery-location-text-nonpa "
        //     onClick={() => wineryLocationBtnClicked(item)}
        //   >
        //     {winery.wineryStreetAddress} {winery.wineryCity}{" "}
        //     {winery.wineryState} {winery.wineryZip}{" "}
        //   </span>
        // </div>
        // <ul>
        //   <li>
        <span
          className="winery-location-text-nonpa"
          onClick={() => wineryLocationBtnClicked(item)}
        >
          <img
            alt="Location"
            className="winery-location-btn ptr-clss"
            src={bullet}
            onClick={() => wineryLocationBtnClicked(item)}
          />
          <div>
            {winery.wineryStreetAddress.split("\n").map((str) => (
              <p style={{ textAlign: "left" }}>{str}</p>
            ))}
          </div>
        </span>
      ) : //   </li>
      // </ul>
      null}
    </div>
  );
};
export default LocationCard;
